import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, EventEmitter, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
var PopupControlComponent = /** @class */ (function () {
    function PopupControlComponent(elementRef, viewContainerRef) {
        this.elementRef = elementRef;
        this.viewContainerRef = viewContainerRef;
        this.closed = new EventEmitter();
        if (!PopupControlComponent.INSTANCE) {
            PopupControlComponent.INSTANCE = this;
        }
        else if (!PopupControlComponent.SUBINSTANCE) {
            PopupControlComponent.SUBINSTANCE = this;
        }
    }
    Object.defineProperty(PopupControlComponent, "instance", {
        get: function () {
            return PopupControlComponent.INSTANCE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupControlComponent, "subInstance", {
        get: function () {
            return PopupControlComponent.SUBINSTANCE;
        },
        enumerable: true,
        configurable: true
    });
    PopupControlComponent.closeAll = function () {
        PopupControlComponent.INSTANCE.close();
        PopupControlComponent.SUBINSTANCE.close();
    };
    PopupControlComponent.prototype.keyHandler = function (event) {
        if (!this.isOpen) {
            return;
        }
        if (event.code === 'Escape') {
            event.stopPropagation();
            this.close();
        }
        else if (event.code === 'Enter' && !event['isComposing'] && (!this.config.confirmEnabled || this.config.confirmEnabled())) {
            event.stopPropagation();
            this.confirm(this.config.confirmCallback);
        }
    };
    Object.defineProperty(PopupControlComponent.prototype, "config", {
        get: function () {
            return this.innerConfig;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupControlComponent.prototype, "outlet", {
        get: function () {
            return this.innerOutlet;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupControlComponent.prototype, "isText", {
        get: function () {
            return this.config.content && typeof this.config.content === 'string';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupControlComponent.prototype, "confirmText", {
        get: function () {
            return typeof this.config.confirmText === 'function' ? this.config.confirmText() : this.config.confirmText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupControlComponent.prototype, "inClose", {
        get: function () {
            return this.inCloseFlag;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupControlComponent.prototype, "isOpen", {
        get: function () {
            return this.elementRef.nativeElement.className.includes('active');
        },
        enumerable: true,
        configurable: true
    });
    PopupControlComponent.prototype.ngOnInit = function () {
        this.elementRef.nativeElement.tabIndex = -1;
    };
    PopupControlComponent.prototype.open = function (config) {
        this.innerConfig = config;
        this.inCloseFlag = false;
        if (!this.config || !this.config.content || typeof this.config.content === 'string') {
            this.innerOutlet = null;
        }
        else if (this.config.content instanceof TemplateRef) {
            this.innerOutlet = new TemplatePortal(this.config.content, this.viewContainerRef);
        }
        else {
            this.innerOutlet = new ComponentPortal(this.config.content);
        }
        if (!this.isOpen) {
            this.elementRef.nativeElement.className += ' active';
        }
        this.elementRef.nativeElement.focus();
    };
    PopupControlComponent.prototype.close = function (callbackOnly) {
        if (callbackOnly === void 0) { callbackOnly = false; }
        if (this.isOpen) {
            if (!callbackOnly) {
                this.elementRef.nativeElement.className = this.elementRef.nativeElement.className.replace(/ ?active/g, '');
                this.closed.emit();
                this.innerOutlet = null;
            }
            if (this.config && this.config.closeCallback) {
                this.config.closeCallback();
            }
        }
    };
    PopupControlComponent.prototype.cancel = function () {
        if (this.config.cancelCallback) {
            this.config.cancelCallback();
        }
        this.close();
    };
    PopupControlComponent.prototype.confirm = function (confirmCallback) {
        var _this = this;
        if (this.inClose) {
            return;
        }
        this.inCloseFlag = true;
        var ret = (confirmCallback || this.config.confirmCallback)();
        if (ret instanceof Observable) {
            ret.subscribe(function () {
                _this.close(!_this.inClose);
                _this.inCloseFlag = false;
            }, function () { return (_this.inCloseFlag = false); });
        }
        else {
            if (!ret) {
                this.close(!this.inClose);
            }
            this.inCloseFlag = false;
        }
    };
    return PopupControlComponent;
}());
export { PopupControlComponent };
