<div
  agClickOutside
  (clickOutside)="onOutsideClick(true)"
  [style.height]="'100%'"
  [style.flex]="1"
>
  <div
    class="select-btn {{ extraClass }}"
    (click)="open()"
    [ngClass]="{
      'select-btn--disabled': disabled,
      'select-btn--empty': value === undefined || (multi && !value.length)
    }"
  >
    <span
      class="select-btn__text"
      *ngIf="value !== undefined && (!multi || value.length || allSelected)"
      ><span class="select-btn__text-child">{{ selectedLabel }}</span></span
    >
    <span
      class="select-btn__text select-btn__text--placeholder"
      *ngIf="value === undefined || (multi && !value.length && !allSelected)"
      >{{ placeholder || (multi ? "" : "-----") }}</span
    >
    <span class="select-btn__arrow select-btn__arrow--down"></span>
  </div>
  <div
    class="options {{ extraClass }}"
    [ngClass]="{ 'options--closed': closed }"
  >
    <div class="options__head" *ngIf="multi">
      <span class="options__all" *ngIf="allLabel" (click)="toggle('all')">
        <input
          type="checkbox"
          [(ngModel)]="tempSelected['all']"
          (click)="$event.stopPropagation()"
          class="options__checkbox"
        />
        {{ allLabel }}
      </span>
      <span
        class="options__confirm"
        *ngIf="confirmLabel"
        (click)="confirm(true)"
        >{{ confirmLabel }}</span
      >
      <span
        class="options__close close close--small"
        *ngIf="!confirmLabel"
        (click)="onOutsideClick(true)"
      ></span>
    </div>
    <div class="options__holder">
      <div
        class="options__option"
        [style.paddingLeft]="
          option._selectLevel ? 3 + option._selectLevel * 12 + 'px' : ''
        "
        *ngFor="let option of options; let i = index"
        (click)="onSpecificValueChanged(i)"
      >
        <input
          type="checkbox"
          [ngModel]="tempSelected[i]"
          (change)="onSpecificValueChanged(i)"
          (click)="$event.stopPropagation()"
          class="options__checkbox"
          *ngIf="multi"
        />
        {{
          labelField
            ? getValueFromObject(option, labelField) ||
              ((valueField | typeof) == "string"
                ? getValueFromObject(option, valueField)
                : option)
            : option
        }}
      </div>
    </div>
  </div>
</div>
