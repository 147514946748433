<div class="content">
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        *ngIf="metadataLeft"
        [metadata]="metadataLeft"
        [model]="student?.dynamicData"
        (changed)="onChangedMetadataLeft($event)"
      ></ag-dynamic-form>
    </div>
    <div class="row__column">
      <ag-dynamic-form
        *ngIf="metadataRight"
        [metadata]="metadataRight"
        [model]="student?.dynamicData"
      ></ag-dynamic-form>
    </div>
  </div>
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        *ngIf="metadataOther"
        [metadata]="metadataOther"
        [model]="student?.dynamicData"
      ></ag-dynamic-form>
    </div>
  </div>
</div>
<div class="footer" *ngIf="!readonly">
  <div class="footer__container">
    <button class="footer__btn footer__btn--blue" (click)="save()">更新</button>
  </div>
</div>
