/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-jobs-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/job-list/job-list.component.ngfactory";
import * as i3 from "../../../../../shared/components/job-list/job-list.component";
import * as i4 from "../../../../../shared/services/api/master-api.service";
import * as i5 from "../../../../../shared/services/api/job-api.service";
import * as i6 from "../../../../../shared/pipes/safe-date.pipe";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../shared/services/auth.service";
import * as i9 from "../../../../../shared/services/dialog.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../shared/models/tab";
import * as i12 from "./company-jobs-tab.component";
var styles_CompanyJobsTabComponent = [i0.styles];
var RenderType_CompanyJobsTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyJobsTabComponent, data: {} });
export { RenderType_CompanyJobsTabComponent as RenderType_CompanyJobsTabComponent };
export function View_CompanyJobsTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-job-list", [], null, [[null, "itemSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemSelected" === en)) {
        var pd_0 = (_co.jobSelected.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_JobListComponent_0, i2.RenderType_JobListComponent)), i1.ɵdid(1, 4964352, null, 0, i3.JobListComponent, [i4.MasterApiService, i5.JobApiService, i6.SafeDatePipe, i7.DecimalPipe, i8.AuthService, i1.ChangeDetectorRef, i9.DialogService, i7.Location, i10.Router], { requestObject: [0, "requestObject"], options: [1, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requestObject; var currVal_1 = _co.listConfiguration; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CompanyJobsTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-company-jobs-tab", [], null, null, null, View_CompanyJobsTabComponent_0, RenderType_CompanyJobsTabComponent)), i1.ɵprd(6144, null, i11.Tab, null, [i12.CompanyJobsTabComponent]), i1.ɵdid(2, 573440, null, 0, i12.CompanyJobsTabComponent, [], null, null)], null, null); }
var CompanyJobsTabComponentNgFactory = i1.ɵccf("ag-company-jobs-tab", i12.CompanyJobsTabComponent, View_CompanyJobsTabComponent_Host_0, { companyId: "companyId" }, { jobSelected: "jobSelected" }, []);
export { CompanyJobsTabComponentNgFactory as CompanyJobsTabComponentNgFactory };
