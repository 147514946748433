/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dialog.component";
import * as i3 from "./dialog-ref";
var styles_DialogComponent = [i0.styles];
var RenderType_DialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
function View_DialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_DialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewContainerRef: 0 }), i1.ɵqud(402653184, 2, { dialogEl: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["dialog", 1]], null, 2, "div", [["class", "dialog"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOverlayClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "dialog__content-wrapper"], ["style", "overflow: scroll"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, [[1, 3], ["dialogHost", 2]], null, 0, null, View_DialogComponent_1))], null, null); }
export function View_DialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dialog", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DialogComponent_0, RenderType_DialogComponent)), i1.ɵdid(1, 4374528, null, 0, i2.DialogComponent, [i1.ComponentFactoryResolver, i1.ChangeDetectorRef, i3.DialogRef], null, null)], null, null); }
var DialogComponentNgFactory = i1.ɵccf("ag-dialog", i2.DialogComponent, View_DialogComponent_Host_0, {}, {}, []);
export { DialogComponentNgFactory as DialogComponentNgFactory };
