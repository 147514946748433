/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../file-upload-area/file-upload-area.component.ngfactory";
import * as i3 from "../file-upload-area/file-upload-area.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./file-upload-dialog.component";
import * as i7 from "../dialog/dialog-ref";
import * as i8 from "../dialog/dialog-config";
var styles_FileUploadDialogComponent = [i0.styles];
var RenderType_FileUploadDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadDialogComponent, data: {} });
export { RenderType_FileUploadDialogComponent as RenderType_FileUploadDialogComponent };
function View_FileUploadDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-file-upload-area", [], null, [[null, "filesChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesChanged" === en)) {
        var pd_0 = (_co.onFilesChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FileUploadAreaComponent_0, i2.RenderType_FileUploadAreaComponent)), i1.ɵdid(1, 114688, null, 0, i3.FileUploadAreaComponent, [], null, { filesChanged: "filesChanged" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_FileUploadDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "files-table__option"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.parent.parent.context.$implicit.options[_v.context.$implicit.key] = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i4.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_7 = _v.parent.parent.context.$implicit.options[_v.context.$implicit.key]; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.key; _ck(_v, 8, 0, currVal_8); }); }
function View_FileUploadDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "files-table__options"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FileUploadDialogComponent_5)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i5.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.parent.context.$implicit.options)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileUploadDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "files-table__cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "files-table__link"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadDialogComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "files-table__button \n                      files-table__button--small \n                      files-table__button--outline-gray \n                      files-table__button--white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u524A\u9664 "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.options; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.file.name; _ck(_v, 4, 0, currVal_0); }); }
function View_FileUploadDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "table", [["class", "files-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30D5\u30A1\u30A4\u30EB\u540D"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadDialogComponent_3)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadFilesData; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_FileUploadDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "dialog__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "fileupload-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadDialogComponent_1)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadDialogComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30A2\u30C3\u30D7\u30ED\u30FC\u30C9 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.uploadFilesData.length === 0); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.uploadFilesData.length > 0); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.uploadFilesData.length == 0) || _co.dialog.inClose); _ck(_v, 14, 0, currVal_2); }); }
export function View_FileUploadDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-file-upload-dialog", [], null, null, null, View_FileUploadDialogComponent_0, RenderType_FileUploadDialogComponent)), i1.ɵdid(1, 245760, null, 0, i6.FileUploadDialogComponent, [i7.DialogRef, i8.DialogConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadDialogComponentNgFactory = i1.ɵccf("ag-file-upload-dialog", i6.FileUploadDialogComponent, View_FileUploadDialogComponent_Host_0, {}, {}, []);
export { FileUploadDialogComponentNgFactory as FileUploadDialogComponentNgFactory };
