import * as tslib_1 from "tslib";
import { CompanyContractCreateDialogComponent } from '@agent-ds/company/components';
import { Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DialogService } from '@agent-ds/shared/services';
import { AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, TemplateRef, } from '@angular/core';
import { tap } from 'rxjs/operators';
import { COMPANY_CONTRACTS_TABLE_CONFIG } from './company-contracts-tab-table-config';
var CompanyContractsTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyContractsTabComponent, _super);
    function CompanyContractsTabComponent(companyApiService, datePipe, dialog, cdr) {
        var _this = _super.call(this) || this;
        _this.companyApiService = companyApiService;
        _this.datePipe = datePipe;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.activeFilter = false;
        return _this;
    }
    CompanyContractsTabComponent.prototype.ngOnChanges = function (changes) {
        if (changes['company']) {
            this.loadContracts();
        }
    };
    CompanyContractsTabComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig = COMPANY_CONTRACTS_TABLE_CONFIG(this.statusTemplate, this.actionsTemplate, function (date) {
            return _this.datePipe.transform(date, 'yyyy/MM/dd (E)');
        });
        this.cdr.detectChanges();
    };
    CompanyContractsTabComponent.prototype.loadContracts = function () {
        var _this = this;
        if (this.company) {
            this.companyApiService.getDepartments(this.company.id).subscribe(function (response) {
                _this.departments = response.enterpriseDepartments;
            });
            this.companyApiService.getContracts(this.company.id).subscribe(function (response) {
                _this.contracts = response;
                _this.filter();
            });
        }
    };
    CompanyContractsTabComponent.prototype.onFilterChange = function (value) {
        this.activeFilter = value;
        this.filter();
    };
    CompanyContractsTabComponent.prototype.filter = function () {
        this.filteredContracts = this.activeFilter ? this.contracts : this.contracts.filter(function (dep) { return dep.dynamicData.status !== '無効'; });
    };
    CompanyContractsTabComponent.prototype.openCompanyContractCreateDialog = function () {
        var _this = this;
        this.dialog.open(CompanyContractCreateDialogComponent, {
            data: { contract: {}, company: this.company, departmentList: this.departments },
        }, function (result) {
            if (!result) {
                return;
            }
            return _this.companyApiService.addContract(_this.company.id, result).pipe(tap(function () { return _this.loadContracts(); }));
        });
    };
    CompanyContractsTabComponent.prototype.openCompanyContractEditDialog = function (data) {
        var _this = this;
        this.dialog.open(CompanyContractCreateDialogComponent, {
            data: { contract: data, company: this.company, departmentList: this.departments },
        }, function (result) {
            if (!result) {
                return;
            }
            return _this.companyApiService.updateContract(_this.company.id, data.id, result).pipe(tap(function () { return _this.loadContracts(); }));
        });
    };
    return CompanyContractsTabComponent;
}(Tab));
export { CompanyContractsTabComponent };
