<div class="header">
  管理
</div>

<div class="section">
  <div class="sub-header">
    <div class="sub-header__title">
      報告用CSVダウンロード
    </div>
    <div class="sub-header__description">
      ※
      報告用CSVは直近１ヶ月のみダウンロード可能です。それ以前のデータが必要な場合はシステム管理者にお問い合わせください。
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th>日付</th>
        <th class="table__notice"></th>
        <th class="table__action"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of reports">
        <td class="table__date">
          {{ report.exportedDate | date: "yyyy年MM月dd日 (E)" }}
        </td>
        <td class="table__notice" title="{{ report.errorMessage }}">
          {{ report.errorMessage || "" }}
        </td>
        <td class="table__action">
          <button
            class="table__btn btn btn--small btn--white btn--outline-gray"
            [disabled]="report.inDownload"
            (click)="onDownload(report)"
          >
            ダウンロード
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
