import * as tslib_1 from "tslib";
import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { ProgressCreateDialogComponent, ProgressScheduleAdjustDialogComponent } from '@agent-ds/progress/components';
// tslint:disable-next-line:max-line-length
import { BulkProgressChangeDialogComponent } from '@agent-ds/shared/components/bulk-progress-change-dialog/bulk-progress-change-dialog.component';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { getProgressStatusLabel, NULL_SELECTED_VALUE, PROGRESS_BULK_EDIT, PROGRESS_STATUSES } from '@agent-ds/shared/constants';
import { ProgressActionColor, ProgressActionType } from '@agent-ds/shared/enums';
import { EnterpriseDepartmentUserType, } from '@agent-ds/shared/interfaces';
import { RefreshEvent } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, DialogService, DynamicFieldService, JobApiService, MailApiService, StudentApiService, } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { ProgressApiService } from '@agent-ds/shared/services/api/progress-api.service';
import { UserApiService } from '@agent-ds/shared/services/api/user-api.service';
import { cleanObject, deepClone, getValueFromObject } from '@agent-ds/shared/util/util';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, Type, } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { ProgressDetailComponent } from '../../../progress/pages/progress-detail/progress-detail.component';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MailSendFlowComponent } from '../mail-send-flow/mail-send-flow.component';
import { ProgressBulkConfig } from '../mail-send/configs/progress-bulk-config';
import { ProgressConsultConfig } from '../mail-send/configs/progress-consult-config';
import { ProgressInrowJobPostingConfig } from '../mail-send/configs/progress-inrow-job-posting-config';
import { ProgressJobPostingConfig } from '../mail-send/configs/progress-job-posting-config';
import { ProgressNgEndConfig } from '../mail-send/configs/progress-ng-end-config';
import { ProgressOfferConfig } from '../mail-send/configs/progress-offer-config';
import { ProgressRecommendConfig } from '../mail-send/configs/progress-recommend-config';
import { ProgressSendInterviewInfoConfig } from '../mail-send/configs/progress-send-interview-info-config';
import { ProgressSendResumeConfig } from '../mail-send/configs/progress-send-resume-config';
import { PopupControlComponent } from '../popup-control/popup-control.component';
import { SelectComponent } from '../select/select.component';
import { PROGRESS_LIST_TABLE_CONFIG } from './progress-list-table-config';
var ProgressListComponent = /** @class */ (function () {
    function ProgressListComponent(masterApiService, userApiService, progressApiService, jobApiService, datePipe, cdr, dialog, location, dynamicService, studentApiService, mailApiService, authService, router) {
        var _this = this;
        this.masterApiService = masterApiService;
        this.userApiService = userApiService;
        this.progressApiService = progressApiService;
        this.jobApiService = jobApiService;
        this.datePipe = datePipe;
        this.cdr = cdr;
        this.dialog = dialog;
        this.location = location;
        this.dynamicService = dynamicService;
        this.studentApiService = studentApiService;
        this.mailApiService = mailApiService;
        this.authService = authService;
        this.router = router;
        this.requestObject = { status: [] };
        this.disableDetailFullScreen = false;
        this.disableBulkUpdateButton = false;
        this.statusFilterChange = new EventEmitter();
        this.errorGetList = new EventEmitter();
        this.initialized = false;
        this.ProgressActionType = ProgressActionType;
        this.ProgressActionColor = ProgressActionColor;
        this.getStatusLabel = getProgressStatusLabel;
        this.offerConfig = new ProgressOfferConfig();
        this.consultConfig = new ProgressConsultConfig();
        this.bulkConfig = new ProgressBulkConfig();
        this.recommendConfig = new ProgressRecommendConfig();
        this.ngEndConfig = new ProgressNgEndConfig();
        this.ProgressSendInterviewInfoConfig = ProgressSendInterviewInfoConfig;
        this.PROGRESS_STATUSES = PROGRESS_STATUSES;
        this.canBulkEdit = false;
        this.progressTypeSelectOptions = [{ label: NULL_SELECTED_VALUE, value: undefined }, { label: 'AG', value: 0 }, { label: 'Plus', value: 1 }];
        this.progressStatusCodesSelectOptions = Object.keys(PROGRESS_STATUSES)
            .filter(function (code) { return +code !== 210; })
            .map(function (statusCode) {
            var code = +statusCode;
            if (code === 100) {
                return [
                    { label: '1次面接設定中', value: 503 },
                    { label: '2次以降面接設定中', value: 506 },
                ];
            }
            else if (code === 110) {
                return [
                    { label: '1次面接確認中', value: 504 },
                    { label: '2次以降面接確認中', value: 507 },
                    { label: '最終前面接確認中', value: 511 },
                    { label: '最終面接確認中', value: 521 },
                ];
            }
            else if (code === 120) {
                return [
                    { label: '1次面接結果待ち', value: 505 },
                    { label: '2次以降面接結果待ち', value: 508 },
                    { label: '最終前面接結果待ち', value: 512 },
                    { label: '最終面接結果待ち', value: 522 },
                ];
            }
            return {
                label: PROGRESS_STATUSES[statusCode].label,
                value: code,
            };
        })
            .flatten();
        this.eventEditors = {};
        this.remarkEditors = {};
        this.stopReasonEditors = {};
        this.editorMeta = {};
        this.ckeckJobUsersInProgress = false;
        this.jobUserChecks = null;
        this.loadProgress = function (page, pageSize, sortingField, sortingOrder) {
            return _this.progressApiService
                .getList(cleanObject(tslib_1.__assign({}, _this.requestObject, { status: _this.requestObject ? _this.requestObject.status || [] : [], sort: sortingField, order: sortingOrder, from: page * pageSize, size: pageSize })))
                .pipe(map(function (resp) { return ({
                result: resp.progresses,
                totalSize: resp.total ? resp.total : 0,
            }); }), catchError(function (error) {
                // ネットワークに繋がらなかったときはエラーを通知する
                _this.errorGetList.emit(error);
                return EMPTY;
            }));
        };
    }
    Object.defineProperty(ProgressListComponent.prototype, "hasCheckedItems", {
        get: function () {
            return this.table && this.table.hasCheckedItems;
        },
        enumerable: true,
        configurable: true
    });
    ProgressListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refreshSubscription = this.progressApiService.refreshEvent.subscribe(function () { return _this.refreshData(true, true); });
        this.checkRoute();
        this.dateTime = this.dynamicService.getFormRows({ fieldType: 'date', fieldName: 'seminarAt', displayType: 'date+time' })[0];
        this.dateTime.fields.push({
            type: 'radio',
            name: 'seminarType',
            labelField: 'label',
            valueField: 'value',
            options: [{ label: NULL_SELECTED_VALUE, value: 1 }, { label: '最終前面接', value: 99 }, { label: '最終面接', value: 100 }],
        });
        this.baseEditorMeta = {
            groups: [
                {
                    class: 'no-title-column no-border no-background',
                    rows: [this.dateTime],
                },
            ],
        };
    };
    ProgressListComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes &&
            changes['student'] != null &&
            changes['student'].previousValue !== changes['student'].currentValue &&
            this.progressStatusSelect) {
            this.progressStatusSelect.selectAll();
        }
        if (!changes || (changes['requestObject'] && changes['requestObject'].previousValue !== changes['requestObject'].currentValue)) {
            Object.keys(this.eventEditors).concat(Object.keys(this.remarkEditors)).removeSame().forEach(function (key) { return _this.onCancelClick(+key); });
            if (this.table && this.initialized) {
                this.refreshData();
            }
        }
    };
    ProgressListComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig = PROGRESS_LIST_TABLE_CONFIG(this.statusTemplate, this.applicantTemplate, this.applicantContactTemplate, this.cellArrowTemplate, this.jobTemplate, this.jobContactTemplate, this.actionsTemplate, function (date) { return _this.datePipe.transform(date, 'yyyy/MM/dd (E) HH:mm'); });
        this.cdr.detectChanges();
        this.initialized = true;
        if (this.requestObject && this.table) {
            this.refreshData(true);
        }
        if (this.table) {
            this.itemCheckedSubscription = this.table.itemChecked.subscribe(function () { return _this.computeCanBulkEdit(); });
        }
    };
    ProgressListComponent.prototype.ngOnDestroy = function () {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        if (this.itemCheckedSubscription) {
            this.itemCheckedSubscription.unsubscribe();
        }
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    };
    ProgressListComponent.prototype.showStudentDelayBtn = function (status) {
        return status === 70 || status === 90 || status === 100;
    };
    ProgressListComponent.prototype.showSeminarDate = function (status) {
        return status === 80 || status === 90 || status === 110 || status === 120;
    };
    ProgressListComponent.prototype.showEnterpriseDocReceivedDate = function (status) {
        return status === 60;
    };
    ProgressListComponent.prototype.showOfferedDate = function (status) {
        return status === 130 || status === 140;
    };
    ProgressListComponent.prototype.showInvoiceReceivedDate = function (status) {
        return status === 150;
    };
    ProgressListComponent.prototype.showInvoiceProcessedDate = function (status) {
        return status === 160;
    };
    ProgressListComponent.prototype.getContactUser = function (userId) {
        return userId != null ? this.userApiService.getAll().pipe(map(function (users) { return users.find(function (user) { return user.id === userId; }); })) : of(null);
    };
    ProgressListComponent.prototype.getContactTeamName = function (userId) {
        var _this = this;
        return this.getContactUser(userId).pipe(concatMap(function (user) { return (user ? _this.getTeamNameForUser(user) : of(null)); }));
    };
    ProgressListComponent.prototype.getJobContactUser = function (jobUsers, type) {
        var jobUserType = type === 0 ? EnterpriseDepartmentUserType.RA : type === 1 ? EnterpriseDepartmentUserType.PA : null;
        var jobUser = jobUsers && jobUsers.length ? jobUsers.find(function (user) { return user.enterpriseDepartmentUser.type === jobUserType; }) : null;
        return jobUser && jobUser.enterpriseDepartmentUser && jobUser.enterpriseDepartmentUser.userId != null
            ? this.getContactUser(jobUser.enterpriseDepartmentUser.userId)
            : of(null);
    };
    ProgressListComponent.prototype.getJobContactTeamName = function (jobUsers, type) {
        var _this = this;
        return this.getJobContactUser(jobUsers, type).pipe(concatMap(function (user) { return (user ? _this.getTeamNameForUser(user) : of(null)); }));
    };
    ProgressListComponent.prototype.getTeamNameForUser = function (user) {
        return this.masterApiService.getTeams().pipe(map(function (teams) {
            var foundTeam = teams.find(function (team) { return team.id === user.teamId; });
            return foundTeam ? foundTeam.name : '';
        }));
    };
    ProgressListComponent.prototype.onProgressSelect = function (progress) {
        ProgressDetailComponent.instance.disableFullScreen = this.disableDetailFullScreen;
        ProgressDetailComponent.instance.referenceId = progress ? progress.id : null;
        ProgressDetailComponent.instance.open();
        this.cdr.detectChanges();
    };
    ProgressListComponent.prototype.onProgressFilterChange = function () {
        this.statusFilterChange.emit();
        this.refreshData();
    };
    ProgressListComponent.prototype.onStudentDelayClick = function (progress, event) {
        var _this = this;
        event.stopPropagation();
        this.progressApiService
            .updateWithoutAction(progress.id, {
            type: progress.type,
            hasInterview: progress.hasInterview,
            n: progress.n,
            seminarType: progress.seminarType,
            isStudentDelay: progress.isStudentDelay > 0 ? 0 : 1,
            seminarAt: progress.seminarAt,
            dynamicData: progress.dynamicData,
        })
            .subscribe(function () {
            progress.isStudentDelay = progress.isStudentDelay > 0 ? 0 : 1;
            _this.progressApiService.refreshEvent.emit();
        });
    };
    ProgressListComponent.prototype.onProgressActionClick = function (progress, action) {
        if (!progress || !action) {
            return;
        }
        switch (action.type) {
            case ProgressActionType.DELETE:
                this.onDeleteActionClick(progress.id);
                break;
            case ProgressActionType.DIALOG:
                this.onDialogActionClick(progress, action.dialog, action.id);
                break;
            case ProgressActionType.EDITOR:
                this.onEditorActionClick(progress.id, action.request ? action.request(progress, action.baseRequest) : null, action.seminarValues);
                break;
            case ProgressActionType.MAIL:
                this.onMailActionClick(progress, action.mailConfig);
                break;
            case ProgressActionType.OPERATION:
                this.onOperationActionClick(progress.id, action.request ? action.request(progress, action.baseRequest) : null);
                break;
            default:
                return;
        }
    };
    ProgressListComponent.prototype.onMailActionClick = function (progress, mailConfig) {
        var _this = this;
        if (!mailConfig) {
            return;
        }
        var mailConfigInstance = new mailConfig();
        var progressObj = {};
        if (progress) {
            if (this.eventEditors[progress.id]) {
                progressObj.progressSeminarAt = new Date(this.eventEditors[progress.id].seminarAt).toAsialDateTimeString() || progress.seminarAt;
                progressObj.progressSeminarType = this.eventEditors[progress.id].seminarType;
            }
            else {
                progressObj.progressSeminarAt = progress.seminarAt;
                progressObj.progressSeminarType = progress.seminarType;
            }
            progressObj.progressId = progress.id;
            progressObj.progressN = progress.n;
            progressObj.progressHasInterview = progress.hasInterview;
            progressObj.progressSeminarAtMailSendFlag = 1;
            progressObj.progressIsStudentDelay = progress.isStudentDelay;
            progressObj.progressType = progress.type;
            progressObj.progressStatus = progress.status;
        }
        if (mailConfigInstance instanceof ProgressRecommendConfig) {
            this.openMail(mailConfigInstance, progress, true);
        }
        else if (mailConfigInstance instanceof ProgressInrowJobPostingConfig || mailConfigInstance instanceof ProgressJobPostingConfig) {
            forkJoin(this.studentApiService.getSuggestions(undefined, [progress.studentId]), this.jobApiService.getSuggestions(undefined, [progress.jobId])).subscribe(function (_a) {
                var students = _a[0], jobs = _a[1];
                MailSendFlowComponent.instance.config = mailConfigInstance;
                mailConfigInstance.setParams(tslib_1.__assign({ students: students, jobs: jobs }, progressObj));
                MailSendFlowComponent.instance.start();
                var sentSub = MailSendFlowComponent.instance.sent.subscribe(function () {
                    _this.refreshData(true, true);
                    if (progress && _this.eventEditors[progress.id]) {
                        _this.eventEditors[progress.id] = null;
                    }
                    sentSub.unsubscribe();
                });
                var closedSub = MailSendFlowComponent.instance.closed.subscribe(function () {
                    sentSub.unsubscribe();
                    closedSub.unsubscribe();
                });
            });
        }
        else if (mailConfigInstance instanceof ProgressSendInterviewInfoConfig) {
            this.progressApiService.getStakeholders([progress.id]).subscribe(function (stakeholders) {
                if (stakeholders.length) {
                    MailSendFlowComponent.instance.config = mailConfigInstance;
                    mailConfigInstance.setParams(tslib_1.__assign({ stakeholders: stakeholders[0], progressType: progress.type, studentId: progress.studentId, jobId: progress.jobId, enterpriseId: progress.enterprise.id }, progressObj));
                    MailSendFlowComponent.instance.start();
                    if (progress.status === 70 || progress.status === 80 || progress.status === 100 || progress.status === 110) {
                        var sub_1 = MailSendFlowComponent.instance.sent.subscribe(function () {
                            _this.progressApiService
                                .updateWithAction(progress.id, {
                                type: progress.type,
                                hasInterview: progress.hasInterview,
                                n: progress.status === 70 && (progressObj.progressSeminarType === 99 || progressObj.progressSeminarType === 100)
                                    ? progress.n + 1
                                    : progress.n,
                                seminarType: progressObj.progressSeminarType === 1 && progressObj.progressStatus === 70 ? 0 : progressObj.progressSeminarType,
                                seminarAt: progressObj.progressSeminarAt,
                                isStudentDelay: progress.isStudentDelay,
                                action: progress.status === 70 && progressObj.progressSeminarType === 1
                                    ? 75
                                    : progress.status === 70 && (progressObj.progressSeminarType === 99 || progressObj.progressSeminarType === 100)
                                        ? 76
                                        : progress.status === 80
                                            ? 82
                                            : progress.status === 100
                                                ? 105
                                                : progress.status === 110
                                                    ? 112
                                                    : null,
                                seminarAtMailSendFlag: 0,
                            })
                                .subscribe(function () {
                                // this.refreshData(true, true);
                                _this.progressApiService.refreshEvent.emit();
                                if (progress && _this.eventEditors[progress.id]) {
                                    _this.eventEditors[progress.id] = null;
                                }
                            });
                            sub_1.unsubscribe();
                        });
                        var closedSub_1 = MailSendFlowComponent.instance.closed.subscribe(function () {
                            sub_1.unsubscribe();
                            closedSub_1.unsubscribe();
                        });
                    }
                }
            });
        }
        else if (mailConfigInstance instanceof ProgressSendResumeConfig) {
            if (this.authService.authInfo.zone !== 'zone3') {
                PopupControlComponent.instance.open({
                    title: null,
                    content: 'ZONE3専用です。',
                    confirmText: 'OK',
                    confirmCallback: function () { return false; },
                });
                return;
            }
            forkJoin(this.studentApiService.getSuggestions(undefined, [progress.studentId]), this.jobApiService.getSuggestions(undefined, [progress.jobId]), this.jobApiService.ckeckJobUsers([progress.jobId])).subscribe(function (_a) {
                var students = _a[0], jobs = _a[1], checkedJobs = _a[2];
                MailSendFlowComponent.instance.config = mailConfigInstance;
                mailConfigInstance.setParams(tslib_1.__assign({ student: students.length ? students[0] : [], enterpriseId: progress.enterprise.id, jobs: [tslib_1.__assign({}, jobs[0], { jobUsers: checkedJobs[0].jobUsers })] }, progressObj));
                MailSendFlowComponent.instance.start();
                var sentSub = MailSendFlowComponent.instance.sent.subscribe(function () {
                    _this.refreshData(true, true);
                    if (progress && _this.eventEditors[progress.id]) {
                        _this.eventEditors[progress.id] = null;
                    }
                    sentSub.unsubscribe();
                });
                var closedSub = MailSendFlowComponent.instance.closed.subscribe(function () {
                    sentSub.unsubscribe();
                    closedSub.unsubscribe();
                });
            });
        }
    };
    ProgressListComponent.prototype.onEditorActionClick = function (progressId, requestObject, seminarValues) {
        if (!requestObject) {
            return;
        }
        if (seminarValues) {
            this.editorMeta[progressId] = deepClone(this.baseEditorMeta);
            this.editorMeta[progressId].groups[0].rows[0].fields.last().options = seminarValues;
        }
        else {
            this.editorMeta[progressId] = this.baseEditorMeta;
        }
        this.eventEditors[progressId] = {
            seminarAt: this.datePipe.transform(new Date(), 'yyyy/MM/dd'),
            seminarType: 1,
            seminarAtMailSendFlag: false,
            requestObject: requestObject,
            inSave: false,
        };
    };
    ProgressListComponent.prototype.onOperationActionClick = function (progressId, requestObject) {
        var _this = this;
        if (!requestObject) {
            return;
        }
        this.progressApiService.updateWithAction(progressId, requestObject).subscribe(function (resp) {
            // this.refreshData(true, true);
            _this.progressApiService.refreshEvent.emit();
        });
    };
    ProgressListComponent.prototype.onDialogActionClick = function (progress, dialog, actionId) {
        var _this = this;
        if (!dialog) {
            return;
        }
        var dataObj = {};
        if (actionId === 140) {
            this.progressApiService.getContractInfo(progress.id).subscribe(function (info) {
                if (info.approvedContractIds.length && info.enterpriseBillingAddressIds.length) {
                    dataObj = {
                        progressId: progress.id,
                        enterpriseId: progress.enterprise.id,
                        progressType: progress.type,
                        jobUsers: progress.job.jobUsers,
                        caUserId: progress.student.caUserId,
                    };
                    var dRef = _this.dialog.open(dialog, { data: dataObj });
                    var dCloseSubscription_1 = dRef.afterClosed.subscribe(function (res) {
                        if (res) {
                            _this.refreshData(true, true);
                        }
                        dCloseSubscription_1.unsubscribe();
                    });
                }
                else {
                    PopupControlComponent.instance.open({
                        title: '必要な情報が入力されていません',
                        content: '承認済みの契約が登録されていないか、\n請求先が登録されていません。',
                        confirmText: '閉じる',
                        confirmCallback: function () { return false; },
                    });
                }
            });
            return;
        }
        else if (actionId === 74) {
            dataObj = {
                studentIds: [progress.studentId],
                jobId: progress.jobId,
                progressStatus: progress.status,
                progressN: progress.n,
                progressIds: [progress.id],
                progressType: progress.type,
                title: dialog === ProgressScheduleAdjustDialogComponent ? '求職者へ日程調整送信' : null,
            };
        }
        var dialogRef = this.dialog.open(dialog, { data: dataObj });
        var dialogCloseSubscription = dialogRef.afterClosed.subscribe(function (res) {
            if (res) {
                _this.refreshData(true, true);
            }
            dialogCloseSubscription.unsubscribe();
        });
    };
    ProgressListComponent.prototype.onDeleteActionClick = function (progressId) {
        var _this = this;
        this.progressApiService.delete(progressId).subscribe(function () { return _this.progressApiService.refreshEvent.emit(RefreshEvent.DELETE); });
    };
    ProgressListComponent.prototype.onSaveClick = function (progress) {
        var _this = this;
        if (this.eventEditors[progress.id] && this.eventEditors[progress.id].inSave) {
            return;
        }
        var eventRequestObject = null;
        var remarksRequestObject = null;
        var remarksRequest = null;
        var eventRequest = null;
        if (this.remarkEditors[progress.id]) {
            var remarks = this.remarkEditors[progress.id].remarks || '';
            remarksRequestObject = { remarks: remarks };
            remarksRequest = this.progressApiService.updateHistoryRemarks(progress.id, progress.latestRevision, remarksRequestObject);
        }
        if (this.eventEditors[progress.id]) {
            this.eventEditors[progress.id].inSave = true;
            var event_1 = this.eventEditors[progress.id];
            if (event_1.requestObject && event_1.seminarAt) {
                eventRequestObject = tslib_1.__assign({}, event_1.requestObject, {
                    seminarAt: new Date(event_1.seminarAt).toAsialDateTimeString(),
                    seminarType: event_1.seminarType || 1,
                    seminarAtMailSendFlag: event_1.seminarAtMailSendFlag ? 0 : 1,
                });
                if (progress.status === 70) {
                    if (eventRequestObject.seminarType === 1) {
                        eventRequestObject.seminarType = 0;
                        eventRequestObject.action = 72;
                        eventRequestObject.n = progress.n;
                    }
                    else if (eventRequestObject.seminarType === 99 || eventRequestObject.seminarType === 100) {
                        eventRequestObject.action = 74;
                        eventRequestObject.n = (progress.n || 0) + 1;
                    }
                }
                eventRequest = this.progressApiService.updateWithAction(progress.id, eventRequestObject);
                if (remarksRequest) {
                    remarksRequest = this.progressApiService.updateHistoryRemarks(progress.id, (progress.latestRevision || 0) + 1, remarksRequestObject);
                }
            }
            if (!event_1.seminarAtMailSendFlag) {
                this.progressApiService.getStakeholders([progress.id]).subscribe(function (stakeholders) {
                    var stakeholder = stakeholders && stakeholders.length ? stakeholders[0] : null;
                    if (stakeholders && stakeholder.studentInfo.mainAvailable === '不可') {
                        PopupControlComponent.instance.open({
                            title: 'エラー',
                            content: 'E-MAIL(メイン)が「送信不可」です。',
                            confirmText: '閉じる',
                            confirmCallback: function () {
                                _this.eventEditors[progress.id].inSave = false;
                            },
                        });
                    }
                    else if (stakeholder) {
                        _this.mailApiService
                            .getMailTemplates()
                            .pipe(map(function (templates) {
                            return templates.find(function (template) { return template.id === (progress.type === 0 ? 14 : progress.type === 1 ? 19 : -1); });
                        }))
                            .subscribe(function (template) {
                            if (template) {
                                var mailRequestObject_1 = {
                                    to: stakeholder.studentInfo.mainAvailable !== '不可' ? [stakeholder.studentInfo.main] : null,
                                    cc: [
                                        stakeholder.studentInfo.subAvailable !== '不可' ? stakeholder.studentInfo.sub : null
                                    ].concat(stakeholder.jobUsersInfo.filter(function (jobUser) { return jobUser.type === 0; }).map(function (jobUser) { return jobUser.email; })).filter(function (v) { return v; }),
                                    bcc: stakeholder.jobUsersInfo
                                        .filter(function (jobUser) {
                                        return jobUser.type ===
                                            (progress.type === 0
                                                ? EnterpriseDepartmentUserType.RA
                                                : progress.type === 1
                                                    ? EnterpriseDepartmentUserType.PA
                                                    : -1);
                                    })
                                        .map(function (jobUser) { return jobUser.email; }).slice().filter(function (v) { return v; }),
                                    userId: _this.authService.loginUser.id,
                                    from: _this.authService.loginUser.email,
                                    subject: template.subject,
                                    text: template.body,
                                    templateTypeId: 8,
                                    jobId: progress.jobId,
                                };
                                var mailRequest_1 = _this.mailApiService
                                    .replaceTemplate(mailRequestObject_1.templateTypeId, {
                                    from: mailRequestObject_1.from,
                                    to: mailRequestObject_1.to,
                                    subject: mailRequestObject_1.subject,
                                    text: mailRequestObject_1.text,
                                    studentId: progress.studentId,
                                    jobId: progress.jobId,
                                })
                                    .pipe(mergeMap(function (res) {
                                    mailRequestObject_1.subject = res.subject;
                                    mailRequestObject_1.text = res.body;
                                    return _this.mailApiService.sendStudentMail(progress.studentId, mailRequestObject_1);
                                }));
                                if (eventRequest && remarksRequest) {
                                    eventRequest = eventRequest.pipe(mergeMap(function () { return remarksRequest.pipe(mergeMap(function () { return mailRequest_1; })); }));
                                }
                                else if (remarksRequest) {
                                    eventRequest = remarksRequest.pipe(mergeMap(function () { return mailRequest_1; }));
                                }
                                else {
                                    eventRequest = eventRequest.pipe(mergeMap(function () { return mailRequest_1; }));
                                }
                                eventRequest.subscribe(function () {
                                    _this.refreshData(true, true);
                                    _this.eventEditors[progress.id].inSave = false;
                                    _this.onCancelClick(progress.id);
                                }, function () {
                                    _this.eventEditors[progress.id].inSave = false;
                                });
                            }
                            else {
                                _this.eventEditors[progress.id].inSave = false;
                            }
                        }, function () {
                            _this.eventEditors[progress.id].inSave = false;
                        });
                    }
                    else {
                        _this.eventEditors[progress.id].inSave = false;
                    }
                }, function () {
                    _this.eventEditors[progress.id].inSave = false;
                });
            }
            else if (eventRequest || remarksRequest) {
                if (eventRequest && remarksRequest) {
                    eventRequest = eventRequest.pipe(mergeMap(function () { return remarksRequest; }));
                }
                else if (remarksRequest) {
                    eventRequest = remarksRequest;
                }
                eventRequest.subscribe(function () {
                    // this.refreshData(true, true);
                    _this.progressApiService.refreshEvent.emit();
                    _this.eventEditors[progress.id].inSave = false;
                    _this.onCancelClick(progress.id);
                });
            }
            else {
                this.eventEditors[progress.id].inSave = false;
            }
        }
        else {
            remarksRequest.subscribe(function () {
                _this.refreshData(true, true);
                _this.onCancelClick(progress.id);
            });
        }
    };
    ProgressListComponent.prototype.onStopReasonSaveClick = function (progress) {
        var _this = this;
        if (!this.stopReasonEditors[progress.id]) {
            this.onCancelClick(progress.id);
            return;
        }
        var stopReasonText = this.stopReasonEditors[progress.id].stopReason || '';
        this.progressApiService
            .updateWithoutAction(progress.id, {
            type: progress.type,
            hasInterview: progress.hasInterview,
            n: progress.n,
            seminarType: progress.seminarType,
            seminarAt: progress.seminarAt != null ? new Date(progress.seminarAt).toAsialDateTimeString() : null,
            isStudentDelay: progress.isStudentDelay,
            dynamicData: tslib_1.__assign({}, progress.dynamicData, { stopReason: stopReasonText }),
        })
            .subscribe(function () {
            _this.refreshData(true, true);
            _this.onCancelClick(progress.id);
        }, function (error) {
            var dialogConfig = ConfirmDialogConfig.createErrorDialog(error);
            _this.dialog.open(ConfirmDialogComponent, dialogConfig);
        });
    };
    ProgressListComponent.prototype.onCancelClick = function (progressId) {
        if (this.eventEditors[progressId]) {
            delete this.eventEditors[progressId];
        }
        if (this.remarkEditors[progressId]) {
            delete this.remarkEditors[progressId];
        }
        if (this.stopReasonEditors[progressId]) {
            delete this.stopReasonEditors[progressId];
        }
    };
    ProgressListComponent.prototype.editRemarks = function (progressId, remarks) {
        this.remarkEditors[progressId] = {
            remarks: remarks,
            edit: true,
            show: true,
        };
    };
    ProgressListComponent.prototype.toggleRemarksDisplay = function (progressId) {
        if (!this.remarkEditors[progressId]) {
            this.remarkEditors[progressId] = {
                remarks: '',
                edit: false,
                show: true,
            };
            return;
        }
        this.remarkEditors[progressId].show = !this.remarkEditors[progressId].show;
    };
    ProgressListComponent.prototype.editStopReason = function (progressId, stopReason) {
        this.stopReasonEditors[progressId] = {
            stopReason: stopReason,
            edit: true,
            show: true,
        };
    };
    ProgressListComponent.prototype.toggleStopReasonDisplay = function (progressId) {
        if (!this.stopReasonEditors[progressId]) {
            this.stopReasonEditors[progressId] = {
                stopReason: '',
                edit: false,
                show: true,
            };
            return;
        }
        this.stopReasonEditors[progressId].show = !this.stopReasonEditors[progressId].show;
    };
    ProgressListComponent.prototype.refreshData = function (dataOnly, noScroll) {
        this.table.reset(dataOnly, noScroll);
        this.table.next();
        this.computeCanBulkEdit();
    };
    ProgressListComponent.prototype.isValidSelectedItemsForBulkScheduleAdjust = function () {
        var selectedProgresses = this.table && this.table.checkedItems;
        if (!selectedProgresses || !selectedProgresses.length) {
            return false;
        }
        if (selectedProgresses.length === 1 && (selectedProgresses[0].status === 70 || selectedProgresses[0].status === 100)) {
            return true;
        }
        var isJobIdSame = selectedProgresses
            .map(function (progress) { return progress.jobId; })
            .every(function (jobId) { return jobId === selectedProgresses[0].jobId; });
        var isStatusSame = selectedProgresses
            .map(function (progress) { return progress.status; })
            .every(function (status) {
            return status === selectedProgresses[0].status && (selectedProgresses[0].status === 70 || selectedProgresses[0].status === 100);
        });
        var isNSame = true;
        if (selectedProgresses[0].status === 100) {
            isNSame = selectedProgresses.map(function (progress) { return progress.n; }).every(function (n) { return n === selectedProgresses[0].n; });
        }
        return isJobIdSame && isStatusSame && isNSame;
    };
    ProgressListComponent.prototype.openProgressBulkScheduleAdjustDialog = function () {
        var _this = this;
        if (!this.isValidSelectedItemsForBulkScheduleAdjust()) {
            return;
        }
        var selectedProgresses = this.table && this.table.checkedItems;
        if (!selectedProgresses || !selectedProgresses.length) {
            return;
        }
        var dialogRef = this.dialog.open(ProgressScheduleAdjustDialogComponent, {
            data: {
                studentIds: selectedProgresses.map(function (progress) { return progress.studentId; }),
                jobId: selectedProgresses[0].jobId,
                progressStatus: selectedProgresses[0].status,
                progressN: selectedProgresses[0].n,
                progressSeminarType: selectedProgresses[0].seminarType,
                progressIds: selectedProgresses.map(function (progress) { return progress.id; }),
                progressType: selectedProgresses[0].type,
            },
        });
        var dialogCloseSubscription = dialogRef.afterClosed.subscribe(function (res) {
            if (res) {
                _this.refreshData(true, true);
            }
            dialogCloseSubscription.unsubscribe();
        });
    };
    ProgressListComponent.prototype.openProgressBulkDeclineDialog = function () {
        var _this = this;
        if (!this.hasCheckedItems) {
            return;
        }
        var config = {
            title: '一括辞退',
            messages: ['一括辞退をしてもよろしいですか'],
            style: {
                height: '245px',
                width: '600px',
            },
            buttons: {
                no: 'キャンセル',
                yes: 'OK',
            },
        };
        this.dialog.open(ConfirmDialogComponent, config, function (result) {
            if (result) {
                return _this.progressApiService
                    .bulkDecline(_this.table.checkedItems.map(function (item) { return (item && item['id'] ? item['id'] : null); }).filter(function (v) { return v != null; }))
                    .pipe(tap(function () {
                    // this.refreshData(true, true);
                    _this.progressApiService.refreshEvent.emit();
                }));
            }
        });
    };
    ProgressListComponent.prototype.openProgressBulkEditDialog = function () {
        var _this = this;
        if (!this.checkCanBulkEdit()) {
            return;
        }
        var config = {
            data: {
                options: deepClone(PROGRESS_BULK_EDIT[this.table.checkedItems[0].status]),
            },
        };
        this.dialog.open(BulkProgressChangeDialogComponent, config, function (result) {
            if (result) {
                _this.openProgressBulkEditConfirmationDialog(result);
            }
        });
    };
    ProgressListComponent.prototype.openProgressBulkEditConfirmationDialog = function (result) {
        var _this = this;
        var config = {
            title: '一括変更',
            messages: ["\u9078\u629E\u3057\u305F\u5168\u3066\u306E\u9032\u6357\u30B9\u30C6\u30FC\u30BF\u30B9\u3092\u300C" + result.label(undefined, 1) + "\u300D\u3078\u4E00\u62EC\u3067\u5909\u66F4\u3057\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"],
            style: {
                height: '245px',
                width: '600px',
            },
            buttons: {
                no: 'キャンセル',
                yes: 'OK',
            },
        };
        this.dialog.open(ConfirmDialogComponent, config, function (confirmed) {
            if (!confirmed) {
                return;
            }
            var progressIds = _this.table.checkedItems.map(function (item) { return (item && item['id'] ? item['id'] : null); }).filter(function (v) { return v != null; });
            var request = {
                toStatus: result.toStatus,
                fromStatus: result.fromStatus,
                stopReason: result.stopReason,
                remarks: result.remarks,
            };
            return _this.progressApiService.bulkEdit(progressIds, request).pipe(tap(function () {
                _this.canBulkEdit = false;
                _this.refreshData(true, true);
                _this.progressApiService.refreshEvent.emit();
            }));
        });
    };
    ProgressListComponent.prototype.checkCanBulkEdit = function () {
        if (!this.hasCheckedItems) {
            return false;
        }
        var status = this.table.checkedItems[0].status;
        return Object.keys(PROGRESS_BULK_EDIT).includes('' + status) && this.checkedItemsStatusIs(status);
    };
    ProgressListComponent.prototype.directTransition = function (progress, transition) {
        var _this = this;
        var requestDto = {
            fromStatus: transition.fromStatus,
            toStatus: transition.toStatus,
            remarks: transition.remarks,
            stopReason: transition.stopReason,
        };
        var directSubs = this.progressApiService.bulkEdit([progress.id], requestDto).subscribe(function () {
            _this.refreshData(true, true);
            _this.progressApiService.refreshEvent.emit();
            directSubs.unsubscribe();
        });
    };
    ProgressListComponent.prototype.checkRoute = function () {
        if (this.router.url.endsWith('/progresses/add')) {
            this.openProgressCreateDialog();
        }
    };
    ProgressListComponent.prototype.checkedItemsStatusIs = function (status) {
        return this.table ? this.table.checkedItems.every(function (i) { return i.status === status; }) : false;
    };
    ProgressListComponent.prototype.openProgressCreateDialog = function (origUrl) {
        var _this = this;
        if (origUrl === void 0) { origUrl = this.location.path(); }
        if (this.dialogRef) {
            return;
        }
        this.location.go('/progresses/add');
        ProgressDetailComponent.instance.close();
        this.dialogRef = this.dialog.open(ProgressCreateDialogComponent, {
            data: {
                studentId: this.student ? this.student.id : null,
                jobId: this.job ? this.job.id : null,
                enterpriseId: this.requestObject ? this.requestObject.enterpriseId : null,
            },
        });
        var closeSubscription;
        var navigationSubscription;
        var unsubscribe = function () {
            if (closeSubscription) {
                closeSubscription.unsubscribe();
            }
            if (navigationSubscription) {
                navigationSubscription.unsubscribe();
            }
        };
        closeSubscription = this.dialogRef.afterClosed.subscribe(function () {
            _this.location.go(origUrl);
            _this.dialogRef = null;
            unsubscribe();
        });
        navigationSubscription = this.location.subscribe(function () {
            _this.dialogRef.close();
            unsubscribe();
        });
    };
    ProgressListComponent.prototype.openMail = function (config, progress, refreshProgressList) {
        var _this = this;
        var jobIds = [];
        var studentIds = [];
        var enterprises = [];
        var progressIds = [];
        var students = [];
        var jobs = [];
        if (progress) {
            progressIds.push(progress.id);
            jobIds.push(progress.jobId);
            studentIds.push(progress.studentId);
            enterprises.push({ frontId: progress.enterprise.frontId, id: progress.enterprise.id, name: progress.enterprise.name });
        }
        else {
            this.table.checkedItems.forEach(function (item) {
                if (!(config instanceof ProgressNgEndConfig) || item.status === 170) {
                    progressIds.push(item.id);
                    jobIds.push(item.jobId);
                    studentIds.push(item.studentId);
                    enterprises.push({ frontId: item.enterprise.frontId, id: item.enterprise.id, name: item.enterprise.name });
                }
            });
        }
        var obs = [];
        if (this.student) {
            students.push({
                id: this.student.id,
                frontId: this.student.frontId,
                emailMain: getValueFromObject(this.student.dynamicData, 'emailMain.email'),
                emailMainAvailable: getValueFromObject(this.student.dynamicData, 'emailMain.emailAvailable'),
                emailSub: getValueFromObject(this.student.dynamicData, 'emailSub.email'),
                emailSubAvailable: getValueFromObject(this.student.dynamicData, 'emailSub.emailAvailable'),
                studentUser: this.student.studentUsers,
                firstName: this.student.dynamicData.firstName,
                lastName: this.student.dynamicData.lastName,
            });
        }
        else {
            obs.push(this.studentApiService.getSuggestions(undefined, studentIds.removeSame()).pipe(tap(function (res) { return (students = res); })));
        }
        if (this.job && !(config instanceof ProgressRecommendConfig)) {
            jobs.push({
                id: this.job.id,
                frontId: this.job.frontId,
                position: getValueFromObject(this.job.dynamicData, 'position'),
                enterpriseName: this.job.enterpriseName,
                enterpriseId: this.job.enterpriseId,
                frontEnterpriseId: this.job.frontEnterpriseId,
                enterpriseDepartmentId: this.job.enterpriseDepartmentId,
                jobUsers: this.job.enterpriseDepartmentUsers,
            });
        }
        else {
            obs.push(this.jobApiService.getSuggestions(undefined, jobIds.removeSame()).pipe(tap(function (res) { return (jobs = res); })));
        }
        var progressObj = {};
        if (progress) {
            if (this.eventEditors[progress.id]) {
                progressObj.progressSeminarAt = new Date(this.eventEditors[progress.id].seminarAt).toAsialDateTimeString();
                progressObj.progressSeminarType = this.eventEditors[progress.id].seminarType;
            }
            else {
                progressObj.progressSeminarAt = progress.seminarAt;
                progressObj.progressSeminarType = progress.seminarType;
            }
            progressObj.progressId = progress.id;
            progressObj.progressN = progress.n;
            progressObj.progressHasInterview = progress.hasInterview;
            progressObj.progressSeminarAtMailSendFlag = 1;
            progressObj.progressIsStudentDelay = progress.isStudentDelay;
            progressObj.progressType = progress.type;
            progressObj.progressStatus = progress.status;
        }
        forkJoin(obs).subscribe(function () {
            MailSendFlowComponent.instance.config = config;
            config.setParams(tslib_1.__assign({ progressIds: progressIds, students: students, jobs: jobs, jobId: _this.job ? _this.job.id : null, enterprises: enterprises.removeSame('id') }, progressObj));
            MailSendFlowComponent.instance.start();
            if (refreshProgressList) {
                var sentSub_1 = MailSendFlowComponent.instance.sent.subscribe(function () {
                    _this.refreshData(true, true);
                    if (progress && _this.eventEditors[progress.id]) {
                        _this.eventEditors[progress.id] = null;
                    }
                    sentSub_1.unsubscribe();
                });
                var closedSub_2 = MailSendFlowComponent.instance.closed.subscribe(function () {
                    sentSub_1.unsubscribe();
                    closedSub_2.unsubscribe();
                });
            }
        });
    };
    ProgressListComponent.prototype.onStudentLinkClick = function (event, studentId) {
        this.onLinkClick(event, StudentDetailPageComponent.instance, studentId);
    };
    ProgressListComponent.prototype.onCompanyLinkClick = function (event, companyId) {
        this.onLinkClick(event, CompanyDetailPageComponent.instance, companyId);
    };
    ProgressListComponent.prototype.onJobLinkClick = function (event, jobId) {
        this.onLinkClick(event, JobDetailPageComponent.instance, jobId);
    };
    ProgressListComponent.prototype.onLinkClick = function (event, detail, id) {
        event.stopPropagation();
        detail.referenceId = id;
        detail.open();
    };
    ProgressListComponent.prototype.computeCanBulkEdit = function () {
        this.canBulkEdit = this.checkCanBulkEdit();
    };
    return ProgressListComponent;
}());
export { ProgressListComponent };
