import * as tslib_1 from "tslib";
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CounselingApiService = /** @class */ (function (_super) {
    tslib_1.__extends(CounselingApiService, _super);
    function CounselingApiService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    CounselingApiService.prototype.getSeminarsForStudent = function (studentId) {
        return this.http.get("/api/seminar/student/" + studentId);
    };
    CounselingApiService.prototype.deleteSeminarForStudent = function (seminarId, studentId) {
        return this.http.delete("/api/seminar/student/" + studentId + "/seminar/" + seminarId);
    };
    CounselingApiService.prototype.addSeminarForStudent = function (studentId, seminarId) {
        return this.http.post("/api/seminar/student/" + studentId, { seminarId: seminarId });
    };
    CounselingApiService.prototype.searchSeminarsForStudent = function (studentId, params) {
        return this.http.get("/api/seminar/student/" + studentId + "/search", {
            params: toAsialRequestParameters(params),
        });
    };
    CounselingApiService.prototype.getSeminarJobsForStudent = function (studentId, seminarId) {
        return this.http.get("/api/seminar/student/" + studentId + "/seminar/" + seminarId + "/job");
    };
    CounselingApiService.prototype.addSeminarJobsForStudent = function (studentId, seminarId, jobId) {
        return this.http.post("/api/seminar/student/" + studentId + "/seminar/" + seminarId + "/job", { jobId: jobId });
    };
    CounselingApiService.prototype.deleteSeminarJobsForStudent = function (studentId, seminarId, jobId) {
        return this.http.delete("/api/seminar/student/" + studentId + "/seminar/" + seminarId + "/job/" + jobId);
    };
    CounselingApiService.prototype.updateSeminarJobApplicationsForStudent = function (studentId, seminarId, requestObj) {
        return this.http.put("/api/seminar/student/" + studentId + "/seminar/" + seminarId + "/application", requestObj);
    };
    CounselingApiService.prototype.getSeminars = function (area, startDate, endDate) {
        return this.http.get('/api/seminar', {
            params: {
                area: area,
                startDate: new Date(startDate).toAsialDateString(true),
                endDate: new Date(endDate).toAsialDateString(true),
            },
        });
    };
    CounselingApiService.prototype.addSeminar = function (requestObj) {
        return this.http.post('/api/seminar', requestObj);
    };
    CounselingApiService.prototype.deleteSeminar = function (seminarId) {
        return this.http.delete("/api/seminar/" + seminarId);
    };
    CounselingApiService.prototype.deleteSeminars = function (seminarIds) {
        return this.http.request('delete', '/api/seminar', {
            params: toAsialRequestParameters({ seminarIds: seminarIds }),
        });
    };
    CounselingApiService.prototype.getSeminarMasterJobs = function (areaId) {
        return this.http.get("/api/seminar/job-master/" + areaId);
    };
    CounselingApiService.prototype.addSeminarMasterJob = function (areaId, jobId) {
        return this.http.post("/api/seminar/job-master/" + areaId, { jobId: jobId });
    };
    CounselingApiService.prototype.deleteSeminarMasterJob = function (areaId, jobId) {
        return this.http.delete("/api/seminar/job-master/" + areaId + "/" + jobId);
    };
    CounselingApiService.prototype.deleteSeminarMasterJobs = function (areaId, jobIds) {
        return this.http.request('delete', "/api/seminar/job-master/" + areaId, {
            params: toAsialRequestParameters({ jobIds: jobIds }),
        });
    };
    CounselingApiService.prototype.getSeminarJobs = function (seminarId) {
        return this.http.get("/api/seminar/" + seminarId + "/job");
    };
    CounselingApiService.prototype.addSeminarJob = function (seminarId, jobId) {
        return this.http.post("/api/seminar/" + seminarId + "/job", { jobId: jobId });
    };
    CounselingApiService.prototype.deleteSeminarJob = function (seminarId, jobId) {
        return this.http.delete("/api/seminar/" + seminarId + "/job/" + jobId);
    };
    CounselingApiService.prototype.deleteSeminarJobs = function (seminarId, jobIds) {
        return this.http.request('delete', "/api/seminar/" + seminarId + "/job", {
            params: toAsialRequestParameters({ jobIds: jobIds }),
        });
    };
    CounselingApiService.prototype.getStudentsForSeminar = function (seminarId) {
        return this.http.get("/api/seminar/" + seminarId + "/student");
    };
    CounselingApiService.prototype.addStudentsThanksMailForSeminar = function (seminarId, requestObj) {
        return this.http.post("/api/seminar/" + seminarId + "/student/thanks-mail", requestObj);
    };
    CounselingApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CounselingApiService_Factory() { return new CounselingApiService(i0.ɵɵinject(i1.HttpClient)); }, token: CounselingApiService, providedIn: "root" });
    return CounselingApiService;
}(ApiService));
export { CounselingApiService };
