<div class="content">
  <div class="content__controls">
    <input
      class="content__controls__select-all"
      type="checkbox"
      [checked]="table?.allChecked"
      (click)="table?.checkAll(table.allChecked)"
      *ngIf="options?.body?.checkbox"
    />
    <span class="content__controls__count">{{
      table?.checkedItems.length || table?.totalSize
    }}</span>
    <span class="content__controls__label">{{
      table?.checkedItems.length ? "件選択中" : "件"
    }}</span>

    <button
      [disabled]="!hasCheckedItems"
      class="controls__control btn btn--gray btn--larger"
      (click)="openSalesLinkBtoBDialog()"
    >
      請求連携
    </button>

    <div
      class="content__controls__refresh"
      (click)="table.reset(true); table.next()"
    >
      <div class="content__controls__refresh-icon">
        <svg class="content__controls__refresh--svg">
          <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
        </svg>
      </div>
      最新情報に更新
    </div>
  </div>
  <ag-page-scroll-table
    class="content__table"
    [tableConfig]="tableConfig"
    [supplier]="loadList"
    (itemSelected)="onSaleSelect($event)"
  ></ag-page-scroll-table>
</div>

<ng-template #ag_t let-data>
  <div
    class="basic-holder"
    *ngIf="data.type === 0 || data.type === 1"
    class="status__type"
  >
    <span
      class="basic-holder__type-badge"
      [class.basic-holder__type-badge--AG]="data.type === 0 || !data.type"
      [class.basic-holder__type-badge--Plus]="data.type === 1"
    >
    </span>
  </div>
</ng-template>

<ng-template #row_header_company_t let-data>
  <div>
    <p class="link-holder">
      企業名:&nbsp;
      <span
        class="link"
        (click)="onCompanyLinkClick($event, data?.enterprise?.id)"
      >
        {{ data?.enterprise?.frontId }}-{{
          data?.enterprise?.dynamicData?.name
        }}
      </span>
    </p>
    <p class="link-holder">
      求人:&nbsp;
      <span class="link" (click)="onJobLinkClick($event, data?.jobId)"
        >{{ data?.jobFrontId }}-{{ data?.jobPosition }}
      </span>
    </p>
  </div>
</ng-template>

<ng-template #row_header_job_seeker_t let-data>
  <div>
    <p class="link-holder">
      求職者:&nbsp;
      <span
        class="link"
        (click)="onStudentLinkClick($event, data?.student?.id)"
      >
        {{ data?.student?.frontId }}-
        {{ data?.student?.dynamicData?.lastName }}
        {{ data?.student?.dynamicData?.firstName }} </span
      >&nbsp;
      <span
        *ngIf="data?.student?.dynamicData?.academicFieldType"
        class="badge badge--big"
        [class.badge--pink]="
          data.student.dynamicData.academicFieldType[0] === '文'
        "
        [class.badge--blue]="
          data.student.dynamicData.academicFieldType[0] === '理'
        "
      >
        {{ data.student.dynamicData.academicFieldType[0] }}
      </span>
    </p>
  </div>
</ng-template>

<ng-template #responsible_persons_t let-data>
  <p *ngFor="let resp of data.users">
    <ng-container *ngIf="resp.id">
      <span
        class="badge"
        [agClass]="resp.area"
        [classOptions]="RAPACA_VALUE_BADGE_CLASSES"
        >{{ resp.area }}</span
      >&nbsp;
      <span>{{ resp.name }} {{ resp.teamName }}</span>
    </ng-container>
  </p>
</ng-template>
