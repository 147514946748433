import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupType } from '../config';
import { StudentBulkConfig } from './student-bulk-config';
var StudentJobConfig = /** @class */ (function (_super) {
    tslib_1.__extends(StudentJobConfig, _super);
    function StudentJobConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StudentJobConfig.prototype.getTemplateTypeId = function () {
        return [2];
    };
    StudentJobConfig.prototype.getTemplateId = function () {
        return 1;
    };
    StudentJobConfig.prototype.getPopupConfig = function () {
        return [{ type: MailPopupType.JOB, title: '一斉打診', message: '一斉打診する求人を選択してください。' }];
    };
    StudentJobConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.jobs = params.jobs || [];
            this.sender.model.jobId = params.jobId;
            if (this.sender.model.jobs && this.sender.model.jobs.length) {
                this.sender.model.enterprises = this.sender.model.jobs.map(function (j) { return ({
                    id: j.enterpriseId,
                    frontId: j.frontEnterpriseId,
                    name: j.enterpriseName,
                }); });
            }
        }
        _super.prototype.setParams.call(this, params);
    };
    StudentJobConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.sender.model.students) {
            return;
        }
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        metaData.groups[0].rows.push({
            title: 'To (求職者)',
            showRequired: true,
            fields: [
                {
                    name: 'to',
                    type: 'label',
                    supplier: function () {
                        return _this.sender
                            ? _this.sender.model.students
                                .map(function (s) {
                                return s.emailMainAvailable
                                    ? "\u3010\u9001\u4FE1\u4E0D\u53EF\u3011" + (s.lastName + s.firstName) + " \u30E1\u30A4\u30F3 <" + s.emailMain + ">"
                                    : s.lastName + s.firstName + " \u30E1\u30A4\u30F3 <" + s.emailMain + ">";
                            })
                                .join(', ')
                            : '';
                    },
                },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (求職者、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            row.fields.unshift({
                name: 'cc_label',
                type: 'label',
                default: '※E-Mailサブにも送信',
            }, {
                name: 'cc-hr',
                type: 'hr',
                class: 'full',
            }, {
                name: 'cc2_label',
                type: 'label',
                default: '※求職者担当にも送信',
            });
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                // displayType: 'email',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.subject : undefined;
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return _this.sender.model.subject; },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = {
                title: '添付ファイル',
                fields: [
                    {
                        type: 'label',
                        name: 'jobs',
                        linkTo: ['enterpriseFileIds'],
                        supplier: function () { return _this.sender.model.jobs.map(function (j) { return j.frontEnterpriseId + " " + j.enterpriseName; }).join(', '); },
                    },
                ],
            };
            row.class = 'ou';
            if (!_this.sender.inConfirm) {
                row.fields[0].labelBefore = '選択した企業(求人)：';
                row.fields[0].linkTo = ['enterpriseFileIds'];
            }
            else {
                row.fields.length = 0;
            }
            if (!_this.sender.inConfirm || _this.sender.model.enterpriseFileIds.length) {
                row.fields.push({
                    name: 'enterpriseFileIds',
                    type: 'autocomplete',
                    labelField: [
                        {
                            name: 'name',
                            class: _this.sender.inConfirm ? 'link' : null,
                            action: _this.sender.inConfirm
                                ? function (file) {
                                    return _this.sender.fileService.downloadFile('enterprise', +Object.keys(_this.sender.enterpriseFiles).find(function (key) { return _this.sender.enterpriseFiles[key].find(function (f) { return f.id === file.id; }) != null; }), file.id, file.name);
                                }
                                : null,
                        },
                    ],
                    labelBefore: '企業：',
                    valueField: 'id',
                    multi: true,
                    class: 'options-only half',
                    options: [],
                    actions: [
                        {
                            title: 'データフォルダから選択',
                            type: 'RUNNABLE',
                            runnable: function () {
                                _this.sender.enterpriseFileIds = _this.sender.model.enterpriseFileIds.slice();
                                PopupControlComponent.instance.open({
                                    content: _this.sender.fileSelectTemplate,
                                    confirmText: '添付',
                                    cancelText: 'キャンセル',
                                    title: 'データフォルダ',
                                    confirmCallback: function () {
                                        _this.sender.model.enterpriseFileIds = _this.sender.enterpriseFileIds.slice();
                                    },
                                });
                            },
                        },
                    ],
                    supplier: function (value, callType, getValue) {
                        _this.sender.enterpriseFiles = {};
                        _this.sender.model.jobs.forEach(function (j) { return (_this.sender.enterpriseInfoById[j.enterpriseId] = j.frontEnterpriseId + " " + j.enterpriseName); });
                        return forkJoin(_this.sender.model.jobs.map(function (j) {
                            return _this.sender.fileService.getFiles('enterprise', j.enterpriseId).pipe(map(function (res) {
                                _this.sender.enterpriseFiles[j.enterpriseId] = res.filter(function (f) { return f.studentSendFlag; });
                                return { options: _this.sender.enterpriseFiles[j.enterpriseId] };
                            }));
                        })).pipe(map(function (res) { return ({ options: Object.values(_this.sender.enterpriseFiles).flatten() }); }));
                    },
                });
            }
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.body : undefined;
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }].concat(res);
                                    return {
                                        value: value ? res.find(function (s) { return s.id === value.id; }) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        if (!this.sender.inConfirm) {
            metaData.groups.unshift({
                title: '選択した求人',
                class: 'no-title-column',
                rows: [
                    {
                        fields: this.sender.model.jobs.map(function (job, index) { return ({
                            name: 'jobs' + index,
                            type: 'textarea',
                            class: 'quarter',
                            disabled: true,
                            supplier: function () { return job.frontId + "\n" + job.position + "\n" + job.enterpriseName; },
                        }); }),
                    },
                ],
            });
            metaData.groups[0].rows[0].fields.push({
                type: 'label',
                name: 'dummy',
                actions: [
                    {
                        type: 'RUNNABLE',
                        title: '求人を再選択',
                        runnable: function () { return _this.sender.showPopup.emit(MailPopupType.JOB); },
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    StudentJobConfig.prototype.save = function (toSave) {
        var _this = this;
        toSave.jobIds = this.sender.model.jobs.map(function (j) { return j.id; });
        this.sender.progressApiService.checkProgress(this.sender.model.students.map(function (s) { return s.id; }), toSave.jobIds).subscribe(function (res) {
            if (res && res.length) {
                _this.sender.progressErrorInfo = res;
                PopupControlComponent.instance.open({
                    cancelText: '中止',
                    cancelCallback: function () { return _this.sender.close(); },
                    confirmText: null,
                    content: _this.sender.progressErrorTemplate,
                    title: '進捗重複',
                    confirmCallback: null,
                    multiConfirm: [
                        {
                            confirmText: '重複案件は上書きしないで送信のみ行う',
                            confirmCallback: function () {
                                return _this.sender.mailApiService.sendJobStudentsBulkMail(toSave).pipe(tap(function (result) { return _this.sender.afterSend(result); }));
                            },
                        },
                        {
                            confirmText: '重複案件は上書き',
                            confirmCallback: function () {
                                return _this.sender.mailApiService
                                    .sendJobStudentsBulkMail(toSave)
                                    .pipe(mergeMap(function (result) {
                                    return _this.sender.progressApiService
                                        .bulkCreate(toSave.jobIds, _this.sender.model.students.map(function (s) { return s.id; }))
                                        .pipe(tap(function () { return _this.sender.afterSend(result); }));
                                }));
                            },
                        },
                        {
                            confirmText: '重複案件を除いて送信',
                            confirmEnabled: function () {
                                // disable when all of selected have '進捗重複' errors
                                return _this.sender &&
                                    _this.sender.progressErrorInfo &&
                                    _this.sender.model.students &&
                                    !(_this.sender.progressErrorInfo.length === toSave.jobIds.length * _this.sender.model.students.length);
                            },
                            confirmCallback: function () {
                                var studentsWithJobIds = {};
                                _this.sender.model.students.forEach(function (student) {
                                    studentsWithJobIds[student.emailMain] = {
                                        studentId: student.id,
                                        jobIds: toSave.jobIds.filter(function (jobId) { return !_this.sender.progressErrorInfo.some(function (error) { return error.studentId === student.id && error.jobId === jobId; }); }),
                                    };
                                });
                                var sendMailObs = [];
                                var progressCreateCombinations = [];
                                var dests = toSave.to.filter(function (email) { return studentsWithJobIds[email].jobIds.length > 0; });
                                dests.forEach(function (email) {
                                    sendMailObs.push(_this.sender.mailApiService.sendJobStudentsBulkMail(tslib_1.__assign({}, toSave, { to: [email], jobIds: studentsWithJobIds[email].jobIds })));
                                    progressCreateCombinations.push({
                                        studentId: studentsWithJobIds[email].studentId,
                                        jobIds: studentsWithJobIds[email].jobIds,
                                    });
                                });
                                if (dests.length > 0) {
                                    return forkJoin(sendMailObs).pipe(mergeMap(function (result) {
                                        return _this.sender.progressApiService
                                            .bulkCreateCombination(progressCreateCombinations)
                                            .pipe(tap(function () { return _this.sender.afterSend(result); }));
                                    }));
                                }
                            },
                            confirmClass: 'btn--green',
                        },
                    ],
                });
            }
            else {
                _this.sender.mailApiService
                    .sendJobStudentsBulkMail(toSave)
                    .subscribe(function (result) {
                    return _this.sender.progressApiService
                        .bulkCreate(toSave.jobIds, _this.sender.model.students.map(function (s) { return s.id; }))
                        .subscribe(function () { return _this.sender.afterSend(result); });
                });
            }
        });
    };
    return StudentJobConfig;
}(StudentBulkConfig));
export { StudentJobConfig };
