import * as tslib_1 from "tslib";
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { TabGroupComponent } from '@agent-ds/shared/components/tab-group';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { AuthService, CompanyApiService, JobApiService } from '@agent-ds/shared/services';
import { SalesApiService } from '@agent-ds/shared/services/api/sales-api.service';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyOverviewTabComponent } from './tabs/company-overview-tab/company-overview-tab.component';
import { CompanyTabs, CompanyTabsForIntakeCa } from './tabs/company-tabs.enum';
var CompanyDetailPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyDetailPageComponent, _super);
    function CompanyDetailPageComponent(companyService, jobApiService, salesApiService, authService, activeRoute, router) {
        var _this = _super.call(this, router, companyService, activeRoute) || this;
        _this.companyService = companyService;
        _this.jobApiService = jobApiService;
        _this.salesApiService = salesApiService;
        _this.authService = authService;
        _this.activeRoute = activeRoute;
        _this.router = router;
        _this.sideActions = [{ label: '詳細', phases: ['1000px', '100%'] }, { label: '担当者', phases: ['450px'] }];
        _this.tabs = CompanyTabs;
        _this.urlTag = 'enterprises';
        _this.isIntakeCa = false;
        return _this;
    }
    CompanyDetailPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginSubscription = this.authService.isLogined().subscribe(function (logined) {
            // 副担当者かどうかを確認
            _this.isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
            // 権限を見て、タブの一覧を設定
            _this.tabs = _this.isIntakeCa ? CompanyTabsForIntakeCa : CompanyTabs;
            // 副担当者かどうかを確認してから初期化
            _super.prototype.ngOnInit.call(_this);
        });
    };
    CompanyDetailPageComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
    };
    CompanyDetailPageComponent.prototype.fill = function () {
        var _this = this;
        this.updateUrl();
        if (this.referenceId) {
            this.companyService.getDetail(this.referenceId).subscribe(function (company) {
                _this.company = company;
                if (_this.company.user) {
                    _this.company.userId = _this.company.user.id;
                }
            });
            this.companyService.getContacts(this.referenceId).subscribe(function (history) {
                _this.contactHistory = history;
            });
            this.jobApiService
                .getList({
                from: 0,
                size: 100,
                sort: 'updatedAt',
                order: 'desc',
                enterpriseId: this.referenceId,
            })
                .subscribe(function (jobs) {
                _this.jobList = jobs.jobs;
            });
            this.companyService.getMemos(this.referenceId).subscribe(function (info) {
                _this.innerInfo = info;
            });
            // インテークCAの場合は、概要に成約と契約・覚書を表示しないので、APIを実行しない
            if (this.isIntakeCa) {
                return;
            }
            this.salesApiService
                .getList({
                from: 0,
                size: 5,
                sort: 'id',
                order: 'desc',
                enterpriseId: [this.referenceId],
            })
                .subscribe(function (res) {
                _this.sales = res.saleses;
            });
            this.companyService.getContracts(this.referenceId).subscribe(function (info) {
                _this.contracts = info.sort(function (a, b) { return (a.updatedAt > b.updatedAt ? 1 : -1); });
            });
        }
    };
    CompanyDetailPageComponent.prototype.onJobShow = function (id) {
        JobDetailPageComponent.instance.referenceId = id;
        JobDetailPageComponent.instance.open();
    };
    return CompanyDetailPageComponent;
}(DetailPage));
export { CompanyDetailPageComponent };
