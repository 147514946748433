/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-contracts-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/page-scroll-table/page-scroll-table.component.ngfactory";
import * as i3 from "../../../../../shared/components/page-scroll-table/page-scroll-table.component";
import * as i4 from "../../../../../shared/pipes/safe-date.pipe";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../shared/models/tab";
import * as i8 from "./company-contracts-tab.component";
import * as i9 from "../../../../../shared/services/api/company-api.service";
import * as i10 from "../../../../../shared/services/dialog.service";
var styles_CompanyContractsTabComponent = [i0.styles];
var RenderType_CompanyContractsTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyContractsTabComponent, data: {} });
export { RenderType_CompanyContractsTabComponent as RenderType_CompanyContractsTabComponent };
function View_CompanyContractsTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-scroll-table", [["class", "table"]], null, null, null, i2.View_PageScrollTableComponent_0, i2.RenderType_PageScrollTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.PageScrollTableComponent, [i4.SafeDatePipe], { content: [0, "content"], scrollerClass: [1, "scrollerClass"], tableConfig: [2, "tableConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredContracts; var currVal_1 = "no-scroll"; var currVal_2 = _co.tableConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CompanyContractsTabComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "actions__btn btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCompanyContractEditDialog(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u8A73\u7D30 "]))], null, null); }
function View_CompanyContractsTabComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "status__badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7121 "]))], null, null); }
function View_CompanyContractsTabComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyContractsTabComponent_4)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.dynamicData.status === "\u7121\u52B9"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CompanyContractsTabComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { statusTemplate: 0 }), i1.ɵqud(671088640, 2, { actionsTemplate: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "counter__count"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "counter__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u4EF6"])), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "checkbox-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "input", [["class", "checkbox-wrapper__input"], ["id", "show_ended_jobs"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = (_co.onFilterChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i6.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.CheckboxControlValueAccessor]), i1.ɵdid(13, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(15, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "label", [["class", "checkbox-wrapper__label"], ["for", "show_ended_jobs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7121\u52B9\u3082\u8868\u793A "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "controls__btn btn btn--green btn--larger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCompanyContractCreateDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u65B0\u898F\u5951\u7D04\u767B\u9332 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyContractsTabComponent_1)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["actions", 2]], null, 0, null, View_CompanyContractsTabComponent_2)), (_l()(), i1.ɵand(0, [[1, 2], ["status", 2]], null, 0, null, View_CompanyContractsTabComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.activeFilter; _ck(_v, 13, 0, currVal_8); var currVal_9 = (_co.contracts && _co.contracts.length); _ck(_v, 21, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.filteredContracts == null) ? null : _co.filteredContracts.length) || 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 15).ngClassValid; var currVal_6 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CompanyContractsTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-company-contracts-tab", [], null, null, null, View_CompanyContractsTabComponent_0, RenderType_CompanyContractsTabComponent)), i1.ɵprd(6144, null, i7.Tab, null, [i8.CompanyContractsTabComponent]), i1.ɵdid(2, 4767744, null, 0, i8.CompanyContractsTabComponent, [i9.CompanyApiService, i4.SafeDatePipe, i10.DialogService, i1.ChangeDetectorRef], null, null)], null, null); }
var CompanyContractsTabComponentNgFactory = i1.ɵccf("ag-company-contracts-tab", i8.CompanyContractsTabComponent, View_CompanyContractsTabComponent_Host_0, { company: "company" }, {}, []);
export { CompanyContractsTabComponentNgFactory as CompanyContractsTabComponentNgFactory };
