/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/portal";
import * as i4 from "./tab-group.component";
var styles_TabGroupComponent = [i0.styles];
var RenderType_TabGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabGroupComponent, data: {} });
export { RenderType_TabGroupComponent as RenderType_TabGroupComponent };
function View_TabGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "header__label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedIndex = _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "header__label--active": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header__label"; var currVal_1 = _ck(_v, 3, 0, (_co.selectedIndex === _v.context.index)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_2); }); }
export function View_TabGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i3.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabList; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.portals[_co.selectedIndex]; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TabGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "ag-tab-group", [], null, null, null, View_TabGroupComponent_0, RenderType_TabGroupComponent)), i1.ɵdid(1, 1294336, null, 1, i4.TabGroupComponent, [i1.ViewContainerRef], null, null), i1.ɵqud(603979776, 1, { tabs: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabGroupComponentNgFactory = i1.ɵccf("ag-tab-group", i4.TabGroupComponent, View_TabGroupComponent_Host_0, { selectedIndex: "selectedIndex" }, { selectedIndexChange: "selectedIndexChange" }, []);
export { TabGroupComponentNgFactory as TabGroupComponentNgFactory };
