/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job-create-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "./job-create-dialog.component";
import * as i8 from "../../../shared/components/dialog/dialog-ref";
import * as i9 from "../../../shared/components/dialog/dialog-config";
import * as i10 from "../../../shared/services/api/job-api.service";
import * as i11 from "../../../shared/services/api/dynamic-field.service";
import * as i12 from "../../../shared/services/api/company-api.service";
import * as i13 from "../../../shared/services/api/user-api.service";
var styles_JobCreateDialogComponent = [i0.styles];
var RenderType_JobCreateDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobCreateDialogComponent, data: {} });
export { RenderType_JobCreateDialogComponent as RenderType_JobCreateDialogComponent };
export function View_JobCreateDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u65B0\u898F\u6C42\u4EBA\u767B\u9332"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "dialog__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "create"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = (_co.onValidityChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(8, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated" }), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u767B\u9332 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.meta; var currVal_1 = _co.model; _ck(_v, 8, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((!_co.validity || _co.inClose) || _co.dialog.inClose); _ck(_v, 12, 0, currVal_2); }); }
export function View_JobCreateDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-job-create-dialog", [], null, null, null, View_JobCreateDialogComponent_0, RenderType_JobCreateDialogComponent)), i1.ɵdid(1, 245760, null, 0, i7.JobCreateDialogComponent, [i8.DialogRef, i9.DialogConfig, i10.JobApiService, i11.DynamicFieldService, i12.CompanyApiService, i13.UserApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JobCreateDialogComponentNgFactory = i1.ɵccf("ag-job-create-dialog", i7.JobCreateDialogComponent, View_JobCreateDialogComponent_Host_0, {}, {}, []);
export { JobCreateDialogComponentNgFactory as JobCreateDialogComponentNgFactory };
