/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-department-create-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "../../../shared/components/select/select.component.ngfactory";
import * as i9 from "../../../shared/components/select/select.component";
import * as i10 from "./company-department-create-dialog.component";
import * as i11 from "../../../shared/components/dialog/dialog-ref";
import * as i12 from "../../../shared/components/dialog/dialog-config";
import * as i13 from "../../../shared/services/api/dynamic-field.service";
import * as i14 from "../../../shared/services/api/master-api.service";
var styles_CompanyDepartmentCreateDialogComponent = [i0.styles];
var RenderType_CompanyDepartmentCreateDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyDepartmentCreateDialogComponent, data: {} });
export { RenderType_CompanyDepartmentCreateDialogComponent as RenderType_CompanyDepartmentCreateDialogComponent };
function View_CompanyDepartmentCreateDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = (_co.onValidityChange(0, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataLeft; var currVal_1 = _co.model; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CompanyDepartmentCreateDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = (_co.onValidityChange(1, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataResponsible; var currVal_1 = _co.modelResponsible; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CompanyDepartmentCreateDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "contact-info__delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeContactInfo(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "contact-info__close contact-info__close--medium no-hover"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u524A\u9664 "]))], null, null); }
function View_CompanyDepartmentCreateDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "contact-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-dynamic-form", [["class", "contact-info__form"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(2, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyDepartmentCreateDialogComponent_4)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.model; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.index && _v.context.$implicit); _ck(_v, 4, 0, currVal_2); }, null); }
function View_CompanyDepartmentCreateDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "add-contact-info-btn btn btn--big btn--dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addContactInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "add-contact-info-btn__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u62C5\u5F53\u8005\u60C5\u5831\u3092\u8FFD\u52A0"]))], null, null); }
function View_CompanyDepartmentCreateDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dep-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ag-select", [["class", "dep-selector__select"], ["labelField", "name"], ["valueField", "address"]], null, null, null, i8.View_SelectComponent_0, i8.RenderType_SelectComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.SelectComponent]), i1.ɵprd(5120, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i9.SelectComponent]), i1.ɵdid(4, 770048, [["addressSelect", 4]], 0, i9.SelectComponent, [i1.ChangeDetectorRef], { options: [0, "options"], labelField: [1, "labelField"], valueField: [2, "valueField"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn--small btn--dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddressSelect(i1.ɵnov(_v, 4).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5F15\u7528 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.departmentDynamicList; var currVal_1 = "name"; var currVal_2 = "address"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CompanyDepartmentCreateDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { deptSelectorTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "dialog__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyDepartmentCreateDialogComponent_1)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyDepartmentCreateDialogComponent_2)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyDepartmentCreateDialogComponent_3)), i1.ɵdid(16, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyDepartmentCreateDialogComponent_5)), i1.ɵdid(18, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(23, null, [" ", " "])), (_l()(), i1.ɵand(0, [[1, 2], ["deptSelect", 2]], null, 0, null, View_CompanyDepartmentCreateDialogComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.metadataLeft; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.metadataResponsible; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.metadataContactInformation; _ck(_v, 16, 0, currVal_3); var currVal_4 = (_co.metadataContactInformation.length < _co.CONTACT_LIMIT); _ck(_v, 18, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.config.data.department.dynamicData ? "\u90E8\u7F72\u7DE8\u96C6" : "\u65B0\u898F\u90E8\u7F72\u767B\u9332"); _ck(_v, 4, 0, currVal_0); var currVal_5 = (!_co.valid || _co.dialog.inClose); _ck(_v, 22, 0, currVal_5); var currVal_6 = (_co.config.data.department.dynamicData ? "\u66F4\u65B0" : "\u767B\u9332"); _ck(_v, 23, 0, currVal_6); }); }
export function View_CompanyDepartmentCreateDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-company-department-create-dialog", [], null, null, null, View_CompanyDepartmentCreateDialogComponent_0, RenderType_CompanyDepartmentCreateDialogComponent)), i1.ɵdid(1, 245760, null, 0, i10.CompanyDepartmentCreateDialogComponent, [i11.DialogRef, i12.DialogConfig, i13.DynamicFieldService, i14.MasterApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanyDepartmentCreateDialogComponentNgFactory = i1.ɵccf("ag-company-department-create-dialog", i10.CompanyDepartmentCreateDialogComponent, View_CompanyDepartmentCreateDialogComponent_Host_0, {}, {}, []);
export { CompanyDepartmentCreateDialogComponentNgFactory as CompanyDepartmentCreateDialogComponentNgFactory };
