<div class="header">
  管理
</div>

<div class="section">
  <div class="sub-header">
    <div class="sub-header__title">
      フィールド管理
    </div>
  </div>
  <table *ngIf="studentFields && studentFields.length" class="table">
    <thead>
      <tr>
        <th>求職者</th>
        <th class="table__action"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of studentFields">
        <ng-container
          *ngTemplateOutlet="tableRow; context: { $implicit: field }"
        ></ng-container>
      </tr>
    </tbody>
  </table>
  <table *ngIf="enterpriseFields && enterpriseFields.length" class="table">
    <thead>
      <tr>
        <th>企業</th>
        <th class="table__action"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of enterpriseFields">
        <ng-container
          *ngTemplateOutlet="tableRow; context: { $implicit: field }"
        ></ng-container>
      </tr>
    </tbody>
  </table>
  <table *ngIf="jobFields && jobFields.length" class="table">
    <thead>
      <tr>
        <th>求人</th>
        <th class="table__action"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of jobFields">
        <ng-container
          *ngTemplateOutlet="tableRow; context: { $implicit: field }"
        ></ng-container>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #tableRow let-field>
  <td class="table__value">
    {{ field.label }}
    <div *ngIf="editors[field.id]?.isEdit" class="edit">
      <div class="edit__sections">
        <div>
          <div class="edit__title">項目</div>
          <div class="edit__content">
            <div class="edit__label">名称</div>
            <div class="edit__value">
              {{ field.label }}
            </div>
          </div>
        </div>
        <div>
          <div class="edit__title">項目形式</div>
          <div class="edit__content">
            <div class="edit__label">値</div>
            <div class="edit__value">
              <div class="edit__input-container">
                <input
                  type="text"
                  placeholder="リストの項目を入力してください"
                  [(ngModel)]="editors[field.id].newOption"
                />
                <button
                  class="edit__input-btn btn btn--dark"
                  [disabled]="
                    !editors[field.id]?.newOption?.trim() ||
                    (editors[field.id]?.allOptions?.length &&
                      editors[field.id]?.allOptions.includes(
                        editors[field.id].newOption.trim()
                      ))
                  "
                  (click)="
                    editors[field.id]?.allOptions.push(
                      editors[field.id].newOption.trim()
                    );
                    editors[field.id]?.newOptions.push(
                      editors[field.id].newOption.trim()
                    );
                    editors[field.id].newOption = ''
                  "
                >
                  追加
                </button>
              </div>
              <ag-ordered-list
                [(value)]="editors[field.id].allOptions"
              ></ag-ordered-list>
            </div>
          </div>
        </div>
      </div>
      <div class="edit__footer">
        <button
          class="edit__footer-btn edit__footer-btn--accent"
          [disabled]="editors[field.id].isUpdateInProgress"
          (click)="editors[field.id].isEdit = false"
        >
          キャンセル
        </button>
        <button
          class="edit__footer-btn edit__footer-btn--primary"
          [disabled]="editors[field.id].isUpdateInProgress"
          (click)="onSave(field, editors[field.id])"
        >
          確定
        </button>
      </div>
    </div>
  </td>
  <td class="table__action">
    <ng-container
      *ngIf="field.fieldType === 'list' || field.fieldType === 'multi-list'"
    >
      <button
        *ngIf="!editors[field.id]?.isEdit; else cancelBtn"
        class="table__btn btn btn--small btn--bg-white btn--outline-gray"
        (click)="
          editors[field.id] = {
            isEdit: true,
            isUpdateInProgress: false,
            allOptions: field.validationStyle?.options?.slice() || [],
            newOptions: [],
            newOption: ''
          }
        "
      >
        編集
      </button>
    </ng-container>
    <ng-template #cancelBtn>
      <button
        class="edit-cancel-btn btn btn--white"
        (click)="
          !editors[field.id].isUpdateInProgress
            ? (editors[field.id].isEdit = false)
            : null
        "
      >
        キャンセル
        <span class="close close--blue"></span>
      </button>
    </ng-template>
  </td>
</ng-template>
