<div class="login">
  <div class="login__header">
    <span class="close" (click)="close()"></span>
  </div>
  <div class="login__title">
    <img src="/assets/images/mach.svg" />
  </div>
  <div class="login__wrapper" [style.width]="'80%'">
    <ag-dynamic-form
      [metadata]="pwChangeMeta"
      [model]="credentials"
      [style.width]="'100%'"
    ></ag-dynamic-form>
  </div>
  <div class="login__footer">
    <button
      class="login__button btn btn--larger btn--blue-primary"
      [disabled]="
        !(
          credentials.oldPassword &&
          credentials.oldPassword2 &&
          credentials.newPassword &&
          credentials.newPassword2
        )
      "
      (click)="onChangePassword()"
    >
      パスワードを変更
    </button>
  </div>
</div>
