/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-memo-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/memo-list/memo-list.component.ngfactory";
import * as i3 from "../../../../../shared/components/memo-list/memo-list.component";
import * as i4 from "../../../../../shared/models/tab";
import * as i5 from "./student-memo-tab.component";
import * as i6 from "../../../../../shared/services/api/student-api.service";
import * as i7 from "../../../../../shared/services/dialog.service";
var styles_StudentMemoTabComponent = [i0.styles];
var RenderType_StudentMemoTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentMemoTabComponent, data: {} });
export { RenderType_StudentMemoTabComponent as RenderType_StudentMemoTabComponent };
export function View_StudentMemoTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-memo-list", [["newText", "\u65B0\u898F\u4F5C\u6210"], ["title", "\u72B6\u6CC1\u30E1\u30E2"]], null, [[null, "errorGetMemos"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("errorGetMemos" === en)) {
        var pd_0 = (_co.errorGetMemosCallback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MemoListComponent_0, i2.RenderType_MemoListComponent)), i1.ɵdid(1, 4898816, null, 0, i3.MemoListComponent, [], { service: [0, "service"], referenceId: [1, "referenceId"], newText: [2, "newText"], title: [3, "title"] }, { errorGetMemos: "errorGetMemos" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.studentApiService; var currVal_1 = _co.studentId; var currVal_2 = "\u65B0\u898F\u4F5C\u6210"; var currVal_3 = "\u72B6\u6CC1\u30E1\u30E2"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_StudentMemoTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-student-memo-tab", [], null, null, null, View_StudentMemoTabComponent_0, RenderType_StudentMemoTabComponent)), i1.ɵprd(6144, null, i4.Tab, null, [i5.StudentMemoTabComponent]), i1.ɵdid(2, 49152, null, 0, i5.StudentMemoTabComponent, [i6.StudentApiService, i7.DialogService], null, null)], null, null); }
var StudentMemoTabComponentNgFactory = i1.ɵccf("ag-student-memo-tab", i5.StudentMemoTabComponent, View_StudentMemoTabComponent_Host_0, { studentId: "studentId" }, {}, []);
export { StudentMemoTabComponentNgFactory as StudentMemoTabComponentNgFactory };
