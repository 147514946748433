<div class="row">
  <div class="row__column">
    <ag-dynamic-form
      class="row__head-form"
      [metadata]="headMeta"
      [model]="dynamicFormModel"
    ></ag-dynamic-form>
  </div>
</div>

<div *ngIf="jobSettingMail" class="row">
  <div class="row__column">
    <table class="job-setting-mail-table">
      <thead>
        <tr>
          <th class="no">No.</th>
          <th class="event-date-at">開催日時</th>
          <th class="url">URL</th>
          <th class="event-date-type">ステータス</th>
          <th class="timing">配信タイミング</th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let setting of jobSettingMail.settings; index as i"
        >
          <tr>
            <td class="no">
              {{ i + 1 }}
              <br />
              <input
                class="btn btn--small btn--white btn--outline-gray btn--display-inline"
                type="button"
                value="削除"
                (click)="onResetSetting(i)"
              />
            </td>

            <td>
              <input
                type="date"
                class="event-data-at margin-left margin-right"
                [class.required]="
                  !setting.eventDateAt && !isSettingValidated(setting)
                "
                [(ngModel)]="setting.eventDateAt"
              />
              <input
                type="time"
                class="event-data-time-from margin-right"
                [class.required]="
                  (!setting.eventDateTimeFrom &&
                    !isSettingValidated(setting)) ||
                  !isEventDateTimeValidated(setting)
                "
                [(ngModel)]="setting.eventDateTimeFrom"
              />〜
              <input
                type="time"
                class="event-data-time-to margin-left"
                [class.required]="
                  (!setting.eventDateTimeTo && !isSettingValidated(setting)) ||
                  !isEventDateTimeValidated(setting)
                "
                [(ngModel)]="setting.eventDateTimeTo"
              />
            </td>
            <td class="url">
              <textarea rows="6" [(ngModel)]="setting.url"></textarea>
            </td>
            <td>
              <input
                type="radio"
                name="event-date-type-{{ i }}"
                id="event-date-type-seminar-{{ i }}"
                value="0"
                class="margin-right v-center"
                [class.required]="
                  !setting.eventDateType.length && !isSettingValidated(setting)
                "
                [checked]="setting.eventDateType.includes(0)"
                (change)="setting.eventDateType = [0]"
              />
              <label
                class="margin-right v-center"
                for="event-date-type-seminar-{{ i }}"
                >セミナー</label
              >
              <input
                type="radio"
                name="event-date-type-{{ i }}"
                id="event-date-type-1th-{{ i }}"
                value="1"
                class="margin-right v-center"
                [class.required]="
                  !setting.eventDateType.length && !isSettingValidated(setting)
                "
                [checked]="setting.eventDateType.includes(1)"
                (change)="setting.eventDateType = [1]"
              />
              <label class="v-center" for="event-date-type-1th-{{ i }}"
                >1次</label
              >
            </td>
            <td class="timing">
              <ag-select
                class="dep-selector__select"
                [options]="timingList"
                labelField="label"
                valueField="value"
                [(value)]="setting.sendMailTiming"
                [extraClass]="
                  setting.sendMailTiming == null && !isSettingValidated(setting)
                    ? 'red-border'
                    : null
                "
              ></ag-select>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div class="footer">
  <div class="footer__container">
    <button
      class="footer__btn footer__btn--blue"
      [disabled]="!validity"
      (click)="save()"
    >
      更新
    </button>
  </div>
</div>

<ng-template #fileSelect>
  <div class="file-table">
    <div class="file-table__head">ファイル名</div>
    <ng-container *ngIf="enterpriseFiles | keyvalue as enterpriseFilesObjs">
      <ng-container *ngFor="let fileObj of enterpriseFilesObjs">
        <div
          class="file-table__head"
          *ngIf="(enterpriseFilesObjs || []).length > 1 && !enterpriseIdForFile"
        >
          {{ enterpriseInfoById[fileObj.key] }}
        </div>
        <div
          class="file-table__file"
          *ngFor="
            let file of !enterpriseIdForFile ||
            +fileObj.key === enterpriseIdForFile
              ? fileObj.value
              : []
          "
        >
          <input
            class="file-table__check"
            type="checkbox"
            [ngModel]="enterpriseFileIds.includes(file.id)"
            (ngModelChange)="
              $event
                ? enterpriseFileIds.push(file.id)
                : enterpriseFileIds.splice(
                    enterpriseFileIds.indexOf(file.id),
                    1
                  )
            "
          />
          {{ file.name }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
