import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../components/popup-control/popup-control.component';
import { AuthService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
var ZoneGuard = /** @class */ (function () {
    function ZoneGuard(authService) {
        this.authService = authService;
    }
    ZoneGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.authService.isLogined().pipe(map(function () {
            if (_this.authService.authInfo.zone === 'zone3') {
                return true;
            }
            else {
                PopupControlComponent.instance.open({
                    title: null,
                    content: 'ZONE3専用です。',
                    confirmText: 'OK',
                    confirmCallback: function () { return false; },
                });
                return false;
            }
        }));
    };
    ZoneGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.canActivate(childRoute, state);
    };
    ZoneGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ZoneGuard_Factory() { return new ZoneGuard(i0.ɵɵinject(i1.AuthService)); }, token: ZoneGuard, providedIn: "root" });
    return ZoneGuard;
}());
export { ZoneGuard };
