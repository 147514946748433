<a
  class="control"
  [class.control--hidden]="
    phaseIndex >= phases.length - 1 ||
    (disableFullScreen && phases[phaseIndex + 1] === '100%')
  "
  [href]="
    phases[phaseIndex + 1] === '100%' ? fullModeUrl || '#' : origUrl || '#'
  "
  (click)="grow($event)"
>
  <span class="control__arrow control__arrow--left"></span>
</a>
<a
  class="control control--shrink"
  [class.control--hidden]="phaseIndex < 0"
  [href]="origUrl || '#'"
  (click)="shrink($event)"
>
  <span class="control__arrow control__arrow--left"></span>
</a>
<div *ngIf="sideActions && sideActions.length > 1" class="side-actions">
  <div
    *ngFor="let sideAction of sideActions; index as i"
    class="side-actions__item"
    [class.side-actions__item--active]="selectedSideActionIndex === i"
    (click)="selectedSideActionIndex = i"
  >
    <div class="side-actions__text">{{ sideAction.label }}</div>
  </div>
</div>
<ng-content></ng-content>
