/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../page-scroll-table/page-scroll-table.component.ngfactory";
import * as i4 from "../page-scroll-table/page-scroll-table.component";
import * as i5 from "../../pipes/safe-date.pipe";
import * as i6 from "./company-list.component";
import * as i7 from "@angular/router";
import * as i8 from "../../services/api/company-api.service";
import * as i9 from "../../services/auth.service";
import * as i10 from "../../services/dialog.service";
import * as i11 from "../../services/api/providers/user-team-injector.provider";
var styles_CompanyListComponent = [i0.styles];
var RenderType_CompanyListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyListComponent, data: {} });
export { RenderType_CompanyListComponent as RenderType_CompanyListComponent };
function View_CompanyListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "company-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.name)); _ck(_v, 1, 0, currVal_0); }); }
function View_CompanyListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "status__order"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30AA\u30FC\u30C0\u30FC"]))], null, null); }
function View_CompanyListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "status status--bottom-space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "badge badge--blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AG"])), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyListComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.jobCount; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.agClientStatus)); _ck(_v, 3, 0, currVal_0); }); }
function View_CompanyListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "badge badge--red-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DR"])), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.drClientStatus)); _ck(_v, 3, 0, currVal_0); }); }
function View_CompanyListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "representatives__item"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.teamName; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.ngIf.teamName; _ck(_v, 1, 0, currVal_1); }); }
function View_CompanyListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "representatives__item"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.name; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.ngIf.name; _ck(_v, 1, 0, currVal_1); }); }
function View_CompanyListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "representatives"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyListComponent_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyListComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf.teamName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf.name; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CompanyListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_CompanyListComponent_6)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.userInjector.getUserTeamInfoById(_v.context.$implicit.userIds[0]))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_CompanyListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "actions__item actions__item--fill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCompanyLinkClick($event, _v.parent.context.$implicit, _co.tabs[_co.tabs.CONTRACTS]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5951\u7D04\u4E00\u89A7 "]))], null, null); }
function View_CompanyListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "actions__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCompanyLinkClick($event, _v.context.$implicit, "userList") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u62C5\u5F53\u4E00\u89A7 "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "actions__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCompanyLinkClick($event, _v.context.$implicit, _co.tabs[_co.tabs.JOBS]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6C42\u4EBA\u4E00\u89A7 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyListComponent_10)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isIntakeCa; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_CompanyListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { table: 0 }), i1.ɵqud(671088640, 2, { companyNameTemplate: 0 }), i1.ɵqud(671088640, 3, { agStatusTemplate: 0 }), i1.ɵqud(671088640, 4, { drStatusTemplate: 0 }), i1.ɵqud(671088640, 5, { representativeTemplate: 0 }), i1.ɵqud(671088640, 6, { actionsTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 16, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 13, "div", [["class", "content__controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "content__count"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "content__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "content__filters"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u203B\u6210\u7D04\u5B9F\u7E3E\uFF1A\uFF13\u5E74\u524D\u306E4\u67081\u65E5\u301C\u524D\u5E74\u5EA63\u670831\u65E5\u8FC4\u306E\u671F\u9593"])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "content__refresh"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.table.reset(true);
        var pd_0 = (_co.table.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "content__refresh-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, ":svg:svg", [["class", "content__refresh--svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/icn_refresh.svg#refresh"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6700\u65B0\u60C5\u5831\u306B\u66F4\u65B0 "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "content__create-btn btn btn--green btn--larger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCompanyCreateDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u65B0\u898F\u767B\u9332 "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "ag-page-scroll-table", [["class", "content__table"]], null, [[null, "itemSelected"], [null, "itemChecked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemSelected" === en)) {
        var pd_0 = (_co.onItemSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("itemChecked" === en)) {
        var pd_1 = (_co.onItemChecked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PageScrollTableComponent_0, i3.RenderType_PageScrollTableComponent)), i1.ɵdid(22, 638976, [[1, 4]], 0, i4.PageScrollTableComponent, [i5.SafeDatePipe], { supplier: [0, "supplier"], tableConfig: [1, "tableConfig"] }, { itemSelected: "itemSelected", itemChecked: "itemChecked" }), (_l()(), i1.ɵand(0, [[2, 2], ["companyName", 2]], null, 0, null, View_CompanyListComponent_1)), (_l()(), i1.ɵand(0, [[3, 2], ["agStatus", 2]], null, 0, null, View_CompanyListComponent_2)), (_l()(), i1.ɵand(0, [[4, 2], ["drStatus", 2]], null, 0, null, View_CompanyListComponent_4)), (_l()(), i1.ɵand(0, [[5, 2], ["representative", 2]], null, 0, null, View_CompanyListComponent_5)), (_l()(), i1.ɵand(0, [[6, 2], ["actions", 2]], null, 0, null, View_CompanyListComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loadList; var currVal_3 = _co.tableConfig; _ck(_v, 22, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.table == null) ? null : _co.table.checkedItems.length) || ((_co.table == null) ? null : _co.table.totalSize)); _ck(_v, 9, 0, currVal_0); var currVal_1 = (((_co.table == null) ? null : _co.table.checkedItems.length) ? "\u4EF6\u9078\u629E\u4E2D" : "\u4EF6"); _ck(_v, 11, 0, currVal_1); }); }
export function View_CompanyListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-company-list", [], null, null, null, View_CompanyListComponent_0, RenderType_CompanyListComponent)), i1.ɵdid(1, 4964352, null, 0, i6.CompanyListComponent, [i7.Router, i1.ChangeDetectorRef, i8.CompanyApiService, i9.AuthService, i10.DialogService, i2.Location, i11.UserTeamInjectorProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanyListComponentNgFactory = i1.ɵccf("ag-company-list", i6.CompanyListComponent, View_CompanyListComponent_Host_0, { requestObject: "requestObject", options: "options", extraControls: "extraControls" }, { itemChecked: "itemChecked" }, []);
export { CompanyListComponentNgFactory as CompanyListComponentNgFactory };
