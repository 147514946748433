import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { getProgressStatusLabel } from '@agent-ds/shared/constants';
import {
  CompanyDetail,
  EnterpriseDepartmentUserType,
  Job,
  Progress,
  ProgressHistory,
  ProgressItemJobUserResponse,
  StudentDetail,
  Team,
  User,
} from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import { DetailPage, RefreshEvent } from '@agent-ds/shared/models/detail-page';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import {
  CompanyApiService,
  DynamicFieldService,
  JobApiService,
  MasterApiService,
  ProgressApiService,
  StudentApiService,
  UserApiService,
} from '@agent-ds/shared/services';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { SideActionConfig } from '../../../shared/components/page-floater/page-floater-interface';

export interface ProgressDetails extends Progress {
  job: Job;
  enterprise: CompanyDetail;
  student: StudentDetail;
}

const DEFAULT_TRUNCATE_AT = 300;

@Component({
  selector: 'ag-progress-detail',
  templateUrl: './progress-detail.component.html',
  styleUrls: ['./progress-detail.component.scss'],
})
export class ProgressDetailComponent extends DetailPage implements OnInit, OnDestroy {
  @ViewChild('progressReturnPopup', { static: false }) progressReturnPopupTemplate: TemplateRef<any>;
  public sideActions: SideActionConfig[] = [{ phases: ['700px', '100%'] }];
  protected tabs = {};
  protected urlTag = 'progresses';
  modelResponsible = {
    raIds: [],
    paIds: [],
    caIds: [],
  };
  private validityArray = [true, true, true];
  valid = false;

  progress: ProgressDetails | null = null;
  getStatusLabel = getProgressStatusLabel;

  isStopReasonEditInProgress = false;
  stopReasonText: string;
  isHistoryRemarkEditInProgress = false;
  historyRemarkText: string;
  isTurnOverFormEditInProgress = false;
  turnoverStatusModel: any;
  turnoverStatusMetadata: FormMeta = {
    groups: [
      {
        rows: [
          {
            title: '就業状況',
            fields: [
              {
                type: 'label',
                name: 'workStatus',
              },
            ],
          },
          {
            title: '調査日',
            fields: [
              {
                type: 'label',
                name: 'workStatusDate',
                supplier: (value) => this.datePipe.transform(value, 'yyyy/MM/dd (E)'),
              },
            ],
          },
          {
            title: '調査方法',
            fields: [
              {
                type: 'label',
                name: 'workStatusCheckMethod',
              },
            ],
          },
        ],
      },
    ],
  };
  turnoverStatusMetadataEdit: FormMeta;

  metadataResponsible: FormMeta;

  origUrl: string;
  public fullModeUrl: string;
  truncateAt: number | null = DEFAULT_TRUNCATE_AT;
  historyRemarks = '';

  constructor(
    private progressApiService: ProgressApiService,
    private dynamicService: DynamicFieldService,
    private masterApiService: MasterApiService,
    private userApiService: UserApiService,
    private studentApiService: StudentApiService,
    private companyApiService: CompanyApiService,
    private jobApiService: JobApiService,
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    private datePipe: SafeDatePipe,
  ) {
    super(router, progressApiService, activeRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dynamicService.fieldUpdateEvent.subscribe(() => {
      this.turnoverStatusMetadataEdit = {
        groups: [
          {
            rows: [
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('progress', 'workStatus')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('progress', 'workStatusDate')),
              ...this.dynamicService.getFormRows(this.dynamicService.getDefinition('progress', 'workStatusCheckMethod')),
            ],
          },
        ],
      };

      this.validityArray = [true, true, true];
      this.metadataResponsible = {
        groups: [
          {
            class: 'form__group--red-title-border',
            rows: [
              ...this.dynamicService.getFormRows({
                fieldName: 'raIds',
                fieldType: 'multi-user',
                label: 'RA',
                validationStyle: { max: 1 },
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'paIds',
                fieldType: 'multi-user',
                label: 'PA',
                validationStyle: { max: 1 },
              }),
              ...this.dynamicService.getFormRows({
                fieldName: 'caIds',
                fieldType: 'multi-user',
                label: 'CA',
                validationStyle: { max: 1 },
              }),
            ],
          },
        ],
      };
    });
  }

  onValidityChange(index: number, validity: boolean): void {
    this.validityArray[index] = validity;
    this.valid = this.validityArray.find((v) => !v) == null;
  }

  onHasInterViewChange(event: MouseEvent) {
    event.preventDefault();
    const hasInterviewUpdate = this.progress.hasInterview ? 0 : 1;
    this.progressApiService
      .updateWithoutAction(this.referenceId, {
        type: this.progress.type,
        hasInterview: hasInterviewUpdate,
        n: this.progress.n,
        seminarType: this.progress.seminarType,
        seminarAt: this.progress.seminarAt != null ? new Date(this.progress.seminarAt).toAsialDateTimeString() : null,
        isStudentDelay: this.progress.isStudentDelay,
        dynamicData: this.progress.dynamicData,
      })
      .subscribe(() => {
        this.progress.hasInterview = hasInterviewUpdate;
        this.progressApiService.refreshEvent.emit();
      });
  }

  editHistoryRemark(remarks: string): void {
    this.isHistoryRemarkEditInProgress = true;
    this.historyRemarkText = remarks;
  }

  cancelHistoryRemarkEdit(): void {
    this.historyRemarkText = '';
    this.isHistoryRemarkEditInProgress = false;
  }

  saveHistoryRemarkText(history: ProgressHistory): void {
    this.progressApiService.updateHistoryRemarks(this.referenceId, history.revision, { remarks: this.historyRemarkText }).subscribe(() => {
      this.progressApiService.refreshEvent.emit();
      history.remarks = this.historyRemarkText;
      this.cancelHistoryRemarkEdit();
    });
  }

  onRevisionClick(revision: number): void {
    PopupControlComponent.instance.open({
      title: 'ステータスの戻し',
      content: this.progressReturnPopupTemplate,
      confirmText: '更新',
      confirmEnabled: () => (this.historyRemarks && this.historyRemarks.trim() ? true : false),
      confirmCallback: () => {
        this.progressApiService.revertHistory(this.referenceId, revision, this.historyRemarks).subscribe((progress: Progress) => {
          this.historyRemarks = '';
          this.progressApiService.refreshEvent.emit();
        });
      },
      cancelText: 'キャンセル',
    });
  }

  editStopReasonText(): void {
    this.isStopReasonEditInProgress = true;
    this.stopReasonText = this.progress.dynamicData.stopReason;
  }

  cancelStopReasonTextEdit(): void {
    this.stopReasonText = '';
    this.isStopReasonEditInProgress = false;
  }

  saveUsers(): void {
    const users = [
      ...this.modelResponsible.raIds.map((id) => ({ type: EnterpriseDepartmentUserType.RA, id: id })),
      ...this.modelResponsible.paIds.map((id) => ({ type: EnterpriseDepartmentUserType.PA, id: id })),
      ...this.modelResponsible.caIds.map((id) => ({ type: EnterpriseDepartmentUserType.CA, id: id })),
    ];
    this.progressApiService
      .updateUsers(this.referenceId, users)
      .subscribe(() => {
        this.progress.dynamicData.stopReason = this.stopReasonText;
        this.progressApiService.refreshEvent.emit();
        this.cancelStopReasonTextEdit();
      });
  }

  editTurnOverForm(): void {
    this.turnoverStatusModel = {
      workStatus:
        this.progress && this.progress.dynamicData && this.progress.dynamicData.workStatus ? this.progress.dynamicData.workStatus : null,
      workStatusDate:
        this.progress && this.progress.dynamicData && this.progress.dynamicData.workStatusDate
          ? this.progress.dynamicData.workStatusDate
          : null,
      workStatusCheckMethod:
        this.progress && this.progress.dynamicData && this.progress.dynamicData.workStatusCheckMethod
          ? this.progress.dynamicData.workStatusCheckMethod
          : null,
    };
    this.isTurnOverFormEditInProgress = true;
  }

  cancelTurnOverFormEdit(): void {
    this.turnoverStatusModel = null;
    this.isTurnOverFormEditInProgress = false;
  }

  saveTurnOverForm(): void {
    this.progressApiService
      .updateWithoutAction(this.referenceId, {
        type: this.progress.type,
        hasInterview: this.progress.hasInterview,
        n: this.progress.n,
        seminarType: this.progress.seminarType,
        seminarAt: this.progress.seminarAt,
        isStudentDelay: this.progress.isStudentDelay,
        dynamicData: {
          ...this.progress.dynamicData,
          ...this.turnoverStatusModel,
        },
      })
      .subscribe(() => {
        this.progress.dynamicData = { ...this.progress.dynamicData, ...this.turnoverStatusModel };
        this.progressApiService.refreshEvent.emit();
        this.cancelTurnOverFormEdit();
      });
  }

  public getProgressStatusDateLabel(progress: ProgressHistory): string {
    if (progress.eventAt && progress.eventName) {
      return this.datePipe.transform(progress.eventAt, `${progress.eventName}: yyyy/MM/dd(E) HH:mm`);
    }
    return '';
  }

  public getContactUser(userId: number): Observable<User> {
    return userId != null ? this.userApiService.getAll().pipe(map((users: User[]) => users.find((user) => user.id === userId))) : of(null);
  }

  public getContactTeamName(userId: number): Observable<string> {
    return this.getContactUser(userId).pipe(concatMap((user: User) => (user ? this.getTeamNameForUser(user) : of(null))));
  }

  public getJobContactUser(jobUsers: any[], type: 0 | 1): Observable<User> {
    const jobUserType = type === 0 ? EnterpriseDepartmentUserType.RA : type === 1 ? EnterpriseDepartmentUserType.PA : null;
    const jobUser = jobUsers && jobUsers.length ? jobUsers.find((user) => user.type === jobUserType) : null;
    return jobUser && jobUser.id != null ? this.getContactUser(jobUser.userId) : of(null);
  }

  public getJobContactTeamName(jobUsers: ProgressItemJobUserResponse[], type: 0 | 1): Observable<string> {
    return this.getJobContactUser(jobUsers, type).pipe(concatMap((user: User) => (user ? this.getTeamNameForUser(user) : of(null))));
  }

  private getTeamNameForUser(user: User): Observable<string> {
    return this.masterApiService.getTeams().pipe(
      map((teams: Team[]) => {
        const foundTeam = teams.find((team) => team.id === user.teamId);
        return foundTeam ? foundTeam.name : '';
      }),
    );
  }

  onStudentLinkClick(studentId: number): void {
    this.onLinkClick(StudentDetailPageComponent.instance, studentId);
  }

  onCompanyLinkClick(companyId: number): void {
    this.onLinkClick(CompanyDetailPageComponent.instance, companyId);
  }

  onJobLinkClick(jobId: number): void {
    this.onLinkClick(JobDetailPageComponent.instance, jobId);
  }

  private onLinkClick(detail: DetailPage, id: number): void {
    detail.referenceId = id;
    detail.open();
  }

  protected fill(onRefresh?: boolean, event?: RefreshEvent): void {
    if (event && event === RefreshEvent.DELETE) {
      return;
    }
    this.cancelHistoryRemarkEdit();
    this.cancelStopReasonTextEdit();
    this.cancelTurnOverFormEdit();
    if (this.referenceId) {
      this.updateUrl();
      this.progress = null;
      this.progressApiService
        .getDetail(this.referenceId)
        .pipe(
          concatMap((progress: Progress) => forkJoin(
              this.studentApiService.getDetail(progress.studentId),
              this.companyApiService.getDetail(progress.enterpriseId),
              this.jobApiService.getDetail(progress.jobId),
              of(progress),
            )
          ),
        )
        .subscribe(([student, company, job, progress]) => {
          this.modelResponsible.raIds = [];
          this.modelResponsible.paIds = [];
          this.modelResponsible.caIds = [];
          this.progress = {
            ...progress,
            student: student,
            enterprise: company,
            job: job,
          };
          if (progress.progressUsers.length !== 0) {
            progress.progressUsers.forEach((user) => {
              switch (user.type) {
                case EnterpriseDepartmentUserType.RA:
                  this.modelResponsible.raIds.push(user.userId);
                  break;
                case EnterpriseDepartmentUserType.PA:
                  this.modelResponsible.paIds.push(user.userId);
                  break;
                case EnterpriseDepartmentUserType.CA:
                  this.modelResponsible.caIds.push(user.userId);
                  break;
                default:
                  console.log('type unknow');
              }
            });
          }
        });
    }
  }
}
