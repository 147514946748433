import { AuthGuard, USAGE_MODE } from '@agent-ds/shared/guard';
/**
 * レイアウト
 */
var LayoutComponent = /** @class */ (function () {
    function LayoutComponent() {
        this.useLayout = true;
    }
    Object.defineProperty(LayoutComponent.prototype, "pageInfo", {
        set: function (pageInfo) {
            this.useLayout = (pageInfo ? pageInfo.layout : true) && AuthGuard.CURRENT_USAGE === USAGE_MODE.APP;
        },
        enumerable: true,
        configurable: true
    });
    return LayoutComponent;
}());
export { LayoutComponent };
