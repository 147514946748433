import { enableProdMode } from '@angular/core';
import { AuthGuard } from '@agent-ds/shared/guard';
import { loadScript } from '@agent-ds/shared/util/util';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
function init() {
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .catch(function (err) { return console.error(err); });
}
if (AuthGuard.CURRENT_USAGE) {
    var replaceState_1 = window.history.replaceState;
    var pushState_1 = window.history.pushState;
    loadScript('https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js', 'office-js').then(function (res) {
        return Office.onReady(function () {
            window.history.replaceState = replaceState_1;
            window.history.pushState = pushState_1;
            init();
        });
    });
}
else {
    init();
}
