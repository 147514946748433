/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-message-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/mail/mail.component.ngfactory";
import * as i3 from "../../../../../shared/components/mail/mail.component";
import * as i4 from "../../../../../shared/services/api/mail-api.service";
import * as i5 from "../../../../../shared/services/dialog.service";
import * as i6 from "../../../../../shared/models/tab";
import * as i7 from "./student-message-tab.component";
import * as i8 from "../../../../../shared/services/api/student-api.service";
var styles_StudentMessageTabComponent = [i0.styles];
var RenderType_StudentMessageTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentMessageTabComponent, data: {} });
export { RenderType_StudentMessageTabComponent as RenderType_StudentMessageTabComponent };
export function View_StudentMessageTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-mail", [], null, [[null, "send"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("send" === en)) {
        var pd_0 = (_co.openMail($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MailComponent_0, i2.RenderType_MailComponent)), i1.ɵdid(1, 770048, null, 0, i3.MailComponent, [i4.MailApiService, i5.DialogService], { referenceId: [0, "referenceId"], service: [1, "service"] }, { send: "send" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.student == null) ? null : _co.student.id); var currVal_1 = _co.studentApiService; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_StudentMessageTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-student-message-tab", [], null, null, null, View_StudentMessageTabComponent_0, RenderType_StudentMessageTabComponent)), i1.ɵprd(6144, null, i6.Tab, null, [i7.StudentMessageTabComponent]), i1.ɵdid(2, 49152, null, 0, i7.StudentMessageTabComponent, [i8.StudentApiService], null, null)], null, null); }
var StudentMessageTabComponentNgFactory = i1.ɵccf("ag-student-message-tab", i7.StudentMessageTabComponent, View_StudentMessageTabComponent_Host_0, { student: "student" }, {}, []);
export { StudentMessageTabComponentNgFactory as StudentMessageTabComponentNgFactory };
