/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-memo-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/memo-list/memo-list.component.ngfactory";
import * as i3 from "../../../../../shared/components/memo-list/memo-list.component";
import * as i4 from "../../../../../shared/models/tab";
import * as i5 from "./company-memo-tab.component";
import * as i6 from "../../../../../shared/services/api/company-api.service";
var styles_CompanyMemoTabComponent = [i0.styles];
var RenderType_CompanyMemoTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyMemoTabComponent, data: {} });
export { RenderType_CompanyMemoTabComponent as RenderType_CompanyMemoTabComponent };
export function View_CompanyMemoTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-memo-list", [["newText", "\u65B0\u898FINNER\u60C5\u5831\u767B\u9332"], ["title", "INNER\u60C5\u5831"]], null, null, null, i2.View_MemoListComponent_0, i2.RenderType_MemoListComponent)), i1.ɵdid(1, 4898816, null, 0, i3.MemoListComponent, [], { service: [0, "service"], referenceId: [1, "referenceId"], newText: [2, "newText"], title: [3, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyApiService; var currVal_1 = _co.companyId; var currVal_2 = "\u65B0\u898FINNER\u60C5\u5831\u767B\u9332"; var currVal_3 = "INNER\u60C5\u5831"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CompanyMemoTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-company-memo-tab", [], null, null, null, View_CompanyMemoTabComponent_0, RenderType_CompanyMemoTabComponent)), i1.ɵprd(6144, null, i4.Tab, null, [i5.CompanyMemoTabComponent]), i1.ɵdid(2, 49152, null, 0, i5.CompanyMemoTabComponent, [i6.CompanyApiService], null, null)], null, null); }
var CompanyMemoTabComponentNgFactory = i1.ɵccf("ag-company-memo-tab", i5.CompanyMemoTabComponent, View_CompanyMemoTabComponent_Host_0, { companyId: "companyId" }, {}, []);
export { CompanyMemoTabComponentNgFactory as CompanyMemoTabComponentNgFactory };
