/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-academic-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../../../shared/pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../shared/models/tab";
import * as i9 from "./student-academic-tab.component";
import * as i10 from "../../../../../shared/services/api/dynamic-field.service";
import * as i11 from "../../../../../shared/services/api/student-api.service";
var styles_StudentAcademicTabComponent = [i0.styles];
var RenderType_StudentAcademicTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentAcademicTabComponent, data: {} });
export { RenderType_StudentAcademicTabComponent as RenderType_StudentAcademicTabComponent };
function View_StudentAcademicTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = ((_co.valid = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadata; var currVal_1 = ((_co.student == null) ? null : _co.student.dynamicData); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentAcademicTabComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "footer__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "footer__btn footer__btn--blue"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u66F4\u65B0 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.valid; _ck(_v, 2, 0, currVal_0); }); }
export function View_StudentAcademicTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentAcademicTabComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentAcademicTabComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadata; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.readonly; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StudentAcademicTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-student-academic-tab", [], null, null, null, View_StudentAcademicTabComponent_0, RenderType_StudentAcademicTabComponent)), i1.ɵprd(6144, null, i8.Tab, null, [i9.StudentAcademicTabComponent]), i1.ɵdid(2, 114688, null, 0, i9.StudentAcademicTabComponent, [i10.DynamicFieldService, i11.StudentApiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var StudentAcademicTabComponentNgFactory = i1.ɵccf("ag-student-academic-tab", i9.StudentAcademicTabComponent, View_StudentAcademicTabComponent_Host_0, { student: "student", readonly: "readonly" }, {}, []);
export { StudentAcademicTabComponentNgFactory as StudentAcademicTabComponentNgFactory };
