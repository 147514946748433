import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { AuthInterceptor } from '@agent-ds/shared/interceptors/auth.interceptor';
import { isNestedSupplied, isPartial, strip, SupplierCallType } from '@agent-ds/shared/models/form';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { TruncatePipe } from '@agent-ds/shared/pipes/truncate.pipe';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { NestedDiffer } from '@agent-ds/shared/util';
import { getValueFromObject, setValueInObject } from '@agent-ds/shared/util/util';
import { ChangeDetectorRef, DoCheck, ElementRef, EventEmitter, KeyValueChangeRecord, KeyValueDiffer, KeyValueDiffers, OnDestroy, } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
var DynamicFormComponent = /** @class */ (function () {
    function DynamicFormComponent(fb, differs, datePipe, truncatePipe, cdr) {
        var _this = this;
        this.fb = fb;
        this.differs = differs;
        this.datePipe = datePipe;
        this.truncatePipe = truncatePipe;
        this.cdr = cdr;
        this.myForm = new FormGroup({});
        this.submitted = new EventEmitter();
        this.validated = new EventEmitter();
        this.focused = new EventEmitter();
        this.changed = new EventEmitter();
        this.blured = new EventEmitter();
        this.transparentModel = {};
        this.fieldsByName = {};
        this.namesByValueKeys = {};
        this.partialKeysByName = {};
        this.afterFieldInit = {};
        this.linksInAction = {};
        this.groupsExpanded = [];
        this.labelsToggled = {};
        this.labelToggleLength = 400;
        this.alive = true;
        this.boundGetValue = function (key, override) { return _this.getValue(_this.fieldsByName[key] || { name: key, type: null }, override); };
        this.boundSetValue = function (key, value) { return _this.setControlValue(key, value); };
        this.modelDiffer = new NestedDiffer();
        this.metadataDiffer = this.differs.find({}).create(); // new NestedDiffer(['options', 'showRequired']);
        this.errorSubscription = AuthInterceptor.FIELD_ERROR_EVENT.subscribe(function (event) {
            var fieldNames = Object.keys(_this.fieldsByName).concat(Object.keys(_this.partialKeysByName));
            Object.keys(event).forEach(function (key) {
                var expandedKey = 'dynamicData.' + key;
                var found = fieldNames.find(function (name) { return name === key || name === expandedKey || 'dynamicData.' + name === key; });
                if (found) {
                    if (_this.fieldsByName[found]) {
                        // this.myForm.controls[found].setErrors({ message: event[key] });
                        _this.myForm.controls[found].markAsTouched();
                    }
                    else {
                        _this.partialKeysByName[found].forEach(function (pKey) {
                            _this.myForm.controls[pKey].setErrors({});
                            _this.myForm.controls[pKey].markAsTouched({});
                        });
                    }
                }
            });
        });
    }
    DynamicFormComponent.prototype.checkUpdate = function (prop) {
        var _this = this;
        var field = this.fieldsByName[prop.key];
        var currentValue = prop.currentValue;
        var control = this.myForm.controls[prop.key];
        if (!field && this.namesByValueKeys[prop.key]) {
            field = this.namesByValueKeys[prop.key];
            currentValue = this.getValue(field, typeOf(field.valueField) === 'object');
            control = this.myForm.controls[field.name];
        }
        if (field) {
            var sup = field.supplier
                ? field.supplier(currentValue, SupplierCallType.MODEL_CHANGE, this.boundGetValue, this.boundSetValue)
                : undefined;
            if (sup instanceof Observable) {
                var subs_1 = sup.subscribe(function (res) {
                    _this.suppliedUpdateOnField(field, control, res, currentValue);
                    setTimeout(function () {
                        subs_1.unsubscribe();
                        _this.detect();
                    }, 0);
                });
            }
            else if (sup) {
                this.suppliedUpdateOnField(field, control, sup, currentValue);
            }
            else {
                var partial = isPartial(field);
                var value = void 0;
                if ((field.type === 'date' || field.type === 'time') && currentValue && partial) {
                    value = Date.from(currentValue);
                }
                else if (field.type === 'year' && currentValue && partial) {
                    value = new Date(currentValue).getFullYear();
                }
                else if (field.type === 'month' && currentValue && partial) {
                    value = new Date(currentValue).getMonth() + 1;
                }
                else if (field.type === 'zip') {
                    var place = parseInt(field.name.substring(field.name.length - 1), 10);
                    value = currentValue ? (place ? currentValue.substring(3) : currentValue.substring(0, 3)) : null;
                }
                else if (field.multi && field.type !== 'radio') {
                    value = currentValue ? (Array.isArray(currentValue) ? currentValue : Object.keys(currentValue)) : [];
                }
                else {
                    value = currentValue;
                }
                if (value == null &&
                    field.default != null &&
                    (!field.multi || Array.isArray(field.default)) &&
                    (!field.options || field.options[0] !== NULL_SELECTED_VALUE)) {
                    value = field.default;
                }
                if (control) {
                    control.patchValue(this.prepareControlValue(field, value));
                }
                if (field.transparent) {
                    this.setValue(field, value);
                }
                this.updateLinks(field.name, field, SupplierCallType.MODEL_LINK_CHANGE);
            }
        }
        else if (this.partialKeysByName[prop.key]) {
            Object.keys(this.partialKeysByName[prop.key]).forEach(function (key) {
                return _this.checkUpdate({ key: _this.partialKeysByName[prop.key][key], currentValue: currentValue, previousValue: null });
            });
        }
    };
    DynamicFormComponent.prototype.suppliedUpdateOnField = function (field, control, sup, currentValue) {
        var val = currentValue;
        if (isNestedSupplied(sup)) {
            field.options = sup.options || field.options;
            if (sup.value !== undefined) {
                val = sup.value;
            }
        }
        else if (sup !== undefined) {
            val = sup;
        }
        if (control) {
            control.patchValue(this.prepareControlValue(field, val));
        }
        if (field.transparent || typeOf(field.valueField) !== 'object') {
            this.setValue(field, val);
        }
        this.updateLinks(field.name, field, SupplierCallType.MODEL_LINK_CHANGE);
    };
    DynamicFormComponent.prototype.ngDoCheck = function () {
        var _this = this;
        var metadataChanges = this.metadataDiffer.diff(this.metadata);
        if (metadataChanges) {
            this.noCheckNeeded = true;
            this.createForm();
        }
        else if (!this.noCheckNeeded) {
            this.noCheckNeeded = true;
            var modelChanges = this.modelDiffer.diff(this.model);
            if (!modelChanges) {
                return;
            }
            modelChanges.forEachItem(function (prop) {
                // empty transparent model to avoid collision with past and current values
                delete _this.transparentModel[prop.key];
            });
            modelChanges.forEachItem(function (prop) {
                _this.checkUpdate(prop);
            });
            this.checkAllowedState();
            this.detect();
        }
        this.noCheckNeeded = false;
    };
    DynamicFormComponent.prototype.detect = function () {
        if (this.alive) {
            this.cdr.detectChanges();
        }
    };
    DynamicFormComponent.prototype.createForm = function () {
        var _this = this;
        this.fieldsByName = {};
        this.namesByValueKeys = {};
        this.partialKeysByName = {};
        this.transparentModel = {};
        this.linksInAction = {};
        this.groupsExpanded = [];
        this.labelsToggled = {};
        var group = {};
        var i = 0;
        for (var _i = 0, _a = this.metadata.groups; _i < _a.length; _i++) {
            var fieldGroup = _a[_i];
            this.groupsExpanded[i++] = fieldGroup.expanded;
            for (var _b = 0, _c = fieldGroup.rows; _b < _c.length; _b++) {
                var row = _c[_b];
                var _loop_1 = function (field) {
                    if (!field) {
                        return "continue";
                    }
                    field.validators = field.validators ? tslib_1.__assign({}, field.validators) : {};
                    if (field.type === 'label' || field.type === 'hr') {
                        field.transparent = true;
                    }
                    else if (field.type === 'date') {
                        field.validators.maxValue = field.validators.maxValue || '2500-01-01';
                        field.validators.minValue = field.validators.minValue || '1900-01-01';
                    }
                    else if (field.type === 'yearmonth') {
                        field.validators.maxValue = field.validators.maxValue || '2500-01';
                        field.validators.minValue = field.validators.minValue || '1900-01';
                    }
                    else if (field.type === 'year') {
                        field.validators.maxValue = field.validators.maxValue || 9999;
                    }
                    else if (field.type === 'month') {
                        field.validators.maxValue = field.validators.maxValue || 12;
                        field.validators.minValue = field.validators.minValue || 1;
                    }
                    this_1.fieldsByName[field.name] = field;
                    this_1.linksInAction[field.name] = {};
                    if (typeOf(field.valueField) === 'object') {
                        var parts = strip(field.name).split('.');
                        parts.pop();
                        var baseKey_1 = parts.join('.');
                        Object.keys(field.valueField).forEach(function (key) { return (_this.namesByValueKeys[key ? baseKey_1 + "." + key : baseKey_1] = field); });
                    }
                    var params = [];
                    var value;
                    var partial = isPartial(field);
                    if (partial) {
                        var key = strip(field.name);
                        var arr = this_1.partialKeysByName[key] || [];
                        arr.push(field.name);
                        this_1.partialKeysByName[key] = arr;
                    }
                    if (this_1.model) {
                        var val = this_1.getValue(field, typeOf(field.valueField) === 'object');
                        if ((val == null ||
                            (field.options &&
                                (!field.options.length ||
                                    ((field.options.length === 1 &&
                                        field.options[0] != null &&
                                        NULL_SELECTED_VALUE === field.options[0][field.valueField]) ||
                                        field.options[0])))) &&
                            field.supplier) {
                            var sup = field.supplier(typeOf(field.valueField) === 'object' ? val : this_1.getValue(field, true), SupplierCallType.INIT, this_1.boundGetValue, this_1.boundSetValue);
                            if (sup instanceof Observable) {
                                var subs_2 = sup.subscribe(function (res) {
                                    if (isNestedSupplied(res)) {
                                        field.options = res.options || field.options;
                                        if (res.value !== undefined) {
                                            _this.setControlValue(field.name, res.value);
                                        }
                                    }
                                    else if (res !== undefined) {
                                        _this.setControlValue(field.name, res);
                                    }
                                    setTimeout(function () {
                                        subs_2.unsubscribe();
                                        _this.detect();
                                    }, 0);
                                });
                            }
                            else if (sup && sup.options) {
                                field.options = sup.options || field.options;
                                val = sup.value || val;
                                setTimeout(function () { return _this.detect(); });
                            }
                            else {
                                val = sup || val || this_1.getValue(field, true);
                                if (field.transparent) {
                                    this_1.setValue(field, val);
                                }
                            }
                        }
                        else if (field.transparent) {
                            val = val || this_1.getValue(field, true);
                            this_1.setValue(field, val);
                        }
                        if ((field.type === 'date' || field.type === 'time') && val && partial) {
                            value = Date.from(val);
                        }
                        else if (field.type === 'year' && val && partial) {
                            value = new Date(val).getFullYear();
                        }
                        else if (field.type === 'month' && val && partial) {
                            value = new Date(val).getMonth() + 1;
                        }
                        else if (field.type === 'zip') {
                            var place = parseInt(field.name.substring(field.name.length - 1), 10);
                            value = val ? (place ? val.substring(3) : val.substring(0, 3)) : null;
                        }
                        else if (field.multi && field.type !== 'radio') {
                            value = val ? (Array.isArray(val) ? val : Object.keys(val)) : [];
                        }
                        else {
                            value = val;
                        }
                    }
                    else if (value == null && field.default && (!field.multi || Array.isArray(field.default))) {
                        value = field.default;
                    }
                    else if (value == null && field.multi) {
                        value = [];
                    }
                    // add object for formbuilder
                    if (field.multi && field.type === 'checkbox') {
                        group[field.name] = this_1.fb.array(field.options.map(function (option) { return ({
                            value: value.includes(typeof field.valueField === 'string' ? option[field.valueField] : option),
                            disabled: _this.metadata.disabled || field.disabled || (field.allowOn && !_this.isDependencyOk(field.allowOn)),
                        }); }), this_1.getValidators(field.validators));
                    }
                    else {
                        params.push({
                            value: this_1.prepareControlValue(field, value),
                            disabled: this_1.metadata.disabled || field.disabled || (field.allowOn && !this_1.isDependencyOk(field.allowOn)),
                        });
                        // add validators to form builder
                        if (field.validators) {
                            params.push(this_1.getValidators(field.validators));
                        }
                        // add field to group
                        group[field.name] = params;
                        if (field.type === 'radio' && field.specialOption) {
                            var foundInOption = field.options.find(function (option) { return value === (typeof field.valueField === 'string' ? option[field.valueField] : option); });
                            if (!foundInOption && value != null) {
                                params[0].value = field.specialOption;
                            }
                            group[field.name + '.special'] = [
                                {
                                    value: foundInOption && value != null ? null : value,
                                    disabled: params[0].value !== field.specialOption ||
                                        this_1.metadata.disabled ||
                                        field.disabled ||
                                        (field.allowOn && !this_1.isDependencyOk(field.allowOn)),
                                },
                            ];
                        }
                        setTimeout(function () { return (_this.afterFieldInit[field.name] = true); }, 500);
                    }
                };
                var this_1 = this;
                for (var _d = 0, _e = row.fields; _d < _e.length; _d++) {
                    var field = _e[_d];
                    _loop_1(field);
                }
            }
        }
        this.myForm = this.fb.group(group);
        Promise.all(Object.values(this.fieldsByName).map(function (field) { return _this.updateLinks(field.name, field, SupplierCallType.INIT); })).then(function () {
            return _this.detect();
        });
        if (this.valueChangeSubscription) {
            this.valueChangeSubscription.unsubscribe();
        }
        this.valueChangeSubscription = this.myForm.valueChanges.subscribe(function () {
            _this.validated.emit(_this.myForm.valid);
        });
        setTimeout(function () {
            if (_this.metadata.initTouched) {
                _this.myForm.markAllAsTouched();
            }
            _this.myForm.updateValueAndValidity();
        }, 200);
    };
    DynamicFormComponent.prototype.updateModel = function (key) {
        var _this = this;
        var field = this.fieldsByName[key];
        var val = Object.keys(this.myForm.value).length ? this.myForm.value[key] : this.getValue(field);
        if (typeOf(field.valueField) === 'object' && typeof val !== 'object') {
            return;
        }
        var baseValue = this.getValue(field);
        var partial = isPartial(field);
        if ((field.type === 'date' || field.type === 'time') && !val) {
            val = null;
        }
        if (field.multi && field.type === 'checkbox') {
            val = field.options
                .filter(function (item, index) { return val[index]; })
                .map(function (option) { return (typeof field.valueField === 'string' ? option[field.valueField] : option); });
        }
        else if (field.type === 'radio' && field.specialOption && val === field.specialOption) {
            val = this.myForm.controls[key + '.special'].value || '';
        }
        else if (field.type === 'date' && partial) {
            if (val) {
                val = new Date(val);
                var tmp = baseValue ? new Date(baseValue) : new Date(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0, 0);
                val.setHours(tmp.getHours());
                val.setMinutes(tmp.getMinutes());
            }
            else {
                this.setControlValue(strip(key) + '.1', null);
            }
        }
        else if (field.type === 'time' && partial) {
            val = val ? val.split(':').map(function (v) { return Number.parseInt(v, 10); }) : [0, 0];
            var tmp = baseValue ? new Date(baseValue) : new Date();
            tmp.setHours(val[0]);
            tmp.setMinutes(val[1]);
            tmp.setSeconds(0);
            tmp.setMilliseconds(0);
            val = tmp;
            if (!baseValue) {
                this.setControlValue(strip(key) + '.0', tmp);
            }
        }
        else if (field.type === 'yearmonth') {
            val = val ? new Date(val) : null;
        }
        else if (field.type === 'year' && partial && val) {
            var tmp = baseValue ? new Date(baseValue) : new Date();
            tmp.setFullYear(val);
            tmp.setDate(1);
            val = tmp;
        }
        else if (field.type === 'month' && partial) {
            if (val != null && val !== '') {
                var tmp = baseValue ? new Date(baseValue) : new Date();
                tmp.setMonth(val - 1);
                tmp.setDate(1);
                val = tmp;
            }
        }
        else if (field.type === 'zip') {
            var arrVal_1 = [null, null];
            var baseKey = strip(key);
            var keys = this.partialKeysByName[baseKey];
            keys.forEach(function (pKey) { return (arrVal_1[parseInt(pKey.substring(pKey.length - 1), 10)] = _this.myForm.value[pKey]); });
            val = arrVal_1.join('');
        }
        if (field.multi && val[0] === NULL_SELECTED_VALUE) {
            val = [null].concat(val.slice(1));
        }
        if (typeOf(field.valueField) === 'object' && this.afterFieldInit[key] && val != null) {
            var valObj_1 = val || {};
            var strippedKey = strip(key);
            var lastKey_1 = strippedKey.split('.').pop();
            var valKeys = Object.keys(field.valueField);
            var skipSafeSave_1 = false;
            valKeys.forEach(function (modelKey) {
                skipSafeSave_1 = skipSafeSave_1 || !modelKey || modelKey === lastKey_1;
                var setVal = field.valueField[modelKey] ? valObj_1[field.valueField[modelKey]] : modelKey ? valObj_1[modelKey] : valObj_1;
                _this.setValue(field, setVal, modelKey ? key.replace(/\.[^.]+?$/, '.' + modelKey) : key, true);
            });
            if (!skipSafeSave_1) {
                this.setValue(field, valObj_1[lastKey_1], null, true);
            }
            if (field.transparent) {
                this.setValue(field, val);
            }
        }
        else if (typeOf(field.valueField) === 'object' && val == null) {
            // this branch intends to set every field with null values instead of undefined which would be filteres from update request
            // otherwise we could remove val != null condition above
            Object.keys(field.valueField).forEach(function (modelKey) {
                _this.setValue(field, null, modelKey ? key.replace(/\..+?$/, '.' + modelKey) : key, true);
            });
        }
        else if (typeOf(field.valueField) !== 'object' || field.transparent || val == null) {
            this.setValue(field, val);
        }
        this.afterFieldInit[key] = true;
        if (!field.actions || !field.actions.find(function (action) { return action.type === 'UPDATE_LINKED'; })) {
            this.updateLinks(key, field).then(function () { return _this.detect(); });
        }
        if (partial && (val == null || val === NULL_SELECTED_VALUE)) {
            var nKey = this.partialKeysByName[strip(key)].reverse().find(function (pKey) { return pKey < key && _this.myForm.value[pKey] != null; });
            if (nKey) {
                this.updateModel(nKey);
                return;
            }
        }
        this.checkAllowedState();
        if (!field.transparent || typeOf(field.valueField) === 'object') {
            this.modelDiffer.diff(this.model);
            this.changed.emit(key);
        }
    };
    DynamicFormComponent.prototype.updateLinks = function (key, fieldDef, mode) {
        if (mode === void 0) { mode = SupplierCallType.LINK_CHANGE; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var field, _loop_2, this_2, _i, _a, link;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                field = fieldDef || this.fieldsByName[key];
                if (field.linkTo) {
                    _loop_2 = function (link) {
                        var linkField = this_2.fieldsByName[link];
                        if (!linkField) {
                            console.warn("no linkField found for key " + link);
                            return "continue";
                        }
                        if (linkField.supplier) {
                            var sup = linkField.supplier(this_2.getValue(linkField, typeOf(linkField.valueField) === 'object'), mode, this_2.boundGetValue, this_2.boundSetValue);
                            if (sup instanceof Observable) {
                                sup.toPromise().then(function (res) {
                                    _this.suppliedUpdateOnLink(field, linkField, res);
                                });
                            }
                            else {
                                this_2.suppliedUpdateOnLink(field, linkField, sup);
                            }
                        }
                        else {
                            (linkField.actions || []).filter(function (a) { return a.type === 'SELECT'; }).forEach(function (a) { return _this.onAction(linkField.name, a, null); });
                        }
                    };
                    this_2 = this;
                    for (_i = 0, _a = field.linkTo; _i < _a.length; _i++) {
                        link = _a[_i];
                        _loop_2(link);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    DynamicFormComponent.prototype.suppliedUpdateOnLink = function (field, linkField, sup) {
        var _this = this;
        var value = sup;
        var options;
        if (isNestedSupplied(sup)) {
            options = sup.options || options;
            value = sup.value;
        }
        if (linkField.options) {
            linkField.options = options || linkField.options;
        }
        if (value !== undefined && !this.linksInAction[linkField.name][field.name]) {
            this.linksInAction[field.name][linkField.name] = true;
            this.setControlValue(linkField.name, value);
        }
        this.linksInAction[linkField.name][field.name] = false;
        (linkField.actions || []).filter(function (a) { return a.type === 'SELECT'; }).forEach(function (a) { return _this.onAction(linkField.name, a, null); });
    };
    DynamicFormComponent.prototype.onAction = function (key, action, value) {
        switch (action.type) {
            case 'UPDATE_LINKED':
                this.updateLinks(key);
                break;
            case 'REVEAL':
                this.fieldsByName[key].hidden = false;
                break;
            case 'RUNNABLE':
            case 'EMPTY':
            case 'SELECT':
                if (action.runnable) {
                    var ret = action.runnable(this.boundGetValue, this.boundSetValue, value);
                    if (ret !== undefined) {
                        this.setControlValue(key, ret);
                    }
                }
                break;
        }
        this.detect();
    };
    DynamicFormComponent.prototype.onKey = function (event) {
        if (!event['isComposing']) {
            if (event.code === 'Escape') {
                this.onBlur();
                this.formEl.nativeElement.focus();
            }
            else if (event.code === 'Enter' && event.target['tagName'] === 'INPUT') {
                event.stopPropagation();
            }
        }
    };
    DynamicFormComponent.prototype.onBlur = function (key) {
        if (!key) {
            document.activeElement.blur();
        }
        this.blured.emit(key);
    };
    DynamicFormComponent.prototype.onFocus = function (key) {
        this.focused.emit(key);
    };
    DynamicFormComponent.prototype.onSubmit = function (event) {
        if (event) {
            if (event['isComposing']) {
                return;
            }
            event.stopPropagation();
        }
        if (this.myForm.valid) {
            this.onBlur();
            this.submitted.emit();
        }
    };
    DynamicFormComponent.prototype.close = function () {
        var overlays = document.body.getElementsByClassName('cdk-overlay-backdrop');
        if (overlays.length) {
            overlays[0].click();
        }
    };
    DynamicFormComponent.prototype.reset = function () {
        this.fgDirective.resetForm();
    };
    DynamicFormComponent.prototype.ngOnDestroy = function () {
        this.alive = false;
        if (this.valueChangeSubscription) {
            this.valueChangeSubscription.unsubscribe();
        }
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    };
    DynamicFormComponent.prototype.getValueByKey = function (key) {
        return this.getValue({ name: key, type: 'text' });
    };
    DynamicFormComponent.prototype.getValue = function (field, overrideTransparency) {
        var value = field.transparent && !overrideTransparency ? this.transparentModel[field.name] : getValueFromObject(this.model, strip(field.name));
        if (value == null && field.transparent && field.default) {
            return field.default;
        }
        return value;
    };
    DynamicFormComponent.prototype.setValue = function (field, value, key, overrideTransparency) {
        if (field.transparent && !overrideTransparency) {
            this.transparentModel[field.name] = value;
        }
        else {
            setValueInObject(this.model, strip(key || field.name), value === NULL_SELECTED_VALUE ? null : value);
            this.detect();
        }
    };
    DynamicFormComponent.prototype.setControlValue = function (key, value, tries) {
        var _this = this;
        if (tries === void 0) { tries = 0; }
        if (tries >= 5) {
            if (!this.fieldsByName[key] || this.fieldsByName[key].transparent) {
                this.transparentModel[key] = value;
                this.detect();
            }
            return;
        }
        if (this.myForm.controls[key]) {
            this.myForm.controls[key].setValue(this.prepareControlValue(this.fieldsByName[key], value));
            this.updateModel(key);
        }
        else {
            setTimeout(function () { return _this.setControlValue(key, value, ++tries); }, 100);
        }
    };
    DynamicFormComponent.prototype.checkAllowedState = function () {
        var _this = this;
        if (this.checkTimeout) {
            clearTimeout(this.checkTimeout);
        }
        this.checkTimeout = setTimeout(function () {
            Object.keys(_this.fieldsByName).forEach(function (key) {
                var control = _this.myForm.controls[key];
                var field = _this.fieldsByName[key];
                if (control && field) {
                    var disabled = _this.metadata.disabled || field.disabled || (field.allowOn && !_this.isDependencyOk(field.allowOn));
                    if (control && control.disabled !== disabled) {
                        if (disabled) {
                            control.disable();
                        }
                        else {
                            control.enable();
                        }
                    }
                    if (field.type === 'radio' && field.specialOption) {
                        var specialDisabled = disabled || control.value !== field.specialOption;
                        var specialControl = _this.myForm.controls[key + '.special'];
                        if (specialControl && specialControl.disabled !== specialDisabled) {
                            if (specialDisabled) {
                                specialControl.disable();
                            }
                            else {
                                specialControl.enable();
                            }
                        }
                    }
                }
            });
        }, 300);
    };
    DynamicFormComponent.prototype.isDependencyOk = function (conditions) {
        var _this = this;
        var keys = Object.keys(conditions);
        return (keys.length === 0 ||
            keys.find(function (key) {
                return !_this.checkCondition(_this.fieldsByName[key] ? _this.getValue(_this.fieldsByName[key]) : _this.getValueByKey(key), conditions[key]);
            }) == null);
    };
    DynamicFormComponent.prototype.checkCondition = function (value, condition) {
        var _this = this;
        return (value != null &&
            (condition == null ||
                (Array.isArray(value)
                    ? value.find(function (v) { return _this.checkCondition(v, condition); }) != null
                    : Array.isArray(condition)
                        ? condition.find(function (c) { return _this.checkCondition(value, c); }) != null
                        : typeOf(condition) === 'object'
                            ? Object.keys(condition).find(function (k) { return _this.checkCondition(value[k], condition[k]); }) != null
                            : value === condition)));
    };
    /**
     * Returns an array of validators for FormBuilder
     * @param validators Array of validator config objects
     * @return Array of Validators which can be type of Validator, ValidatorFn
     */
    DynamicFormComponent.prototype.getValidators = function (validators) {
        return validators
            ? Object.keys(validators)
                .map(function (validator) {
                var fn;
                if (validator === 'pattern') {
                    fn = Validators.pattern(validators.pattern);
                }
                else if (validator === 'required' && validators.required) {
                    fn = Validators.required;
                }
                else if (validator === 'email' && validators.email) {
                    fn = Validators.email;
                }
                else if (validator === 'minValue') {
                    fn = Validators.min(validators.minValue);
                }
                else if (validator === 'maxValue') {
                    fn = Validators.max(validators.maxValue);
                }
                else if (validator === 'minDate') {
                    fn = function (control) {
                        var _a;
                        var valid = control.value && new Date(control.value) >= new Date(validators.minDate);
                        return !valid ? (_a = {}, _a[validator] = "Date must be later than " + validators.minDate + ".", _a) : null;
                    };
                }
                else if (validator === 'minChecked') {
                    fn = function (control) {
                        var _a;
                        var valid = control.value && control.value.length && control.value.filter(function (v) { return !!v; }).length >= validators.minChecked;
                        return !valid ? (_a = {}, _a[validator] = 'Not enough checkbox is checked in this form control.', _a) : null;
                    };
                }
                else if (validator === 'max') {
                    fn = Validators.maxLength(validators.max);
                }
                else if (typeof validators[validator] === 'function') {
                    fn = function (control) {
                        var _a;
                        var err = validators[validator](control);
                        return err != null ? (_a = {}, _a[validator] = err, _a) : null;
                    };
                }
                if (fn) {
                    return function (control) { return (control && control.disabled ? null : fn(control)); };
                }
                return null;
            })
                .filter(function (val) { return val != null; })
            : null;
    };
    DynamicFormComponent.prototype.prepareControlValue = function (field, value) {
        if (field.type === 'date') {
            return this.datePipe.transform(value, 'yyyy-MM-dd');
        }
        else if (field.type === 'time') {
            return typeof value === 'string' && value.match(/^\d\d:\d\d$/) ? value : this.datePipe.transform(value, 'HH:mm');
        }
        else if (field.type === 'yearmonth') {
            return this.datePipe.transform(value, 'yyyy-MM');
        }
        else if (field.options &&
            field.options.length &&
            (field.options[0] === NULL_SELECTED_VALUE ||
                (typeof field.labelField === 'string' && field.options[0][field.labelField] === NULL_SELECTED_VALUE)) &&
            value == null) {
            if (field.type === 'radio' && field.specialOption && this.myForm.controls[field.name + '.special']) {
                this.myForm.controls[field.name + '.special'].patchValue(null);
            }
            return typeof field.valueField !== 'string' ? field.options[0] : NULL_SELECTED_VALUE;
        }
        else if (field.multi && field.type === 'checkbox' && Array.isArray(value)) {
            value = field.options.map(function (option) {
                return option === NULL_SELECTED_VALUE
                    ? value.includes(null) || value.includes(NULL_SELECTED_VALUE)
                    : value.includes(typeof field.valueField === 'string' ? option[field.valueField] : option);
            });
        }
        else if (field.type === 'radio' && field.specialOption) {
            var foundInOption = field.options.find(function (option) { return value === (typeof field.valueField === 'string' ? option[field.valueField] : option); });
            if (!foundInOption && value != null && this.myForm.controls[field.name + '.special']) {
                this.myForm.controls[field.name + '.special'].patchValue(value);
                value = field.specialOption;
            }
            else if (this.myForm.controls[field.name + '.special']) {
                this.myForm.controls[field.name + '.special'].patchValue(null);
            }
        }
        return value;
    };
    return DynamicFormComponent;
}());
export { DynamicFormComponent };
