<div class="header">
  <span class="header__title">当日カウ検索結果</span>
  <span class="header__criteria">
    キーワード：{{ searchObj["keyword"] || "" }}
  </span>
  <button class="header__modify" (click)="openSearch()">
    検索条件を変更<span
      class="header__modify__arrow header__modify__arrow--down"
    ></span>
  </button>
  <button
    class="header__mail"
    [disabled]="!table?.table.totalSize"
    (click)="sendMail()"
  >
    一斉メール配信(全件)
  </button>
  <button
    class="header__bulk"
    [disabled]="!table?.table.totalSize"
    (click)="bulkUpdate()"
  >
    一括更新(全件)
  </button>
</div>
<div class="content">
  <ag-student-list-with-todays-counseling
    #studentList
    [requestObject]="searchObj"
    [options]="listConfiguration"
  >
  </ag-student-list-with-todays-counseling>
</div>

<ng-template #export>
  <div class="export">
    <p class="export__note">出力するCSVを選択してください。</p>
    <p class="export__label">出力リスト</p>
    <ag-autocomplete
      class="export__select"
      valueField="id"
      labelField="name"
      [hideBtn]="false"
      [options]="exportTemplates"
      [(value)]="exportTemplateId"
    ></ag-autocomplete>
  </div>
</ng-template>

<ag-student-search-with-todays-counseling
  *ngIf="isSearchOpen"
  [searchModel]="searchObj"
  (closed)="closeSearch()"
  (search)="doSearch($event)"
></ag-student-search-with-todays-counseling>
