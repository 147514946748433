<div class="page">
  <div class="header">
    管理
  </div>

  <div class="section">
    <div class="sub-header">
      <div class="sub-header__title">
        システム設定
      </div>
    </div>
    <table *ngIf="systemConfigs && systemConfigs.length" class="table">
      <thead>
        <tr>
          <th class="table__name table__value">アクション名</th>
          <th class="table__value">説明</th>
          <th class="table__action"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let config of systemConfigs">
          <td class="table__name table__value">
            {{ config.name }}
          </td>
          <td class="table__value">
            {{ config.description }}
          </td>
          <td class="table__action">
            <div class="radio-list">
              <div
                *ngFor="let option of config.options | keyvalue"
                class="radio-group"
              >
                <input
                  #input
                  type="radio"
                  [name]="config.code"
                  [ngModel]="config.value"
                  [value]="+option.key"
                  (change)="onChange(config, +option.key)"
                />
                <label class="radio-group__label" (click)="input.click()">
                  {{ option.value }}
                </label>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="footer">
  <button
    class="footer__btn footer__btn--blue"
    [disabled]="!isChange() || isUpdateInProgress"
    (click)="onSave()"
  >
    更新
  </button>
</div>
