/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-files-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/file-list/file-list.component.ngfactory";
import * as i3 from "../../../../../shared/components/file-list/file-list.component";
import * as i4 from "../../../../../shared/services/dialog.service";
import * as i5 from "../../../../../shared/services/api/file-api.service";
import * as i6 from "../../../../../shared/services/auth.service";
import * as i7 from "../../../../../shared/models/tab";
import * as i8 from "./company-files-tab.component";
var styles_CompanyFilesTabComponent = [i0.styles];
var RenderType_CompanyFilesTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyFilesTabComponent, data: {} });
export { RenderType_CompanyFilesTabComponent as RenderType_CompanyFilesTabComponent };
export function View_CompanyFilesTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-file-list", [], null, null, null, i2.View_FileListComponent_0, i2.RenderType_FileListComponent)), i1.ɵdid(1, 573440, null, 0, i3.FileListComponent, [i4.DialogService, i5.FileApiService, i6.AuthService], { model: [0, "model"], reference: [1, "reference"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "enterprise"; var currVal_1 = _co.company; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CompanyFilesTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-company-files-tab", [], null, null, null, View_CompanyFilesTabComponent_0, RenderType_CompanyFilesTabComponent)), i1.ɵprd(6144, null, i7.Tab, null, [i8.CompanyFilesTabComponent]), i1.ɵdid(2, 49152, null, 0, i8.CompanyFilesTabComponent, [], null, null)], null, null); }
var CompanyFilesTabComponentNgFactory = i1.ɵccf("ag-company-files-tab", i8.CompanyFilesTabComponent, View_CompanyFilesTabComponent_Host_0, { company: "company" }, {}, []);
export { CompanyFilesTabComponentNgFactory as CompanyFilesTabComponentNgFactory };
