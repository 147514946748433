<header class="nav-header">
  <img src="/assets/icons/logo_m.svg" alt="bic_logo" class="nav-header__logo" />
</header>
<ul class="nav">
  <li
    class="nav__item"
    routerLink="/dashboard"
    routerLinkActive="nav__item--active"
    [class.nav__item--selected]="activePopupMenuIndex === 0"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(0)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img src="/assets/icons/icn_nav_top.svg" alt="top" class="nav__icon" />
    <span class="nav__label">
      TOP
    </span>
  </li>
  <li
    class="nav__item"
    routerLink="/students"
    routerLinkActive="nav__item--active"
    [class.nav__item--selected]="activePopupMenuIndex === 1"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(1)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img
      src="/assets/icons/icn_nav_job_applicant.svg"
      alt="applicants"
      class="nav__icon"
    />
    <span class="nav__label">
      求職者
    </span>
  </li>
  <li
    class="nav__item"
    routerLink="/enterprises"
    routerLinkActive="nav__item--active"
    [class.nav__item--selected]="activePopupMenuIndex === 2"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(2)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img
      src="/assets/icons/icn_nav_company.svg"
      alt="enterprises"
      class="nav__icon"
    />
    <span class="nav__label">
      企業
    </span>
  </li>
  <li
    class="nav__item"
    routerLink="/jobs"
    routerLinkActive
    #jobs="routerLinkActive"
    [class.nav__item--active]="jobs?.isActive && !jobFavorites?.isActive"
    [class.nav__item--selected]="activePopupMenuIndex === 3"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(3)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img
      src="/assets/icons/icn_nav_position.svg"
      alt="positions"
      class="nav__icon"
    />
    <span class="nav__label">
      求人
    </span>
  </li>
  <li
    *ngIf="favorites"
    class="nav__item"
    routerLink="/jobs/favorites"
    routerLinkActive
    #jobFavorites="routerLinkActive"
    [class.nav__item--active]="jobFavorites?.isActive"
    [class.nav__item--selected]="activePopupMenuIndex === 4"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(4)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img
      src="/assets/icons/icn_nav_position.svg"
      alt="position favorites"
      class="nav__icon"
    />
    <span class="nav__label nav__label--small">
      求人お気に入り
    </span>
    <span class="count">{{ favorites }}</span>
  </li>
  <li
    *ngIf="!isIntakeCa"
    class="nav__item"
    routerLink="/progresses"
    routerLinkActive="nav__item--active"
    [class.nav__item--selected]="activePopupMenuIndex === 5"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(5)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img
      src="/assets/icons/icn_nav_progress.svg"
      alt="progresses"
      class="nav__icon"
    />
    <span class="nav__label">
      進捗
    </span>
  </li>
  <li
    *ngIf="!isIntakeCa"
    class="nav__item"
    routerLink="/sales"
    routerLinkActive="nav__item--active"
    [class.nav__item--selected]="activePopupMenuIndex === 6"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(6)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img src="/assets/icons/icn_nav_uriage.svg" alt="sales" class="nav__icon" />
    <span class="nav__label">
      売り上げ
    </span>
  </li>
  <li
    *ngIf="!isIntakeCa"
    class="nav__item"
    routerLink="/counseling"
    routerLinkActive="nav__item--active"
    [class.nav__item--selected]="activePopupMenuIndex === 7"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(7)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img
      src="/assets/icons/icn_nav_counseling.svg"
      alt="counseling"
      class="nav__icon"
    />
    <span class="nav__label">
      セミナー
    </span>
  </li>
  <li
    class="nav__item"
    routerLink="/settings"
    routerLinkActive="nav__item--active"
    [class.nav__item--selected]="activePopupMenuIndex === 8"
    [class.nav__item--popup-open]="activePopupMenuIndex !== null"
    (mouseenter)="onMouseEnter(8)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <img
      src="/assets/icons/icn_nav_settings.svg"
      alt="settings"
      class="nav__icon"
    />
    <span class="nav__label">
      管理
    </span>
  </li>

  <ul
    class="popup-nav"
    [class.popup-nav--open]="activePopupConfig"
    [ngStyle]="{
      'padding-top': popupMenuPaddingTop + 'px'
    }"
    (mouseenter)="onMouseEnter(activePopupMenuIndex)"
    (mouseleave)="onMouseLeave()"
    (click)="onMouseLeave()"
  >
    <li
      *ngFor="let popupNav of activePopupConfig"
      class="popup-nav__item"
      (click)="openLink(popupNav.routerLink)"
    >
      {{ popupNav.label }}
    </li>
  </ul>
</ul>
