<ag-dynamic-form
  [metadata]="meta"
  [model]="model"
  (validated)="onValidation($event)"
></ag-dynamic-form>
<div class="footer">
  <div class="footer__container">
    <button class="footer__btn" (click)="close()" *ngIf="!inConfirm">
      キャンセル
    </button>
    <button
      class="footer__btn footer__btn--blue"
      [disabled]="!validity || !(model.to.length || model.condition)"
      (click)="confirm()"
      *ngIf="!inConfirm"
    >
      確認
    </button>
    <button class="footer__btn" (click)="cancel()" *ngIf="inConfirm">
      編集へ戻る
    </button>
    <button
      class="footer__btn footer__btn--blue"
      [disabled]="!(model.to.length || model.condition) || confirmDisabled"
      (click)="save()"
      *ngIf="inConfirm"
    >
      送信
    </button>
  </div>
</div>

<ng-template #fileSelect>
  <div class="file-table">
    <div class="file-table__head">ファイル名</div>
    <ng-container *ngIf="enterpriseFiles | keyvalue as enterpriseFilesObjs">
      <ng-container *ngFor="let fileObj of enterpriseFilesObjs">
        <div
          class="file-table__head"
          *ngIf="(enterpriseFilesObjs || []).length > 1 && !enterpriseIdForFile"
        >
          {{ enterpriseInfoById[fileObj.key] }}
        </div>
        <div
          class="file-table__file"
          *ngFor="
            let file of !enterpriseIdForFile ||
            +fileObj.key === enterpriseIdForFile
              ? fileObj.value
              : []
          "
        >
          <input
            class="file-table__check"
            type="checkbox"
            [ngModel]="enterpriseFileIds.includes(file.id)"
            (ngModelChange)="
              $event
                ? enterpriseFileIds.push(file.id)
                : enterpriseFileIds.splice(
                    enterpriseFileIds.indexOf(file.id),
                    1
                  )
            "
          />
          {{ file.name }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #studentFileSelect>
  <div class="file-table">
    <div class="file-table__head">ファイル名</div>
    <ng-container *ngIf="studentFiles | keyvalue as studentFilesObjs">
      <ng-container *ngFor="let fileObj of studentFilesObjs">
        <div
          class="file-table__head"
          *ngIf="(studentFilesObjs || []).length > 1 && !studentIdForFiles"
        >
          {{ studentInfoById[fileObj.key] }}
        </div>
        <div
          class="file-table__file"
          *ngFor="
            let file of !studentIdForFiles || +fileObj.key === studentIdForFiles
              ? fileObj.value
              : []
          "
        >
          <input
            class="file-table__check"
            type="checkbox"
            [ngModel]="studentFileIds.includes(file.id)"
            (ngModelChange)="
              $event
                ? studentFileIds.push(file.id)
                : studentFileIds.splice(studentFileIds.indexOf(file.id), 1)
            "
          />
          {{ file.name }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #progressError>
  <div class="progress-table">
    <div class="progress-table__title">
      下記の進捗データがすでに存在します。
    </div>
    <div class="progress-table__head">
      <div class="progress-table__head-item">進捗区分</div>
      <div class="progress-table__head-item">求職者ID、求職者名</div>
      <div class="progress-table__head-item">求人ID、ポジション名</div>
      <div class="progress-table__head-item">進捗ステータス</div>
    </div>
    <div class="progress-table__info" *ngFor="let info of progressErrorInfo">
      <div
        class="progress-table__info-item progress-table__info-item--labeled"
        [class.progress-table__info-item--labeled--AG]="info.progressType === 0"
        [class.progress-table__info-item--labeled--Plus]="
          info.progressType === 1
        "
        [class.progress-table__info-item--labeled--DR]="info.progressType === 2"
      ></div>
      <div class="progress-table__info-item">
        <span class="progress-table__info-item progress-table__info-item--id">{{
          info.studentFrontId
        }}</span>
        <span
          class="progress-table__info-item progress-table__info-item--name"
          >{{ info.studentLastName + info.studentFirstName }}</span
        >
      </div>
      <div class="progress-table__info-item">
        <span class="progress-table__info-item progress-table__info-item--id">{{
          info.jobFrontId
        }}</span>
        {{ info.position }}
      </div>
      <div class="progress-table__info-item progress-table__info-item--red">
        {{
          progressStatus[info.progressStatus] ||
            extendedProgressStatus[info.progressStatus]
        }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sendResult>
  <div class="send-message">
    {{
      sendResultInfo?.sendCount != null
        ? sendResultInfo.sendCount + "件メールを送信して推薦しました。"
        : sendResultInfo?.successes != null
        ? sendResultInfo.successes.length + "件メールを送信しました。"
        : "メールを送信しました。"
    }}
  </div>
  <div class="send-table" *ngIf="sendResultInfo?.errors?.length">
    <div class="send-table__title">
      下記はエラーによりメール送信できませんでした。エラー理由をご確認ください。
    </div>
    <div class="send-table__head">
      <div class="send-table__head-item">メールアドレス</div>
      <div class="send-table__head-item">エラー理由</div>
    </div>
    <div class="send-table__info" *ngFor="let info of sendResultInfo.errors">
      <div class="send-table__info-item">
        {{ info.to }}
      </div>
      <div class="send-table__info-item">
        {{ info.message }}
      </div>
    </div>
  </div>
</ng-template>
