<ag-dynamic-form
  *ngIf="metadata"
  [metadata]="metadata"
  [model]="student?.dynamicData"
  (validated)="valid = $event"
></ag-dynamic-form>

<div class="footer" *ngIf="!readonly">
  <div class="footer__container">
    <button
      class="footer__btn footer__btn--blue"
      [disabled]="!valid"
      (click)="save()"
    >
      更新
    </button>
  </div>
</div>
