import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, ElementRef, OnDestroy, Type, ViewContainerRef, } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogRef } from './dialog-ref';
var DialogComponent = /** @class */ (function () {
    function DialogComponent(componentFactoryResolver, cd, dialogRef) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.cd = cd;
        this.dialogRef = dialogRef;
        this.closeSubject = new Subject();
        this.onClose = this.closeSubject.asObservable();
    }
    DialogComponent.prototype.keyHandler = function (event) {
        if (event.code === 'Escape') {
            this.onCloseClicked();
        }
        else if (event.code === 'Enter' && !event['isComposing']) {
            this.dialogRef.confirmed.emit();
        }
    };
    DialogComponent.prototype.ngAfterViewInit = function () {
        this.loadChildComponent(this.childComponentType);
        this.cd.detectChanges();
    };
    DialogComponent.prototype.ngOnDestroy = function () {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    };
    Object.defineProperty(DialogComponent.prototype, "zIndex", {
        set: function (zIndex) {
            this.dialogEl.nativeElement.style.zIndex = zIndex + '';
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent.prototype.onOverlayClicked = function (evt) {
        // this.dialogRef.close();
    };
    DialogComponent.prototype.onCloseClicked = function (evt) {
        this.dialogRef.close();
        this.close();
    };
    DialogComponent.prototype.loadChildComponent = function (componentType) {
        this.viewContainerRef.clear();
        if (componentType) {
            var componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
            this.componentRef = this.viewContainerRef.createComponent(componentFactory);
        }
        this.dialogEl.nativeElement.tabIndex = -1;
        this.dialogEl.nativeElement.focus();
    };
    DialogComponent.prototype.close = function () {
        this.closeSubject.next();
    };
    return DialogComponent;
}());
export { DialogComponent };
