import * as tslib_1 from "tslib";
import { ContactHistoryAction, ContactHistoryEnterpriseAction } from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, DynamicFieldService, MasterApiService, StudentApiService, UserApiService, } from '@agent-ds/shared/services';
import { AfterViewInit, EventEmitter, OnChanges, OnDestroy, SimpleChanges, TemplateRef, } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { PopupControlComponent } from '../popup-control/popup-control.component';
import { CONTACT_HISTORY_TABLE_CONFIG } from './contact-history-tab-table-config';
var DEFAULT_TRUNCATE_AT = 300;
var DEFAULT_TRUNCATE_LINES = 3;
var ContactHistoryComponent = /** @class */ (function () {
    function ContactHistoryComponent(datePipe, dynamicFieldService, userService, masterApiService, authService) {
        this.datePipe = datePipe;
        this.dynamicFieldService = dynamicFieldService;
        this.userService = userService;
        this.masterApiService = masterApiService;
        this.authService = authService;
        this.errorGetContacts = new EventEmitter();
        this.actionId = 0;
        this.validForm = false;
        this.model = { message: '', userId: null, actionAt: new Date(), action: 1 };
        this.metadata = { groups: [] };
        this.truncateAt = {};
        this.defaultTruncateAt = DEFAULT_TRUNCATE_AT;
        this.defaultTruncateLines = DEFAULT_TRUNCATE_LINES;
    }
    ContactHistoryComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['service'] && this.service) {
            this.addFn = this.service.addContact.bind(this.service);
            this.updateFn = this.service.updateContact.bind(this.service);
            this.actionTypes = this.service instanceof StudentApiService ? ContactHistoryAction : ContactHistoryEnterpriseAction;
            this.contactHistoryActions = Object.keys(this.actionTypes)
                .filter(function (key) { return isNaN(key); })
                .map(function (key) { return ({
                label: key,
                value: _this.actionTypes[key],
            }); });
        }
        if (changes['referenceId']) {
            this.getContacts();
        }
    };
    ContactHistoryComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig = CONTACT_HISTORY_TABLE_CONFIG(this.responsibleTemplate, this.actionsTemplate, this.messageTemplate, function (date) { return _this.datePipe.transform(date, 'yyyy/MM/dd (E) HH:mm'); }, this.actionTypes);
        this.dynamicFieldService.fieldUpdateEvent.subscribe(function () {
            _this.metadata = {
                groups: [
                    {
                        class: 'ou',
                        rows: [
                            (function () {
                                var row = _this.dynamicFieldService.getFormRows({
                                    fieldName: 'actionAt',
                                    fieldType: 'date',
                                    displayType: 'date+today+time',
                                    label: '対応日',
                                    validationStyle: { required: true },
                                }, null, 'tall')[0];
                                row.fields[0].class = 'half ' + row.fields[0].class;
                                return row;
                            })(),
                            {
                                title: 'アクション',
                                fields: [
                                    {
                                        type: 'dropdown',
                                        name: 'action',
                                        class: 'half tall',
                                        options: _this.contactHistoryActions,
                                        labelField: 'label',
                                        valueField: 'value',
                                    },
                                ],
                            }
                        ].concat(_this.dynamicFieldService.getFormRows({
                            fieldName: 'userId',
                            fieldType: 'user',
                            label: '担当',
                            validationStyle: { required: true },
                        }, null, 'three-quarter'), [
                            {
                                fields: [{ type: 'hr', name: 'hr' }],
                            },
                            (function () {
                                var row = _this.dynamicFieldService.getFormRows({
                                    fieldName: 'nextContactAt',
                                    fieldType: 'date',
                                    displayType: 'date+today+time',
                                    label: '次回コンタクト日時',
                                }, null, 'tall')[0];
                                row.fields[0].class = 'half ' + row.fields[0].class;
                                return row;
                            })(),
                        ]),
                    },
                ],
            };
        });
        this.refreshEventSubscription = this.service.refreshEvent.subscribe(function () { return _this.getContacts(); });
    };
    ContactHistoryComponent.prototype.ngOnDestroy = function () {
        if (this.refreshEventSubscription) {
            this.refreshEventSubscription.unsubscribe();
            this.refreshEventSubscription = null;
        }
    };
    ContactHistoryComponent.prototype.isOpen = function (id) {
        return this.truncateAt[id] === null;
    };
    ContactHistoryComponent.prototype.isTruncated = function (text) {
        return text && (text.length > this.defaultTruncateAt || text.split('\n').length > DEFAULT_TRUNCATE_LINES);
    };
    ContactHistoryComponent.prototype.toggleText = function (id) {
        this.truncateAt[id] = this.truncateAt[id] !== null ? null : this.defaultTruncateAt;
    };
    ContactHistoryComponent.prototype.findUserTeam = function (userId) {
        var _this = this;
        return this.userService.getAll().pipe(mergeMap(function (users) {
            var user = users.find(function (u) { return u.id === userId; });
            if (user) {
                return _this.masterApiService.getTeams().pipe(map(function (teams) {
                    var team = teams.find(function (t) { return t.id === user.teamId; });
                    if (team) {
                        return { userName: user.name, teamName: team.name };
                    }
                    else {
                        return { userName: user.name, teamName: '' };
                    }
                }));
            }
            return null;
        }));
    };
    ContactHistoryComponent.prototype.getContacts = function (actionId) {
        var _this = this;
        if (this.referenceId) {
            if (actionId != null) {
                this.actionId = Number.parseInt(actionId, 10);
                this.filteredContacts = this.actionId ? this.contacts.filter(function (contact) { return contact.action === _this.actionId; }) : this.contacts;
            }
            else {
                this.contacts = [];
                this.service.getContacts(this.referenceId).subscribe(function (contacts) {
                    _this.contacts = contacts;
                    _this.filteredContacts = _this.actionId ? _this.contacts.filter(function (contact) { return contact.action === _this.actionId; }) : _this.contacts;
                }, function (error) {
                    _this.errorGetContacts.emit(error);
                });
            }
        }
    };
    ContactHistoryComponent.prototype.addContact = function () {
        var actionCallback = this.addFn;
        this.model = { message: '', userId: this.authService.loginUser.id, actionAt: new Date(), action: 1 };
        this.onShowForm(actionCallback);
    };
    ContactHistoryComponent.prototype.updateContact = function (contact) {
        var actionCallback = this.updateFn;
        this.model = tslib_1.__assign({}, contact);
        delete this.model.user;
        delete this.model.team;
        this.onShowForm(actionCallback);
    };
    ContactHistoryComponent.prototype.deleteContact = function (contactId) {
        var _this = this;
        PopupControlComponent.instance.open({
            title: '対応履歴の削除',
            content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
            confirmText: 'OK',
            cancelText: 'キャンセル',
            width: '600px',
            height: '357px',
            confirmCallback: function () {
                return _this.service.deleteContact(_this.referenceId, contactId).pipe(tap(function () {
                    _this.contacts.splice(_this.contacts.findIndex(function (contact) { return contact.id === contactId; }), 1);
                }));
            },
        });
    };
    ContactHistoryComponent.prototype.onShowForm = function (actionCallback) {
        var _this = this;
        PopupControlComponent.instance.open({
            title: '対応履歴の登録',
            content: this.contactHistoryFormTemplate,
            confirmText: '登録',
            confirmEnabled: function () { return _this.validForm; },
            confirmCallback: function () {
                _this.validForm = false;
                return actionCallback(_this.referenceId, _this.model).pipe(tap(function () {
                    _this.getContacts();
                }));
            },
            cancelText: 'キャンセル',
        });
    };
    return ContactHistoryComponent;
}());
export { ContactHistoryComponent };
