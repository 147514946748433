<div class="header">
  <span class="title">セミナー管理 │ 求職者別セミナー紹介求人一覧</span>
</div>
<div class="content" [class.content--no-footer]="readOnly">
  <div class="content__title">
    {{ data?.studentName ? data.studentName + "様 ┃ " : "" }}
    {{ data?.seminar?.areaName ? data.seminar.areaName + " ┃ " : "" }}
    {{ data?.seminar?.seminarAt | date: "yyyy/MM/dd（E）HH:mm〜" }}
    {{
      data?.seminar?.type != null
        ? "　　セミナー属性：" + data.seminar?.type
        : ""
    }}
  </div>
  <ag-job-list
    [requestObject]="requestObject"
    [content]="jobs"
    [options]="listConfiguration"
    [dynamicColumns]="selectorColumnConfig"
    [dynamicRows]="remarksRowConfig"
    [customActionsTemplate]="actions"
    [extraControls]="buttons"
    #jobList
  >
  </ag-job-list>
</div>

<div *ngIf="!readOnly" class="footer">
  <button
    [disabled]="!jobs?.length"
    class="footer__button btn btn--large btn--blue"
    (click)="saveApplications()"
  >
    更新
  </button>
</div>

<ng-template #selector let-data>
  <div
    *ngIf="!readOnly; else readOnlyApplication"
    class="container container--center-items"
    (click)="$event.stopPropagation()"
  >
    <ng-container *ngIf="model && model[data.id]">
      <div
        *ngFor="let application of applications"
        class="container__radio-item"
        [class.container__radio-item--active]="
          model[data.id].application === application.id
        "
        [class.container__radio-item--disabled]="
          data?.dynamicData?.status &&
          (data.dynamicData.status !== '募集中' &&
            data.dynamicData.status !== '推薦停止')
        "
      >
        <input
          class="radio"
          type="radio"
          name="sel_{{ data.id }}"
          [value]="application.id"
          [(ngModel)]="model[data.id].application"
          [disabled]="
            data?.dynamicData?.status &&
            (data.dynamicData.status !== '募集中' &&
              data.dynamicData.status !== '推薦停止')
          "
        />
        <label class="label">{{ application.name }}</label>
      </div>
    </ng-container>
    <div class="container__separator"></div>
  </div>
  <ng-template #readOnlyApplication>
    <div class="container container--center-items">
      <span class="application">{{
        data.application ? APPLICATION_TYPES[data.application] : "-"
      }}</span>
      <div class="container__separator"></div>
    </div>
  </ng-template>
</ng-template>

<ng-template #actions let-data>
  <div
    *ngIf="data.isSpecific"
    class="actions actions--margin"
    (click)="$event.stopPropagation()"
  >
    <span class="actions__badge">追加求人</span>
    <button
      *ngIf="!readOnly"
      class="actions__btn btn btn--small btn--outline-gray"
      (click)="deleteJob(data)"
    >
      削除
    </button>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="button__container">
    <div class="button__group">
      <button
        *ngIf="!readOnly"
        class="btn btn--green btn--large"
        (click)="openSeminarJobRegistrationDialog()"
      >
        セミナー紹介求人新規登録
      </button>
    </div>
  </div>
</ng-template>

<ng-template #summaryTable let-jobs="jobs">
  <table class="applications-summary__table">
    <thead>
      <tr>
        <th>企業ID, 企業名</th>
        <th>求人ID, ポジション名</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let job of jobs">
        <td>
          <span class="applications-summary__table--light">{{
            job.frontEnterpriseId
          }}</span>
          {{ job.enterpriseName }}
        </td>
        <td>
          <span class="applications-summary__table--light">{{
            job.frontJobId
          }}</span>
          {{ job.dynamicData?.position }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #applicationsSummary>
  <div class="applications-summary">
    <div class="applications-summary__subtitle">
      下記求人を応募、CA NG、辞退にします。よろしいですか？
    </div>
    <section
      *ngIf="applicationsSummaryJobs.apply.length"
      class="applications-summary__section"
    >
      <span class="applications-summary__label">応募求人</span>
      <ng-container
        [ngTemplateOutlet]="summaryTable"
        [ngTemplateOutletContext]="{ jobs: applicationsSummaryJobs.apply }"
      ></ng-container>
    </section>
    <section
      *ngIf="applicationsSummaryJobs.caNg.length"
      class="applications-summary__section"
    >
      <span class="applications-summary__label">CA NG</span>
      <ng-container
        [ngTemplateOutlet]="summaryTable"
        [ngTemplateOutletContext]="{ jobs: applicationsSummaryJobs.caNg }"
      ></ng-container>
    </section>
    <section class="applications-summary__section">
      <span class="applications-summary__label">上記以外の紹介求人は辞退</span>
    </section>
  </div>
</ng-template>

<ng-template #remarks let-data>
  <span class="remarks-container">
    <span class="remarks-container__info">備考</span>
    <div class="remarks">
      <span
        *ngIf="
          readOnly ||
          (data?.dynamicData?.status &&
            (data.dynamicData.status !== '募集中' &&
              data.dynamicData.status !== '推薦停止'))
        "
        class="remarks__text"
        title="{{ data.remarks }}"
      >
        {{ data.remarks || "" }}
      </span>
      <textarea
        *ngIf="
          !readOnly &&
          !(
            data?.dynamicData?.status &&
            (data.dynamicData.status !== '募集中' &&
              data.dynamicData.status !== '推薦停止')
          )
        "
        (keydown.enter)="$event.stopPropagation()"
        class="remarks__text remarks__text--editor"
        [defaultValue]="data.remarks || ''"
        [(ngModel)]="data.remarks"
        rows="1"
        style="height: 20px"
      ></textarea>
    </div>
  </span>
</ng-template>

<ng-template #errors>
  <div class="errors">
    <div class="errors__subtitle">
      <div>更新処理が完了しました。</div>
      <div>下記の求人については、求人ステータスが</div>
      <div>「募集中」「推薦停止」以外か、担当者が未登録か、</div>
      <div>進捗データが既に存在する為、処理をキャンセルしました。</div>
    </div>
    <table class="errors__table">
      <thead>
        <tr>
          <th>求人ID, ポジション名</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let job of errorJobs">
          <td>
            <span class="errors__table--light">{{ job.frontJobId }}</span>
            {{ job.dynamicData?.position }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
