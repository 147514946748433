import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { SortOrder } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ExportModel, ExportModelLabel } from '@agent-ds/shared/interfaces/export';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, ExportApiService, MailApiService } from '@agent-ds/shared/services';
import { OnInit, TemplateRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
var SignatureCsvSettingsPageComponent = /** @class */ (function () {
    function SignatureCsvSettingsPageComponent(datePipe, mailService, exportService, authService) {
        var _this = this;
        this.datePipe = datePipe;
        this.mailService = mailService;
        this.exportService = exportService;
        this.authService = authService;
        this.signatureMeta = {
            groups: [
                {
                    class: 'ou',
                    rows: [
                        {
                            title: '署名タイトル',
                            fields: [
                                {
                                    name: 'title',
                                    type: 'text',
                                    class: 'full tall',
                                    validators: { required: true },
                                },
                            ],
                        },
                        {
                            title: '内容',
                            fields: [
                                {
                                    name: 'body',
                                    type: 'textarea',
                                    class: 'full',
                                    validators: { required: true },
                                    style: {
                                        minRows: 15,
                                        maxRows: 15,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        };
        this.templateMeta = {
            groups: [
                {
                    class: 'ou',
                    rows: [
                        {
                            title: '出力データ',
                            fields: [
                                {
                                    name: 'model',
                                    type: 'radio',
                                    valueField: 'value',
                                    labelField: 'label',
                                    linkTo: ['baseColumns', 'colums'],
                                    options: Object.values(ExportModel).map(function (v) { return ({ label: ExportModelLabel[v], value: v }); }),
                                    validators: { required: true },
                                },
                            ],
                        },
                        {
                            title: 'タイトル',
                            fields: [
                                {
                                    name: 'name',
                                    type: 'text',
                                    class: 'full tall',
                                    validators: { required: true },
                                },
                            ],
                        },
                        {
                            title: '出力フィールド\t\t\t\t※ 改行で区切ってください\t\t\t利用可能なフィールド一覧',
                            class: 'top',
                            fields: [
                                {
                                    name: 'textColumns',
                                    type: 'textarea',
                                    class: 'fill mr-50',
                                    validators: { required: true },
                                    style: {
                                        minRows: 15,
                                        maxRows: 15,
                                    },
                                    supplier: function (value, callType, getValue) {
                                        var model = getValue('model');
                                        if (value && value.split('\n').find(function (v) { return !_this.templateFields[model].includes(v); })) {
                                            return null;
                                        }
                                    },
                                },
                                {
                                    name: 'baseColumns',
                                    type: 'textarea',
                                    transparent: true,
                                    disabled: true,
                                    class: 'fill',
                                    style: {
                                        minRows: 15,
                                        maxRows: 15,
                                    },
                                    supplier: function (value, callType, getValue) {
                                        var model = getValue('model');
                                        return _this.templateFields[model] ? _this.templateFields[model].join('\n') : '';
                                    },
                                },
                            ],
                        },
                        {
                            fields: [
                                {
                                    name: 'isPublic',
                                    type: 'checkbox',
                                    labelAfter: '他のユーザーにも公開する',
                                },
                            ],
                        },
                    ],
                },
            ],
        };
        this.signatures = [];
        this.templates = [];
        this.templateFields = {};
    }
    SignatureCsvSettingsPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.signatureTableConfig = {
            head: {
                config: [
                    {
                        columns: [
                            {
                                fields: [
                                    {
                                        name: 'title',
                                        title: '署名タイトル',
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    flex: 1,
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'actions',
                                        hidden: true,
                                        cellTemplate: this.signatureEditButtonTemplate,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            body: {
                checkbox: false,
                arrow: false,
            },
        };
        this.csvTableConfig = {
            head: {
                config: [
                    {
                        columns: [
                            {
                                fields: [
                                    {
                                        name: 'model',
                                        title: '出力データ',
                                        formatter: function (data) { return ExportModelLabel[data.model]; },
                                    },
                                ],
                                style: {
                                    width: '110px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'isPublic',
                                        title: '公開非公開',
                                        formatter: function (data) { return (data.isPublic ? '公開' : '非公開'); },
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    width: '110px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'name',
                                        title: 'タイトル',
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    flex: 1,
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'updatedAt',
                                        title: '更新日時',
                                        formatter: function (data) { return _this.datePipe.transform(data.updatedAt, 'yyyy/MM/dd HH:mm'); },
                                        defaultSort: SortOrder.DESCENDING,
                                    },
                                ],
                                style: {
                                    width: '115px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'actions',
                                        title: '',
                                        sortable: false,
                                        cellTemplate: this.csvEditButtonTemplate,
                                    },
                                ],
                                style: {
                                    width: '75px',
                                },
                            },
                        ],
                    },
                ],
            },
            body: {
                checkbox: false,
                arrow: false,
            },
        };
        if (this.authService.loginUser && this.authService.loginUser.role === 1) {
            forkJoin([
                this.exportService.getFields(ExportModel.student),
                this.exportService.getFields(ExportModel.enterprise),
                this.exportService.getFields(ExportModel.job),
                this.exportService.getFields(ExportModel.progress),
            ]).subscribe(function (_a) {
                var student = _a[0], enterprise = _a[1], job = _a[2], progress = _a[3];
                _this.templateFields[ExportModel.student] = student;
                _this.templateFields[ExportModel.enterprise] = enterprise;
                _this.templateFields[ExportModel.job] = job;
                _this.templateFields[ExportModel.progress] = progress;
            });
        }
        this.load();
    };
    SignatureCsvSettingsPageComponent.prototype.load = function () {
        var _this = this;
        if (this.authService.loginUser) {
            this.mailService.getSignature('user', this.authService.loginUser.id).subscribe(function (res) { return (_this.signatures = res); });
            if (this.authService.loginUser.role === 1) {
                forkJoin([
                    this.exportService.getTemplates(ExportModel.student),
                    this.exportService.getTemplates(ExportModel.enterprise),
                    this.exportService.getTemplates(ExportModel.job),
                    this.exportService.getTemplates(ExportModel.progress),
                ]).subscribe(function (_a) {
                    var _b;
                    var student = _a[0], enterprise = _a[1], job = _a[2], progress = _a[3];
                    _this.templates.length = 0;
                    (_b = _this.templates).push.apply(_b, student.templates.concat(enterprise.templates, job.templates, progress.templates));
                });
            }
        }
    };
    SignatureCsvSettingsPageComponent.prototype.openSignatureEdit = function (signature) {
        var _this = this;
        this.selectedSignature = signature || {};
        PopupControlComponent.instance.open({
            title: '署名設定',
            cancelText: this.selectedSignature.id ? '削除' : 'キャンセル',
            cancelClass: this.selectedSignature.id ? 'red' : null,
            cancelCallback: function () {
                if (_this.selectedSignature.id) {
                    _this.mailService
                        .deleteSignature(_this.selectedSignature.id)
                        .pipe(tap(function () { return _this.load(); }))
                        .subscribe();
                }
            },
            confirmText: '保存',
            width: '700px',
            height: '546px',
            content: this.signatureEditPopupTemplate,
            confirmEnabled: function () { return _this.signatureFormComponent && _this.signatureFormComponent.myForm.valid; },
            confirmCallback: function () {
                if (_this.selectedSignature.id) {
                    return _this.mailService.updateSignature(_this.selectedSignature.id, _this.selectedSignature).pipe(tap(function () { return _this.load(); }));
                }
                else {
                    return _this.mailService.addSignature('user', _this.authService.loginUser.id, _this.selectedSignature).pipe(tap(function () { return _this.load(); }));
                }
            },
        });
    };
    SignatureCsvSettingsPageComponent.prototype.openTemplateEdit = function (template) {
        var _this = this;
        this.selectedTemplate = template || { columns: [] };
        this.selectedTemplate['textColumns'] = this.selectedTemplate.columns.join('\n');
        PopupControlComponent.instance.open({
            title: 'CSV出力設定',
            cancelText: this.selectedTemplate.id ? '削除' : 'キャンセル',
            cancelClass: this.selectedTemplate.id ? 'red' : null,
            cancelCallback: function () {
                if (_this.selectedTemplate.id) {
                    _this.exportService
                        .deleteTemplate(_this.selectedTemplate.id)
                        .pipe(tap(function () { return _this.load(); }))
                        .subscribe();
                }
            },
            confirmText: '保存',
            width: '800px',
            height: '653px',
            content: this.csvEditPopupTemplate,
            confirmEnabled: function () { return _this.csvFormComponent && _this.csvFormComponent.myForm.valid; },
            confirmCallback: function () {
                _this.selectedTemplate.columns = _this.selectedTemplate['textColumns'].split('\n');
                if (_this.selectedTemplate.id) {
                    return _this.exportService.updateTemplate(_this.selectedTemplate.id, _this.selectedTemplate).pipe(tap(function () { return _this.load(); }));
                }
                else {
                    return _this.exportService.addTemplate(_this.selectedTemplate).pipe(tap(function () { return _this.load(); }));
                }
            },
        });
    };
    return SignatureCsvSettingsPageComponent;
}());
export { SignatureCsvSettingsPageComponent };
