import * as tslib_1 from "tslib";
import { HelperBase } from './helper-base';
var OverviewHelper = /** @class */ (function (_super) {
    tslib_1.__extends(OverviewHelper, _super);
    function OverviewHelper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OverviewHelper.prototype.updateFieldDefinitions = function () {
        this.meta = {
            // Overview
            title: '概要',
            class: 'form__group--golden-title-border',
            rows: [
                {
                    title: '企業',
                    fields: [
                        {
                            type: 'label',
                            name: 'enterprise.frontId',
                            class: 'auto',
                            supplier: function (value, callType, getter) {
                                var enterprise = getter('enterprise', true);
                                return enterprise ? enterprise.frontId + " - " + enterprise.dynamicData.name : '';
                            },
                        },
                    ],
                },
                {
                    title: '部署',
                    fields: [
                        {
                            type: 'label',
                            name: 'department.dynamicData.name',
                            class: 'auto',
                        },
                    ],
                },
                {
                    title: '求人',
                    fields: [
                        {
                            type: 'label',
                            name: 'job.frontId',
                            class: 'auto',
                            supplier: function (value, callType, getter) {
                                var job = getter('job', true);
                                return job ? job.frontId + " - " + job.dynamicData.position : '';
                            },
                        },
                    ],
                },
                {
                    title: '求職者',
                    fields: [
                        {
                            type: 'label',
                            name: 'student.frontId',
                            class: 'auto',
                            supplier: function (value, callType, getter) {
                                var student = getter('student', true);
                                return student
                                    ? [
                                        student.frontId,
                                        [[student.dynamicData.lastName, student.dynamicData.firstName].join(''), student.dynamicData.academicFieldType].join('　'),
                                    ].join(' - ')
                                    : '';
                            },
                        },
                    ],
                },
            ],
        };
    };
    OverviewHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.meta];
    };
    return OverviewHelper;
}(HelperBase));
export { OverviewHelper };
