<div
  class="autocomplete {{
    (extraClass && extraClass.includes('options-only')) || readonly
      ? 'options-only'
      : ''
  }}"
  agClickOutside
  (clickOutside)="onBlur($event)"
>
  <div class="input-container">
    <input
      #input
      class="input-container__input {{ extraClass }}"
      [ngClass]="{ 'input-container__input--disabled': readonly }"
      placeholder="{{ placeholder || '' }}"
      type="text"
      (keydown.enter)="onKeyDown($event)"
      (input)="onKeyDown($event)"
      [readonly]="readonly"
    />
    <svg
      class="input-container__clear-icon"
      *ngIf="value && !multi"
      (click)="clear()"
    >
      <use xlink:href="/assets/icons/btn_clear.svg#btn_clear"></use>
    </svg>
    <svg class="input-container__search-icon">
      <use xlink:href="/assets/icons/icn_search_blue.svg#icn_search"></use>
    </svg>
  </div>

  <div
    class="options-button"
    [ngClass]="{
      'options-button--disabled': readonly || !closed
    }"
    (click)="onArrowButtonClick()"
    [hidden]="hideBtn"
  >
    <span class="options-button__arrow options-button__arrow--down">選択</span>
  </div>

  <span class="enter-tooltip" *ngIf="showTooltipToEnter">
    {{
      manualInputOn != null
        ? "Enterキーで「" + input?.value + "」を確定"
        : customTooltipErrorMessage || "該当するものが見つかりません"
    }}
  </span>

  <div class="options-wrapper" [hidden]="closed">
    <div class="options-wrapper__head" *ngIf="multi && showCheckbox">
      <div class="options-wrapper__filter-holder">
        <ng-container
          *ngFor="let filter of filters | filter: true:'hidden':true"
        >
          <span
            class="options-wrapper__filter-label"
            *ngIf="filter.labelBefore"
            >{{ filter.labelBefore }}</span
          >
          <select
            class="select options-wrapper__filter-options {{ filter.class }}"
            (change)="onFilterChange(filter.name, $event.target.value)"
          >
            <option
              *ngFor="let option of filter.options; let i = index"
              [selected]="
                (filter.transparent ? transparentFilterValues : filterValues)[
                  filter.name
                ] ===
                (filter.valueField
                  ? getValueFromObject(option, filter.valueField)
                  : option)
              "
              [value]="i"
              >{{
                filter.labelField ? option[filter.labelField] : option
              }}</option
            >
          </select>
        </ng-container>
      </div>
      <span class="options-wrapper__counter" *ngIf="validators['max']">{{
        value.length + "/" + validators["max"]
      }}</span>
      <span
        class="options-wrapper__close close close--small"
        (click)="hideOptions()"
      ></span>
    </div>
    <div *ngIf="hasOptionHeader" class="options-wrapper__option-head">
      <div
        [ngClass]="{ 'options-wrapper__option-head-box': showCheckbox }"
      ></div>
      <div
        *ngFor="let field of labelField"
        class="options-wrapper__option-head-label {{ field.class }}"
      >
        {{ field.title || "" }}
      </div>
    </div>
    <div class="options-wrapper__options-holder">
      <div
        *ngFor="
          let option of showCheckbox
            ? filters && filters.length
              ? globalFilteredOptions
              : options
            : filteredOptions
        "
        class="options-wrapper__option"
        (mousedown)="onOptionSelect(option)"
      >
        <input
          type="checkbox"
          class="options-wrapper__checkbox"
          *ngIf="multi && showCheckbox"
          (click)="$event.preventDefault()"
          [checked]="compareAll(value, option)"
        />
        <div
          *ngFor="let item of optionTemplateArray"
          class="options-wrapper__option-fragment {{ item.class }}"
        >
          {{
            item.supplier
              ? item.supplier(option)
              : item.supplierAsync
              ? (item.supplierAsync(option) | async)
              : item.name
              ? getValueFromObject(option, item.name)
              : option
          }}
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="selected"
  *ngFor="
    let sel of multi
      ? selectedItems
      : readonly && selectedItem
      ? [selectedItem]
      : []
  "
  [ngClass]="{ 'selected--disabled': readonly }"
>
  <div class="selected__label-holder">
    <span
      *ngFor="let item of optionTemplateArray"
      class="selected__label {{ item.class }}"
      (click)="item.action ? item.action(sel) : ''"
      >{{
        item.supplier
          ? item.supplier(sel)
          : item.supplierAsync
          ? (item.supplierAsync(sel) | async)
          : item.name
          ? getValueFromObject(sel, item.name)
          : sel
      }}</span
    >
  </div>
  <span
    class="selected__delete"
    *ngIf="!readonly"
    (click)="onDeleteClick(sel)"
  ></span>
</div>
