import { downloadResponse, toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExportApiService = /** @class */ (function () {
    function ExportApiService(http) {
        this.http = http;
    }
    ExportApiService.prototype.export = function (model, templateId, params) {
        return this.http
            .get("/api/export/" + model + "/" + templateId, { responseType: 'blob', observe: 'response', params: params })
            .pipe(tap(function (res) { return downloadResponse(res); }));
    };
    ExportApiService.prototype.exportContracts = function (params) {
        return this.http
            .get('/api/enterprise/csv/contract/export', {
            responseType: 'blob',
            observe: 'response',
            params: params,
        })
            .pipe(tap(function (res) { return downloadResponse(res); }));
    };
    ExportApiService.prototype.exportSales = function (salesIds, requestObj) {
        return this.http
            .get('/api/sales/file/csv', {
            responseType: 'blob',
            observe: 'response',
            params: requestObj || { salesId: salesIds },
        })
            .pipe(tap(function (res) { return downloadResponse(res); }));
    };
    ExportApiService.prototype.exportInvoice = function (salesId) {
        return this.http
            .get("/api/sales/" + salesId + "/invoice/export", {
            responseType: 'blob',
            observe: 'response',
        })
            .pipe(tap(function (res) { return downloadResponse(res); }));
    };
    ExportApiService.prototype.exportProgresses = function (params) {
        return this.http
            .get('/api/progress/csv', {
            responseType: 'blob',
            observe: 'response',
            params: params,
        })
            .pipe(tap(function (res) { return downloadResponse(res); }));
    };
    ExportApiService.prototype.exportStudentsForSeminar = function (seminarId) {
        return this.http
            .get("/api/seminar/" + seminarId + "/student/excel", {
            responseType: 'blob',
            observe: 'response',
        })
            .pipe(tap(function (res) { return downloadResponse(res); }));
    };
    ExportApiService.prototype.exportJobsForSeminar = function (seminarId, jobIds) {
        return this.http
            .get("/api/seminar/" + seminarId + "/sheet", {
            responseType: 'blob',
            observe: 'response',
            params: toAsialRequestParameters({ jobIds: jobIds }),
        })
            .pipe(tap(function (res) { return downloadResponse(res); }));
    };
    ExportApiService.prototype.getFields = function (model) {
        return this.http.get('/api/export/templates/fields', { params: { model: model } });
    };
    ExportApiService.prototype.getTemplates = function (model, sort, order) {
        return this.http.get('/api/export/templates', {
            params: { model: model, sort: sort || 'updatedAt', order: order || 'desc' },
        });
    };
    ExportApiService.prototype.getTemplate = function (templateId) {
        return this.http.get("/api/export/templates/" + templateId);
    };
    ExportApiService.prototype.addTemplate = function (template) {
        return this.http.post('/api/export/templates', template);
    };
    ExportApiService.prototype.updateTemplate = function (id, template) {
        return this.http.put("/api/export/templates/" + id, template);
    };
    ExportApiService.prototype.deleteTemplate = function (templateId) {
        return this.http.delete("/api/export/templates/" + templateId);
    };
    ExportApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExportApiService_Factory() { return new ExportApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ExportApiService, providedIn: "root" });
    return ExportApiService;
}());
export { ExportApiService };
