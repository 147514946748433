<div class="header">
  <span class="header__title">月間KPI</span>
</div>
<div class="page">
  <div class="section">
    <div class="sub-header">
      <div class="sub-header__title">
        月間KPI検索
      </div>
    </div>
    <div class="form-container">
      <ag-dynamic-form
        *ngIf="formMetadata"
        #searchForm
        class="form-container__form"
        [metadata]="formMetadata"
        [model]="formModel"
        (validated)="onValidityChange($event)"
      ></ag-dynamic-form>
      <button
        class="form-container__btn btn btn--green"
        [disabled]="!valid"
        (click)="onSearch()"
      >
        検索
      </button>
    </div>
  </div>
  <div *ngIf="raKpiReports != null" class="section">
    <div class="sub-header">
      <div class="sub-header__title">
        求人担当
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th class="table--col-w-180">チーム名</th>
          <th class="table--col-w-130 table--br-dark">ユーザ名</th>
          <th class="table--padding-small">月初進捗人数</th>
          <th class="table--padding-small">月初進捗案件数</th>
          <th class="table--padding-small">月初進捗件数</th>
          <th class="table--padding-small">月初案件数</th>
          <th class="table--padding-small">AZ数</th>
          <th class="table--padding-small">打診数</th>
          <th class="table--padding-small">本人OK数</th>
          <th class="table--padding-small">営業書類提出数</th>
          <th class="table--padding-small">セミナー実施数</th>
          <th class="table--padding-small">一次面接実施数</th>
          <th class="table--padding-small">最終面接実施数</th>
          <th class="table--padding-small">内定数</th>
          <th class="table--padding-small table--br-dark">決定人数</th>
          <th class="table--padding-small">本人OK率</th>
          <th class="table--padding-small">一次移行率</th>
          <th class="table--padding-small">最終移行率</th>
          <th class="table--padding-small">内定移行率</th>
          <th class="table--padding-small">内定承諾率</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of raKpiReports">
          <td class="table--font-light table--bg-dark">
            {{ team?.name }}
          </td>
          <td class="table--font-light table--bg-dark table--br-dark">
            {{ report.userName }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.beginningCount.students || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.beginningCount.jobs || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.beginningCount.progresses || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.beginningCount.jobsUnderRecruitment || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.az || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.offerSubmissions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.salesDocuments || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.seminarCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.offers || 0 }}
          </td>
          <td
            class="table--font-dark table--font-right table--padding-small table--br-dark"
          >
            {{ report.count.acceptances || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.offers || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.acceptances || 0 }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="table--bg-dark table--font-bold">
          <td class="table--font-light  table--br-dark" colspan="2">合計</td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.beginningCount?.students || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.beginningCount?.jobsUnderRecruitment || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.beginningCount?.progresses || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.beginningCount?.jobs || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.az || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.offerSubmissions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.salesDocuments || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.seminarCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.count?.offers || 0 }}
          </td>
          <td
            class="table--font-dark table--font-right table--padding-small table--br-dark"
          >
            {{ raKpiTotal.count?.acceptances || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.percentage?.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.percentage?.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.percentage?.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.percentage?.offers || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ raKpiTotal.percentage?.acceptances || 0 }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div *ngIf="caKpiReports != null" class="section">
    <div class="sub-header">
      <div class="sub-header__title">
        求職者担当
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th class="table--col-w-180">チーム名</th>
          <th class="table--col-w-130 table--br-dark">ユーザ名</th>
          <th class="table--padding-small">月初進捗人数</th>
          <th class="table--padding-small">月初進捗件数</th>
          <th class="table--padding-small">カウ数</th>
          <th class="table--padding-small">打診数</th>
          <th class="table--padding-small">本人OK数</th>
          <th class="table--padding-small">営業書類提出数</th>
          <th class="table--padding-small">セミナー実施数</th>
          <th class="table--padding-small">一次面接実施数</th>
          <th class="table--padding-small">最終面接実施数</th>
          <th class="table--padding-small">内定数</th>
          <th class="table--padding-small table--br-dark">決定人数</th>
          <th class="table--padding-small">本人OK率</th>
          <th class="table--padding-small">一次移行率</th>
          <th class="table--padding-small">最終移行率</th>
          <th class="table--padding-small">内定移行率</th>
          <th class="table--padding-small">内定承諾率</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of caKpiReports">
          <td class="table--font-light table--bg-dark">
            {{ team?.name }}
          </td>
          <td class="table--font-light table--bg-dark table--br-dark">
            {{ report.userName }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.beginningCount.students || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.beginningCount.progresses || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.counseling || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.offerSubmissions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.salesDocuments || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.seminarCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.count.offers || 0 }}
          </td>
          <td
            class="table--font-dark table--font-right table--padding-small table--br-dark"
          >
            {{ report.count.acceptances || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.offers || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ report.percentage.acceptances || 0 }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="table--bg-dark table--font-bold">
          <td class="table--font-light  table--br-dark" colspan="2">合計</td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.beginningCount?.students || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.beginningCount?.progresses || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.counseling || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.offerSubmissions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.salesDocuments || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.seminarCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.count?.offers || 0 }}
          </td>
          <td
            class="table--font-dark table--font-right table--padding-small table--br-dark"
          >
            {{ caKpiTotal.count?.acceptances || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.percentage?.studentOks || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.percentage?.firstInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.percentage?.finalInterviewCompletions || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.percentage?.offers || 0 }}
          </td>
          <td class="table--font-dark table--font-right table--padding-small">
            {{ caKpiTotal.percentage?.acceptances || 0 }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
