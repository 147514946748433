import * as tslib_1 from "tslib";
import { APPROVAL_CANCEL_STATES, APPROVAL_STATES } from '@agent-ds/shared/constants';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { multiFill } from '@agent-ds/shared/util/util';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
var SalesSearchComponent = /** @class */ (function () {
    function SalesSearchComponent(dynamicService) {
        this.dynamicService = dynamicService;
        this.closed = new EventEmitter();
        this.search = new EventEmitter();
        this.innerSearchModel = {};
        this.metadataLeft = { groups: [] };
        this.metadataRight = { groups: [] };
    }
    Object.defineProperty(SalesSearchComponent.prototype, "searchModel", {
        get: function () {
            return this.innerSearchModel;
        },
        set: function (model) {
            this.innerSearchModel = tslib_1.__assign({}, model);
        },
        enumerable: true,
        configurable: true
    });
    SalesSearchComponent.prototype.getMultiDropdownField = function (field) {
        var row = this.dynamicService.getFormRows(field)[0];
        row.fields[0].valueField = 'code';
        row.fields[0].labelField = 'name';
        return row;
    };
    SalesSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadataLeft = {
                groups: [
                    {
                        title: '求職者担当',
                        class: 'form__group--golden-title-border',
                        rows: _this.dynamicService.getFormRows({
                            fieldName: 'caUserTeamId',
                            fieldType: 'multi-team',
                            displayType: 'dropdown',
                            label: '担当チーム',
                        }).concat(_this.dynamicService.getFormRows({
                            fieldName: 'caUserId',
                            fieldType: 'multi-user',
                            displayType: 'multi-user',
                            label: '担当者',
                        })),
                    },
                    {
                        title: '承認区分',
                        class: 'form__group--golden-title-border',
                        rows: [
                            _this.getMultiDropdownField({
                                label: '承認区分',
                                fieldType: 'multi-list',
                                displayType: 'dropdown',
                                fieldName: 'status',
                                validationStyle: { options: APPROVAL_STATES },
                            }),
                            _this.getMultiDropdownField({
                                label: 'キャンセル承認区分',
                                fieldType: 'multi-list',
                                displayType: 'dropdown',
                                fieldName: 'cancelStatus',
                                validationStyle: { options: APPROVAL_CANCEL_STATES },
                            }),
                        ],
                    },
                    {
                        title: '企業・求人・求職者情報',
                        class: 'form__group--golden-title-border',
                        rows: _this.dynamicService.getFormRows({
                            fieldName: 'enterpriseId',
                            fieldType: 'multi-enterprise',
                            displayType: 'multi-enterprise',
                            label: '企業',
                        }).concat(_this.dynamicService.getFormRows({
                            fieldName: 'jobId',
                            fieldType: 'multi-job',
                            displayType: 'multi-job',
                            label: '求人',
                        }), _this.dynamicService.getFormRows({
                            fieldName: 'studentId',
                            fieldType: 'multi-student',
                            displayType: 'multi-student',
                            label: '求職者',
                        })),
                    },
                ],
            };
            _this.metadataRight = {
                groups: [
                    {
                        title: '求人担当',
                        class: 'form__group--golden-title-border',
                        rows: _this.dynamicService.getFormRows({
                            fieldName: 'paraUserTeamId',
                            fieldType: 'multi-team',
                            displayType: 'dropdown',
                            label: '担当チーム',
                        }).concat(_this.dynamicService.getFormRows({
                            fieldName: 'paraUserId',
                            fieldType: 'multi-user',
                            displayType: 'multi-user',
                            label: '担当者',
                        })),
                    },
                    {
                        title: '成約情報',
                        class: 'form__group--golden-title-border',
                        rows: _this.dynamicService.getFormRows({
                            fieldName: 'salesId',
                            fieldType: 'number',
                            displayType: 'number',
                            label: '成約ID',
                        }, null, 'quarter').concat(_this.dynamicService.getFormRows(multiFill(tslib_1.__assign({}, _this.dynamicService.getDefinition('sales', 'invoiceConfirmation'), { displayType: 'checkbox', fieldType: 'multi-list' })), null, 'medium'), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('sales', 'billingLinkageStatus'))),
                    },
                    {
                        title: '日付',
                        class: 'form__group--golden-title-border',
                        rows: _this.dynamicService.getFormRows(multiFill(tslib_1.__assign({}, _this.dynamicService.getDefinition('sales', 'contractDate'), { fieldType: 'date-range', displayType: 'date-advanced' }))).concat(_this.dynamicService.getFormRows(multiFill(tslib_1.__assign({}, _this.dynamicService.getDefinition('sales', 'enterDate'), { fieldType: 'date-range', displayType: 'date-advanced' }))), _this.dynamicService.getFormRows({
                            fieldName: 'statusApprovedDate',
                            fieldType: 'date-range',
                            displayType: 'date-advanced',
                            label: '承認日',
                        }), _this.dynamicService.getFormRows({
                            fieldName: 'cancelStatusApprovedDate',
                            fieldType: 'date-range',
                            displayType: 'date-advanced',
                            label: 'キャンセル承認日',
                        }), _this.dynamicService.getFormRows({
                            fieldName: 'salesOrCancelApprovedDate',
                            fieldType: 'date-range',
                            displayType: 'date-advanced',
                            label: '成約日・\nキャンセル承認日',
                        })),
                    },
                ],
            };
        });
    };
    SalesSearchComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
    };
    SalesSearchComponent.prototype.onCloseClick = function () {
        this.closed.emit();
    };
    SalesSearchComponent.prototype.doSearch = function (event) {
        if (!event || !event['isComposing']) {
            this.search.emit(tslib_1.__assign({}, this.searchModel));
        }
    };
    return SalesSearchComponent;
}());
export { SalesSearchComponent };
