<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">新規セミナー登録</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__container">
    <ag-dynamic-form
      class="form"
      [metadata]="metadata"
      [model]="model"
      (validated)="onValidityChange($event)"
      (changed)="onFormChange($event)"
    ></ag-dynamic-form>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!isValid"
      (click)="create()"
    >
      登録
    </button>
  </div>
</div>

<ng-template #errors>
  <div class="errors">
    <div class="errors__subtitle">
      <div>セミナー登録が完了しました。</div>
      <div>下記のセミナーは既に登録済みです。</div>
    </div>
    <table class="errors__table">
      <thead>
        <tr>
          <th>開催日</th>
          <th>開催時間</th>
          <th>セミナー属性</th>
          <th>セミナーエリア</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let error of createErrors">
          <td>{{ error.date | date: "yyyy/MM/dd (E)" }}</td>
          <td>{{ error.time + "〜" }}</td>
          <td>{{ error.type }}</td>
          <td>{{ AREAS[error.area] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
