import * as tslib_1 from "tslib";
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { OnDestroy, OnInit } from '@angular/core';
import { DialogConfig } from '../dialog/dialog-config';
var FileUploadDialogComponent = /** @class */ (function () {
    function FileUploadDialogComponent(dialog, config) {
        this.dialog = dialog;
        this.config = config;
        this.uploadFilesData = [];
    }
    FileUploadDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return _this.upload(); });
    };
    FileUploadDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubScription) {
            this.fieldSubScription.unsubscribe();
        }
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    FileUploadDialogComponent.prototype.close = function (result) {
        this.dialog.close(result);
    };
    FileUploadDialogComponent.prototype.onFilesChanged = function (files) {
        var optionsObj = {};
        this.config.data.options.forEach(function (option) { return (optionsObj[option] = false); });
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            this.uploadFilesData.push({
                file: file,
                options: tslib_1.__assign({}, optionsObj),
            });
        }
    };
    FileUploadDialogComponent.prototype.upload = function () {
        if (this.uploadFilesData.length === 0 || this.dialog.inClose) {
            return;
        }
        this.close(this.uploadFilesData);
    };
    FileUploadDialogComponent.prototype.removeFile = function (index) {
        this.uploadFilesData.splice(index, 1);
    };
    return FileUploadDialogComponent;
}());
export { FileUploadDialogComponent };
