/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./persons-in-charge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/class.directive";
import * as i3 from "@angular/common";
import * as i4 from "./persons-in-charge.component";
import * as i5 from "../../services/api/company-api.service";
import * as i6 from "../../services/api/master-api.service";
import * as i7 from "../../services/api/user-api.service";
var styles_PersonsInChargeComponent = [i0.styles];
var RenderType_PersonsInChargeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonsInChargeComponent, data: {} });
export { RenderType_PersonsInChargeComponent as RenderType_PersonsInChargeComponent };
function View_PersonsInChargeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "list__empty"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u767B\u9332\u3055\u308C\u3066\u3044\u307E\u305B\u3093 "]))], null, null); }
function View_PersonsInChargeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list__badge badge"]], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.ClassDirective, [], { clazz: [0, "clazz"], value: [1, "value"], classOptions: [2, "classOptions"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "list__badge badge"; var currVal_2 = _co.departmentUserType[_v.context.$implicit]; var currVal_3 = _co.RAPACA_VALUE_BADGE_CLASSES; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).clazz; _ck(_v, 0, 0, currVal_0); var currVal_4 = _co.departmentUserType[_v.context.$implicit]; _ck(_v, 2, 0, currVal_4); }); }
function View_PersonsInChargeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "list__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "list__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "list__text"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "list__column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonsInChargeComponent_4)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "list__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "list__text list__text--light"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.types; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); var currVal_3 = _v.context.$implicit.teamName; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.teamName; _ck(_v, 9, 0, currVal_4); }); }
function View_PersonsInChargeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "list__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonsInChargeComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonsInChargeComponent_3)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.users.length === 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.users; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_PersonsInChargeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u62C5\u5F53\u8005\u4E00\u89A7"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonsInChargeComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupsToShow; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_PersonsInChargeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-persons-in-charge", [], null, null, null, View_PersonsInChargeComponent_0, RenderType_PersonsInChargeComponent)), i1.ɵdid(1, 638976, null, 0, i4.PersonsInChargeComponent, [i5.CompanyApiService, i6.MasterApiService, i7.UserApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonsInChargeComponentNgFactory = i1.ɵccf("ag-persons-in-charge", i4.PersonsInChargeComponent, View_PersonsInChargeComponent_Host_0, { companyId: "companyId", job: "job", groupType: "groupType" }, {}, []);
export { PersonsInChargeComponentNgFactory as PersonsInChargeComponentNgFactory };
