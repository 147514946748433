/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-contact-history-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/contact-history/contact-history.component.ngfactory";
import * as i3 from "../../../../../shared/components/contact-history/contact-history.component";
import * as i4 from "../../../../../shared/pipes/safe-date.pipe";
import * as i5 from "../../../../../shared/services/api/dynamic-field.service";
import * as i6 from "../../../../../shared/services/api/user-api.service";
import * as i7 from "../../../../../shared/services/api/master-api.service";
import * as i8 from "../../../../../shared/services/auth.service";
import * as i9 from "./student-contact-history-tab.component";
import * as i10 from "../../../../../shared/services/api/student-api.service";
import * as i11 from "../../../../../shared/services/dialog.service";
var styles_StudentContactHistoryTabComponent = [i0.styles];
var RenderType_StudentContactHistoryTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentContactHistoryTabComponent, data: {} });
export { RenderType_StudentContactHistoryTabComponent as RenderType_StudentContactHistoryTabComponent };
export function View_StudentContactHistoryTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-contact-history", [], null, [[null, "errorGetContacts"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("errorGetContacts" === en)) {
        var pd_0 = (_co.errorGetContactsCallback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ContactHistoryComponent_0, i2.RenderType_ContactHistoryComponent)), i1.ɵdid(1, 4898816, null, 0, i3.ContactHistoryComponent, [i4.SafeDatePipe, i5.DynamicFieldService, i6.UserApiService, i7.MasterApiService, i8.AuthService], { service: [0, "service"], referenceId: [1, "referenceId"] }, { errorGetContacts: "errorGetContacts" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.studentApiService; var currVal_1 = _co.studentId; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_StudentContactHistoryTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-student-contact-history-tab", [], null, null, null, View_StudentContactHistoryTabComponent_0, RenderType_StudentContactHistoryTabComponent)), i1.ɵdid(1, 49152, null, 0, i9.StudentContactHistoryTabComponent, [i10.StudentApiService, i11.DialogService], null, null)], null, null); }
var StudentContactHistoryTabComponentNgFactory = i1.ɵccf("ag-student-contact-history-tab", i9.StudentContactHistoryTabComponent, View_StudentContactHistoryTabComponent_Host_0, { studentId: "studentId" }, {}, []);
export { StudentContactHistoryTabComponentNgFactory as StudentContactHistoryTabComponentNgFactory };
