<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">
      {{ config.data.department.dynamicData ? "部署編集" : "新規部署登録" }}
    </h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="content">
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            *ngIf="metadataLeft"
            [metadata]="metadataLeft"
            [model]="model"
            (validated)="onValidityChange(0, $event)"
          ></ag-dynamic-form>
        </div>
        <div class="row__column">
          <ag-dynamic-form
            *ngIf="metadataResponsible"
            [metadata]="metadataResponsible"
            [model]="modelResponsible"
            (validated)="onValidityChange(1, $event)"
          ></ag-dynamic-form>
          <div
            *ngFor="
              let contactInfoMeta of metadataContactInformation;
              let i = index
            "
            class="contact-info"
          >
            <ag-dynamic-form
              class="contact-info__form"
              [metadata]="contactInfoMeta"
              [model]="model"
            >
            </ag-dynamic-form>
            <span
              *ngIf="i && contactInfoMeta"
              class="contact-info__delete"
              (click)="removeContactInfo(i)"
            >
              <span
                class="contact-info__close contact-info__close--medium no-hover"
              ></span>
              削除
            </span>
          </div>
          <button
            *ngIf="metadataContactInformation.length < CONTACT_LIMIT"
            class="add-contact-info-btn btn btn--big btn--dark"
            (click)="addContactInfo()"
          >
            <span class="plus"></span>
            <span class="add-contact-info-btn__label">担当者情報を追加</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!valid || dialog.inClose"
      (click)="create()"
    >
      {{ config.data.department.dynamicData ? "更新" : "登録" }}
    </button>
  </div>
</div>

<ng-template #deptSelect>
  <div class="dep-selector">
    <ag-select
      class="dep-selector__select"
      #addressSelect
      [options]="departmentDynamicList"
      labelField="name"
      valueField="address"
    ></ag-select>
    <button
      class="btn btn--small btn--dark"
      (click)="onAddressSelect(addressSelect.value)"
    >
      引用
    </button>
  </div>
</ng-template>
