/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-users-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/page-scroll-table/page-scroll-table.component.ngfactory";
import * as i3 from "../../../shared/components/page-scroll-table/page-scroll-table.component";
import * as i4 from "../../../shared/pipes/safe-date.pipe";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i7 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../../shared/pipes/truncate.pipe";
import * as i10 from "./settings-users-page.component";
import * as i11 from "../../../shared/services/api/providers/user-team-injector.provider";
import * as i12 from "../../../shared/services/api/user-api.service";
import * as i13 from "../../../shared/services/api/dynamic-field.service";
var styles_SettingsUsersPageComponent = [i0.styles];
var RenderType_SettingsUsersPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsUsersPageComponent, data: {} });
export { RenderType_SettingsUsersPageComponent as RenderType_SettingsUsersPageComponent };
function View_SettingsUsersPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "input-container__clear-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/btn_clear.svg#btn_clear"]], null, null, null, null, null))], null, null); }
function View_SettingsUsersPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-scroll-table", [["class", "table"]], null, null, null, i2.View_PageScrollTableComponent_0, i2.RenderType_PageScrollTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.PageScrollTableComponent, [i4.SafeDatePipe], { content: [0, "content"], scrollerClass: [1, "scrollerClass"], tableConfig: [2, "tableConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredUsers; var currVal_1 = "no-scroll"; var currVal_2 = _co.tableConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SettingsUsersPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "overflow"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_1); }); }
function View_SettingsUsersPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "overflow"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.email; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.email; _ck(_v, 1, 0, currVal_1); }); }
function View_SettingsUsersPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "overflow"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.ngIf.teamName; _ck(_v, 1, 0, currVal_1); }); }
function View_SettingsUsersPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SettingsUsersPageComponent_6)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.teamId && i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.userTeamProvider.getUserTeamInfoById(_v.context.$implicit.id)))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SettingsUsersPageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "edit-btn btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUserEdit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5909\u66F4 "]))], null, null); }
function View_SettingsUsersPageComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i6.View_DynamicFormComponent_0, i6.RenderType_DynamicFormComponent)), i1.ɵdid(2, 442368, [[6, 4], ["userForm", 4]], 0, i7.DynamicFormComponent, [i8.FormBuilder, i1.KeyValueDiffers, i4.SafeDatePipe, i9.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userPopupMeta; var currVal_1 = _co.userPopupModel; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SettingsUsersPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { editButtonTemplate: 0 }), i1.ɵqud(402653184, 2, { teamTemplate: 0 }), i1.ɵqud(402653184, 3, { nameTemplate: 0 }), i1.ɵqud(402653184, 4, { emailTemplate: 0 }), i1.ɵqud(402653184, 5, { userPopupTemplate: 0 }), i1.ɵqud(671088640, 6, { userFormComponent: 0 }), i1.ɵqud(402653184, 7, { searchInput: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7BA1\u7406\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 16, "div", [["class", "section section--horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "sub-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30E6\u30FC\u30B6\u30FC\u7BA1\u7406 "])), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, [[7, 0], ["searchInput", 1]], null, 5, "input", [["class", "input-container__input"], ["placeholder", "\u6C0F\u540D\u3001\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3067\u691C\u7D22"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.search = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i8.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DefaultValueAccessor]), i1.ɵdid(17, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(19, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsUsersPageComponent_1)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, ":svg:svg", [["class", "input-container__search-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/icn_search_blue.svg#icn_search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "btn btn--green section__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUserEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u65B0\u898F\u30E6\u30FC\u30B6\u30FC\u767B\u9332 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsUsersPageComponent_2)), i1.ɵdid(27, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[3, 2], ["name", 2]], null, 0, null, View_SettingsUsersPageComponent_3)), (_l()(), i1.ɵand(0, [[4, 2], ["email", 2]], null, 0, null, View_SettingsUsersPageComponent_4)), (_l()(), i1.ɵand(0, [[2, 2], ["team", 2]], null, 0, null, View_SettingsUsersPageComponent_5)), (_l()(), i1.ɵand(0, [[1, 2], ["editButton", 2]], null, 0, null, View_SettingsUsersPageComponent_7)), (_l()(), i1.ɵand(0, [[5, 2], ["userPopup", 2]], null, 0, null, View_SettingsUsersPageComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.search; _ck(_v, 17, 0, currVal_7); var currVal_8 = _co.search; _ck(_v, 21, 0, currVal_8); var currVal_9 = _co.tableConfig; _ck(_v, 27, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 19).ngClassValid; var currVal_5 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_SettingsUsersPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-settings-users-page", [], null, null, null, View_SettingsUsersPageComponent_0, RenderType_SettingsUsersPageComponent)), i1.ɵdid(1, 245760, null, 0, i10.SettingsUsersPageComponent, [i11.UserTeamInjectorProvider, i12.UserApiService, i13.DynamicFieldService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsUsersPageComponentNgFactory = i1.ɵccf("ag-settings-users-page", i10.SettingsUsersPageComponent, View_SettingsUsersPageComponent_Host_0, {}, {}, []);
export { SettingsUsersPageComponentNgFactory as SettingsUsersPageComponentNgFactory };
