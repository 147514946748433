<div class="area" (drop)="onDrop($event)" (dragover)="$event.preventDefault()">
  <div
    class="area__item"
    *ngFor="let item of value"
    [class.area__item--selected]="selectedItems.includes(item)"
    (click)="onItemClick($event, item)"
    (dragstart)="onDragStart($event, item)"
    (dragend)="onDragEnd($event, item)"
    draggable="true"
  >
    {{ labelField ? item[labelField] : item }}
  </div>
</div>
<span class="arrow arrow--up" *ngIf="!readonly" (click)="onUp()"></span>
<span class="arrow arrow--down" *ngIf="!readonly" (click)="onDown()"></span>
