<ag-company-detail-header
  [company]="company"
  [showOnlyMainInfo]="inSlimMode"
  [jobs]="jobList"
>
</ag-company-detail-header>
<ng-container *ngIf="inSlimMode; then slimMode; else tabMode"></ng-container>
<ng-template #slimMode>
  <div class="top-shadow"></div>
  <ag-persons-in-charge
    [companyId]="referenceId"
    [groupType]="'BRANCH'"
  ></ag-persons-in-charge>
</ng-template>
<ng-template #tabMode>
  <ag-tab-group class="tab-group" [(selectedIndex)]="selectedTabIndex">
    <ag-tab label="概要">
      <ag-company-overview-tab
        #companyOverviewTabComponent
        [company]="company"
        [contactHistory]="contactHistory"
        [jobs]="jobList"
        [innerInfo]="innerInfo"
        [sales]="sales"
        [contracts]="contracts"
        [isIntakeCa]="isIntakeCa"
        (selectedJobId)="onJobShow($event)"
      ></ag-company-overview-tab>
    </ag-tab>
    <ag-tab label="詳細">
      <ag-company-detail-tab [company]="company"></ag-company-detail-tab>
    </ag-tab>
    <ag-tab label="部署・請求先" *ngIf="!isIntakeCa">
      <ag-company-sections-tab
        [company]="company"
        (selectedJobId)="onJobShow($event)"
      ></ag-company-sections-tab>
    </ag-tab>
    <ag-tab label="契約・覚書" *ngIf="!isIntakeCa">
      <ag-company-contracts-tab [company]="company"></ag-company-contracts-tab>
    </ag-tab>
    <ag-tab label="進捗" *ngIf="!isIntakeCa">
      <ag-company-progress-tab
        [companyId]="referenceId"
      ></ag-company-progress-tab>
    </ag-tab>
    <ag-tab label="成約" *ngIf="!isIntakeCa">
      <ag-company-sales-tab [companyId]="referenceId"> </ag-company-sales-tab>
    </ag-tab>
    <ag-tab label="データフォルダ" *ngIf="!isIntakeCa">
      <ag-company-files-tab [company]="company"></ag-company-files-tab>
    </ag-tab>
    <ag-tab label="求人一覧">
      <ag-company-jobs-tab [companyId]="referenceId"></ag-company-jobs-tab>
    </ag-tab>
    <ag-tab label="対応履歴" *ngIf="!isIntakeCa">
      <ag-company-contact-history-tab
        [companyId]="referenceId"
      ></ag-company-contact-history-tab>
    </ag-tab>
    <ag-tab label="メール" *ngIf="!isIntakeCa">
      <ag-company-message-tab
        [companyId]="referenceId"
      ></ag-company-message-tab>
    </ag-tab>
    <ag-tab label="INNER情報">
      <ag-company-memo-tab [companyId]="referenceId"></ag-company-memo-tab>
    </ag-tab>
  </ag-tab-group>
</ng-template>
