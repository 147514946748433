import * as tslib_1 from "tslib";
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentBulkConfig } from '@agent-ds/shared/components/mail-send/configs/student-bulk-config';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { StudentListComponent } from '@agent-ds/shared/components/student-list/student-list.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { AuthService, DialogService, ExportApiService } from '@agent-ds/shared/services';
import { cleanObject } from '@agent-ds/shared/util/util';
import { BatchUpdateDialogComponent } from '@agent-ds/student/components/batch-update-dialog/batch-update-dialog.component';
import { Location } from '@angular/common';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentDetailPageComponent } from '../student-detail-page/student-detail-page.component';
var StudentListPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentListPageComponent, _super);
    function StudentListPageComponent(router, activeRoute, location, exportService, dialog, authService) {
        var _this = _super.call(this, router, location, activeRoute, exportService) || this;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.location = location;
        _this.exportService = exportService;
        _this.dialog = dialog;
        _this.authService = authService;
        _this.model = 'students';
        _this.detail = StudentDetailPageComponent;
        _this.exportModel = ExportModel.student;
        _this.listConfiguration = { body: { checkbox: true } };
        return _this;
    }
    StudentListPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.authService.isLogined().subscribe(function () {
            // 副担当者かどうかを確認
            var isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
            if (isIntakeCa) {
                _this.router.navigate(['/students/searchWithTodaysCounseling']);
            }
        });
    };
    StudentListPageComponent.prototype.sendMail = function () {
        var bulkSendConfig = new StudentBulkConfig();
        MailSendFlowComponent.instance.config = bulkSendConfig;
        bulkSendConfig.setParams({ condition: this.searchObj });
        MailSendFlowComponent.instance.start();
    };
    StudentListPageComponent.prototype.bulkUpdate = function () {
        this.dialog.open(BatchUpdateDialogComponent, {
            data: {
                condition: cleanObject(tslib_1.__assign({}, this.searchObj, { from: 0, size: this.table.totalSize })),
            },
        });
    };
    StudentListPageComponent.prototype.showExport = function () {
        var _this = this;
        PopupControlComponent.instance.open({
            title: 'CSV出力',
            content: this.exportTemplate,
            confirmText: 'ダウンロード',
            confirmEnabled: function () { return _this.exportTemplateId != null; },
            confirmCallback: function () { return _this.exportService.export(_this.exportModel, _this.exportTemplateId, _this.searchObj); },
            cancelText: '閉じる',
        });
    };
    return StudentListPageComponent;
}(ListPage));
export { StudentListPageComponent };
