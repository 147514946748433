<div class="block block--general">
  <div class="block__head">
    <span class="block__title">カウンセリング情報</span>
    <span class="block__control" (click)="gotoIndex.emit(tabs.PREFERENCES)"
      >編集</span
    >
  </div>
  <div class="block__body block__body">
    <span class="block__row block__row--header"></span>
    <span class="block__row block__row--header"></span>
    <span class="block__row block__row--title block__row--first"
      >カウンセリング日</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.interviewDate | safeDate: "yyyy/MM/dd (E)"
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >就活終了時期</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.activityFinish
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >就活終了時期補足</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.activityFinishRemarks
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >HR Analyst診断結果</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.hrAnalysis
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >ソーシャルタイプ</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.socialType?.join(", ")
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >コミュニケーション力</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.communicationSkill
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >印象・愛嬌</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.impressionAndCharm
    }}</span>
    <span
      class="block__row block__row--title block__row--first
      "
      >就活軸</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.jobHuntingAxis
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >カウンセリング内容</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.councelInformation
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >性格・タイプ①</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.characterAndType1
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >性格・タイプ②</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.characterAndType2
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >性格・タイプ③</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.characterAndType3
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >性格・タイプ④</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.characterAndType4
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >性格・タイプ⑤</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.characterAndType5
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >特筆すべき経験</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.exceptionalExperience
    }}</span>
    <span class="block__row block__row--title block__row--first">語学力</span>
    <span class="block__row block__row--content">{{
      student?.dynamicData.languageSkill
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >学生時代の活動内容</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.studentActivityUniversity
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >アルバイト</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.studentActivityParttime1
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >ゼミ・研究内容</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.researchContent
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >留年既卒理由</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.reasonRepeating
    }}</span>
    <span class="block__row block__row--title block__row--first">PG経験</span>
    <span class="block__row block__row--content">{{
      student?.dynamicData.pgExperience
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >PG経験（使用言語）</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.programmingExperienceLanguage
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >PG経験（開発内容）</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.programmingExperience
    }}</span>
  </div>
</div>

<div class="block block--files">
  <div class="block__head">
    <span class="block__title">データフォルダ</span>
    <span class="block__control" (click)="gotoIndex.emit(tabs.FILES)"
      >一覧</span
    >
  </div>
  <div class="block__body block__body--files">
    <span class="block__row block__row--header">名前</span>
    <span class="block__row block__row--header">種類</span>
    <span class="block__row block__row--header">サイズ</span>
    <span class="block__row block__row--header">登録日時</span>
    <ng-container *ngFor="let file of files">
      <span
        class="block__row block__row--title block__row--first block__row--link link"
        (click)="downloadFile(file)"
        >{{ file.name }}</span
      >
      <span class="block__row">{{
        file.name.indexOf(".") > -1 ? file.name.split(".").pop() : file.mimeType
      }}</span>
      <span class="block__row">{{ file.size | fileSize }}</span>
      <span class="block__row">{{
        file.createdAt | safeDate: "yyyy/MM/dd (E) HH:mm"
      }}</span>
    </ng-container>
  </div>
</div>

<div class="block block--desire">
  <div class="block__head">
    <span class="block__title">希望職種・業種</span>
    <span class="block__control" (click)="gotoIndex.emit(tabs.PREFERENCES)"
      >編集</span
    >
  </div>
  <div class="block__body block__body">
    <span class="block__row block__row--header"></span>
    <span class="block__row block__row--header"></span>
    <span class="block__row block__row--title block__row--first">転勤</span>
    <span class="block__row block__row--content">{{
      student?.dynamicData.jobTransfer
    }}</span>
    <span class="block__row block__row--title block__row--first">初任地</span>
    <span class="block__row block__row--content">{{
      student?.dynamicData.firstPlaceOfAssignment
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >希望業種1</span
    >
    <span class="block__row block__row--content">{{
      (
        (industryTypes | find: student?.dynamicData.requestIndustry1:"code") ||
        {}
      ).name
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >希望業種2</span
    >
    <span class="block__row block__row--content">{{
      (
        (industryTypes | find: student?.dynamicData.requestIndustry2:"code") ||
        {}
      ).name
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >希望業種3</span
    >
    <span class="block__row block__row--content">{{
      (
        (industryTypes | find: student?.dynamicData.requestIndustry3:"code") ||
        {}
      ).name
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >希望職種1</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.requestJobType1?.code1
        ? (
            (jobTypes
              | find: student?.dynamicData.requestJobType1?.code1:"code") || {}
          ).name +
          (student?.dynamicData.requestJobType1?.code2
            ? " > " +
              (
                (jobTypes
                  | find: student?.dynamicData.requestJobType1?.code2:"code") ||
                {}
              ).name
            : "")
        : ""
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >希望職種2</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.requestJobType2?.code1
        ? (
            (jobTypes
              | find: student?.dynamicData.requestJobType2?.code1:"code") || {}
          ).name +
          (student?.dynamicData.requestJobType2?.code2
            ? " > " +
              (
                (jobTypes
                  | find: student?.dynamicData.requestJobType2?.code2:"code") ||
                {}
              ).name
            : "")
        : ""
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >希望職種3</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.requestJobType3?.code1
        ? (
            (jobTypes
              | find: student?.dynamicData.requestJobType3?.code1:"code") || {}
          ).name +
          (student?.dynamicData.requestJobType3?.code2
            ? " > " +
              (
                (jobTypes
                  | find: student?.dynamicData.requestJobType3?.code2:"code") ||
                {}
              ).name
            : "")
        : ""
    }}</span>
    <span class="block__row block__row--title block__row--first">PG経験</span>
    <span class="block__row block__row--content">{{
      student?.dynamicData.pgExperience
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >ITタイプ（キャリア）</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.itTypeCareer
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >ITタイプ（領域）</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.itTypeArea
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >IT魅力フラグ</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.itAppealFlag
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >営業職フラグ</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.salesJobFlag
    }}</span>
    <span class="block__row block__row--title block__row--first"
      >営業職（商材）</span
    >
    <span class="block__row block__row--content">{{
      student?.dynamicData.salesJobProduct
    }}</span>
  </div>
</div>

<div class="block block--progress">
  <div class="block__head">
    <span class="block__title">進捗</span>
    <span class="block__control" (click)="gotoIndex.emit(tabs.PROGRESS)"
      >一覧</span
    >
  </div>
  <div class="summary">
    <span class="summary__part">
      <div class="summary__title">稼働件数</div>
      <span class="summary__count">
        <span class="summary__number">{{ progressesMain.length }}</span
        >件
      </span>
      <span>内Plus:{{ progressesMainPlus.length }}</span>
    </span>
    <span class="summary__part">
      <div class="summary__title">進捗件数</div>
      <span class="summary__count">
        <span class="summary__number">{{ progressesSub.length }}</span
        >件
      </span>
      <span>内Plus:{{ progressesSubPlus.length }}</span>
    </span>
    <span class="summary__part">
      <div class="summary__title">面接対策（対面）</div>
      <span class="summary__count">
        <span class="summary__number">{{
          (contacts | filter: 6:"action").length
        }}</span
        >件
      </span>
    </span>
    <span class="summary__part">
      <div class="summary__title">面接対策（非対面）</div>
      <span class="summary__count">
        <span class="summary__number">{{
          (contacts | filter: 7:"action").length
        }}</span
        >件
      </span>
    </span>
  </div>
  <div class="block__body block__body--progress">
    <span class="block__row block__row--header"></span>
    <span class="block__row block__row--header">更新日</span>
    <span class="block__row block__row--header">ステータス</span>
    <span class="block__row block__row--header">{{ "企業\n求人" }}</span>
    <ng-container *ngFor="let progress of progresses.slice(0, 5)">
      <span
        class="block__row block__row--labeled"
        [class.block__row--labeled--AG]="progress.type === 0"
        [class.block__row--labeled--Plus]="progress.type === 1"
        [class.block__row--labeled--DR]="progress.type === 2"
      ></span>
      <span class="block__row block__row--descriptor block__row--small">{{
        progress.updatedAt | safeDate: "yyyy/MM/dd(E)"
      }}</span>
      <span class="block__row">{{
        getStatusLabel(progress.status, progress.seminarType, progress.n)
      }}</span>
      <span class="block__row block__row--blue" style="display: block;">
        <div class="link" (click)="onCompanyLinkClick(progress.enterprise?.id)">
          {{ progress.enterprise?.name }}
        </div>
        <div class="link" (click)="onJobLinkClick(progress.job?.id)">
          {{ progress.job?.dynamicData.position }}
        </div>
      </span>
    </ng-container>
  </div>
</div>

<div class="block block--message">
  <div class="block__head">
    <span class="block__title">対応履歴</span>
    <span class="block__control" (click)="gotoIndex.emit(tabs.CONTACTS)"
      >一覧</span
    >
  </div>
  <div class="block__body block__body--message">
    <span class="block__row block__row--first-p">対応日</span>
    <span class="block__row">アクション</span>
    <span class="block__row">担当</span>
    <span class="block__row">次回コンタクト日時</span>
    <ng-container *ngFor="let contact of contacts.slice(0, 5)">
      <span class="block__row block__row--first-p block__row--noborder">{{
        contact.actionAt | safeDate: "yyyy/MM/dd (E) HH:mm"
      }}</span>
      <span class="block__row block__row--bold block__row--noborder">{{
        contactAction[contact.action]
      }}</span>
      <span class="block__row block__row--noborder">
        <ng-container *ngIf="findUserTeam(contact.userId) | async as info">
          {{ info.teamName }}<br />
          {{ info.userName }}
        </ng-container></span
      >
      <span class="block__row block__row--noborder">{{
        contact.nextContactAt | safeDate: "yyyy/MM/dd (E) HH:mm"
      }}</span>
      <span class="block__row block__row--first-p block__row--message">{{
        contact.message
      }}</span>
    </ng-container>
  </div>
</div>
