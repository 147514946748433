<div
  *ngIf="sale"
  class="sales-detail-header"
  [class.sales-detail-header--with-one-child]="showOnlyMainInfo"
>
  <div class="info" [class.info--full]="showOnlyMainInfo">
    <div class="main-info" [class.main-info--full]="showOnlyMainInfo">
      <div class="main-info__id">{{ sale?.frontId }}</div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">承認区分</div>
    <div class="info__values">
      <div class="info__value">
        {{ (APPROVAL_STATES | find: sale?.status:"code")?.name || "-" }}
      </div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">承認日</div>
    <div class="info__values">
      <div class="info__value">
        {{ sale?.approvedDate | safeDate: "yyyy/MM/dd" }}
      </div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">更新者</div>
    <div class="info__values">
      <div class="info__value">{{ mappedRegisterUser?.teamName }}</div>
      <div class="info__value">{{ mappedRegisterUser?.name }}</div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">入力日</div>
    <div class="info__values">
      <div class="info__pinned_value">
        {{ sale?.registeredAt | safeDate: "yyyy/MM/dd" }}
      </div>
    </div>
    <div class="info__label">更新日</div>
    <div class="info__values">
      <div class="info__pinned_value">
        {{ sale?.lastUpdatedAt | safeDate: "yyyy/MM/dd" }}
      </div>
    </div>
  </div>
  <div
    class="info info__export"
    [class.info--hidden]="
      showOnlyMainInfo || (sale?.status !== 2 && sale?.status !== 3)
    "
  >
    <button
      class="btn btn--green btn--small"
      [disabled]="inExport"
      (click)="exportInvoice()"
    >
      請求書
    </button>
  </div>
</div>
