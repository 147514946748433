<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">マッチング条件の登録</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="create">
      <ag-dynamic-form
        [metadata]="meta"
        [model]="model"
        (validated)="onValidityChange($event)"
      ></ag-dynamic-form>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!validity || dialog.inClose"
      (click)="create()"
    >
      登録
    </button>
  </div>
</div>
