<div class="header">
  <div
    class="header__title"
    [class.header__title--red]="title === 'INNER情報'"
    [class.header__title--hidden]="!title"
  >
    {{ title }}
  </div>
  <button
    class="btn btn--large btn--green"
    [disabled]="isCreateNewMemoInProgress"
    (click)="createNewMemo()"
  >
    {{ newText }}
  </button>
</div>

<ag-memo
  *ngIf="isCreateNewMemoInProgress"
  class="memo"
  [service]="service"
  [referenceId]="referenceId"
  [isCreateInProgress]="isCreateNewMemoInProgress"
  (createCanceled)="cancelNewMemo()"
  (saveSuccess)="getMemos()"
></ag-memo>
<ag-memo
  *ngFor="let memo of memos; index as i"
  class="memo"
  [service]="service"
  [referenceId]="referenceId"
  [memo]="memo"
  (deleteSuccess)="removeMemo(i)"
  (saveSuccess)="getMemos()"
></ag-memo>
