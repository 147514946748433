/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./memo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../pipes/safe-date.pipe";
import * as i4 from "../../../pipes/truncate.pipe";
import * as i5 from "../../dynamic-form/dynamic-form.component.ngfactory";
import * as i6 from "../../dynamic-form/dynamic-form.component";
import * as i7 from "@angular/forms";
import * as i8 from "./memo.component";
import * as i9 from "../../../services/api/master-api.service";
import * as i10 from "../../../services/api/dynamic-field.service";
import * as i11 from "../../../services/api/user-api.service";
import * as i12 from "../../../services/auth.service";
var styles_MemoComponent = [i0.styles];
var RenderType_MemoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MemoComponent, data: {} });
export { RenderType_MemoComponent as RenderType_MemoComponent };
function View_MemoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.ngIf.userName + " ") + _v.context.ngIf.teamName); _ck(_v, 1, 0, currVal_0); }); }
function View_MemoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "footer__open-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "footer__open-btn__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "footer__open-btn__arrow"]], [[2, "footer__open-btn__arrow--down", null], [2, "footer__open-btn__arrow--up", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isOpen ? "\u9589\u3058\u308B" : "\u958B\u304F"); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isOpen; var currVal_2 = _co.isOpen; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_MemoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MemoComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "actions__action btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "actions__action btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u524A\u9664 "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵppd(14, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MemoComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.findUserTeam(_co.memo.userId))); _ck(_v, 5, 0, currVal_1); var currVal_3 = ((((_co.memo.situationMemo || _co.memo.innerMemo) || _co.memo.memo) || "").length > _co.truncateAt); _ck(_v, 16, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.memo.registerDate, "yyyy/MM/dd (E)")); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent, 1), ((_co.memo.situationMemo || _co.memo.innerMemo) || _co.memo.memo), _co.truncateAt)); _ck(_v, 13, 0, currVal_2); }); }
function View_MemoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "footer__cancel-btn btn btn--medium btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCreateInProgress ? _co.cancelCreate() : _co.cancelEdit()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "footer__save-btn btn btn--medium btn--green"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCreateInProgress ? _co.create() : _co.update()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !((i1.ɵnov(_v.parent, 3) == null) ? null : i1.ɵnov(_v.parent, 3).myForm.valid); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.isCreateInProgress ? "\u4F5C\u6210" : "\u66F4\u65B0"); _ck(_v, 4, 0, currVal_1); }); }
export function View_MemoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeDatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i4.TruncatePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ag-dynamic-form", [["class", "form"]], [[8, "hidden", 0]], null, null, i5.View_DynamicFormComponent_0, i5.RenderType_DynamicFormComponent)), i1.ɵdid(3, 442368, [["form", 4]], 0, i6.DynamicFormComponent, [i7.FormBuilder, i1.KeyValueDiffers, i3.SafeDatePipe, i4.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MemoComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MemoComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.metadata; var currVal_2 = _co.model; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = (!_co.isEditInProgress && !_co.isCreateInProgress); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.isCreateInProgress || _co.isEditInProgress); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isCreateInProgress && !_co.isEditInProgress); _ck(_v, 2, 0, currVal_0); }); }
export function View_MemoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-memo", [], null, null, null, View_MemoComponent_0, RenderType_MemoComponent)), i1.ɵdid(1, 638976, null, 0, i8.MemoComponent, [i9.MasterApiService, i10.DynamicFieldService, i11.UserApiService, i12.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MemoComponentNgFactory = i1.ɵccf("ag-memo", i8.MemoComponent, View_MemoComponent_Host_0, { service: "service", referenceId: "referenceId", isCreateInProgress: "isCreateInProgress", memo: "memo" }, { createCanceled: "createCanceled", saveSuccess: "saveSuccess", deleteSuccess: "deleteSuccess" }, []);
export { MemoComponentNgFactory as MemoComponentNgFactory };
