<div class="table-actions">
  <button
    class="button--right btn btn--large btn--green"
    (click)="onReserveSeminar()"
  >
    セミナー予約
  </button>
</div>

<ag-page-scroll-table
  class="content__table"
  [tableConfig]="tableConfig"
  [content]="seminars"
></ag-page-scroll-table>

<ng-template #actions let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="onSeminarJobOffers(data)"
    >
      紹介求人一覧
    </button>
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      [disabled]="data.isSeminarAtPastDate || data.isApplied"
      (click)="onDeleteSeminar(data)"
    >
      削除
    </button>
  </div>
</ng-template>
