import { TemplatePortal } from '@angular/cdk/portal';
import { AfterContentInit, EventEmitter, OnDestroy, OnInit, QueryList, ViewContainerRef, } from '@angular/core';
import { TabComponent } from './tab/tab.component';
var TabGroupComponent = /** @class */ (function () {
    function TabGroupComponent(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
        this.selectedIndexChange = new EventEmitter();
        this.innerSelectedIndex = 0;
        this.tabSubscriptions = [];
        this.tabList = [];
        this.portals = [];
    }
    Object.defineProperty(TabGroupComponent.prototype, "selectedIndex", {
        get: function () {
            return this.innerSelectedIndex;
        },
        set: function (index) {
            this.innerSelectedIndex = index;
            this.selectedIndexChange.emit(this.innerSelectedIndex);
            this.tabList.forEach(function (tab) {
                if (tab.tabChild) {
                    tab.tabChild.selectedIndex = index;
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    TabGroupComponent.prototype.ngOnInit = function () { };
    TabGroupComponent.prototype.ngOnDestroy = function () {
        this.empty();
    };
    TabGroupComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.tabs.changes.subscribe(function () {
            _this.init();
        });
        this.init();
    };
    TabGroupComponent.prototype.init = function () {
        var _this = this;
        this.empty();
        this.tabList = this.tabs.toArray();
        this.portals = this.tabs.map(function (tab, tabIndex) {
            if (tab.tabChild) {
                tab.tabChild.tabIndex = tabIndex;
                _this.tabSubscriptions.push(tab.tabChild.gotoIndex.subscribe(function (index) { return (_this.selectedIndex = index); }));
            }
            return new TemplatePortal(tab.templateRef, _this.viewContainerRef);
        });
    };
    TabGroupComponent.prototype.empty = function () {
        this.tabSubscriptions.forEach(function (sub) { return sub.unsubscribe(); });
        this.tabSubscriptions.length = 0;
        this.portals.forEach(function (portal) {
            if (portal.isAttached) {
                portal.detach();
            }
        });
    };
    return TabGroupComponent;
}());
export { TabGroupComponent };
