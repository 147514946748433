/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-uploads-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/pipes/find.pipe";
import * as i4 from "../../../shared/components/page-scroll-table/page-scroll-table.component.ngfactory";
import * as i5 from "../../../shared/components/page-scroll-table/page-scroll-table.component";
import * as i6 from "../../../shared/pipes/safe-date.pipe";
import * as i7 from "./student-uploads-page.component";
import * as i8 from "../../../shared/services/api/file-api.service";
import * as i9 from "../../../shared/services/api/user-api.service";
import * as i10 from "../../../shared/services/api/master-api.service";
var styles_StudentUploadsPageComponent = [i0.styles];
var RenderType_StudentUploadsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentUploadsPageComponent, data: {} });
export { RenderType_StudentUploadsPageComponent as RenderType_StudentUploadsPageComponent };
function View_StudentUploadsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content__empty-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u8981\u78BA\u8A8D\u66F8\u985E\u306E\u30C7\u30FC\u30BF\u306F\u3042\u308A\u307E\u305B\u3093 "]))], null, null); }
function View_StudentUploadsPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.name; _ck(_v, 1, 0, currVal_0); }); }
function View_StudentUploadsPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 3, null, View_StudentUploadsPageComponent_3)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(3, 3), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.student.dynamicData.supportBranch && i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.branches)), _v.context.$implicit.student.dynamicData.supportBranch, "id"))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_StudentUploadsPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " "])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(3, 3), i1.ɵpod(4, { name: 0 })], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.name; var currVal_1 = (i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.teams)), _v.context.ngIf.teamId, "id")) || _ck(_v, 4, 0, "")).name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_StudentUploadsPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 3, null, View_StudentUploadsPageComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(3, 3), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.student.studentUsers && _v.context.$implicit.student.studentUsers.length) && i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.users)), _v.context.$implicit.student.studentUsers[0].userId, "id"))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_StudentUploadsPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.FindPipe, []), i1.ɵqud(671088640, 1, { table: 0 }), i1.ɵqud(402653184, 2, { branchTemplate: 0 }), i1.ɵqud(402653184, 3, { inChargeTemplate: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u30C7\u30FC\u30BF\u4E00\u89A7\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 15, "section", [["class", "uploads"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "content__count"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "content__count__number"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "content__count__pcs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u4EF6"])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "content__refresh"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "content__refresh-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, ":svg:svg", [["class", "content__refresh--svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/icn_refresh.svg#refresh"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6700\u65B0\u60C5\u5831\u306B\u66F4\u65B0 "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "ag-page-scroll-table", [["class", "content__table"]], null, [[null, "itemSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemSelected" === en)) {
        var pd_0 = (_co.onItemSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PageScrollTableComponent_0, i4.RenderType_PageScrollTableComponent)), i1.ɵdid(19, 638976, [[1, 4]], 0, i5.PageScrollTableComponent, [i6.SafeDatePipe], { supplier: [0, "supplier"] }, { itemSelected: "itemSelected" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentUploadsPageComponent_1)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["branch", 2]], null, 0, null, View_StudentUploadsPageComponent_2)), (_l()(), i1.ɵand(0, [[3, 2], ["inCharge", 2]], null, 0, null, View_StudentUploadsPageComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loadUploads; _ck(_v, 19, 0, currVal_1); var currVal_2 = (_co.initialized && !_co.table.totalSize); _ck(_v, 21, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.table == null) ? null : _co.table.totalSize); _ck(_v, 10, 0, currVal_0); }); }
export function View_StudentUploadsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-student-uploads-page", [], null, null, null, View_StudentUploadsPageComponent_0, RenderType_StudentUploadsPageComponent)), i1.ɵdid(1, 4767744, null, 0, i7.StudentUploadsPageComponent, [i8.FileApiService, i1.ChangeDetectorRef, i6.SafeDatePipe, i9.UserApiService, i10.MasterApiService], null, null)], null, null); }
var StudentUploadsPageComponentNgFactory = i1.ɵccf("ag-student-uploads-page", i7.StudentUploadsPageComponent, View_StudentUploadsPageComponent_Host_0, {}, {}, []);
export { StudentUploadsPageComponentNgFactory as StudentUploadsPageComponentNgFactory };
