import * as tslib_1 from "tslib";
import { ProgressListComponent } from '@agent-ds/shared/components';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { AuthService, ExportApiService } from '@agent-ds/shared/services';
import { Location } from '@angular/common';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressDetailComponent } from '../progress-detail/progress-detail.component';
var ProgressListPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressListPageComponent, _super);
    function ProgressListPageComponent(router, activeRoute, location, exportService, authService) {
        var _this = _super.call(this, router, location, activeRoute, exportService) || this;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.location = location;
        _this.exportService = exportService;
        _this.authService = authService;
        _this.model = 'progresses';
        _this.detail = ProgressDetailComponent;
        _this.exportModel = ExportModel.progress;
        _this.isIntakeCa = false;
        // 副担当者のログインかどうかを確認
        _this.loginSubscription = _this.authService.isLogined().subscribe(function (logined) {
            _this.isIntakeCa = _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
        });
        return _this;
    }
    ProgressListPageComponent.prototype.ngOnDestroy = function () {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
    };
    ProgressListPageComponent.prototype.showExport = function () {
        var _this = this;
        PopupControlComponent.instance.open({
            title: 'CSV出力',
            content: this.exportTemplate,
            confirmText: 'ダウンロード',
            confirmEnabled: function () { return _this.exportTemplateId != null; },
            confirmCallback: function () { return _this.exportService.export(_this.exportModel, _this.exportTemplateId, _this.searchObj); },
            cancelText: '閉じる',
        });
    };
    ProgressListPageComponent.prototype.proxySearch = function (obj) {
        this.doSearch(obj);
    };
    return ProgressListPageComponent;
}(ListPage));
export { ProgressListPageComponent };
