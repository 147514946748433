import * as tslib_1 from "tslib";
import { getProgressStatusLabel, NULL_SELECTED_VALUE, PROGRESS_STATUSES } from '@agent-ds/shared/constants';
import { ProgressActionType } from '@agent-ds/shared/enums';
import { ContactHistoryAction, EnterpriseDepartmentUserType, } from '@agent-ds/shared/interfaces';
import { AuthService, CompanyApiService, DynamicFieldService, JobApiService, MailApiService, ProgressApiService, StudentApiService, } from '@agent-ds/shared/services';
import { OutlookApiService } from '@agent-ds/shared/services/api/outlook-api.service';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { deepClone } from '@agent-ds/shared/util/util';
import { ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
var OutlookListComponent = /** @class */ (function () {
    function OutlookListComponent(outlookService, studentService, companyService, jobService, progressService, userInjector, dynamicService, authService, mailService, zone, host) {
        this.outlookService = outlookService;
        this.studentService = studentService;
        this.companyService = companyService;
        this.jobService = jobService;
        this.progressService = progressService;
        this.userInjector = userInjector;
        this.dynamicService = dynamicService;
        this.authService = authService;
        this.mailService = mailService;
        this.zone = zone;
        this.host = host;
        this.errorState = false;
        this.response = { parsed: true, type: 'student', data: { studentIds: [] } };
        this.selectableStudents = [];
        this.selectedDynamic = {};
        this.companies = [];
        this.jobs = [];
        this.progresses = [];
        this.headerCallbackState = false;
        this.contactHistoryActions = Object.keys(ContactHistoryAction)
            .filter(function (key) { return isNaN(key); })
            .map(function (key) { return ({
            label: key,
            value: ContactHistoryAction[key],
        }); });
        this.contactModel = { message: '', userId: null, actionAt: null, action: 1 };
        this.getStatusLabel = getProgressStatusLabel;
        this.PROGRESS_STATUSES = PROGRESS_STATUSES;
        this.PROGRESS_ACTION_TYPE = ProgressActionType;
        this.WINDOW = window;
        this.eventEditors = {};
        this.editorMeta = {};
        this.progressByStudentPage = 0;
        this.totalProgressCount = 0;
        this.innerLoading = false;
    }
    OutlookListComponent.prototype.ngOnInit = function () {
        var _this = this;
        Office.onReady(function () {
            if (!Office.context.mailbox) {
                console.warn('Office mailbox is not available.');
                return;
            }
            // Set up ItemChanged event
            Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, function () { return _this.zone.run(function () { return _this.messageChanged(); }); });
            _this.messageChanged();
        });
        this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.studentEditMeta = {
                groups: [
                    {
                        class: 'no-border no-background',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'registrationStatus')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'interviewStatus'))),
                    },
                ],
            };
            _this.contactMeta = {
                groups: [
                    {
                        title: '対応履歴',
                        class: 'no-border no-background form__group--no-title-border',
                        rows: [
                            (function () {
                                var row = _this.dynamicService.getFormRows({
                                    fieldName: 'actionAt',
                                    fieldType: 'date',
                                    displayType: 'date+today+time',
                                    label: '対応日',
                                    validationStyle: { required: true },
                                }, null, 'tall')[0];
                                row.fields[0].class = 'half ' + row.fields[0].class;
                                return row;
                            })(),
                            {
                                title: 'アクション',
                                fields: [
                                    {
                                        type: 'dropdown',
                                        name: 'action',
                                        class: 'half tall',
                                        options: _this.contactHistoryActions,
                                        labelField: 'label',
                                        valueField: 'value',
                                    },
                                ],
                            },
                            {
                                title: '担当',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'userId',
                                        supplier: function () { return _this.userInjector.getUserTeamNameById(_this.contactModel.userId); },
                                    },
                                ],
                            },
                            (function () {
                                var row = _this.dynamicService.getFormRows({
                                    fieldName: 'nextContactAt',
                                    fieldType: 'date',
                                    displayType: 'date+today+time',
                                    label: '次回コンタクト日時',
                                }, null, 'tall')[0];
                                row.fields[0].class = 'half ' + row.fields[0].class;
                                return row;
                            })(),
                            {
                                title: '内容',
                                fields: [
                                    {
                                        type: 'textarea',
                                        name: 'message',
                                        class: 'half tall',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
        });
        var dateTime = this.dynamicService.getFormRows({ fieldType: 'date', fieldName: 'seminarAt', displayType: 'date+time' })[0];
        dateTime.fields.push({
            type: 'radio',
            name: 'seminarType',
            labelField: 'label',
            valueField: 'value',
            options: [{ label: NULL_SELECTED_VALUE, value: 1 }, { label: '最終前面接', value: 99 }, { label: '最終面接', value: 100 }],
        });
        this.baseEditorMeta = {
            groups: [
                {
                    class: 'no-title-column no-border no-background no-padding outlook',
                    rows: [dateTime],
                },
            ],
        };
        this.host.nativeElement.onscroll = function (event) {
            if (_this.progresses.length < _this.totalProgressCount &&
                !_this.innerLoading &&
                event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) > 0.95) {
                _this.getNextProgresses();
            }
        };
    };
    OutlookListComponent.prototype.getMessageInfo = function () {
        var _this = this;
        this.progressByStudentPage = this.totalProgressCount = 0;
        this.innerLoading = false;
        this.errorState = false;
        this.outlookService.getMessageInfo(this.messageId).subscribe(function (res) {
            _this.selectableStudents.length = _this.companies.length = _this.jobs.length = _this.progresses.length = 0;
            _this.selectStudent(null);
            if (!res || !res.parsed) {
                _this.errorState = true;
                return;
            }
            _this.errorState = false;
            _this.response = res;
            if (_this.response.data.studentIds) {
                forkJoin(_this.response.data.studentIds.map(function (id) { return _this.studentService.getDetail(id); }))
                    .pipe(first())
                    .subscribe(function (details) {
                    _this.selectableStudents = details;
                    if (_this.selectableStudents.length === 1) {
                        _this.selectStudent(_this.selectableStudents[0]);
                    }
                });
            }
            if (_this.response.data.enterpriseIds) {
                forkJoin(_this.response.data.enterpriseIds.map(function (id) { return _this.companyService.getDetail(id); }))
                    .pipe(first())
                    .subscribe(function (details) { return (_this.companies = details); });
            }
            if (_this.response.data.jobIds) {
                forkJoin(_this.response.data.jobIds.map(function (id) { return _this.jobService.getDetail(id); }))
                    .pipe(first())
                    .subscribe(function (details) {
                    _this.jobs = details;
                    forkJoin(_this.jobs.map(function (detail) { return _this.companyService.getDetail(detail.enterpriseId); }))
                        .pipe(first())
                        .subscribe(function (enterprises) {
                        var _a;
                        return (_a = _this.companies).push.apply(_a, enterprises.filter(function (ent) { return !_this.companies.some(function (c) { return c.id === ent.id; }); }));
                    });
                });
            }
            if (_this.response.data.progressIds) {
                forkJoin(_this.response.data.progressIds.map(function (id) { return _this.progressService.getDetail(id); }))
                    .pipe(first())
                    .subscribe(function (details) {
                    details.forEach(function (detail) {
                        detail.student$ = _this.studentService.getDetail(detail.studentId);
                        detail.enterprise$ = _this.companyService.getDetail(detail.enterpriseId);
                        detail.job$ = _this.jobService.getDetail(detail.jobId);
                        var latestHistory = detail.progressHistories && detail.progressHistories[0];
                        detail.latestRevision = latestHistory && latestHistory.revision ? latestHistory.revision : 1;
                        detail.latestRemarks = latestHistory && latestHistory.remarks ? latestHistory.remarks : '';
                        detail.enterprise$.pipe(first()).subscribe(function (ent) {
                            if (_this.companies.length > 0 && _this.companies.some(function (c) { return c.id === ent.id; })) {
                                return;
                            }
                            _this.companies.push(ent);
                        });
                    });
                    _this.progresses = details;
                });
            }
        }, function () { return (_this.errorState = true); });
    };
    OutlookListComponent.prototype.isPatternMatchOfMessageId = function (value) {
        return value.match(/@mach.doda.jp/) || value.match(/^.*-.*-.*_.*/g) ? true : false;
    };
    OutlookListComponent.prototype.getHeaderCallback = function (asyncResult) {
        var _this = this;
        var startReferencesNum = asyncResult.value.search(/\nReferences:/);
        var endReferencesNum = asyncResult.value.slice(startReferencesNum).search(/\r\n.*:/g);
        this.messageId = asyncResult.value
            .slice(startReferencesNum, startReferencesNum + endReferencesNum)
            .replace(/\nReferences:| |\<|\>/g, '')
            .split('\r\n')
            .filter(function (id) { return _this.isPatternMatchOfMessageId(id); })
            .reduce(function (res, value) { return (res += value); }, '');
        this.headerCallbackState = true;
    };
    OutlookListComponent.prototype._getMessageInfo = function () {
        var _this = this;
        Office.context.mailbox.item.getAllInternetHeadersAsync(this.getHeaderCallback.bind(this));
        var id = setInterval(function () {
            if (_this.headerCallbackState) {
                if (!_this.messageId) {
                    _this.errorState = true;
                }
                else {
                    _this.getMessageInfo();
                }
                clearInterval(id);
            }
        }, 500);
    };
    OutlookListComponent.prototype.messageChanged = function () {
        var newId = (Office.context.mailbox.item.internetMessageId || '').replace(/\<|\>/g, '');
        if (newId === this.messageId) {
            return;
        }
        this.messageId = newId;
        !this.isPatternMatchOfMessageId(newId) ? this._getMessageInfo() : this.getMessageInfo();
    };
    OutlookListComponent.prototype.ngOnDestroy = function () {
        if (this.paramSubscription) {
            this.paramSubscription.unsubscribe();
            this.paramSubscription = null;
        }
    };
    OutlookListComponent.prototype.selectStudent = function (student) {
        this.progressByStudentPage = this.totalProgressCount = 0;
        this.innerLoading = false;
        this.selectedStudent = student;
        this.selectedDynamic = this.selectedStudent ? tslib_1.__assign({}, this.selectedStudent.dynamicData) : {};
        this.contactModel = { message: '', userId: this.authService.loginUser.id, actionAt: null, action: 1 };
        if (!this.progresses.length && this.selectedStudent) {
            this.getNextProgresses();
        }
    };
    OutlookListComponent.prototype.getNextProgresses = function () {
        var _this = this;
        if (this.innerLoading || !this.selectedStudent) {
            return;
        }
        this.innerLoading = true;
        this.progressService
            .getList({
            studentId: [this.selectedStudent.id],
            sort: 'updatedAt',
            order: 'desc',
            from: 20 * this.progressByStudentPage++,
            size: 20,
        })
            .pipe(first())
            .subscribe(function (progresses) {
            var _a;
            _this.innerLoading = false;
            _this.totalProgressCount = _this.totalProgressCount || progresses.total;
            (_a = _this.progresses).push.apply(_a, progresses.progresses
                .filter(function (p) { return !_this.progresses.some(function (pr) { return pr.id === p.id; }); })
                .map(function (p) {
                var pas = p;
                pas.student$ = _this.studentService.getDetail(p.studentId);
                pas.enterprise$ = p.enterprise ? _this.companyService.getDetail(p.enterprise.id) : of(null);
                pas.job$ = _this.jobService.getDetail(p.jobId);
                pas.enterpriseId = p.enterprise.id;
                return pas;
            }));
        });
    };
    OutlookListComponent.prototype.updateStudent = function () {
        var _this = this;
        this.studentService.update(this.selectedStudent.id, this.selectedStudent).subscribe(function (res) {
            return _this.studentService.getDetail(_this.selectedStudent.id).subscribe(function (detail) {
                _this.selectStudent(detail);
                for (var i = 0; i < _this.selectableStudents.length; i++) {
                    if (_this.selectableStudents[i].id === _this.selectedStudent.id) {
                        _this.selectableStudents[i] = _this.selectedStudent;
                        break;
                    }
                }
            });
        });
    };
    OutlookListComponent.prototype.createContact = function () {
        var _this = this;
        this.studentService
            .addContact(this.selectedStudent.id, this.contactModel)
            .subscribe(function (res) { return (_this.contactModel = { message: '', userId: _this.authService.loginUser.id, actionAt: null, action: 1 }); });
    };
    Object.defineProperty(OutlookListComponent.prototype, "loggedInText", {
        get: function () {
            if (this.selectedStudent && this.selectedStudent.mypageLoggedInAt) {
                var diff = Math.trunc((Date.now() - Date.parse(this.selectedStudent.mypageLoggedInAt.toString())) / 86400000);
                return diff < 1 ? '今日' : diff + '日前';
            }
            return '-';
        },
        enumerable: true,
        configurable: true
    });
    OutlookListComponent.prototype.onStudentDelayClick = function (progress, event) {
        event.preventDefault();
        event.stopPropagation();
        progress.isStudentDelayActionInProgress = true;
        this.progressService
            .updateWithoutAction(progress.id, {
            type: progress.type,
            hasInterview: progress.hasInterview,
            n: progress.n,
            seminarType: progress.seminarType,
            isStudentDelay: progress.isStudentDelay > 0 ? 0 : 1,
            seminarAt: progress.seminarAt,
            dynamicData: progress.dynamicData,
        })
            .subscribe(function () {
            progress.isStudentDelay = progress.isStudentDelay > 0 ? 0 : 1;
            progress.isStudentDelayActionInProgress = false;
        }, function () { return (progress.isStudentDelayActionInProgress = false); });
    };
    OutlookListComponent.prototype.onProgressSaveClick = function (progress) {
        var _this = this;
        if (this.eventEditors[progress.id] && this.eventEditors[progress.id].inSave) {
            return;
        }
        var eventRequestObject = null;
        var remarksRequestObject = null;
        var remarksRequest = null;
        var eventRequest = null;
        if (progress.latestRemarks !== undefined) {
            var remarks = progress.latestRemarks || '';
            remarksRequestObject = { remarks: remarks };
            remarksRequest = this.progressService.updateHistoryRemarks(progress.id, progress.latestRevision, remarksRequestObject);
        }
        if (this.eventEditors[progress.id]) {
            this.eventEditors[progress.id].inSave = true;
            var event_1 = this.eventEditors[progress.id];
            if (event_1.requestObject && event_1.seminarAt) {
                eventRequestObject = tslib_1.__assign({}, event_1.requestObject, {
                    seminarAt: event_1.seminarAt != null ? new Date(event_1.seminarAt).toAsialDateTimeString() : null,
                    seminarType: event_1.seminarType || 1,
                    seminarAtMailSendFlag: event_1.seminarAtMailSendFlag ? 0 : 1,
                });
                if (progress.status === 70) {
                    if (eventRequestObject.seminarType === 1) {
                        eventRequestObject.seminarType = 0;
                        eventRequestObject.action = 72;
                        eventRequestObject.n = progress.n;
                    }
                    else if (eventRequestObject.seminarType === 99 || eventRequestObject.seminarType === 100) {
                        eventRequestObject.action = 74;
                        eventRequestObject.n = (progress.n || 0) + 1;
                    }
                }
                eventRequest = this.progressService.updateWithAction(progress.id, eventRequestObject);
                if (remarksRequest) {
                    remarksRequest = this.progressService.updateHistoryRemarks(progress.id, (progress.latestRevision || 0) + 1, remarksRequestObject);
                }
            }
            if (!event_1.seminarAtMailSendFlag) {
                this.progressService.getStakeholders([progress.id]).subscribe(function (stakeholders) {
                    var stakeholder = stakeholders && stakeholders.length ? stakeholders[0] : null;
                    if (stakeholders && stakeholder.studentInfo.mainAvailable === '不可') {
                        alert('E-MAIL(メイン)が「送信不可」です。');
                        _this.eventEditors[progress.id].inSave = false;
                    }
                    else if (stakeholder) {
                        _this.mailService
                            .getMailTemplates()
                            .pipe(map(function (templates) {
                            return templates.find(function (template) { return template.id === (progress.type === 0 ? 14 : progress.type === 1 ? 19 : -1); });
                        }))
                            .subscribe(function (template) {
                            if (template) {
                                var mailRequestObject_1 = {
                                    to: stakeholder.studentInfo.mainAvailable !== '不可' ? [stakeholder.studentInfo.main] : null,
                                    cc: [
                                        stakeholder.studentInfo.subAvailable !== '不可' ? stakeholder.studentInfo.sub : null
                                    ].concat(stakeholder.jobUsersInfo.filter(function (jobUser) { return jobUser.type === 0; }).map(function (jobUser) { return jobUser.email; })).filter(function (v) { return v; }),
                                    bcc: stakeholder.jobUsersInfo
                                        .filter(function (jobUser) {
                                        return jobUser.type ===
                                            (progress.type === 0
                                                ? EnterpriseDepartmentUserType.RA
                                                : progress.type === 1
                                                    ? EnterpriseDepartmentUserType.PA
                                                    : -1);
                                    })
                                        .map(function (jobUser) { return jobUser.email; }).slice().filter(function (v) { return v; }),
                                    userId: _this.authService.loginUser.id,
                                    from: _this.authService.loginUser.email,
                                    subject: template.subject,
                                    text: template.body,
                                    templateTypeId: 8,
                                    jobId: progress.jobId,
                                };
                                var mailRequest_1 = _this.mailService
                                    .replaceTemplate(mailRequestObject_1.templateTypeId, {
                                    from: mailRequestObject_1.from,
                                    to: mailRequestObject_1.to,
                                    subject: mailRequestObject_1.subject,
                                    text: mailRequestObject_1.text,
                                    studentId: progress.studentId,
                                    jobId: progress.jobId,
                                })
                                    .pipe(mergeMap(function (res) {
                                    mailRequestObject_1.subject = res.subject;
                                    mailRequestObject_1.text = res.body;
                                    return _this.mailService.sendStudentMail(progress.studentId, mailRequestObject_1);
                                }));
                                if (eventRequest && remarksRequest) {
                                    eventRequest = eventRequest.pipe(mergeMap(function () { return remarksRequest.pipe(mergeMap(function () { return mailRequest_1; })); }));
                                }
                                else if (remarksRequest) {
                                    eventRequest = remarksRequest.pipe(mergeMap(function () { return mailRequest_1; }));
                                }
                                else {
                                    eventRequest = eventRequest.pipe(mergeMap(function () { return mailRequest_1; }));
                                }
                                eventRequest.subscribe(function () {
                                    _this.refreshProgress(progress.id, function () {
                                        _this.eventEditors[progress.id].inSave = false;
                                        _this.onProgressEditorCancelClick(progress.id);
                                    });
                                }, function () {
                                    _this.eventEditors[progress.id].inSave = false;
                                });
                            }
                            else {
                                _this.eventEditors[progress.id].inSave = false;
                            }
                        }, function () {
                            _this.eventEditors[progress.id].inSave = false;
                        });
                    }
                    else {
                        _this.eventEditors[progress.id].inSave = false;
                    }
                }, function () {
                    _this.eventEditors[progress.id].inSave = false;
                });
            }
            else if (eventRequest || remarksRequest) {
                if (eventRequest && remarksRequest) {
                    eventRequest = eventRequest.pipe(mergeMap(function () { return remarksRequest; }));
                }
                else if (remarksRequest) {
                    eventRequest = remarksRequest;
                }
                eventRequest.subscribe(function () {
                    _this.refreshProgress(progress.id, function () {
                        _this.eventEditors[progress.id].inSave = false;
                        _this.onProgressEditorCancelClick(progress.id);
                    });
                });
            }
            else {
                this.eventEditors[progress.id].inSave = false;
            }
        }
        else {
            progress.isOperationActionInProgress = true;
            remarksRequest.subscribe(function () {
                _this.refreshProgress(progress.id, function () { return _this.onProgressEditorCancelClick(progress.id); });
            });
        }
    };
    OutlookListComponent.prototype.onProgressEditorCancelClick = function (progressId) {
        if (this.eventEditors[progressId] && !this.eventEditors[progressId].inSave) {
            delete this.eventEditors[progressId];
        }
        var progress = this.progresses.find(function (p) { return p.id === progressId; });
        if (progress) {
            progress.isOperationActionInProgress = false;
        }
    };
    OutlookListComponent.prototype.onProgressActionClick = function (progress, action) {
        if (!progress || !action) {
            return;
        }
        switch (action.type) {
            case ProgressActionType.DELETE:
                this.onDeleteActionClick(progress);
                break;
            case ProgressActionType.DIALOG:
                this.openBrowser('jobs/' + progress.jobId);
                break;
            case ProgressActionType.MAIL:
                this.handleUnsupportedAction();
                break;
            case ProgressActionType.EDITOR:
                this.onEditorActionClick(progress.id, action.request ? action.request(progress, action.baseRequest) : null, action.seminarValues);
                break;
            case ProgressActionType.OPERATION:
                this.onOperationActionClick(progress, action.request ? action.request(progress, action.baseRequest) : null);
                break;
            default:
                return;
        }
    };
    OutlookListComponent.prototype.onDeleteActionClick = function (progress) {
        var _this = this;
        progress.isOperationActionInProgress = true;
        this.progressService.delete(progress.id).subscribe(function () {
            var index = _this.progresses.findIndex(function (p) { return p.id === progress.id; });
            progress.isOperationActionInProgress = false;
            _this.progresses.splice(index, 1);
        }, function () { return (progress.isOperationActionInProgress = false); });
    };
    OutlookListComponent.prototype.onEditorActionClick = function (progressId, requestObject, seminarValues) {
        if (!requestObject) {
            return;
        }
        if (seminarValues) {
            this.editorMeta[progressId] = deepClone(this.baseEditorMeta);
            this.editorMeta[progressId].groups[0].rows[0].fields.last().options = seminarValues;
        }
        else {
            this.editorMeta[progressId] = this.baseEditorMeta;
        }
        this.eventEditors[progressId] = {
            seminarAt: null,
            seminarType: 1,
            seminarAtMailSendFlag: false,
            requestObject: requestObject,
            inSave: false,
        };
    };
    OutlookListComponent.prototype.onOperationActionClick = function (progress, requestObject) {
        var _this = this;
        if (!requestObject) {
            return;
        }
        progress.isOperationActionInProgress = true;
        this.progressService.updateWithAction(progress.id, requestObject).subscribe(function () {
            _this.refreshProgress(progress.id, function () { return (progress.isOperationActionInProgress = false); });
        }, function () { return (progress.isOperationActionInProgress = false); });
    };
    OutlookListComponent.prototype.refreshProgress = function (progressId, success) {
        var _this = this;
        if (!this.progresses.some(function (progress) { return progress.id === progressId; })) {
            return;
        }
        this.progressService.getDetail(progressId).subscribe(function (progress) {
            var index = _this.progresses.findIndex(function (p) { return p.id === progressId; });
            _this.progresses[index].status = progress.status;
            _this.progresses[index].n = progress.n;
            _this.progresses[index].seminarType = progress.seminarType;
            var latestHistory = progress.progressHistories && progress.progressHistories[0];
            _this.progresses[index].latestRevision = latestHistory && latestHistory.revision ? latestHistory.revision : 1;
            _this.progresses[index].latestRemarks = latestHistory && latestHistory.remarks ? latestHistory.remarks : '';
            success();
        });
    };
    OutlookListComponent.prototype.openBrowser = function (link) {
        Office.context.ui['openBrowserWindow'](window.location.origin + '/' + link);
    };
    OutlookListComponent.prototype.handleUnsupportedAction = function () {
        // TODO: Change to japanese text
        alert('Please continue your work in a browser.');
    };
    return OutlookListComponent;
}());
export { OutlookListComponent };
