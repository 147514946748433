<div class="content">
  <div class="content__controls">
    <span class="content__count">{{
      table?.checkedItems.length || table?.totalSize
    }}</span>
    <span class="content__label">{{
      table?.checkedItems.length ? "件選択中" : "件"
    }}</span>
    <span class="content__filters"
      >※成約実績：３年前の4月1日〜前年度3月31日迄の期間</span
    >
    <div class="content__refresh" (click)="table.reset(true); table.next()">
      <div class="content__refresh-icon">
        <svg class="content__refresh--svg">
          <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
        </svg>
      </div>
      最新情報に更新
    </div>
    <button
      class="content__create-btn btn btn--green btn--larger"
      (click)="openCompanyCreateDialog()"
    >
      新規登録
    </button>
  </div>
  <ag-page-scroll-table
    class="content__table"
    [tableConfig]="tableConfig"
    [supplier]="loadList"
    (itemSelected)="onItemSelected($event)"
    (itemChecked)="onItemChecked($event)"
  ></ag-page-scroll-table>
</div>

<ng-template #companyName let-data>
  <span class="company-name">{{ data?.dynamicData?.name }}</span>
</ng-template>

<ng-template #agStatus let-data>
  <div class="status status--bottom-space">
    <div class="badge badge--blue">AG</div>
    {{ data?.dynamicData?.agClientStatus }}
    <div *ngIf="data.jobCount" class="status__order">オーダー</div>
  </div>
</ng-template>

<ng-template #drStatus let-data>
  <div class="status">
    <div class="badge badge--red-light">DR</div>
    {{ data?.dynamicData?.drClientStatus }}
  </div>
</ng-template>

<ng-template #representative let-data>
  <div
    class="representatives"
    *ngIf="
      userInjector.getUserTeamInfoById(data.userIds[0]) | async as userInfo
    "
  >
    <div
      *ngIf="userInfo.teamName"
      class="representatives__item"
      [title]="userInfo.teamName"
    >
      {{ userInfo.teamName }}
    </div>
    <div
      *ngIf="userInfo.name"
      class="representatives__item"
      [title]="userInfo.name"
    >
      {{ userInfo.name }}
    </div>
  </div>
</ng-template>

<ng-template #actions let-data>
  <div class="actions">
    <div
      class="actions__item"
      (click)="onCompanyLinkClick($event, data, 'userList')"
    >
      担当一覧
    </div>
    <div
      class="actions__item"
      (click)="onCompanyLinkClick($event, data, tabs[tabs.JOBS])"
    >
      求人一覧
    </div>
    <div
      class="actions__item actions__item--fill"
      (click)="onCompanyLinkClick($event, data, tabs[tabs.CONTRACTS])"
      *ngIf="!isIntakeCa"
    >
      契約一覧
    </div>
  </div>
</ng-template>
