<div class="toaster" *ngIf="!guard.CURRENT_USAGE">
  <div
    class="toaster__toast {{ toast.color }}"
    *ngFor="let toast of toasterService.toasts"
  >
    <div class="toast__head {{ toast.color }}">
      <svg class="toast__mark">
        <use xlink:href="/assets/icons/exclamation_mark.svg#exclamation"></use>
      </svg>
    </div>
    <div class="toast__content {{ toast.color }}">
      <p class="toast__title">{{ toast.title }}</p>
      <p class="toast__message">{{ toast.message }}</p>
    </div>
    <div class="toast__controls {{ toast.color }}">
      <span
        class="toast__close close close--bold"
        (click)="toasterService.removeToast(toast.key)"
      ></span>
    </div>
  </div>
</div>
<ag-layout [pageInfo]="pageInfo">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <ag-mail-send-flow *ngIf="!guard.CURRENT_USAGE"></ag-mail-send-flow>
  <ag-page-floater
    #jobFloater
    *ngIf="!guard.CURRENT_USAGE"
    [origUrl]="jobDetails.origUrl"
    [fullModeUrl]="jobDetails.fullModeUrl"
    [sideActions]="jobDetails.sideActions"
    [disableFullScreen]="jobDetails.disableFullScreen"
    [(selectedSideActionIndex)]="jobDetails.selectedSideActionIndex"
  >
    <ag-job-detail-page
      #jobDetails
      [inSlimMode]="jobFloater.selectedSideActionIndex !== 0"
      [inFullMode]="jobFloater.phaseIndex >= 1"
      (opened)="jobFloater.open()"
      (closed)="jobFloater.close()"
    ></ag-job-detail-page>
  </ag-page-floater>

  <ag-page-floater
    #progressFloater
    *ngIf="!guard.CURRENT_USAGE"
    [origUrl]="progressDetails.origUrl"
    [fullModeUrl]="progressDetails.fullModeUrl"
    [sideActions]="progressDetails.sideActions"
    [disableFullScreen]="progressDetails.disableFullScreen"
    [(selectedSideActionIndex)]="progressDetails.selectedSideActionIndex"
  >
    <ag-progress-detail
      #progressDetails
      [inSlimMode]="progressFloater.selectedSideActionIndex !== 0"
      [inFullMode]="progressFloater.phaseIndex >= 1"
      (opened)="progressFloater.open()"
      (closed)="progressFloater.close()"
    ></ag-progress-detail>
  </ag-page-floater>

  <ag-page-floater
    #salesFloater
    *ngIf="!guard.CURRENT_USAGE"
    [origUrl]="salesDetails.origUrl"
    [fullModeUrl]="salesDetails.fullModeUrl"
    [sideActions]="salesDetails.sideActions"
    [disableFullScreen]="salesDetails.disableFullScreen"
    [(selectedSideActionIndex)]="salesDetails.selectedSideActionIndex"
  >
    <ag-sales-detail-page
      #salesDetails
      [inSlimMode]="salesFloater.selectedSideActionIndex !== 0"
      [inFullMode]="salesFloater.phaseIndex >= 1"
      (opened)="salesFloater.open()"
      (closed)="salesFloater.close()"
    ></ag-sales-detail-page>
  </ag-page-floater>

  <ag-page-floater
    #studentFloater
    *ngIf="!guard.CURRENT_USAGE"
    [origUrl]="studentDetails.origUrl"
    [fullModeUrl]="studentDetails.fullModeUrl"
    [sideActions]="studentDetails.sideActions"
    [disableFullScreen]="studentDetails.disableFullScreen"
    [(selectedSideActionIndex)]="studentDetails.selectedSideActionIndex"
  >
    <ag-student-detail-page
      #studentDetails
      [inSlimMode]="studentFloater.selectedSideActionIndex !== 0"
      [inFullMode]="studentFloater.phaseIndex >= 1"
      (opened)="studentFloater.open()"
      (closed)="studentFloater.close()"
    ></ag-student-detail-page>
  </ag-page-floater>

  <ag-page-floater
    #companyFloater
    *ngIf="!guard.CURRENT_USAGE"
    [origUrl]="companyDetail.origUrl"
    [fullModeUrl]="companyDetail.fullModeUrl"
    [sideActions]="companyDetail.sideActions"
    [disableFullScreen]="companyDetail.disableFullScreen"
    [(selectedSideActionIndex)]="companyDetail.selectedSideActionIndex"
  >
    <ag-company-detail-page
      #companyDetail
      [inSlimMode]="companyFloater.selectedSideActionIndex !== 0"
      [inFullMode]="companyFloater.phaseIndex >= 1"
      (opened)="companyFloater.open()"
      (closed)="companyFloater.close()"
    ></ag-company-detail-page>
  </ag-page-floater>
  <ag-page-floater
    #stJobMatchingFloater
    *ngIf="!guard.CURRENT_USAGE"
    [origUrl]="stJobMatchingDetail.origUrl"
    [fullModeUrl]="stJobMatchingDetail.fullModeUrl"
    [sideActions]="stJobMatchingDetail.sideActions"
    [disableFullScreen]="stJobMatchingDetail.disableFullScreen"
    [(selectedSideActionIndex)]="stJobMatchingDetail.selectedSideActionIndex"
  >
    <ag-student-job-matching-page
      #stJobMatchingDetail
      [inFullMode]="stJobMatchingFloater.phaseIndex >= 0"
      (opened)="stJobMatchingFloater.open()"
      (closed)="stJobMatchingFloater.close()"
    ></ag-student-job-matching-page>
  </ag-page-floater>
  <ag-page-floater
    #jobStMatchingFloater
    *ngIf="!guard.CURRENT_USAGE"
    [origUrl]="jobStMatchingDetail.origUrl"
    [fullModeUrl]="jobStMatchingDetail.fullModeUrl"
    [sideActions]="jobStMatchingDetail.sideActions"
    [disableFullScreen]="jobStMatchingDetail.disableFullScreen"
    [(selectedSideActionIndex)]="jobStMatchingDetail.selectedSideActionIndex"
  >
    <ag-job-student-matching-page
      #jobStMatchingDetail
      [inFullMode]="jobStMatchingFloater.phaseIndex >= 0"
      (opened)="jobStMatchingFloater.open()"
      (closed)="jobStMatchingFloater.close()"
    ></ag-job-student-matching-page>
  </ag-page-floater>
  <ag-page-floater
    #seminarJobApplicationFloater
    *ngIf="!guard.CURRENT_USAGE"
    [sideActions]="seminarJobApplication.sideActions"
    [selectedSideActionIndex]="0"
  >
    <ag-seminar-job-application
      #seminarJobApplication
      (opened)="seminarJobApplicationFloater.open()"
    ></ag-seminar-job-application>
  </ag-page-floater>
</ag-layout>
<ag-popup-control></ag-popup-control>
<ag-popup-control></ag-popup-control>
<div class="loading" *ngIf="requestCount">
  <svg class="loading__spinner" viewBox="0 0 50 50">
    <circle class="loading__spinner__path" cx="25" cy="25" r="20"></circle>
  </svg>
</div>
