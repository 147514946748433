/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job-student-matching-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/chip/chip.component.ngfactory";
import * as i3 from "../../../shared/components/chip/chip.component";
import * as i4 from "../../../jobs/components/job-detail-header/job-detail-header.component.ngfactory";
import * as i5 from "../../../jobs/components/job-detail-header/job-detail-header.component";
import * as i6 from "../../../shared/services/api/job-api.service";
import * as i7 from "../../../shared/services/api/company-api.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../shared/services/auth.service";
import * as i10 from "../../../shared/components/student-list/student-list.component.ngfactory";
import * as i11 from "../../../shared/components/student-list/student-list.component";
import * as i12 from "../../../shared/services/api/student-api.service";
import * as i13 from "../../../shared/services/dialog.service";
import * as i14 from "../../../shared/pipes/safe-date.pipe";
import * as i15 from "../../../shared/services/api/master-api.service";
import * as i16 from "../../../shared/services/api/user-api.service";
import * as i17 from "@angular/router";
import * as i18 from "../../../student/components/student-search/student-search.component.ngfactory";
import * as i19 from "../../../student/components/student-search/student-search.component";
import * as i20 from "../../../shared/services/api/dynamic-field.service";
import * as i21 from "../../../jobs/pages/job-detail-page/job-detail-page.component.ngfactory";
import * as i22 from "../../../jobs/pages/job-detail-page/job-detail-page.component";
import * as i23 from "../../../shared/services/api/progress-api.service";
import * as i24 from "../../../shared/services/api/sales-api.service";
import * as i25 from "./job-student-matching-page.component";
import * as i26 from "../../../shared/services/api/matching-api.service";
var styles_JobStudentMatchingPageComponent = [i0.styles];
var RenderType_JobStudentMatchingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobStudentMatchingPageComponent, data: {} });
export { RenderType_JobStudentMatchingPageComponent as RenderType_JobStudentMatchingPageComponent };
function View_JobStudentMatchingPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-chip", [["class", "chip chip--green"]], null, [[null, "toggled"], [null, "removed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggled" === en)) {
        var pd_0 = (_co.matchingSelected($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("removed" === en)) {
        var pd_1 = (_co.deleteMatching(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ChipComponent_0, i2.RenderType_ChipComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChipComponent, [], { label: [0, "label"], selected: [1, "selected"] }, { removed: "removed", toggled: "toggled" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; var currVal_1 = (_v.context.$implicit.id === ((_co.selectedMatching == null) ? null : _co.selectedMatching.id)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_JobStudentMatchingPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "student-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u8077\u8005\u691C\u7D22\u30DE\u30C3\u30C1\u30F3\u30B0\u6761\u4EF6"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "header__modify"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30DE\u30C3\u30C1\u30F3\u30B0\u6761\u4EF6\u3092\u5909\u66F4"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "header__modify__arrow header__modify__arrow--left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ag-job-detail-header", [["class", "job-detail-header"]], null, null, null, i4.View_JobDetailHeaderComponent_0, i4.RenderType_JobDetailHeaderComponent)), i1.ɵdid(8, 245760, null, 0, i5.JobDetailHeaderComponent, [i6.JobApiService, i7.CompanyApiService, i8.Location, i9.AuthService], { job: [0, "job"], readonly: [1, "readonly"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "ag-student-list", [["class", "student-list-content"]], null, null, null, i10.View_StudentListComponent_0, i10.RenderType_StudentListComponent)), i1.ɵdid(10, 4964352, [[2, 4], ["matchingStudentList", 4]], 0, i11.StudentListComponent, [i12.StudentApiService, i1.ChangeDetectorRef, i13.DialogService, i14.SafeDatePipe, i15.MasterApiService, i16.UserApiService, i8.Location, i17.Router], { requestObject: [0, "requestObject"], options: [1, "options"], extraControls: [2, "extraControls"], job: [3, "job"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.job; var currVal_1 = true; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_2 = _co.studentSearchObj; var currVal_3 = _co.listConfiguration; var currVal_4 = i1.ɵnov(_v.parent, 27); var currVal_5 = _co.job; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_JobStudentMatchingPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "footer__btn footer__btn--green"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 15).doSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u691C\u7D22 "]))], null, null); }
function View_JobStudentMatchingPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "content__controls__control"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createProgress() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u9032\u6357\u767B\u9332 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((((_co.studentList == null) ? null : _co.studentList.checkedItems.length) > 20) || !((_co.studentList == null) ? null : _co.studentList.checkedItems.length)) || (((_co.studentList == null) ? null : _co.studentList.allChecked) && (((_co.studentList == null) ? null : _co.studentList.totalSize) > 20))); _ck(_v, 0, 0, currVal_0); }); }
export function View_JobStudentMatchingPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { jobDetails: 0 }), i1.ɵqud(671088640, 2, { studentList: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "content"]], [[2, "content--hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "matching-parameters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "matching-parameters__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "matching-parameters__header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u8077\u8005\u691C\u7D22\u30DE\u30C3\u30C1\u30F3\u30B0\u6761\u4EF6"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "matching-parameters__header__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u4FDD\u5B58\u3055\u308C\u305F\u691C\u7D22\u6761\u4EF6"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "matching-parameters__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobStudentMatchingPageComponent_1)), i1.ɵdid(13, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "ag-student-search", [["class", "student-search-matching"]], null, [[null, "search"], [null, "filterChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("search" === en)) {
        var pd_0 = (_co.doStudentSearch($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterChanged" === en)) {
        var pd_1 = (_co.onFilterChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i18.View_StudentSearchComponent_0, i18.RenderType_StudentSearchComponent)), i1.ɵdid(15, 245760, [["matchingStudentSearch", 4]], 0, i19.StudentSearchComponent, [i20.DynamicFieldService], { searchModel: [0, "searchModel"] }, { search: "search", filterChanged: "filterChanged" }), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "ag-job-detail-page", [["class", "job-detail"]], null, null, null, i21.View_JobDetailPageComponent_0, i21.RenderType_JobDetailPageComponent)), i1.ɵdid(18, 245760, [[1, 4], ["matchingJobDetails", 4]], 0, i22.JobDetailPageComponent, [i6.JobApiService, i17.ActivatedRoute, i17.Router, i23.ProgressApiService, i7.CompanyApiService, i24.SalesApiService, i9.AuthService], { inFullMode: [0, "inFullMode"], isReadonly: [1, "isReadonly"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobStudentMatchingPageComponent_2)), i1.ɵdid(20, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 5, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "footer__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "footer__btn footer__btn--outline-white footer__btn--bg-transparent footer__btn--hover-blue footer__btn--active-blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveMatching() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30DE\u30C3\u30C1\u30F3\u30B0\u6761\u4EF6\u3092\u4FDD\u5B58 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobStudentMatchingPageComponent_3)), i1.ɵdid(26, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["extraButtons", 2]], null, 0, null, View_JobStudentMatchingPageComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.matchings; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.studentSearchObj; _ck(_v, 15, 0, currVal_2); var currVal_3 = false; var currVal_4 = true; _ck(_v, 18, 0, currVal_3, currVal_4); var currVal_5 = _co.showList; _ck(_v, 20, 0, currVal_5); var currVal_6 = !_co.showList; _ck(_v, 26, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showList; _ck(_v, 2, 0, currVal_0); }); }
export function View_JobStudentMatchingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-job-student-matching-page", [], null, null, null, View_JobStudentMatchingPageComponent_0, RenderType_JobStudentMatchingPageComponent)), i1.ɵdid(1, 4440064, null, 0, i25.JobStudentMatchingPageComponent, [i26.MatchingApiService, i13.DialogService, i17.Router, i17.ActivatedRoute, i12.StudentApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JobStudentMatchingPageComponentNgFactory = i1.ɵccf("ag-job-student-matching-page", i25.JobStudentMatchingPageComponent, View_JobStudentMatchingPageComponent_Host_0, { referenceId: "referenceId", inFullMode: "inFullMode", inSlimMode: "inSlimMode", isReadonly: "isReadonly" }, { opened: "opened", closed: "closed" }, []);
export { JobStudentMatchingPageComponentNgFactory as JobStudentMatchingPageComponentNgFactory };
