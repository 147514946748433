/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-change-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../pipes/safe-date.pipe";
import * as i6 from "../../pipes/truncate.pipe";
import * as i7 from "./password-change-dialog.component";
import * as i8 from "../../services/auth.service";
import * as i9 from "../../services/dialog.service";
import * as i10 from "../dialog/dialog-ref";
var styles_PasswordChangeDialogComponent = [i0.styles];
var RenderType_PasswordChangeDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordChangeDialogComponent, data: {} });
export { RenderType_PasswordChangeDialogComponent as RenderType_PasswordChangeDialogComponent };
export function View_PasswordChangeDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { form: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "login__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "login__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "/assets/images/mach.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "login__wrapper"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ag-dynamic-form", [], [[4, "width", null]], null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(8, 442368, [[1, 4]], 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "login__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "login__button btn btn--larger btn--blue-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangePassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5909\u66F4 "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.pwChangeMeta; var currVal_3 = _co.credentials; _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "80%"; _ck(_v, 6, 0, currVal_0); var currVal_1 = "100%"; _ck(_v, 7, 0, currVal_1); var currVal_4 = !(((_co.credentials.oldPassword && _co.credentials.oldPassword2) && _co.credentials.newPassword) && _co.credentials.newPassword2); _ck(_v, 10, 0, currVal_4); }); }
export function View_PasswordChangeDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-password-change-dialog", [], null, null, null, View_PasswordChangeDialogComponent_0, RenderType_PasswordChangeDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.PasswordChangeDialogComponent, [i8.AuthService, i9.DialogService, i10.DialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PasswordChangeDialogComponentNgFactory = i1.ɵccf("ag-password-change-dialog", i7.PasswordChangeDialogComponent, View_PasswordChangeDialogComponent_Host_0, {}, {}, []);
export { PasswordChangeDialogComponentNgFactory as PasswordChangeDialogComponentNgFactory };
