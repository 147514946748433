import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailSendConfig } from '../config';
var ProgressSendInterviewInfoConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressSendInterviewInfoConfig, _super);
    function ProgressSendInterviewInfoConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressSendInterviewInfoConfig.prototype.getTemplateId = function () {
        if (this.sender.model.progressType === 0) {
            return 15;
        }
        else if (this.sender.model.progressType === 1) {
            return 20;
        }
        return;
    };
    ProgressSendInterviewInfoConfig.prototype.getTemplateTypeId = function () {
        return [9];
    };
    ProgressSendInterviewInfoConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.stakeholders = params.stakeholders || this.sender.model.stakeholders;
            this.sender.model.progressId = params.progressId || this.sender.model.progressId;
            this.sender.model.progressType = params.progressType;
            this.sender.model.progressStatus = params.progressStatus;
            this.sender.model.progressSeminarType = params.progressSeminarType;
            this.sender.model.progressSeminarAt = params.progressSeminarAt;
            this.sender.model.studentId = params.studentId || this.sender.model.studentId;
            this.sender.model.jobId = params.jobId || this.sender.model.jobId;
            this.sender.model.enterpriseId = params.enterpriseId || this.sender.model.enterpriseId;
        }
        _super.prototype.setParams.call(this, params);
    };
    ProgressSendInterviewInfoConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.sender.model.stakeholders) {
            return;
        }
        var stakeholders = this.sender.model.stakeholders;
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        this.sender.model.cc_single_hidden = stakeholders.studentInfo.sub != null && stakeholders.studentInfo.sub !== '';
        metaData.groups[0].rows.push({
            title: 'To (求職者)',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'to',
                        type: 'checkbox',
                        labelField: 'label',
                        valueField: 'value',
                        multi: true,
                        disabled: stakeholders.studentInfo.mainAvailable === '不可',
                        options: stakeholders.studentInfo.main
                            ? [
                                {
                                    label: "" + (stakeholders.studentInfo.mainAvailable === '不可' ? '【送信不可】' : '') + (stakeholders.studentInfo
                                        .lastName + stakeholders.studentInfo.firstName) + " \u30E1\u30A4\u30F3 <" + stakeholders.studentInfo.main + ">",
                                    value: stakeholders.studentInfo.main,
                                },
                            ]
                            : [],
                        validators: { required: true },
                    }
                    : {
                        name: 'to',
                        type: 'label',
                        supplier: function () {
                            return "" + (stakeholders.studentInfo.mainAvailable === '不可' ? '【送信不可】' : '') + (stakeholders.studentInfo.lastName +
                                stakeholders.studentInfo.firstName) + " \u30E1\u30A4\u30F3 <" + stakeholders.studentInfo.main + ">";
                        },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (求職者、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            if (!_this.sender.inConfirm) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'checkbox',
                    labelField: 'label',
                    valueField: 'value',
                    class: 'full',
                    multi: true,
                    disabled: stakeholders.studentInfo.subAvailable === '不可',
                    options: stakeholders.studentInfo.sub
                        ? [
                            {
                                label: "" + (stakeholders.studentInfo.subAvailable === '不可' ? '【送信不可】' : '') + (stakeholders.studentInfo.lastName +
                                    stakeholders.studentInfo.firstName) + " \u30B5\u30D6 <" + stakeholders.studentInfo.sub + ">",
                                value: stakeholders.studentInfo.sub,
                            },
                        ]
                        : [],
                    showOn: { cc_single_hidden: null },
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                    showOn: { cc_single_hidden: null },
                });
            }
            else if (_this.sender.model.cc_single.length) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'label',
                    class: 'full',
                    supplier: function () {
                        return stakeholders.studentInfo.sub
                            ? "" + (stakeholders.studentInfo.subAvailable === '不可' ? '【送信不可】' : '') + (stakeholders.studentInfo.lastName +
                                stakeholders.studentInfo.firstName) + " \u30B5\u30D6 <" + stakeholders.studentInfo.sub + ">"
                            : '';
                    },
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                    showOn: stakeholders ? { cc_single_hidden: null } : null,
                });
            }
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                // displayType: 'email',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.subject : undefined;
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return (_this.sender ? _this.sender.model.subject : ''); },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = {
                title: '添付ファイル',
                fields: [
                    {
                        name: 'enterpriseId',
                        type: 'label',
                        class: 'half',
                        supplier: function () {
                            return _this.sender && _this.sender.model.enterpriseId
                                ? _this.sender.companyApiService
                                    .getSuggestions(undefined, [_this.sender.model.enterpriseId])
                                    .pipe(map(function (res) { return res[0].frontId + " " + res[0].name; }))
                                : '';
                        },
                    },
                ],
            };
            row.class = 'ou';
            if (!_this.sender.inConfirm) {
                row.fields[0].labelBefore = '企業：';
                row.fields[0].linkTo = ['enterpriseFileIds'];
                row.fields[0].actions = [
                    {
                        title: 'データフォルダから選択',
                        type: 'RUNNABLE',
                        allowOn: { enterpriseId: null },
                        runnable: function () {
                            _this.sender.enterpriseFileIds = _this.sender.model.enterpriseFileIds.slice();
                            PopupControlComponent.instance.open({
                                content: _this.sender.fileSelectTemplate,
                                confirmText: '添付',
                                cancelText: 'キャンセル',
                                title: 'データフォルダ',
                                confirmCallback: function () {
                                    _this.sender.model.enterpriseFileIds = _this.sender.enterpriseFileIds.slice();
                                },
                            });
                        },
                    },
                ];
            }
            else {
                row.fields.length = 0;
            }
            if (!_this.sender.inConfirm || _this.sender.model.enterpriseFileIds.length) {
                row.fields.push({
                    name: 'enterpriseFileIds',
                    type: 'autocomplete',
                    labelField: [
                        {
                            name: 'name',
                            class: _this.sender.inConfirm ? 'link' : null,
                            action: _this.sender.inConfirm
                                ? function (file) {
                                    return _this.sender.fileService.downloadFile('enterprise', +Object.keys(_this.sender.enterpriseFiles).find(function (key) { return _this.sender.enterpriseFiles[key].find(function (f) { return f.id === file.id; }) != null; }), file.id, file.name);
                                }
                                : null,
                        },
                    ],
                    labelBefore: _this.sender.inConfirm ? '企業：' : null,
                    valueField: 'id',
                    multi: true,
                    style: !_this.sender.inConfirm ? { 'padding-left': '40px' } : null,
                    class: 'options-only half',
                    options: [],
                    supplier: function (value, callType, getValue) {
                        var id = getValue('enterpriseId', true);
                        if (!_this.sender) {
                            return { options: [] };
                        }
                        var selectedFiles = Object.values(_this.sender.enterpriseFiles).flatten().filter(function (f) {
                            return _this.sender.model.enterpriseFileIds.includes(f.id);
                        });
                        if (id === _this.sender.enterpriseIdForFile) {
                            return { options: selectedFiles.concat(_this.sender.enterpriseFiles[id]) };
                        }
                        if (!id) {
                            return { options: selectedFiles };
                        }
                        else {
                            _this.sender.enterpriseIdForFile = id;
                            return _this.sender.fileService.getFiles('enterprise', id).pipe(map(function (res) {
                                _this.sender.enterpriseFiles[id] = res.filter(function (f) { return f.studentSendFlag; });
                                return { options: selectedFiles.concat(_this.sender.enterpriseFiles[id]) };
                            }));
                        }
                    },
                });
                if (!_this.sender.inConfirm || _this.sender.model.studentFileIds.length) {
                    row.fields.push({
                        name: 'file-hr',
                        type: 'hr',
                        class: 'full',
                    });
                }
            }
            if (!_this.sender.inConfirm || _this.sender.model.studentFileIds.length) {
                row.fields.push({
                    name: 'studentFileIds',
                    type: !_this.sender.inConfirm ? 'checkbox' : 'autocomplete',
                    class: 'multi-ou sticky-label full',
                    labelField: !_this.sender.inConfirm
                        ? 'name'
                        : [
                            {
                                name: 'name',
                                class: _this.sender.inConfirm ? 'link' : null,
                                action: _this.sender.inConfirm
                                    ? function (file) { return _this.sender.fileService.downloadFile('students', _this.sender.model.studentId, file.id, file.name); }
                                    : null,
                            },
                        ],
                    valueField: 'id',
                    labelBefore: '求職者：',
                    multi: true,
                    options: [],
                    supplier: function () { return ({ options: _this.sender.files }); },
                });
            }
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.body : undefined;
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [
                                        { title: NULL_SELECTED_VALUE, id: null, body: null }
                                    ].concat((_this.sender.model.progressType === 1 ? [] : res));
                                    return {
                                        value: value ? (_this.sender.model.progressType === 1 ? null : res.find(function (s) { return s.id === value.id; })) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    ProgressSendInterviewInfoConfig.prototype.update = function () {
        var _this = this;
        var stakeholders = this.sender.model.stakeholders;
        if (stakeholders.studentInfo && stakeholders.studentInfo.main && !stakeholders.studentInfo.mainAvailable) {
            this.sender.model.to = [stakeholders.studentInfo.main];
        }
        if (stakeholders.studentInfo && stakeholders.studentInfo.sub && !stakeholders.studentInfo.subAvailable) {
            this.sender.model.cc_single = [stakeholders.studentInfo.sub];
        }
        var ccUserIds = stakeholders.jobUsersInfo
            .filter(function (jobUser) { return jobUser.type === EnterpriseDepartmentUserType.アシ; })
            .map(function (jobUser) { return jobUser.id; });
        if (this.sender.model.progressType === 0 && stakeholders.studentUserInfo && stakeholders.studentUserInfo.id) {
            ccUserIds.push(stakeholders.studentUserInfo.id);
        }
        this.sender.model.cc = ccUserIds;
        var bccUserIds;
        if (this.sender.model.progressType === 1) {
            bccUserIds = stakeholders.jobUsersInfo
                .filter(function (jobUser) { return jobUser.type === EnterpriseDepartmentUserType.PA; })
                .map(function (jobUser) { return jobUser.id; });
        }
        else if (this.sender.model.progressType === 0) {
            bccUserIds = stakeholders.jobUsersInfo
                .filter(function (jobUser) { return jobUser.type === EnterpriseDepartmentUserType.RA; })
                .map(function (jobUser) { return jobUser.id; });
        }
        this.sender.model.bcc = bccUserIds;
        if (this.sender.model.studentId && this.sender.studentIdForFiles !== this.sender.model.studentId) {
            this.sender.studentIdForFiles = this.sender.model.studentId;
            this.sender.fileService.getFiles('students', this.sender.model.studentId).subscribe(function (res) {
                var _a;
                _this.sender.files.length = 0;
                (_a = _this.sender.files).push.apply(_a, res);
                _this.initForm();
            });
        }
    };
    ProgressSendInterviewInfoConfig.prototype.save = function (toSave) {
        var _this = this;
        toSave['jobId'] = this.sender.model.jobId;
        this.sender.mailApiService.sendStudentMail(this.sender.model.studentId, toSave).subscribe(function (res) { return _this.sender.afterSend(res); });
    };
    return ProgressSendInterviewInfoConfig;
}(MailSendConfig));
export { ProgressSendInterviewInfoConfig };
