<ag-job-detail-header
  [job]="job"
  [readonly]="isReadonly"
  [showOnlyMainInfo]="inSlimMode"
></ag-job-detail-header>
<ng-container *ngIf="inSlimMode; then slimMode; else tabMode"></ng-container>
<ng-template #slimMode>
  <div class="top-shadow"></div>
  <ag-persons-in-charge
    [job]="job"
    [groupType]="'BRANCH'"
  ></ag-persons-in-charge>
</ng-template>
<ng-template #tabMode>
  <ag-tab-group class="tab-group" [(selectedIndex)]="selectedTabIndex">
    <ag-tab label="概要">
      <ag-job-overview-tab
        [job]="job"
        [memos]="memos"
        [progresses]="progresses"
        [sales]="sales"
        [company]="company"
        [isIntakeCa]="isIntakeCa"
      ></ag-job-overview-tab>
    </ag-tab>
    <ag-tab label="詳細">
      <ag-job-detail-tab
        [job]="job"
        [readonly]="isReadonly"
      ></ag-job-detail-tab>
    </ag-tab>
    <ag-tab label="進捗" *ngIf="!isIntakeCa">
      <ag-job-progress-tab [job]="job"></ag-job-progress-tab>
    </ag-tab>
    <ag-tab label="面接等メール配信">
      <ag-job-mail-tab [job]="job"></ag-job-mail-tab>
    </ag-tab>
    <ag-tab label="成約" *ngIf="!isIntakeCa && !isReadonly">
      <ag-job-sales-tab [jobId]="referenceId"></ag-job-sales-tab>
    </ag-tab>
    <ag-tab label="求人メモ" *ngIf="!isReadonly">
      <ag-job-memo-tab [jobId]="referenceId"></ag-job-memo-tab>
    </ag-tab>
  </ag-tab-group>
</ng-template>
