import * as tslib_1 from "tslib";
import { SortOrder } from '@agent-ds/shared/components/page-scroll-table/table-interface';
export var JOBS_TABLE_CONFIG = function (dynamicColumns, dynamicRows, newArrivalTemplate, personsInChargeTemplate, companyTemplate, positionTemplate, heatTemplate, favoritesTemplate, actionsTemplate, dateFormatter, numberFormat, jobTypeResolver, industryResolver, options) {
    var _a, _b;
    var resp = {
        head: {
            style: {
                padding: 0,
            },
            sticky: true,
            config: [
                {
                    columns: [
                        {
                            fields: [
                                {
                                    name: 'new',
                                    title: '新\n着',
                                    cellTemplate: newArrivalTemplate,
                                    hidden: options && options.body && options.body.hideCheckboxHeader,
                                },
                            ],
                            style: {
                                width: options && options.body && options.body.checkbox ? '40px' : '28px',
                                'padding-left': '8px',
                                'padding-right': '8px',
                                'white-space': 'pre-wrap',
                                'padding-top': '6px',
                                'padding-bottom': '6.5px',
                                'font-size': '12px',
                                'line-height': '12px',
                                position: options && options.body && options.body.checkbox ? 'absolute' : 'relative',
                            },
                            bodyStyle: {
                                padding: '0',
                                'justify-content': 'flex-start',
                                'pointer-events': 'none',
                            },
                            class: 'table-head__column--vertical',
                        },
                        {
                            fields: [
                                {
                                    name: 'favorites',
                                    sortable: false,
                                    title: 'お気に\n入り',
                                    cellTemplate: favoritesTemplate,
                                },
                            ],
                            style: {
                                width: '40px',
                                'text-align': 'center',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'status',
                                    title: '求人ステータス',
                                    formatter: function (data) { return (data.dynamicData ? data.dynamicData.status : undefined); },
                                    style: {
                                        'margin-bottom': '5px',
                                    },
                                },
                                {
                                    name: 'recommendationDateKind',
                                    title: '熱度',
                                    cellTemplate: heatTemplate,
                                },
                            ],
                            style: {
                                width: '107px',
                                'padding-right': '20px',
                                'padding-top': '6px',
                                'padding-bottom': '6px',
                            },
                            bodyStyle: {
                                'padding-top': '5px',
                                'padding-bottom': '6.5px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'enterpriseName',
                                    title: '企業名',
                                    cellTemplate: companyTemplate,
                                },
                                {
                                    name: 'position',
                                    title: 'ポジション名',
                                    cellTemplate: positionTemplate,
                                },
                            ],
                            style: {
                                width: '300px',
                                flex: 1,
                                overflow: 'hidden',
                                'padding-right': '20px',
                                'padding-top': '6px',
                                'padding-bottom': '6.5px',
                            },
                            bodyStyle: {
                                'padding-top': '5px',
                                'padding-bottom': '5px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'industry',
                                    title: '業種',
                                    formatter: function (data) {
                                        return data.enterprise && data.enterprise.dynamicData ? industryResolver(data.enterprise.dynamicData.industry) : undefined;
                                    },
                                },
                                {
                                    name: 'jobType',
                                    title: '職種',
                                    formatter: function (data) { return (data.dynamicData ? jobTypeResolver(data.dynamicData.jobType1) : undefined); },
                                    style: {
                                        'margin-top': '4px',
                                    },
                                },
                            ],
                            style: {
                                width: '150px',
                                'padding-right': '20px',
                                'padding-top': '6px',
                                'padding-bottom': '6.5px',
                            },
                            bodyStyle: {
                                'padding-top': '5px',
                                'padding-bottom': '5px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'salary',
                                    title: '月給',
                                    formatter: function (data) {
                                        return data.dynamicData
                                            ? data.dynamicData.salary
                                                ? numberFormat(Math.floor(data.dynamicData.salary / 1000) / 10) + "\u4E07\u5186"
                                                : undefined
                                            : undefined;
                                    },
                                },
                            ],
                            style: {
                                width: '67px',
                                'padding-right': '15px',
                                'padding-top': '6px',
                                'padding-bottom': '6.5px',
                            },
                            bodyStyle: {
                                'padding-top': '5px',
                                'padding-bottom': '5px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'annualHolidays',
                                    title: '年間\n休日',
                                    formatter: function (data) {
                                        return data.dynamicData
                                            ? data.dynamicData.annualHolidays
                                                ? numberFormat(data.dynamicData.annualHolidays) + "\u65E5"
                                                : undefined
                                            : undefined;
                                    },
                                },
                            ],
                            style: {
                                width: '50px',
                                'padding-right': '15px',
                                'padding-top': '6px',
                                'padding-bottom': '6.5px',
                            },
                            bodyStyle: {
                                'padding-top': '5px',
                                'padding-bottom': '5px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'updatedAt',
                                    title: '更新日',
                                    style: {
                                        'margin-bottom': '1px',
                                    },
                                    defaultSort: SortOrder.DESCENDING,
                                    formatter: function (data) { return (data.lastUpdatedAt ? dateFormatter(data.lastUpdatedAt) : ''); },
                                },
                                {
                                    name: 'persons_in_charge',
                                    title: '担当者',
                                    cellTemplate: personsInChargeTemplate,
                                    sortable: false,
                                },
                            ],
                            style: {
                                width: '200px',
                                'padding-right': '20px',
                                'padding-top': '6px',
                                'padding-bottom': '6.5px',
                            },
                            bodyStyle: {
                                'padding-top': '5px',
                                'padding-bottom': '5px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'recruitYear',
                                    title: '募集年次',
                                    style: {
                                        'margin-bottom': '1px',
                                    },
                                    sortable: false,
                                    formatter: function (data) { return (data.dynamicData.recruitYear ? data.dynamicData.recruitYear.substring(2) + '卒' : ''); },
                                },
                            ],
                            style: {
                                width: '60px',
                                'padding-right': '20px',
                                'padding-top': '6px',
                                'padding-bottom': '6.5px',
                            },
                            bodyStyle: {
                                'padding-top': '5px',
                                'padding-bottom': '5px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'actions',
                                    title: 'アクション',
                                    sortable: false,
                                    cellTemplate: actionsTemplate,
                                    hidden: options && options.body && options.body.hideActionsHeader,
                                },
                            ],
                            style: {
                                // flex: 1,
                                'padding-right': '49px',
                                'justify-content': 'flex-end',
                                display: 'flex',
                                'align-items': 'center',
                                'flex-direction': 'row',
                            },
                            bodyStyle: {
                                'padding-right': '0px',
                                'padding-top': '5px',
                                'padding-bottom': '5px',
                            },
                        },
                    ],
                    dynamicStyle: {
                        'min-height': function () { return '79px'; },
                    },
                },
            ],
        },
        body: tslib_1.__assign({ checkbox: false, arrow: true }, (options ? options.body : {})),
    };
    if (dynamicColumns) {
        (_a = resp.head.config[0].columns).unshift.apply(_a, dynamicColumns);
    }
    if (dynamicRows) {
        (_b = resp.head.config).push.apply(_b, dynamicRows);
    }
    if (options && options.body && options.body.hideAllSort) {
        resp.head.config.forEach(function (cfg) { return cfg.columns.forEach(function (col) { return col.fields.forEach(function (field) { return (field.sortable = false); }); }); });
    }
    if (options && options.body && options.body.noSorting) {
        resp.head.config.forEach(function (cfg) {
            return cfg.columns.forEach(function (col) {
                return col.fields.forEach(function (field) {
                    if (field.defaultSort) {
                        delete field.defaultSort;
                    }
                });
            });
        });
    }
    var _loop_1 = function (option) {
        if (!options[option]) {
            resp.head.config.forEach(function (cfg) {
                cfg.columns = cfg.columns.filter(function (col) {
                    col.fields = col.fields.length ? col.fields.filter(function (field) { return field.name !== option; }) : col.fields;
                    return col.fields.length;
                });
            });
        }
    };
    // filter disabled columns
    for (var option in options) {
        _loop_1(option);
    }
    return resp;
};
