/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job-memo-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/memo-list/memo-list.component.ngfactory";
import * as i3 from "../../../../../shared/components/memo-list/memo-list.component";
import * as i4 from "./job-memo-tab.component";
import * as i5 from "../../../../../shared/services/api/job-api.service";
var styles_JobMemoTabComponent = [i0.styles];
var RenderType_JobMemoTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobMemoTabComponent, data: {} });
export { RenderType_JobMemoTabComponent as RenderType_JobMemoTabComponent };
export function View_JobMemoTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-memo-list", [["newText", "\u65B0\u898F\u6C42\u4EBA\u30E1\u30E2\u4F5C\u6210"], ["title", ""]], null, null, null, i2.View_MemoListComponent_0, i2.RenderType_MemoListComponent)), i1.ɵdid(1, 4898816, null, 0, i3.MemoListComponent, [], { service: [0, "service"], referenceId: [1, "referenceId"], newText: [2, "newText"], title: [3, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jobApiService; var currVal_1 = _co.jobId; var currVal_2 = "\u65B0\u898F\u6C42\u4EBA\u30E1\u30E2\u4F5C\u6210"; var currVal_3 = ""; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_JobMemoTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-job-memo-tab", [], null, null, null, View_JobMemoTabComponent_0, RenderType_JobMemoTabComponent)), i1.ɵdid(1, 114688, null, 0, i4.JobMemoTabComponent, [i5.JobApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JobMemoTabComponentNgFactory = i1.ɵccf("ag-job-memo-tab", i4.JobMemoTabComponent, View_JobMemoTabComponent_Host_0, { jobId: "jobId" }, {}, []);
export { JobMemoTabComponentNgFactory as JobMemoTabComponentNgFactory };
