import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DASHBOARD_API_URL = '/api/dashboard';
var DashboardApiService = /** @class */ (function () {
    function DashboardApiService(http) {
        this.http = http;
        this.refreshEvent = new EventEmitter();
    }
    DashboardApiService.prototype.getTeamKPI = function (teamId, dashboardType) {
        return this.http.get(DASHBOARD_API_URL + "/team/" + teamId + "/kpi/" + dashboardType);
    };
    DashboardApiService.prototype.getKPI = function (dashboardType) {
        return this.http.get(DASHBOARD_API_URL + "/kpi/" + dashboardType);
    };
    DashboardApiService.prototype.getProgress = function (dashboardType, year) {
        return this.http.get(DASHBOARD_API_URL + "/progress/" + dashboardType + "/" + year);
    };
    DashboardApiService.prototype.getTeamProgress = function (teamId, dashboardType, year) {
        return this.http.get(DASHBOARD_API_URL + "/team/" + teamId + "/progress/" + dashboardType + "/" + year);
    };
    DashboardApiService.prototype.getProgressWithNextYear = function (dashboardType, year) {
        return this.http.get(DASHBOARD_API_URL + "/progress/" + dashboardType + "/" + year + "/with-next-year");
    };
    DashboardApiService.prototype.getTeamProgressWithNextYear = function (teamId, dashboardType, year) {
        return this.http.get(DASHBOARD_API_URL + "/team/" + teamId + "/progress/" + dashboardType + "/" + year + "/with-next-year");
    };
    DashboardApiService.prototype.getEnterprises = function () {
        return this.http.get(DASHBOARD_API_URL + "/enterprises");
    };
    DashboardApiService.prototype.getTeamEnterprises = function (teamId) {
        return this.http.get(DASHBOARD_API_URL + "/team/" + teamId + "/enterprises");
    };
    DashboardApiService.prototype.getEnterprisesOfAssistant = function () {
        return this.http.get(DASHBOARD_API_URL + "/enterprises/ra-assistant");
    };
    DashboardApiService.prototype.getStudents = function () {
        return this.http.get(DASHBOARD_API_URL + "/students");
    };
    DashboardApiService.prototype.getTeamStudents = function (teamId) {
        return this.http.get(DASHBOARD_API_URL + "/team/" + teamId + "/students");
    };
    DashboardApiService.prototype.updateKpi = function (kpi) {
        return this.http.put(DASHBOARD_API_URL + "/kpi/target", kpi, { observe: 'response' });
    };
    DashboardApiService.prototype.updateTeamKpi = function (teamId, kpi) {
        return this.http.put(DASHBOARD_API_URL + "/team/" + teamId + "/kpi/target", kpi, { observe: 'response' });
    };
    DashboardApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardApiService_Factory() { return new DashboardApiService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardApiService, providedIn: "root" });
    return DashboardApiService;
}());
export { DashboardApiService };
