import * as tslib_1 from "tslib";
import { DatePipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
var SafeDatePipe = /** @class */ (function (_super) {
    tslib_1.__extends(SafeDatePipe, _super);
    function SafeDatePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SafeDatePipe.prototype.transform = function (value, format, timezone, locale) {
        try {
            return _super.prototype.transform.call(this, value, format, timezone, locale);
        }
        catch (e) {
            console.warn('param is not a valid date representation');
            return '';
        }
    };
    return SafeDatePipe;
}(DatePipe));
export { SafeDatePipe };
