<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">ステータス一括変更</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="bulk-progress-change-container">
      <p class="bulk-progress-change-message">
        選択した全ての進捗のステータスを一括で変更できます。<br />変更先のステータスを選んでください。
      </p>

      <div class="input-group">
        <label class="input-group__label">
          変更先ステータス
          <span class="input-group__label--required">必須</span>
        </label>

        <div class="input-group__controls">
          <ng-template
            ngFor
            [ngForOf]="config.data.options"
            let-option
            let-index="index"
          >
            <label class="input-group__controls__radio--label">
              <input
                type="radio"
                name="targetStatus"
                [value]="option"
                [id]="'target-status-' + index"
                [ngModel]="selected"
                (ngModelChange)="onTargetStatusChange($event)"
              />
              {{ option.label(undefined, 1) }}
            </label>
          </ng-template>
        </div>
      </div>

      <div
        class="input-group"
        [class.input-group--disabled]="!selectedStatusNeedsReason"
      >
        <label for="status-data" class="input-group__label">
          辞退・不合格理由
          <span class="input-group__label--required">必須</span>
        </label>
        <div class="input-group__controls">
          <textarea
            id="status-data"
            class="input-group__controls__form-control"
            [disabled]="!selectedStatusNeedsReason"
            rows="5"
            [(ngModel)]="selected.stopReason"
          ></textarea>
          <div class="input-group__controls__notes">
            ※ 既存で辞退・不合格理由が記入されている場合は上書きされます
          </div>
        </div>
      </div>

      <div
        class="input-group"
        [class.input-group--disabled]="!selectedStatusHasRemarks"
      >
        <label for="remarks" class="input-group__label">
          備考
        </label>
        <div class="input-group__controls">
          <textarea
            id="remarks"
            class="input-group__controls__form-control"
            [disabled]="!selectedStatusHasRemarks"
            rows="5"
            [(ngModel)]="selected.remarks"
          ></textarea>
          <div class="input-group__controls__notes">
            ※ 変更先のステータスの備考に保存されます
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      (click)="submit()"
      [disabled]="invalid || dialog.inClose"
    >
      一括変更
    </button>
  </div>
</div>
