/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chip.component";
var styles_ChipComponent = [i0.styles];
var RenderType_ChipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChipComponent, data: {} });
export { RenderType_ChipComponent as RenderType_ChipComponent };
export function View_ChipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "chip"]], [[2, "chip--selected", null], [2, "chip--disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "chip__label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "chip__label__checkmark"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2714"])), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "chip__remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selected; var currVal_1 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.label; _ck(_v, 4, 0, currVal_2); }); }
export function View_ChipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-chip", [], null, null, null, View_ChipComponent_0, RenderType_ChipComponent)), i1.ɵdid(1, 114688, null, 0, i2.ChipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChipComponentNgFactory = i1.ɵccf("ag-chip", i2.ChipComponent, View_ChipComponent_Host_0, { label: "label", selected: "selected", disabled: "disabled" }, { removed: "removed", toggled: "toggled" }, []);
export { ChipComponentNgFactory as ChipComponentNgFactory };
