<div class="controls">
  <input
    class="controls__select-all"
    type="checkbox"
    [checked]="table?.allChecked"
    (click)="table?.checkAll(table.allChecked)"
  />
  <span class="counter__count">{{
    table?.checkedItems.length || table?.totalSize
  }}</span>
  <span class="counter__label">{{
    table?.checkedItems.length ? "件選択中" : "件"
  }}</span>

  <button
    [disabled]="!hasCheckedItems"
    class="controls__control btn btn--white btn--outline-gray btn--larger"
    (click)="openMail(offerConfig)"
  >
    求人票送信
  </button>
  <button
    *ngIf="student"
    [disabled]="!hasCheckedItems || ckeckJobUsersInProgress"
    class="controls__control btn btn--white btn--outline-gray btn--larger"
    (click)="openMail(recommendConfig)"
  >
    一括推薦
  </button>

  <button
    class="controls__control btn btn--white btn--outline-gray btn--larger"
    [disabled]="!hasCheckedItems || !checkedItemsStatusIs(170)"
    (click)="openMail(ngEndConfig)"
  >
    NG終了登録
  </button>
  <button
    *ngIf="job"
    [disabled]="!hasCheckedItems"
    class="controls__control btn btn--white btn--outline-gray btn--larger"
    (click)="openMail(consultConfig)"
  >
    打診
  </button>
  <button
    [disabled]="!hasCheckedItems"
    class="controls__control btn btn--gray btn--larger"
    (click)="openProgressBulkDeclineDialog()"
  >
    辞退
  </button>
  <button
    *ngIf="!disableBulkUpdateButton"
    [disabled]="!canBulkEdit"
    class="controls__control btn btn--outline-gray btn--larger"
    (click)="openProgressBulkEditDialog()"
  >
    ステータス変
  </button>
  <button
    *ngIf="job"
    [disabled]="
      !hasCheckedItems || !isValidSelectedItemsForBulkScheduleAdjust()
    "
    class="controls__control btn btn--white btn--outline-gray btn--larger"
    (click)="openProgressBulkScheduleAdjustDialog()"
  >
    日程調整
  </button>
  <button
    *ngIf="job"
    [disabled]="!hasCheckedItems"
    class="controls__control btn btn--white btn--outline-gray btn--larger"
    (click)="openMail(bulkConfig)"
  >
    メール
  </button>

  <span
    class="counter__filter"
    [class.counter__filter--two-line]="!disableBulkUpdateButton"
  >
    <ag-select
      #progressStatusSelect
      *ngIf="requestObject"
      class="counter__select counter__select--small"
      [class.counter__select--two-line]="!disableBulkUpdateButton"
      [options]="progressTypeSelectOptions"
      [valueField]="'value'"
      [labelField]="'label'"
      placeholder="すべての進捗"
      [(value)]="requestObject.type"
      (valueChange)="onProgressFilterChange()"
    >
    </ag-select>
    <ag-select
      #progressStatusSelect
      *ngIf="requestObject"
      class="counter__select"
      [options]="progressStatusCodesSelectOptions"
      [valueField]="'value'"
      [labelField]="'label'"
      allLabel="すべてのステータス"
      confirmLabel="確定"
      [allSelected]="true"
      [allIsEmpty]="true"
      [multi]="true"
      [(value)]="requestObject.status"
      (valueChange)="onProgressFilterChange()"
    >
    </ag-select>
  </span>

  <div class="controls__refresh" (click)="ngOnChanges()">
    <div class="controls__refresh-icon">
      <svg class="controls__refresh--svg">
        <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
      </svg>
    </div>
  </div>
  <button
    class="controls__control controls__control--create btn btn--green btn--larger"
    (click)="openProgressCreateDialog()"
  >
    登録
  </button>
</div>
<ag-page-scroll-table
  class="table"
  [tableConfig]="tableConfig"
  [supplier]="loadProgress"
  (itemSelected)="onProgressSelect($event)"
></ag-page-scroll-table>

<ng-template #status let-data>
  <div class="status">
    <div *ngIf="data.type === 0 || data.type === 1" class="status__type">
      <span
        class="status__type-badge"
        [class.status__type-badge--AG]="data.type === 0"
        [class.status__type-badge--Plus]="data.type === 1"
      ></span>
    </div>
    <div>
      <div
        *ngIf="showStudentDelayBtn(data.status)"
        class="status__badge badge"
        [class.badge--dark]="data.isStudentDelay"
        [class.badge--outline-gray]="!data.isStudentDelay"
        (click)="onStudentDelayClick(data, $event)"
      >
        <span *ngIf="data.isStudentDelay" class="status__tick tick"></span>
        <span class="status__label">学生起因</span>
      </div>
      <span *ngIf="data.hasInterview" class="status__type-interview"
        >面談会</span
      >
    </div>
    <span class="status__text">
      {{ getStatusLabel(data.status, data.seminarType, data.n) }}</span
    >

    <span *ngIf="data.eventName && data.eventAt" class="status__date">
      <span class="status__date--label">{{ data.eventName }}</span
      >{{ data.eventAt | safeDate: "yyyy/MM/dd(E) HH:mm" }}
    </span>
  </div>
</ng-template>

<ng-template #applicant let-data>
  <span
    class="degrees"
    *ngIf="
      data.student?.dynamicData?.academicHistory1?.graduateYear &&
      data.student.dynamicData.academicHistory1.graduateType
    "
  >
    {{
      (
        +data.student.dynamicData.academicHistory1.graduateYear +
        (data.student.dynamicData.academicHistory1.graduateMonth > 3 ? 1 : 0) +
        ""
      ).substring(2)
    }}
    {{ data.student.dynamicData.academicHistory1.graduateType[0] }}
  </span>
  <span class="name link" (click)="onStudentLinkClick($event, data.student.id)">
    {{ data.student.dynamicData.lastName + data.student.dynamicData.firstName }}
  </span>
</ng-template>

<ng-template #applicantContact let-data>
  <div class="contact">
    <span
      *ngIf="
        (getContactUser(data.student.caUserId) | async)?.name as contactName
      "
      class="contact__name contact--applicant"
    >
      {{ contactName }}
    </span>
    <span
      *ngIf="
        getContactTeamName(data.student.caUserId) | async as contactTeamName
      "
      class="contact--applicant"
    >
      {{ contactTeamName }}
    </span>
  </div>
</ng-template>

<ng-template #jobGeneral let-data>
  <div class="dest-container">
    <span class="dest-container__company-code">{{
      data.enterprise.frontId
    }}</span>
    <span
      class="dest-container__company-name link"
      [title]="data.enterprise.name"
      (click)="onCompanyLinkClick($event, data.enterprise.id)"
      >{{ data.enterprise.name }}</span
    >
  </div>
  <div class="dest-container">
    <span class="dest-container__job-code">{{ data.job.frontId }}</span>
    <span
      class="dest-container__job-name link two-line"
      [title]="data.job.dynamicData.position"
      (click)="onJobLinkClick($event, data.job.id)"
      >{{ data.job.dynamicData.position }}</span
    >
    <span
      *ngIf="data.job.dynamicData.status"
      class="dest-container__job-status"
    >
      {{ data.job.dynamicData.status }}
    </span>
    <svg
      *ngIf="
        data.dynamicData.isCreate2ndMatch?.length &&
        data.dynamicData.isCreate2ndMatch[0] === '有効'
      "
      class="dest-container__second-matching"
    >
      <use xlink:href="/assets/icons/icn_2nd_match.svg#icn_2nd_match"></use>
    </svg>
  </div>
</ng-template>

<ng-template #jobContact let-data>
  <div class="contact">
    <span
      *ngIf="
        (getJobContactUser(data?.job?.jobUsers, data?.type) | async)
          ?.name as contactName
      "
      class="contact__name contact--company"
    >
      {{ contactName }}
    </span>
    <span
      *ngIf="
        getJobContactTeamName(data?.job?.jobUsers, data?.type)
          | async as contactTeamName
      "
      class="contact--company"
    >
      {{ contactTeamName }}
    </span>
  </div>
</ng-template>

<ng-template #cellArrow let-data>
  <span class="arrow arrow--right"></span>
</ng-template>

<ng-template #actions let-data>
  <div class="actions">
    <ng-container
      *ngFor="
        let actionGroup of PROGRESS_STATUSES[data.status].actions;
        index as i
      "
    >
      <div
        *ngIf="actionGroup"
        class="actions__section"
        [class.actions__section--iteractives]="
          i === 0 && PROGRESS_STATUSES[data.status].actions[1]
        "
      >
        <button
          *ngFor="let action of actionGroup"
          class="actions__btn btn btn--medium"
          [class.btn--green]="action?.color === ProgressActionColor.GREEN"
          [class.btn--blue]="action?.color === ProgressActionColor.BLUE"
          [class.btn--outline-gray]="action?.color === ProgressActionColor.GRAY"
          [class.btn--outline-red]="action?.color === ProgressActionColor.RED"
          [disabled]="eventEditors[data.id]"
          (click)="onProgressActionClick(data, action)"
        >
          {{ action.label(data.seminarType, data.n) }}
        </button>
      </div>
    </ng-container>
    <div
      *ngIf="PROGRESS_STATUSES[data.status].directTransitions?.length"
      class="actions__section actions__section--direct-transitions"
    >
      <button
        *ngFor="
          let transition of PROGRESS_STATUSES[data.status].directTransitions
        "
        class="actions__btn btn btn--medium btn--blue"
        (click)="directTransition(data, transition)"
      >
        {{ transition.label(data.seminarType, data.n) }}
      </button>
    </div>
  </div>

  <div
    class="event-editor"
    *ngIf="eventEditors[data.id] && editorMeta[data.id]"
  >
    <ag-dynamic-form
      [metadata]="editorMeta[data.id]"
      [model]="eventEditors[data.id]"
    ></ag-dynamic-form>
    <div class="event-editor__controls">
      <button
        class="event-editor__control btn btn--medium btn--green"
        (click)="onMailActionClick(data, ProgressSendInterviewInfoConfig)"
        [disabled]="!eventEditors[data.id] || !eventEditors[data.id].seminarAt"
      >
        面接案内送信
      </button>
    </div>
  </div>

  <!-- 備考編集の表示 -->
  <!-- NG連絡前でも辞退連絡前でもない場合に表示 https://benesse-g.backlog.jp/view/MACH_PJ-125 -->
  <div
    *ngIf="data.status !== 170 && data.status !== 180"
    class="message"
    [ngClass]="{
      'message--open': remarkEditors[data.id]?.show,
      'message--empty': !data.latestRemarks && !remarkEditors[data.id]?.edit
    }"
  >
    <span class="message__info">備考</span>
    <span
      *ngIf="!remarkEditors[data.id]?.edit && data.latestRemarks"
      class="message__text"
      title="{{ data.latestRemarks }}"
      [ngClass]="{
        'message__text--open': remarkEditors[data.id]?.show
      }"
    >
      {{ data.latestRemarks || "" }}
    </span>
    <textarea
      *ngIf="remarkEditors[data.id]?.edit"
      cdkTextareaAutosize
      cdkAutosizeMinRows="2"
      [maxLength]="400"
      (keypress.esc)="remarkEditors[data.id].edit = false"
      (keydown.enter)="$event.stopPropagation()"
      class="message__text message__text--editor"
      [defaultValue]="data.latestRemarks || ''"
      [(ngModel)]="remarkEditors[data.id].remarks"
    ></textarea>
    <span
      class="message__note"
      *ngIf="!remarkEditors[data.id]?.edit"
      (click)="editRemarks(data.id, data.latestRemarks)"
      >備考を入力</span
    >
    <span
      class="message__arrow"
      *ngIf="!remarkEditors[data.id]?.edit && data.latestRemarks"
      [ngClass]="{
        'message__arrow--down': !remarkEditors[data.id]?.show,
        'message__arrow--up': remarkEditors[data.id]?.show
      }"
      (click)="toggleRemarksDisplay(data.id)"
    ></span>
  </div>

  <!-- 備考のキャンセルと更新 -->
  <div
    class="operations-edit"
    *ngIf="remarkEditors[data.id]?.edit || eventEditors[data.id]"
  >
    <div
      *ngIf="
        eventEditors[data.id] && (data.status === 70 || data.status === 100)
      "
      class="checkbox-group"
    >
      <input
        type="checkbox"
        [(ngModel)]="eventEditors[data.id].seminarAtMailSendFlag"
      />
      <span>日程確定メール送信不要</span>
    </div>
    <button
      class="operations-edit__control btn btn--medium btn--white btn--outline-gray"
      (click)="onCancelClick(data.id)"
    >
      キャンセル
    </button>
    <button
      class="operations-edit__control operations-edit__control--save btn btn--medium btn--blue-primary"
      [disabled]="
        eventEditors[data.id]?.inSave ||
        ((data.status === 70 || data.status === 100) &&
          eventEditors[data.id] &&
          !eventEditors[data.id].seminarAt)
      "
      (click)="onSaveClick(data)"
    >
      更新
    </button>
  </div>

  <!-- 辞退・不合格理由編集の表示 https://benesse-g.backlog.jp/view/MACH_PJ-125 -->
  <div
    *ngIf="data.status === 170 || data.status === 180"
    class="message"
    [ngClass]="{
      'message--open': stopReasonEditors[data.id]?.show,
      'message--empty':
        !data.dynamicData?.stopReason && !stopReasonEditors[data.id]?.edit
    }"
  >
    <span class="message__info">辞退・不合格理由</span>
    <span
      *ngIf="!stopReasonEditors[data.id]?.edit && data.dynamicData?.stopReason"
      class="message__text"
      title="{{ data.dynamicData?.stopReason }}"
      [ngClass]="{
        'message__text--open': stopReasonEditors[data.id]?.show
      }"
    >
      {{ data.dynamicData?.stopReason || "" }}
    </span>
    <textarea
      *ngIf="stopReasonEditors[data.id]?.edit"
      cdkTextareaAutosize
      cdkAutosizeMinRows="2"
      (keypress.esc)="stopReasonEditors[data.id].edit = false"
      (keydown.enter)="$event.stopPropagation()"
      class="message__text message__text--editor"
      [defaultValue]="data.dynamicData?.stopReason || ''"
      [(ngModel)]="stopReasonEditors[data.id].stopReason"
    ></textarea>
    <span
      class="message__note"
      *ngIf="!stopReasonEditors[data.id]?.edit"
      (click)="editStopReason(data.id, data.dynamicData?.stopReason)"
      >辞退・不合格理由を入力</span
    >
    <span
      class="message__arrow"
      *ngIf="!stopReasonEditors[data.id]?.edit && data.dynamicData?.stopReason"
      [ngClass]="{
        'message__arrow--down': !stopReasonEditors[data.id]?.show,
        'message__arrow--up': stopReasonEditors[data.id]?.show
      }"
      (click)="toggleStopReasonDisplay(data.id)"
    ></span>
  </div>

  <!-- 辞退・不合格理由のキャンセルと更新 -->
  <div class="operations-edit" *ngIf="stopReasonEditors[data.id]?.edit">
    <button
      class="operations-edit__control btn btn--medium btn--white btn--outline-gray"
      (click)="onCancelClick(data.id)"
    >
      キャンセル
    </button>
    <button
      class="operations-edit__control operations-edit__control--save btn btn--medium btn--blue-primary"
      (click)="onStopReasonSaveClick(data)"
    >
      更新
    </button>
  </div>
</ng-template>

<ng-template #missingInfoDialog>
  <div class="missing-info-dialog-content">
    <p class="missing-info-dialog-content__header">
      担当が設定されていないか、求人ステータスが募集中または推薦停止ではありません。<br />求人詳細ページを確認してください。
    </p>
    <table
      *ngIf="jobUserChecks && jobUserChecks.length"
      class="missing-info-dialog-content__table"
    >
      <thead>
        <tr>
          <th>求人ID、ポジション名</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let jobUserCheck of jobUserChecks">
          <td>
            <div class="missing-info-dialog-content__cell">
              <span class="missing-info-dialog-content__cell--label">
                {{ jobUserCheck.frontJobId }}
              </span>
              <span class="missing-info-dialog-content__cell--value">
                {{ jobUserCheck.position }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
