import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailSendConfig } from '../config';
var StudentSendConfig = /** @class */ (function (_super) {
    tslib_1.__extends(StudentSendConfig, _super);
    function StudentSendConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StudentSendConfig.prototype.getTemplateTypeId = function () {
        return [1];
    };
    StudentSendConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.student = params.student || this.sender.model.student;
            this.sender.model.studentId = this.sender.model.student ? this.sender.model.student.id : null;
        }
        _super.prototype.setParams.call(this, params);
    };
    StudentSendConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.sender.model.student) {
            return;
        }
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        var student = this.sender.model.student;
        this.sender.model.cc_single_hidden = student.emailSub != null;
        metaData.groups[0].rows.push({
            title: 'To (求職者)',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'to',
                        type: 'checkbox',
                        labelField: 'label',
                        valueField: 'value',
                        multi: true,
                        disabled: student.emailMainAvailable != null,
                        options: student.emailMain
                            ? [
                                {
                                    label: student.emailMainAvailable
                                        ? "\u3010\u9001\u4FE1\u4E0D\u53EF\u3011" + (student.lastName + student.firstName) + " \u30E1\u30A4\u30F3 <" + student.emailMain + ">"
                                        : student.lastName + student.firstName + " \u30E1\u30A4\u30F3 <" + student.emailMain + ">",
                                    value: student.emailMain,
                                },
                            ]
                            : [],
                        validators: { required: true },
                    }
                    : {
                        name: 'to',
                        type: 'label',
                        supplier: function () { return student.lastName + student.firstName + " \u30E1\u30A4\u30F3 <" + student.emailMain + ">"; },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (求職者、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            if (!_this.sender.inConfirm) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'checkbox',
                    labelField: 'label',
                    valueField: 'value',
                    class: 'full',
                    multi: true,
                    disabled: !student.emailSub || student.emailSubAvailable != null,
                    options: student.emailSub
                        ? [
                            {
                                label: student.emailSubAvailable
                                    ? "\u3010\u9001\u4FE1\u4E0D\u53EF\u3011" + (student.lastName + student.firstName) + " \u30B5\u30D6 <" + student.emailSub + ">"
                                    : student.lastName + student.firstName + " \u30B5\u30D6 <" + student.emailSub + ">",
                                value: student.emailSub,
                            },
                        ]
                        : [],
                    showOn: { cc_single_hidden: null },
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                    showOn: { cc_single_hidden: null },
                }, {
                    name: 'cc_single_hidden',
                    type: 'text',
                    hidden: true,
                });
            }
            else if (_this.sender.model.cc_single.length) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'label',
                    class: 'full',
                    supplier: function () { return (student.emailSub ? student.lastName + student.firstName + " \u30B5\u30D6 <" + student.emailSub + ">" : ''); },
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                    showOn: student ? { cc_single_hidden: null } : null,
                });
            }
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                // displayType: 'email',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: 'テンプレート選択',
                fields: [
                    {
                        name: 'dummyTemplateType',
                        type: 'autocomplete',
                        linkTo: ['subject', 'body'],
                        placeholder: 'テンプレート名',
                        labelField: 'name',
                        class: 'half',
                        options: [],
                        supplier: function (value, callType, getValue) {
                            var typeIds = getValue('templateTypeIds');
                            return _this.sender.mailApiService.getMailTemplateNames().pipe(map(function (res) {
                                var filtered = res.filter(function (r) { return r.status === 1 && (!typeIds.length || typeIds.includes(r.mailTemplateTypeId)); });
                                return {
                                    options: filtered,
                                };
                            }));
                        },
                    },
                ],
            });
        }
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.subject : undefined,
                                };
                            }));
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return _this.sender.model.subject; },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'enterprise',
                label: '添付ファイル',
                fieldName: 'enterpriseId',
            }, null, 'half tall')[0];
            row.class = 'ou';
            if (!_this.sender.inConfirm) {
                row.fields[0].labelBefore = '企業：';
                row.fields[0].linkTo = ['enterpriseFileIds'];
                row.fields[0].actions = [
                    {
                        title: 'データフォルダから選択',
                        type: 'RUNNABLE',
                        allowOn: { enterpriseId: null },
                        runnable: function () {
                            _this.sender.enterpriseFileIds = _this.sender.model.enterpriseFileIds.slice();
                            PopupControlComponent.instance.open({
                                content: _this.sender.fileSelectTemplate,
                                confirmText: '添付',
                                cancelText: 'キャンセル',
                                title: 'データフォルダ',
                                confirmCallback: function () {
                                    _this.sender.model.enterpriseFileIds = _this.sender.enterpriseFileIds.slice();
                                },
                            });
                        },
                    },
                ];
            }
            else {
                row.fields.length = 0;
            }
            if (!_this.sender.inConfirm || _this.sender.model.enterpriseFileIds.length) {
                row.fields.push({
                    name: 'enterpriseFileIds',
                    type: 'autocomplete',
                    labelField: [
                        {
                            name: 'name',
                            class: _this.sender.inConfirm ? 'link' : null,
                            action: _this.sender.inConfirm
                                ? function (file) {
                                    return _this.sender.fileService.downloadFile('enterprise', +Object.keys(_this.sender.enterpriseFiles).find(function (key) { return _this.sender.enterpriseFiles[key].find(function (f) { return f.id === file.id; }) != null; }), file.id, file.name);
                                }
                                : null,
                        },
                    ],
                    labelBefore: _this.sender.inConfirm ? '企業：' : null,
                    valueField: 'id',
                    multi: true,
                    style: !_this.sender.inConfirm ? { 'padding-left': '40px' } : null,
                    class: 'options-only half',
                    options: [],
                    supplier: function (value, callType, getValue) {
                        var id = getValue('enterpriseId');
                        var selectedFiles = Object.values(_this.sender.enterpriseFiles).flatten().filter(function (f) {
                            return _this.sender.model.enterpriseFileIds.includes(f.id);
                        });
                        if (id === _this.sender.enterpriseIdForFile) {
                            return { options: selectedFiles.concat(_this.sender.enterpriseFiles[id]) };
                        }
                        if (!id) {
                            return { options: selectedFiles };
                        }
                        else {
                            _this.sender.enterpriseIdForFile = id;
                            return _this.sender.fileService.getFiles('enterprise', id).pipe(map(function (res) {
                                _this.sender.enterpriseFiles[id] = res.filter(function (f) { return f.studentSendFlag; });
                                return { options: selectedFiles.concat(_this.sender.enterpriseFiles[id]) };
                            }));
                        }
                    },
                });
                if (!_this.sender.inConfirm || _this.sender.model.studentFileIds.length) {
                    row.fields.push({
                        name: 'file-hr',
                        type: 'hr',
                        class: 'full',
                    });
                }
            }
            if (!_this.sender.inConfirm || _this.sender.model.studentFileIds.length) {
                row.fields.push({
                    name: 'studentFileIds',
                    type: !_this.sender.inConfirm ? 'checkbox' : 'autocomplete',
                    class: 'multi-ou sticky-label full',
                    labelField: !_this.sender.inConfirm
                        ? 'name'
                        : [
                            {
                                name: 'name',
                                class: _this.sender.inConfirm ? 'link' : null,
                                action: _this.sender.inConfirm
                                    ? function (file) { return _this.sender.fileService.downloadFile('students', student.id, file.id, file.name); }
                                    : null,
                            },
                        ],
                    valueField: 'id',
                    labelBefore: '求職者：',
                    multi: true,
                    options: [],
                    supplier: function () { return ({ options: _this.sender.files }); },
                });
            }
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.body : undefined,
                                };
                            }));
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }].concat(res);
                                    return {
                                        value: value ? res.find(function (s) { return s.id === value.id; }) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    StudentSendConfig.prototype.update = function () {
        var _this = this;
        if (this.sender.model.student.emailMain && !this.sender.model.student.emailMainAvailable) {
            this.sender.model.to = [this.sender.model.student.emailMain];
        }
        if (this.sender.model.student.emailSub && !this.sender.model.student.emailSubAvailable) {
            this.sender.model.cc_single = [this.sender.model.student.emailSub];
        }
        if (this.sender.model.student && this.sender.studentIdForFiles !== this.sender.model.student.id) {
            this.sender.studentIdForFiles = this.sender.model.student.id;
            this.sender.fileService.getFiles('students', this.sender.model.student.id).subscribe(function (res) {
                var _a;
                _this.sender.files.length = 0;
                (_a = _this.sender.files).push.apply(_a, res);
                _this.initForm();
            });
        }
    };
    StudentSendConfig.prototype.save = function (toSave) {
        var _this = this;
        this.sender.mailApiService.sendStudentMail(this.sender.model.student.id, toSave).subscribe(function (res) { return _this.sender.afterSend(res); });
    };
    return StudentSendConfig;
}(MailSendConfig));
export { StudentSendConfig };
