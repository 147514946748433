import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { ClickOutsideDirective } from '@agent-ds/shared/directives/click-outside.directive';
import { isNestedSupplied, SupplierCallType } from '@agent-ds/shared/models';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { deepCompare, getValueFromObject } from '@agent-ds/shared/util/util';
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { ControlValueAccessor, FormControl, ValidationErrors, Validator } from '@angular/forms';
import { Observable } from 'rxjs';
var AutocompleteComponent = /** @class */ (function () {
    function AutocompleteComponent(cdr) {
        var _this = this;
        this.cdr = cdr;
        this.hideBtn = true;
        this.filters = [];
        this.valueChange = new EventEmitter();
        this.optionsChange = new EventEmitter();
        this.innerOptions = [];
        this.filteredOptions = [];
        this.showCheckbox = false;
        this.showTooltipToEnter = false;
        this.closed = true;
        this.selectedItems = [];
        this.everSelectedItems = [];
        this.filterValues = {};
        this.transparentFilterValues = {};
        this.singleSelectedLabel = '';
        this.getValueFromObject = getValueFromObject;
        this.alive = true;
        this.propagateChange = function () { return _this.valueChange.emit(_this.valueInner); };
        this.propagateTouch = function () { return false; };
    }
    Object.defineProperty(AutocompleteComponent.prototype, "options", {
        get: function () {
            return this.innerOptions;
        },
        set: function (options) {
            var _a;
            this.innerOptions = options || [];
            if (!this.everSelectedItems.length) {
                (_a = this.everSelectedItems).push.apply(_a, this.innerOptions);
            }
            this.onFilterChange(null, null);
            this.updateSelected();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "value", {
        get: function () {
            return this.valueInner;
        },
        set: function (value) {
            this.valueInner = value;
            this.updateSelected();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "hasOptionHeader", {
        get: function () {
            return Array.isArray(this.labelField) && this.labelField.find(function (field) { return field.title != null; }) != null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "optionTemplateArray", {
        get: function () {
            return Array.isArray(this.labelField) ? this.labelField.filter(function (item) { return !item.hidden; }) : [{ name: this.labelField }];
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComponent.prototype.ngOnInit = function () {
        this.alive = true;
        this.clickDir.detach();
        if (!this.value) {
            this.value = this.multi ? [] : '';
            this.input.nativeElement.value = '';
        }
        this.initFilters();
    };
    AutocompleteComponent.prototype.ngOnChanges = function (changes) {
        if (changes.valueField || changes.labelField) {
            this.updateSelected();
        }
    };
    AutocompleteComponent.prototype.ngOnDestroy = function () {
        this.alive = false;
        this.propagateChange = this.propagateTouch = function () { return false; };
    };
    AutocompleteComponent.prototype.initFilters = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, filter;
            return tslib_1.__generator(this, function (_b) {
                if (this.filters) {
                    for (_i = 0, _a = this.filters; _i < _a.length; _i++) {
                        filter = _a[_i];
                        if (filter.supplier) {
                            this.updateFilter(filter);
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    AutocompleteComponent.prototype.compare = function (value, option) {
        var _this = this;
        return (value != null &&
            (typeof this.valueField === 'string'
                ? value === getValueFromObject(option, this.valueField)
                : typeOf(this.valueField) === 'object'
                    ? Object.keys(this.valueField).find(function (key) { return (value[key] || value) === option[_this.valueField[key]]; }) != null
                    : value === option || deepCompare(value, option, 'id')));
    };
    AutocompleteComponent.prototype.compareAll = function (value, option) {
        var _this = this;
        return value.find(function (val) { return _this.compare(val, option); }) != null;
    };
    AutocompleteComponent.prototype.onKeyDown = function (event) {
        var _this = this;
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.blurClearPrevent = event.code === 'Enter';
        // wait a little, to input element value update
        this.timeout = setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var sup;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.propagateTouch();
                if (this.input.nativeElement.value.length && this.input.nativeElement.value !== this.singleSelectedLabel) {
                    if (this.supplier && event.code !== 'Enter') {
                        event.stopPropagation();
                        sup = this.supplier(this.input.nativeElement.value, SupplierCallType.UPDATE, this.getValue, this.setValue, this.filterValues);
                        if (sup instanceof Observable) {
                            sup.toPromise().then(function (res) {
                                if (res && res.options) {
                                    _this.innerOptions = res.options;
                                    _this.onFilterChange(null, null);
                                    _this.populate(event.code === 'Enter');
                                }
                            });
                        }
                        else if (sup && sup.options) {
                            this.innerOptions = sup.options;
                            this.onFilterChange(null, null);
                            this.populate(event.code === 'Enter');
                        }
                        else {
                            this.populate(event.code === 'Enter');
                        }
                    }
                    else {
                        event.stopPropagation();
                        this.populate(event.code === 'Enter');
                    }
                }
                else {
                    this.showTooltipToEnter = false;
                    this.closed = true;
                    this.clickDir.detach();
                    this.detect();
                }
                return [2 /*return*/];
            });
        }); }, 400);
    };
    AutocompleteComponent.prototype.populate = function (onEnter) {
        var _a;
        this.filter();
        if (this.filteredOptions.length || this.showCheckbox) {
            this.showTooltipToEnter = false;
            this.showOptionList();
        }
        else if (this.input.nativeElement.value.length) {
            var keepTooltip = false;
            if (onEnter && this.manualInputOn != null && !this.multi) {
                this.value = this.manualInputOn ? (_a = {}, _a[this.manualInputOn] = this.input.nativeElement.value, _a) : this.input.nativeElement.value;
                this.propagateChange();
                this.input.nativeElement.blur();
            }
            else {
                this.showTooltipToEnter = keepTooltip = true;
                this.clickDir.attach();
            }
            this.hideOptions(keepTooltip);
            if (keepTooltip) {
                this.detect();
            }
        }
        else {
            this.detect();
        }
    };
    AutocompleteComponent.prototype.updateSelected = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filtered, values, _i, filtered_1, l, _a, _b, _c, _d;
            var _this = this;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this.closed) {
                            return [2 /*return*/];
                        }
                        if (!(this.multi && this.valueInner)) return [3 /*break*/, 1];
                        this.selectedItems = this.valueInner.map(function (v) { return _this.everSelectedItems.find(function (option) { return _this.compare(v, option); }); }).filter(function (s) { return s; });
                        return [3 /*break*/, 14];
                    case 1:
                        this.selectedItem = this.everSelectedItems.find(function (option) { return _this.compare(_this.value, option); }) || this.selectedFromValue;
                        if (!this.selectedItem) return [3 /*break*/, 12];
                        if (!Array.isArray(this.labelField)) return [3 /*break*/, 10];
                        filtered = this.labelField.filter(function (label) { return !(label.hidden || label.hiddenAsValue); });
                        values = [];
                        _i = 0, filtered_1 = filtered;
                        _e.label = 2;
                    case 2:
                        if (!(_i < filtered_1.length)) return [3 /*break*/, 9];
                        l = filtered_1[_i];
                        _b = (_a = values).push;
                        if (!l.supplier) return [3 /*break*/, 3];
                        _c = l.supplier(this.selectedItem);
                        return [3 /*break*/, 7];
                    case 3:
                        if (!l.supplierAsync) return [3 /*break*/, 5];
                        return [4 /*yield*/, l.supplierAsync(this.selectedItem).toPromise()];
                    case 4:
                        _d = _e.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        _d = getValueFromObject(this.selectedItem, l.name);
                        _e.label = 6;
                    case 6:
                        _c = _d;
                        _e.label = 7;
                    case 7:
                        _b.apply(_a, [_c]);
                        _e.label = 8;
                    case 8:
                        _i++;
                        return [3 /*break*/, 2];
                    case 9:
                        this.singleSelectedLabel = values.join(' ');
                        return [3 /*break*/, 11];
                    case 10:
                        this.singleSelectedLabel = this.labelField
                            ? getValueFromObject(this.selectedItem, this.labelField.toString())
                            : this.selectedItem;
                        _e.label = 11;
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        this.singleSelectedLabel = '';
                        _e.label = 13;
                    case 13:
                        this.input.nativeElement.value = this.singleSelectedLabel;
                        _e.label = 14;
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AutocompleteComponent.prototype, "selectedFromValue", {
        get: function () {
            var _a;
            if (this.value != null && this.manualInputOn) {
                var val_1 = typeof this.value === 'object' && this.manualInputOn ? this.value[this.manualInputOn] : this.value;
                if (Array.isArray(this.labelField)) {
                    var res_1 = {};
                    this.labelField.forEach(function (label) { return (res_1[label.name] = res_1[label.name] || val_1); });
                    return res_1;
                }
                else if (typeof this.labelField === 'string') {
                    return _a = {}, _a[this.labelField] = val_1, _a;
                }
                else {
                    return val_1;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComponent.prototype.onBlur = function (event) {
        this.hideOptions();
        if (!this.blurClearPrevent && !this.multi) {
            this.updateSelected();
        }
        this.blurClearPrevent = false;
    };
    AutocompleteComponent.prototype.onOptionSelect = function (option) {
        this.propagateTouch();
        if (this.multi) {
            if (!Array.isArray(this.valueInner)) {
                this.valueInner = [];
            }
            var val = typeof this.valueField === 'string' ? getValueFromObject(option, this.valueField) : option;
            var idx = this.valueInner.indexOf(val);
            if (idx > -1) {
                this.valueInner.splice(idx, 1);
                this.selectedItems.splice(idx, 1);
                this.everSelectedItems.remove(option, true);
            }
            else {
                this.valueInner.push(val);
                this.selectedItems.push(option);
                this.everSelectedItems.include(option, true);
            }
            this.propagateChange();
            this.input.nativeElement.value = '';
            if (!this.showCheckbox) {
                this.hideOptions();
            }
            else {
                this.detect();
            }
        }
        else {
            this.everSelectedItems.length = 0;
            this.value = typeof this.valueField === 'string' ? getValueFromObject(option, this.valueField) : option;
            this.everSelectedItems.include(option, true);
            this.propagateChange();
            this.hideOptions();
        }
    };
    AutocompleteComponent.prototype.onFilterChange = function (key, value, suppressEvent) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filter, _loop_1, this_1, _i, _a, linkKey;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                if (this.filters) {
                    filter = this.filters.find(function (item) { return item.name === key; });
                    if (filter) {
                        value =
                            value === NULL_SELECTED_VALUE
                                ? null
                                : filter.valueField
                                    ? getValueFromObject(filter.options[value], filter.valueField)
                                    : filter.options[value];
                        if (filter.linkTo) {
                            _loop_1 = function (linkKey) {
                                var linkFilter = this_1.filters.find(function (linkItem) { return linkItem.name === linkKey; });
                                if (linkFilter && linkFilter.supplier && linkKey !== key) {
                                    this_1.updateFilter(linkFilter, value);
                                }
                            };
                            this_1 = this;
                            for (_i = 0, _a = filter.linkTo; _i < _a.length; _i++) {
                                linkKey = _a[_i];
                                _loop_1(linkKey);
                            }
                        }
                        if (!filter.transparent) {
                            this.filterValues[key] = value;
                        }
                        else {
                            this.transparentFilterValues[key] = value;
                        }
                    }
                }
                if (!suppressEvent) {
                    this.globalFilteredOptions = this.filters
                        ? this.options.filter(function (option) {
                            return !Object.keys(_this.filterValues).find(function (filterKey) {
                                var filterVal = option[filterKey] === undefined ? undefined : _this.filterValues[filterKey];
                                return Array.isArray(filterVal)
                                    ? filterVal.find(function (f) { return f === option[filterKey]; }) == null
                                    : filterVal != null && option[filterKey] !== filterVal;
                            });
                        })
                        : this.options;
                }
                return [2 /*return*/];
            });
        });
    };
    AutocompleteComponent.prototype.updateFilter = function (filter, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sup, options, linkValue;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                sup = filter.supplier(value);
                if (sup instanceof Observable) {
                    sup.toPromise().then(function (res) {
                        if (res != null) {
                            var options = res.options;
                            var linkValue = isNestedSupplied(res) ? res.value : res;
                            filter.options = options || filter.options;
                            if (linkValue != null) {
                                _this.onFilterChange(filter.name, linkValue, true);
                            }
                            _this.detect();
                        }
                    });
                }
                else if (sup != null) {
                    options = sup.options;
                    linkValue = isNestedSupplied(sup) ? sup.value : sup;
                    filter.options = options || filter.options;
                    if (linkValue != null) {
                        this.onFilterChange(filter.name, linkValue, true);
                    }
                    this.detect();
                }
                return [2 /*return*/];
            });
        });
    };
    AutocompleteComponent.prototype.onArrowButtonClick = function () {
        var _this = this;
        if (this.closed && !this.readonly) {
            this.showCheckbox = true;
            var sup = this.supplier
                ? this.supplier(this.input.nativeElement.value, SupplierCallType.UPDATE, this.getValue, this.setValue, this.filterValues)
                : null;
            if (sup instanceof Observable) {
                sup.toPromise().then(function (res) {
                    if (res && res.options) {
                        _this.innerOptions = res.options;
                    }
                    _this.onFilterChange(null, null).then(function () { return _this.showOptionList(); });
                });
            }
            else if (sup && sup.options) {
                this.innerOptions = sup.options;
                this.onFilterChange(null, null).then(function () { return _this.showOptionList(); });
            }
            else {
                this.showOptionList();
            }
        }
    };
    AutocompleteComponent.prototype.onDeleteClick = function (option) {
        if (!this.readonly && this.valueInner) {
            this.everSelectedItems.remove(option, true);
            this.selectedItems.remove(option);
            this.valueInner.remove(typeof this.valueField === 'string' ? getValueFromObject(option, this.valueField) : option);
            this.propagateTouch();
            this.propagateChange();
            this.detect();
        }
    };
    AutocompleteComponent.prototype.clear = function () {
        if (this.multi) {
            this.valueInner.length = 0;
            this.selectedItems.length = 0;
        }
        else {
            this.valueInner = null;
            this.selectedItem = null;
        }
        this.everSelectedItems.length = 0;
        this.input.nativeElement.value = '';
        this.showTooltipToEnter = false;
        this.propagateTouch();
        this.propagateChange();
        this.detect();
    };
    AutocompleteComponent.prototype.filter = function () {
        var _this = this;
        var labels = Array.isArray(this.labelField) ? this.labelField.filter(function (l) { return !l.skipInFilter; }) : [];
        this.filteredOptions =
            this.input.nativeElement.value.length > 0 || (this.multi && this.selectedItems.length)
                ? this.globalFilteredOptions.filter(function (x) {
                    if (Array.isArray(_this.labelField)) {
                        if (!labels.length) {
                            return true;
                        }
                        for (var _i = 0, labels_1 = labels; _i < labels_1.length; _i++) {
                            var label = labels_1[_i];
                            var l = label.supplier ? label.supplier(x) : getValueFromObject(x, label.name);
                            if (l && l.toString().includes(_this.input.nativeElement.value)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return (_this.labelField ? getValueFromObject(x, _this.labelField.toString()) : x).includes(_this.input.nativeElement.value);
                })
                : this.globalFilteredOptions;
    };
    AutocompleteComponent.prototype.hideOptions = function (keepTooltip) {
        if (keepTooltip === void 0) { keepTooltip = false; }
        this.showCheckbox = false;
        if (!keepTooltip) {
            this.showTooltipToEnter = false;
            this.clickDir.detach();
        }
        if (this.closed) {
            return;
        }
        this.closed = true;
        if (!this.multi && this.value && this.input.nativeElement.value !== this.value) {
            this.updateSelected();
        }
        if (!keepTooltip) {
            this.detect();
        }
    };
    AutocompleteComponent.prototype.showOptionList = function () {
        if (!this.readonly && (this.filteredOptions.length || this.showCheckbox)) {
            this.closed = false;
            this.clickDir.attach();
        }
        this.detect();
    };
    AutocompleteComponent.prototype.writeValue = function (value) {
        var _this = this;
        var _a;
        var val = value == null && this.multi ? [] : value;
        if (this.multi) {
            var currentSelected = this.options.concat(this.everSelectedItems).filter(function (option) { return _this.compareAll(val, option); });
            this.everSelectedItems.length = 0;
            (_a = this.everSelectedItems).push.apply(_a, currentSelected);
        }
        else {
            this.everSelectedItems.length = 0;
            var selected = this.options.find(function (option) { return _this.compare(val, option); });
            if (selected) {
                this.everSelectedItems.push(selected);
            }
        }
        this.value = val;
        this.detect();
    };
    AutocompleteComponent.prototype.registerOnChange = function (fn) {
        var _this = this;
        this.propagateChange = function () {
            fn(_this.valueInner);
            _this.valueChange.emit(_this.valueInner);
        };
    };
    AutocompleteComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouch = fn;
    };
    AutocompleteComponent.prototype.setDisabledState = function (isDisabled) {
        this.readonly = isDisabled;
    };
    AutocompleteComponent.prototype.validate = function (c) {
        var _this = this;
        if (!this.alive) {
            return null;
        }
        var valid = {};
        if (this && this.validators) {
            Object.keys(this.validators).forEach(function (key) {
                var ret = typeof _this.validators[key] === 'function' ? _this.validators[key](c) : null;
                if (ret != null ||
                    (key === 'required' &&
                        _this.validators[key] &&
                        (_this.value == null || (_this.multi && (!_this.valueInner || !_this.valueInner.length)))) ||
                    (key === 'min' && _this.multi && (!_this.valueInner || _this.valueInner.length < _this.validators[key])) ||
                    (key === 'max' && _this.multi && _this.valueInner && _this.valueInner.length > _this.validators[key])) {
                    valid[key] = ret || true;
                }
            });
        }
        return Object.keys(valid).length ? valid : null;
    };
    AutocompleteComponent.prototype.detect = function () {
        if (this.alive) {
            this.cdr.detectChanges();
        }
    };
    return AutocompleteComponent;
}());
export { AutocompleteComponent };
