<div class="content" [class.content--no-footer]="readOnly">
  <div class="content__title">
    {{ area?.name ? area?.name + " ┃ " : "" }}
    {{ seminar?.seminarAt | date: "yyyy/MM/dd（E）HH:mm〜" }}
    {{ seminar?.type != null ? "　　セミナー属性：" + seminar?.type : "" }}
  </div>
  <div class="content__controls">
    <span class="content__controls__count">{{
      table?.checkedItems.length || table?.totalSize
    }}</span>
    <span class="content__controls__label">{{
      table?.checkedItems.length ? "人選択中" : "人"
    }}</span>
  </div>
  <form #form="ngForm" class="content__form">
    <ag-page-scroll-table
      class="content__table"
      [tableConfig]="tableConfig"
      [content]="attendees"
      (itemSelected)="onAttendeeSelect($event)"
    ></ag-page-scroll-table>
  </form>
</div>

<div *ngIf="!readOnly" class="footer">
  <div class="footer__container">
    <div>
      <button
        [disabled]="!attendees?.length"
        class="footer__button btn btn--large btn--blue"
        (click)="saveThanksMails()"
      >
        サンクスメール送信
      </button>
    </div>
  </div>
</div>

<ng-template #thanksMailType let-data>
  <div
    *ngIf="!readOnly; else readOnlyThanksMailType"
    class="container container--center-items"
    (click)="$event.stopPropagation()"
  >
    <div
      *ngFor="let mailType of thanksMailTypes"
      class="container__radio-item"
      [class.container__radio-item--active]="
        model[data.id].thanksMailType === mailType.id
      "
    >
      <input
        class="radio"
        type="radio"
        name="thanksMailType_{{ data.id }}"
        [value]="mailType.id"
        [(ngModel)]="model[data.id].thanksMailType"
      />
      <label class="label">{{ mailType.name }}</label>
    </div>
    <div class="container__separator"></div>
  </div>

  <ng-template #readOnlyThanksMailType>
    <span class="thanks-mail">{{ THANKS_MAIL_TYPES[data.mailType] }}</span>
  </ng-template>
</ng-template>

<ng-template #student let-data>
  <div class="container">
    <div class="container__column">
      <div class="label--biggest">
        {{ data.dynamicData?.lastName }}{{ data.dynamicData?.firstName }}
      </div>
      <div>
        ({{ (data.dynamicData?.birthday | age) || "-" }}歳
        {{ data.dynamicData?.gender }})
      </div>
    </div>
  </div>
</ng-template>

<ng-template #school let-data>
  <div class="school-info">
    <span
      *ngIf="data?.dynamicData?.academicFieldType"
      class="school-info__flag"
      [class.school-info__flag--pink]="
        data?.dynamicData?.academicFieldType?.includes('文')
      "
      [class.school-info__flag--blue]="
        data?.dynamicData?.academicFieldType?.includes('理')
      "
      >{{
        data?.dynamicData.academicFieldType.includes("文") ? "文" : "理"
      }}</span
    >
    <span class="school-info__name">{{
      data?.dynamicData?.academicHistory1?.schoolName
    }}</span>
    <div class="school-info__university">
      <div
        class="school-info__class"
        *ngIf="data?.dynamicData?.universityLevel"
      >
        {{ (data?.dynamicData?.universityLevel)[0] }}
      </div>
      <span>{{ data?.dynamicData?.graduateType }}</span>
      <span>{{ data?.dynamicData?.academicHistory1?.departmentName }}</span>
    </div>
  </div>
</ng-template>
