import * as tslib_1 from "tslib";
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DynamicFieldService, JobApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { deepClone } from '@agent-ds/shared/util/util';
import { OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
var JobDetailTabComponent = /** @class */ (function () {
    function JobDetailTabComponent(dynamicService, datePipe, jobApiService, companyApiService, userTeamProvider) {
        this.dynamicService = dynamicService;
        this.datePipe = datePipe;
        this.jobApiService = jobApiService;
        this.companyApiService = companyApiService;
        this.userTeamProvider = userTeamProvider;
        this.validityArray = [true, true, true, true, true, true, true, true, true];
        this.validity = true;
        this.enterpriseDepartmentUsers = {};
    }
    JobDetailTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.init();
        });
    };
    JobDetailTabComponent.prototype.init = function () {
        var _this = this;
        this.headMeta = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    class: 'oneliner',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'status'), 'dynamicData').concat([
                        {
                            title: '更新者',
                            class: 'fill',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'updateUserId',
                                    supplier: function () { return (_this.job ? _this.userTeamProvider.getUserTeamNameById(_this.job.updateUserId) : '-'); },
                                },
                            ],
                        },
                        {
                            title: '入力日',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'registeredAt',
                                    supplier: function (val) { return _this.datePipe.transform(val); },
                                },
                            ],
                        },
                        {
                            title: '更新日',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'lastUpdatedAt',
                                    supplier: function (val) { return _this.datePipe.transform(val); },
                                },
                            ],
                        },
                    ]),
                },
            ],
        };
        this.leftMeta = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    title: '概要',
                    class: 'form__group--green-title-border',
                    rows: [
                        {
                            title: '企業',
                            fields: [
                                {
                                    name: 'enterpriseId',
                                    type: 'label',
                                    supplier: function () { return _this.job.frontEnterpriseId + "-" + _this.job.enterpriseName; },
                                },
                            ],
                        },
                        {
                            title: '部署',
                            fields: [
                                {
                                    name: 'enterpriseDepartmentId',
                                    type: 'label',
                                    options: [],
                                    allowOn: { enterpriseId: null },
                                    supplier: function (value, callType, getValue) {
                                        var linkValue = getValue('enterpriseId');
                                        return linkValue
                                            ? _this.companyApiService.getDepartments(_this.job.enterpriseId).pipe(map(function (v) {
                                                var ret = v.enterpriseDepartments
                                                    .map(function (e) {
                                                    e.dynamicData.id = e.id;
                                                    _this.enterpriseDepartmentUsers[e.id] = e.enterpriseDepartmentUsers;
                                                    return e.dynamicData;
                                                })
                                                    .find(function (d) { return d.id === _this.job.enterpriseDepartmentId; }) || {
                                                    name: '',
                                                    id: '',
                                                };
                                                _this.rightMeta.groups = _this.rightMeta.groups.slice();
                                                return ret.id + "-" + ret.name;
                                            }))
                                            : null;
                                    },
                                    validators: { required: true },
                                },
                            ],
                        }
                    ].concat(this.dynamicService.getFormRows(tslib_1.__assign({}, this.dynamicService.getDefinition('job', 'position'), { displayType: 'textarea' }), 'dynamicData'), this.dynamicService.getFormRows(tslib_1.__assign({}, this.dynamicService.getDefinition('job', 'tagline'), { displayType: 'textarea' }), 'dynamicData')),
                },
                {
                    title: '職務内容',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'jobType1'), 'dynamicData', 'half').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'jobType2'), 'dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'jobType3'), 'dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'itTypeCareer'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'itTypeArea'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'itAppealFlag'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'pgExperience'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'salesJobFlag'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'salesJobProduct'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'descriptionMainBody'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'description1Title'), 'dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'description1Body'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'description2Title'), 'dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'description2Body'), 'dynamicData')),
                },
                {
                    title: '勤務先',
                    class: 'form__group--green-title-border',
                    injectToHeader: !this.readonly && this.actionButtonTemplate,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'workplace'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'workplaceRemarks'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'transfer'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'jobTransfer'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'firstPlaceOfAssignment'), 'dynamicData'), (function () {
                        var typeRow = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('job', 'passiveSmokingMeasureType'), 'dynamicData', 'full')[0];
                        typeRow.fields[0].linkTo = ['dynamicData.passiveSmokingMeasureRule'];
                        var ruleRow = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('job', 'passiveSmokingMeasureRule'), 'dynamicData', 'full')[0];
                        ruleRow.fields[0].supplier = function (value, callType, getValue) {
                            var linkValue = getValue('dynamicData.passiveSmokingMeasureType');
                            switch (linkValue) {
                                case '第一種施設（学校・官公庁・病院等）':
                                    return { options: ['敷地内禁煙', '敷地内禁煙（喫煙場所：有）', 'その他'] };
                                case '第二種施設（事業所・ホテル・旅館・飲食店等）':
                                    return {
                                        options: [
                                            '屋内禁煙',
                                            '屋内禁煙（喫煙場所：有）',
                                            '屋内原則禁煙（喫煙室：有）',
                                            '屋内原則禁煙（喫煙可の宿泊室：有）',
                                            'その他',
                                        ],
                                    };
                                case '喫煙目的施設（バー・スナック・たばこ販売店等）':
                                    return { options: ['屋内喫煙可', '屋内喫煙可（喫煙室内に限る）', 'その他'] };
                                case '旅客運送事業（バス・飛行機・電車・船舶）':
                                    return { options: ['車内禁煙', '車内原則禁煙（喫煙室：有）', 'その他'] };
                                case '屋外（第一種施設の敷地内は除く）':
                                    return { options: ['屋外のため規定なし'] };
                            }
                        };
                        ruleRow.fields[0].allowOn = { 'dynamicData.passiveSmokingMeasureType': null };
                        return [typeRow, ruleRow];
                    })(), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'passiveSmokingMeasureRemarks'), 'dynamicData')),
                },
                {
                    title: '選考内容',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'requirement'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'certificates'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'languageSkill'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewDescription'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'aptitudeTest'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewCount'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'requireDocument'), 'dynamicData')),
                },
                {
                    title: 'アピールポイント',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'appealMainBody'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'appeal1Title'), 'dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'appeal1Body'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'appeal2Title'), 'dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'appeal2Body'), 'dynamicData')),
                },
                {
                    title: '選考情報',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'hasSeminar'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'screeningArea'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'workArea'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'webBasedScreening'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'seminarNote1'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'seminarNote2'), 'dynamicData')),
                },
                {
                    title: '性格・タイプ',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'characterAndType1'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'characterAndType2'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'characterAndType3'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'characterAndType4'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'characterAndType5'), 'dynamicData')),
                },
            ],
        };
        this.rightMeta = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    title: '担当',
                    class: 'form__group--green-title-border',
                    rows: this.getUserRows(),
                },
                {
                    title: 'ステータス',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'recommendationDateKind')).concat([
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('job', 'recommendationDate'))[0];
                            row.fields[0].allowOn = { recommendationDateKind: null };
                            row.fields[0].validators.required = true;
                            return row;
                        })()
                    ], this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'rank'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'recruitYear'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'agentServiceHire'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'partnerHire'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'turnaroundDay'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'exclusiveJob'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'dedicatedHire'), null, 'half')),
                },
                {
                    title: '採用条件',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'communicationSkill')).concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'impressionAndCharm')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'lastAcademicRecord'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'gender'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'afterGraduate')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'postGraduate')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'universityLevel'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'academicFieldType'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'academicCourseType')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'firstPriorityFaculty')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'secondPriorityFaculty')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'scienceProjectFlag')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'scienceRecruitmentCategory')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'roninYearType')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'repeatYearType')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'exceptionalExperience')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'internationalStudent')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'pastGlobalCountry'))),
                },
                {
                    title: '待遇・福利厚生',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'employmentMethod')).concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'hasTrial')), [
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('job', 'trialMonth'))[0];
                            row.fields[0].allowOn = { hasTrial: '有' };
                            row.fields[0].validators.required = true;
                            return row;
                        })()
                    ], this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'employmentPeriod')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'workingHourRange'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'breakHour'), null, 'full'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'overtimeType')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'overtimeHours')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'overtimeWork')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'workingHourComment')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'paymentMethod')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'salary'), null, 'third'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'promotion')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'bonus'), null, 'full'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'salaryDescription')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'fixedOvertimeFee'), null, 'half'), [
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('job', 'fixedOvertimeFeeDescription'), null, 'half')[0];
                            row.fields.push({
                                type: 'label',
                                name: 'fixedOvertimeFeeDescriptionNote',
                                class: 'pre-line',
                                default: '※固定残業の金額・時間・超過分支給がわかるように明記。例）基本給170,000円+固定残業代60,000円（45時間分\n※45時間超過分は別途支給',
                            });
                            return row;
                        })(),
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('job', 'trialDescription'))[0];
                            row.fields.push({
                                type: 'label',
                                name: 'trialDescriptionNote',
                                class: 'pre-line',
                                default: '※試用期間中は労働条件などと試用期間終了後の労働条件が異なる場合はそれぞれ労働条件などを記載してください。',
                            });
                            return row;
                        })()
                    ], this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'workhourType'), null, 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'annualHolidays')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'holidayType')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'weekendDescription')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'insurance')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'commuterDescription'), null, 'full'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'supplementDescription')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'benefitDescription')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'flexibleWorkingFlag'))),
                },
            ],
        };
        this.screeningMeta1 = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    title: 'セミナー・面接情報',
                    collapsedTitle: 'セミナー選考情報',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.screeningInfoSeminar); },
                    class: 'form__group--green-title-border',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.screeningInfoSeminar || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'screeningInfoSeminar')),
                },
                {
                    collapsedTitle: '1次選考情報',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.screeningInfo1); },
                    class: 'form__group--no-margin',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.screeningInfo1 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'screeningInfo1')),
                },
                {
                    collapsedTitle: '2次選考情報',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.screeningInfo2); },
                    class: 'form__group--no-margin',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.screeningInfo2 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'screeningInfo2')),
                },
            ],
        };
        this.screeningMeta2 = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    collapsedTitle: '3次選考情報',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.screeningInfo3); },
                    class: '',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.screeningInfo3 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'screeningInfo3')),
                },
                {
                    collapsedTitle: '4次選考情報',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.screeningInfo4); },
                    class: 'form__group--no-margin',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.screeningInfo4 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'screeningInfo4')),
                },
                {
                    collapsedTitle: '5次以降選考情報',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.screeningInfo5); },
                    class: 'form__group--no-margin',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.screeningInfo5 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'screeningInfo5')),
                },
            ],
        };
        this.interviewMeta1 = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    collapsedTitle: 'セミナー案内',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.interviewInfoSeminar); },
                    class: 'form__group--no-margin no-title-column',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.interviewInfoSeminar || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewInfoSeminar')),
                },
                {
                    collapsedTitle: '面接案内1',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.interviewInfo1); },
                    class: 'form__group--no-margin no-title-column',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.interviewInfo1 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewInfo1')),
                },
                {
                    collapsedTitle: '面接案内2',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.interviewInfo2); },
                    class: 'form__group--no-margin no-title-column',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.interviewInfo2 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewInfo2')),
                },
            ],
        };
        this.interviewMeta2 = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    collapsedTitle: '面接案内3',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.interviewInfo3); },
                    class: 'form__group--no-margin no-title-column',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.interviewInfo3 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewInfo3')),
                },
                {
                    collapsedTitle: '面接案内4',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.interviewInfo4); },
                    class: 'form__group--no-margin no-title-column',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.interviewInfo4 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewInfo4')),
                },
                {
                    collapsedTitle: '面接案内5以降',
                    toggleButtonLabel: '入力',
                    toggledButtonLabel: '削除',
                    toggledButtonAction: function () { return _this.clearInfo(_this.job.dynamicData.interviewInfo5); },
                    class: 'form__group--no-margin no-title-column',
                    collapsable: true,
                    expanded: this.job != null && Object.values(this.job.dynamicData.interviewInfo5 || {}).find(function (v) { return !!v; }) != null,
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'interviewInfo5')),
                },
            ],
        };
        var customs = this.dynamicService.getDefinitions('job').filter(function (def) { return def.isCustomField; });
        if (customs.length) {
            this.customMeta = {
                disabled: this.readonly,
                initTouched: !this.readonly,
                groups: [
                    {
                        title: 'カスタムフィールド',
                        class: 'form__group--green-title-border',
                        rows: customs.map(function (c) { return _this.dynamicService.getFormRows(c); }).flatten(),
                    },
                ],
            };
        }
        this.remarksMeta = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    title: 'その他',
                    class: 'form__group--green-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'remarks'), null, 'full').slice(),
                },
            ],
        };
        this.remarksMeta1 = {
            disabled: this.readonly,
            initTouched: !this.readonly,
            groups: [
                {
                    class: 'form__group--no-title',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('job', 'confirmationRemarks'), null, 'full').slice(),
                },
            ],
        };
    };
    JobDetailTabComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.job && this.job) {
            this.job.dynamicData.raId = this.job.enterpriseDepartmentUsers
                .filter(function (user) { return user.type === EnterpriseDepartmentUserType.RA; })
                .map(function (user) { return user.userId; });
            this.job.dynamicData.paId = this.job.enterpriseDepartmentUsers
                .filter(function (user) { return user.type === EnterpriseDepartmentUserType.PA; })
                .map(function (user) { return user.userId; });
            this.job.dynamicData.asId = this.job.enterpriseDepartmentUsers
                .filter(function (user) { return user.type === EnterpriseDepartmentUserType.アシ; })
                .map(function (user) { return user.userId; });
            this.companyApiService.getDetail(this.job.enterpriseId).subscribe(function (company) {
                _this.job.dynamicData.company = company.id;
            });
            this.onFormChanged('recommendationDateKind');
            this.onFormChanged('hasTrial');
            if (this.screeningMeta1) {
                this.screeningMeta1.groups[0].expanded = Object.values(this.job.dynamicData.screeningInfoSeminar || {}).find(function (v) { return !!v; }) != null;
                this.screeningMeta1.groups[1].expanded = Object.values(this.job.dynamicData.screeningInfo1 || {}).find(function (v) { return !!v; }) != null;
                this.screeningMeta1.groups[2].expanded = Object.values(this.job.dynamicData.screeningInfo2 || {}).find(function (v) { return !!v; }) != null;
            }
            if (this.screeningMeta2) {
                this.screeningMeta2.groups[0].expanded = Object.values(this.job.dynamicData.screeningInfo3 || {}).find(function (v) { return !!v; }) != null;
                this.screeningMeta2.groups[1].expanded = Object.values(this.job.dynamicData.screeningInfo4 || {}).find(function (v) { return !!v; }) != null;
                this.screeningMeta2.groups[2].expanded = Object.values(this.job.dynamicData.screeningInfo5 || {}).find(function (v) { return !!v; }) != null;
            }
            if (this.interviewMeta1) {
                this.interviewMeta1.groups[0].expanded =
                    this.job.dynamicData.interviewInfoSeminar && this.job.dynamicData.interviewInfoSeminar.description;
                this.interviewMeta1.groups[1].expanded = this.job.dynamicData.interviewInfo1 && this.job.dynamicData.interviewInfo1.description;
                this.interviewMeta1.groups[2].expanded = this.job.dynamicData.interviewInfo2 && this.job.dynamicData.interviewInfo2.description;
            }
            if (this.interviewMeta2) {
                this.interviewMeta2.groups[0].expanded = this.job.dynamicData.interviewInfo3 && this.job.dynamicData.interviewInfo3.description;
                this.interviewMeta2.groups[1].expanded = this.job.dynamicData.interviewInfo4 && this.job.dynamicData.interviewInfo4.description;
                this.interviewMeta2.groups[2].expanded = this.job.dynamicData.interviewInfo5 && this.job.dynamicData.interviewInfo5.description;
            }
        }
    };
    JobDetailTabComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
    };
    JobDetailTabComponent.prototype.onValidityChange = function (validity, index) {
        this.validityArray[index] = validity;
        this.validity = this.validityArray.find(function (v) { return !v; }) == null;
    };
    JobDetailTabComponent.prototype.getUserRows = function () {
        var _this = this;
        return this.dynamicService.getFormRows({ fieldName: 'raId', label: 'RA', fieldType: 'multi-user', validationStyle: { max: 1 } }, null, null, function (user) {
            if (!_this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId]) {
                return false;
            }
            return !!_this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId].find(function (depUser) { return depUser.userId === user.id && depUser.type === EnterpriseDepartmentUserType.RA; });
        }).concat(this.dynamicService.getFormRows({ fieldName: 'paId', label: 'PA', fieldType: 'multi-user', validationStyle: { max: 1 } }, null, null, function (user) {
            if (!_this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId]) {
                return false;
            }
            return !!_this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId].find(function (depUser) { return depUser.userId === user.id && depUser.type === EnterpriseDepartmentUserType.PA; });
        }), this.dynamicService.getFormRows({
            fieldName: 'asId',
            label: 'アシスタント',
            fieldType: 'multi-user',
            validationStyle: { max: 10 },
        }, null, null, function (user) {
            if (!_this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId]) {
                return false;
            }
            return !!_this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId].find(function (depUser) { return depUser.userId === user.id && depUser.type === EnterpriseDepartmentUserType.アシ; });
        }));
    };
    JobDetailTabComponent.prototype.onActionButton = function (event) {
        var _this = this;
        event.target.disabled = true;
        this.companyApiService.getDepartments(this.job.enterpriseId).subscribe(function (departments) {
            departments.enterpriseDepartments.forEach(function (department) {
                if (department.id === _this.job.enterpriseDepartmentId) {
                    _this.job.dynamicData.workplace = tslib_1.__assign({}, department.dynamicData.address);
                }
            });
            event.target.disabled = false;
        }, function () { return (event.target.disabled = false); });
    };
    JobDetailTabComponent.prototype.onFormChanged = function (key) {
        if (key === 'recommendationDateKind' && this.rightMeta) {
            this.rightMeta.groups[1].rows[1].showRequired = this.job && this.job.dynamicData.recommendationDateKind;
        }
        else if (key === 'hasTrial' && this.rightMeta) {
            this.rightMeta.groups[3].rows[2].showRequired = this.job && this.job.dynamicData.hasTrial === '有';
        }
    };
    JobDetailTabComponent.prototype.save = function () {
        var _this = this;
        var jobData = deepClone(this.job);
        delete jobData.enterpriseDepartmentUsers;
        delete jobData.dynamicData.raId;
        delete jobData.dynamicData.paId;
        delete jobData.dynamicData.asId;
        jobData.enterpriseDepartmentUserIds = this.job.dynamicData.raId.map(function (user) {
            return _this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId].find(function (depUser) { return depUser.userId === user && depUser.type === EnterpriseDepartmentUserType.RA; }).id;
        }).concat(this.job.dynamicData.paId.map(function (user) {
            return _this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId].find(function (depUser) { return depUser.userId === user && depUser.type === EnterpriseDepartmentUserType.PA; }).id;
        }), this.job.dynamicData.asId.map(function (user) {
            return _this.enterpriseDepartmentUsers[_this.job.enterpriseDepartmentId].find(function (depUser) { return depUser.userId === user && depUser.type === EnterpriseDepartmentUserType.アシ; }).id;
        }));
        this.jobApiService.update(this.job.id, jobData).subscribe(function (response) { return _this.jobApiService.refreshEvent.emit(); });
    };
    JobDetailTabComponent.prototype.clearInfo = function (data) {
        if (data == null) {
            return;
        }
        Object.keys(data || {}).forEach(function (key) { return (data[key] = null); });
    };
    return JobDetailTabComponent;
}());
export { JobDetailTabComponent };
