import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { OnDestroy, OnInit } from '@angular/core';
import { DialogConfig } from '../dialog/dialog-config';
var BulkProgressChangeDialogComponent = /** @class */ (function () {
    function BulkProgressChangeDialogComponent(dialog, config) {
        this.dialog = dialog;
        this.config = config;
        // tslint:disable-next-line:no-object-literal-type-assertion
        this.selected = {};
    }
    Object.defineProperty(BulkProgressChangeDialogComponent.prototype, "selectedStatusNeedsReason", {
        get: function () {
            return this.selected && this.selected.needsReason;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkProgressChangeDialogComponent.prototype, "selectedStatusHasRemarks", {
        get: function () {
            return this.selected && !this.selectedStatusNeedsReason;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkProgressChangeDialogComponent.prototype, "invalid", {
        get: function () {
            return !this.selected || !this.selected.toStatus || (this.selectedStatusNeedsReason && !this.selected.stopReason);
        },
        enumerable: true,
        configurable: true
    });
    BulkProgressChangeDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.config.data || !this.config.data.options || !this.config.data.options.length) {
            this.close();
            return;
        }
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return _this.submit(); });
    };
    BulkProgressChangeDialogComponent.prototype.ngOnDestroy = function () {
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    BulkProgressChangeDialogComponent.prototype.onTargetStatusChange = function (selected) {
        var remarksToKeep = this.selected.remarks;
        this.selected = selected;
        this.selected.stopReason = null;
        this.selected.remarks = remarksToKeep || null;
    };
    BulkProgressChangeDialogComponent.prototype.close = function (result) {
        this.dialog.close(result);
    };
    BulkProgressChangeDialogComponent.prototype.submit = function () {
        if (this.dialog.inClose) {
            return;
        }
        this.close(this.selected);
    };
    return BulkProgressChangeDialogComponent;
}());
export { BulkProgressChangeDialogComponent };
