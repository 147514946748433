import * as tslib_1 from "tslib";
import { ApiService } from '@agent-ds/shared/models/api-service';
import { CACHE } from '@agent-ds/shared/util/cache';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { merge, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StudentApiService = /** @class */ (function (_super) {
    tslib_1.__extends(StudentApiService, _super);
    function StudentApiService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    StudentApiService.prototype.getList = function (search) {
        return this.http.get('/api/students/search', { params: search });
    };
    StudentApiService.prototype.getDetail = function (id) {
        return this.http.get('/api/students/' + id).pipe(tap(function (student) {
            student.userId = student.studentUsers && student.studentUsers.length ? student.studentUsers[0].userId : null;
            // 副担当者のIDを抽出
            if (student.studentSubUsers && student.studentSubUsers.length) {
                student.subUserIds = student.studentSubUsers.map(function (studentSubUser) {
                    return studentSubUser.userId;
                });
            }
            else {
                // do nothing
            }
            return student;
        }));
    };
    StudentApiService.prototype.create = function (studentData) {
        var _a;
        return this.http.post('/api/students', studentData, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    StudentApiService.prototype.update = function (id, studentData) {
        var _a;
        return this.http.put('/api/students/' + id, studentData, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    StudentApiService.prototype.bulkUpdate = function (key, value, studentIds, condition) {
        return this.http.put('/api/students/bulk-update', {
            key: key,
            value: value,
            studentIds: studentIds,
            condition: condition,
        });
    };
    StudentApiService.prototype.getMemos = function (id) {
        return this.http.get("/api/students/" + id + "/memos");
    };
    StudentApiService.prototype.addMemo = function (id, memo) {
        return this.http.post("/api/students/" + id + "/memos", memo);
    };
    StudentApiService.prototype.updateMemo = function (id, memo) {
        return this.http.put("/api/students/" + id + "/memos/" + memo.id, memo);
    };
    StudentApiService.prototype.deleteMemo = function (id, memoId) {
        return this.http.delete("/api/students/" + id + "/memos/" + memoId);
    };
    StudentApiService.prototype.getContacts = function (id, actionId) {
        return this.http.get("/api/students/" + id + "/contact-histories", actionId ? { params: { action: "" + actionId } } : undefined);
    };
    StudentApiService.prototype.addContact = function (id, request) {
        return this.http.post("/api/students/" + id + "/contact-histories", request);
    };
    StudentApiService.prototype.updateContact = function (id, request) {
        return this.http.put("/api/students/" + id + "/contact-histories/" + request.id, request);
    };
    StudentApiService.prototype.deleteContact = function (id, contactId) {
        return this.http.delete("/api/students/" + id + "/contact-histories/" + contactId);
    };
    StudentApiService.prototype.getJobs = function (id) {
        return this.http.get("/api/students/" + id + "/job-offers");
    };
    StudentApiService.prototype.getJobFiles = function (id) {
        return this.http.get("/api/students/" + id + "/job-files");
    };
    StudentApiService.prototype.downloadJob = function (id, attachmentId) {
        this.http
            .get("/api/students/" + id + "/job-offer/" + attachmentId, { responseType: 'blob' })
            .subscribe(function (res) { return window.open(window.URL.createObjectURL(res)); });
    };
    StudentApiService.prototype.downloadJobFile = function (id, fileId) {
        this.http
            .get("/api/students/" + id + "/job-file/" + fileId, {
            responseType: 'blob',
            observe: 'response',
            headers: { 'Content-Type': 'application/json' },
        })
            .subscribe(function (res) { return downloadResponse(res); });
    };
    StudentApiService.prototype.addJob = function (id, file) {
        var formData = new FormData();
        formData.append('file', file, file.name);
        this.http.post("/api/students/" + id + "/job-offer", formData).subscribe();
    };
    StudentApiService.prototype.deleteJob = function (id, attachmentId) {
        this.http.delete("/api/students/" + id + "/job-offer/" + attachmentId);
    };
    StudentApiService.prototype.getProfileImage = function (id, size) {
        var url = "/api/students/" + id + "/profile-image/" + size;
        CACHE.set(url, null, 300000);
        return this.http.get(url, { responseType: 'blob' }).pipe(map(function (res) { return window.URL.createObjectURL(res); }), catchError(function (error) { return of('assets/images/profile.png'); }));
    };
    StudentApiService.prototype.getProfileImages = function (ids, size) {
        var observables = [];
        for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
            var id = ids_1[_i];
            observables.push(this.getProfileImage(id, size));
        }
        return merge(observables);
    };
    StudentApiService.prototype.addNote = function (studentId, note) {
        return this.http.post("/api/students/" + studentId + "/note", note);
    };
    StudentApiService.prototype.updateNote = function (studentId, note) {
        return this.http.put("/api/students/" + studentId + "/note/" + note.id, note);
    };
    StudentApiService.prototype.deleteNote = function (studentId, noteId) {
        return this.http.delete("/api/students/" + studentId + "/note/" + noteId);
    };
    StudentApiService.prototype.getSuggestions = function (keyword, ids) {
        return keyword || (ids && ids.length)
            ? this.http.get("/api/students/suggest", { params: { name: keyword || undefined, id: ids } })
            : of([]);
    };
    StudentApiService.prototype.getSuggestionsForIntakeCa = function (keyword, ids) {
        return keyword || (ids && ids.length)
            ? this.http.get("/api/students/suggestForIntakeCa", {
                params: { name: keyword || undefined, id: ids },
            })
            : of([]);
    };
    StudentApiService.prototype.downloadImportExampleFile = function () {
        this.http.get('/api/students-import/header', { responseType: 'blob', observe: 'response' }).subscribe(function (res) { return downloadResponse(res); });
    };
    StudentApiService.prototype.uploadStudentImportFile = function (file) {
        var formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post('/api/students-import/file', formData);
    };
    StudentApiService.prototype.deleteStudentImportFiles = function () {
        return this.http.delete('/api/students-import/file');
    };
    StudentApiService.prototype.getImportFiles = function () {
        return this.http.get('/api/students-import/files');
    };
    StudentApiService.prototype.getImportFile = function (fileId, registrationRoute) {
        if (registrationRoute === void 0) { registrationRoute = null; }
        return this.http.get("/api/students-import/file/" + fileId, {
            params: registrationRoute ? { registrationRoute: registrationRoute } : null,
        });
    };
    StudentApiService.prototype.getImportErrorDetail = function (studentImportErrorId) {
        return this.http.get("/api/students-import/error/" + studentImportErrorId);
    };
    StudentApiService.prototype.getImportErrorDiff = function (studentImportErrorId, studentId) {
        return this.http.get("/api/students-import/error/" + studentImportErrorId + "/diff/" + studentId);
    };
    StudentApiService.prototype.addImportError = function (studentImportErrorId, studentReimportRequest) {
        if (studentReimportRequest === void 0) { studentReimportRequest = null; }
        return this.http.post("/api/students-import/error/" + studentImportErrorId, studentReimportRequest);
    };
    StudentApiService.prototype.updateImportError = function (studentImportErrorId, studentId, studentUpdateRequest) {
        return this.http.put("/api/students-import/error/" + studentImportErrorId + "/update/" + studentId, studentUpdateRequest);
    };
    StudentApiService.prototype.deleteImportError = function (importErrorIds) {
        var request = { importErrorIds: importErrorIds };
        return this.http.request('delete', '/api/students-import/error', { body: request });
    };
    StudentApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentApiService_Factory() { return new StudentApiService(i0.ɵɵinject(i1.HttpClient)); }, token: StudentApiService, providedIn: "root" });
    return StudentApiService;
}(ApiService));
export { StudentApiService };
