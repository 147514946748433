import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { JobStudentMatchingPageComponent } from '@agent-ds/matching/pages';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { JobCompanyConfig } from '@agent-ds/shared/components/mail-send/configs/job-company-config';
import { JobPostingConfig } from '@agent-ds/shared/components/mail-send/configs/job-posting-config';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { AuthService, CompanyApiService, JobApiService } from '@agent-ds/shared/services';
import { download, getFileNameFromContentDispositionHeader, getValueFromObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
var JobDetailHeaderComponent = /** @class */ (function () {
    function JobDetailHeaderComponent(jobService, companyService, location, authService) {
        this.jobService = jobService;
        this.companyService = companyService;
        this.location = location;
        this.authService = authService;
        this.recommendationHeatClasses = {
            次月: 'badge--yellow-light',
            次月注力: 'badge--yellow-light',
            月内: 'badge--red-light-alt',
        };
        this.disablePdfButton = false;
        this.postingConfig = new JobPostingConfig();
        this.companyConfig = new JobCompanyConfig();
        this.isIntakeCa = false;
    }
    JobDetailHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginSubscription = this.authService.isLogined().subscribe(function (logined) {
            // 副担当者かどうかを確認
            _this.isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
        });
    };
    JobDetailHeaderComponent.prototype.ngOnDestroy = function () {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
    };
    JobDetailHeaderComponent.prototype.onCompanyLinkClick = function (id) {
        CompanyDetailPageComponent.instance.referenceId = id;
        CompanyDetailPageComponent.instance.open();
    };
    JobDetailHeaderComponent.prototype.copyJob = function () {
        var _this = this;
        this.jobService.copyJob(this.job.id).subscribe(function (result) {
            _this.jobService.refreshEvent.emit();
            JobDetailPageComponent.instance.referenceId = result.id;
        });
    };
    JobDetailHeaderComponent.prototype.openMatching = function () {
        JobStudentMatchingPageComponent.instance.referenceId = this.job.id;
        JobStudentMatchingPageComponent.instance.open();
    };
    JobDetailHeaderComponent.prototype.downloadPdf = function () {
        var _this = this;
        this.disablePdfButton = true;
        this.jobService.downloadPdf(this.job.id).subscribe(function (resp) {
            _this.disablePdfButton = false;
            download(resp.body, decodeURIComponent(getFileNameFromContentDispositionHeader(resp)));
        }, function () { return (_this.disablePdfButton = false); });
    };
    JobDetailHeaderComponent.prototype.openMail = function (config) {
        var _this = this;
        MailSendFlowComponent.instance.config = config;
        var params = {
            jobs: [
                {
                    id: this.job.id,
                    frontId: this.job.frontId,
                    position: getValueFromObject(this.job.dynamicData, 'position'),
                    enterpriseName: this.job.enterpriseName,
                    enterpriseId: this.job.enterpriseId,
                    frontEnterpriseId: this.job.frontEnterpriseId,
                    enterpriseDepartmentId: this.job.enterpriseDepartmentId,
                    jobUsers: this.job.enterpriseDepartmentUsers,
                },
            ],
            department: null,
            enterprise: null,
        };
        if (config instanceof JobCompanyConfig) {
            forkJoin([this.companyService.getDetail(this.job.enterpriseId), this.companyService.getDepartments(this.job.enterpriseId)]).subscribe(function (_a) {
                var company = _a[0], departments = _a[1];
                params.department = departments.enterpriseDepartments.find(function (d) { return d.id === _this.job.enterpriseDepartmentId; });
                params.enterprise = company;
                config.setParams(params);
                MailSendFlowComponent.instance.start();
            });
        }
        else {
            config.setParams(params);
            MailSendFlowComponent.instance.start();
        }
    };
    return JobDetailHeaderComponent;
}());
export { JobDetailHeaderComponent };
