import { PageFloaterComponent } from '@agent-ds/shared/components/page-floater/page-floater.component';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { interval } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { STUDENT_IMPORT_TABLE_CONFIG } from './student-import-page-table-config';
var StudentImportErrors;
(function (StudentImportErrors) {
    StudentImportErrors[StudentImportErrors["\u767B\u9332\u5185\u5BB9\u306B\u4E0D\u5099\u304C\u3042\u308A\u307E\u3059\u3002"] = 1] = "\u767B\u9332\u5185\u5BB9\u306B\u4E0D\u5099\u304C\u3042\u308A\u307E\u3059\u3002";
    StudentImportErrors[StudentImportErrors["\u30D5\u30A1\u30A4\u30EB\u5185\u3067\u91CD\u8907\u3057\u3066\u3044\u307E\u3059\u3002"] = 2] = "\u30D5\u30A1\u30A4\u30EB\u5185\u3067\u91CD\u8907\u3057\u3066\u3044\u307E\u3059\u3002";
    StudentImportErrors[StudentImportErrors["\u65E2\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002"] = 3] = "\u65E2\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002";
    StudentImportErrors[StudentImportErrors["E-Mail\u304C\u91CD\u8907\u3057\u3066\u3044\u307E\u3059\u3002"] = 4] = "E-Mail\u304C\u91CD\u8907\u3057\u3066\u3044\u307E\u3059\u3002";
})(StudentImportErrors || (StudentImportErrors = {}));
var FloaterContent;
(function (FloaterContent) {
    FloaterContent[FloaterContent["COMPARE"] = 0] = "COMPARE";
    FloaterContent[FloaterContent["DETAIL"] = 1] = "DETAIL";
})(FloaterContent || (FloaterContent = {}));
var ImportStatus;
(function (ImportStatus) {
    ImportStatus[ImportStatus["START"] = 1] = "START";
    ImportStatus[ImportStatus["IN_PROGRESS"] = 2] = "IN_PROGRESS";
    ImportStatus[ImportStatus["COMPLETE"] = 3] = "COMPLETE";
    ImportStatus[ImportStatus["FAILURE"] = 4] = "FAILURE";
    ImportStatus[ImportStatus["CANCEL"] = 5] = "CANCEL";
})(ImportStatus || (ImportStatus = {}));
var StudentImportPageComponent = /** @class */ (function () {
    function StudentImportPageComponent(studentApiService, dynamicFieldService, datePipe, chRef) {
        this.studentApiService = studentApiService;
        this.dynamicFieldService = dynamicFieldService;
        this.datePipe = datePipe;
        this.chRef = chRef;
        this.table = null;
        this.fileToUpload = null;
        this.isImportInProgress = false;
        this.DEFAULT_IMPORT_HISTORY_OPTION = { label: NULL_SELECTED_VALUE, value: null };
        this.DEFAULT_FIRST_REGISTRATION_ROUTE = { label: 'すべての媒体', value: null };
        this.importData = null;
        this.selectedFileId = this.DEFAULT_IMPORT_HISTORY_OPTION.value;
        this.selectedFirstRegistrationRoute = this.DEFAULT_FIRST_REGISTRATION_ROUTE.value;
        this.sideActions = [{ phases: ['1000px', '100%'] }];
        this.selectedStudentImportErrorId = null;
        this.selectedDuplicatedStudentId = null;
        this.selectedErrorType = null;
        this.StudentImportErrors = StudentImportErrors;
        this.FloaterContent = FloaterContent;
        this.fileImportStatusRetrySubscription = null;
        this.getImportFileSubscription = null;
        this.getImportFileRetrySubscriptions = [];
        this.isLastOperationImport = false;
    }
    Object.defineProperty(StudentImportPageComponent.prototype, "tableView", {
        set: function (v) {
            this.table = v;
            this.chRef.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    StudentImportPageComponent.prototype.ngOnInit = function () {
        this.getImportFileOptions();
        this.getFirstRegistrationRouteOptions();
    };
    StudentImportPageComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig = STUDENT_IMPORT_TABLE_CONFIG(this.errorTypeTemplate, this.actionsTemplate, function (date) {
            try {
                return _this.datePipe.transform(date, 'yyyy/MM/dd (E)');
            }
            catch (e) {
                return '';
            }
        });
        this.chRef.detectChanges();
    };
    StudentImportPageComponent.prototype.ngOnDestroy = function () {
        this.cancelImportFileRetry();
    };
    StudentImportPageComponent.prototype.onCloseWithRefresh = function () {
        this.floater.close();
        this.getImportData(this.selectedFileId, this.selectedFirstRegistrationRoute);
    };
    StudentImportPageComponent.prototype.onInputChanged = function (event) {
        this.fileToUpload = event && event.srcElement && event.srcElement.files ? event.srcElement.files[0] : this.fileToUpload;
    };
    StudentImportPageComponent.prototype.onImport = function () {
        var _this = this;
        if (!this.fileToUpload) {
            return;
        }
        this.isImportInProgress = true;
        this.isLastOperationImport = true;
        this.studentApiService.uploadStudentImportFile(this.fileToUpload).subscribe(function (resp) {
            _this.fileImportStatusRetrySubscription = interval(3000)
                .pipe(startWith(0))
                .subscribe(function () {
                _this.importData = null;
                _this.selectedFileId = null;
                _this.getImportFileRetrySubscriptions.push(_this.studentApiService.getImportFile(resp.id).subscribe(function (importData) {
                    if (importData.status === ImportStatus.COMPLETE || importData.status === ImportStatus.FAILURE) {
                        if (_this.isLastOperationImport) {
                            _this.importData = importData;
                            _this.table.allChecked = false;
                        }
                        _this.getImportFileOptions(function () {
                            _this.selectedFileId = resp.id;
                        });
                        _this.isImportInProgress = false;
                        _this.fileToUpload = undefined;
                        if (_this.fileinput) {
                            _this.fileinput.value = undefined;
                        }
                        _this.cancelImportFileRetry();
                    }
                }, function () {
                    _this.isImportInProgress = false;
                    if (_this.fileinput) {
                        _this.fileinput.value = undefined;
                    }
                    _this.cancelImportFileRetry();
                }));
            });
        }, function () {
            _this.isImportInProgress = false;
            if (_this.fileinput) {
                _this.fileinput.value = undefined;
            }
        });
    };
    StudentImportPageComponent.prototype.onClearImportHistory = function () {
        var _this = this;
        this.isLastOperationImport = false;
        PopupControlComponent.instance.open({
            width: '600px',
            height: '357px',
            title: '取り込み履歴データのクリア',
            content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
            confirmText: '確定',
            confirmCallback: function () {
                return _this.studentApiService.deleteStudentImportFiles().pipe(tap(function () {
                    _this.selectedFirstRegistrationRoute = _this.DEFAULT_FIRST_REGISTRATION_ROUTE.value;
                    _this.selectedFileId = _this.DEFAULT_IMPORT_HISTORY_OPTION.value;
                    _this.importFiles = [];
                    _this.importData = null;
                }));
            },
            cancelText: 'キャンセル',
        });
    };
    StudentImportPageComponent.prototype.onImportErrorSelect = function (importError, studentId) {
        this.isLastOperationImport = false;
        this.selectedStudentImportErrorId = importError.id;
        this.selectedDuplicatedStudentId = studentId;
        this.selectedErrorType = importError.type === 3 ? 3 : importError.type === 4 ? 4 : null;
        if (!this.isFloaterOpen) {
            this.floater.open();
        }
    };
    StudentImportPageComponent.prototype.onDelete = function (ids) {
        var _this = this;
        PopupControlComponent.instance.open({
            title: '削除',
            cancelText: 'キャンセル',
            content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
            confirmText: 'OK',
            confirmCallback: function () {
                if (_this.isFloaterOpen) {
                    _this.floater.close();
                }
                _this.isLastOperationImport = false;
                _this.studentApiService.deleteImportError(ids).subscribe(function () {
                    _this.getImportData(_this.selectedFileId, _this.selectedFirstRegistrationRoute);
                });
            },
        });
    };
    StudentImportPageComponent.prototype.onBatchDeleteErrors = function () {
        if (!this.table) {
            return;
        }
        this.onDelete(this.table.checkedItems.map(function (item) { return item.id; }));
    };
    StudentImportPageComponent.prototype.onAccept = function (studentImportErrorId) {
        var _this = this;
        this.isLastOperationImport = false;
        this.studentApiService.addImportError(studentImportErrorId, { force: 1 }).subscribe(function () {
            _this.getImportData(_this.selectedFileId, _this.selectedFirstRegistrationRoute);
        });
    };
    StudentImportPageComponent.prototype.downloadExampleCsv = function () {
        this.studentApiService.downloadImportExampleFile();
    };
    StudentImportPageComponent.prototype.getErrorDuplicatesCount = function (errors) {
        return errors && errors.length ? errors.filter(function (error) { return error.type === 3 || error.type === 4; }).length : 0;
    };
    StudentImportPageComponent.prototype.onFileSelect = function (fileId) {
        this.isLastOperationImport = false;
        this.selectedFirstRegistrationRoute = this.DEFAULT_FIRST_REGISTRATION_ROUTE.value;
        if (isNaN(fileId)) {
            this.importData = null;
            return;
        }
        this.table.allChecked = false;
        this.getImportData(fileId);
    };
    StudentImportPageComponent.prototype.onFirstRegistrationRouteSelect = function (fileId, registrationRoute) {
        this.isLastOperationImport = false;
        this.getImportData(fileId, registrationRoute);
    };
    StudentImportPageComponent.prototype.getImportData = function (fileId, registrationRoute) {
        var _this = this;
        if (!registrationRoute || registrationRoute === "" + this.DEFAULT_FIRST_REGISTRATION_ROUTE.value) {
            registrationRoute = null;
        }
        if (this.getImportFileSubscription) {
            this.getImportFileSubscription.unsubscribe();
        }
        this.getImportFileSubscription = this.studentApiService
            .getImportFile(fileId, registrationRoute)
            .subscribe(function (importData) {
            _this.importData = importData;
        });
    };
    StudentImportPageComponent.prototype.getImportFileOptions = function (callback) {
        var _this = this;
        this.studentApiService.getImportFiles().subscribe(function (importFiles) {
            _this.importFiles = importFiles;
            if (callback) {
                callback();
            }
        });
    };
    StudentImportPageComponent.prototype.getFirstRegistrationRouteOptions = function () {
        var _this = this;
        this.dynamicFieldService.fieldUpdateEvent.subscribe(function () {
            var fieldDefinition = _this.dynamicFieldService.getDefinition('student', 'registrationRoute');
            _this.registrationRouteOptions =
                fieldDefinition && fieldDefinition.validationStyle && fieldDefinition.validationStyle.options
                    ? fieldDefinition.validationStyle.options
                    : [];
        });
    };
    StudentImportPageComponent.prototype.cancelImportFileRetry = function () {
        if (this.fileImportStatusRetrySubscription) {
            this.fileImportStatusRetrySubscription.unsubscribe();
        }
        this.getImportFileRetrySubscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    return StudentImportPageComponent;
}());
export { StudentImportPageComponent };
