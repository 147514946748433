import * as tslib_1 from "tslib";
import { SeminarJobApplicationComponent } from '@agent-ds/counseling/components/seminar-job-application/seminar-job-application.component';
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AREAS } from '@agent-ds/shared/constants';
import { Tab } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { DatePipe } from '@angular/common';
import { OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SeminarReservationDialogComponent } from '../../../../components/seminar-reservation-dialog/seminar-reservation-dialog.component';
import { STUDENT_SEMINAR_LIST_TABLE_CONFIG } from './student-seminar-list-table-config';
var StudentSeminarsTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentSeminarsTabComponent, _super);
    function StudentSeminarsTabComponent(dialog, datePipe, counselingApiService, studentApiService, dialogService) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.datePipe = datePipe;
        _this.counselingApiService = counselingApiService;
        _this.studentApiService = studentApiService;
        _this.dialogService = dialogService;
        return _this;
    }
    StudentSeminarsTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tableConfig = STUDENT_SEMINAR_LIST_TABLE_CONFIG(this.buttonsTemplate, function (date) { return _this.datePipe.transform(date, 'yyyy/MM/dd (E)'); }, function (date) { return _this.datePipe.transform(date, 'HH:mm〜'); });
        this.refreshSubscription = this.counselingApiService.refreshEvent.subscribe(function () { return _this.getSeminars(); });
    };
    StudentSeminarsTabComponent.prototype.ngOnChanges = function (changes) {
        if (this.student != null && changes['student'].currentValue !== changes['student'].previousValue) {
            this.getSeminars();
        }
    };
    StudentSeminarsTabComponent.prototype.ngOnDestroy = function () {
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
            this.dialogSubscription = null;
        }
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = null;
        }
    };
    StudentSeminarsTabComponent.prototype.onReserveSeminar = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SeminarReservationDialogComponent, { data: { studentId: this.student.id } });
        this.dialogSubscription = dialogRef.afterClosed.subscribe(function (res) {
            if (res) {
                _this.counselingApiService.refreshEvent.next();
                PopupControlComponent.subInstance.open({
                    content: 'セミナー予約が完了しました。',
                    confirmText: '閉じる',
                    confirmCallback: function () { return void 0; },
                    title: 'セミナー予約',
                    height: '357px',
                    width: '600px',
                });
            }
        });
    };
    StudentSeminarsTabComponent.prototype.onDeleteSeminar = function (seminar) {
        var _this = this;
        if (seminar.isSeminarAtPastDate || seminar.isApplied) {
            return;
        }
        PopupControlComponent.instance.open({
            content: 'セミナー予約を削除します。よろしいですか？',
            confirmText: 'OK',
            confirmCallback: function () {
                return _this.counselingApiService.deleteSeminarForStudent(seminar.id, _this.student.id).pipe(tap(function () {
                    var index = _this.seminars.findIndex(function (sem) { return sem.id === seminar.id; });
                    _this.seminars.splice(index, 1);
                }));
            },
            title: 'セミナー予約削除',
            height: '357px',
            width: '600px',
            cancelText: 'キャンセル',
        });
    };
    StudentSeminarsTabComponent.prototype.onSeminarJobOffers = function (seminar) {
        SeminarJobApplicationComponent.instance.referenceId = this.student.id;
        SeminarJobApplicationComponent.instance.data = {
            seminar: seminar,
            studentName: this.student.dynamicData.lastName + this.student.dynamicData.firstName,
        };
        SeminarJobApplicationComponent.instance.open();
    };
    StudentSeminarsTabComponent.prototype.getSeminars = function () {
        var _this = this;
        if (this.student == null) {
            return;
        }
        this.seminars = [];
        this.counselingApiService.getSeminarsForStudent(this.student.id).subscribe(function (response) {
            _this.seminars = [];
            _this.seminars = response
                .map(function (res) { return (tslib_1.__assign({}, res, { areaName: AREAS[res.area] || '', isSeminarAtPastDate: new Date(res.seminarAt) < new Date() })); })
                .reverse();
        }, function (error) {
            console.error('getSeminarsForStudent error', error);
            if (!error.status) {
                // ネットワークに繋がらなかったときはエラーを通知する
                _this.showErrorDialog();
                _this.studentApiService.notifyConnectionErrorEvent.emit();
            }
            else {
                // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
            }
        });
    };
    StudentSeminarsTabComponent.prototype.showErrorDialog = function () {
        var dialogConfig = ConfirmDialogConfig.createStudentApiErrorDialogConfig([
            'セミナーの取得中に通信エラーが発生しました。',
            '画面上部右側の「求職者の全情報を再取得する」ボタンを押して再度取得してください。',
        ]);
        this.dialogService.open(ConfirmDialogComponent, dialogConfig);
    };
    return StudentSeminarsTabComponent;
}(Tab));
export { StudentSeminarsTabComponent };
