import * as tslib_1 from "tslib";
import { CompanyCreateDialogComponent } from '@agent-ds/company/components';
import { CompanyDetailPageComponent } from '@agent-ds/company/pages/company-detail-page/company-detail-page.component';
import { CompanyTabs } from '@agent-ds/company/pages/company-detail-page/tabs/company-tabs.enum';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { AuthService, CompanyApiService, DialogService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { cleanObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { COMPANY_LIST_TABLE_CONFIG } from '../../../shared/components/company-list/company-list-table-config';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
var CompanyListComponent = /** @class */ (function () {
    function CompanyListComponent(router, cdr, companyApiService, authService, dialog, location, userInjector) {
        var _this = this;
        this.router = router;
        this.cdr = cdr;
        this.companyApiService = companyApiService;
        this.authService = authService;
        this.dialog = dialog;
        this.location = location;
        this.userInjector = userInjector;
        this.requestObject = {};
        this.options = {};
        this.itemChecked = new EventEmitter();
        this.initialized = false;
        this.tabs = CompanyTabs;
        this.isIntakeCa = false;
        this.loadList = function (page, size, sort, order) {
            var params = cleanObject(tslib_1.__assign({}, _this.requestObject, { from: page * size, size: size, sort: sort, order: order }));
            return _this.companyApiService.getList(params).pipe(map(function (res) { return _this.convertCompanyList(res); }));
        };
    }
    Object.defineProperty(CompanyListComponent.prototype, "checkedItems", {
        get: function () {
            return this.table ? this.table.checkedItems : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyListComponent.prototype, "allChecked", {
        get: function () {
            return this.table.allChecked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyListComponent.prototype, "totalSize", {
        get: function () {
            return this.table.totalSize;
        },
        enumerable: true,
        configurable: true
    });
    CompanyListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginSubscription = this.authService.isLogined().subscribe(function (logined) {
            // 副担当者かどうかを確認
            _this.isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
        });
        this.refreshSubscription = this.companyApiService.refreshEvent.subscribe(function () { return _this.ngOnChanges(); });
        if (!this.options.sort) {
            this.options.sort = { field: 'updatedAt', order: 'desc' };
        }
        this.checkRoute();
    };
    CompanyListComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig = COMPANY_LIST_TABLE_CONFIG(this.companyNameTemplate, this.agStatusTemplate, this.drStatusTemplate, this.representativeTemplate, this.actionsTemplate, function (event, data) { return _this.onCompanyLinkClick(event, data, CompanyTabs[CompanyTabs.SALES]); });
        this.cdr.detectChanges();
        this.initialized = true;
    };
    CompanyListComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
            this.cdr.detectChanges();
            if (this.table && this.initialized) {
                this.table.reset(true);
                this.table.next();
            }
        }
    };
    CompanyListComponent.prototype.ngOnDestroy = function () {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    };
    CompanyListComponent.prototype.checkRoute = function () {
        if (this.router.url.endsWith('/enterprises/add')) {
            this.openCompanyCreateDialog();
        }
    };
    CompanyListComponent.prototype.openCompanyCreateDialog = function (origUrl) {
        var _this = this;
        if (origUrl === void 0) { origUrl = this.location.path(); }
        if (this.dialogRef) {
            return;
        }
        CompanyDetailPageComponent.instance.close();
        JobDetailPageComponent.instance.close();
        this.location.go('/enterprises/add');
        this.dialogRef = this.dialog.open(CompanyCreateDialogComponent);
        var closeSubscription;
        var navigationSubscription;
        var unsubscribe = function () {
            if (closeSubscription) {
                closeSubscription.unsubscribe();
            }
            if (navigationSubscription) {
                navigationSubscription.unsubscribe();
            }
        };
        closeSubscription = this.dialogRef.afterClosed.subscribe(function () {
            _this.location.go(origUrl);
            _this.dialogRef = null;
            unsubscribe();
        });
        navigationSubscription = this.location.subscribe(function () {
            _this.dialogRef.close();
            unsubscribe();
        });
    };
    CompanyListComponent.prototype.onItemSelected = function (selectedItem) {
        CompanyDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
        CompanyDetailPageComponent.instance.open();
    };
    CompanyListComponent.prototype.onItemChecked = function (checkedItemEvent) {
        this.itemChecked.emit(checkedItemEvent);
    };
    CompanyListComponent.prototype.onCompanyLinkClick = function (event, company, tab) {
        event.stopPropagation();
        if (tab === 'userList') {
            CompanyDetailPageComponent.instance.selectedSideActionIndex = 1;
        }
        else {
            CompanyDetailPageComponent.instance.tabCode = tab;
        }
        this.onItemSelected(company);
    };
    CompanyListComponent.prototype.convertCompanyList = function (res) {
        // default result
        var result = {
            result: [],
            totalSize: res && res.total ? res.total : 0,
        };
        // map all elements to make object root to _source property
        if (res && res.enterprises && res.enterprises.length > 0) {
            result.result = res.enterprises;
        }
        return result;
    };
    return CompanyListComponent;
}());
export { CompanyListComponent };
