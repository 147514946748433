<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">
      <span *ngIf="config.isError" class="dialog__icon--error">！</span>
      {{ config.title }}
    </h1>
    <span *ngIf="!config.hideClose" class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body" [ngStyle]="config.style">
    <div *ngFor="let message of config.messages" class="dialog__message">
      {{ message }}
    </div>
    <div
      class="dialog__navigation-image-wrapper"
      *ngIf="config.navigationImagePath"
    >
      <img [src]="config.navigationImagePath" />
    </div>
  </div>
  <div class="dialog__footer">
    <button
      *ngIf="config.buttons && !config.buttons.hideNo"
      class="dialog__button dialog__button--accent"
      (click)="close(false)"
    >
      {{ config.buttons && config.buttons.no ? config.buttons.no : "いいえ" }}
    </button>
    <button
      *ngIf="config.buttons && !config.buttons.hideYes"
      class="dialog__button dialog__button--primary"
      (click)="close(true)"
    >
      {{ config.buttons && config.buttons.yes ? config.buttons.yes : "はい" }}
    </button>
  </div>
</div>
