import * as tslib_1 from "tslib";
import { StudentJobMatchingPageComponent } from '@agent-ds/matching/pages/student-job-matching-page/student-job-matching-page.component';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DialogService, MasterApiService, StudentApiService, UserApiService } from '@agent-ds/shared/services';
import { cleanObject, getValueFromObject } from '@agent-ds/shared/util/util';
import { BatchUpdateDialogComponent } from '@agent-ds/student/components/batch-update-dialog/batch-update-dialog.component';
import { StudentCreateDialogComponent } from '@agent-ds/student/components/student-create-dialog/student-create-dialog.component';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { StudentTabs } from '@agent-ds/student/pages/student-detail-page/tabs/student-tabs';
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MailSendFlowComponent } from '../mail-send-flow/mail-send-flow.component';
import { ProgressBulkConfig } from '../mail-send/configs/progress-bulk-config';
import { StudentBulkConfig } from '../mail-send/configs/student-bulk-config';
import { StudentJobConfig } from '../mail-send/configs/student-job-config';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { STUDENT_LIST_TABLE_CONFIG } from './student-list-table-config';
var StudentListComponent = /** @class */ (function () {
    function StudentListComponent(studentApiService, cdr, dialog, datePipe, masterService, userService, location, router) {
        var _this = this;
        this.studentApiService = studentApiService;
        this.cdr = cdr;
        this.dialog = dialog;
        this.datePipe = datePipe;
        this.masterService = masterService;
        this.userService = userService;
        this.location = location;
        this.router = router;
        this.requestObject = {};
        this.options = {};
        this.itemChecked = new EventEmitter();
        this.tabs = StudentTabs;
        this.initialized = false;
        this.bulkSendConfig = new StudentBulkConfig();
        this.jobSendConfig = new StudentJobConfig();
        this.progressBulkSendConfig = new ProgressBulkConfig();
        this.loadList = function (page, size, sort, order) {
            var params = cleanObject(tslib_1.__assign({}, _this.requestObject, { from: page * size, size: size, sort: sort, order: order }));
            return _this.studentApiService.getList(params).pipe(map(function (res) { return _this.convertStudentList(res); }));
        };
    }
    Object.defineProperty(StudentListComponent.prototype, "checkedItems", {
        get: function () {
            return this.table ? this.table.checkedItems : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudentListComponent.prototype, "allChecked", {
        get: function () {
            return this.table.allChecked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudentListComponent.prototype, "totalSize", {
        get: function () {
            return this.table.totalSize;
        },
        enumerable: true,
        configurable: true
    });
    StudentListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refreshSubscription = this.studentApiService.refreshEvent.subscribe(function () { return _this.ngOnChanges(); });
        if (!this.options.sort) {
            this.options.sort = { field: 'updatedAt', order: 'desc' };
        }
        if (this.router.url.endsWith('/students/add')) {
            this.openStudentCreateDialog();
        }
    };
    StudentListComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
            this.cdr.detectChanges();
            if (this.table && this.initialized) {
                this.table.reset(true);
                this.table.next();
            }
        }
    };
    StudentListComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.table.tableConfig = STUDENT_LIST_TABLE_CONFIG(this.imgTemplate, this.basicTemplate, this.messageTemplate, this.schoolTemplate, this.actionsTemplate, this.inChargeTemplate, this.generalTemplate, this.registrationStatusTemplate, function (date) { return _this.datePipe.transform(date, 'yyyy/MM/dd(E)'); });
        this.cdr.detectChanges();
        this.initialized = true;
    };
    StudentListComponent.prototype.ngOnDestroy = function () {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    };
    StudentListComponent.prototype.onItemSelected = function (selectedItem) {
        StudentDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
        StudentDetailPageComponent.instance.open();
    };
    StudentListComponent.prototype.onItemChecked = function (checkedItemEvent) {
        this.itemChecked.emit(checkedItemEvent);
    };
    StudentListComponent.prototype.onStudentLinkClick = function (event, student, tab) {
        event.stopPropagation();
        if (tab === this.tabs[this.tabs.MATCHES]) {
            StudentJobMatchingPageComponent.instance.referenceId = student.id;
            StudentJobMatchingPageComponent.instance.open();
            // this.router.navigate(['/matching/student-job/', student.id]);
        }
        else {
            StudentDetailPageComponent.instance.tabCode = tab;
            this.onItemSelected(student);
        }
    };
    StudentListComponent.prototype.openStudentCreateDialog = function (origUrl) {
        var _this = this;
        if (origUrl === void 0) { origUrl = this.location.path(); }
        if (this.dialogRef) {
            return;
        }
        this.location.go('/students/add');
        StudentDetailPageComponent.instance.close();
        this.dialogRef = this.dialog.open(StudentCreateDialogComponent);
        var closeSubscription;
        var navigationSubscription;
        var unsubscribe = function () {
            if (closeSubscription) {
                closeSubscription.unsubscribe();
            }
            if (navigationSubscription) {
                navigationSubscription.unsubscribe();
            }
        };
        closeSubscription = this.dialogRef.afterClosed.subscribe(function () {
            _this.location.go(origUrl);
            _this.dialogRef = null;
            unsubscribe();
        });
        navigationSubscription = this.location.subscribe(function () {
            _this.dialogRef.close();
            unsubscribe();
        });
    };
    StudentListComponent.prototype.openBatchUpdateDialog = function () {
        this.dialog.open(BatchUpdateDialogComponent, {
            data: {
                // condition: this.table.allChecked
                //   ? cleanObject({
                //       ...this.requestObject,
                //       from: 0,
                //       size: this.table.totalSize,
                //     })
                //   : undefined,
                studentIds: /* this.table.allChecked ? undefined : */ this.table.checkedItems.map(function (item) { return item.id; }),
            },
        });
    };
    StudentListComponent.prototype.openMailSend = function (config) {
        var params = {
            students: /* !this.table.allChecked || config !== this.bulkSendConfig
                ? */ this.table.checkedItems.map(function (s) { return ({
                id: s.id,
                frontId: s.frontId,
                emailMain: getValueFromObject(s.dynamicData, 'emailMain.email'),
                emailMainAvailable: getValueFromObject(s.dynamicData, 'emailMain.emailAvailable'),
                emailSub: getValueFromObject(s.dynamicData, 'emailSub.email'),
                emailSubAvailable: getValueFromObject(s.dynamicData, 'emailSub.emailAvailable'),
                studentUser: s.studentUser,
                firstName: s.dynamicData.firstName,
                lastName: s.dynamicData.lastName,
            }); }),
            // : null,
            jobs: this.job
                ? [
                    {
                        id: this.job.id,
                        frontId: this.job.frontId,
                        position: getValueFromObject(this.job.dynamicData, 'position'),
                        enterpriseName: this.job.enterpriseName,
                        enterpriseId: this.job.enterpriseId,
                        frontEnterpriseId: this.job.frontEnterpriseId,
                        enterpriseDepartmentId: this.job.enterpriseDepartmentId,
                        jobUsers: this.job.enterpriseDepartmentUsers,
                    },
                ]
                : null,
            // condition: this.table.allChecked && config === this.bulkSendConfig ? this.requestObject : null,
            jobId: this.job ? this.job.id : null,
            enterprises: this.job ? [{ id: this.job.enterpriseId, name: this.job.enterpriseName, frontId: this.job.frontEnterpriseId }] : [],
        };
        MailSendFlowComponent.instance.config = config;
        config.setParams(params);
        MailSendFlowComponent.instance.start();
    };
    StudentListComponent.prototype.convertStudentList = function (res) {
        // default result
        var result = {
            result: [],
            totalSize: res && res.total ? res.total : 0,
        };
        // map all elements to make object root to _source property
        if (res && res.students && res.students.length > 0) {
            result.result = res.students;
        }
        return result;
    };
    return StudentListComponent;
}());
export { StudentListComponent };
