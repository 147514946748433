<img
  [width]="displaySize || size"
  [src]="src || 'assets/images/profile.png'"
  class="image"
  [class.image__loading]="loading"
/>
<div *ngIf="loading" class="loading">
  <svg class="loading__spinner" viewBox="0 0 50 50">
    <circle class="loading__spinner__path" cx="25" cy="25" r="20"></circle>
  </svg>
</div>
