import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { map, mergeMap, tap } from 'rxjs/operators';
import { StudentBulkConfig } from './student-bulk-config';
var ProgressNgEndConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressNgEndConfig, _super);
    function ProgressNgEndConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressNgEndConfig.prototype.getTemplateTypeId = function () {
        return [20];
    };
    ProgressNgEndConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.jobs = params.jobs || [];
            this.sender.model.progressIds = params.progressIds || [];
        }
        _super.prototype.setParams.call(this, params);
    };
    ProgressNgEndConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.sender.model.students) {
            return;
        }
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        metaData.groups[0].rows.push({
            title: 'To (求職者)',
            showRequired: true,
            fields: [
                {
                    name: 'to',
                    type: 'label',
                    supplier: function () {
                        return _this.sender
                            ? _this.sender.model.students
                                .map(function (s) {
                                return s.emailMainAvailable
                                    ? "\u3010\u9001\u4FE1\u4E0D\u53EF\u3011" + (s.lastName + s.firstName) + " \u30E1\u30A4\u30F3 <" + s.emailMain + ">"
                                    : s.lastName + s.firstName + " \u30E1\u30A4\u30F3 <" + s.emailMain + ">";
                            })
                                .join(', ')
                            : '';
                    },
                },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (求職者、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            row.fields.unshift({
                name: 'cc_label',
                type: 'label',
                default: '※E-Mailサブにも送信',
            }, {
                name: 'cc-hr',
                type: 'hr',
                class: 'full',
            });
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: 'テンプレート選択',
                fields: [
                    {
                        name: 'dummyTemplateType',
                        type: 'autocomplete',
                        linkTo: ['subject', 'body'],
                        placeholder: 'テンプレート名',
                        labelField: 'name',
                        class: 'half',
                        options: [],
                        supplier: function (value, callType, getValue) {
                            var typeIds = getValue('templateTypeIds');
                            return _this.sender.mailApiService.getMailTemplateNames().pipe(map(function (res) {
                                var filtered = res.filter(function (r) { return r.status === 1 && (!typeIds.length || typeIds.includes(r.mailTemplateTypeId)); });
                                return {
                                    options: filtered,
                                };
                            }));
                        },
                    },
                ],
            });
        }
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.subject : undefined,
                                };
                            }));
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return _this.sender.model.subject; },
                    },
            ],
        });
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.body : undefined,
                                };
                            }));
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }].concat(res);
                                    return {
                                        value: value ? res.find(function (s) { return s.id === value.id; }) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    ProgressNgEndConfig.prototype.save = function (toSave) {
        var _this = this;
        toSave.progressIds = this.sender.model.progressIds;
        this.sender.mailApiService
            .sendNgNotification(toSave)
            .pipe(mergeMap(function (res) { return _this.sender.progressApiService.ngEnd(_this.sender.model.progressIds).pipe(tap(function () { return _this.sender.afterSend(res); })); }))
            .subscribe();
    };
    return ProgressNgEndConfig;
}(StudentBulkConfig));
export { ProgressNgEndConfig };
