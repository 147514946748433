<div class="content">
  <ul class="header">
    <li class="header__title">
      {{ isCA ? "担当求職者" : "担当企業" }}
    </li>
    <li *ngIf="!isCA">
      総数<span class="header--big">{{
        enterpriseModel.total | number | undef: "0"
      }}</span
      >社<span class="header--gray"
        >(オーダー有{{
          enterpriseModel.totalWithJobs | number | undef: "0"
        }}社、無{{
          enterpriseModel.total - enterpriseModel.totalWithJobs
            | number
            | undef: "0"
        }}社)</span
      >
    </li>
  </ul>
  <ul class="kpi">
    <ng-container *ngIf="isCA">
      <li class="kpi__container kpi__container--gray">
        <div class="kpi__innercontainer kpi__innercontainer--no-border">
          <div class="kpi__element">
            <div class="kpi__element__title">
              進捗人数
            </div>
            <div
              class="kpi__element__value link"
              routerLink="/students/list"
              [queryParams]="getStudentLinkByUser(0)"
            >
              {{ studentModel.students | number | undef
              }}<span class="kpi__unit">人</span>
            </div>
          </div>
        </div>
      </li>
      <ng-container
        *ngIf="isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '進捗件数',
          value: studentModel.progresses,
          dark: 'true',
          linkParams: {
            status: [100, 110, 120, 130],
            studentTeamId: selectedTeamId
          },
          unit: '件'
        }"
      ></ng-container>
      <ng-container
        *ngIf="!isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '進捗件数',
          value: studentModel.progresses,
          dark: 'true',
          linkParams: {
            status: [100, 110, 120, 130],
            studentUserId: loginUserId
          },
          unit: '件'
        }"
      ></ng-container>
      <ng-container
        *ngIf="!isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '本日選考一覧',
          value: studentModel.todayInterviews,
          noBorder: true,
          linkParams: {
            status: [90, 120],
            studentUserId: loginUserId,
            seminarDate: today()
          },
          unit: '件'
        }"
      ></ng-container>
      <ng-container
        *ngIf="!isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '翌日選考一覧',
          value: studentModel.tomorrowInterviews,
          linkParams: {
            status: [90, 120],
            studentUserId: loginUserId,
            seminarDate: tomorrow()
          },
          unit: '件'
        }"
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="!isCA">
      <ng-container
        *ngIf="!isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '本日選考一覧',
          value: enterpriseModel.todayInterviews,
          noBorder: true,
          dark: true,
          linkParams: {
            status: [90, 120],
            enterpriseUserId: loginUserId,
            seminarDate: today()
          },
          unit: '件'
        }"
      ></ng-container>
      <ng-container
        *ngIf="!isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '翌日選考一覧',
          value: enterpriseModel.tomorrowInterviews,
          dark: true,
          linkParams: {
            status: [90, 120],
            enterpriseUserId: loginUserId,
            seminarDate: tomorrow()
          },
          unit: '件'
        }"
      ></ng-container>
      <ng-container
        *ngIf="isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '本日選考一覧',
          value: enterpriseModel.todayInterviews,
          noBorder: true,
          dark: true,
          linkParams: {
            status: [90, 120],
            enterpriseTeamId: selectedTeamId,
            seminarDate: today()
          },
          unit: '件'
        }"
      ></ng-container>
      <ng-container
        *ngIf="isManager"
        [ngTemplateOutlet]="kpi_item"
        [ngTemplateOutletContext]="{
          label: '翌日選考一覧',
          value: enterpriseModel.tomorrowInterviews,
          dark: true,
          linkParams: {
            status: [90, 120],
            enterpriseTeamId: selectedTeamId,
            seminarDate: tomorrow()
          },
          unit: '件'
        }"
      ></ng-container>
      <li
        class="kpi__container kpi__container--gray"
        *ngIf="isAssistant || (!isCA && isManager)"
      ></li>
      <li
        class="kpi__container kpi__container--gray"
        *ngIf="isAssistant || (!isCA && isManager)"
      ></li>
    </ng-container>
  </ul>
  <div class="tables">
    <div *ngIf="!isCA" class="tables--wide table-enterprises">
      <div class="table-enterprises__head">
        <div
          class="table-enterprises__head__title table-enterprises__head__title--indent table-enterprises__head__title--multi-col"
        >
          AG総数
        </div>
        <div
          class="table-enterprises__head__title table-enterprises__head__title--multi-row"
        >
          Plus総数
        </div>
        <div
          class="table-enterprises__head__title table-enterprises__head__title--big"
        >
          案件内訳
        </div>
        <div class="table-enterprises__head__title">
          総数
        </div>
        <div class="table-enterprises__head__title">
          Aランク
        </div>
        <div class="table-enterprises__head__title">
          Bランク
        </div>
        <div class="table-enterprises__head__title">
          Cランク
        </div>
      </div>
      <div class="table-enterprises__row table-enterprises__row--group-start">
        <ng-container
          [ngTemplateOutlet]="row_5_values"
          [ngTemplateOutletContext]="{
            label: '案件数',
            values: enterpriseModel.jobsUnderRecruitment
          }"
        >
        </ng-container>
      </div>
      <div class="table-enterprises__row">
        <ng-container
          [ngTemplateOutlet]="row_5_values"
          [ngTemplateOutletContext]="{
            label: '進捗案件数',
            values: enterpriseModel.jobsInProgress,
            indent: true,
            status: '進捗'
          }"
        >
        </ng-container>
      </div>
      <div class="table-enterprises__row">
        <ng-container
          [ngTemplateOutlet]="row_5_values"
          [ngTemplateOutletContext]="{
            label: '稼動案件数',
            values: enterpriseModel.jobsInOperation,
            indent: true,
            status: '稼働'
          }"
        >
        </ng-container>
      </div>
      <div class="table-enterprises__row">
        <ng-container
          [ngTemplateOutlet]="row_5_values"
          [ngTemplateOutletContext]="{
            label: '未稼動案件数',
            values: enterpriseModel.jobsBeforeOperation,
            indent: true,
            status: '未稼働'
          }"
        >
        </ng-container>
      </div>
      <div class="table-enterprises__row">
        <ng-container
          [ngTemplateOutlet]="row_5_values_withProgress"
          [ngTemplateOutletContext]="{
            label: '進捗件数',
            values: enterpriseModel.progresses
          }"
        >
        </ng-container>
      </div>
      <div class="table-enterprises__row">
        <div class="table-enterprises__row__label">進捗人数</div>
        <div
          class="table-enterprises__row__value link"
          routerLink="/students/list"
          [queryParams]="getStudentLinkByUser(0)"
        >
          {{ enterpriseModel.students?.agTotal | number | undef }}
        </div>
        <div
          class="table-enterprises__row__unit"
          style="grid-column-start: AG_unit; grid-column-end: Plus_value;"
        >
          人
        </div>
        <div
          class="table-enterprises__row__value table-enterprises__row__value--plus link"
          routerLink="/students/list"
          [queryParams]="getStudentLinkByUser(1)"
        >
          {{ enterpriseModel.students?.plusTotal | number | undef }}
        </div>
        <div class="table-enterprises__row__unit">人</div>
      </div>
    </div>
    <div *ngIf="isCA" class="tables--wide table-students">
      <div class="table-students__head">
        <div
          class="table-students__head__title table-students__head__title--indent"
        >
          総数
        </div>
        <div class="table-students__head__title">
          Aランク
        </div>
        <div class="table-students__head__title">
          Bランク
        </div>
        <div class="table-students__head__title">
          その他
        </div>
      </div>
      <div class="table-students__row table-students__row--group-start">
        <ng-container
          [ngTemplateOutlet]="row_4_values"
          [ngTemplateOutletContext]="{
            label: '紹介可能人数',
            values: studentModel.studentsIntroductable
          }"
        >
        </ng-container>
      </div>
      <div class="table-students__row">
        <ng-container
          [ngTemplateOutlet]="row_4_values"
          [ngTemplateOutletContext]="{
            label: '稼動前',
            values: studentModel.studentsBeforeOperation,
            hasActive: 'preactive'
          }"
        >
        </ng-container>
      </div>
      <div class="table-students__row">
        <ng-container
          [ngTemplateOutlet]="row_4_values"
          [ngTemplateOutletContext]="{
            label: '稼働中',
            values: studentModel.studentsInOperation,
            hasActive: 'active'
          }"
        >
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="dashboardType === 0 || (!isCA && isManager && collapsedNextYear)"
      class="tables__job_status table-single"
    >
      <div class="table-single__head">
        求人ステータス
      </div>

      <div class="table-single__row table-single__row--group-start">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '作成中',
            value: enterpriseModel.jobStatus?.making,
            link: '/jobs/list',
            linkParams: getParaJobLinkParams('作成中')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '企業確認待ち',
            value: enterpriseModel.jobStatus?.waitingConfirmation,
            link: '/jobs/list',
            linkParams: getParaJobLinkParams('企業確認待ち')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '企業確認済み',
            value: enterpriseModel.jobStatus?.confirmed,
            link: '/jobs/list',
            linkParams: getParaJobLinkParams('企業確認済み')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '募集中',
            value: enterpriseModel.jobStatus?.recruitment,
            link: '/jobs/list',
            linkParams: getParaJobLinkParams('募集中')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '推薦停止',
            value: enterpriseModel.jobStatus?.stop,
            link: '/jobs/list',
            linkParams: getParaJobLinkParams('推薦停止')
          }"
        >
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="!(isCA || isAssistant)"
      class="tables__corporate_status tables--indent table-single"
    >
      <div class="table-single__head">
        法人ステータス
      </div>
      <div class="table-single__row table-single__row--group-start">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '作成中',
            value: enterpriseModel.enterpriseStatus?.making,
            link: '/enterprises/list',
            linkParams: getParaEnterpriseLinkParams('作成中')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '企業確認待ち',
            value: enterpriseModel.enterpriseStatus?.waitingConfirmation,
            link: '/enterprises/list',
            linkParams: getParaEnterpriseLinkParams('企業確認待ち')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '企業確認済み',
            value: enterpriseModel.enterpriseStatus?.confirmation,
            link: '/enterprises/list',
            linkParams: getParaEnterpriseLinkParams('企業確認済み')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '復活アプローチ中',
            value: enterpriseModel.enterpriseStatus?.approachesToRevival,
            link: '/enterprises/list',
            linkParams: getParaEnterpriseLinkParams('復活アプローチ中')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '取引休止(今年度採用無)',
            value: enterpriseModel.enterpriseStatus?.noAdoption,
            link: '/enterprises/list',
            linkParams: getParaEnterpriseLinkParams('取引休止(今年度採用無)')
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '取引停止・禁止',
            value: enterpriseModel.enterpriseStatus?.suspension,
            link: '/enterprises/list',
            linkParams: getParaEnterpriseLinkParams('取引停止・禁止')
          }"
        >
        </ng-container>
      </div>
    </div>

    <div *ngIf="isCA" class="tables--wide table-single">
      <div class="table-single__head">
        その他
      </div>

      <div class="table-single__row table-single__row--group-start">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '新着求人数（全社）',
            value: studentModel.newJobs,
            link: '/jobs/list',
            linkParams: { isNew: 1 }
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row" *ngIf="!isManager">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '次回アクション対象者',
            value: studentModel.nextActionStudents,
            link: '/students/list',
            linkParams: { nextContactUserId: loginUserId }
          }"
        >
        </ng-container>
      </div>
    </div>

    <div *ngIf="isAssistant" class="tables--wide table-single">
      <div class="table-single__head">
        求人進捗
      </div>

      <div class="table-single__row table-single__row--group-start">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '各設定中フェーズ',
            remark: '(セミナー＋1次面接+2次以降面接)',
            value: enterpriseModel.jobProgressStatus.setting,
            link: '/progresses/list',
            linkParams: { status: [70, 100], enterpriseUserId: loginUserId }
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '各確認中フェーズ',
            remark: '(セミナー＋1次面接+2次以降面接+最終前面接+最終面接)',
            value: enterpriseModel.jobProgressStatus.confirming,
            link: '/progresses/list',
            linkParams: { status: [80, 110], enterpriseUserId: loginUserId }
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '各結果待ちフェーズ',
            remark: '(セミナー＋1次面接+2次以降面接+最終前面接+最終面接)',
            value: enterpriseModel.jobProgressStatus.completion,
            link: '/progresses/list',
            linkParams: { status: [90, 120], enterpriseUserId: loginUserId }
          }"
        >
        </ng-container>
      </div>
    </div>
    <div *ngIf="isAssistant" class="table-single">
      <div class="table-single__head">
        求人ステータス
      </div>

      <div class="table-single__row table-single__row--group-start">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '募集中（前日分）',
            value: enterpriseModel.jobYesterdayStatus.recruitment,
            link: '/jobs/list',
            linkParams: {
              status: '募集中',
              jobUserUserId: loginUserId,
              updatedAtFrom: yesterday(),
              updatedAtTo: yesterday()
            }
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '推薦停止（前日分）',
            value: enterpriseModel.jobYesterdayStatus.stop,
            link: '/jobs/list',
            linkParams: {
              status: '推薦停止',
              jobUserUserId: loginUserId,
              updatedAtFrom: yesterday(),
              updatedAtTo: yesterday()
            }
          }"
        >
        </ng-container>
      </div>
      <div class="table-single__row">
        <ng-container
          [ngTemplateOutlet]="row_single_value"
          [ngTemplateOutletContext]="{
            label: '終了（前日分）',
            value: enterpriseModel.jobYesterdayStatus.end,
            link: '/jobs/list',
            linkParams: {
              status: '終了',
              jobUserUserId: loginUserId,
              updatedAtFrom: yesterday(),
              updatedAtTo: yesterday()
            }
          }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template
  #row_single_value
  let-label="label"
  let-value="value"
  let-remark="remark"
  let-link="link"
  let-linkParams="linkParams"
>
  <div class="table-single__row__label">
    {{ label
    }}<span class="label-remark" *ngIf="remark"><br />{{ remark }}</span>
  </div>
  <div
    class="table-single__row__value link"
    [routerLink]="link"
    [queryParams]="linkParams"
  >
    {{ value | number | undef }}
  </div>
  <div class="table-single__row__unit">件</div>
</ng-template>

<ng-template
  #row_5_values
  let-label="label"
  let-values="values"
  let-indent="indent"
  let-status="status"
>
  <div
    class="table-enterprises__row__label"
    [class.table-enterprises__row__label--indent]="indent"
  >
    {{ label }}
  </div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/jobs/list"
    [queryParams]="getParaJobLinkParamsWithRank(0, status)"
  >
    {{ values?.agTotal | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/jobs/list"
    [queryParams]="getParaJobLinkParamsWithRank(0, status, 'A')"
  >
    {{ values?.agRankA | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/jobs/list"
    [queryParams]="getParaJobLinkParamsWithRank(0, status, 'B')"
  >
    {{ values?.agRankB | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/jobs/list"
    [queryParams]="getParaJobLinkParamsWithRank(0, status, 'C')"
  >
    {{ values?.agRankC | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value table-enterprises__row__value--plus link"
    routerLink="/jobs/list"
    [queryParams]="getParaJobLinkParamsWithRank(1, status)"
  >
    {{ values?.plusTotal | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
</ng-template>

<ng-template #row_5_values_withProgress let-label="label" let-values="values">
  <div class="table-enterprises__row__label">
    {{ label }}
  </div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/progresses/list"
    [queryParams]="getProgressLinkParamsWithRank(0)"
  >
    {{ values?.agTotal | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/progresses/list"
    [queryParams]="getProgressLinkParamsWithRank(0, 'A')"
  >
    {{ values?.agRankA | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/progresses/list"
    [queryParams]="getProgressLinkParamsWithRank(0, 'B')"
  >
    {{ values?.agRankB | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value link"
    routerLink="/progresses/list"
    [queryParams]="getProgressLinkParamsWithRank(0, 'C')"
  >
    {{ values?.agRankC | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
  <div
    class="table-enterprises__row__value table-enterprises__row__value--plus link"
    routerLink="/progresses/list"
    [queryParams]="getProgressLinkParamsWithRank(1)"
  >
    {{ values?.plusTotal | number | undef }}
  </div>
  <div class="table-enterprises__row__unit">件</div>
</ng-template>

<ng-template
  #row_4_values
  let-label="label"
  let-values="values"
  let-indent="indent"
  let-hasActive="hasActive"
>
  <div
    class="table-students__row__label"
    [class.table-students__row__label--indent]="indent"
  >
    {{ label }}
  </div>
  <div
    class="table-students__row__value link"
    routerLink="/students/list"
    [queryParams]="getStudentLink(hasActive)"
  >
    {{ values?.total | number | undef }}
  </div>
  <div class="table-students__row__unit">件</div>
  <div
    class="table-students__row__value link"
    routerLink="/students/list"
    [queryParams]="getStudentLink(hasActive, 'A')"
  >
    {{ values?.rankA | number | undef }}
  </div>
  <div class="table-students__row__unit">件</div>
  <div
    class="table-students__row__value link"
    routerLink="/students/list"
    [queryParams]="getStudentLink(hasActive, 'B')"
  >
    {{ values?.rankB | number | undef }}
  </div>
  <div class="table-students__row__unit">件</div>
  <div
    class="table-students__row__value link"
    routerLink="/students/list"
    [queryParams]="getStudentLink(hasActive, 'OTHER')"
  >
    {{ values?.others | number | undef }}
  </div>
  <div class="table-students__row__unit">件</div>
</ng-template>

<ng-template
  #kpi_item
  let-label="label"
  let-value="value"
  let-noBorder="noBorder"
  let-dark="dark"
  let-linkParams="linkParams"
  let-unit="unit"
>
  <li class="kpi__container" [class.kpi__container--gray]="dark">
    <div
      class="kpi__innercontainer"
      [class.kpi__innercontainer--no-border]="noBorder"
    >
      <div class="kpi__element">
        <div class="kpi__element__title">
          {{ label }}
        </div>
        <div
          class="kpi__element__value link"
          routerLink="/progresses/list"
          [queryParams]="linkParams"
        >
          {{ value | number | undef
          }}<span class="kpi__unit">
            {{ unit }}
          </span>
        </div>
      </div>
    </div>
  </li>
</ng-template>
