import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { CompanyApiService, DialogService } from '@agent-ds/shared/services';
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
var CompanySeminarAttendanceSendDialogComponent = /** @class */ (function () {
    function CompanySeminarAttendanceSendDialogComponent(dialog, config, companyService, dialogService) {
        this.dialog = dialog;
        this.config = config;
        this.companyService = companyService;
        this.dialogService = dialogService;
        this.dayOfWeekLabels = ['月', '火', '水', '木', '金'];
        this.deliveryTimingLabels = ['前日', '2営業日前', '3営業日前', '4営業日前', '5営業日前'];
        this.minDate = moment()
            .add(1, 'days')
            .startOf('days');
        this.defaultTime = '09:00';
        this.dateSettingCount = 10;
        this.exportTemplateId = null;
        this.exportTemplates = [];
        this.formData = {
            dateSettings: [],
            dayOfWeeks: [],
            dayOfWeekTime: '',
            deliveryTimings: [],
        };
        for (var i = 0; i < this.dateSettingCount; i++) {
            this.formData.dateSettings.push({ senderDate: '', senderTime: '', seminarDate: '' });
        }
        for (var _i = 0, _a = this.dayOfWeekLabels; _i < _a.length; _i++) {
            var label = _a[_i];
            this.formData.dayOfWeeks.push({ label: label, checked: false });
        }
        for (var _b = 0, _c = this.deliveryTimingLabels; _b < _c.length; _b++) {
            var label = _c[_b];
            this.formData.deliveryTimings.push({ label: label, checked: false, time: '' });
        }
    }
    Object.defineProperty(CompanySeminarAttendanceSendDialogComponent.prototype, "isValid", {
        get: function () {
            var _this = this;
            if (this.formData.dateSettings.some(function (d) { return ((d.senderDate && !d.seminarDate) || (!d.senderDate && d.seminarDate) ? true : false); })) {
                return false;
            }
            var dateSettings = this.formData.dateSettings.filter(function (d) { return d.senderDate && d.seminarDate; });
            if (dateSettings.length > 0) {
                var compare = dateSettings.every(function (d) {
                    var senderDate = moment(d.senderDate).startOf('days');
                    var seminarDate = moment(d.seminarDate).startOf('days');
                    return (_this.minDate.isSameOrBefore(senderDate) && _this.minDate.isSameOrBefore(seminarDate) && senderDate.isSameOrBefore(seminarDate));
                });
                if (!compare) {
                    return false;
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    CompanySeminarAttendanceSendDialogComponent.prototype.ngOnInit = function () {
        var _a;
        this.company = this.config.data.company;
        this.department = this.config.data.department;
        if (Array.isArray(this.config.data.progressExportTemplates)) {
            (_a = this.exportTemplates).push.apply(_a, this.config.data.progressExportTemplates);
        }
        this.initForm();
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.create = function () {
        var _this = this;
        if (this.dialog.inClose) {
            return;
        }
        if (!this.checkResumePassword()) {
            this.openConfirmDialog();
            return;
        }
        var users = Array.isArray(this.department.enterpriseDepartmentUsers)
            ? this.department.enterpriseDepartmentUsers.map(function (d) { return ({ type: d.type, id: d.userId }); })
            : [];
        var requestData = {
            dynamicData: this.createDynamicData(),
            users: users,
        };
        this.companyService.updateDepartment(this.company.id, this.department.id, requestData).subscribe(function (res) {
            _this.close(res);
        });
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.close = function (params) {
        this.dialog.close(params);
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.initForm = function () {
        if (_.isNumber(this.department.dynamicData.listExportTemplateId)) {
            this.exportTemplateId = this.department.dynamicData.listExportTemplateId;
        }
        for (var i = 0; i < this.formData.dateSettings.length; i++) {
            this.setDateSettingValue(this.formData.dateSettings[i], this.department.dynamicData["listSenderDate" + (i + 1)], this.department.dynamicData["listSeminarDate" + (i + 1)]);
        }
        if (Array.isArray(this.department.dynamicData.listDayOfWeek)) {
            var dayOfWeeks = this.department.dynamicData.listDayOfWeek;
            for (var _i = 0, _a = this.formData.dayOfWeeks; _i < _a.length; _i++) {
                var d = _a[_i];
                d.checked = dayOfWeeks.includes(d.label);
            }
        }
        this.formData.dayOfWeekTime = this.getTimeString(this.department.dynamicData.listDayOfWeekTime);
        for (var i = 0; i < this.formData.deliveryTimings.length; i++) {
            var deliveryTimingTime = this.department.dynamicData["listDeliveryTimingTime" + (i + 1)];
            this.formData.deliveryTimings[i].checked = this.department.dynamicData["listDeliveryTiming" + (i + 1)] === '有';
            this.formData.deliveryTimings[i].time = deliveryTimingTime ? this.getTimeString(deliveryTimingTime) : '';
        }
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.createDynamicData = function () {
        var dynamicData = {};
        dynamicData.listExportTemplateId = this.exportTemplateId;
        for (var i = 0; i < this.formData.dateSettings.length; i++) {
            dynamicData["listSenderDate" + (i + 1)] = this.getSenderDate(this.formData.dateSettings[i]);
            dynamicData["listSeminarDate" + (i + 1)] = this.getSeminarDate(this.formData.dateSettings[i]);
        }
        dynamicData.listDayOfWeek = this.formData.dayOfWeeks.filter(function (value) { return value.checked; }).map(function (value) { return value.label; });
        dynamicData.listDayOfWeekTime = this.convertTimeToISOString(this.formData.dayOfWeekTime);
        for (var i = 0; i < this.formData.deliveryTimings.length; i++) {
            dynamicData["listDeliveryTiming" + (i + 1)] = this.formData.deliveryTimings[i].checked ? '有' : null;
            dynamicData["listDeliveryTimingTime" + (i + 1)] = this.convertTimeToISOString(this.formData.deliveryTimings[i].time);
        }
        dynamicData.listSettingDate = moment()
            .startOf('days')
            .toISOString();
        return dynamicData;
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.setDateSettingValue = function (dateSetting, senderDate, seminarDate) {
        if (senderDate) {
            var date = moment(senderDate);
            dateSetting.senderDate = date.format('YYYY-MM-DD');
            dateSetting.senderTime = date.format('HH:mm');
        }
        if (seminarDate) {
            var date = moment(seminarDate);
            dateSetting.seminarDate = date.format('YYYY-MM-DD');
        }
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.getSenderDate = function (dateSetting) {
        if (!dateSetting.senderDate) {
            return null;
        }
        var time = dateSetting.senderTime ? dateSetting.senderTime : this.defaultTime;
        var dateTime = moment(dateSetting.senderDate + " " + time, 'YYYY-MM-DD HH:mm');
        return dateTime.toISOString();
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.getSeminarDate = function (dateSetting) {
        return dateSetting.seminarDate ? moment(dateSetting.seminarDate).toISOString() : null;
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.getTimeString = function (value) {
        return value ? moment(value).format('HH:mm') : null;
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.convertTimeToISOString = function (value) {
        return value ? moment(value, 'HH:mm').toISOString() : null;
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.checkResumePassword = function () {
        var dynamicData = this.company.dynamicData;
        return dynamicData && dynamicData.resumePassword;
    };
    CompanySeminarAttendanceSendDialogComponent.prototype.openConfirmDialog = function () {
        var config = {
            title: '必要な情報が入力されていません',
            messages: ['パスワードが登録されていません'],
            style: {
                height: '110px',
                width: '510px',
            },
            buttons: {
                hideNo: true,
                no: '',
                yes: '閉じる',
            },
        };
        this.dialogService.open(ConfirmDialogComponent, config);
    };
    return CompanySeminarAttendanceSendDialogComponent;
}());
export { CompanySeminarAttendanceSendDialogComponent };
