<div class="header">
  <span
    *ngFor="let tab of tabList; let i = index"
    class="header__label"
    [ngClass]="{ 'header__label--active': selectedIndex === i }"
    (click)="selectedIndex = i"
  >
    {{ tab.label }}
  </span>
</div>
<div class="content">
  <ng-container [cdkPortalOutlet]="portals[selectedIndex]"></ng-container>
</div>
