/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-contact-history-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/contact-history/contact-history.component.ngfactory";
import * as i3 from "../../../../../shared/components/contact-history/contact-history.component";
import * as i4 from "../../../../../shared/pipes/safe-date.pipe";
import * as i5 from "../../../../../shared/services/api/dynamic-field.service";
import * as i6 from "../../../../../shared/services/api/user-api.service";
import * as i7 from "../../../../../shared/services/api/master-api.service";
import * as i8 from "../../../../../shared/services/auth.service";
import * as i9 from "../../../../../shared/models/tab";
import * as i10 from "./company-contact-history-tab.component";
import * as i11 from "../../../../../shared/services/api/company-api.service";
var styles_CompanyContactHistoryTabComponent = [i0.styles];
var RenderType_CompanyContactHistoryTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyContactHistoryTabComponent, data: {} });
export { RenderType_CompanyContactHistoryTabComponent as RenderType_CompanyContactHistoryTabComponent };
export function View_CompanyContactHistoryTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-contact-history", [], null, null, null, i2.View_ContactHistoryComponent_0, i2.RenderType_ContactHistoryComponent)), i1.ɵdid(1, 4898816, null, 0, i3.ContactHistoryComponent, [i4.SafeDatePipe, i5.DynamicFieldService, i6.UserApiService, i7.MasterApiService, i8.AuthService], { service: [0, "service"], referenceId: [1, "referenceId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyApiService; var currVal_1 = _co.companyId; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CompanyContactHistoryTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-company-contact-history-tab", [], null, null, null, View_CompanyContactHistoryTabComponent_0, RenderType_CompanyContactHistoryTabComponent)), i1.ɵprd(6144, null, i9.Tab, null, [i10.CompanyContactHistoryTabComponent]), i1.ɵdid(2, 49152, null, 0, i10.CompanyContactHistoryTabComponent, [i11.CompanyApiService], null, null)], null, null); }
var CompanyContactHistoryTabComponentNgFactory = i1.ɵccf("ag-company-contact-history-tab", i10.CompanyContactHistoryTabComponent, View_CompanyContactHistoryTabComponent_Host_0, { companyId: "companyId" }, {}, []);
export { CompanyContactHistoryTabComponentNgFactory as CompanyContactHistoryTabComponentNgFactory };
