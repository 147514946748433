import * as tslib_1 from "tslib";
import { HelperBase } from './helper-base';
var ClosingInformationHelper = /** @class */ (function (_super) {
    tslib_1.__extends(ClosingInformationHelper, _super);
    function ClosingInformationHelper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClosingInformationHelper.prototype.updateFieldDefinitions = function () {
        var _this = this;
        this.editable = {
            // Closing information
            title: '成約情報',
            class: 'form__group--golden-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'salesClassification'), 'sales.dynamicData', 'half').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'hireType'), 'sales.dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'contractDate'), 'sales.dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'enterDate'), 'sales.dynamicData')),
        };
        this.readonly = {
            // Closing information
            title: '成約情報',
            class: 'form__group--golden-title-border',
            rows: [
                {
                    title: '契約区分',
                    fields: [
                        {
                            type: 'label',
                            name: 'sales.dynamicData.salesClassification',
                            class: 'auto',
                        },
                    ],
                },
                {
                    title: '雇用形態',
                    fields: [
                        {
                            type: 'label',
                            name: 'sales.dynamicData.hireType',
                            class: 'auto',
                        },
                    ],
                },
                {
                    title: '成約日',
                    fields: [
                        {
                            type: 'label',
                            name: 'sales.dynamicData.contractDate',
                            class: 'auto',
                            supplier: function (val) { return _this.datePipe.transform(val); },
                        },
                    ],
                },
                {
                    title: '入社日',
                    fields: [
                        {
                            type: 'label',
                            name: 'sales.dynamicData.enterDate',
                            class: 'auto',
                            supplier: function (val) { return _this.datePipe.transform(val); },
                        },
                    ],
                },
            ],
        };
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [this.readonly, this.readonly, this.readonly, this.readonly],
            [this.editable, this.editable, this.editable, this.editable],
            [this.readonly, this.readonly, this.readonly, this.readonly],
            [this.readonly, this.readonly, this.readonly, this.readonly],
        ];
    };
    ClosingInformationHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus]];
    };
    return ClosingInformationHelper;
}(HelperBase));
export { ClosingInformationHelper };
