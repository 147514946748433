/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sales-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "./sales-search.component";
import * as i8 from "../../../shared/services/api/dynamic-field.service";
var styles_SalesSearchComponent = [i0.styles];
var RenderType_SalesSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SalesSearchComponent, data: {} });
export { RenderType_SalesSearchComponent as RenderType_SalesSearchComponent };
export function View_SalesSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "page"]], null, [[null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.doSearch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "search-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "search-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6210\u7D04\u691C\u7D22"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "search-header__close close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "ag-dynamic-form", [["class", "dynamic-form"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(10, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "ag-dynamic-form", [["class", "dynamic-form"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(13, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "footer-spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "footer__btn footer__btn--green"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u691C\u7D22 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataLeft; var currVal_1 = _co.innerSearchModel; _ck(_v, 10, 0, currVal_0, currVal_1); var currVal_2 = _co.metadataRight; var currVal_3 = _co.innerSearchModel; _ck(_v, 13, 0, currVal_2, currVal_3); }, null); }
export function View_SalesSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-sales-search", [], null, null, null, View_SalesSearchComponent_0, RenderType_SalesSearchComponent)), i1.ɵdid(1, 245760, null, 0, i7.SalesSearchComponent, [i8.DynamicFieldService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SalesSearchComponentNgFactory = i1.ɵccf("ag-sales-search", i7.SalesSearchComponent, View_SalesSearchComponent_Host_0, { searchModel: "searchModel" }, { closed: "closed", search: "search" }, []);
export { SalesSearchComponentNgFactory as SalesSearchComponentNgFactory };
