import * as tslib_1 from "tslib";
import { CompanyListComponent } from '@agent-ds/shared/components/company-list/company-list.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { ExportApiService } from '@agent-ds/shared/services';
import { Location } from '@angular/common';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDetailPageComponent } from '../company-detail-page/company-detail-page.component';
var CompanyListPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyListPageComponent, _super);
    function CompanyListPageComponent(router, activeRoute, location, exportService) {
        var _this = _super.call(this, router, location, activeRoute, exportService) || this;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.location = location;
        _this.exportService = exportService;
        _this.model = 'enterprises';
        _this.detail = CompanyDetailPageComponent;
        _this.exportModel = ExportModel.enterprise;
        _this.listConfiguration = { body: { checkbox: true } };
        _this.exportTypes = [{ label: '企業', value: ExportModel.enterprise }, { label: '契約', value: 'contract' }];
        return _this;
    }
    CompanyListPageComponent.prototype.showExport = function () {
        var _this = this;
        PopupControlComponent.instance.open({
            title: 'CSV出力',
            content: this.exportTemplate,
            confirmText: 'ダウンロード',
            confirmEnabled: function () { return _this.exportTemplateId != null || _this.selectedExportType === 'contract'; },
            confirmCallback: function () {
                if (_this.selectedExportType === _this.exportModel) {
                    return _this.exportService.export(_this.exportModel, _this.exportTemplateId, _this.searchObj);
                }
                else {
                    return _this.exportService.exportContracts(_this.searchObj);
                }
            },
            cancelText: '閉じる',
        });
    };
    return CompanyListPageComponent;
}(ListPage));
export { CompanyListPageComponent };
