import * as tslib_1 from "tslib";
import { HelperBase } from './helper-base';
var BillingInformationHelper = /** @class */ (function (_super) {
    tslib_1.__extends(BillingInformationHelper, _super);
    function BillingInformationHelper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.billingOptions = [];
        _this.enterpriseFrontId = '';
        _this.editable = { rows: [] };
        _this.readonly = { rows: [] };
        return _this;
    }
    BillingInformationHelper.prototype.init = function (detailPage) {
        var _this = this;
        detailPage.billingSubject.subscribe(function (data) {
            var _a;
            // the object reference of "this.billingOptions" must be unchanged
            _this.billingOptions.splice(0, _this.billingOptions.length);
            (_a = _this.billingOptions).push.apply(_a, data.billingOptions);
            _this.enterpriseFrontId = _this.enterpriseFrontId;
        });
    };
    BillingInformationHelper.prototype.updateFieldDefinitions = function () {
        this.editable = this.getTemplate(true);
        this.readonly = this.getTemplate(false);
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [this.readonly, this.readonly, this.readonly, this.readonly],
            [this.editable, this.editable, this.editable, this.editable],
            [this.readonly, this.readonly, this.readonly, this.readonly],
            [this.readonly, this.readonly, this.readonly, this.readonly],
        ];
    };
    BillingInformationHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus]];
    };
    BillingInformationHelper.prototype.getTemplate = function (editable) {
        var _this = this;
        return {
            // Billing information
            title: '請求先情報',
            class: 'form__group--golden-title-border',
            rows: [
                editable
                    ? {
                        title: '請求先',
                        showRequired: true,
                        fields: [
                            {
                                name: 'sales.enterpriseBillingAddressId',
                                type: 'dropdown',
                                class: 'fill',
                                valueField: 'id',
                                labelField: 'label',
                                options: this.billingOptions,
                                linkTo: [
                                    'billing.companyName',
                                    'billing.contact.department',
                                    'billing.contact.name',
                                    'billing.contact.position',
                                    'billing.contact.tel',
                                    'billing.contact.fax',
                                    'billing.contact.address',
                                    'billing.btobId',
                                    'billing.billignAddressService',
                                    'billing.machTransferBankList',
                                ],
                                validators: { required: true },
                            },
                        ],
                    }
                    : {
                        title: '請求先',
                        fields: [
                            {
                                name: 'sales.enterpriseBillingAddressId',
                                type: 'label',
                                hidden: true,
                                linkTo: [
                                    'billing.title',
                                    'billing.companyName',
                                    'billing.contact.department',
                                    'billing.contact.name',
                                    'billing.contact.position',
                                    'billing.contact.tel',
                                    'billing.contact.fax',
                                    'billing.contact.address',
                                    'billing.btobId',
                                    'billing.billignAddressService',
                                    'billing.machTransferBankList',
                                ],
                            },
                            {
                                name: 'billing.title',
                                type: 'label',
                                supplier: function (value, callType, getter) {
                                    var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                    var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                    if (billing) {
                                        return billing
                                            ? [billing.companyName, billing.departmentName, billing.contact.name].filter(function (v) { return !!v; }).join(' ')
                                            : '-';
                                    }
                                },
                            },
                        ],
                    },
                {
                    title: (this.dynamicService.getDefinition('billingAddress', 'companyName') || { label: '請求先名' }).label,
                    fields: [
                        {
                            type: 'label',
                            name: 'billing.companyName',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                if (billing) {
                                    return [_this.enterpriseFrontId, billing && billing.companyName ? billing.companyName : ''].filter(function (v) { return !!v; }).join('-');
                                }
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('billingAddress', 'departmentName') || { label: '担当者部署名' }).label,
                    fields: [
                        {
                            type: 'label',
                            name: 'billing.contact.department',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.departmentName ? billing.departmentName : '';
                            },
                        },
                    ],
                },
                {
                    title: '担当者役職',
                    fields: [
                        {
                            name: 'billing.contact.position',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.contact && billing.contact.position ? billing.contact.position : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('billingAddress', 'contact') || { label: '担当者名' }).label,
                    fields: [
                        {
                            type: 'label',
                            name: 'billing.contact.name',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.contact && billing.contact.name ? billing.contact.name : '';
                            },
                        },
                    ],
                },
                {
                    title: '担当者Tel',
                    fields: [
                        {
                            name: 'billing.contact.tel',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.contact && billing.contact.tel ? billing.contact.tel : '';
                            },
                        },
                    ],
                },
                {
                    title: '担当者Fax',
                    fields: [
                        {
                            name: 'billing.contact.fax',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.contact && billing.contact.fax ? billing.contact.fax : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('billingAddress', 'address') || { label: '住所' }).label,
                    fields: [
                        {
                            name: 'billing.contact.address',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.address
                                    ? [
                                        "" + (billing.address.zip ? billing.address.zip.slice(0, 3) + '-' + billing.address.zip.slice(3) : ''),
                                        "" + (billing.address.prefecture ? billing.address.prefecture : ''),
                                        "" + (billing.address.address1 ? billing.address.address1 : ''),
                                        "" + (billing.address.address2 ? billing.address.address2 : ''),
                                        "" + (billing.address.address3 ? billing.address.address3 : ''),
                                    ].join(' ')
                                    : '';
                            },
                        },
                    ],
                },
                {
                    title: '発行先ID',
                    fields: [
                        {
                            name: 'billing.btobId',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.billignAddressCode && billing.billignBikeCD
                                    ? "" + billing.billignBikeCD + billing.billignAddressCode
                                    : '';
                            },
                        },
                    ],
                },
                {
                    title: '請求先サービス',
                    fields: [
                        {
                            name: 'billing.billignAddressService',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.billignAddressService ? billing.billignAddressService : '';
                            },
                        },
                    ],
                },
                {
                    title: '振込先',
                    fields: [
                        {
                            name: 'billing.machTransferBankList',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var billingAddressId = getter('sales.enterpriseBillingAddressId');
                                var billing = _this.billingOptions.find(function (v) { return v.id === billingAddressId; });
                                return billing && billing.machTransferBankList ? billing.machTransferBankList : '';
                            },
                        },
                    ],
                }
            ].concat(this.getDynamicRowOrLabel(true, this.dynamicService.getDefinition('sales', 'billingFrontRemark'), null, 'sales.dynamicData', 'fill')),
        };
    };
    return BillingInformationHelper;
}(HelperBase));
export { BillingInformationHelper };
