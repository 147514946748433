/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-teams-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/page-scroll-table/page-scroll-table.component.ngfactory";
import * as i3 from "../../../shared/components/page-scroll-table/page-scroll-table.component";
import * as i4 from "../../../shared/pipes/safe-date.pipe";
import * as i5 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i6 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../../shared/pipes/truncate.pipe";
import * as i9 from "@angular/common";
import * as i10 from "./settings-teams-page.component";
import * as i11 from "../../../shared/services/api/master-api.service";
import * as i12 from "../../../shared/services/api/dynamic-field.service";
var styles_SettingsTeamsPageComponent = [i0.styles];
var RenderType_SettingsTeamsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsTeamsPageComponent, data: {} });
export { RenderType_SettingsTeamsPageComponent as RenderType_SettingsTeamsPageComponent };
function View_SettingsTeamsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-scroll-table", [["class", "table"]], null, null, null, i2.View_PageScrollTableComponent_0, i2.RenderType_PageScrollTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.PageScrollTableComponent, [i4.SafeDatePipe], { content: [0, "content"], scrollerClass: [1, "scrollerClass"], tableConfig: [2, "tableConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teams; var currVal_1 = "no-scroll"; var currVal_2 = _co.tableConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SettingsTeamsPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "overflow"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 1, 0, currVal_1); }); }
function View_SettingsTeamsPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "overflow"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.branch == null) ? null : _v.context.$implicit.branch.name)); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.branch == null) ? null : _v.context.$implicit.branch.name)); _ck(_v, 1, 0, currVal_1); }); }
function View_SettingsTeamsPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "overflow"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.manager == null) ? null : _v.context.$implicit.manager.name)); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.manager == null) ? null : _v.context.$implicit.manager.name)); _ck(_v, 1, 0, currVal_1); }); }
function View_SettingsTeamsPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "edit-btn btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTeamEdit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5909\u66F4 "]))], null, null); }
function View_SettingsTeamsPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i5.View_DynamicFormComponent_0, i5.RenderType_DynamicFormComponent)), i1.ɵdid(2, 442368, [[6, 4], ["teamForm", 4]], 0, i6.DynamicFormComponent, [i7.FormBuilder, i1.KeyValueDiffers, i4.SafeDatePipe, i8.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teamPopupMeta; var currVal_1 = _co.teamPopupModel; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SettingsTeamsPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { editButtonTemplate: 0 }), i1.ɵqud(402653184, 2, { teamNameTemplate: 0 }), i1.ɵqud(402653184, 3, { branchNameTemplate: 0 }), i1.ɵqud(402653184, 4, { managerNameTemplate: 0 }), i1.ɵqud(402653184, 5, { teamPopupTemplate: 0 }), i1.ɵqud(671088640, 6, { teamFormComponent: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7BA1\u7406\n"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "section section--horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "sub-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30C1\u30FC\u30E0\u7BA1\u7406 "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn--green section__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTeamEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u65B0\u898F\u30C1\u30FC\u30E0\u767B\u9332 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsTeamsPageComponent_1)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["teamName", 2]], null, 0, null, View_SettingsTeamsPageComponent_2)), (_l()(), i1.ɵand(0, [[3, 2], ["branchName", 2]], null, 0, null, View_SettingsTeamsPageComponent_3)), (_l()(), i1.ɵand(0, [[4, 2], ["managerName", 2]], null, 0, null, View_SettingsTeamsPageComponent_4)), (_l()(), i1.ɵand(0, [[1, 2], ["editButton", 2]], null, 0, null, View_SettingsTeamsPageComponent_5)), (_l()(), i1.ɵand(0, [[5, 2], ["teamPopup", 2]], null, 0, null, View_SettingsTeamsPageComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableConfig; _ck(_v, 15, 0, currVal_0); }, null); }
export function View_SettingsTeamsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-settings-teams-page", [], null, null, null, View_SettingsTeamsPageComponent_0, RenderType_SettingsTeamsPageComponent)), i1.ɵdid(1, 114688, null, 0, i10.SettingsTeamsPageComponent, [i11.MasterApiService, i12.DynamicFieldService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsTeamsPageComponentNgFactory = i1.ɵccf("ag-settings-teams-page", i10.SettingsTeamsPageComponent, View_SettingsTeamsPageComponent_Host_0, {}, {}, []);
export { SettingsTeamsPageComponentNgFactory as SettingsTeamsPageComponentNgFactory };
