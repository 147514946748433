import * as tslib_1 from "tslib";
import { ExtendedProgressStatusCode, ProgressStatusCode } from '@agent-ds/shared/enums';
import { MapperInterceptor } from '@agent-ds/shared/interceptors/mapper.interceptor';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, CompanyApiService, DynamicFieldService, FileApiService, JobApiService, MailApiService, ProgressApiService, UserApiService, } from '@agent-ds/shared/services';
import { getValueFromObject } from '@agent-ds/shared/util/util';
import { EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { PopupControlComponent } from '../popup-control/popup-control.component';
var MailSendComponent = /** @class */ (function () {
    function MailSendComponent(dynamicService, mailApiService, authService, fileService, jobApiService, progressApiService, companyApiService, userService, datePipe) {
        this.dynamicService = dynamicService;
        this.mailApiService = mailApiService;
        this.authService = authService;
        this.fileService = fileService;
        this.jobApiService = jobApiService;
        this.progressApiService = progressApiService;
        this.companyApiService = companyApiService;
        this.userService = userService;
        this.datePipe = datePipe;
        this.showPopup = new EventEmitter();
        this.closed = new EventEmitter();
        this.sent = new EventEmitter();
        this.shouldOpen = new EventEmitter();
        this.meta = { groups: [] };
        this.model = { to: [] };
        this.files = [];
        this.enterpriseFiles = {};
        this.studentFiles = {};
        this.validity = false;
        this.inConfirm = false;
        this.confirmDisabled = false;
        this.enterpriseFileIds = [];
        this.studentFileIds = [];
        this.progressErrorInfo = [];
        this.progressStatus = ProgressStatusCode;
        this.extendedProgressStatus = ExtendedProgressStatusCode;
        this.enterpriseInfoById = {};
        this.studentInfoById = {};
        this.userSignature = [];
    }
    MailSendComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(MailSendComponent.prototype, "config", {
        get: function () {
            return this.sendConfig;
        },
        set: function (sendConfig) {
            this.close(true);
            if (this.sendConfig !== sendConfig) {
                if (this.sendConfig) {
                    this.sendConfig.detach();
                }
                this.sendConfig = sendConfig;
                this.sendConfig.attach(this);
                this.sendConfig.initForm();
            }
        },
        enumerable: true,
        configurable: true
    });
    MailSendComponent.prototype.onValidation = function (valid) {
        this.validity = valid;
    };
    MailSendComponent.prototype.reset = function (prefill) {
        var _this = this;
        this.prefill = prefill;
        this.model = tslib_1.__assign({ studentIds: [], enterpriseIds: [] }, this.model, { studentFileIds: [], enterpriseFileIds: [], cc: [], cc_single: [], bcc: [], to: [], from: this.authService.loginUser ? this.authService.loginUser.id : null, subject: getValueFromObject(this.prefill, 'subject'), body: getValueFromObject(this.prefill, 'body'), signature: null, signatureBody: null, dummyTemplateType: null, text: null });
        this.enterpriseIdForFile = null;
        this.studentIdForFiles = null;
        this.enterpriseFiles = {};
        this.studentFiles = {};
        this.enterpriseFileIds = [];
        this.studentFileIds = [];
        this.progressErrorInfo = [];
        this.sendResultInfo = null;
        this.enterpriseInfoById = {};
        this.studentInfoById = {};
        if (getValueFromObject(this.authService.loginUser, 'id') !== this.userIdForSignatures) {
            this.userIdForSignatures = null;
        }
        if (this.sendConfig) {
            if (this.sendConfig.getTemplateId()) {
                // idを指定して、該当するテンプレートを取得する
                var templateId = this.sendConfig.getTemplateId() || 0;
                this.mailApiService.getMailTemplate(templateId).subscribe(function (res) {
                    _this.model.dummyTemplateType = res;
                    if (_this.model.dummyTemplateType) {
                        if (_this.model.dummyTemplateType.mailTemplateTypeId === 9) {
                            _this.userService.getAll().subscribe(function (users) {
                                _this.mailApiService
                                    .replaceTemplate(9, {
                                    from: users.find(function (u) { return u.id === _this.model.from; }).email,
                                    to: _this.model.to,
                                    subject: _this.model.dummyTemplateType.subject,
                                    text: _this.model.dummyTemplateType.body,
                                    studentId: _this.model.studentId,
                                    jobId: _this.model.jobId,
                                })
                                    .subscribe(function (replaced) {
                                    _this.model.body = replaced.body;
                                    _this.model.subject = replaced.subject;
                                });
                            });
                        }
                        else {
                            _this.model.body = _this.model.dummyTemplateType.body;
                            _this.model.subject = _this.model.dummyTemplateType.subject;
                        }
                    }
                });
            }
        }
        this.inConfirm = false;
    };
    MailSendComponent.prototype.update = function () {
        this.model.templateTypeIds = this.sendConfig.getTemplateTypeId();
        this.sendConfig.update();
    };
    MailSendComponent.prototype.confirm = function (sendAfter) {
        var _this = this;
        if (sendAfter === void 0) { sendAfter = false; }
        this.confirmDisabled = false;
        this.model.text = this.model.signatureBody ? this.model.body + '\n' + this.model.signatureBody : this.model.body;
        var templateTypeId = this.model.dummyTemplateType
            ? this.model.dummyTemplateType.mailTemplateTypeId
            : this.sendConfig.getTemplateTypeId().length === 1
                ? this.sendConfig.getTemplateTypeId()[0]
                : undefined;
        if (templateTypeId) {
            this.userService.getAll().subscribe(function (users) {
                var templateObj = {
                    from: users.find(function (u) { return u.id === _this.model.from; }).email,
                    to: _this.model.to,
                    subject: _this.model.subject,
                    text: _this.model.text,
                };
                if (_this.model.students && _this.model.students.length === 1) {
                    templateObj['studentId'] = _this.model.students[0].id;
                }
                if (_this.model.studentId) {
                    templateObj['studentId'] = _this.model.studentId;
                }
                if (_this.model.jobs && _this.model.jobs.length) {
                    if (_this.model.jobs.length === 1) {
                        templateObj['jobId'] = _this.model.jobs[0].id || _this.model.jobId;
                    }
                    templateObj['jobIds'] = _this.model.jobs.map(function (j) { return j.id; });
                }
                else if (_this.model.jobId) {
                    templateObj['jobId'] = _this.model.jobId;
                }
                if (_this.model.departmentId) {
                    templateObj['enterpriseDepartmentId'] = _this.model.departmentId;
                }
                else if (_this.model.department) {
                    templateObj['enterpriseDepartmentId'] = _this.model.department.id;
                }
                if (_this.model.progressId) {
                    templateObj['progressId'] = _this.model.progressId;
                }
                if (_this.model.progressStatus != null &&
                    _this.model.progressSeminarType != null &&
                    _this.model.progressSeminarAt != null &&
                    (_this.model.progressStatus === 70 || _this.model.progressStatus === 100)) {
                    switch (_this.model.progressSeminarType) {
                        case 1:
                            templateObj['additionalReplacements'] = [
                                {
                                    label: '面接日時',
                                    value: _this.datePipe.transform(_this.model.progressSeminarAt, 'yyyy年M月d日 HH時mm分'),
                                },
                            ];
                            break;
                        case 99:
                            templateObj['additionalReplacements'] = [
                                {
                                    label: '面接日時',
                                    value: _this.datePipe.transform(_this.model.progressSeminarAt, 'yyyy年M月d日 HH時mm分'),
                                },
                                {
                                    label: '選考ステータス',
                                    value: '最終前面接',
                                },
                            ];
                            break;
                        case 100:
                            templateObj['additionalReplacements'] = [
                                {
                                    label: '面接日時',
                                    value: _this.datePipe.transform(_this.model.progressSeminarAt, 'yyyy年M月d日 HH時mm分'),
                                },
                                {
                                    label: '選考ステータス',
                                    value: '最終面接',
                                },
                            ];
                            break;
                        default:
                            break;
                    }
                }
                _this.confirmDisabled = true;
                _this.mailApiService.replaceTemplate(templateTypeId, templateObj).subscribe(function (res) {
                    _this.model.subject = res.subject;
                    _this.model.text = res.body;
                    _this.confirmDisabled = false;
                    if (sendAfter) {
                        _this.save();
                    }
                });
            });
        }
        else if (sendAfter) {
            this.save();
        }
        this.inConfirm = true;
        this.sendConfig.initForm();
    };
    MailSendComponent.prototype.cancel = function () {
        this.inConfirm = false;
        this.confirmDisabled = false;
        this.sendConfig.initForm();
    };
    MailSendComponent.prototype.save = function () {
        var _this = this;
        this.confirmDisabled = true;
        this.userService.getAll().subscribe(function (users) {
            var toSave = {
                userId: _this.authService.loginUser.id,
                from: users.find(function (u) { return u.id === _this.model.from; }).email,
                to: _this.model.to.removeSame(),
                cc: _this.model.cc_single.concat((_this.model.cc || []).map(function (id) { return (users.find(function (u) { return u.id === id; }) || { email: null }).email; }).filter(function (v) { return v; })).removeSame(),
                bcc: (_this.model.bcc || []).map(function (id) { return users.find(function (u) { return u.id === id; }).email; }).removeSame(),
                enterpriseFileIds: _this.model.enterpriseFileIds,
                studentFileIds: _this.model.studentFileIds,
                subject: _this.model.subject,
                text: _this.model.text,
                templateTypeId: _this.model.dummyTemplateType
                    ? _this.model.dummyTemplateType.mailTemplateTypeId
                    : _this.sendConfig.getTemplateTypeId().length === 1
                        ? _this.sendConfig.getTemplateTypeId()[0]
                        : undefined,
                condition: _this.model.condition ? MapperInterceptor.toSearchFormat(_this.model.condition) : undefined,
            };
            if (_this.prefill) {
                toSave['inReplyTo'] = _this.prefill.inReplyTo;
                toSave['references'] = _this.prefill.references;
            }
            _this.sendConfig.save(toSave);
        });
    };
    MailSendComponent.prototype.afterSend = function (result) {
        var _this = this;
        this.sent.emit(result);
        PopupControlComponent.subInstance.open({
            confirmText: '閉じる',
            content: this.sendResultTemplate,
            title: '送信',
            confirmCallback: function () { return false; },
        });
        var subs = PopupControlComponent.subInstance.closed.subscribe(function () {
            _this.close();
            subs.unsubscribe();
        });
    };
    MailSendComponent.prototype.close = function (dataOnly) {
        if (dataOnly === void 0) { dataOnly = false; }
        this.model.enterpriseId = null;
        this.model.students = null;
        this.model.studentIds = [];
        this.model.studentId = null;
        this.model.jobs = [];
        this.model.jobId = null;
        this.model.enterpriseIds = [];
        this.model.enterprises = [];
        this.model.department = null;
        this.model.departmentId = null;
        this.model.studentFileIds = [];
        this.model.enterpriseFileIds = [];
        this.model.condition = null;
        if (!dataOnly) {
            this.closed.emit();
        }
    };
    return MailSendComponent;
}());
export { MailSendComponent };
