<div
  class="popup"
  *ngIf="config"
  [style.height]="config.height"
  [style.width]="config.width"
>
  <div class="popup__header">
    <span class="popup__header__title">{{ config.title }}</span>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="popup__content">
    <span class="popup__content__text" *ngIf="isText">{{
      config.content
    }}</span>
    <ng-container *ngIf="outlet" [cdkPortalOutlet]="outlet"></ng-container>
    <ng-content></ng-content>
  </div>
  <div class="popup__footer">
    <button
      *ngIf="config.cancelText"
      class="popup__footer__cancel btn btn--larger btn--white-dirty btn--outline-blue-primary btn--regular-font {{
        config.cancelClass
      }}"
      (click)="cancel()"
    >
      {{ config.cancelText }}
    </button>
    <button
      *ngFor="let confirmConfig of config.multiConfirm || []"
      [disabled]="
        (confirmConfig.confirmEnabled && !confirmConfig.confirmEnabled()) ||
        inClose
      "
      class="popup__footer__confirm btn btn--larger {{
        confirmConfig.confirmClass || 'btn--blue-primary'
      }}"
      (click)="confirm(confirmConfig.confirmCallback)"
    >
      {{ confirmConfig.confirmText }}
    </button>
    <button
      *ngIf="config.confirmText"
      [disabled]="
        (config.confirmEnabled && !config.confirmEnabled()) || inClose
      "
      class="popup__footer__confirm btn btn--larger btn--blue-primary"
      (click)="confirm(config.confirmCallback)"
    >
      {{ confirmText }}
    </button>
  </div>
</div>
