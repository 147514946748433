/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-confirm-file-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./student-confirm-file-dialog.component";
import * as i3 from "../../../shared/components/dialog/dialog-ref";
import * as i4 from "../../../shared/components/dialog/dialog-config";
var styles_StudentConfirmFileDialogComponent = [i0.styles];
var RenderType_StudentConfirmFileDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentConfirmFileDialogComponent, data: {} });
export { RenderType_StudentConfirmFileDialogComponent as RenderType_StudentConfirmFileDialogComponent };
export function View_StudentConfirmFileDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30D5\u30A1\u30A4\u30EB\u3092\u78BA\u8A8D\u3059\u308B"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "dialog__body"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" \u300C", "\u300D\u306E\u300C\u78BA\u8A8D\u5F85\u3061\u300D\u304C\u89E3\u9664\u3055\u308C\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F "])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--danger-accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close("resubmit") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u518D\u63D0\u51FA "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close("confirm") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.data.filename; _ck(_v, 6, 0, currVal_0); }); }
export function View_StudentConfirmFileDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-student-confirm-file-dialog", [], null, null, null, View_StudentConfirmFileDialogComponent_0, RenderType_StudentConfirmFileDialogComponent)), i1.ɵdid(1, 49152, null, 0, i2.StudentConfirmFileDialogComponent, [i3.DialogRef, i4.DialogConfig], null, null)], null, null); }
var StudentConfirmFileDialogComponentNgFactory = i1.ɵccf("ag-student-confirm-file-dialog", i2.StudentConfirmFileDialogComponent, View_StudentConfirmFileDialogComponent_Host_0, {}, {}, []);
export { StudentConfirmFileDialogComponentNgFactory as StudentConfirmFileDialogComponentNgFactory };
