<div class="section-header">
  <div class="section-header__title">セミナー一覧</div>
  <div class="section-header__controls">
    <button class="btn btn--green btn--medium" (click)="onMasterJobListClick()">
      セミナー紹介求人マスタ登録
    </button>
    <button
      class="btn btn--green btn--medium"
      (click)="openSeminarRegistrationDialog()"
    >
      新規セミナー登録
    </button>
  </div>
</div>
<ag-tab-group class="tab-group" [(selectedIndex)]="selectedTabIndex">
  <ag-tab [label]="area.name" *ngFor="let area of areas"> </ag-tab>
</ag-tab-group>
<div class="section-content">
  <div class="controls">
    <input
      class="controls__select-all"
      type="checkbox"
      [checked]="table?.allChecked"
      (click)="table?.checkAll(table.allChecked)"
    />
    <button
      [disabled]="!table?.checkedItems?.length"
      class="controls__control btn btn--white btn--outline-gray btn--larger"
      (click)="onDelete()"
    >
      削除
    </button>
    <div class="controls__filter">
      <ag-dynamic-form
        class="control"
        [metadata]="filterMetadata"
        [model]="filterModel"
        *ngIf="filterMetadata"
      >
      </ag-dynamic-form>
      <button
        class="controls__btn btn btn--small btn--gray"
        (click)="onFilterChanged()"
      >
        確定
      </button>
    </div>
  </div>
  <ag-page-scroll-table
    class="table"
    [tableConfig]="tableConfig"
    [content]="seminars"
  ></ag-page-scroll-table>
</div>

<ng-template #actions let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="onJobListClick(data)"
    >
      セミナー紹介求人一覧
    </button>
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="onAttendeeListClick(data)"
    >
      出席予定者一覧
    </button>
  </div>
</ng-template>
