/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-seminars-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/page-scroll-table/page-scroll-table.component.ngfactory";
import * as i3 from "../../../../../shared/components/page-scroll-table/page-scroll-table.component";
import * as i4 from "../../../../../shared/pipes/safe-date.pipe";
import * as i5 from "../../../../../shared/models/tab";
import * as i6 from "./student-seminars-tab.component";
import * as i7 from "../../../../../shared/services/dialog.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../shared/services/api/counseling-api.service";
import * as i10 from "../../../../../shared/services/api/student-api.service";
var styles_StudentSeminarsTabComponent = [i0.styles];
var RenderType_StudentSeminarsTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentSeminarsTabComponent, data: {} });
export { RenderType_StudentSeminarsTabComponent as RenderType_StudentSeminarsTabComponent };
function View_StudentSeminarsTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "actions"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "actions__action btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSeminarJobOffers(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7D39\u4ECB\u6C42\u4EBA\u4E00\u89A7 "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "actions__action btn btn--small btn--white btn--outline-gray"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDeleteSeminar(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u524A\u9664 "]))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.isSeminarAtPastDate || _v.context.$implicit.isApplied); _ck(_v, 3, 0, currVal_0); }); }
export function View_StudentSeminarsTabComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { buttonsTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "table-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "button--right btn btn--large btn--green"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onReserveSeminar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30BB\u30DF\u30CA\u30FC\u4E88\u7D04 "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ag-page-scroll-table", [["class", "content__table"]], null, null, null, i2.View_PageScrollTableComponent_0, i2.RenderType_PageScrollTableComponent)), i1.ɵdid(5, 638976, null, 0, i3.PageScrollTableComponent, [i4.SafeDatePipe], { content: [0, "content"], tableConfig: [1, "tableConfig"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["actions", 2]], null, 0, null, View_StudentSeminarsTabComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.seminars; var currVal_1 = _co.tableConfig; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_StudentSeminarsTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-student-seminars-tab", [], null, null, null, View_StudentSeminarsTabComponent_0, RenderType_StudentSeminarsTabComponent)), i1.ɵprd(6144, null, i5.Tab, null, [i6.StudentSeminarsTabComponent]), i1.ɵdid(2, 770048, null, 0, i6.StudentSeminarsTabComponent, [i7.DialogService, i8.DatePipe, i9.CounselingApiService, i10.StudentApiService, i7.DialogService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var StudentSeminarsTabComponentNgFactory = i1.ɵccf("ag-student-seminars-tab", i6.StudentSeminarsTabComponent, View_StudentSeminarsTabComponent_Host_0, { student: "student" }, {}, []);
export { StudentSeminarsTabComponentNgFactory as StudentSeminarsTabComponentNgFactory };
