<div class="header">
  管理
</div>

<div class="section">
  <div class="sub-header">
    <div class="sub-header__title">
      組織管理
    </div>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td class="table__label">ユーザー管理</td>
        <td class="table__value">担当者の管理を行います</td>
        <td class="table__action">
          <a
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            routerLink="/settings/users"
          >
            編集
          </a>
        </td>
      </tr>
      <tr>
        <td class="table__label">チーム管理</td>
        <td class="table__value">
          担当者が所属するチームの管理を行います
        </td>
        <td class="table__action">
          <a
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            routerLink="/settings/teams"
          >
            編集
          </a>
        </td>
      </tr>
      <tr>
        <td class="table__label">報告用CSVダウンロード</td>
        <td class="table__value">
          報告用CSVのダウンロードを行います
        </td>
        <td class="table__action">
          <a
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            routerLink="/settings/csv-reporting"
          >
            一覧
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="section">
  <div class="sub-header">
    <div class="sub-header__title">
      システム管理
    </div>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td class="table__label">システム設定</td>
        <td class="table__value">システム全体の設定を行います</td>
        <td class="table__action">
          <a
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            routerLink="/settings/system"
          >
            編集
          </a>
        </td>
      </tr>
      <tr>
        <td class="table__label">フィールド管理</td>
        <td class="table__value">
          システム項目の管理を行います
        </td>
        <td class="table__action">
          <a
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            routerLink="/settings/field-definitions"
          >
            編集
          </a>
        </td>
      </tr>
      <tr>
        <td class="table__label">カスタムフィールド管理</td>
        <td class="table__value">
          カスタムフィールドの管理を行います
        </td>
        <td class="table__action">
          <a
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            routerLink="/settings/custom-fields"
          >
            編集
          </a>
        </td>
      </tr>
      <tr>
        <td class="table__label">メールテンプレート管理</td>
        <td class="table__value">
          メールテンプレートの管理を行います
        </td>
        <td class="table__action">
          <a
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            routerLink="/settings/mail-templates"
          >
            編集
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
