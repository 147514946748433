import * as i0 from "@angular/core";
var ToasterService = /** @class */ (function () {
    function ToasterService() {
        this.toastRefs = [];
        this.TIMEOUT = 10000;
        this.timeouts = {};
    }
    Object.defineProperty(ToasterService.prototype, "toasts", {
        get: function () {
            return this.toastRefs;
        },
        enumerable: true,
        configurable: true
    });
    ToasterService.prototype.addToast = function (toast) {
        var _this = this;
        this.removeToast(toast.key);
        this.toastRefs.push(toast);
        this.timeouts[toast.key] = setTimeout(function () { return _this.removeToast(toast.key); }, this.TIMEOUT);
    };
    ToasterService.prototype.removeToast = function (key) {
        var existingIndex = this.toastRefs.findIndex(function (t) { return t.key === key; });
        if (existingIndex > -1) {
            clearTimeout(this.timeouts[key]);
            this.toastRefs.splice(existingIndex, 1);
        }
    };
    ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(); }, token: ToasterService, providedIn: "root" });
    return ToasterService;
}());
export { ToasterService };
