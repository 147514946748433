<div class="page" (keydown.enter)="doSearch($event)">
  <div class="search-header">
    <div class="search-header__title">求人検索</div>
    <div class="search-header__close close" (click)="onCloseClick()"></div>
  </div>

  <div class="content">
    <div class="row">
      <div class="row__column keyword">
        <div class="header keyword__header">
          <div class="header__title">キーワード</div>
        </div>
        <div class="keyword__input-container">
          <input
            class="keyword__input"
            type="text"
            [(ngModel)]="searchModel.keyword"
            (change)="onFormChange()"
          />
          <div class="keyword__hint">
            ※キーワード検索対象：企業名、企業カナ、企業ID、企業No.、求人ID、ポジション名
          </div>
        </div>
      </div>
      <div class="row__column"></div>
    </div>

    <div class="row">
      <div class="row__column">
        <ag-dynamic-form
          [metadata]="leftMeta"
          [model]="searchModel"
          (changed)="onFormChange()"
          *ngIf="leftMeta"
        ></ag-dynamic-form>
      </div>
      <div class="row__column">
        <ag-dynamic-form
          [metadata]="rightMeta"
          [model]="searchModel"
          (changed)="onFormChange()"
          *ngIf="rightMeta"
        ></ag-dynamic-form>
      </div>
    </div>
  </div>

  <div class="content content--bg-gray" *ngIf="customMeta">
    <div class="row">
      <div class="row__column">
        <ag-dynamic-form
          class="content__custom-fields-form"
          [metadata]="customMeta"
          [model]="searchModel"
          (changed)="onFormChange()"
        ></ag-dynamic-form>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <button class="footer__btn footer__btn--green" (click)="doSearch()">
    検索
  </button>
</div>
