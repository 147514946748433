<div class="header">
  <span class="header__title">求人お気に入り</span>
</div>
<div class="content">
  <div class="content__controls">
    <input
      class="content__controls__select-all"
      type="checkbox"
      [checked]="table?.allChecked"
      (click)="table?.checkAll(table.allChecked)"
    />
    <span class="content__controls__count">{{
      table?.checkedItems.length || table?.totalSize
    }}</span>
    <span class="content__controls__label">{{
      table?.checkedItems.length ? "件選択中" : "件"
    }}</span>
    <button
      [disabled]="
        table?.checkedItems.length > 20 || !table?.checkedItems.length
      "
      class="content__controls__control"
      (click)="getJobsAsPdf()"
    >
      求人票
    </button>
    <button
      [disabled]="!table?.checkedItems.length"
      class="content__controls__control"
      (click)="openMail(postingConfig)"
    >
      求人票送信
    </button>
    <button
      [disabled]="!table?.checkedItems.length"
      class="content__controls__control"
      (click)="onRegisterStudents()"
    >
      進捗登録
    </button>
    <button
      [disabled]="!table?.checkedItems.length"
      class="content__controls__control"
      (click)="onRemoveFromFavorites()"
    >
      お気に入りから外す
    </button>
    <button
      [disabled]="!table?.checkedItems.length"
      class="content__controls__control"
      (click)="openMail(recommendConfig)"
    >
      一括推薦
    </button>
  </div>

  <ag-page-scroll-table
    class="content__table"
    [tableConfig]="tableConfig"
    [content]="jobs"
    (itemSelected)="onItemSelected($event)"
  ></ag-page-scroll-table>
</div>

<ng-template #heat let-data>
  <div class="heat">
    <div>
      <span
        class="heat__badge badge"
        [agClass]="data.dynamicData?.recommendationDateKind"
        [classOptions]="recommendationHeatClasses"
      >
        {{ data.dynamicData?.recommendationDateKind }}
      </span>
    </div>
    <div
      class="heat__deadline"
      *ngIf="
        data.dynamicData?.recommendationDateKind &&
        data.dynamicData?.recommendationDate
      "
    >
      推薦期限:&nbsp;{{
        data.dynamicData?.recommendationDate | safeDate: "MM/dd"
      }}
    </div>
  </div>
</ng-template>

<ng-template #company let-data>
  <div class="description">
    <div class="description__label description__label--big">
      {{ data.frontEnterpriseId }}
    </div>
    <div
      class="description__link description__link--big description__link--bold description__link--no-wrap"
    >
      {{ data.enterpriseName }}
    </div>
    <div class="description__seg" *ngIf="data.companyClassification">
      <span
        class="description__seg--ml10 badge"
        [ngClass]="classMap[data.companyClassification]"
        >{{ data.companyClassification }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #position let-data>
  <div class="description">
    <div class="description__label description__label--small">
      {{ data.frontId }}
    </div>
    <div
      class="description__link description__link--small description__link--bold description__link--no-wrap"
    >
      {{ data.dynamicData?.position }}
    </div>
  </div>
</ng-template>
