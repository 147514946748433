import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { OnChanges, SimpleChanges } from '@angular/core';
var CompanyProgressTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyProgressTabComponent, _super);
    function CompanyProgressTabComponent() {
        var _this = _super.call(this) || this;
        _this.companyId = null;
        return _this;
    }
    CompanyProgressTabComponent.prototype.ngOnChanges = function (changes) {
        if (this.companyId != null && changes['companyId'].currentValue !== changes['companyId'].previousValue) {
            this.requestObject = {
                enterpriseId: [this.companyId],
            };
        }
    };
    return CompanyProgressTabComponent;
}(Tab));
export { CompanyProgressTabComponent };
