<div class="content">
  <div class="content__title">
    {{ area?.name ? area?.name : "" }}
    {{ area && seminar ? " ┃ " : "" }}
    {{ seminar?.seminarAt | date: "yyyy/MM/dd（E）HH:mm〜" }}
    {{ seminar?.type != null ? "　　セミナー属性：" + seminar?.type : "" }}
  </div>

  <ag-job-list
    class="content__list"
    [options]="listConfiguration"
    [extraControls]="buttons"
    [content]="jobs"
    #jobList
  >
  </ag-job-list>

  <span *ngIf="jobs && !jobs.length" class="content__empty-list">
    <span>登録された求人はありません。</span>
    <span
      >「{{
        seminar ? "セミナー紹介求人新規登録" : "求人登録"
      }}」ボタンを押して求人を登録してください。</span
    >
  </span>
</div>

<ng-template #buttons>
  <div class="button__container">
    <button
      [disabled]="!jobList?.checkedItems.length"
      class="content__controls__control btn btn--outline-gray btn--large"
      (click)="onDelete()"
    >
      削除
    </button>
    <button
      *ngIf="seminar"
      [disabled]="
        !jobList?.checkedItems.length && jobList?.checkedItems.length <= 30
      "
      class="content__controls__control btn btn--outline-gray btn--large"
      (click)="onExportJobsPdf()"
    >
      求人票の出力(PDF)
    </button>
    <button
      *ngIf="seminar"
      [disabled]="
        !jobList?.checkedItems.length && jobList?.checkedItems.length <= 30
      "
      class="content__controls__control btn btn--outline-gray btn--large"
      (click)="onExportJobsForSeminar()"
    >
      応募シート(Excel)
    </button>
    <div class="button__group">
      <button
        *ngIf="!seminar"
        class="content__controls__control btn btn--green btn--large"
        (click)="openSeminarJobRegistrationDialog()"
      >
        求人登録
      </button>
      <button
        *ngIf="seminar"
        class="content__controls__control btn btn--green btn--large"
        (click)="openSeminarJobRegistrationDialog()"
      >
        セミナー紹介求人新規登録
      </button>
    </div>
  </div>
</ng-template>
