import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
var CompanyFilesTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyFilesTabComponent, _super);
    function CompanyFilesTabComponent() {
        return _super.call(this) || this;
    }
    return CompanyFilesTabComponent;
}(Tab));
export { CompanyFilesTabComponent };
