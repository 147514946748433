/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../pipes/safe-date.pipe";
import * as i6 from "../../pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "./login-page.component";
import * as i9 from "../../services/auth.service";
import * as i10 from "@angular/router";
import * as i11 from "../../services/dialog.service";
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
function View_LoginPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "login__wrapper"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-dynamic-form", [], [[4, "width", null]], [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(2, 442368, [[1, 4], ["LoginForm", 4]], 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { submitted: "submitted" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.meta; var currVal_3 = _co.credentials; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = "80%"; _ck(_v, 0, 0, currVal_0); var currVal_1 = "100%"; _ck(_v, 1, 0, currVal_1); }); }
function View_LoginPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "login__logout-notice"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30ED\u30B0\u30A2\u30A6\u30C8\u3057\u307E\u3057\u305F\u3002"]))], null, null); }
export function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { form: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "login"]], [[2, "login--outlook", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "login__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "/assets/images/mach.svg"]], [[8, "height", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPageComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPageComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "login__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "login__button btn btn--larger btn--blue-primary"]], [[2, "btn--outlook", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.pageInfo.layout === false) ? _co.pageInfo.layout = null : _co.onLogin()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.pageInfo.layout == null) || _co.pageInfo.layout); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.pageInfo.layout === false); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.guard.CURRENT_USAGE; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.pageInfo.layout === false) ? 28 : 51); _ck(_v, 3, 0, currVal_1); var currVal_4 = _co.guard.CURRENT_USAGE; var currVal_5 = ((_co.pageInfo.layout !== false) && (!_co.credentials.email || !_co.credentials.password)); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = ((_co.pageInfo.layout === false) ? "\u518D\u30ED\u30B0\u30A4\u30F3" : "\u30ED\u30B0\u30A4\u30F3"); _ck(_v, 10, 0, currVal_6); }); }
export function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoginPageComponent, [i9.AuthService, i10.Router, i11.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageComponentNgFactory = i1.ɵccf("ag-login-page", i8.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
