/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-progress-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/progress-list/progress-list.component.ngfactory";
import * as i3 from "../../../../../shared/components/progress-list/progress-list.component";
import * as i4 from "../../../../../shared/services/api/master-api.service";
import * as i5 from "../../../../../shared/services/api/user-api.service";
import * as i6 from "../../../../../shared/services/api/progress-api.service";
import * as i7 from "../../../../../shared/services/api/job-api.service";
import * as i8 from "../../../../../shared/pipes/safe-date.pipe";
import * as i9 from "../../../../../shared/services/dialog.service";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../shared/services/api/dynamic-field.service";
import * as i12 from "../../../../../shared/services/api/student-api.service";
import * as i13 from "../../../../../shared/services/api/mail-api.service";
import * as i14 from "../../../../../shared/services/auth.service";
import * as i15 from "@angular/router";
import * as i16 from "../../../../../shared/models/tab";
import * as i17 from "./company-progress-tab.component";
var styles_CompanyProgressTabComponent = [i0.styles];
var RenderType_CompanyProgressTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyProgressTabComponent, data: {} });
export { RenderType_CompanyProgressTabComponent as RenderType_CompanyProgressTabComponent };
export function View_CompanyProgressTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-progress-list", [], null, null, null, i2.View_ProgressListComponent_0, i2.RenderType_ProgressListComponent)), i1.ɵdid(1, 4964352, null, 0, i3.ProgressListComponent, [i4.MasterApiService, i5.UserApiService, i6.ProgressApiService, i7.JobApiService, i8.SafeDatePipe, i1.ChangeDetectorRef, i9.DialogService, i10.Location, i11.DynamicFieldService, i12.StudentApiService, i13.MailApiService, i14.AuthService, i15.Router], { requestObject: [0, "requestObject"], disableDetailFullScreen: [1, "disableDetailFullScreen"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requestObject; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CompanyProgressTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-company-progress-tab", [], null, null, null, View_CompanyProgressTabComponent_0, RenderType_CompanyProgressTabComponent)), i1.ɵprd(6144, null, i16.Tab, null, [i17.CompanyProgressTabComponent]), i1.ɵdid(2, 573440, null, 0, i17.CompanyProgressTabComponent, [], null, null)], null, null); }
var CompanyProgressTabComponentNgFactory = i1.ɵccf("ag-company-progress-tab", i17.CompanyProgressTabComponent, View_CompanyProgressTabComponent_Host_0, { companyId: "companyId" }, {}, []);
export { CompanyProgressTabComponentNgFactory as CompanyProgressTabComponentNgFactory };
