<ag-file-list [reference]="student"></ag-file-list>
<div class="job-history-header">
  <div class="job-history-header__title">求人履歴管理</div>
</div>
<div class="job-history">
  <table *ngIf="jobFiles.length > 0" class="job-history-table">
    <thead>
      <tr>
        <th>ファイル名</th>
        <th>登録日時</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of jobFiles">
        <td class="job-history-table__link" (click)="downloadJobFile(file)">
          {{ file.name }}
        </td>
        <td class="job-history-table__date">
          {{ file.createdAt | basedDate }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
