import * as tslib_1 from "tslib";
import { APPROVAL_CANCEL_STATES } from '@agent-ds/shared/constants';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { DecimalPipe } from '@angular/common';
import { TemplateRef } from '@angular/core';
import { HelperBase } from './helper-base';
var CancellationInformationHelper = /** @class */ (function (_super) {
    tslib_1.__extends(CancellationInformationHelper, _super);
    function CancellationInformationHelper(dynamicService, datePipe, decimalPipe) {
        var _this = _super.call(this, dynamicService, datePipe) || this;
        _this.decimalPipe = decimalPipe;
        _this.calculateRefoundAmountProp = { subscribers: [] };
        _this.cancellationButtonsProp = null;
        _this.cancelApproved = { rows: [] };
        _this.cancelNotApproved = { rows: [] };
        _this.matrix = [];
        return _this;
    }
    Object.defineProperty(CancellationInformationHelper.prototype, "cancellationButtons", {
        set: function (value) {
            this.cancellationButtonsProp = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CancellationInformationHelper.prototype, "calculateRefoundAmount", {
        set: function (value) {
            this.setupActionProperty(this.calculateRefoundAmountProp, value);
        },
        enumerable: true,
        configurable: true
    });
    CancellationInformationHelper.prototype.getTemplate = function (editable) {
        var _this = this;
        var res;
        res = {
            // Cancellation information
            title: 'キャンセル情報',
            class: 'form__group--golden-title-border',
            injectToHeader: this.cancellationButtonsProp,
            rows: [
                {
                    title: 'キャンセル承認区分',
                    fields: [
                        {
                            type: 'label',
                            name: 'sales.cancelStatus',
                            supplier: function (data, callType, getter) {
                                var value = getter('sales.cancelStatus', true);
                                return value ? (APPROVAL_CANCEL_STATES.find(function (state) { return state.code === value; }) || { name: '' }).name : '';
                            },
                        },
                    ],
                }
            ].concat(this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'joinTiming'), null, 'sales.dynamicData'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'cancelDate'), null, 'sales.dynamicData'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'cancelClassification'), null, 'sales.dynamicData'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'refundDate'), null, 'sales.dynamicData'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'refundMonth'), null, 'sales.dynamicData', 'half'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'refundPercent'), this.calculateRefoundAmountProp, 'sales.dynamicData', 'half'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'refundPrice'), null, 'sales.dynamicData', 'half', function (val) { return _this.decimalPipe.transform(val); }), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'refundRemarks'), null, 'sales.dynamicData', 'full'), this.getDynamicRowOrLabel(true, this.dynamicService.getDefinition('sales', 'refundRejectReason'), null, 'sales.dynamicData', 'fill')),
        };
        return res;
    };
    CancellationInformationHelper.prototype.updateFieldDefinitions = function () {
        this.cancelApproved = this.getTemplate(false);
        this.cancelNotApproved = this.getTemplate(true);
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [null, null, null, null],
            [null, null, null, null],
            [this.cancelApproved, this.cancelNotApproved, this.cancelNotApproved, this.cancelApproved],
            [null, null, null, null],
        ];
    };
    CancellationInformationHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus]];
    };
    return CancellationInformationHelper;
}(HelperBase));
export { CancellationInformationHelper };
