/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./memo-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./memo/memo.component.ngfactory";
import * as i3 from "./memo/memo.component";
import * as i4 from "../../services/api/master-api.service";
import * as i5 from "../../services/api/dynamic-field.service";
import * as i6 from "../../services/api/user-api.service";
import * as i7 from "../../services/auth.service";
import * as i8 from "@angular/common";
import * as i9 from "./memo-list.component";
var styles_MemoListComponent = [i0.styles];
var RenderType_MemoListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MemoListComponent, data: {} });
export { RenderType_MemoListComponent as RenderType_MemoListComponent };
function View_MemoListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-memo", [["class", "memo"]], null, [[null, "createCanceled"], [null, "saveSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("createCanceled" === en)) {
        var pd_0 = (_co.cancelNewMemo() !== false);
        ad = (pd_0 && ad);
    } if (("saveSuccess" === en)) {
        var pd_1 = (_co.getMemos() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MemoComponent_0, i2.RenderType_MemoComponent)), i1.ɵdid(1, 638976, null, 0, i3.MemoComponent, [i4.MasterApiService, i5.DynamicFieldService, i6.UserApiService, i7.AuthService], { service: [0, "service"], referenceId: [1, "referenceId"], isCreateInProgress: [2, "isCreateInProgress"] }, { createCanceled: "createCanceled", saveSuccess: "saveSuccess" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.service; var currVal_1 = _co.referenceId; var currVal_2 = _co.isCreateNewMemoInProgress; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MemoListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-memo", [["class", "memo"]], null, [[null, "deleteSuccess"], [null, "saveSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleteSuccess" === en)) {
        var pd_0 = (_co.removeMemo(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("saveSuccess" === en)) {
        var pd_1 = (_co.getMemos() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MemoComponent_0, i2.RenderType_MemoComponent)), i1.ɵdid(1, 638976, null, 0, i3.MemoComponent, [i4.MasterApiService, i5.DynamicFieldService, i6.UserApiService, i7.AuthService], { service: [0, "service"], referenceId: [1, "referenceId"], memo: [2, "memo"] }, { saveSuccess: "saveSuccess", deleteSuccess: "deleteSuccess" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.service; var currVal_1 = _co.referenceId; var currVal_2 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MemoListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header__title"]], [[2, "header__title--red", null], [2, "header__title--hidden", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn--large btn--green"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNewMemo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MemoListComponent_1)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MemoListComponent_2)), i1.ɵdid(8, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.isCreateNewMemoInProgress; _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.memos; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.title === "INNER\u60C5\u5831"); var currVal_1 = !_co.title; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.title; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.isCreateNewMemoInProgress; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.newText; _ck(_v, 4, 0, currVal_4); }); }
export function View_MemoListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-memo-list", [], null, null, null, View_MemoListComponent_0, RenderType_MemoListComponent)), i1.ɵdid(1, 4898816, null, 0, i9.MemoListComponent, [], null, null)], null, null); }
var MemoListComponentNgFactory = i1.ɵccf("ag-memo-list", i9.MemoListComponent, View_MemoListComponent_Host_0, { service: "service", referenceId: "referenceId", newText: "newText", title: "title" }, { errorGetMemos: "errorGetMemos" }, []);
export { MemoListComponentNgFactory as MemoListComponentNgFactory };
