<div class="header">
  <span class="header__title">企業検索結果</span>
  <span class="header__criteria"
    >キーワード：{{ searchObj["keyword"] || "" }}
  </span>
  <button
    class="header__modify btn btn--big btn--white btn--outline-blue btn--rounded btn--regular-font"
    (click)="openSearch()"
  >
    検索条件を変更
    <span class="header__modify__arrow header__modify__arrow--down"></span>
  </button>
  <button
    class="header__export btn btn--big btn--white btn--outline-gray"
    (click)="showExport()"
  >
    CSV出力
  </button>
</div>
<div class="content">
  <ag-company-list
    #companyList
    [requestObject]="searchObj"
    [options]="listConfiguration"
  >
  </ag-company-list>
</div>

<ng-template #export>
  <div class="export">
    <span class="export__note">出力するCSVを選択してください。</span>
    <div class="export__row">
      <label for="category_select" class="export__label">カテゴリー</label>
      <ag-select
        id="category_select"
        class="export__select"
        valueField="value"
        labelField="label"
        [options]="exportTypes"
        [(value)]="selectedExportType"
      ></ag-select>
    </div>
    <div class="export__row" *ngIf="selectedExportType === 'enterprise'">
      <label for="output_select" class="export__label">出力リスト</label>
      <ag-autocomplete
        id="output_select"
        class="export__select"
        valueField="id"
        labelField="name"
        [hideBtn]="false"
        [options]="exportTemplates"
        [(value)]="exportTemplateId"
      ></ag-autocomplete>
    </div>
  </div>
</ng-template>

<ag-company-search
  *ngIf="isSearchOpen"
  [searchModel]="searchObj"
  (closed)="closeSearch()"
  (search)="doSearch($event)"
></ag-company-search>
