<div class="page" (keydown.enter)="doSearch($event)">
  <div class="search-header">
    <div class="search-header__title">進捗検索</div>
    <div class="search-header__close close" (click)="onCloseClick()"></div>
  </div>

  <div class="content">
    <div class="row">
      <div class="row__column">
        <ag-dynamic-form
          *ngIf="leftMeta"
          [metadata]="leftMeta"
          [model]="searchModel"
          (validated)="onValidityChange(0, $event)"
        ></ag-dynamic-form>
      </div>
      <div class="row__column">
        <ag-dynamic-form
          *ngIf="rightMeta"
          [metadata]="rightMeta"
          [model]="searchModel"
          (validated)="onValidityChange(1, $event)"
        ></ag-dynamic-form>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <button
    class="footer__btn footer__btn--green"
    [disabled]="!valid"
    (click)="doSearch()"
  >
    検索
  </button>
</div>
