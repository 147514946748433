<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">2ndマッチ実績出力</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__container">
    <div class="content">
      <p>
        出力したい期間を選択してください。
      </p>
      <ag-dynamic-form [metadata]="metadata" [model]="dateRange">
      </ag-dynamic-form>
    </div>
  </div>
  <div class="dialog__footer">
    <button
      class="dialog__footer__button btn btn--outline-blue"
      (click)="close()"
    >
      キャンセル
    </button>
    <button
      class="dialog__footer__button btn btn--blue"
      (click)="download()"
      [disabled]="!(dateRange.from && dateRange.to)"
    >
      ダウンロード
    </button>
  </div>
</div>
