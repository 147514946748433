import * as tslib_1 from "tslib";
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentSendConfig } from '@agent-ds/shared/components/mail-send/configs/student-send-config';
import { Tab } from '@agent-ds/shared/models';
import { StudentApiService } from '@agent-ds/shared/services';
import { getValueFromObject } from '@agent-ds/shared/util/util';
var StudentMessageTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentMessageTabComponent, _super);
    function StudentMessageTabComponent(studentApiService) {
        var _this = _super.call(this) || this;
        _this.studentApiService = studentApiService;
        _this.sendConfig = new StudentSendConfig();
        return _this;
    }
    StudentMessageTabComponent.prototype.openMail = function (prefill) {
        MailSendFlowComponent.instance.config = this.sendConfig;
        this.sendConfig.setParams({
            student: {
                id: this.student.id,
                frontId: this.student.frontId,
                firstName: this.student.dynamicData.firstName,
                lastName: this.student.dynamicData.lastName,
                age: null,
                prefecture: null,
                schoolName: null,
                schoolDepartmentName: null,
                studentUser: this.student.studentUsers,
                emailMain: getValueFromObject(this.student, 'dynamicData.emailMain.email'),
                emailMainAvailable: getValueFromObject(this.student, 'dynamicData.emailMain.emailAvailable'),
                emailSub: getValueFromObject(this.student, 'dynamicData.emailSub.email'),
                emailSubAvailable: getValueFromObject(this.student, 'dynamicData.emailSub.emailAvailable'),
            },
        });
        MailSendFlowComponent.instance.start(prefill);
    };
    return StudentMessageTabComponent;
}(Tab));
export { StudentMessageTabComponent };
