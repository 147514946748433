import * as tslib_1 from "tslib";
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { removeEmptyObjects } from '@agent-ds/shared/util/util';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
var CompanyDepartmentCreateDialogComponent = /** @class */ (function () {
    function CompanyDepartmentCreateDialogComponent(dialog, config, dynamicService, masterApiService) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.masterApiService = masterApiService;
        this.model = {};
        this.modelResponsible = {
            raIds: [],
            paIds: [],
            others: [],
        };
        this.departmentDynamicList = [];
        this.metadataContactInformation = [];
        this.validityArray = [true, true, true];
        this.valid = false;
        this.CONTACT_LIMIT = 5;
    }
    CompanyDepartmentCreateDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.config.data.department.dynamicData) {
            this.config.data.department.dynamicData = removeEmptyObjects(this.config.data.department.dynamicData);
        }
        this.model = this.config.data.department.dynamicData
            ? tslib_1.__assign({}, this.config.data.department.dynamicData, { address: this.config.data.department.dynamicData.address ? tslib_1.__assign({}, this.config.data.department.dynamicData.address) : null, contact1: this.config.data.department.dynamicData.contact1 ? tslib_1.__assign({}, this.config.data.department.dynamicData.contact1) : null, contact2: this.config.data.department.dynamicData.contact2 ? tslib_1.__assign({}, this.config.data.department.dynamicData.contact2) : null, contact3: this.config.data.department.dynamicData.contact3 ? tslib_1.__assign({}, this.config.data.department.dynamicData.contact3) : null, contact4: this.config.data.department.dynamicData.contact4 ? tslib_1.__assign({}, this.config.data.department.dynamicData.contact4) : null, contact5: this.config.data.department.dynamicData.contact5 ? tslib_1.__assign({}, this.config.data.department.dynamicData.contact5) : null }) : {};
        this.validityArray = this.config.data.department.dynamicData ? [true, true, true] : [false, true, true];
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return _this.create(); });
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadataContactInformation.length = 0;
            var tmpArr = [_this.model.contact1, _this.model.contact2, _this.model.contact3, _this.model.contact4, _this.model.contact5];
            tmpArr
                .filter(function (c, index) {
                _this.model['contact' + (index + 1)] = null;
                return c;
            })
                .forEach(function (item, index) {
                _this.model['contact' + (index + 1)] = item;
                _this.addContactInfoGroup();
            });
            _this.departmentDynamicList = [
                _this.config.data.company.dynamicData
            ].concat(_this.config.data.departmentList.map(function (dep) { return dep.dynamicData; }));
            if (_this.config.data.department.enterpriseDepartmentUsers) {
                _this.config.data.department.enterpriseDepartmentUsers.forEach(function (user) {
                    switch (user.type) {
                        case EnterpriseDepartmentUserType.RA:
                            _this.modelResponsible.raIds.push(user.userId);
                            break;
                        case EnterpriseDepartmentUserType.PA:
                            _this.modelResponsible.paIds.push(user.userId);
                            break;
                        default:
                            _this.modelResponsible.others.push(user.userId);
                    }
                });
            }
            _this.metadataLeft = {
                groups: [
                    {
                        title: '企業情報',
                        class: 'form__group--red-title-border',
                        rows: [
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('enterprise', 'name'), { displayStyle: null, validationStyle: null }))[0];
                                row.fields[0].name = 'company.name';
                                row.fields[0].type = 'label';
                                row.fields[0].supplier = function () { return _this.config.data.company.dynamicData.name; };
                                return row;
                            })(),
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('enterprise', 'industry'), { displayStyle: null, validationStyle: null }))[0];
                                row.fields[0].type = 'label';
                                row.fields[0].supplier = function () {
                                    return _this.masterApiService
                                        .getFlattenedIndustryTypes()
                                        .pipe(map(function (industries) {
                                        return (industries.find(function (ind) { return ind.code === _this.config.data.company.dynamicData.industry; }) || { name: '' }).name;
                                    }));
                                };
                                return row;
                            })()
                        ].concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('department', 'name'), null, 'fill'), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('department', 'status'))),
                    },
                    {
                        title: '部署住所情報',
                        class: 'form__group--red-title-border',
                        injectToHeader: _this.deptSelectorTemplate,
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('department', 'address')),
                    },
                    {
                        title: '備考',
                        class: 'form__group--red-title-border',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('department', 'remarks')),
                    },
                ],
            };
            _this.metadataResponsible = {
                groups: [
                    {
                        title: '担当',
                        class: 'form__group--red-title-border',
                        rows: _this.dynamicService.getFormRows({
                            fieldName: 'raIds',
                            fieldType: 'multi-user',
                            label: 'RA',
                            validationStyle: { max: 5 },
                        }).concat(_this.dynamicService.getFormRows({
                            fieldName: 'paIds',
                            fieldType: 'multi-user',
                            label: 'PA',
                            validationStyle: { max: 5 },
                        }), _this.dynamicService.getFormRows({
                            fieldName: 'others',
                            fieldType: 'multi-user',
                            label: 'アシスタント',
                            validationStyle: { max: 10 },
                        })),
                    },
                ],
            };
        });
    };
    CompanyDepartmentCreateDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    CompanyDepartmentCreateDialogComponent.prototype.onAddressSelect = function (address) {
        this.model.address = tslib_1.__assign({}, address);
    };
    CompanyDepartmentCreateDialogComponent.prototype.onValidityChange = function (index, validity) {
        this.validityArray[index] = validity;
        this.valid = this.validityArray.find(function (v) { return !v; }) == null;
    };
    CompanyDepartmentCreateDialogComponent.prototype.create = function () {
        if (!this.valid || this.dialog.inClose) {
            return;
        }
        this.config.data.department.dynamicData = tslib_1.__assign({}, this.config.data.department.dynamicData, this.model);
        var users = this.modelResponsible.raIds.map(function (id) { return ({ type: EnterpriseDepartmentUserType.RA, id: id }); }).concat(this.modelResponsible.paIds.map(function (id) { return ({ type: EnterpriseDepartmentUserType.PA, id: id }); }), this.modelResponsible.others.map(function (id) { return ({ type: EnterpriseDepartmentUserType.アシ, id: id }); }));
        var emptyAddress = { zip: '', prefecture: null, address1: '', address2: '', address3: '' };
        this.config.data.department.dynamicData.address = this.config.data.department.dynamicData.address || emptyAddress;
        var emptyContact = { position: '', name: '', phoneticName: '', tel: '', fax: '', email: '', emailAvailable: null, remarks: '' };
        this.config.data.department.dynamicData.contact1 = this.config.data.department.dynamicData.contact1 || emptyContact;
        this.config.data.department.dynamicData.contact2 = this.config.data.department.dynamicData.contact2 || emptyContact;
        this.config.data.department.dynamicData.contact3 = this.config.data.department.dynamicData.contact3 || emptyContact;
        this.config.data.department.dynamicData.contact4 = this.config.data.department.dynamicData.contact4 || emptyContact;
        this.config.data.department.dynamicData.contact5 = this.config.data.department.dynamicData.contact5 || emptyContact;
        this.close({ dynamicData: this.config.data.department.dynamicData, users: users });
    };
    CompanyDepartmentCreateDialogComponent.prototype.close = function (params) {
        this.dialog.close(params);
    };
    CompanyDepartmentCreateDialogComponent.prototype.addContactInfo = function () {
        if (this.metadataContactInformation.length < this.CONTACT_LIMIT) {
            this.addContactInfoGroup();
            this.model['contact' + (this.metadataContactInformation.length + 1)] = {};
        }
    };
    CompanyDepartmentCreateDialogComponent.prototype.removeContactInfo = function (index) {
        this.model['contact' + (index + 1)] = null;
        for (var i = index + 1; i < this.metadataContactInformation.length; i++) {
            this.model['contact' + i] = this.model['contact' + (i + 1)];
            this.model['contact' + (i + 1)] = null;
        }
        var count = this.metadataContactInformation.length - 1;
        this.metadataContactInformation.length = 0;
        for (var i = 0; i < count; i++) {
            this.addContactInfoGroup();
        }
    };
    CompanyDepartmentCreateDialogComponent.prototype.addContactInfoGroup = function () {
        this.metadataContactInformation.push({
            groups: [
                {
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('department', 'contact' + (this.metadataContactInformation.length + 1))),
                },
            ],
        });
    };
    return CompanyDepartmentCreateDialogComponent;
}());
export { CompanyDepartmentCreateDialogComponent };
