<div
  class="chip"
  [class.chip--selected]="selected"
  [class.chip--disabled]="disabled"
  (click)="toggle()"
>
  <span class="chip__label"
    ><span class="chip__label__checkmark">✔</span>{{ label }}</span
  >
  <span class="chip__remove" (click)="remove($event)">x</span>
</div>
