<form
  [formGroup]="myForm"
  novalidate
  *ngIf="metadata.groups"
  (ngSubmit)="onSubmit($event)"
  class="form {{ metadata.class }}"
  (keydown)="onKey($event)"
  tabindex="-1"
  #form
>
  <button hidden type="submit"></button>
  <ng-container *ngFor="let group of metadata.groups; let gi = index">
    <div
      class="form__group {{ group.class }}"
      *ngIf="group.title || group.injectToHeader"
    >
      <span class="group__header">
        <span class="group__header--title" *ngIf="group.title">{{
          group.title
        }}</span>
        <ng-template
          *ngIf="group.injectToHeader"
          [ngTemplateOutlet]="group.injectToHeader"
        ></ng-template>
      </span>
    </div>
    <div
      class="collapsable__header collapsable__header--{{ gi }}"
      *ngIf="group.collapsable"
    >
      <span class="collapsable__header_title">{{ group.collapsedTitle }}</span>
      <span class="collapsable__header__spacer"></span>
      <button
        class="btn"
        [ngClass]="{
          'btn--dark': !groupsExpanded[gi],
          'btn--white btn--outline-gray': groupsExpanded[gi]
        }"
        (click)="
          groupsExpanded[gi] && group.toggledButtonAction
            ? group.toggledButtonAction()
            : '';
          groupsExpanded[gi] = !groupsExpanded[gi]
        "
      >
        {{
          groupsExpanded[gi]
            ? group.toggledButtonLabel || group.toggleButtonLabel
            : group.toggleButtonLabel
        }}
      </button>
    </div>
    <div
      class="form__group form__group__content {{ group.class }}"
      [class.collapsable__content]="group.collapsable"
      [class.collapsable__content--collapsed]="
        group.collapsable && !groupsExpanded[gi]
      "
    >
      <ng-container *ngFor="let row of group.rows; let i = index">
        <span
          class="group__title group__title--{{ i }} {{ row.class }} {{
            row.showRequired ? 'group__title--required' : ''
          }}"
          *ngIf="row.title && !row.hidden"
        >
          {{ row.title }}
          <span class="group__sub-title" *ngIf="row.subTitle">{{
            row.subTitle
          }}</span></span
        >
        <div
          class="group__field-container group__field-container--{{ i }} {{
            row.class
          }}"
          [ngStyle]="row.style"
          *ngIf="!row.hidden"
        >
          <div
            *ngFor="let field of row.fields | filter: null:null:true"
            class="group__field group__field--{{ field.type }} {{
              field.class
            }} {{ field.multi ? 'group__field--multi' : '' }}"
            [ngStyle]="field.style"
          >
            <!-- <label
              class="field__error"
              *ngIf="
                myForm.controls[field.name]?.errors?.message || null as message
              "
              >{{ message }}</label
            > -->
            <label
              class="field__before-label {{
                !row.showRequired &&
                field.validators?.required &&
                !myForm.controls[field.name]?.disabled
                  ? 'field__before-label--required'
                  : ''
              }}"
              *ngIf="field.labelBefore"
              >{{ field.labelBefore }}</label
            >

            <label
              class="field__input field__input--label"
              *ngIf="field.type === 'label'"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              (click)="field.clickAction ? field.clickAction($event) : ''"
              >{{
                transparentModel[field.name] &&
                transparentModel[field.name].length >
                  (field.style?.toggleLength || labelToggleLength) &&
                !labelsToggled[field.name]
                  ? truncatePipe.transform(
                      transparentModel[field.name],
                      field.style?.toggleLength || labelToggleLength
                    )
                  : transparentModel[field.name]
              }}
              <span
                *ngIf="
                  transparentModel[field.name] &&
                  transparentModel[field.name].length >
                    (field.style?.toggleLength || labelToggleLength)
                "
                class="field__label-toggle"
                (click)="labelsToggled[field.name] = !labelsToggled[field.name]"
                >{{ labelsToggled[field.name] ? "閉じる" : "全て表示"
                }}<span
                  class="arrow"
                  [class.arrow--down]="!labelsToggled[field.name]"
                  [class.arrow--up]="labelsToggled[field.name]"
                ></span></span
            ></label>
            <div
              *ngIf="field.type === 'hr'"
              class="field__input field__input--hr"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
            ></div>
            <input
              class="field__input field__input--number"
              *ngIf="
                field.type === 'number' ||
                field.type === 'year' ||
                field.type === 'month'
              "
              placeholder="{{ field.placeholder }}"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              type="number"
              formControlName="{{ field.name }}"
              (change)="updateModel(field.name)"
              (blur)="field.blur ? field.blur() : onBlur(field.name)"
              (focus)="field.focus ? field.focus() : onFocus(field.name)"
            />
            <input
              class="field__input field__input--text"
              *ngIf="field.type === 'text'"
              placeholder="{{ field.placeholder }}"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              type="{{
                field.validators && field.validators.password
                  ? 'password'
                  : 'text'
              }}"
              formControlName="{{ field.name }}"
              (change)="updateModel(field.name)"
              (blur)="field.blur ? field.blur() : onBlur(field.name)"
              (focus)="field.focus ? field.focus() : onFocus(field.name)"
            />
            <textarea
              class="field__input field__input--textarea"
              *ngIf="field.type === 'textarea'"
              placeholder="{{ field.placeholder }}"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              formControlName="{{ field.name }}"
              (change)="updateModel(field.name)"
              (blur)="field.blur ? field.blur() : onBlur(field.name)"
              (focus)="field.focus ? field.focus() : onFocus(field.name)"
              (keydown.enter)="$event.stopPropagation()"
              cdkTextareaAutosize
              cdkAutosizeMinRows="{{ field.style?.minRows || 2 }}"
              cdkAutosizeMaxRows="{{ field.style?.maxRows || 15 }}"
            ></textarea>
            <ag-select
              class="field__input field__input--dropdown"
              *ngIf="field.type === 'dropdown'"
              placeholder="{{ field.placeholder }}"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              [valueField]="field.valueField"
              [labelField]="field.labelField"
              [options]="field.options"
              [multi]="field.multi"
              [validators]="field.validators"
              [extraClass]="
                field.class?.includes('red-border') ? 'red-border' : null
              "
              formControlName="{{ field.name }}"
              (valueChange)="updateModel(field.name)"
            ></ag-select>
            <div
              *ngIf="field.type === 'radio'"
              class="group__field group__field--{{ field.type }}"
            >
              <div
                class="group__field group__field--{{ field.type }}"
                *ngFor="let option of field.options"
              >
                <input
                  #input
                  class="field__input field__input--radio"
                  [value]="field.valueField ? option[field.valueField] : option"
                  [hidden]="
                    field.hidden ||
                    (field.showOn && !isDependencyOk(field.showOn))
                  "
                  type="radio"
                  name="{{ field.name }}"
                  formControlName="{{ field.name }}"
                  (change)="updateModel(field.name)"
                  (blur)="field.blur ? field.blur() : onBlur(field.name)"
                  (focus)="field.focus ? field.focus() : onFocus(field.name)"
                />
                <label
                  class="field__after-label"
                  (click)="input.click()"
                  *ngIf="
                    (field.labelField && option[field.labelField]) || option
                  "
                  [hidden]="
                    field.hidden ||
                    (field.showOn && !isDependencyOk(field.showOn))
                  "
                  >{{
                    field.labelField ? option[field.labelField] : option
                  }}</label
                >
              </div>
              <div
                class="group__field group__field--{{ field.type }}"
                *ngIf="field.specialOption"
              >
                <input
                  #specialInput
                  class="field__input field__input--radio"
                  [value]="field.specialOption"
                  [hidden]="
                    field.hidden ||
                    (field.showOn && !isDependencyOk(field.showOn))
                  "
                  type="radio"
                  name="{{ field.name }}"
                  formControlName="{{ field.name }}"
                  (change)="updateModel(field.name)"
                  (blur)="field.blur ? field.blur() : onBlur(field.name)"
                  (focus)="field.focus ? field.focus() : onFocus(field.name)"
                />
                <label
                  class="field__after-label"
                  (click)="specialInput.click()"
                  [hidden]="
                    field.hidden ||
                    (field.showOn && !isDependencyOk(field.showOn))
                  "
                  >{{ field.specialOption }}</label
                >
                <input
                  class="field__input field__input--text"
                  [hidden]="
                    field.hidden ||
                    (field.showOn && !isDependencyOk(field.showOn))
                  "
                  type="text"
                  formControlName="{{ field.name + '.special' }}"
                  (change)="updateModel(field.name)"
                  (blur)="field.blur ? field.blur() : onBlur(field.name)"
                  (focus)="field.focus ? field.focus() : onFocus(field.name)"
                />
              </div>
            </div>
            <input
              class="field__input field__input--checkbox"
              *ngIf="field.type === 'checkbox' && !field.multi"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              type="checkbox"
              formControlName="{{ field.name }}"
              (change)="updateModel(field.name)"
              (blur)="field.blur ? field.blur() : onBlur(field.name)"
              (focus)="field.focus ? field.focus() : onFocus(field.name)"
            />
            <div
              class="group__field group__field--{{ field.type }}"
              *ngIf="field.type === 'checkbox' && field.multi"
            >
              <div
                class="group__field group__field--{{ field.type }}"
                formArrayName="{{ field.name }}"
                *ngFor="let option of field.options; let i = index"
              >
                <input
                  #input
                  class="field__input field__input--checkbox"
                  [hidden]="
                    field.hidden ||
                    (field.showOn && !isDependencyOk(field.showOn))
                  "
                  type="checkbox"
                  [attr.data-value]="option[field.valueField]"
                  formControlName="{{ i }}"
                  (change)="input.blur(); updateModel(field.name)"
                  (blur)="field.blur ? field.blur() : onBlur(field.name)"
                  (focus)="field.focus ? field.focus() : onFocus(field.name)"
                />
                <label
                  class="field__after-label"
                  (click)="input.click()"
                  *ngIf="
                    (field.labelField && option[field.labelField]) || option
                  "
                  [hidden]="
                    field.hidden ||
                    (field.showOn && !isDependencyOk(field.showOn))
                  "
                  >{{
                    field.labelField ? option[field.labelField] : option
                  }}</label
                >
              </div>
            </div>
            <input
              class="field__input field__input--date"
              *ngIf="field.type === 'date' || field.type === 'yearmonth'"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              type="{{ field.type === 'date' ? 'date' : 'month' }}"
              formControlName="{{ field.name }}"
              (change)="updateModel(field.name)"
              (blur)="field.blur ? field.blur() : onBlur(field.name)"
              (focus)="field.focus ? field.focus() : onFocus(field.name)"
              [max]="field.validators.maxValue"
              [min]="field.validators.minValue"
            />
            <input
              class="field__input field__input--time"
              *ngIf="field.type === 'time'"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              type="time"
              formControlName="{{ field.name }}"
              (change)="updateModel(field.name)"
              (blur)="field.blur ? field.blur() : onBlur(field.name)"
              (focus)="field.focus ? field.focus() : onFocus(field.name)"
            />
            <input
              class="field__input field__input--zip-{{
                field.name[field.name.length - 1]
              }}"
              *ngIf="field.type === 'zip'"
              pattern="\d*"
              [maxlength]="field.name.endsWith('0') ? '3' : '4'"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              type="text"
              formControlName="{{ field.name }}"
              (change)="updateModel(field.name)"
              (blur)="field.blur ? field.blur() : onBlur(field.name)"
              (focus)="field.focus ? field.focus() : onFocus(field.name)"
            />
            <ag-autocomplete
              class="field__input field__input--autocomplete"
              *ngIf="field.type === 'autocomplete'"
              placeholder="{{ field.placeholder }}"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              [valueField]="field.valueField"
              [labelField]="field.labelField"
              [multi]="field.multi"
              [options]="field.options"
              [supplier]="field.supplier"
              [validators]="field.validators"
              [formControlName]="field.name"
              [filters]="field.filters"
              [extraClass]="field.class"
              (valueChange)="updateModel(field.name)"
              [manualInputOn]="field.manualInputOn"
              [customTooltipErrorMessage]="field.customTooltipErrorMessage"
              [getValue]="boundGetValue"
              [setValue]="boundSetValue"
              [hideBtn]="
                (field.multi && field.specialOption != null) ||
                (!field.multi && field.specialOption == null)
              "
            ></ag-autocomplete>
            <ag-ordered-list
              class="field__input field__input--list"
              *ngIf="field.type === 'list'"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              [valueField]="field.valueField"
              [labelField]="field.labelField"
              [validators]="field.validators"
              [formControlName]="field.name"
              (valueChange)="updateModel(field.name)"
            ></ag-ordered-list>

            <label
              class="field__after-label"
              *ngIf="field.labelAfter"
              [hidden]="
                field.hidden || (field.showOn && !isDependencyOk(field.showOn))
              "
              >{{ field.labelAfter }}</label
            >

            <ng-container *ngIf="field.actions as fieldActions">
              <div class="field__actions {{ field.actionsClass }}">
                <ng-container
                  *ngFor="let action of fieldActions || []; let i = index"
                >
                  <button
                    *ngIf="
                      action.type !== 'SELECT' &&
                      (action.type !== 'REVEAL' ||
                        field.hidden ||
                        (field.showOn && !isDependencyOk(field.showOn)))
                    "
                    class="field__action field__action--{{
                      action.type | lowercase
                    }} btn btn--small btn--dark"
                    [disabled]="
                      metadata.disabled ||
                      field.disabled ||
                      (field.allowOn && !isDependencyOk(field.allowOn)) ||
                      (action.allowOn && !isDependencyOk(action.allowOn))
                    "
                    (click)="
                      metadata.disabled ||
                      field.disabled ||
                      (field.allowOn && !isDependencyOk(field.allowOn)) ||
                      (action.allowOn && !isDependencyOk(action.allowOn))
                        ? ''
                        : onAction(field.name, action)
                    "
                  >
                    {{ action.title }}
                  </button>
                  <ag-select
                    *ngIf="action.type === 'SELECT'"
                    class="field__action field__action--{{
                      action.type | lowercase
                    }}"
                    placeholder="{{ action.title }}"
                    extraClass="simple"
                    [readonly]="
                      metadata.disabled ||
                      field.disabled ||
                      (field.allowOn && !isDependencyOk(field.allowOn)) ||
                      (action.allowOn && !isDependencyOk(action.allowOn))
                    "
                    [options]="action.options"
                    (valueChange)="
                      transparentModel[field.name + '.actions.' + i] = $event;
                      onAction(field.name, action, $event)
                    "
                    [value]="transparentModel[field.name + '.actions.' + i]"
                  ></ag-select>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</form>
