<!-- <p class="title">Machクイック操作</p> -->
<div class="error" *ngIf="errorState">
  一致する求職者、企業、求人、進捗がありません。
</div>
<div
  class="multi-select"
  *ngIf="selectableStudents?.length > 1 && !selectedStudent"
>
  <p class="multi-select__note">複数の求職者が一致しています。</p>
  <p class="multi-select__note">Machで操作してください。</p>
  <div
    class="multi-select__link link"
    *ngFor="let element of selectableStudents"
    (click)="selectStudent(element)"
  >
    {{ element.frontId }}
    {{ element.dynamicData.lastName + element.dynamicData.firstName }}
  </div>
</div>
<ng-container *ngIf="!selectableStudents?.length || selectedStudent">
  <div
    class="card student"
    *ngIf="selectedStudent && selectedStudent.dynamicData"
  >
    <div class="card__line">
      <div class="card__col">
        {{
          selectedStudent.frontId +
            "  [" +
            selectedStudent.dynamicData.rank +
            "]"
        }}
      </div>
      <div class="card__col card__col--sub">
        マイページ：
        <a
          (click)="openBrowser('students/' + selectedStudent.id)"
          class="link"
          >{{ loggedInText }}</a
        >
        最終コンタクト：
        {{
          selectedStudent.lastContactDate
            ? (selectedStudent.lastContactDate | safeDate: "yyyy/MM/dd")
            : "-"
        }}
      </div>
    </div>
    <div class="card__line card__line--bold card__line--large">
      {{
        selectedStudent.dynamicData.lastName +
          selectedStudent.dynamicData.firstName
      }}
    </div>
    <div class="card__line">
      {{
        selectedStudent.dynamicData.phoneticLastName +
          selectedStudent.dynamicData.phoneticFirstName +
          "  "
      }}{{ (selectedStudent.dynamicData.birthday | age) || "-" }}歳{{
        "  " + selectedStudent.dynamicData.gender
      }}
    </div>
    <div class="card__line">
      [{{
        selectedStudent.dynamicData.academicFieldType
          ? selectedStudent.dynamicData.academicFieldType[0]
          : "-"
      }}] [{{
        (
          +selectedStudent.dynamicData.academicHistory1?.graduateYear +
          (selectedStudent.dynamicData.academicHistory1?.graduateMonth > 3
            ? 1
            : 0) +
          ""
        ).substring(2)
      }}
      {{
        selectedStudent.dynamicData.academicHistory1?.graduateType
          ? selectedStudent.dynamicData.academicHistory1.graduateType[0]
          : "-"
      }}] [{{
        selectedStudent.dynamicData.universityLevel
          ? selectedStudent.dynamicData.universityLevel[0]
          : "-"
      }}] {{ selectedStudent.dynamicData.academicHistory1?.schoolName || "" }}
      {{ selectedStudent.dynamicData.academicHistory1?.departmentName || "" }}
      {{
        selectedStudent.dynamicData.academicHistory1?.subDepartmentName || ""
      }}
    </div>
    <div class="card__line--section">
      携帯TEL {{ selectedStudent.dynamicData.mobileTel?.tel || "-"
      }}{{
        selectedStudent.dynamicData.mobileTel?.telAvailable ? " [不可]" : ""
      }}
    </div>
    <div class="card__line">
      自宅TEL {{ selectedStudent.dynamicData.houseTel?.tel || "-"
      }}{{
        selectedStudent.dynamicData.houseTel?.telAvailable ? " [不可]" : ""
      }}
    </div>
  </div>
  <div
    class="card editor editor--regstatus"
    *ngIf="
      studentEditMeta &&
      selectedStudent &&
      selectedDynamic.registrationStatus === '仮登録'
    "
  >
    <div class="card__line">
      <div class="card__col">
        <ag-dynamic-form
          #regForm
          [metadata]="studentEditMeta"
          [model]="selectedStudent.dynamicData"
        ></ag-dynamic-form>
      </div>
      <div class="card__col  card__col--sub"></div>
    </div>
    <div class="card__line">
      <div class="card__col"></div>
      <div class="card__col  card__col--sub">
        <button
          class="btn btn--outlook"
          [disabled]="!regForm?.myForm.valid"
          (click)="regForm?.myForm.valid ? updateStudent() : ''"
        >
          保存
        </button>
      </div>
    </div>
  </div>
  <div
    class="card editor editor--contact"
    *ngIf="
      contactMeta &&
      selectedStudent &&
      selectedDynamic.registrationStatus === '仮登録'
    "
  >
    <div class="card__line card__line--reverse">
      <ag-dynamic-form
        #contactForm
        [metadata]="contactMeta"
        [model]="contactModel"
      ></ag-dynamic-form>
    </div>
    <div class="card__line">
      <div class="card__col"></div>
      <div class="card__col card__col--sub">
        <a
          class="link"
          (click)="openBrowser('students/' + selectedStudent.id + '/contacts')"
          >対応履歴を見る</a
        >
        <button
          class="btn btn--outlook"
          [disabled]="!contactForm?.myForm.valid"
          (click)="contactForm?.myForm.valid ? createContact() : ''"
        >
          保存
        </button>
      </div>
    </div>
  </div>
  <div class="card company" *ngFor="let company of companies">
    <div class="card__line">
      {{ company.frontId + "  " + company.dynamicData.enterpriseNo }}
    </div>
    <div class="card__line card__line--bold card__line--large">
      {{ company.dynamicData.name }}
    </div>
    <div class="card__line">
      <div class="card__col card__col--title">顧客ステータス</div>
      <div class="card__col">
        [AG] {{ company.dynamicData.agClientStatus }}
        <span *ngIf="jobs?.length || progresses?.length" class="bordered"
          >オーダー</span
        ><br />
        [DR] {{ company.dynamicData.drClientStatus }}
      </div>
      <div class="card__col card__col--title">離任Kランク</div>
      <div class="card__col">
        {{ company.dynamicData.rininKRank }}
      </div>
      <div class="card__col card__col--title">企業担当者情報</div>
      <div class="card__col">
        {{ userInjector.getUserTeamNameById(company.user?.id) | async }}
      </div>
      <div class="card__col card__col--title"></div>
      <div class="card__col card__col--sub">
        <a
          class="btn btn--outlook"
          (click)="openBrowser('enterprises/' + company.id + '/jobs')"
          >求人一覧を確認</a
        ><a
          class="btn btn--outlook"
          (click)="openBrowser('enterprises/' + company.id)"
          >企業詳細を確認</a
        >
      </div>
    </div>
  </div>
  <div class="card job" *ngFor="let job of jobs">
    <div class="card__line" *ngIf="job.dynamicData.recommendationDateKind">
      [{{ job.dynamicData.recommendationDateKind }}] 推薦期限
      {{ job.dynamicData.recommendationDate | safeDate: "MM/dd" }}
    </div>
    <div class="card__line">
      <div class="card__col card__col--title">{{ job.frontEnterpriseId }}</div>
      <div class="card__col">
        <a
          class="link"
          (click)="openBrowser('enterprises/' + job.enterpriseId)"
          >{{ job.enterpriseName }}</a
        >
      </div>
      <div class="card__col card__col--title">{{ job.frontId }}</div>
      <div class="card__col">
        <a class="link" (click)="openBrowser('jobs/' + job.id)">{{
          job.dynamicData.position
        }}</a>
      </div>
    </div>
  </div>
  <div
    class="card progress"
    *ngFor="
      let progress of selectedDynamic.registrationStatus !== '仮登録'
        ? progresses
        : []
    "
  >
    <div class="card__line">
      <div class="card__col">
        <a
          class="link card__line--bold"
          (click)="openBrowser('progresses/' + progress.id)"
          >{{
            progress.type === 0 ? "[AG]" : progress.type === 1 ? "[Plus]" : ""
          }}{{
            getStatusLabel(progress.status, progress.seminarType, progress.n)
          }}</a
        >
        <input
          class="student-delay outlook"
          type="checkbox"
          [disabled]="
            progress.isStudentDelayActionInProgress ||
            (progress.status !== 70 &&
              progress.status !== 90 &&
              progress.status !== 100)
          "
          [checked]="!!progress.isStudentDelay"
          (click)="onStudentDelayClick(progress, $event)"
        />学生起因
      </div>
      <div class="card__col card__col--align-right">
        {{ progress.updatedAt | safeDate: "yyyy-MM-dd (E) HH:mm" }}
      </div>
    </div>
    <div class="card__line">
      <div class="card__col">
        {{ ((progress.student$ | async) || {})?.frontId }}
      </div>
      <div class="card__col">
        <a class="link" (click)="openBrowser('students/' + progress.studentId)"
          >{{ ((progress.student$ | async) || {})?.dynamicData?.lastName
          }}{{ ((progress.student$ | async) || {})?.dynamicData?.firstName }}</a
        >
      </div>
    </div>
    <div class="card__line">
      <div class="card__col">
        {{ ((progress.enterprise$ | async) || {})?.frontId }}
      </div>
      <div class="card__col">
        <a
          class="link"
          (click)="openBrowser('enterprises/' + progress.enterpriseId)"
          >{{ ((progress.enterprise$ | async) || {})?.dynamicData?.name }}</a
        >
      </div>
    </div>
    <div class="card__line">
      <div class="card__col">
        {{ ((progress.job$ | async) || {})?.frontId }}
      </div>
      <div class="card__col">
        <a class="link" (click)="openBrowser('jobs/' + progress.jobId)"
          >{{ ((progress.job$ | async) || {})?.dynamicData?.position }} [{{
            ((progress.job$ | async) || {})?.dynamicData?.status
          }}]</a
        >
      </div>
    </div>

    <div class="card__line">
      <div class="card__col btn-group">
        <ng-container
          *ngFor="
            let actionGroup of PROGRESS_STATUSES[progress.status].actions;
            index as i
          "
        >
          <button
            *ngFor="
              let action of actionGroup
                | filter
                  : [PROGRESS_ACTION_TYPE.MAIL, PROGRESS_ACTION_TYPE.DIALOG]
                  : 'type'
                  : true
            "
            [disabled]="
              eventEditors[progress.id] || progress.isOperationActionInProgress
            "
            class="btn-group__btn btn-group__btn--mt-7 btn-group__btn--mr-10 btn btn--outlook"
            (click)="onProgressActionClick(progress, action)"
          >
            {{ action.label(progress.seminarType, progress.n) }}
          </button>
        </ng-container>
      </div>
      <div class="card__col btn-group btn-group--align-right">
        <button
          class="btn-group__btn btn-group__btn--mt-7 btn btn--outlook"
          [disabled]="
            progress.isOperationActionInProgress ||
            (eventEditors[progress.id]?.inSave ||
              ((progress.status === 70 || progress.status === 100) &&
                eventEditors[progress.id] &&
                !eventEditors[progress.id].seminarAt))
          "
          (click)="onProgressSaveClick(progress)"
        >
          備考を保存
        </button>
      </div>
    </div>

    <div
      *ngIf="eventEditors[progress.id] && editorMeta[progress.id]"
      class="event-editor card__line"
    >
      <div class="card__col">
        <ag-dynamic-form
          [metadata]="editorMeta[progress.id]"
          [model]="eventEditors[progress.id]"
        ></ag-dynamic-form>
        <div
          *ngIf="progress.status === 70 || progress.status === 100"
          class="checkbox-group"
        >
          <input
            type="checkbox"
            class="outlook"
            [(ngModel)]="eventEditors[progress.id].seminarAtMailSendFlag"
          />
          <span>日程確定メール送信不要</span>
        </div>
      </div>
      <div class="card__col">
        <span
          class="close"
          (click)="onProgressEditorCancelClick(progress.id)"
        ></span>
      </div>
    </div>

    <div class="message">
      <textarea
        class="message__textarea outlook"
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        [maxLength]="400"
        [(ngModel)]="progress.latestRemarks"
      ></textarea>
    </div>
  </div>
</ng-container>
