import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
var CompanyJobsTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyJobsTabComponent, _super);
    function CompanyJobsTabComponent() {
        var _this = _super.call(this) || this;
        _this.jobSelected = new EventEmitter();
        _this.requestObject = {};
        _this.listConfiguration = { annualHolidays: false, salary: false, favorites: false, checkbox: false, filterOpen: true };
        return _this;
    }
    CompanyJobsTabComponent.prototype.ngOnChanges = function (changes) {
        if (this.companyId != null && changes['companyId'].currentValue !== changes['companyId'].previousValue) {
            this.requestObject = {
                enterpriseId: this.companyId,
            };
        }
    };
    return CompanyJobsTabComponent;
}(Tab));
export { CompanyJobsTabComponent };
