<ag-dynamic-form
  #form
  class="form"
  [metadata]="metadata"
  [model]="model"
  [hidden]="!isCreateInProgress && !isEditInProgress"
></ag-dynamic-form>

<ng-container *ngIf="!isEditInProgress && !isCreateInProgress">
  <span class="date">
    {{ memo.registerDate | safeDate: "yyyy/MM/dd (E)" }}
  </span>
  <span class="name" *ngIf="findUserTeam(memo.userId) | async as info">
    {{ info.userName + " " + info.teamName }}
  </span>
  <div class="actions">
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="edit()"
    >
      編集
    </button>
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="delete()"
    >
      削除
    </button>
  </div>
  <span class="text">
    {{
      memo.situationMemo || memo.innerMemo || memo.memo | truncate: truncateAt
    }}
  </span>
  <div
    *ngIf="
      (memo.situationMemo || memo.innerMemo || memo.memo || '').length >
      truncateAt
    "
    class="footer"
  >
    <div class="footer__open-btn" (click)="toggleText()">
      <div class="footer__open-btn__label">
        {{ isOpen ? "閉じる" : "開く" }}
      </div>
      <div
        class="footer__open-btn__arrow"
        [class.footer__open-btn__arrow--down]="!isOpen"
        [class.footer__open-btn__arrow--up]="isOpen"
      ></div>
    </div>
  </div>
</ng-container>

<div *ngIf="isCreateInProgress || isEditInProgress" class="footer">
  <button
    class="footer__cancel-btn btn btn--medium btn--white btn--outline-gray"
    (click)="isCreateInProgress ? cancelCreate() : cancelEdit()"
  >
    キャンセル
  </button>
  <button
    class="footer__save-btn btn btn--medium btn--green"
    [disabled]="!form?.myForm.valid"
    (click)="isCreateInProgress ? create() : update()"
  >
    {{ isCreateInProgress ? "作成" : "更新" }}
  </button>
</div>
