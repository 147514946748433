<div
  *ngIf="tableConfig"
  class="table {{ tableConfig.class }}"
  [ngClass]="!tableConfig.head.sticky ? 'scrollable-part ' + scrollerClass : ''"
  [ngStyle]="tableConfig.style"
  (scroll)="!tableConfig.head.sticky ? onScroll($event) : ''"
>
  <div
    class="table-head {{ tableConfig.head.class }}"
    [ngStyle]="tableConfig.head.style"
  >
    <div
      *ngFor="let row of tableConfig.head.config | filter: true:'hidden':true"
      class="table-head__row"
    >
      <div *ngIf="tableConfig.body?.checkbox" class="checkbox"></div>
      <div
        *ngFor="let column of row.columns"
        class="table-head__column {{ column.class }}"
        [ngStyle]="column.style"
      >
        <div
          *ngFor="let field of column.fields | filter: true:'hidden':true"
          class="table-head__field"
          [ngClass]="{
            'table-head__field--sorted': sorting.field === field.name,
            'table-head__field--sortable': field.sortable !== false
          }"
        >
          <span class="table-head__field__text">{{
            field.title != null ? field.title : field.name
          }}</span>
          <span
            *ngIf="field.sortable !== false"
            class="table-head__field__sort"
            [ngClass]="{
              'table-head__field__sort--up':
                sorting.field === field.name && sorting.order === 'asc',
              'table-head__field__sort--down':
                sorting.field !== field.name || sorting.order !== 'asc'
            }"
            (click)="onSortChange(field.name)"
          ></span>
        </div>
      </div>
      <span class="arrow" *ngIf="tableConfig.body?.arrow"></span>
    </div>
  </div>

  <div
    class="table-body-wrapper"
    [ngClass]="
      tableConfig.head.sticky ? 'scrollable-part ' + scrollerClass : ''
    "
    (scroll)="tableConfig.head.sticky ? onScroll($event) : ''"
  >
    <div
      *ngFor="let data of content"
      class="table-body {{ tableConfig.body?.class }}"
      [ngStyle]="tableConfig.body?.style"
      (click)="onItemSelect(data)"
    >
      <div
        *ngFor="let row of tableConfig.head.config; let i = index"
        class="table-body__row"
        (click)="onRowClick(row, $event, data)"
        [ngStyle]="dynamicStyle(row.dynamicStyle, data)"
      >
        <div
          *ngIf="i === 0 && tableConfig.body?.checkbox"
          class="checkbox"
          (click)="onItemCheck(data, $event)"
        >
          <input
            type="checkbox"
            *ngIf="i === 0"
            [disabled]="allChecked"
            [checked]="allChecked || checkedItems.includes(data)"
          />
        </div>
        <div
          *ngFor="let column of row.columns"
          class="table-body__column {{ column.class }}"
          [ngStyle]="column.allStyle"
        >
          <div
            *ngFor="let field of column.fields"
            [ngStyle]="field.style"
            (click)="onFieldClick(field, $event, data)"
          >
            <ng-template
              *ngIf="field.cellTemplate"
              [ngTemplateOutlet]="field.cellTemplate"
              [ngTemplateOutletContext]="{ $implicit: data, data: data }"
            ></ng-template>
            <span *ngIf="!field.cellTemplate">{{
              field.formatter
                ? field.formatter(data)
                : defaultFormatter(data, field.name)
            }}</span>
          </div>
        </div>
        <span
          *ngIf="i === 0 && tableConfig.body?.arrow"
          class="arrow arrow--right"
        >
        </span>
      </div>
    </div>
    <div *ngIf="loading" class="loading">
      <svg class="loading__spinner" viewBox="0 0 50 50">
        <circle class="loading__spinner__path" cx="25" cy="25" r="20"></circle>
      </svg>
      <span class="loading__message">さらに表示</span>
    </div>
  </div>
</div>
