/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-floater.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./page-floater.component";
var styles_PageFloaterComponent = [i0.styles];
var RenderType_PageFloaterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageFloaterComponent, data: {} });
export { RenderType_PageFloaterComponent as RenderType_PageFloaterComponent };
function View_PageFloaterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "side-actions__item"]], [[2, "side-actions__item--active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedSideActionIndex = _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "side-actions__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedSideActionIndex === _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.label; _ck(_v, 2, 0, currVal_1); }); }
function View_PageFloaterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "side-actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageFloaterComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sideActions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PageFloaterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "control"]], [[2, "control--hidden", null], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.grow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "control__arrow control__arrow--left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "control control--shrink"]], [[2, "control--hidden", null], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shrink($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "control__arrow control__arrow--left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageFloaterComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.sideActions && (_co.sideActions.length > 1)); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.phaseIndex >= (_co.phases.length - 1)) || (_co.disableFullScreen && (_co.phases[(_co.phaseIndex + 1)] === "100%"))); var currVal_1 = ((_co.phases[(_co.phaseIndex + 1)] === "100%") ? (_co.fullModeUrl || "#") : (_co.origUrl || "#")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.phaseIndex < 0); var currVal_3 = (_co.origUrl || "#"); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_PageFloaterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-floater", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PageFloaterComponent_0, RenderType_PageFloaterComponent)), i1.ɵdid(1, 245760, null, 0, i3.PageFloaterComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageFloaterComponentNgFactory = i1.ɵccf("ag-page-floater", i3.PageFloaterComponent, View_PageFloaterComponent_Host_0, { closeOnShrink: "closeOnShrink", sideActions: "sideActions", origUrl: "origUrl", fullModeUrl: "fullModeUrl", disableFullScreen: "disableFullScreen", selectedSideActionIndex: "selectedSideActionIndex" }, { selectedSideActionIndexChange: "selectedSideActionIndexChange", transformed: "transformed" }, ["*"]);
export { PageFloaterComponentNgFactory as PageFloaterComponentNgFactory };
