/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-import-compare.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../../shared/pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "./student-import-compare.component";
import * as i9 from "../../../../shared/services/api/student-api.service";
import * as i10 from "../../../../shared/services/api/dynamic-field.service";
import * as i11 from "../../../../shared/services/api/master-api.service";
var styles_StudentImportCompareComponent = [i0.styles];
var RenderType_StudentImportCompareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentImportCompareComponent, data: {} });
export { RenderType_StudentImportCompareComponent as RenderType_StudentImportCompareComponent };
function View_StudentImportCompareComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "changed"], [null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onModelChange(_co.basicMetaGroups, $event) !== false);
        ad = (pd_0 && ad);
    } if (("validated" === en)) {
        var pd_1 = (_co.onValidityChange($event, 0) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, [[4, 4], ["basicDbForm", 4]], 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated", changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value.dbMeta; var currVal_1 = ((_co.compareData == null) ? null : ((_co.compareData.dbStudent == null) ? null : _co.compareData.dbStudent.dynamicData)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentImportCompareComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "arrow-badge badge badge--dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyRow(_co.basicMetaGroups, _v.parent.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "arrow-badge__arrow arrow-badge__arrow--left"]], null, null, null, null, null))], null, null); }
function View_StudentImportCompareComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "changed"], [null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onModelChange(_co.basicMetaGroups, $event) !== false);
        ad = (pd_0 && ad);
    } if (("validated" === en)) {
        var pd_1 = (_co.onValidityChange($event, 1) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, [[3, 4], ["basicCsvForm", 4]], 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated", changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value.csvMeta; var currVal_1 = ((_co.compareData == null) ? null : ((_co.compareData.csvStudent == null) ? null : _co.compareData.csvStudent.dynamicData)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentImportCompareComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [["class", "compare-table__row compare-table__row--border-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["class", "compare-table__col-meta compare-table__col-meta--with-label"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_3)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "td", [["class", "compare-table__col-meta compare-table__col-meta--arrow compare-table__col-meta--with-border"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_4)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [["class", "compare-table__col-meta"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_5)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value.dbMeta; _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_v.context.$implicit.key !== "registrationRouteHistory") && (_v.context.$implicit.key !== "registrationStatus")) && (_v.context.$implicit.key !== "contactNotes")); _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.value.csvMeta; _ck(_v, 9, 0, currVal_2); }, null); }
function View_StudentImportCompareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u57FA\u672C\u60C5\u5831"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "table", [["class", "compare-table"], ["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "th", [["class", "compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--with-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u8077\u8005\u60C5\u5831"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "th", [["class", "compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "th", [["class", "compare-table__col-meta compare-table__col-meta--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u53D6\u308A\u8FBC\u307F\u60C5\u5831"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StudentImportCompareComponent_2)), i1.ɵdid(14, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i7.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.basicMetaGroups, _co.asIsOrder)); _ck(_v, 14, 0, currVal_0); }, null); }
function View_StudentImportCompareComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "changed"], [null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onModelChange(_co.differenceMetaGroups, $event) !== false);
        ad = (pd_0 && ad);
    } if (("validated" === en)) {
        var pd_1 = (_co.onValidityChange($event, 2) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, [[2, 4], ["diffDbForm", 4]], 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated", changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value.dbMeta; var currVal_1 = ((_co.compareData == null) ? null : ((_co.compareData.dbStudent == null) ? null : _co.compareData.dbStudent.dynamicData)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentImportCompareComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "arrow-badge badge badge--dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyRow(_co.differenceMetaGroups, _v.parent.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "arrow-badge__arrow arrow-badge__arrow--left"]], null, null, null, null, null))], null, null); }
function View_StudentImportCompareComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "changed"], [null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onModelChange(_co.differenceMetaGroups, $event) !== false);
        ad = (pd_0 && ad);
    } if (("validated" === en)) {
        var pd_1 = (_co.onValidityChange($event, 3) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, [[1, 4], ["diffCsvForm", 4]], 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated", changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value.csvMeta; var currVal_1 = ((_co.compareData == null) ? null : ((_co.compareData.csvStudent == null) ? null : _co.compareData.csvStudent.dynamicData)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentImportCompareComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [["class", "compare-table__col-meta compare-table__col-meta--with-label"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_8)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "td", [["class", "compare-table__col-meta compare-table__col-meta--arrow"]], [[2, "compare-table__col-meta--with-border", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_9)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [["class", "compare-table__col-meta"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_10)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.value.dbMeta; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.toShowCopy(_v.context.$implicit.value.csvMeta); _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.value.csvMeta; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.toShowCopy(_v.context.$implicit.value.csvMeta); _ck(_v, 4, 0, currVal_1); }); }
function View_StudentImportCompareComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u5DEE\u5206\u60C5\u5831"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "table", [["class", "compare-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "th", [["class", "compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--with-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u8077\u8005\u60C5\u5831"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "th", [["class", "compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "th", [["class", "compare-table__col-meta compare-table__col-meta--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u53D6\u308A\u8FBC\u307F\u60C5\u5831"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StudentImportCompareComponent_7)), i1.ɵdid(14, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i7.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.differenceMetaGroups, _co.asIsOrder)); _ck(_v, 14, 0, currVal_0); }, null); }
export function View_StudentImportCompareComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { diffCsvForm: 0 }), i1.ɵqud(671088640, 2, { diffDbForm: 0 }), i1.ɵqud(671088640, 3, { basicCsvForm: 0 }), i1.ɵqud(671088640, 4, { basicDbForm: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u53D6\u308A\u8FBC\u307F\u6BD4\u8F03\u5B9F\u884C "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentImportCompareComponent_6)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "footer__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "footer__btn btn--larger btn btn--blue"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.update() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u4FDD\u5B58 "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "footer__btn btn--larger btn btn--white btn--outline-red"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u524A\u9664 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.basicMetaGroups && _co.compareData); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.differenceMetaGroups && _co.compareData); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.compareData || !_co.validity); _ck(_v, 13, 0, currVal_2); var currVal_3 = !_co.compareData; _ck(_v, 15, 0, currVal_3); }); }
export function View_StudentImportCompareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-student-import-compare", [], null, null, null, View_StudentImportCompareComponent_0, RenderType_StudentImportCompareComponent)), i1.ɵdid(1, 573440, null, 0, i8.StudentImportCompareComponent, [i9.StudentApiService, i10.DynamicFieldService, i11.MasterApiService, i1.ChangeDetectorRef, i5.SafeDatePipe], null, null)], null, null); }
var StudentImportCompareComponentNgFactory = i1.ɵccf("ag-student-import-compare", i8.StudentImportCompareComponent, View_StudentImportCompareComponent_Host_0, { studentImportErrorId: "studentImportErrorId", duplicatedStudentId: "duplicatedStudentId", errorType: "errorType" }, { closeWithRefresh: "closeWithRefresh" }, []);
export { StudentImportCompareComponentNgFactory as StudentImportCompareComponentNgFactory };
