import * as tslib_1 from "tslib";
import { environment } from '@agent-ds/environments/environment';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupType, MailSendConfig } from '../config';
var CompanySendConfig = /** @class */ (function (_super) {
    tslib_1.__extends(CompanySendConfig, _super);
    function CompanySendConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CompanySendConfig.prototype.getTemplateTypeId = function () {
        return [16];
    };
    CompanySendConfig.prototype.getPopupConfig = function () {
        return [{ type: MailPopupType.DEPARTMENT }];
    };
    CompanySendConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.enterpriseId = params.enterpriseId;
        }
        _super.prototype.setParams.call(this, params);
    };
    CompanySendConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.contacts || !this.sender.model.enterprise || !this.sender.model.department) {
            return;
        }
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        var enterprise = this.sender.model.enterprise;
        var department = this.sender.model.department;
        metaData.groups[0].rows.push({
            title: 'To (企業・部署)',
            showRequired: true,
            class: 'ou',
            fields: [
                {
                    name: 'enterpriseName',
                    type: 'label',
                    class: 'pre',
                    supplier: function () {
                        return enterprise.frontId + " " + enterprise.dynamicData.name + enterprise.dynamicData.enterpriseNo + "\n" + department.dynamicData.name;
                    },
                },
                !this.sender.inConfirm
                    ? {
                        name: 'to',
                        type: 'checkbox',
                        labelField: 'label',
                        valueField: 'value',
                        class: 'multi-ou',
                        style: { 'margin-left': '29px' },
                        multi: true,
                        options: department
                            ? this.contacts.map(function (c) {
                                return {
                                    label: c.name + " <" + c.email + ">",
                                    value: c.email,
                                };
                            })
                            : [],
                        validators: { required: true },
                    }
                    : {
                        name: 'to',
                        type: 'label',
                        supplier: function () { return _this.contacts.filter(function (c) { return _this.sender.model.to.includes(c.email); }).map(function (c) { return c.name + " <" + c.email + ">"; }); },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (企業、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            // CCの企業部分のフォーム
            if (!_this.sender.inConfirm) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'checkbox',
                    labelField: 'label',
                    valueField: 'value',
                    labelBefore: '企業',
                    class: 'full multi-ou sticky-label',
                    multi: true,
                    options: department
                        ? _this.contacts.map(function (c) {
                            return {
                                label: c.name + " <" + c.email + ">",
                                value: c.email,
                            };
                        })
                        : [],
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                });
            }
            else if (_this.sender.model.cc_single.length) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'label',
                    class: 'full',
                    supplier: function () {
                        return _this.contacts.filter(function (c) { return _this.sender.model.cc_single.includes(c.email); }).map(function (c) { return c.name + " <" + c.email + ">"; });
                    },
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                });
            }
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                // displayType: 'email',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: 'テンプレート選択',
                fields: [
                    {
                        name: 'dummyTemplateType',
                        type: 'autocomplete',
                        linkTo: ['subject', 'body'],
                        placeholder: 'テンプレート名',
                        labelField: 'name',
                        class: 'half',
                        options: [],
                        supplier: function (value, callType, getValue) {
                            var typeIds = getValue('templateTypeIds');
                            return _this.sender.mailApiService.getMailTemplateNames().pipe(map(function (res) {
                                var filtered = res.filter(function (r) { return r.status === 1 && (!typeIds.length || typeIds.includes(r.mailTemplateTypeId)); });
                                return {
                                    options: filtered,
                                };
                            }));
                        },
                    },
                ],
            });
        }
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.subject : undefined,
                                };
                            }));
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return _this.sender.model.subject; },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = {
                title: '添付ファイル',
                fields: [
                    {
                        type: 'label',
                        name: 'enterpriseId',
                        class: 'half',
                        supplier: function () { return _this.sender.model.enterprise.frontId + " " + _this.sender.model.enterprise.dynamicData.name; },
                    },
                ],
            };
            row.class = 'ou';
            if (!_this.sender.inConfirm) {
                row.fields[0].labelBefore = '企業：';
                row.fields[0].linkTo = ['enterpriseFileIds'];
                row.fields[0].actions = [
                    {
                        title: 'データフォルダから選択',
                        type: 'RUNNABLE',
                        runnable: function () {
                            _this.sender.enterpriseFileIds = _this.sender.model.enterpriseFileIds.slice();
                            PopupControlComponent.instance.open({
                                content: _this.sender.fileSelectTemplate,
                                confirmText: '添付',
                                cancelText: 'キャンセル',
                                title: 'データフォルダ',
                                confirmCallback: function () {
                                    _this.sender.model.enterpriseFileIds = _this.sender.enterpriseFileIds.slice();
                                },
                            });
                        },
                    },
                ];
            }
            else {
                row.fields.length = 0;
            }
            if (!_this.sender.inConfirm || _this.sender.model.enterpriseFileIds.length) {
                row.fields.push({
                    name: 'enterpriseFileIds',
                    type: 'autocomplete',
                    labelField: [
                        {
                            name: 'name',
                            class: _this.sender.inConfirm ? 'link' : null,
                            action: _this.sender.inConfirm
                                ? function (file) {
                                    return _this.sender.fileService.downloadFile('enterprise', +Object.keys(_this.sender.enterpriseFiles).find(function (key) { return _this.sender.enterpriseFiles[key].find(function (f) { return f.id === file.id; }) != null; }), file.id, file.name);
                                }
                                : null,
                        },
                    ],
                    valueField: 'id',
                    multi: true,
                    class: 'options-only half',
                    options: [],
                    style: { 'padding-left': '40px' },
                    supplier: function (value, callType, getValue) {
                        _this.sender.enterpriseFiles = {};
                        _this.sender.enterpriseInfoById[_this.sender.model.enterprise.id] = _this.sender.model.enterprise.frontId + " " + _this.sender.model.enterprise.name;
                        return _this.sender.fileService.getFiles('enterprise', _this.sender.model.enterprise.id).pipe(map(function (res) {
                            _this.sender.enterpriseFiles[_this.sender.model.enterprise.id] = res.filter(function (f) { return f.enterpriseSendFlag; });
                            return { options: _this.sender.enterpriseFiles[_this.sender.model.enterprise.id] };
                        }));
                    },
                });
            }
            if (!_this.sender.inConfirm) {
                var studentSelect = _this.sender.dynamicService.getFormRows({ fieldType: 'multi-student', fieldName: 'studentIds' }, null, 'half sticky-label')[0].fields[0];
                studentSelect.labelBefore = '求職者: ';
                studentSelect.valueField = null;
                studentSelect.linkTo = ['studentFileIds'];
                studentSelect.actions = [
                    {
                        title: 'データフォルダから選択',
                        type: 'RUNNABLE',
                        runnable: function () {
                            _this.sender.studentFileIds = _this.sender.model.studentFileIds.slice();
                            PopupControlComponent.instance.open({
                                content: _this.sender.studentFileSelectTemplate,
                                confirmText: '添付',
                                cancelText: 'キャンセル',
                                title: 'データフォルダ',
                                confirmCallback: function () {
                                    _this.sender.model.studentFileIds = _this.sender.studentFileIds.slice();
                                },
                            });
                        },
                    },
                ];
                row.fields.push(studentSelect);
            }
            if (!_this.sender.inConfirm || _this.sender.model.studentFileIds.length) {
                row.fields.push({
                    name: 'studentFileIds',
                    type: 'autocomplete',
                    labelField: [
                        {
                            name: 'name',
                            class: _this.sender.inConfirm ? 'link' : null,
                            action: _this.sender.inConfirm
                                ? function (file) {
                                    return _this.sender.fileService.downloadFile('students', +Object.keys(_this.sender.studentFiles).find(function (key) { return _this.sender.studentFiles[key].find(function (f) { return f.id === file.id; }) != null; }), file.id, file.name);
                                }
                                : null,
                        },
                    ],
                    valueField: 'id',
                    multi: true,
                    class: 'options-only half',
                    options: [],
                    style: { 'padding-left': '40px' },
                    supplier: function (value, callType, getValue) {
                        _this.sender.studentFiles = {};
                        _this.sender.model.studentIds.forEach(function (s) { return (_this.sender.studentInfoById[s.id] = s.frontId + " " + s.lastName + s.firstName); });
                        return forkJoin(_this.sender.model.studentIds.map(function (s) {
                            return _this.sender.fileService.getFiles('students', s.id).pipe(map(function (res) {
                                _this.sender.studentFiles[s.id] = res.filter(function (f) { return f.enterpriseSendFlag; });
                                return { options: _this.sender.studentFiles[s.id] };
                            }));
                        })).pipe(map(function (res) { return ({ options: Object.values(_this.sender.studentFiles).flatten() }); }));
                    },
                });
            }
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.body : undefined,
                                };
                            }));
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }].concat(res);
                                    return {
                                        value: value ? res.find(function (s) { return s.id === value.id; }) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    CompanySendConfig.prototype.update = function () {
        var _this = this;
        if (this.sender.model.department) {
            this.sender.model.cc_single = [];
            this.contacts = [
                this.sender.model.department.dynamicData.contact1,
                this.sender.model.department.dynamicData.contact2,
                this.sender.model.department.dynamicData.contact3,
                this.sender.model.department.dynamicData.contact4,
                this.sender.model.department.dynamicData.contact5,
            ].filter(function (c) { return c && c.name && c.email && !c.emailAvailable; });
            if (environment.rpaUserIds.includes(this.sender.authService.loginUser.id)) {
                // ログインしているユーザーがRPAの場合、TOにチェックを入れず、CCを設定しない
                // see https://benesse-g.backlog.jp/view/MACH_PJ-157
            }
            else {
                // 通常のユーザーの場合、TOにチェックを入れて、CC, BCCを設定
                this.contacts.forEach(function (c, i) {
                    if (!i) {
                        _this.sender.model.to = [c.email];
                    }
                    else {
                        _this.sender.model.cc_single.push(c.email);
                    }
                });
                this.sender.model.cc = this.sender.model.department.enterpriseDepartmentUsers
                    .filter(function (u) { return u.type === EnterpriseDepartmentUserType.RA || u.type === EnterpriseDepartmentUserType.アシ; })
                    .sort(function (a, b) { return (a.type > b.type ? 1 : -1); })
                    .map(function (u) { return u.userId; });
            }
            this.sender.model.bcc = [this.sender.authService.loginUser.id];
        }
    };
    CompanySendConfig.prototype.save = function (toSave) {
        var _this = this;
        this.sender.mailApiService
            .sendEnterpriseMail(this.sender.model.enterpriseId, this.sender.model.departmentId, toSave)
            .subscribe(function (res) { return _this.sender.afterSend(res); });
    };
    return CompanySendConfig;
}(MailSendConfig));
export { CompanySendConfig };
