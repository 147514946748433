<div *ngIf="progress" class="progress">
  <section class="progress__header">
    <div class="progress__header--left">
      <div
        *ngIf="progress.type === 0 || progress.type === 1"
        class="progress__indicator"
      >
        <div
          class="progress__indicator--badge"
          [class.progress__indicator--badge--AG]="progress.type === 0"
          [class.progress__indicator--badge--Plus]="progress.type === 1"
        ></div>
        <div *ngIf="progress.hasInterview" class="progress__indicator--sub">
          -面談会
        </div>
      </div>
      <div class="progress__status">
        {{ getStatusLabel(progress.status, progress.seminarType, progress.n) }}
      </div>
      <div *ngIf="progress.updatedAt" class="progress__update-date">
        更新日時 : {{ progress.updatedAt | safeDate: "yyyy-MM-dd (E) HH:mm" }}
      </div>
    </div>
    <div class="progress__header--right">
      <ag-dynamic-form
        *ngIf="metadataResponsible"
        [metadata]="metadataResponsible"
        [model]="modelResponsible"
        (validated)="onValidityChange(1, $event)"
      ></ag-dynamic-form>
      <div class="controls">
        <button
          class="controls__control btn btn--medium btn--blue"
          (click)="saveUsers()"
        >
          更新
        </button>
      </div>
    </div>
  </section>
  <section class="progress__detail">
    <div class="applicant">
      <div class="applicant__id">
        {{ progress.student.frontId }}
      </div>
      <div
        class="applicant__degrees"
        *ngIf="
          progress.student?.dynamicData?.academicHistory1?.graduateYear &&
          progress.student.dynamicData.academicHistory1.graduateType
        "
      >
        {{
          (
            +progress.student.dynamicData.academicHistory1.graduateYear +
            (progress.student.dynamicData.academicHistory1.graduateMonth > 3
              ? 1
              : 0) +
            ""
          ).substring(2)
        }}
        {{ progress.student.dynamicData.academicHistory1.graduateType[0] }}
      </div>
      <div
        class="applicant__name link"
        (click)="onStudentLinkClick(progress.student.id)"
      >
        {{
          progress.student.dynamicData.lastName +
            progress.student.dynamicData.firstName
        }}
      </div>
      <div class="applicant__contact">
        <div
          *ngIf="
            (
              getContactUser((progress.student?.studentUsers)[0]?.userId)
              | async
            )?.name as contactName
          "
          class="applicant__contact--name"
        >
          {{ contactName }}
        </div>
        <div
          *ngIf="
            getContactTeamName((progress.student?.studentUsers)[0]?.userId)
              | async as contactTeamName
          "
          class="applicant__contact--team"
        >
          {{ contactTeamName }}
        </div>
      </div>
    </div>
    <span class="arrow arrow--right"></span>
    <div class="destination">
      <div class="destination__company">
        <div class="destination__company--id">
          {{ progress.enterprise.frontId }}
        </div>
        <div
          class="destination__company--name link"
          [title]="progress.enterprise.dynamicData.name"
          (click)="onCompanyLinkClick(progress.enterprise.id)"
        >
          {{ progress.enterprise.dynamicData.name }}
        </div>
      </div>
      <div class="destination__job">
        <div class="destination__job--id">
          {{ progress.job.frontId }}
        </div>
        <div
          class="destination__job--name link"
          [title]="progress.job.dynamicData.position"
          (click)="onJobLinkClick(progress.job.id)"
        >
          {{ progress.job.dynamicData.position }}
        </div>
        <div
          *ngIf="progress.job.dynamicData.status"
          class="destination__job--status"
        >
          {{ progress.job.dynamicData.status }}
        </div>
      </div>
      <div class="destination__contact">
        <div
          *ngIf="
            (
              getJobContactUser(
                progress?.job?.enterpriseDepartmentUsers,
                progress?.type
              ) | async
            )?.name as contactName
          "
          class="destination__contact--name"
        >
          {{ contactName }}
        </div>
        <div
          *ngIf="
            getJobContactTeamName(
              progress?.job?.enterpriseDepartmentUsers,
              progress?.type
            ) | async as contactTeamName
          "
          class="destination__contact--team"
        >
          {{ contactTeamName }}
        </div>
      </div>
    </div>
  </section>
  <section class="interview">
    <div class="interview__label">面談会</div>
    <div class="interview__value">
      <input
        type="checkbox"
        [checked]="!!progress.hasInterview"
        (click)="onHasInterViewChange($event)"
      />
      有
    </div>
  </section>
  <section class="stop-reason">
    <div class="stop-reason__header">
      <div class="header">
        <div class="header__title">辞退・不合格理由</div>
      </div>
      <button
        *ngIf="!isStopReasonEditInProgress"
        class="btn btn--medium btn--white btn--outline-gray"
        (click)="editStopReasonText()"
      >
        編集
      </button>
    </div>
    <div class="stop-reason__box">
      <div
        *ngIf="!isStopReasonEditInProgress; else stopReasonTextEdit"
        class="stop-reason__text"
      >
        {{ progress.dynamicData.stopReason }}
      </div>
      <ng-template #stopReasonTextEdit>
        <textarea
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          class="stop-reason__text stop-reason__text--edit"
          [(ngModel)]="stopReasonText"
          (keydown.enter)="$event.stopPropagation()"
        >
        </textarea>
      </ng-template>
      <div *ngIf="isStopReasonEditInProgress" class="controls">
        <button
          class="controls__control btn btn--medium btn--white btn--outline-gray"
          (click)="cancelStopReasonTextEdit()"
        >
          キャンセル
        </button>
        <button
          class="controls__control btn btn--medium btn--blue"
          (click)="saveStopReasonText()"
        >
          更新
        </button>
      </div>
    </div>
  </section>
  <section class="history">
    <div class="history__header header">
      <div class="header__title">履歴</div>
    </div>
    <div class="history__list">
      <div class="history__item history__item--header">ステータス</div>
      <div
        *ngFor="let historyItem of progress.progressHistories; index as i"
        class="history__item"
      >
        <div class="history__status">
          {{
            getStatusLabel(
              historyItem.status,
              historyItem.seminarType,
              historyItem.n
            )
          }}
        </div>
        <div class="history__update-date">
          {{ historyItem.createdAt | safeDate: "yyyy/MM/dd (E) HH:mm" }}
        </div>
        <div class="history__status-text">
          {{ getProgressStatusDateLabel(historyItem) }}
        </div>
        <div class="history__updater">
          <span>進捗更新者:</span>
          <div
            *ngIf="
              (getContactUser(historyItem.registerUserId) | async)
                ?.name as uploaderName
            "
            class="history__updater--name"
          >
            {{ uploaderName }}
          </div>
          <div
            *ngIf="
              getContactTeamName(historyItem.registerUserId)
                | async as uploaderTeamName
            "
            class="history__updater--team"
          >
            {{ uploaderTeamName }}
          </div>
        </div>
        <button
          *ngIf="i > 0"
          class="history__return-btn btn btn--small btn--white btn--outline-gray"
          (click)="onRevisionClick(historyItem.revision)"
        >
          このステータスに戻す
        </button>
        <div class="history__remark">
          <div
            *ngIf="i === 0; else displayOnly"
            class="remark remark--editable"
            [class.remark--edit]="isHistoryRemarkEditInProgress"
          >
            <div class="remark__label">備考</div>
            <div
              *ngIf="!isHistoryRemarkEditInProgress; else historyRemarkEdit"
              class="remark__text"
            >
              {{ historyItem.remarks | truncate: truncateAt }}
            </div>
            <ng-template #historyRemarkEdit>
              <textarea
                cdkTextareaAutosize
                cdkAutosizeMinRows="3"
                [maxLength]="400"
                class="remark__text"
                [(ngModel)]="historyRemarkText"
                (keydown.enter)="$event.stopPropagation()"
              ></textarea>
            </ng-template>
            <div
              *ngIf="i === 0 && !isHistoryRemarkEditInProgress"
              class="remark__edit"
              (click)="editHistoryRemark(historyItem.remarks)"
            >
              備考を入力
            </div>
          </div>
          <div
            *ngIf="i === 0 && isHistoryRemarkEditInProgress"
            class="controls"
          >
            <button
              class="controls__control btn btn--medium btn--white btn--outline-gray"
              (click)="cancelHistoryRemarkEdit()"
            >
              キャンセル
            </button>
            <button
              class="controls__control btn btn--medium btn--blue"
              (click)="saveHistoryRemarkText(historyItem)"
            >
              更新
            </button>
          </div>
          <ng-template #displayOnly>
            <div class="remark">
              {{ historyItem.remarks | truncate: truncateAt }}
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
  <section class="turnover-status">
    <div class="turnover-status__header">
      <div class="header">
        <div class="header__title">離職状況</div>
      </div>
      <button
        *ngIf="!isTurnOverFormEditInProgress"
        class="btn btn--medium btn--white btn--outline-gray"
        (click)="editTurnOverForm()"
      >
        編集
      </button>
    </div>
    <div *ngIf="progress" class="turnover-status__content">
      <ag-dynamic-form
        [metadata]="
          isTurnOverFormEditInProgress
            ? turnoverStatusMetadataEdit
            : turnoverStatusMetadata
        "
        [model]="
          isTurnOverFormEditInProgress
            ? turnoverStatusModel
            : progress.dynamicData
        "
      ></ag-dynamic-form>
      <div *ngIf="isTurnOverFormEditInProgress" class="controls">
        <button
          class="controls__control btn btn--medium btn--white btn--outline-gray"
          (click)="cancelTurnOverFormEdit()"
        >
          キャンセル
        </button>
        <button
          class="controls__control btn btn--medium btn--blue"
          (click)="saveTurnOverForm()"
        >
          更新
        </button>
      </div>
    </div>
  </section>
</div>

<ng-template #progressReturnPopup>
  <div class="popup">
    <span class="popup__note"
      >ステータスを戻します。備考を入力してください。</span
    >
    <div class="popup__row">
      <label class="popup__label">
        備考
        <span class="popup__label--required">必須</span>
      </label>
      <textarea
        class="popup__textarea"
        [(ngModel)]="historyRemarks"
        (keydown.enter)="$event.stopPropagation()"
      ></textarea>
    </div>
  </div>
</ng-template>
