import * as tslib_1 from "tslib";
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { SortOrder } from '@agent-ds/shared/components/page-scroll-table/table-interface';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { DynamicFieldService, UserApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { ElementRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
var SettingsUsersPageComponent = /** @class */ (function () {
    function SettingsUsersPageComponent(userTeamProvider, userApiService, dynamicFieldService) {
        this.userTeamProvider = userTeamProvider;
        this.userApiService = userApiService;
        this.dynamicFieldService = dynamicFieldService;
        this.filteredUsers = [];
        this.search = '';
        this.users = [];
    }
    SettingsUsersPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userApiService.getAll().subscribe(function (users) {
            _this.users = users;
            if (_this.search) {
                _this.filterUsers(_this.search);
            }
            else {
                _this.filteredUsers = _this.users.slice();
            }
        });
        this.userPopupMeta = {
            groups: [
                {
                    class: 'no-border no-background title-align-top title-w90 row-gap-20',
                    rows: [
                        {
                            title: 'ユーザーID',
                            fields: [
                                {
                                    name: 'id',
                                    type: 'label',
                                },
                            ],
                        },
                        {
                            title: '氏名',
                            fields: [
                                {
                                    name: 'name',
                                    type: 'text',
                                    validators: { required: true },
                                    class: 'tall three-quarter',
                                },
                            ],
                        },
                        {
                            title: 'パスワード',
                            class: 'vertical',
                            fields: [
                                {
                                    name: 'password',
                                    type: 'text',
                                    class: 'tall three-quarter',
                                    validators: {
                                        password: true,
                                    },
                                },
                                {
                                    name: 'passwordHint',
                                    type: 'label',
                                    supplier: function () { return '※ 変更する場合は入力してください'; },
                                    style: {
                                        'font-size': '12px',
                                        color: '#9F9F9F',
                                    },
                                },
                            ],
                        },
                        {
                            title: 'メールアドレス',
                            fields: [
                                {
                                    name: 'email',
                                    type: 'text',
                                    validators: { required: true },
                                    class: 'tall three-quarter',
                                },
                            ],
                        },
                        (function () {
                            var row = _this.dynamicFieldService.getFormRows({
                                fieldName: 'teamId',
                                fieldType: 'team',
                                label: '所属チーム',
                            })[0];
                            row.fields[0].class = 'tall third';
                            row.fields[0].validators = { required: true };
                            return row;
                        })(),
                        {
                            title: '管理者権限',
                            fields: [
                                {
                                    name: 'role',
                                    type: 'checkbox',
                                    labelAfter: '有',
                                },
                            ],
                        },
                        {
                            title: '状態',
                            fields: [
                                {
                                    name: 'status',
                                    type: 'radio',
                                    valueField: 'value',
                                    labelField: 'label',
                                    options: [
                                        { label: '退職', value: 0 },
                                        { label: '在職', value: 1 },
                                        { label: '非表示', value: 2 },
                                        { label: 'ログイン不可', value: 3 },
                                    ],
                                    class: 'vertical',
                                    validators: { required: true },
                                },
                            ],
                        },
                        {
                            title: 'ダッシュボード',
                            fields: [
                                {
                                    name: 'dashboardType',
                                    type: 'radio',
                                    valueField: 'value',
                                    labelField: 'label',
                                    options: [
                                        { label: '求職者担当', value: 3 },
                                        { label: '求職者マネージャー', value: 4 },
                                        { label: '求人担当', value: 0 },
                                        { label: '求人マネージャー', value: 1 },
                                        { label: '求人アシスタント', value: 2 },
                                        { label: '求職者副担当', value: 5 },
                                    ],
                                    class: 'vertical',
                                    validators: { required: true },
                                },
                            ],
                        },
                    ],
                },
            ],
        };
        this.tableConfig = {
            head: {
                style: {
                    padding: '7px 0',
                },
                config: [
                    {
                        columns: [
                            {
                                fields: [
                                    {
                                        name: 'id',
                                        title: 'ユーザーID',
                                        defaultSort: SortOrder.ASCENDING,
                                    },
                                ],
                                style: {
                                    width: '130px',
                                    'padding-right': '15px',
                                },
                                bodyStyle: {
                                    'padding-top': '7px',
                                    'padding-bottom': '7px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'name',
                                        title: '氏名',
                                        cellTemplate: this.nameTemplate,
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    width: '140px',
                                    'padding-right': '15px',
                                },
                                bodyStyle: {
                                    'padding-top': '7px',
                                    'padding-bottom': '7px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'email',
                                        title: 'メールアドレス',
                                        cellTemplate: this.emailTemplate,
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    width: '200px',
                                    'padding-right': '15px',
                                },
                                bodyStyle: {
                                    'padding-top': '7px',
                                    'padding-bottom': '7px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'teamId',
                                        title: '所属チーム',
                                        cellTemplate: this.teamTemplate,
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    flex: 1,
                                    'padding-right': '15px',
                                },
                                bodyStyle: {
                                    'padding-top': '7px',
                                    'padding-bottom': '7px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: '',
                                        title: 'ステータス',
                                        formatter: function (data) {
                                            if (data && data.status != null) {
                                                var statusText = '';
                                                switch (data.status) {
                                                    case 0:
                                                        statusText = '退職';
                                                        break;
                                                    case 1:
                                                        statusText = '在職';
                                                        break;
                                                    case 2:
                                                        statusText = '非表示';
                                                        break;
                                                    case 3:
                                                        statusText = 'ログイン不可';
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                return statusText ? "" + statusText + (data.role === 1 ? ' [管理者]' : '') : '';
                                            }
                                            return '';
                                        },
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    width: '140px',
                                    'padding-right': '15px',
                                },
                                bodyStyle: {
                                    'padding-top': '7px',
                                    'padding-bottom': '7px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'actions',
                                        title: '',
                                        sortable: false,
                                        cellTemplate: this.editButtonTemplate,
                                    },
                                ],
                                style: {
                                    width: '48px',
                                    'padding-right': 0,
                                },
                                bodyStyle: {
                                    'padding-top': '7px',
                                    'padding-bottom': '7px',
                                },
                            },
                        ],
                    },
                ],
            },
            body: {
                checkbox: false,
                arrow: false,
                class: 'no-hover',
                style: {
                    height: '34px',
                },
            },
        };
        this.searchInputSubscription = fromEvent(this.searchInput.nativeElement, 'input')
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function () { return _this.filterUsers(_this.search); });
    };
    SettingsUsersPageComponent.prototype.ngOnDestroy = function () {
        if (this.searchInputSubscription) {
            this.searchInputSubscription.unsubscribe();
            this.searchInputSubscription = null;
        }
    };
    SettingsUsersPageComponent.prototype.openUserEdit = function (user) {
        var _this = this;
        this.userPopupModel = tslib_1.__assign({}, user);
        var passwordField = this.userPopupMeta.groups[0].rows[1].fields[0];
        passwordField.validators = tslib_1.__assign({}, passwordField.validators, { required: this.userPopupModel.id == null });
        PopupControlComponent.instance.open({
            title: this.userPopupModel.id ? 'ユーザー詳細' : 'ユーザー登録',
            cancelText: 'キャンセル',
            confirmText: this.userPopupModel.id ? '更新' : '登録',
            width: '800px',
            height: '640px',
            content: this.userPopupTemplate,
            confirmEnabled: function () { return _this.userFormComponent && _this.userFormComponent.myForm.valid; },
            confirmCallback: function () {
                var requestObj = tslib_1.__assign({}, _this.userPopupModel, { name: _this.userPopupModel.name.trim(), role: _this.userPopupModel.role ? 1 : 0, email: _this.userPopupModel.email.trim() });
                var request = requestObj.id ? _this.userApiService.update(requestObj.id, requestObj) : _this.userApiService.add(requestObj);
                return request.pipe(concatMap(function () {
                    return _this.userApiService.getAll().pipe(tap(function (users) {
                        _this.users = users;
                        if (_this.search) {
                            _this.filterUsers(_this.search);
                        }
                        else {
                            _this.filteredUsers = _this.users.slice();
                        }
                    }));
                }));
            },
        });
    };
    SettingsUsersPageComponent.prototype.clearSearch = function () {
        this.search = '';
        this.filteredUsers = this.users.slice();
    };
    SettingsUsersPageComponent.prototype.filterUsers = function (text) {
        text = text && text.trim();
        var filteredUsers = text ? this.users.filter(function (user) { return user.name.includes(text) || user.email.includes(text); }).slice() : null;
        this.filteredUsers = filteredUsers ? filteredUsers : this.users;
    };
    return SettingsUsersPageComponent;
}());
export { SettingsUsersPageComponent };
