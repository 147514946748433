import { DialogService, MailApiService } from '@agent-ds/shared/services';
import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
var MailComponent = /** @class */ (function () {
    function MailComponent(mailApiService, dialogService) {
        this.mailApiService = mailApiService;
        this.dialogService = dialogService;
        this.model = 'students';
        this.send = new EventEmitter();
        this.sortValues = [{ title: '日付 降順', sort: 'timestamp', order: 'desc' }];
        this.sortValue = this.sortValues[0];
        this.mails = [];
        this.incoming = [];
        this.outgoing = [];
        this.total = 0;
        this.page = 0;
        this.innerLoading = false;
        this.selectedList = this.mails;
    }
    MailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refreshSubscription = this.mailApiService.refreshEvent.subscribe(function () { return _this.ngOnChanges(); });
        this.serviceRefreshSubscription = this.service ? this.service.refreshEvent.subscribe(function () { return _this.init(); }) : null;
    };
    MailComponent.prototype.ngOnDestroy = function () {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        if (this.serviceRefreshSubscription) {
            this.serviceRefreshSubscription.unsubscribe();
        }
    };
    MailComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || changes['referenceId']) {
            if (this.referenceId) {
                this.init();
            }
            else {
                this.total = 0;
                this.mails.length = this.incoming.length = this.outgoing.length = 0;
            }
        }
    };
    MailComponent.prototype.onListChanges = function (list) {
        this.selectedList = list;
        this.selectedMail = null;
    };
    MailComponent.prototype.onSelectedChanges = function (mail) {
        this.selectedMail = mail;
        this.quote = false;
        if (!this.selectedMail.attachments && this.selectedMail.mime.attachments) {
            this.mailApiService
                .getAttachmentInfo(mail.message.mail.messageId)
                .subscribe(function (res) { return (mail.attachments = res); }, function (err) { return (mail.attachments = []); });
        }
    };
    MailComponent.prototype.onSearchChange = function (value, run) {
        if (run === void 0) { run = false; }
        this.searchInputValue = value;
        if (run) {
            this.init();
        }
    };
    MailComponent.prototype.onSortChange = function (val) {
        this.sortValue = val;
        this.init();
    };
    MailComponent.prototype.onScroll = function (event) {
        if (this.mails.length < this.total &&
            !this.innerLoading &&
            event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) > 0.95) {
            this.init(false);
        }
    };
    MailComponent.prototype.onReply = function () {
        var prefill = this.generatePrefill('Re');
        prefill.inReplyTo = this.selectedMail.message.mail.messageId;
        this.send.emit(prefill);
    };
    MailComponent.prototype.onForward = function () {
        var prefill = this.generatePrefill('Fwd');
        this.send.emit(prefill);
    };
    MailComponent.prototype.generatePrefill = function (subjectPrefix) {
        return {
            subject: subjectPrefix + ": " + this.selectedMail.mime.subject,
            body: this.quote ? '> ' + this.selectedMail.mime.text.replace(/(\r?\n|\n)/g, '$1> ') : null,
            references: this.selectedMail.mime.references,
        };
    };
    MailComponent.prototype.downloadAttachment = function (partId) {
        this.mailApiService.downloadAttachment(this.selectedMail.message.mail.messageId, partId);
    };
    MailComponent.prototype.init = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = true; }
        if (this.innerLoading) {
            return;
        }
        if (reset) {
            this.page = 0;
        }
        this.innerLoading = true;
        this.mailApiService
            .getMails(this.model, this.referenceId, this.page++ * 20, 20, this.sortValue.sort, this.sortValue.order, this.searchInputValue)
            .subscribe(function (res) {
            var _a, _b, _c;
            if (reset) {
                _this.mails = res.mails;
                _this.incoming = _this.mails.filter(function (mail) { return !mail.isSend; });
                _this.outgoing = _this.mails.filter(function (mail) { return mail.isSend; });
                _this.selectedMail = null;
                _this.selectedList = _this.mails;
            }
            else {
                (_a = _this.mails).push.apply(_a, res.mails);
                (_b = _this.incoming).push.apply(_b, res.mails.filter(function (mail) { return !mail.isSend; }));
                (_c = _this.outgoing).push.apply(_c, res.mails.filter(function (mail) { return mail.isSend; }));
            }
            _this.total = res.total;
            _this.innerLoading = false;
        }, function (error) {
            console.error('get mail error', error);
            if (!error.status) {
                // ネットワークに繋がらなかったときはエラーを通知する
                _this.showErrorDialog();
            }
            else {
                // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
            }
            // エラーが起きたため、一度メールタブの内容をクリアする
            _this.mails = [];
            _this.incoming = [];
            _this.outgoing = [];
            _this.selectedMail = null;
            _this.selectedList = [];
            _this.total = 0;
            _this.innerLoading = false;
        });
    };
    MailComponent.prototype.getTargetAsText = function (target) {
        return target ? target.map(function (t) { return (t.name ? t.name + " <" + t.address + ">" : t.address); }).join(',') : '';
    };
    MailComponent.prototype.showErrorDialog = function () {
        var dialogConfig = new ConfirmDialogConfig();
        dialogConfig.zIndex = 8000;
        dialogConfig.title = 'エラーが発生しました。';
        dialogConfig.isError = true;
        dialogConfig.buttons = { yes: 'OK', no: '', hideNo: true };
        dialogConfig.messages = ['メール取得中に通信エラーが発生しました。', 'メールタブの最新情報に更新ボタンを押して再度取得してください。'];
        this.dialogService.open(ConfirmDialogComponent, dialogConfig);
    };
    return MailComponent;
}());
export { MailComponent };
