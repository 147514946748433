import * as tslib_1 from "tslib";
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentBulkConfig } from '@agent-ds/shared/components/mail-send/configs/student-bulk-config';
import { PageFloaterComponent } from '@agent-ds/shared/components/page-floater/page-floater.component';
import { StudentListComponent } from '@agent-ds/shared/components/student-list/student-list.component';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { DialogService, ExportApiService } from '@agent-ds/shared/services';
import { cleanObject, parseQueryParam } from '@agent-ds/shared/util/util';
import { BatchUpdateDialogComponent } from '@agent-ds/student/components/batch-update-dialog/batch-update-dialog.component';
import { Location } from '@angular/common';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { StudentDetailPageComponent } from '../student-detail-page/student-detail-page.component';
var StudentListWithTodaysCounselingPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentListWithTodaysCounselingPageComponent, _super);
    function StudentListWithTodaysCounselingPageComponent(router, activeRoute, location, exportService, dialog) {
        var _this = _super.call(this, router, location, activeRoute, exportService) || this;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.location = location;
        _this.exportService = exportService;
        _this.dialog = dialog;
        _this.model = 'students';
        _this.detail = StudentDetailPageComponent;
        _this.exportModel = ExportModel.student;
        _this.listConfiguration = { body: { checkbox: true } };
        _this.searchUrl = 'searchWithTodaysCounseling';
        _this.listUrl = 'listWithTodaysCounseling';
        _this.isSearchOpen = false;
        return _this;
    }
    StudentListWithTodaysCounselingPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.intakeCaRouterEventSubscription = this.router.events.subscribe(function (res) {
            if (res instanceof NavigationEnd) {
                _this.checkRoute();
            }
        });
        this.checkRoute();
        if (this.exportModel) {
            this.exportTemplates.length = 0;
            this.exportService.getTemplates(this.exportModel).subscribe(function (res) {
                var _a;
                return (_a = _this.exportTemplates).push.apply(_a, res.templates);
            });
        }
        this.intakeCaParamSubscription = this.activeRoute.queryParams.subscribe(function (params) {
            _this.searchObj = parseQueryParam(params);
            if (!_this.router.url.endsWith("/" + _this.model + "/" + _this.searchUrl) && !_this.router.url.endsWith("/" + _this.model + "/add")) {
                setTimeout(function () { return _this.doSearch({}); }, 0);
            }
        });
        this.origUrl = this.router.routerState.snapshot.url;
        if (!this.origUrl.startsWith("/" + this.model + "/" + this.listUrl)) {
            this.origUrl = "/" + this.model + "/" + this.listUrl;
        }
        setTimeout(function () { return (_this.detail.instance.origUrl = _this.origUrl); });
    };
    StudentListWithTodaysCounselingPageComponent.prototype.ngOnDestroy = function () {
        if (this.intakeCaRouterEventSubscription) {
            this.intakeCaRouterEventSubscription.unsubscribe();
            this.intakeCaRouterEventSubscription = null;
        }
        if (this.intakeCaParamSubscription) {
            this.intakeCaParamSubscription.unsubscribe();
            this.intakeCaParamSubscription = null;
        }
    };
    StudentListWithTodaysCounselingPageComponent.prototype.sendMail = function () {
        var bulkSendConfig = new StudentBulkConfig();
        MailSendFlowComponent.instance.config = bulkSendConfig;
        bulkSendConfig.setParams({ condition: this.searchObj });
        MailSendFlowComponent.instance.start();
    };
    StudentListWithTodaysCounselingPageComponent.prototype.bulkUpdate = function () {
        this.dialog.open(BatchUpdateDialogComponent, {
            data: {
                condition: cleanObject(tslib_1.__assign({}, this.searchObj, { from: 0, size: this.table.totalSize })),
            },
        });
    };
    StudentListWithTodaysCounselingPageComponent.prototype.checkRoute = function () {
        if (this.router.url.endsWith("/" + this.model + "/" + this.searchUrl)) {
            this.searchObj = { keyword: '' };
            this.openSearch();
        }
        else {
            this.closeSearch();
            if (!this.router.url.endsWith("/" + this.model + "/add")) {
                this.doSearch({});
            }
        }
    };
    StudentListWithTodaysCounselingPageComponent.prototype.doSearch = function (params) {
        this.searchObj = tslib_1.__assign({}, this.searchObj, params);
        // 固定パラメーターの追加
        this.searchObj.sortingDateTo = moment().format('YYYY-MM-DD');
        this.searchObj.sortingDateFrom = moment().format('YYYY-MM-DD');
        this.searchObj.howIntakeCaSearches = 'searchWithTodaysCounseling';
        this.closeSearch();
    };
    StudentListWithTodaysCounselingPageComponent.prototype.openSearch = function () {
        var _this = this;
        if (this.isSearchOpen) {
            return;
        }
        PageFloaterComponent.closeAll();
        this.location.go("/" + this.model + "/" + this.searchUrl);
        this.isSearchOpen = true;
        var navigationSubscription = this.location.subscribe(function () {
            _this.closeSearch();
            navigationSubscription.unsubscribe();
        });
    };
    return StudentListWithTodaysCounselingPageComponent;
}(ListPage));
export { StudentListWithTodaysCounselingPageComponent };
