import { HttpResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CACHE } from '../util/cache';
var CacheInterceptor = /** @class */ (function () {
    function CacheInterceptor() {
    }
    Object.defineProperty(CacheInterceptor, "ongoingRequestCount", {
        set: function (count) {
            CacheInterceptor.requestCount = count;
            if (CacheInterceptor.setTimer) {
                clearTimeout(CacheInterceptor.setTimer);
                CacheInterceptor.setTimer = null;
            }
            if (count) {
                CacheInterceptor.requestCountChange.emit(CacheInterceptor.requestCount);
            }
            else {
                CacheInterceptor.setTimer = setTimeout(function () { return CacheInterceptor.requestCountChange.emit(CacheInterceptor.requestCount); }, 300);
            }
        },
        enumerable: true,
        configurable: true
    });
    CacheInterceptor.prototype.intercept = function (req, next) {
        if (req.method !== 'GET' && req.url.includes('/file')) {
            return next.handle(req);
        }
        var url = req.method === 'GET' ? req.urlWithParams : req.urlWithParams + JSON.stringify(req.body);
        var isRegistered = CACHE.isRegistered(url);
        var countIt = !req.url.includes('profile-image') && !req.url.includes('search') && !req.url.includes('suggest') && !req.url.includes('dashboard');
        if (isRegistered) {
            var lastResponse_1 = CACHE.get(url);
            if (lastResponse_1) {
                return lastResponse_1 instanceof Observable
                    ? lastResponse_1
                    : new Observable(function (observer) {
                        lastResponse_1 instanceof HttpResponse ? observer.next(lastResponse_1) : observer.error(lastResponse_1);
                        observer.complete();
                    });
            }
        }
        var subject = new ReplaySubject();
        var setter = function (event) {
            subject.next(event);
            if (event && event.type !== 0) {
                if (isRegistered) {
                    CACHE.set(url, event, CACHE.getTTL(req.url));
                }
                else {
                    CACHE.clear(url);
                }
            }
        };
        var decrement = function () {
            if (countIt) {
                CacheInterceptor.requestCountChange.emit(--CacheInterceptor.requestCount);
            }
            if (!isRegistered) {
                CACHE.clear(url);
            }
            subject.complete();
        };
        var requestHandle = next.handle(req).pipe(tap(function (event) { return setter(event); }), catchError(function (error) {
            setter(error);
            return throwError(error);
        }), finalize(function () { return decrement(); }));
        CACHE.set(url, subject, CACHE.getTTL(req.url));
        if (countIt) {
            CacheInterceptor.requestCountChange.emit(++CacheInterceptor.requestCount);
        }
        return requestHandle;
    };
    CacheInterceptor.requestCount = 0;
    CacheInterceptor.requestCountChange = new EventEmitter();
    return CacheInterceptor;
}());
export { CacheInterceptor };
