/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-list-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./settings-list-page.component";
var styles_SettingsListPageComponent = [i0.styles];
var RenderType_SettingsListPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsListPageComponent, data: {} });
export { RenderType_SettingsListPageComponent as RenderType_SettingsListPageComponent };
export function View_SettingsListPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7BA1\u7406\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 32, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "sub-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7D44\u7E54\u7BA1\u7406 "])), (_l()(), i1.ɵeld(6, 0, null, null, 28, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 27, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [["class", "table__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30E6\u30FC\u30B6\u30FC\u7BA1\u7406"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u62C5\u5F53\u8005\u306E\u7BA1\u7406\u3092\u884C\u3044\u307E\u3059"])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"], ["routerLink", "/settings/users"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "])), (_l()(), i1.ɵeld(17, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "td", [["class", "table__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30C1\u30FC\u30E0\u7BA1\u7406"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u62C5\u5F53\u8005\u304C\u6240\u5C5E\u3059\u308B\u30C1\u30FC\u30E0\u306E\u7BA1\u7406\u3092\u884C\u3044\u307E\u3059 "])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "a", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"], ["routerLink", "/settings/teams"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "])), (_l()(), i1.ɵeld(26, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "td", [["class", "table__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u5831\u544A\u7528CSV\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9"])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5831\u544A\u7528CSV\u306E\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3092\u884C\u3044\u307E\u3059 "])), (_l()(), i1.ɵeld(31, 0, null, null, 3, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "a", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"], ["routerLink", "/settings/csv-reporting"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 33).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u4E00\u89A7 "])), (_l()(), i1.ɵeld(35, 0, null, null, 41, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 2, "div", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, "div", [["class", "sub-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30B7\u30B9\u30C6\u30E0\u7BA1\u7406 "])), (_l()(), i1.ɵeld(39, 0, null, null, 37, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 36, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "td", [["class", "table__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30B7\u30B9\u30C6\u30E0\u8A2D\u5B9A"])), (_l()(), i1.ɵeld(44, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30B7\u30B9\u30C6\u30E0\u5168\u4F53\u306E\u8A2D\u5B9A\u3092\u884C\u3044\u307E\u3059"])), (_l()(), i1.ɵeld(46, 0, null, null, 3, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(47, 0, null, null, 2, "a", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"], ["routerLink", "/settings/system"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 48).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(48, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "])), (_l()(), i1.ɵeld(50, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(51, 0, null, null, 1, "td", [["class", "table__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30D5\u30A3\u30FC\u30EB\u30C9\u7BA1\u7406"])), (_l()(), i1.ɵeld(53, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30B7\u30B9\u30C6\u30E0\u9805\u76EE\u306E\u7BA1\u7406\u3092\u884C\u3044\u307E\u3059 "])), (_l()(), i1.ɵeld(55, 0, null, null, 3, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(56, 0, null, null, 2, "a", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"], ["routerLink", "/settings/field-definitions"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 57).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(57, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "])), (_l()(), i1.ɵeld(59, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(60, 0, null, null, 1, "td", [["class", "table__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30AB\u30B9\u30BF\u30E0\u30D5\u30A3\u30FC\u30EB\u30C9\u7BA1\u7406"])), (_l()(), i1.ɵeld(62, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AB\u30B9\u30BF\u30E0\u30D5\u30A3\u30FC\u30EB\u30C9\u306E\u7BA1\u7406\u3092\u884C\u3044\u307E\u3059 "])), (_l()(), i1.ɵeld(64, 0, null, null, 3, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(65, 0, null, null, 2, "a", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"], ["routerLink", "/settings/custom-fields"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 66).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(66, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "])), (_l()(), i1.ɵeld(68, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(69, 0, null, null, 1, "td", [["class", "table__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30E1\u30FC\u30EB\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8\u7BA1\u7406"])), (_l()(), i1.ɵeld(71, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30E1\u30FC\u30EB\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8\u306E\u7BA1\u7406\u3092\u884C\u3044\u307E\u3059 "])), (_l()(), i1.ɵeld(73, 0, null, null, 3, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(74, 0, null, null, 2, "a", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"], ["routerLink", "/settings/mail-templates"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 75).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(75, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "]))], function (_ck, _v) { var currVal_2 = "/settings/users"; _ck(_v, 15, 0, currVal_2); var currVal_5 = "/settings/teams"; _ck(_v, 24, 0, currVal_5); var currVal_8 = "/settings/csv-reporting"; _ck(_v, 33, 0, currVal_8); var currVal_11 = "/settings/system"; _ck(_v, 48, 0, currVal_11); var currVal_14 = "/settings/field-definitions"; _ck(_v, 57, 0, currVal_14); var currVal_17 = "/settings/custom-fields"; _ck(_v, 66, 0, currVal_17); var currVal_20 = "/settings/mail-templates"; _ck(_v, 75, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 15).target; var currVal_1 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 24).target; var currVal_4 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 33).target; var currVal_7 = i1.ɵnov(_v, 33).href; _ck(_v, 32, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 48).target; var currVal_10 = i1.ɵnov(_v, 48).href; _ck(_v, 47, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 57).target; var currVal_13 = i1.ɵnov(_v, 57).href; _ck(_v, 56, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵnov(_v, 66).target; var currVal_16 = i1.ɵnov(_v, 66).href; _ck(_v, 65, 0, currVal_15, currVal_16); var currVal_18 = i1.ɵnov(_v, 75).target; var currVal_19 = i1.ɵnov(_v, 75).href; _ck(_v, 74, 0, currVal_18, currVal_19); }); }
export function View_SettingsListPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-settings-list-page", [], null, null, null, View_SettingsListPageComponent_0, RenderType_SettingsListPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.SettingsListPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsListPageComponentNgFactory = i1.ɵccf("ag-settings-list-page", i4.SettingsListPageComponent, View_SettingsListPageComponent_Host_0, {}, {}, []);
export { SettingsListPageComponentNgFactory as SettingsListPageComponentNgFactory };
