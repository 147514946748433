<div class="content">
  <div class="content__segs">
    <span
      *ngIf="companyClassification"
      class="segs-item segs-item-white segs-item-mr20"
      [ngClass]="classMap[companyClassification]"
      >{{ companyClassification }}</span
    >
    <span
      *ngIf="segs8LastYear"
      class="segs-item segs-item-white"
      [ngClass]="classMapSeg[segs8LastYear]"
      >{{ lastYear }}{{ segs8LastYear }}</span
    >
    <span *ngIf="segs8LastYear && segs8CurrentYear" class="segs-item">→</span>
    <span
      *ngIf="segs8CurrentYear"
      class="segs-item segs-item-white"
      [ngClass]="classMapSeg[segs8CurrentYear]"
      >{{ currentYear }}{{ segs8CurrentYear }}</span
    >
  </div>
</div>
