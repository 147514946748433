import * as tslib_1 from "tslib";
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService, UserApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { DecimalPipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { HelperBase } from './helper-base';
var COLUMN_STYLE_FLEX5 = { width: 'unset', flex: 0.5, 'margin-right': '20px', 'min-width': 0 };
var COLUMN_STYLE_NUM = { width: '90px', 'margin-right': '20px' };
var HEADER_STYLE = { 'background-color': '#fafafa' };
var GROUP_TITLE = '計上情報';
var AccountingInformationHelper = /** @class */ (function (_super) {
    tslib_1.__extends(AccountingInformationHelper, _super);
    function AccountingInformationHelper(dynamicService, datePipe, decimalPipe, userTeamService, userService) {
        var _this = _super.call(this, dynamicService, datePipe) || this;
        _this.decimalPipe = decimalPipe;
        _this.userTeamService = userTeamService;
        _this.userService = userService;
        _this.editable = { rows: [] };
        _this.readonly = { rows: [] };
        _this.readonlyWithCancelPrice = { rows: [] };
        _this.readonlyWithReadonlyCancelPrice = { rows: [] };
        return _this;
    }
    AccountingInformationHelper.prototype.hideOrShowRows = function (detailPage, area) {
        var groupMeta = detailPage.metadataBottomFields.groups.find(function (group) { return group.title === GROUP_TITLE; });
        if (groupMeta) {
            var row = groupMeta.rows.find(function (r) { return r.title.endsWith(area.toUpperCase()); });
            if (row) {
                for (var idx = 2; idx < row.fields.length; idx++) {
                    row.fields[idx].hidden = detailPage.model.sales[area + 'UserId'] ? false : true;
                }
            }
        }
    };
    AccountingInformationHelper.prototype.onUserChange = function (detailPage, area) {
        this.hideOrShowRows(detailPage, area);
        this.userTeamService.getUserInfoById(detailPage.model.sales[area + 'UserId']).subscribe(function (userInfo) {
            if (userInfo && userInfo.id) {
                detailPage.model.sales[area + "TeamName"] = userInfo.teamName;
                detailPage.model.sales[area + 'Percentage'] = 100;
                detailPage.model.sales[area + 'Reward'] = detailPage.model.sales.dynamicData.fee;
            }
            else {
                detailPage.model.sales[area + "TeamName"] = null;
                detailPage.model.sales[area + 'Percentage'] = null;
                detailPage.model.sales[area + 'Reward'] = null;
                detailPage.model.sales.dynamicData[area + 'CancelPrice'] = null;
            }
        });
    };
    AccountingInformationHelper.prototype.onUserInit = function (detailPage, area) {
        this.hideOrShowRows(detailPage, area);
        this.userTeamService.getUserInfoById(detailPage.model.sales[area + 'UserId']).subscribe(function (userInfo) {
            detailPage.model.sales[area + 'TeamName'] = userInfo && userInfo.id ? userInfo.teamName : null;
        });
    };
    AccountingInformationHelper.prototype.init = function (detailPage) {
        var _this = this;
        detailPage.bottomForm.changed.subscribe(function (change) {
            if (change === 'sales.raUserId') {
                _this.onUserChange(detailPage, 'ra');
            }
            else if (change === 'sales.paUserId') {
                _this.onUserChange(detailPage, 'pa');
            }
            else if (change === 'sales.caUserId') {
                _this.onUserChange(detailPage, 'ca');
            }
            else if (change === 'sales.raPercentage') {
                detailPage.model.sales.raReward = Math.floor(detailPage.model.sales.dynamicData.fee * detailPage.model.sales.raPercentage * 0.01);
            }
            else if (change === 'sales.paPercentage') {
                detailPage.model.sales.paReward = Math.floor(detailPage.model.sales.dynamicData.fee * detailPage.model.sales.paPercentage * 0.01);
            }
            else if (change === 'sales.caPercentage') {
                detailPage.model.sales.caReward = Math.floor(detailPage.model.sales.dynamicData.fee * detailPage.model.sales.caPercentage * 0.01);
            }
        });
        detailPage.saleSubject.subscribe(function () {
            _this.onUserInit(detailPage, 'ra');
            _this.onUserInit(detailPage, 'pa');
            _this.onUserInit(detailPage, 'ca');
        });
    };
    AccountingInformationHelper.prototype.updateFieldDefinitions = function () {
        this.editable = this.getTemplate(true);
        this.readonly = this.getTemplate(false);
        this.readonlyWithCancelPrice = this.getTemplate(false, true);
        this.readonlyWithReadonlyCancelPrice = this.getTemplate(false, false);
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [this.readonly, this.readonly, this.readonly, this.readonly],
            [this.editable, this.editable, this.editable, this.editable],
            [
                this.readonlyWithReadonlyCancelPrice,
                this.readonlyWithCancelPrice,
                this.readonlyWithCancelPrice,
                this.readonlyWithReadonlyCancelPrice,
            ],
            [this.readonly, this.readonly, this.readonly, this.readonly],
        ];
    };
    AccountingInformationHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus]];
    };
    AccountingInformationHelper.prototype.getTemplate = function (editable, hasCancelPriceColumn) {
        var template = {
            // Accounting information
            title: GROUP_TITLE,
            class: 'form__group--golden-title-border',
            rows: [
                {
                    title: ' ',
                    fields: [
                        {
                            type: 'label',
                            name: 'header-user',
                            class: 'auto',
                            default: '担当者',
                            style: COLUMN_STYLE_FLEX5,
                        },
                        {
                            type: 'label',
                            name: 'header-team',
                            class: 'auto',
                            default: 'チーム',
                            style: COLUMN_STYLE_FLEX5,
                        },
                        {
                            type: 'label',
                            name: 'header-ratio',
                            default: '比率',
                            style: COLUMN_STYLE_NUM,
                        },
                        {
                            type: 'label',
                            name: 'header-amount',
                            default: '売上金額',
                            style: COLUMN_STYLE_NUM,
                            disabled: true,
                        },
                    ],
                    style: HEADER_STYLE,
                }
            ].concat(this.getDynamicTableRow('ca', editable, hasCancelPriceColumn), this.getDynamicTableRow('ra', editable, hasCancelPriceColumn, true), this.getDynamicTableRow('pa', editable, hasCancelPriceColumn)),
        };
        if (hasCancelPriceColumn !== undefined) {
            template.rows[0].fields.push({
                type: 'label',
                name: 'header-cancel-price',
                default: '返金金額',
                style: COLUMN_STYLE_NUM,
                disabled: true,
            });
        }
        return template;
    };
    AccountingInformationHelper.prototype.getDynamicTableRow = function (responsibleRole, editable, hasCancelPriceColumn, isRequired) {
        var _this = this;
        var uppercaseResponsible = responsibleRole.toUpperCase();
        var userSelect = editable
            ? this.dynamicService.getFormRows({
                fieldName: 'sales.' + responsibleRole + 'UserId',
                fieldType: 'user',
                label: '担当' + uppercaseResponsible,
                validationStyle: { required: isRequired },
            })
            : [
                {
                    title: '担当' + uppercaseResponsible,
                    fields: [
                        {
                            type: 'label',
                            name: 'sales.' + responsibleRole + 'UserId',
                            supplier: function (data) {
                                return data ? _this.userService.getAll().pipe(map(function (users) { return (users.find(function (user) { return user.id === data; }) || { name: '' }).name; })) : '';
                            },
                        },
                    ],
                },
            ];
        userSelect[0].fields[0].style = COLUMN_STYLE_FLEX5;
        if (editable) {
            if (Array.isArray(userSelect[0].fields[0].labelField)) {
                var labels = userSelect[0].fields[0].labelField;
                var label = labels.find(function (lab) { return lab.name === 'team'; });
                if (label) {
                    label.hiddenAsValue = true;
                }
            }
        }
        var teamDropdown = [
            {
                title: '担当' + uppercaseResponsible,
                fields: [
                    {
                        type: 'label',
                        name: 'sales.' + responsibleRole + 'TeamName',
                    },
                ],
            },
        ];
        teamDropdown[0].fields[0].style = COLUMN_STYLE_FLEX5;
        var ratio = this.getDynamicRowOrLabel(editable, {
            fieldName: 'sales.' + responsibleRole + 'Percentage',
            fieldType: 'number',
            displayType: 'number',
            label: 'ratio',
            unit: '%',
        });
        ratio[0].fields[0].style = COLUMN_STYLE_NUM;
        var amount = this.getDynamicRowOrLabel(false, {
            fieldName: 'sales.' + responsibleRole + 'Reward',
            fieldType: 'number',
            displayType: 'number',
            label: 'amount',
            unit: '円',
        }, null, null, null, function (data) { return _this.decimalPipe.transform(data); });
        amount[0].fields[0].style = COLUMN_STYLE_NUM;
        userSelect[0].fields = userSelect[0].fields
            .concat(teamDropdown[0].fields)
            .concat(ratio[0].fields)
            .concat(amount[0].fields);
        if (hasCancelPriceColumn !== undefined) {
            var cancelPrice = this.getDynamicRowOrLabel(hasCancelPriceColumn, {
                fieldName: 'sales.' + responsibleRole + 'CancelPrice',
                fieldType: 'number',
                displayType: 'number',
                label: 'cancelPrice',
                unit: '円',
            }, null, null, null, function (data) { return _this.decimalPipe.transform(data); });
            cancelPrice[0].fields[0].style = COLUMN_STYLE_NUM;
            userSelect[0].fields = userSelect[0].fields.concat(cancelPrice[0].fields);
        }
        return userSelect;
    };
    return AccountingInformationHelper;
}(HelperBase));
export { AccountingInformationHelper };
