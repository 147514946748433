/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ordered-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./ordered-list.component";
var styles_OrderedListComponent = [i0.styles];
var RenderType_OrderedListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderedListComponent, data: {} });
export { RenderType_OrderedListComponent as RenderType_OrderedListComponent };
function View_OrderedListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "area__item"], ["draggable", "true"]], [[2, "area__item--selected", null]], [[null, "click"], [null, "dragstart"], [null, "dragend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("dragstart" === en)) {
        var pd_1 = (_co.onDragStart($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("dragend" === en)) {
        var pd_2 = (_co.onDragEnd($event, _v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedItems.includes(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.labelField ? _v.context.$implicit[_co.labelField] : _v.context.$implicit); _ck(_v, 1, 0, currVal_1); }); }
function View_OrderedListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "arrow arrow--up"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_OrderedListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "arrow arrow--down"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_OrderedListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "area"]], null, [[null, "drop"], [null, "dragover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (_co.onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = ($event.preventDefault() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderedListComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderedListComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderedListComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.readonly; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.readonly; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_OrderedListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ag-ordered-list", [], null, null, null, View_OrderedListComponent_0, RenderType_OrderedListComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.OrderedListComponent]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.OrderedListComponent]), i1.ɵdid(3, 245760, null, 0, i4.OrderedListComponent, [i1.ElementRef, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var OrderedListComponentNgFactory = i1.ɵccf("ag-ordered-list", i4.OrderedListComponent, View_OrderedListComponent_Host_0, { labelField: "labelField", valueField: "valueField", readonly: "readonly", validators: "validators", value: "value" }, { valueChange: "valueChange" }, []);
export { OrderedListComponentNgFactory as OrderedListComponentNgFactory };
