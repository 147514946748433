/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job-registration-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "./job-registration-dialog.component";
import * as i8 from "../../../shared/components/dialog/dialog-ref";
import * as i9 from "../../../shared/components/dialog/dialog-config";
import * as i10 from "../../../shared/services/api/dynamic-field.service";
var styles_JobRegistrationDialogComponent = [i0.styles];
var RenderType_JobRegistrationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobRegistrationDialogComponent, data: {} });
export { RenderType_JobRegistrationDialogComponent as RenderType_JobRegistrationDialogComponent };
export function View_JobRegistrationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30BB\u30DF\u30CA\u30FC \u7D39\u4ECB\u6C42\u4EBA\u65B0\u898F\u767B\u9332"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "dialog__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30BB\u30DF\u30CA\u30FC \u7D39\u4ECB\u6C42\u4EBA\u3092\u65B0\u898F\u767B\u9332\u3057\u307E\u3059\u3002"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "form-row__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6C42\u4EBA "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "ag-dynamic-form", [["class", "form-row__form"]], null, [[null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = (_co.onValidityChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(12, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated" }), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.register() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u767B\u9332 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadata; var currVal_1 = _co.model; _ck(_v, 12, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isValid; _ck(_v, 16, 0, currVal_2); }); }
export function View_JobRegistrationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-job-registration-dialog", [], null, null, null, View_JobRegistrationDialogComponent_0, RenderType_JobRegistrationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.JobRegistrationDialogComponent, [i8.DialogRef, i9.DialogConfig, i10.DynamicFieldService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JobRegistrationDialogComponentNgFactory = i1.ɵccf("ag-job-registration-dialog", i7.JobRegistrationDialogComponent, View_JobRegistrationDialogComponent_Host_0, {}, {}, []);
export { JobRegistrationDialogComponentNgFactory as JobRegistrationDialogComponentNgFactory };
