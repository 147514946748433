<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">進捗登録</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__container">
    <div class="content" *ngIf="status === 2">
      {{
        registerResult.successIds?.length
          ? registerResult.successIds.length
          : 0
      }}件処理しました。<br /><br />
      ※「進捗あり」と表示されていた進捗はすでに進捗が登録済みな状態となりますので、進捗の上書き登録をおこなっておりません。
    </div>
    <div class="content" *ngIf="status < 2" #container>
      <div class="instruct">
        一括で進捗を登録します。 対象の求職者を選択してください
      </div>
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            #student_form
            class="row__head-form"
            [metadata]="metadata"
            [model]="model"
            (validated)="onValidityChange($event)"
            (changed)="onValueChange($event)"
          ></ag-dynamic-form>
        </div>
      </div>
      <div
        class="row row__table-header"
        [style.padding-right]="
          table.scrollHeight === table.clientHeight ? '15px' : '32px'
        "
      >
        <div class="row__column">
          企業ID, 企業名
        </div>
        <div class="row__column">
          求人ID, ポジション名
        </div>
        <div class="row__column">
          進捗
        </div>
      </div>
      <div class="table-content" #table>
        <div
          class="row row__table"
          [class.row--red]="progressCheckResponse.indexOf(job.id) > -1"
          *ngFor="let job of config.data"
        >
          <div class="row__column">
            <div>{{ job.frontEnterpriseId }}</div>
            &nbsp;
            <div class="name name__big">{{ job.enterpriseName }}</div>
          </div>
          <div class="row__column">
            <span>{{ job.frontId }}</span
            >&nbsp;<span class="name">{{ job.dynamicData?.position }}</span>
          </div>
          <div class="row__column row__column--red">
            {{ progressCheckResponse.indexOf(job.id) > -1 ? "進捗有" : "" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button
      class="dialog__button dialog__button--accent"
      (click)="close()"
      *ngIf="status !== 2"
      [disabled]="status === 1"
    >
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!isValid || status === 1"
      (click)="register()"
      *ngIf="status !== 2"
    >
      登録
    </button>
    <button
      class="dialog__button dialog__button--accent"
      (click)="close()"
      *ngIf="status === 2"
    >
      閉じる
    </button>
  </div>
</div>

<ng-template #jobError>
  <div class="job-table">
    <div class="job-table__title">
      {{ "担当が設定されていません。\n求人詳細ページでご確認してください。" }}
    </div>
    <div class="job-table__head">求人ID、ポジション名</div>
    <div class="job-table__info" *ngFor="let info of jobErrorInfo">
      {{ info.frontJobId + " " + info.position }}
    </div>
  </div>
</ng-template>
