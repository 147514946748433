<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">新規求職者登録</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="create-container">
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            class="row__head-form"
            [metadata]="headMeta"
            [model]="student"
            (validated)="onValidityChange($event, 0)"
            *ngIf="headMeta && student"
          ></ag-dynamic-form>
        </div>
      </div>
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            [metadata]="leftMeta"
            [model]="student.dynamicData"
            (validated)="onValidityChange($event, 1)"
            *ngIf="leftMeta && student"
          ></ag-dynamic-form>
        </div>

        <div class="row__column">
          <ag-dynamic-form
            [metadata]="rightMeta"
            [model]="student"
            (validated)="onValidityChange($event, 2)"
            *ngIf="rightMeta && student"
          ></ag-dynamic-form>
        </div>
      </div>

      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            class="row__bottom-form"
            [metadata]="bottomMeta"
            [model]="student.dynamicData"
            (validated)="onValidityChange($event, 3)"
            *ngIf="bottomMeta && student"
          ></ag-dynamic-form>
        </div>
      </div>

      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            class="row__bottom-form"
            [metadata]="customMeta"
            [model]="student.dynamicData"
            (validated)="onValidityChange($event, 4)"
            *ngIf="customMeta && student"
          ></ag-dynamic-form>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!validity || inClose || dialog.inClose"
      (click)="register()"
    >
      登録
    </button>
  </div>
</div>
