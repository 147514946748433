<div class="content">
  <div class="content__controls">
    <input
      class="content__controls__select-all"
      type="checkbox"
      [checked]="table?.allChecked"
      (click)="table?.checkAll(table.allChecked)"
      *ngIf="options?.body?.checkbox"
    />
    <span class="content__controls__count">{{
      table?.checkedItems.length || table?.totalSize
    }}</span>
    <span class="content__controls__label">{{
      table?.checkedItems.length ? "件選択中" : "件"
    }}</span>
  </div>
  <div class="content__controls content__controls--fill-space">
    <div *ngTemplateOutlet="extraControls"></div>
  </div>
  <div class="content__controls">
    <div class="checkbox-wrapper" *ngIf="options?.filterOpen">
      <input
        class="checkbox-wrapper__input"
        id="show_ended_jobs"
        type="checkbox"
        [checked]="filterOpen"
        (click)="filterOpenChanged(filterOpen)"
      />
      <label class="checkbox-wrapper__label" for="show_ended_jobs"
        >募集が終わったものも表示</label
      >
    </div>
    <div
      class="controls__refresh"
      *ngIf="!options?.body?.hideRefreshButton"
      (click)="table.reset(true); table.next()"
    >
      <div class="controls__refresh-icon">
        <svg class="controls__refresh--svg">
          <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
        </svg>
      </div>
      最新情報に更新
    </div>
    <button
      class="controls__btn btn btn--green btn--larger"
      (click)="openJobCreateDialog()"
      *ngIf="!options?.body?.hideCreateButton"
    >
      新規求人登録
    </button>
  </div>
</div>

<ag-page-scroll-table
  class="table"
  [tableConfig]="tableConfig"
  [supplier]="jobLoader"
  [content]="content"
  (itemSelected)="onItemSelected($event)"
  (itemChecked)="onItemChecked($event)"
></ag-page-scroll-table>

<ng-template #newArrival let-data>
  <div *ngIf="data.ribbon" class="ribbon">
    <div
      class="ribbon__label"
      [agClass]="data.ribbon"
      [classOptions]="jobRibbonClasses"
    >
      {{ jobRibbonType[data.ribbon] }}
    </div>
  </div>
</ng-template>

<ng-template #heat let-data>
  <div class="heat">
    <div
      class="heat__badge badge"
      [agClass]="data.dynamicData?.recommendationDateKind"
      [classOptions]="recommendationHeatClasses"
    >
      {{ data.dynamicData?.recommendationDateKind }}
    </div>
  </div>
  <div
    class="heat__deadline"
    *ngIf="
      data.dynamicData?.recommendationDateKind &&
      data.dynamicData?.recommendationDate
    "
  >
    推薦期限:&nbsp;{{
      data.dynamicData?.recommendationDate | safeDate: "MM/dd"
    }}
  </div>
</ng-template>

<ng-template #company let-data>
  <div class="description">
    <div class="description__label description__label--big">
      {{ data.frontEnterpriseId }}
    </div>
    <div
      class="description__link description__link--big description__link--bold description__link--no-wrap link"
      (click)="$event.stopPropagation(); onCompanyLinkClick(data.enterpriseId)"
    >
      {{ data.enterpriseName }}
    </div>
    <div class="description__seg" *ngIf="data.companyClassification">
      <span
        class="description__seg--ml10 badge"
        [ngClass]="classMap[data.companyClassification]"
        >{{ data.companyClassification }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #position let-data>
  <div class="description">
    <div class="description__label description__label--small">
      {{ data.frontJobId }}
    </div>
    <div
      class="description__link description__link--small description__link--bold link two-line"
      [title]="data.dynamicData?.position"
    >
      {{ data.dynamicData?.position }}
    </div>
  </div>
</ng-template>

<ng-template #favorites let-data>
  <svg
    class="favorite"
    [ngClass]="{
      'favorite--star': data.favorite,
      'favorite--star-O': !data.favorite
    }"
    (click)="$event.stopPropagation(); onFavoriteClicked(data)"
  >
    <use xlink:href="/assets/icons/icn_star.svg#star" />
  </svg>
</ng-template>

<ng-template #personsInCharge let-data>
  <div class="persons">
    <div *ngFor="let deptUser of data.users" class="persons__row">
      <ng-container *ngIf="deptUser.area">
        <div
          class="persons__badge badge"
          [agClass]="deptUser.area"
          [classOptions]="RAPACA_VALUE_BADGE_CLASSES"
        >
          {{ deptUser.area }}
        </div>
      </ng-container>
      <div class="persons__text">
        {{ deptUser.name }}
        {{
          (
            (masterApiService.getTeams()
              | async
              | find: deptUser.teamId:"id") || { name: "" }
          ).name
        }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #actions let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <div
      class="actions__action"
      (click)="onJobLinkClick(data, 'matching')"
      *ngIf="!isIntakeCa"
    >
      マッチング
      <span class="actions__action__arrow actions__action__arrow--right"></span>
    </div>
    <div class="actions__action" (click)="onJobLinkClick(data, 'userList')">
      担当一覧
      <span class="actions__action__arrow actions__action__arrow--right"></span>
    </div>
  </div>
</ng-template>
