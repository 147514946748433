import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
/*
  This widget needs a relative positioned container to calculate it's dimensions properly.
  The container can be any parent (ex. the router container div.content)
 */
var PageFloaterComponent = /** @class */ (function () {
    function PageFloaterComponent(host) {
        this.host = host;
        this.closeOnShrink = false;
        this.sideActions = null;
        this.selectedSideActionIndexChange = new EventEmitter();
        this.transformed = new EventEmitter();
        this.innerSelectedSideActionIndex = 0;
        this.innerPhaseIndex = -1;
    }
    PageFloaterComponent.closeAll = function () {
        PageFloaterComponent.INSTANCES.slice().forEach(function (ins) { return ins.close(); });
    };
    PageFloaterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.host.nativeElement.tabIndex = -1;
        this.host.nativeElement.style.visibility = 'hidden';
        this.host.nativeElement.style.width = '0px';
        this.host.nativeElement.style.height = '0px';
        this.host.nativeElement.style.zIndex = PageFloaterComponent.DEFAULT_ZINDEX + '';
        this.instanceChangeSubscription = PageFloaterComponent.INSTANCES_CHANGE.subscribe(function () { return _this.updateZindex(); });
    };
    PageFloaterComponent.prototype.ngOnDestroy = function () {
        this.instanceChangeSubscription.unsubscribe();
        this.instanceChangeSubscription = null;
    };
    PageFloaterComponent.prototype.keyHandler = function (event) {
        if (this.innerPhaseIndex < 0) {
            return;
        }
        if (event.code === 'Escape') {
            event.stopPropagation();
            this.close();
        }
        else if (event.ctrlKey) {
            if (event.key === ']' || event.code === 'BracketRight') {
                event.stopPropagation();
                this.shrink();
            }
            else if (event.key === '[' || event.code === 'BracketLeft') {
                if (event.shiftKey) {
                    if (this.fullModeUrl) {
                        event.stopPropagation();
                        window.open(this.fullModeUrl);
                    }
                }
                else {
                    event.stopPropagation();
                    this.grow();
                }
            }
        }
    };
    Object.defineProperty(PageFloaterComponent.prototype, "phaseIndex", {
        get: function () {
            return this.innerPhaseIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageFloaterComponent.prototype, "selectedSideActionIndex", {
        get: function () {
            if (this.innerSelectedSideActionIndex === null && this.sideActions && this.sideActions.length) {
                return 0;
            }
            return this.innerSelectedSideActionIndex !== null ? this.innerSelectedSideActionIndex : null;
        },
        set: function (index) {
            if (index === this.innerSelectedSideActionIndex) {
                return;
            }
            this.innerSelectedSideActionIndex = index;
            this.selectedSideActionIndexChange.emit(index);
            this.move(this.phases[0]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageFloaterComponent.prototype, "phases", {
        get: function () {
            return this.sideActions &&
                this.sideActions.length &&
                this.sideActions[this.selectedSideActionIndex] &&
                this.sideActions[this.selectedSideActionIndex].phases
                ? this.sideActions[this.selectedSideActionIndex].phases
                : [];
        },
        enumerable: true,
        configurable: true
    });
    PageFloaterComponent.prototype.grow = function (event) {
        if (event) {
            event.preventDefault();
        }
        if (this.disableFullScreen && this.phases[this.innerPhaseIndex + 1] === '100%') {
            return;
        }
        this.innerPhaseIndex++;
        this.host.nativeElement.style.visibility = '';
        this.host.nativeElement.style.height = '';
        this.move(this.phases[this.innerPhaseIndex] || this.phases[this.phases.length - 1]);
    };
    PageFloaterComponent.prototype.shrink = function (event) {
        if (event) {
            event.preventDefault();
        }
        if (this.closeOnShrink) {
            this.innerPhaseIndex = 0;
        }
        if (--this.innerPhaseIndex < 0) {
            this.host.nativeElement.style.visibility = 'hidden';
            this.host.nativeElement.style.height = '0px';
        }
        this.move(this.phases[this.innerPhaseIndex] || '0px');
        if (this.innerPhaseIndex < 0) {
            this.innerSelectedSideActionIndex = 0;
            this.selectedSideActionIndexChange.emit(0);
            PageFloaterComponent.INSTANCES.remove(this);
            PageFloaterComponent.INSTANCES_CHANGE.emit();
        }
    };
    PageFloaterComponent.prototype.first = function () {
        if (this.innerPhaseIndex < 0) {
            PageFloaterComponent.INSTANCES.placeTop(this);
            PageFloaterComponent.INSTANCES_CHANGE.emit();
        }
        this.innerPhaseIndex = -1;
        this.grow();
    };
    PageFloaterComponent.prototype.last = function () {
        if (this.innerPhaseIndex < 0) {
            PageFloaterComponent.INSTANCES.placeTop(this);
            PageFloaterComponent.INSTANCES_CHANGE.emit();
        }
        this.innerPhaseIndex = this.phases.length - 2;
        this.grow();
    };
    PageFloaterComponent.prototype.open = function () {
        if (this.innerPhaseIndex < 0) {
            this.grow();
        }
        PageFloaterComponent.INSTANCES.placeTop(this);
        PageFloaterComponent.INSTANCES_CHANGE.emit();
    };
    PageFloaterComponent.prototype.close = function () {
        if (this.innerPhaseIndex > -1) {
            this.innerPhaseIndex = 0;
            this.shrink();
            PageFloaterComponent.INSTANCES.remove(this);
            PageFloaterComponent.INSTANCES_CHANGE.emit();
        }
    };
    PageFloaterComponent.prototype.move = function (phase) {
        this.host.nativeElement.style.width = phase;
        this.host.nativeElement.focus();
        this.transformed.emit(this.host.nativeElement.style.width);
    };
    PageFloaterComponent.prototype.updateZindex = function () {
        this.host.nativeElement.style.zIndex = PageFloaterComponent.DEFAULT_ZINDEX + PageFloaterComponent.INSTANCES.indexOf(this) + 1 + '';
    };
    PageFloaterComponent.INSTANCES = [];
    PageFloaterComponent.DEFAULT_ZINDEX = 5000;
    PageFloaterComponent.INSTANCES_CHANGE = new EventEmitter();
    return PageFloaterComponent;
}());
export { PageFloaterComponent };
