<div class="title">
  アップロードデータ一覧
</div>

<section class="uploads">
  <div class="content">
    <div class="content__count">
      <span class="content__count__number">{{ table?.totalSize }}</span>
      <span class="content__count__pcs">件</span>
    </div>
    <div class="content__refresh" (click)="refresh()">
      <div class="content__refresh-icon">
        <svg class="content__refresh--svg">
          <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
        </svg>
      </div>
      最新情報に更新
    </div>
  </div>
  <ag-page-scroll-table
    class="content__table"
    [supplier]="loadUploads"
    (itemSelected)="onItemSelected($event)"
  ></ag-page-scroll-table>
  <div *ngIf="initialized && !table.totalSize" class="content__empty-list">
    要確認書類のデータはありません
  </div>
</section>

<ng-template #branch let-data>
  <span
    *ngIf="
      data.student.dynamicData.supportBranch &&
      (branches
        | async
        | find: data.student.dynamicData.supportBranch:'id') as branch
    "
    >{{ branch.name }}</span
  >
</ng-template>

<ng-template #inCharge let-data>
  <span
    *ngIf="
      data.student.studentUsers &&
      data.student.studentUsers.length &&
      (users | async | find: data.student.studentUsers[0].userId:'id') as user
    "
  >
    {{ user.name }}
    {{ ((teams | async | find: user.teamId:"id") || { name: "" }).name }}
  </span>
</ng-template>
