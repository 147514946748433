import * as tslib_1 from "tslib";
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { AREAS } from '@agent-ds/shared/constants';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { AfterViewInit, OnDestroy } from '@angular/core';
var SeminarReservationDialogComponent = /** @class */ (function () {
    function SeminarReservationDialogComponent(dialog, config, dynamicService, counselingApiService) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.counselingApiService = counselingApiService;
        this.model = {};
        this.metadata = { groups: [] };
        this.isValid = false;
        this.AREAS = AREAS;
        this.areas = Object.keys(AREAS).map(function (key) { return ({ id: +key, name: AREAS[key] }); });
        this.seminars = [];
    }
    SeminarReservationDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.model.area = this.areas[0].id;
        this.model.seminarReservationTime = ['10:00'];
        this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadata = {
                groups: [
                    {
                        class: 'form__group--no-title-border no-border-around no-background',
                        rows: (function () {
                            var rows = _this.dynamicService.getFormRows({
                                fieldName: 'area',
                                fieldType: 'list',
                                label: 'セミナーエリア',
                                displayType: 'radio',
                                validationStyle: { required: true, options: _this.areas },
                            }, null, 'full');
                            rows[0].fields[0].labelField = 'name';
                            rows[0].fields[0].valueField = 'id';
                            return rows;
                        })().concat(_this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('student', 'seminarReservationDate'), { validationStyle: { required: true, minValue: new Date().toAsialDateString(), minDate: new Date().toAsialDateString() }, displayType: 'date' }), null, 'half'), (function () {
                            var rows = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('student', 'seminarReservationTime'), { fieldType: 'multi-list', displayType: 'checkbox' }), null, 'full');
                            rows[0].fields[0].validators.options = rows[0].fields[0].validators.options.filter(function (option) { return !isNaN(Date.parse('1970-01-01T' + option)); });
                            rows[0].fields[0].options = rows[0].fields[0].validators.options.slice();
                            return rows;
                        })(), _this.dynamicService.getFormRows({
                            fieldName: 'seminarAttributes',
                            fieldType: 'number',
                            label: 'セミナー属性',
                            displayType: 'number',
                            displayStyle: {
                                placeholder: 'セミナー属性番号',
                            },
                        }, null, 'half')),
                    },
                ],
            };
        });
    };
    SeminarReservationDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubScription) {
            this.fieldSubScription.unsubscribe();
            this.fieldSubScription = null;
        }
    };
    SeminarReservationDialogComponent.prototype.onValidityChange = function (valid) {
        this.isValid = valid;
    };
    SeminarReservationDialogComponent.prototype.search = function () {
        var _this = this;
        if (!this.isValid || this.isSearchInProgress) {
            return;
        }
        this.isSearchInProgress = true;
        this.counselingApiService
            .searchSeminarsForStudent(this.config.data.studentId, {
            area: this.model.area,
            date: this.model.seminarReservationDate,
            times: this.model.seminarReservationTime,
            type: this.model.seminarAttributes,
        })
            .subscribe(function (seminars) {
            _this.selectedSeminar = null;
            _this.seminars = seminars.sort(function (a, b) { return (new Date(a.seminarAt) > new Date(b.seminarAt) ? 1 : 0); });
            _this.isSearchInProgress = false;
        }, function () { return (_this.isSearchInProgress = false); });
    };
    SeminarReservationDialogComponent.prototype.reserve = function () {
        var _this = this;
        if (this.selectedSeminar == null) {
            return;
        }
        this.counselingApiService.addSeminarForStudent(this.config.data.studentId, this.selectedSeminar).subscribe(function () { return _this.close(true); });
    };
    SeminarReservationDialogComponent.prototype.close = function (result) {
        this.dialog.close(result);
    };
    return SeminarReservationDialogComponent;
}());
export { SeminarReservationDialogComponent };
