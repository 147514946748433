import * as tslib_1 from "tslib";
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { TAX_PERCENTAGE } from '@agent-ds/shared/constants/consts';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { AuthService, CompanyApiService, DialogService, DynamicFieldService, JobApiService, MailApiService, MasterApiService, ProgressApiService, SalesApiService, StudentApiService, UserApiService, } from '@agent-ds/shared/services';
import { DecimalPipe } from '@angular/common';
import { AfterViewInit, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AccountingInformationHelper } from './helpers/accounting-information-helper';
import { BillingExtraInformationHelper } from './helpers/billing-extra-info-helper';
import { BillingInformationHelper } from './helpers/billing-information-helper';
import { CancellationInformationHelper } from './helpers/cancellation-information-helper';
import { ClosingInformationHelper } from './helpers/closing-info-helper';
import { ContractorInformationHelper } from './helpers/contractor-info-helper';
import { MAX_FEE_VALUE, OrderInformationHelper } from './helpers/order-info-helper';
import { OverviewHelper } from './helpers/overview-helper';
import { RemarkHelper } from './helpers/remark-helper';
var SalesDetailPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SalesDetailPageComponent, _super);
    function SalesDetailPageComponent(dynamicService, dialogService, salesApiService, jobService, progressService, enterpriseService, studentService, activeRoute, router, overviewHelper, closingInformationHelper, orderInformationHelper, cancellationInformationHelper, contractorInformationHelper, billingInformationHelper, billingExtraInformationHelper, accountingInformationHelper, remarkHelper, numberFormat, mailApiService, authService, userApiService, masterApiService) {
        var _this = _super.call(this, router, salesApiService, activeRoute) || this;
        _this.dynamicService = dynamicService;
        _this.dialogService = dialogService;
        _this.salesApiService = salesApiService;
        _this.jobService = jobService;
        _this.progressService = progressService;
        _this.enterpriseService = enterpriseService;
        _this.studentService = studentService;
        _this.activeRoute = activeRoute;
        _this.router = router;
        _this.overviewHelper = overviewHelper;
        _this.closingInformationHelper = closingInformationHelper;
        _this.orderInformationHelper = orderInformationHelper;
        _this.cancellationInformationHelper = cancellationInformationHelper;
        _this.contractorInformationHelper = contractorInformationHelper;
        _this.billingInformationHelper = billingInformationHelper;
        _this.billingExtraInformationHelper = billingExtraInformationHelper;
        _this.accountingInformationHelper = accountingInformationHelper;
        _this.remarkHelper = remarkHelper;
        _this.numberFormat = numberFormat;
        _this.mailApiService = mailApiService;
        _this.authService = authService;
        _this.userApiService = userApiService;
        _this.masterApiService = masterApiService;
        _this.sideActions = [{ phases: ['1000px', '100%'] }];
        _this.tabs = {};
        _this.urlTag = 'sales';
        _this.cancellationInfo = [];
        _this.billingExtraInfo = [];
        _this.model = {};
        _this.modelLeft = {};
        _this.modelRight = {};
        _this.modelBottomFields = {};
        _this.footerButtons = [];
        _this.metadataLeft = { groups: [] };
        _this.metadataRight = { groups: [] };
        _this.metadataBottomFields = { groups: [] };
        _this.validity = false;
        _this.validityArray = [false, false];
        _this.buttonMetaByState = [
            [],
            [
                {
                    // not approved
                    id: 1,
                    title: '計上承認',
                    action: function () {
                        _this.setStatusAndUpdate(2);
                    },
                },
                {
                    id: 2,
                    title: '否認',
                    action: function () {
                        _this.setStatusAndUpdate(3);
                    },
                },
            ],
            [
                {
                    // approved
                    id: 3,
                    title: '承認取消',
                    action: function () {
                        _this.resetFormForApprovalCancellation();
                        _this.setStatusAndUpdate(1, 1);
                    },
                },
            ],
            [
                {
                    // denied
                    id: 4,
                    title: '再申請',
                    action: function () {
                        _this.setStatusAndUpdate(1, null, _this.sendSalesNotificationPreconditions.bind(_this), _this.sendSalesNotificationEmail.bind(_this));
                    },
                },
            ],
        ];
        _this.fieldsUpToDate = false;
        _this.contractSubject = new Subject();
        _this.billingSubject = new Subject();
        _this.saleSubject = new Subject();
        _this.updatePending = false;
        _this.emailModel = {};
        return _this;
    }
    SalesDetailPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.fieldsUpToDate = false;
            _this.updateAllMetadata();
        });
    };
    SalesDetailPageComponent.prototype.registerActions = function () {
        var _this = this;
        this.orderInformationHelper.calculateTaxActions = [
            {
                title: '計算',
                type: 'RUNNABLE',
                runnable: function () {
                    if (!_this.validityArray[0] && (isNaN(_this.model.sales.dynamicData.fee) || _this.model.sales.dynamicData.fee > MAX_FEE_VALUE)) {
                        var config = {
                            title: '入力値が不正です',
                            messages: ['フィーの金額が正しくありません'],
                            style: {
                                height: '245px',
                                width: '510px',
                            },
                            buttons: {
                                no: '',
                                hideNo: true,
                                yes: 'OK',
                            },
                        };
                        _this.dialogService.open(ConfirmDialogComponent, config);
                    }
                    else if (_this.sale && _this.sale.dynamicData) {
                        _this.sale.dynamicData.taxFee = Math.floor(_this.sale.dynamicData.fee * TAX_PERCENTAGE);
                        _this.sale.dynamicData.chargeFee = Math.floor(_this.sale.dynamicData.fee + _this.sale.dynamicData.taxFee);
                        if (_this.sale.raPercentage) {
                            _this.sale.raReward = Math.floor(_this.sale.dynamicData.fee * _this.sale.raPercentage * 0.01);
                        }
                        if (_this.sale.paPercentage) {
                            _this.sale.paReward = Math.floor(_this.sale.dynamicData.fee * _this.sale.paPercentage * 0.01);
                        }
                        if (_this.sale.caPercentage) {
                            _this.sale.caReward = Math.floor(_this.sale.dynamicData.fee * _this.sale.caPercentage * 0.01);
                        }
                    }
                },
            },
        ];
        this.cancellationInformationHelper.calculateRefoundAmount = [
            {
                title: '返金額計算',
                type: 'RUNNABLE',
                runnable: function () {
                    if (_this.sale && _this.sale.dynamicData.refundPercent) {
                        if (_this.sale.dynamicData.chargeFee) {
                            _this.sale.dynamicData.refundPrice = Math.floor(_this.sale.dynamicData.fee * _this.sale.dynamicData.refundPercent * 0.01);
                        }
                        if (_this.sale.caPercentage) {
                            _this.sale.caCancelPrice = Math.floor(_this.sale.dynamicData.refundPrice * _this.sale.caPercentage * 0.01);
                        }
                        if (_this.sale.raPercentage) {
                            _this.sale.raCancelPrice = Math.floor(_this.sale.dynamicData.refundPrice * _this.sale.raPercentage * 0.01);
                        }
                        if (_this.sale.paPercentage) {
                            _this.sale.paCancelPrice = Math.floor(_this.sale.dynamicData.refundPrice * _this.sale.paPercentage * 0.01);
                        }
                    }
                },
            },
        ];
    };
    SalesDetailPageComponent.prototype.ngAfterViewInit = function () {
        this.closingInformationHelper.init(this);
        this.orderInformationHelper.init(this);
        this.cancellationInformationHelper.init(this);
        this.contractorInformationHelper.init(this);
        this.billingInformationHelper.init(this);
        this.billingExtraInformationHelper.init(this);
        this.accountingInformationHelper.init(this);
        this.cancellationInformationHelper.cancellationButtons = this.cancellationHeaderButtons;
    };
    SalesDetailPageComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
        this.contractSubject.complete();
        this.billingSubject.complete();
        this.saleSubject.complete();
    };
    SalesDetailPageComponent.prototype.fill = function () {
        var _this = this;
        if (this.referenceId) {
            this.updateUrl();
            this.salesApiService.getDetail(this.referenceId).subscribe(function (sale) {
                var prevSale = _this.sale;
                _this.sale = sale;
                _this.model = { sales: sale };
                if (_this.sale) {
                    if (!prevSale || _this.sale.status !== prevSale.status || _this.sale.cancelStatus !== prevSale.cancelStatus) {
                        _this.updateAllMetadata();
                    }
                    _this.loadJobAndEnterprise(sale);
                    _this.loadProgressAndStudent(sale);
                }
                _this.saleSubject.next(_this.sale);
            });
        }
    };
    SalesDetailPageComponent.prototype.updateAllMetadata = function () {
        if (this.sale) {
            this.metadataLeft = this.getFormMetadataLeft(this.sale.status, this.sale.cancelStatus);
            this.metadataRight = this.getFormMetadataRight(this.sale.status, this.sale.cancelStatus);
            this.metadataBottomFields = this.getFormMetadataBottom(this.sale.status, this.sale.cancelStatus);
            this.footerButtons = this.getFooterButtons(this.sale.status);
            this.fieldsUpToDate = true;
            this.registerActions();
        }
    };
    SalesDetailPageComponent.prototype.loadProgressAndStudent = function (sale) {
        var _this = this;
        if (sale.progressId) {
            this.progressService.getDetail(sale.progressId).subscribe(function (progress) {
                _this.model.progress = progress;
                if (progress && progress.studentId) {
                    _this.studentService.getDetail(progress.studentId).subscribe(function (student) {
                        _this.model.student = student;
                    });
                }
            });
        }
        else if (sale.studentId) {
            this.studentService.getDetail(sale.studentId).subscribe(function (student) {
                _this.model.student = student;
            });
        }
    };
    SalesDetailPageComponent.prototype.loadJobAndEnterprise = function (sale) {
        var _this = this;
        if (sale.jobId) {
            this.jobService.getDetail(sale.jobId).subscribe(function (job) {
                _this.model.job = job;
                if (job && job.enterpriseId) {
                    var enterpriseFrontIdSubject_1 = new Subject();
                    _this.enterpriseService.getDetail(job.enterpriseId).subscribe(function (enterprise) {
                        _this.model.enterprise = enterprise;
                        enterpriseFrontIdSubject_1.next(enterprise && enterprise.frontId ? enterprise.frontId : '');
                        enterpriseFrontIdSubject_1.unsubscribe();
                    });
                    _this.enterpriseService.getContracts(job.enterpriseId).subscribe(function (contracts) {
                        _this.contractOptions = contracts.map(function (contract) {
                            contract.dynamicData.id = contract.id;
                            contract.dynamicData.frontId = contract.frontId;
                            contract.dynamicData.label = [
                                contract.frontId,
                                contract.dynamicData.companyName,
                                contract.dynamicData.departmentName,
                                contract.dynamicData.contractClassification,
                            ]
                                .filter(function (v) { return v; })
                                .join(' ');
                            return contract.dynamicData;
                        });
                        _this.contractSubject.next(_this.contractOptions);
                        var text = '';
                        if (_this.sale && _this.sale.contractId) {
                            var contract = _this.contractOptions.find(function (contr) {
                                return contr.id === _this.sale.contractId;
                            });
                            if (contract) {
                                for (var i = 1; i < 6; i++) {
                                    if (contract['fee' + i + 'Price']) {
                                        text += _this.numberFormat.transform(contract['fee' + i + 'Price']) + "\n\u30D5\u30A3\u30FC\u898F\u5B9A\u5099\u8003: " + contract['fee' + i + 'Remarks'] + "\n\n";
                                    }
                                }
                            }
                        }
                        _this.model['contract-priceInformation'] = text;
                        _this.rightForm.updateModel('sales.contractId');
                    });
                    _this.enterpriseService.getDepartments(job.enterpriseId).subscribe(function (contacts) {
                        _this.model.department = contacts.enterpriseDepartments.find(function (department) { return department.id === job.enterpriseDepartmentId; });
                        _this.billingOptions =
                            contacts && contacts.enterpriseBillingAddresses && contacts.enterpriseBillingAddresses.length
                                ? contacts.enterpriseBillingAddresses.map(function (billing) {
                                    billing.dynamicData.id = billing.id;
                                    billing.dynamicData.label = [
                                        billing.dynamicData.companyName,
                                        billing.dynamicData.departmentName,
                                        billing.dynamicData.contact.name,
                                    ]
                                        .filter(function (v) { return v; })
                                        .join(' ');
                                    return billing.dynamicData;
                                })
                                : [];
                        if (enterpriseFrontIdSubject_1.closed) {
                            _this.billingSubject.next({
                                billingOptions: _this.billingOptions,
                                enterpriseFrontid: _this.model.enterprise && _this.model.enterprise.frontId ? _this.model.enterprise.frontId : '',
                            });
                            _this.rightForm.updateModel('sales.enterpriseBillingAddressId');
                        }
                        else {
                            enterpriseFrontIdSubject_1.subscribe(function (frontId) {
                                _this.billingSubject.next({ billingOptions: _this.billingOptions, enterpriseFrontid: frontId });
                                _this.rightForm.updateModel('sales.enterpriseBillingAddressId');
                            });
                        }
                    });
                }
            });
        }
    };
    SalesDetailPageComponent.prototype.getFormMetadataLeft = function (approvalStatus, cancellationStaus) {
        var _a, _b, _c, _d;
        approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0
        cancellationStaus = cancellationStaus || 0;
        var res = {
            groups: [],
        };
        var overview = this.overviewHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_a = res.groups).push.apply(_a, overview);
        var closingInfo = this.closingInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_b = res.groups).push.apply(_b, closingInfo);
        var orderInfo = this.orderInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_c = res.groups).push.apply(_c, orderInfo);
        this.cancellationInfo = this.cancellationInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_d = res.groups).push.apply(_d, this.cancellationInfo);
        res.groups = res.groups.filter(function (group) { return (group ? true : false); });
        return res;
    };
    SalesDetailPageComponent.prototype.getFormMetadataRight = function (approvalStatus, cancellationStaus) {
        var _a, _b, _c;
        approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0
        cancellationStaus = cancellationStaus || 0;
        var res = {
            groups: [],
        };
        var contractorInfo = this.contractorInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_a = res.groups).push.apply(_a, contractorInfo);
        var billingInfo = this.billingInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_b = res.groups).push.apply(_b, billingInfo);
        this.billingExtraInfo = this.billingExtraInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_c = res.groups).push.apply(_c, this.billingExtraInfo);
        res.groups = res.groups.filter(function (group) { return (group ? true : false); });
        return res;
    };
    SalesDetailPageComponent.prototype.getFormMetadataBottom = function (approvalStatus, cancellationStaus) {
        var _a, _b;
        approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0
        cancellationStaus = cancellationStaus || 0;
        var res = {
            groups: [],
        };
        var accounting = this.accountingInformationHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_a = res.groups).push.apply(_a, accounting);
        var remrks = this.remarkHelper.getMeta(this.fieldsUpToDate, approvalStatus, cancellationStaus);
        (_b = res.groups).push.apply(_b, remrks);
        res.groups = res.groups.filter(function (group) { return (group ? true : false); });
        return res;
    };
    SalesDetailPageComponent.prototype.getFooterButtons = function (approvalStatus) {
        approvalStatus = approvalStatus || 0; // undefined or null will be threated as 0
        return this.buttonMetaByState[approvalStatus];
    };
    SalesDetailPageComponent.prototype.onValidityChange = function (validity, index) {
        this.validityArray[index] = validity;
        this.validity = this.validityArray.find(function (v) { return !v; }) == null;
    };
    SalesDetailPageComponent.prototype.resetFormForApprovalCancellation = function () {
        var _this = this;
        // clear dynamic fields
        var editableCancellationFieldNames = [];
        if (this.cancellationInfo && this.cancellationInfo.length) {
            editableCancellationFieldNames.push.apply(editableCancellationFieldNames, this.cancellationInfo[0].rows.filter(function (row) { return row.fields.length; }).map(function (item) { return item.fields[0].name; }));
        }
        if (this.billingExtraInfo && this.billingExtraInfo.length) {
            editableCancellationFieldNames.push.apply(editableCancellationFieldNames, this.billingExtraInfo[0].rows.filter(function (row) { return row.fields.length; }).map(function (item) { return item.fields[0].name; }));
        }
        editableCancellationFieldNames.forEach(function (name) {
            var splitted = name.split('.');
            var accessAndClear = function (value, level) {
                if (level < splitted.length - 1) {
                    accessAndClear(value[splitted[level]], level + 1);
                }
                else {
                    value[splitted[level]] = null;
                }
            };
            accessAndClear(_this.model, 0);
        });
        // clear static fields
        this.model.sales.caCancelPrice = null;
        this.model.sales.raCancelPrice = null;
        this.model.sales.paCancelPrice = null;
    };
    SalesDetailPageComponent.prototype.setStatusAndUpdate = function (newStatus, cancellationStatus, preconditionHook, successAction) {
        if (this.sale) {
            this.updateSale(newStatus, cancellationStatus, preconditionHook, successAction);
        }
    };
    SalesDetailPageComponent.prototype.updateSaleInternal = function (newStatus, cancellationStatus, successAction, context) {
        var _this = this;
        this.salesApiService.update(this.sale, newStatus, cancellationStatus).subscribe(function () {
            _this.sale.status = newStatus;
            _this.sale.cancelStatus = cancellationStatus;
            _this.updateAllMetadata();
            if (successAction) {
                successAction(context);
            }
            _this.salesApiService.refreshEvent.emit();
            _this.updatePending = false;
        }, function () {
            _this.updatePending = false;
        });
    };
    SalesDetailPageComponent.prototype.updateSale = function (newStatus, cancellationStatus, preconditionHook, successAction) {
        var _this = this;
        if (this.sale) {
            this.updatePending = true;
            if (preconditionHook) {
                preconditionHook().then(function (context) {
                    _this.updateSaleInternal(newStatus, cancellationStatus, successAction, context);
                }, function () { return (_this.updatePending = false); });
            }
            else {
                this.updateSaleInternal(newStatus, cancellationStatus, successAction);
            }
        }
    };
    SalesDetailPageComponent.prototype.updateSaleInternalBtoB = function () {
        var _this = this;
        this.salesApiService.linkBtoBSale(this.sale.id).subscribe(function () {
            _this.updateAllMetadata();
            _this.salesApiService.refreshEvent.emit();
            _this.updatePending = false;
        }, function () {
            _this.updatePending = false;
        });
    };
    SalesDetailPageComponent.prototype.updateSaleBtoB = function () {
        if (this.sale) {
            this.updatePending = true;
            this.updateSaleInternalBtoB();
        }
    };
    SalesDetailPageComponent.prototype.openSaleLinkBtoBDialog = function () {
        var _this = this;
        if (!(this.sale && this.sale.status === 2)) {
            return;
        }
        var config = {
            title: '請求書連携 → BtoB',
            messages: ["\u3053\u306E\u5236\u7D04[" + this.sale.frontId + "]\u3092BtoB\u3078\u9023\u643A\u3057\u3001\u8ACB\u6C42\u66F8\u3092\u4F5C\u6210\u3057\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"],
            style: {
                height: 'auto',
                width: '600px',
            },
            buttons: {
                no: 'キャンセル',
                yes: 'OK',
            },
        };
        this.dialogService.open(ConfirmDialogComponent, config, function (result) {
            if (result) {
                _this.updateSaleBtoB();
            }
        });
    };
    SalesDetailPageComponent.prototype.sendSalesNotificationPreconditions = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.sale && _this.sale.id != null && _this.sale.raUserId) {
                var teams = _this.masterApiService.getTeams();
                var users = _this.userApiService.getAll();
                var templates = _this.mailApiService.getMailTemplates();
                forkJoin([teams, users, templates]).subscribe(function (results) {
                    var context = {};
                    context.raUser = results[1].find(function (user) { return user.id === _this.sale.raUserId; });
                    context.caUser = results[1].find(function (user) { return user.id === _this.sale.caUserId; });
                    context.paUser = results[1].find(function (user) { return user.id === _this.sale.paUserId; });
                    context.raTeam = results[0].find(function (team) { return team.id === context.raUser.teamId; });
                    context.template = results[2].find(function (mailTemplate) { return mailTemplate.id === 22; });
                    var issues = [];
                    if (!(context.raTeam && context.raTeam.manager)) {
                        issues.push(' 担当RAのチームに代表者が設定されていません。');
                    }
                    if (!context.template) {
                        issues.push('メールテンプレートが設定されていません');
                    }
                    if (!(_this.authService.loginUser && _this.authService.loginUser.email) ||
                        (context.raTeam.manager && !context.raTeam.manager.email)) {
                        issues.push('Technical issue with user emails');
                    }
                    if (issues.length) {
                        var config = {
                            title: '入力値が不正です',
                            messages: issues,
                            style: {
                                height: '245px',
                                width: '510px',
                            },
                            buttons: {
                                no: 'キャンセル',
                                hideNo: true,
                                yes: 'OK',
                            },
                        };
                        _this.dialogService.open(ConfirmDialogComponent, config);
                        reject(issues);
                    }
                    else {
                        _this.emailModel.email = null;
                        PopupControlComponent.instance.open({
                            title: '再申請',
                            content: _this.reApplyPopupTemplate,
                            confirmText: '再申請',
                            confirmEnabled: function () { return _this.emailModel['email'] != null && _this.emailModel['email'].trim(); },
                            confirmCallback: function () {
                                resolve(context);
                            },
                            cancelText: 'キャンセル',
                            cancelCallback: function () { return reject('cancel'); },
                        });
                    }
                });
            }
            else {
                reject([]);
            }
        });
    };
    SalesDetailPageComponent.prototype.sendSalesNotificationEmail = function (context) {
        var _this = this;
        if (this.sale && this.sale.id != null && this.sale.raUserId) {
            var notifyUsers = [context.raUser, context.caUser, context.paUser];
            var salesNotificationRequest_1 = {
                userId: this.authService.loginUser.id,
                subject: context.template.subject,
                text: "" + (context.template.body && context.template.body.trim() ? context.template.body.trim() + '\n\n' : '') + this.emailModel['email'].trim(),
                from: this.authService.loginUser.email,
                to: [context.raTeam.manager.email],
                cc: notifyUsers.filter(function (u) { return u && u.email; }).map(function (usr) { return usr.email; }),
                salesId: this.sale.id,
            };
            this.mailApiService
                .replaceTemplate(context.template.mailTemplateTypeId, {
                from: salesNotificationRequest_1.from,
                to: salesNotificationRequest_1.to,
                salesId: salesNotificationRequest_1.salesId,
                subject: salesNotificationRequest_1.subject,
                text: salesNotificationRequest_1.text,
            })
                .pipe(mergeMap(function (replaced) {
                return _this.mailApiService.sendSalesNotification(tslib_1.__assign({}, salesNotificationRequest_1, { text: replaced.body, subject: replaced.subject }));
            }))
                .subscribe();
        }
    };
    return SalesDetailPageComponent;
}(DetailPage));
export { SalesDetailPageComponent };
