import * as tslib_1 from "tslib";
import { MatchingCreateDialogComponent } from '@agent-ds/matching/components/matching-create-dialog/matching-create-dialog.component';
import { ProgressCreateDialogComponent } from '@agent-ds/progress/components';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { JobPostingConfig } from '@agent-ds/shared/components/mail-send/configs/job-posting-config';
import { DetailPage } from '@agent-ds/shared/models';
import { DialogService, JobApiService, StudentApiService } from '@agent-ds/shared/services';
import { MatchingApiService } from '@agent-ds/shared/services/api/matching-api.service';
import { deepClone, downloadResponse, getValueFromObject } from '@agent-ds/shared/util/util';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
var StudentJobMatchingPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentJobMatchingPageComponent, _super);
    function StudentJobMatchingPageComponent(matchingApi, dialog, service, studentApiService, router, activeRoute) {
        var _this = _super.call(this, router, service, activeRoute) || this;
        _this.matchingApi = matchingApi;
        _this.dialog = dialog;
        _this.service = service;
        _this.studentApiService = studentApiService;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.jobSearchObj = { keyword: '' };
        _this.matchings = [];
        _this.listConfiguration = { body: { checkbox: true, hideCreateButton: true } };
        _this.postingConfig = new JobPostingConfig();
        _this.isSecondMatchingUpdateInProgress = false;
        _this.is2ndMatchOnState = false;
        _this.sideActions = [{ phases: ['100%'] }];
        _this.tabs = {};
        _this.urlTag = 'matching/student-job';
        _this.refreshSubscription.unsubscribe();
        _this.routeSubscription.unsubscribe();
        return _this;
    }
    StudentJobMatchingPageComponent.prototype.ngOnInit = function () { };
    StudentJobMatchingPageComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.studentDetails.studentDetailLoaded.subscribe(function (student) {
            _this.student = student;
            _this.is2ndMatchOnState =
                _this.student &&
                    _this.student.dynamicData &&
                    _this.student.dynamicData.is2ndMatch &&
                    _this.student.dynamicData.is2ndMatch.length &&
                    _this.student.dynamicData.is2ndMatch[0] === '有効';
            // マッチング条件で検索項目のデフォルト値を設定する
            _this.jobSearchObj = tslib_1.__assign({ universityLevel: [student.dynamicData.universityLevel], gender: (function () {
                    var value = student.dynamicData.gender;
                    if (value === '回答しない') {
                        return ['その他'];
                    }
                    return [value];
                })(), academicFieldType: [student.dynamicData.academicFieldType], recruitYear: [student.dynamicData.supportYear], status: ['募集中'], communicationSkill: [student.dynamicData.communicationSkill], impressionAndCharm: [student.dynamicData.impressionAndCharm], 
                // 求職者: 最終学歴 -> マッチング: 最終学歴
                lastAcademicRecord: [student.dynamicData.lastAcademicRecord], 
                // 求職者: 日本語能力検定 -> マッチング: 留学生
                internationalStudent: (function () {
                    var value = student.dynamicData.japaneseLanguageProficiencyTest;
                    if (value && (value.find(function (a) { return a === 'N1'; }) ||
                        value.find(function (a) { return a === 'N2'; }) ||
                        value.find(function (a) { return a === 'N3'; }) ||
                        value.find(function (a) { return a === 'N4'; }) ||
                        value.find(function (a) { return a === 'N5'; }) ||
                        value.find(function (a) { return a === 'None（第一言語が日本語以外の言語で、日本語検定を取得していない）'; }))) {
                        return ['積極採用', '通常採用'];
                    }
                    return [];
                })(), 
                // 求職者: 勤務エリア比率（首都圏・関西・東海） -> マッチング: 勤務エリア
                workArea: (function () {
                    var workArea = [];
                    // console.log('student.dynamicData: ', student.dynamicData);
                    var metropolitanArea = student.dynamicData.workingAreaRatioMetropolitanArea;
                    // console.log('metropolitanArea: ', metropolitanArea);
                    if (metropolitanArea && metropolitanArea.length >= 1 && (metropolitanArea.find(function (a) { return a === '首都圏100%、首都圏以外0%'; }) || metropolitanArea.find(function (a) { return a === '首都圏80%、首都圏以外20%'; }) || metropolitanArea.find(function (a) { return a === '首都圏60%、首都圏以外40%'; }))) {
                        workArea.push('関東');
                    }
                    var kansaiArea = student.dynamicData.workingAreaRatioKansaiArea;
                    // console.log('kansaiArea: ', kansaiArea);
                    if (kansaiArea && kansaiArea.length >= 1 && (kansaiArea.find(function (a) { return a === '関西100%、関西以外0%'; }) || kansaiArea.find(function (a) { return a === '関西80%、関西以外20%'; }) || kansaiArea.find(function (a) { return a === '関西60%、関西以外40%'; }))) {
                        workArea.push('関西');
                    }
                    var chubuArea = student.dynamicData.workingAreaRatioChubuArea;
                    // console.log('chubuArea: ', chubuArea);
                    if (chubuArea && chubuArea.length >= 1 && (chubuArea.find(function (a) { return a === '東海100%、東海以外0%'; }) || chubuArea.find(function (a) { return a === '東海80%、東海以外20%'; }) || chubuArea.find(function (a) { return a === '東海60%、東海以外40%'; }))) {
                        workArea.push('東海');
                    }
                    // console.log('workArea: ', workArea);
                    return workArea;
                })() }, _this.jobSearchObj);
            _this.loadMatchings(student.id);
            _this.updateUrl();
        });
    };
    StudentJobMatchingPageComponent.prototype.fill = function (onRefresh) {
        if (this.referenceId) {
            this.showList = false;
            this.jobSearchObj = {};
            this.studentDetails.referenceId = this.referenceId;
            this.studentDetails.open();
        }
    };
    StudentJobMatchingPageComponent.prototype.loadMatchings = function (studentId, selectedId) {
        var _this = this;
        this.matchingApi.getMatchingsForStudent(studentId).subscribe(function (matchings) {
            _this.matchings = matchings;
            _this.selectedMatching = _this.matchings.find(function (m) { return m.id === selectedId; });
        });
    };
    StudentJobMatchingPageComponent.prototype.onFilterChanged = function (changes) {
        if (this.selectedMatching) {
            this.jobSearchObj = tslib_1.__assign({}, deepClone(this.jobSearchObj), changes);
            this.selectedMatching = undefined;
        }
        else {
            this.jobSearchObj = tslib_1.__assign({}, this.jobSearchObj, changes);
        }
    };
    StudentJobMatchingPageComponent.prototype.toggleSecondMatching = function () {
        var _this = this;
        this.isSecondMatchingUpdateInProgress = true;
        var is2ndMatchDynamicData = (this.student.dynamicData.is2ndMatch && !this.student.dynamicData.is2ndMatch.length) ||
            (this.student.dynamicData.is2ndMatch &&
                this.student.dynamicData.is2ndMatch.length &&
                this.student.dynamicData.is2ndMatch[0] !== '有効')
            ? { is2ndMatch: ['有効'] }
            : { is2ndMatch: [] };
        this.studentApiService.update(this.student.id, tslib_1.__assign({}, this.student, { dynamicData: is2ndMatchDynamicData })).subscribe(function (res) {
            _this.isSecondMatchingUpdateInProgress = false;
            _this.student = tslib_1.__assign({}, _this.student, { dynamicData: tslib_1.__assign({}, _this.student.dynamicData, is2ndMatchDynamicData) });
            _this.is2ndMatchOnState =
                _this.student &&
                    _this.student.dynamicData &&
                    _this.student.dynamicData.is2ndMatch &&
                    _this.student.dynamicData.is2ndMatch.length &&
                    _this.student.dynamicData.is2ndMatch[0] === '有効';
        }, function () {
            _this.isSecondMatchingUpdateInProgress = false;
        });
    };
    StudentJobMatchingPageComponent.prototype.matchingSelected = function (selected, matching) {
        this.selectedMatching = matching;
        this.jobSearchObj = this.selectedMatching && selected ? deepClone(this.selectedMatching.matchingParameter) : { keyword: '' };
    };
    StudentJobMatchingPageComponent.prototype.saveMatching = function () {
        var _this = this;
        this.dialog.open(MatchingCreateDialogComponent, null, function (dialogModel) {
            if (dialogModel) {
                var matching = {
                    name: dialogModel.name,
                    studentId: _this.student.id,
                    matchingParameter: _this.jobSearchObj,
                };
                return _this.matchingApi.saveMatchingForStudent(matching).pipe(tap(function (res) {
                    _this.loadMatchings(_this.student.id, res.id);
                }));
            }
        });
    };
    StudentJobMatchingPageComponent.prototype.deleteMatching = function (matching) {
        var _this = this;
        var config = {
            title: '求人検索マッチング条件の削除',
            messages: ['マッチング条件を削除してもよろしいですか？'],
            style: {
                height: '245px',
                width: '510px',
            },
            buttons: {
                no: 'キャンセル',
                yes: 'OK',
            },
        };
        this.dialog.open(ConfirmDialogComponent, config, function (confirmed) {
            if (confirmed) {
                return _this.matchingApi.deleteStudentMatching(matching.id).pipe(tap(function () {
                    _this.loadMatchings(_this.student.id);
                }));
            }
        });
    };
    StudentJobMatchingPageComponent.prototype.doJobSearch = function (params) {
        var _this = this;
        this.showList = true;
        setTimeout(function () {
            return (_this.jobSearchObj = tslib_1.__assign({}, _this.jobSearchObj, params));
        });
    };
    StudentJobMatchingPageComponent.prototype.toSearch = function () {
        DetailPage.closeAll([StudentJobMatchingPageComponent]);
        this.showList = false;
    };
    StudentJobMatchingPageComponent.prototype.getJobsAsPdf = function (jobs) {
        this.service.getJobsAsPdf(jobs.map(function (job) { return job.id; })).subscribe(function (resp) {
            downloadResponse(resp);
        });
    };
    StudentJobMatchingPageComponent.prototype.openMail = function (config) {
        MailSendFlowComponent.instance.config = config;
        config.setParams({
            jobs: this.jobList.table.checkedItems.map(function (item) { return ({
                id: item.id,
                frontId: item.frontJobId,
                position: getValueFromObject(item.dynamicData, 'position'),
                enterpriseName: item.enterpriseName,
                enterpriseId: item.enterpriseId,
                frontEnterpriseId: item.frontEnterpriseId,
                enterpriseDepartmentId: item.enterpriseDepartmentId,
            }); }),
            students: [
                {
                    id: this.student.id,
                    frontId: this.student.frontId,
                    firstName: this.student.dynamicData.firstName,
                    lastName: this.student.dynamicData.lastName,
                    age: null,
                    prefecture: null,
                    schoolName: null,
                    schoolDepartmentName: null,
                    studentUser: this.student.studentUsers,
                    emailMain: getValueFromObject(this.student, 'dynamicData.emailMain.email'),
                    emailMainAvailable: getValueFromObject(this.student, 'dynamicData.emailMain.emailAvailable'),
                    emailSub: getValueFromObject(this.student, 'dynamicData.emailSub.email'),
                    emailSubAvailable: getValueFromObject(this.student, 'dynamicData.emailSub.emailAvailable'),
                },
            ],
        });
        MailSendFlowComponent.instance.start();
    };
    StudentJobMatchingPageComponent.prototype.createProgress = function () {
        this.dialog.open(ProgressCreateDialogComponent, {
            data: { studentList: [this.student], jobList: this.jobList.checkedItems, matchingMode: true },
        });
    };
    return StudentJobMatchingPageComponent;
}(DetailPage));
export { StudentJobMatchingPageComponent };
