import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { OnInit } from '@angular/core';
var StudentPreferencesTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentPreferencesTabComponent, _super);
    function StudentPreferencesTabComponent(dynamicService, studentApiService) {
        var _this = _super.call(this) || this;
        _this.dynamicService = dynamicService;
        _this.studentApiService = studentApiService;
        _this.student = null;
        return _this;
    }
    StudentPreferencesTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadataLeft = {
                disabled: _this.readonly,
                groups: [
                    {
                        title: 'カウンセリング情報',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'activityFinish')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'activityFinishRemarks')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'socialType')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'communicationSkill')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'impressionAndCharm')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'jobHuntingAxis')), [
                            (function () {
                                var rows = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'councelInformation'));
                                rows[0].fields[0].style = { minRows: 15, maxRows: 15 };
                                return rows[0];
                            })()
                        ], _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'hrAnalysis')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'characterAndType1')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'characterAndType2')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'characterAndType3')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'characterAndType4')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'characterAndType5')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'itTypeCareer')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'itTypeArea')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'itAppealFlag')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'pgExperience')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingExperienceLanguage')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingExperience')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'salesJobFlag')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'salesJobProduct')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'jobTransfer')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'firstPlaceOfAssignment')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'overtimeWork'))),
                    },
                    {
                        title: '希望条件',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestIndustry1')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestIndustry2')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestIndustry3')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestIndustryRemarks')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestJobType1')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestJobType2')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestJobType3')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestJobTypeRemarks'))),
                    },
                ],
            };
            _this.metadataRight = {
                disabled: _this.readonly,
                groups: [
                    {
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'languageSkill')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'exceptionalExperience')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'hearingInformation')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'studentActivityUniversity')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'studentActivityParttime1')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'researchContent')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'reasonRepeating'))),
                    },
                    {
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestWorkplacePref')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'requestWorkplaceRemarks')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'locationChange'))),
                    },
                    {
                        title: '履歴書用',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'licenseHeld')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'hobbiesAndSkills')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'selfPromotion')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'effortsDuringStudentDays')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'initiativesInAcademicAndEtc'))),
                    },
                ],
            };
            _this.metadataOther = {
                disabled: _this.readonly,
                groups: [
                    {
                        title: 'その他情報',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'cSetteiReason')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'cSetteiReasonWorkplace')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'cSetteiReasonDetail'))),
                    },
                ],
            };
        });
    };
    StudentPreferencesTabComponent.prototype.save = function () {
        var _this = this;
        this.studentApiService.update(this.student.id, this.student).subscribe(function (res) { return _this.studentApiService.refreshEvent.emit(); });
    };
    StudentPreferencesTabComponent.prototype.onChangedMetadataLeft = function (value) {
        if (this.student && this.student.dynamicData && value === 'hrAnalysis') {
            // 対応表 HR Anaryst診断結果から、性格・タイプ1-3を自動で埋める実装
            // https://benesse-g.backlog.jp/view/MACH_PJ-12#comment-1327513400
            if (this.student.dynamicData.hrAnalysis === 'イメージタイプ') {
                this.student.dynamicData.characterAndType1 = '主張';
                this.student.dynamicData.characterAndType2 = '他者・社会';
                this.student.dynamicData.characterAndType3 = '感性・行動';
            }
            else if (this.student.dynamicData.hrAnalysis === 'プロセスタイプ') {
                this.student.dynamicData.characterAndType1 = '主張';
                this.student.dynamicData.characterAndType2 = '他者・社会';
                this.student.dynamicData.characterAndType3 = '理性・思考';
            }
            else if (this.student.dynamicData.hrAnalysis === 'フォロータイプ') {
                this.student.dynamicData.characterAndType1 = '受容';
                this.student.dynamicData.characterAndType2 = '他者・社会';
                this.student.dynamicData.characterAndType3 = '感性・行動';
            }
            else if (this.student.dynamicData.hrAnalysis === 'オフィサータイプ') {
                this.student.dynamicData.characterAndType1 = '受容';
                this.student.dynamicData.characterAndType2 = '他者・社会';
                this.student.dynamicData.characterAndType3 = '理性・思考';
            }
            else if (this.student.dynamicData.hrAnalysis === 'アピールタイプ') {
                this.student.dynamicData.characterAndType1 = '主張';
                this.student.dynamicData.characterAndType2 = '自分';
                this.student.dynamicData.characterAndType3 = '感性・行動';
            }
            else if (this.student.dynamicData.hrAnalysis === 'エフェクトタイプ') {
                this.student.dynamicData.characterAndType1 = '主張';
                this.student.dynamicData.characterAndType2 = '自分';
                this.student.dynamicData.characterAndType3 = '理性・思考';
            }
            else if (this.student.dynamicData.hrAnalysis === 'アジャストタイプ') {
                this.student.dynamicData.characterAndType1 = '受容';
                this.student.dynamicData.characterAndType2 = '自分';
                this.student.dynamicData.characterAndType3 = '感性・行動';
            }
            else if (this.student.dynamicData.hrAnalysis === 'トリックタイプ') {
                this.student.dynamicData.characterAndType1 = '受容';
                this.student.dynamicData.characterAndType2 = '自分';
                this.student.dynamicData.characterAndType3 = '理性・思考';
            }
            else {
                // do nothing
            }
        }
        else {
            // do nothing
        }
    };
    return StudentPreferencesTabComponent;
}(Tab));
export { StudentPreferencesTabComponent };
