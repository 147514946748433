/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tab.component";
var styles_TabComponent = [i0.styles];
var RenderType_TabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
function View_TabComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_TabComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_TabComponent_1))], null, null); }
export function View_TabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-tab", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i1.ɵdid(1, 114688, null, 1, i2.TabComponent, [], null, null), i1.ɵqud(603979776, 1, { tabChild: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabComponentNgFactory = i1.ɵccf("ag-tab", i2.TabComponent, View_TabComponent_Host_0, { label: "label" }, {}, ["*"]);
export { TabComponentNgFactory as TabComponentNgFactory };
