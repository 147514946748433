import * as tslib_1 from "tslib";
import { HelperBase } from './helper-base';
var RemarkHelper = /** @class */ (function (_super) {
    tslib_1.__extends(RemarkHelper, _super);
    function RemarkHelper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.metadata = { rows: [] };
        return _this;
    }
    RemarkHelper.prototype.updateFieldDefinitions = function () {
        this.metadata = {
            // Remarks
            title: '備考',
            class: 'form__group--golden-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'remarks'), 'sales.dynamicData', 'fill').slice(),
        };
    };
    RemarkHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.metadata];
    };
    return RemarkHelper;
}(HelperBase));
export { RemarkHelper };
