<div class="content">
  <div class="content__controls">
    <input
      class="content__controls__select-all"
      type="checkbox"
      [checked]="table?.allChecked"
      (click)="table?.checkAll(table.allChecked)"
    />
    <span class="content__controls__count">{{
      table?.checkedItems.length || table?.totalSize
    }}</span>
    <span class="content__controls__label">{{
      table?.checkedItems.length ? "件選択中" : "件"
    }}</span>
    <button
      [disabled]="!table?.checkedItems.length"
      class="content__controls__control"
      (click)="openMailSend(job ? progressBulkSendConfig : bulkSendConfig)"
    >
      一斉メール配信
    </button>
    <button
      [disabled]="!table?.checkedItems.length"
      class="content__controls__control"
      (click)="openMailSend(jobSendConfig)"
    >
      一斉打診
    </button>
    <button
      [disabled]="!table?.checkedItems.length"
      (click)="openBatchUpdateDialog()"
      class="content__controls__control"
      *ngIf="!options?.body?.hideBatchUpdateButton"
    >
      一括更新
    </button>
    <div *ngTemplateOutlet="extraControls"></div>
    <div
      class="content__controls__refresh"
      (click)="table.reset(true); table.next()"
    >
      <div class="content__controls__refresh-icon">
        <svg class="content__controls__refresh--svg">
          <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
        </svg>
      </div>
      最新情報に更新
    </div>
    <button
      class="content__controls__control content__controls__control--create"
      (click)="openStudentCreateDialog()"
      *ngIf="!options?.body?.hideCreateButton"
    >
      新規求職者登録
    </button>
  </div>
</div>
<ag-page-scroll-table
  class="content__table"
  [supplier]="loadList"
  (itemSelected)="onItemSelected($event)"
></ag-page-scroll-table>

<ng-template #img let-data>
  <div class="profile-pic-wrapper">
    <ag-profile-image
      *ngIf="data"
      class="profile-pic"
      [id]="data?.id"
      [size]="80"
      [displaySize]="53"
    ></ag-profile-image>
    <span
      *ngIf="data?.dynamicData.rank"
      class="rank rank--{{ data?.dynamicData.rank }}"
      >{{ data?.dynamicData.rank }}</span
    >
  </div>
</ng-template>

<ng-template #message let-data>
  <span
    class="message"
    [class.message--inactive]="!data.studentNotes?.length"
    title="{{ data.studentNotes?.length ? data.studentNotes[0].note : '' }}"
  >
    <span class="message__info">伝言</span>
    <span *ngIf="data.studentNotes?.length" class="message__text">{{
      data.studentNotes[0].note
    }}</span>
  </span>
</ng-template>

<ng-template #general let-data>
  <span *ngIf="data.dynamicData" class="general"
    >({{
      ((data.dynamicData.birthday | age) || "-") +
        "歳 " +
        (data.dynamicData.gender || "")
    }})</span
  >
  <span
    *ngIf="
      data.dynamicData?.is2ndMatch && data.dynamicData.is2ndMatch[0] === '有効'
    "
    class="ndMatch"
    >✔︎2ndマッチ</span
  >
</ng-template>

<ng-template #registrationStatus let-data>
  <span
    *ngIf="data.dynamicData"
    class="registration-status"
    [class.--red]="data.dynamicData.registrationStatus === '仮登録'"
    >{{ data.dynamicData.registrationStatus }}</span
  >
</ng-template>

<ng-template #basic let-data>
  <div
    *ngIf="
      data?.dynamicData?.academicHistory1?.graduateYear &&
      data?.dynamicData?.academicHistory1?.graduateType
    "
    class="basic-holder"
  >
    <img
      src="/assets/icons/graduation-cap-solid.svg"
      class="basic-holder__basic-pic"
    />
    <span
      >{{ data?.dynamicData?.academicHistory1?.graduateYear }}年{{
        data?.dynamicData?.academicHistory1?.graduateMonth
      }}月{{ data?.dynamicData?.academicHistory1?.graduateType }}</span
    >
  </div>
  <div
    class="basic-holder"
    *ngIf="
      data?.dynamicData?.mobileTel?.tel || data?.dynamicData?.houseTel?.tel
    "
  >
    <img
      src="/assets/icons/mobile-alt-solid.svg"
      class="basic-holder__basic-pic"
    />
    <span>
      {{
        data?.dynamicData?.mobileTel?.tel
          ? data?.dynamicData?.mobileTel?.tel
          : data?.dynamicData?.houseTel?.tel
      }}
    </span>
  </div>
</ng-template>

<ng-template #school let-data>
  <div class="school-info">
    <span
      *ngIf="data?.dynamicData?.academicFieldType"
      class="school-info__flag"
      [class.school-info__flag--pink]="
        data?.dynamicData?.academicFieldType?.includes('文')
      "
      [class.school-info__flag--blue]="
        data?.dynamicData?.academicFieldType?.includes('理')
      "
      >{{
        data?.dynamicData.academicFieldType.includes("文") ? "文" : "理"
      }}</span
    >
    <span class="school-info__name">{{
      data?.dynamicData?.academicHistory1?.schoolName
    }}</span>
    <div class="school-info__university">
      <div class="school-info__class" *ngIf="data.dynamicData.universityLevel">
        {{ data.dynamicData.universityLevel[0] }}
      </div>
      <span>{{ data?.dynamicData?.graduateType }}</span>
      <span>{{ data?.dynamicData?.academicHistory1?.departmentName }}</span>
    </div>
  </div>
</ng-template>

<ng-template #inCharge let-data>
  <div
    class="in-charge"
    *ngIf="
      data.studentUser &&
      data.studentUser.length &&
      (userService.getAll()
        | async
        | find: data.studentUser[0].userId:'id') as user
    "
  >
    <div class="in-charge__team">
      {{
        (
          (masterService.getTeams() | async | find: user.teamId:"id") || {
            name: ""
          }
        ).name
      }}
    </div>
    <div class="in-charge__user">{{ user.name }}</div>
  </div>
</ng-template>

<ng-template #actions let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <p
      class="actions__action"
      (click)="onStudentLinkClick($event, data, tabs[tabs.CONTACTS])"
    >
      対応履歴<span
        class="actions__action__arrow actions__action__arrow--right"
      ></span>
    </p>
    <p
      class="actions__action"
      (click)="onStudentLinkClick($event, data, tabs[tabs.MATCHES])"
    >
      マッチング<span
        class="actions__action__arrow actions__action__arrow--right"
      ></span>
    </p>
    <p
      class="actions__action"
      (click)="onStudentLinkClick($event, data, tabs[tabs.PROGRESS])"
    >
      進捗<span
        class="actions__action__arrow actions__action__arrow--right"
      ></span>
    </p>
  </div>
</ng-template>
