import * as tslib_1 from "tslib";
import { SortOrder } from '@agent-ds/shared/components/page-scroll-table/table-interface';
export var SALE_LIST_TABLE_CONFIG = function (agTemplate, rowHeaderCompanyTemplate, rowHeaderJobSeekerTemplate, personInChargeTemplate, dateFormat, statusFormat, cancelstatusFormat, numberFormat, options) {
    var checkbox = options && options.body && options.body.checkbox;
    var resp = {
        head: {
            sticky: true,
            config: [
                {
                    columns: [
                        {
                            fields: [
                                {
                                    name: 'AGPlus',
                                    hidden: true,
                                    cellTemplate: agTemplate,
                                },
                            ],
                            style: {
                                width: checkbox ? 'unset' : '40px',
                                'justify-content': 'flex-start',
                                'padding-top': '0px',
                                'padding-left': '0px',
                                'padding-bottom': '0px',
                                position: checkbox ? 'absolute' : 'relative',
                                'z-index': -1,
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'company',
                                    hidden: true,
                                    cellTemplate: rowHeaderCompanyTemplate,
                                },
                            ],
                            style: {
                                width: '425px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'student',
                                    hidden: true,
                                    cellTemplate: rowHeaderJobSeekerTemplate,
                                },
                            ],
                            style: {
                                width: '425px',
                                'padding-left': '15px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'placeholder',
                                    hidden: true,
                                },
                            ],
                            style: {
                                flex: 1,
                            },
                        },
                    ],
                    hidden: true,
                    dynamicStyle: {
                        'min-height': function () { return '60px'; },
                    },
                },
                {
                    columns: [
                        {
                            fields: [
                                {
                                    name: 'id',
                                    title: '成約ID',
                                    defaultSort: SortOrder.DESCENDING,
                                    formatter: function (data) { return data.frontId; },
                                },
                            ],
                            style: {
                                width: checkbox ? '76px' : '115px',
                                'padding-left': checkbox ? '0px' : '39px',
                            },
                            bodyStyle: {
                                'font-family': 'Meiryo Ui',
                                'font-size': '14px',
                                'padding-left': '39px',
                                width: '115px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'personsInCharge',
                                    title: '担当者',
                                    cellTemplate: personInChargeTemplate,
                                    sortable: false,
                                },
                            ],
                            style: {
                                width: '350px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'contractDate',
                                    title: '成約日',
                                    formatter: function (data) { return (data.dynamicData ? dateFormat(data.dynamicData.contractDate) : ''); },
                                    sortable: false,
                                },
                                {
                                    name: 'enterDate',
                                    title: '入社日',
                                    formatter: function (data) { return (data.dynamicData ? dateFormat(data.dynamicData.enterDate) : ''); },
                                    sortable: false,
                                },
                            ],
                            style: {
                                width: '100px',
                                'padding-left': '15px',
                            },
                            bodyStyle: {
                                height: '70px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'fee',
                                    formatter: function (data) { return (data.dynamicData && data.dynamicData.fee ? numberFormat(data.dynamicData.fee) + '円' : ''); },
                                    title: 'フィー',
                                },
                            ],
                            style: {
                                width: '103px',
                                'padding-left': '40px',
                                'text-align': 'center',
                            },
                            bodyStyle: {
                                'padding-left': '0px',
                                'text-align': 'right',
                                width: '103px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'status',
                                    title: '承認区分',
                                    sortable: false,
                                    formatter: function (data) { return statusFormat(data.status); },
                                },
                            ],
                            style: {
                                width: '90px',
                                'margin-left': '30px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'cancel_approval_indicator',
                                    title: 'キャンセル承認区分',
                                    formatter: function (data) { return (data.cancelStatus != null ? cancelstatusFormat(data.cancelStatus) : ''); },
                                    sortable: false,
                                },
                                {
                                    name: 'Cancellation date',
                                    title: 'キャンセル日',
                                    formatter: function (data) { return (data.dynamicData && data.dynamicData.cancelDate ? dateFormat(data.dynamicData.cancelDate) : ''); },
                                    sortable: false,
                                },
                            ],
                            style: {
                                width: '150px',
                            },
                            bodyStyle: {
                                width: '132px',
                            },
                        },
                        {
                            fields: [
                                {
                                    name: 'refundDate',
                                    title: '返金日',
                                    formatter: function (data) { return (data.dynamicData && data.dynamicData.refundDate ? dateFormat(data.dynamicData.refundDate) : ''); },
                                    sortable: false,
                                },
                            ],
                        },
                    ],
                    dynamicStyle: {
                        'background-color': function (data) { return '#F7F7F7'; },
                        margin: function (data) { return '1px'; },
                    },
                },
            ],
        },
        body: tslib_1.__assign({ checkbox: false, arrow: true }, (options ? options.body : {})),
    };
    var _loop_1 = function (option) {
        if (!options[option]) {
            resp.head.config.forEach(function (cfg) {
                cfg.columns = cfg.columns.filter(function (col) { return !(col.fields.length && col.fields[0].name === option); });
            });
        }
    };
    // filter disabled columns
    for (var option in options) {
        _loop_1(option);
    }
    return resp;
};
