<div class="status">
  <ag-dynamic-form
    *ngIf="metadataHead && job"
    [metadata]="metadataHead"
    [model]="job"
  ></ag-dynamic-form>
</div>

<div class="block--leftCol">
  <div class="block block--general">
    <div class="block__head">
      <div class="block__title">求人要項</div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.DETAIL)">
        編集
      </div>
    </div>
    <div class="block__body block__body--general">
      <div class="block__row block__row--first block__row--light">担当RA</div>
      <div
        class="block__row block__row--content block__row--light block__row--gap-10"
      >
        <ng-container
          *ngIf="
            job?.enterpriseDepartmentUsers
              | find: userTypes.RA:'type' as depUser
          "
        >
          <ng-container
            *ngIf="
              userApiService.getAll()
                | async
                | find: depUser.userId:'id' as user
            "
          >
            <span>{{ user?.name }}</span>
            <span>{{
              (
                masterApiService.getTeams()
                | async
                | find: user.teamId:"id" || { name: "" }
              ).name
            }}</span>
          </ng-container>
        </ng-container>
      </div>

      <div class="block__row block__row--first block__row--light">担当PA</div>
      <div
        class="block__row block__row--content block__row--light block__row--gap-10"
      >
        <ng-container
          *ngIf="
            job?.enterpriseDepartmentUsers
              | find: userTypes.PA:'type' as depUser
          "
        >
          <ng-container
            *ngIf="
              userApiService.getAll()
                | async
                | find: depUser.userId:'id' as user
            "
          >
            <span>{{ user?.name }}</span>
            <span>{{
              (
                masterApiService.getTeams()
                | async
                | find: user?.teamId:"id" || { name: "" }
              ).name
            }}</span>
          </ng-container>
        </ng-container>
      </div>

      <div class="block__row block__row--first block__row--light">
        担当アシスタント
      </div>
      <div
        class="block__row block__row--content block__row--light block__row--gap-10"
      >
        <ng-container
          *ngIf="
            job?.enterpriseDepartmentUsers
              | find: userTypes['アシ']:'type' as depUser
          "
        >
          <ng-container
            *ngIf="
              userApiService.getAll()
                | async
                | find: depUser.userId:'id' as user
            "
          >
            <span>{{ user?.name }}</span>
            <span>{{
              (
                masterApiService.getTeams()
                | async
                | find: user.teamId:"id" || { name: "" }
              ).name
            }}</span>
          </ng-container>
        </ng-container>
      </div>

      <div class="block__row block__row--first block__row--light">職種</div>
      <div class="block__row block__row--content block__row--light">
        <ng-container
          *ngIf="
            job?.dynamicData?.jobType1?.code2 ||
            job?.dynamicData?.jobType1?.code1 as jobTypeCode
          "
        >
          {{
            (
              (masterApiService.getFlattenedJobTypes()
                | async
                | find: jobTypeCode:"code") || { name: "" }
            ).name
          }}
        </ng-container>
      </div>

      <div class="block__row block__row--first block__row--light">学歴</div>
      <div class="block__row block__row--content block__row--light">
        <ng-container
          *ngIf="
            job?.dynamicData?.lastAcademicRecord &&
            job?.dynamicData?.lastAcademicRecord.length
          "
        >
          {{ (job?.dynamicData?.lastAcademicRecord)[0] }}
        </ng-container>
      </div>

      <div class="block__row block__row--first block__row--light">
        案件ランク
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.rank }}
      </div>

      <div class="block__row block__row--first block__row--light">
        コミュニケーション力
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.communicationSkill }}
      </div>

      <div class="block__row block__row--first block__row--light">
        印象・愛嬌
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.impressionAndCharm }}
      </div>

      <div class="block__row block__row--first block__row--light">性別</div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.gender }}
      </div>

      <div class="block__row block__row--first block__row--light">
        性格・タイプ①
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.characterAndType1 }}
      </div>

      <div class="block__row block__row--first block__row--light">
        性格・タイプ②
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.characterAndType2 }}
      </div>

      <div class="block__row block__row--first block__row--light">
        性格・タイプ③
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.characterAndType3 }}
      </div>

      <div class="block__row block__row--first block__row--light">
        性格・タイプ④
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.characterAndType4 }}
      </div>

      <div class="block__row block__row--first block__row--light">
        性格・タイプ⑤
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.characterAndType5 }}
      </div>

      <div class="block__row block__row--first block__row--light">勤務地</div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.workplace | address: true }}
      </div>

      <div class="block__row block__row--first block__row--light">
        キャッチコピー
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.tagline }}
      </div>

      <div class="block__row block__row--first block__row--light">職務内容</div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.descriptionMainBody }}
      </div>
    </div>
  </div>

  <div class="block block--general">
    <div class="block__head">
      <div class="block__title">待遇</div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.DETAIL)">
        編集
      </div>
    </div>
    <div class="block__body block__body--general">
      <div class="block__row block__row--first block__row--light">雇用形態</div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.employmentMethod }}
      </div>

      <div class="block__row block__row--first block__row--light">就業時間</div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.workingHourRange?.from || "" }} -
        {{ job?.dynamicData?.workingHourRange?.to || "" }}
      </div>

      <div class="block__row block__row--first block__row--light">各種保険</div>
      <div class="block__row block__row--content block__row--light">
        <ng-container
          *ngIf="
            job?.dynamicData?.insurance && job?.dynamicData?.insurance.length
          "
        >
          <ng-container *ngFor="let insurance of job?.dynamicData?.insurance"
            >{{ insurance }}<br
          /></ng-container>
        </ng-container>
      </div>

      <div class="block__row block__row--first block__row--light">諸手当</div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.supplementDescription }}
      </div>

      <div class="block__row block__row--first block__row--light">休日休暇</div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.holidayType }}
      </div>

      <div class="block__row block__row--first block__row--light">
        年間休日
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.annualHolidays }}
      </div>
    </div>
  </div>

  <div class="block block--general">
    <div class="block__head">
      <div class="block__title">会社概要</div>
      <div
        *ngIf="company && company.id"
        class="block__control"
        (click)="onCompanyLinkClick(company.id)"
      >
        編集
      </div>
    </div>
    <div class="block__body block__body--general">
      <div class="block__row block__row--first block__row--light">
        本社所在地
      </div>
      <div class="block__row block__row--content block__row--light">
        {{
          company?.dynamicData?.address
            ? (company?.dynamicData?.address | address: true)
            : "-"
        }}
      </div>

      <div class="block__row block__row--first block__row--light">企業URL</div>
      <div class="block__row block__row--content block__row--light">
        <a class="link" [href]="company?.dynamicData?.url" target="blank">{{
          company?.dynamicData?.url
        }}</a>
      </div>

      <div class="block__row block__row--first block__row--light">業種</div>
      <div class="block__row block__row--content block__row--light">
        {{
          (
            (masterApiService.getFlattenedIndustryTypes()
              | async
              | find: company?.dynamicData?.industry:"code") || { name: "" }
          ).name
        }}
      </div>

      <div class="block__row block__row--first block__row--light">資本金</div>
      <div class="block__row block__row--content block__row--light">
        <ng-container *ngIf="company?.dynamicData">
          {{
            company?.dynamicData?.capital != null
              ? (company?.dynamicData?.capital | number: "0.0-2") +
                capitalDef?.unit
              : "-"
          }}
        </ng-container>
      </div>

      <div class="block__row block__row--first block__row--light">売上高</div>
      <div class="block__row block__row--content block__row--light">
        {{
          company?.dynamicData.salesAmount != null
            ? (company?.dynamicData.salesAmount | number: "0.0-2") +
              salesAmountDef?.unit
            : "-"
        }}
      </div>

      <div class="block__row block__row--first block__row--light">従業員数</div>
      <div class="block__row block__row--content block__row--light">
        {{
          company?.dynamicData?.numberEmployees
            ? (company?.dynamicData?.numberEmployees | number) +
              numberOfEmployeeDef?.unit
            : "-"
        }}
      </div>

      <div class="block__row block__row--first block__row--light">設立年月</div>
      <div class="block__row block__row--content block__row--light">
        {{ company?.dynamicData?.establishDate | safeDate: "yyyy/MM" }}
      </div>

      <div class="block__row block__row--first block__row--light">事業内容</div>
      <div class="block__row block__row--content block__row--light">
        {{ company?.dynamicData?.companyInfo }}
      </div>

      <div class="block__row block__row--first block__row--light">備考</div>
      <div class="block__row block__row--content block__row--light">
        {{ company?.dynamicData?.remarks }}
      </div>

      <div class="block__row block__row--first block__row--light">会社特徴</div>
      <div class="block__row block__row--content block__row--light">
        {{ company?.dynamicData?.companyStrength }}
      </div>
    </div>
  </div>

  <div class="block block--general">
    <div class="block__head">
      <div class="block__title">その他</div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.DETAIL)">
        編集
      </div>
    </div>
    <div class="block__body block__body--general">
      <div class="block__row block__row--first block__row--light">
        企業からの<br />アピールポイントなど
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ job?.dynamicData?.appealMainBody }}
      </div>
    </div>
  </div>
</div>

<div class="block--rightCol">
  <div class="block block--progress" *ngIf="!isIntakeCa">
    <div class="block__head">
      <span class="block__title"
        >進捗
        <span class="block__subtitle">更新日の最新5件を表示</span>
      </span>
      <span class="block__control" (click)="gotoIndex.emit(tabs.PROGRESSES)"
        >一覧</span
      >
    </div>
    <div class="block__body block__body--progress">
      <span class="block__row block__row--header"></span>
      <span class="block__row block__row--header">更新日</span>
      <span class="block__row block__row--header">ステータス</span>
      <span class="block__row block__row--header">求職者</span>
      <ng-container *ngFor="let progress of progresses.slice(0, 5)">
        <span
          class="block__row block__row--labeled"
          [class.block__row--labeled--AG]="progress.type === 0"
          [class.block__row--labeled--Plus]="progress.type === 1"
          [class.block__row--labeled--DR]="progress.type === 2"
        ></span>
        <span class="block__row block__row--descriptor block__row--small">{{
          progress.updatedAt | safeDate: "yyyy/MM/dd(E)"
        }}</span>
        <span class="block__row">{{
          getStatusLabel(progress.status, progress.seminarType, progress.n)
        }}</span>
        <span
          class="block__row block__row--blue link"
          (click)="onStudentLinkClick(progress.student.id)"
          >{{
            progress.student.dynamicData.lastName +
              " " +
              progress.student.dynamicData.firstName
          }}</span
        >
      </ng-container>
    </div>
  </div>

  <div class="block block--sales" *ngIf="!isIntakeCa">
    <div class="block__head">
      <span class="block__title"
        >成約情報
        <span class="block__subtitle">更新日の最新5件を表示</span>
      </span>
      <span class="block__control" (click)="gotoIndex.emit(tabs.SALES)"
        >一覧</span
      >
    </div>
    <div class="block__body block__body--sales">
      <span class="block__row block__row--header">ID-企業名</span>
      <span class="block__row block__row--header">求職者</span>
      <span class="block__row block__row--header">承認区分</span>
      <ng-container *ngFor="let sale of sales.slice(0, 5)">
        <span class="block__row block__row--vertical block__row--align-top">
          <span
            class="block__row--blue link"
            (click)="onCompanyLinkClick(sale?.enterprise?.id)"
            >{{ sale?.id }}-{{ sale?.enterprise?.dynamicData?.name }}</span
          >
          <span
            >成約日:{{
              sale?.dynamicData?.contractDate | safeDate: "yyyy/MM/dd"
            }}</span
          >
        </span>
        <span class="block__row block__row--vertical block__row--align-top">
          <span class="link" (click)="onStudentLinkClick(sale?.student?.id)">
            {{ sale?.student?.frontId }}-
            {{ sale?.student?.dynamicData?.lastName }}
            {{ sale?.student?.dynamicData?.firstName }}
            {{ sale?.student?.dynamicData?.academicFieldType }}
          </span>
          <span
            >入社日:{{
              sale?.dynamicData?.enterDate | safeDate: "yyyy/MM/dd"
            }}</span
          >
        </span>
        <span class="block__row block__row--vertical block__row--align-top">
          {{ (APPROVAL_STATES | find: sale.status:"code")?.name || "-" }}
        </span>
      </ng-container>
    </div>
  </div>

  <div class="block block--inner-info">
    <div class="block__head">
      <div class="block__title">
        求人メモ
        <div class="block__subtitle">更新日の最新5件を表示</div>
      </div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.MEMO)">
        一覧
      </div>
    </div>
    <div class="block__body block__body--inner-info">
      <div class="block__row block__row--header block__row--first">日付</div>
      <div class="block__row block__row--header">担当者</div>

      <ng-container *ngFor="let memo of memos.slice(0, 5)">
        <div
          class="block__row block__row--no-border block__row--medium block__row--first"
        >
          {{ memo?.registerDate | safeDate: "yyyy/MM/dd (E)" }}
        </div>
        <ng-container
          *ngIf="
            userApiService.getAll() | async | find: memo?.userId:'id' as user
          "
        >
          <div
            class="block__row block__row--no-border block__row--medium block__row--gap-10"
          >
            <span>{{ user?.name }}</span>
            <span>{{
              (
                masterApiService.getTeams()
                | async
                | find: user.teamId:"id" || { name: "" }
              ).name
            }}</span>
          </div>
        </ng-container>
        <div class="block__row block__row--first block__row--inner-info">
          {{ memo?.memo | truncate: truncateAt }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
