/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-files-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/pipes/based-date.pipe";
import * as i4 from "../../../../../shared/components/file-list/file-list.component.ngfactory";
import * as i5 from "../../../../../shared/components/file-list/file-list.component";
import * as i6 from "../../../../../shared/services/dialog.service";
import * as i7 from "../../../../../shared/services/api/file-api.service";
import * as i8 from "../../../../../shared/services/auth.service";
import * as i9 from "../../../../../shared/models/tab";
import * as i10 from "./student-files-tab.component";
import * as i11 from "../../../../../shared/services/api/student-api.service";
var styles_StudentFilesTabComponent = [i0.styles];
var RenderType_StudentFilesTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentFilesTabComponent, data: {} });
export { RenderType_StudentFilesTabComponent as RenderType_StudentFilesTabComponent };
function View_StudentFilesTabComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "job-history-table__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadJobFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "td", [["class", "job-history-table__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.createdAt)); _ck(_v, 4, 0, currVal_1); }); }
function View_StudentFilesTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "table", [["class", "job-history-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30D5\u30A1\u30A4\u30EB\u540D"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u767B\u9332\u65E5\u6642"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentFilesTabComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jobFiles; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_StudentFilesTabComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.BasedDatePipe, [i2.DatePipe]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-file-list", [], null, null, null, i4.View_FileListComponent_0, i4.RenderType_FileListComponent)), i1.ɵdid(2, 573440, null, 0, i5.FileListComponent, [i6.DialogService, i7.FileApiService, i8.AuthService], { reference: [0, "reference"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "job-history-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "job-history-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u4EBA\u5C65\u6B74\u7BA1\u7406"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "job-history"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentFilesTabComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.student; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.jobFiles.length > 0); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_StudentFilesTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-student-files-tab", [], null, null, null, View_StudentFilesTabComponent_0, RenderType_StudentFilesTabComponent)), i1.ɵprd(6144, null, i9.Tab, null, [i10.StudentFilesTabComponent]), i1.ɵdid(2, 638976, null, 0, i10.StudentFilesTabComponent, [i11.StudentApiService, i6.DialogService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var StudentFilesTabComponentNgFactory = i1.ɵccf("ag-student-files-tab", i10.StudentFilesTabComponent, View_StudentFilesTabComponent_Host_0, { student: "student" }, {}, []);
export { StudentFilesTabComponentNgFactory as StudentFilesTabComponentNgFactory };
