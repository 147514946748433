import * as tslib_1 from "tslib";
import { JobListComponent } from '@agent-ds/shared/components/job-list/job-list.component';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { JobPostingConfig } from '@agent-ds/shared/components/mail-send/configs/job-posting-config';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { ExportApiService, JobApiService } from '@agent-ds/shared/services';
import { download, getFileNameFromContentDispositionHeader, getValueFromObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobDetailPageComponent } from '../job-detail-page/job-detail-page.component';
var JobListPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(JobListPageComponent, _super);
    function JobListPageComponent(router, location, activeRoute, exportService, service) {
        var _this = _super.call(this, router, location, activeRoute, exportService) || this;
        _this.router = router;
        _this.location = location;
        _this.activeRoute = activeRoute;
        _this.exportService = exportService;
        _this.service = service;
        _this.model = 'jobs';
        _this.detail = JobDetailPageComponent;
        _this.exportModel = ExportModel.job;
        _this.listConfiguration = { body: { checkbox: true } };
        _this.postingConfig = new JobPostingConfig();
        return _this;
    }
    JobListPageComponent.prototype.showExport = function () {
        var _this = this;
        PopupControlComponent.instance.open({
            title: 'CSV出力',
            content: this.exportTemplate,
            confirmText: 'ダウンロード',
            confirmEnabled: function () { return _this.exportTemplateId != null; },
            confirmCallback: function () { return _this.exportService.export(_this.exportModel, _this.exportTemplateId, _this.searchObj); },
            cancelText: '閉じる',
        });
    };
    JobListPageComponent.prototype.getJobsAsPdf = function (jobs) {
        this.service.getJobsAsPdf(jobs.map(function (job) { return job.id; })).subscribe(function (resp) {
            download(resp.body, getFileNameFromContentDispositionHeader(resp));
        });
    };
    JobListPageComponent.prototype.openMail = function (config) {
        MailSendFlowComponent.instance.config = config;
        config.setParams({
            jobs: this.jobList.table.checkedItems.map(function (item) { return ({
                id: item.id,
                frontId: item.frontJobId,
                position: getValueFromObject(item.dynamicData, 'position'),
                enterpriseName: item.enterpriseName,
                enterpriseId: item.enterpriseId,
                frontEnterpriseId: item.frontEnterpriseId,
                enterpriseDepartmentId: item.enterpriseDepartmentId,
            }); }),
        });
        MailSendFlowComponent.instance.start();
    };
    return JobListPageComponent;
}(ListPage));
export { JobListPageComponent };
