import * as tslib_1 from "tslib";
import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { APPROVAL_STATES, getProgressStatusLabel } from '@agent-ds/shared/constants';
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService, MasterApiService, UserApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { OnInit } from '@angular/core';
import { JobTabs } from '../job-tabs.enum';
var DEFAULT_TRUNCATE_AT = 130;
var JobOverviewTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(JobOverviewTabComponent, _super);
    function JobOverviewTabComponent(masterApiService, userApiService, userTeamProvider, dynamicService, datePipe) {
        var _this = _super.call(this) || this;
        _this.masterApiService = masterApiService;
        _this.userApiService = userApiService;
        _this.userTeamProvider = userTeamProvider;
        _this.dynamicService = dynamicService;
        _this.datePipe = datePipe;
        _this.tabs = JobTabs;
        _this.memos = [];
        _this.progresses = [];
        _this.sales = [];
        _this.truncateAt = DEFAULT_TRUNCATE_AT;
        _this.userTypes = EnterpriseDepartmentUserType;
        _this.APPROVAL_STATES = APPROVAL_STATES;
        _this.getStatusLabel = getProgressStatusLabel;
        return _this;
    }
    JobOverviewTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadataHead = {
                groups: [
                    {
                        class: 'oneliner',
                        rows: [
                            {
                                title: '求人ステータス',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'dynamicData.status',
                                        supplier: function (val) { return (val ? val : '-'); },
                                    },
                                ],
                            },
                            {
                                title: 'エージェント\n採用人数',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'dynamicData.agentServiceHire',
                                        supplier: function (val) { return (val ? val : '-'); },
                                    },
                                ],
                            },
                            {
                                title: '更新者',
                                class: 'fill',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'updateUserId',
                                        supplier: function () { return (_this.job ? _this.userTeamProvider.getUserTeamNameById(_this.job.updateUserId) : '-'); },
                                    },
                                ],
                            },
                            {
                                title: '入力日',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'registeredAt',
                                        supplier: function (val) { return _this.datePipe.transform(val); },
                                    },
                                ],
                            },
                            {
                                title: '更新日',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'lastUpdatedAt',
                                        supplier: function (val) { return _this.datePipe.transform(val); },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
            _this.capitalDef = _this.dynamicService.getDefinition('enterprise', 'capital');
            _this.salesAmountDef = _this.dynamicService.getDefinition('enterprise', 'salesAmount');
            _this.numberOfEmployeeDef = _this.dynamicService.getDefinition('enterprise', 'numberEmployees');
        });
    };
    JobOverviewTabComponent.prototype.onCompanyLinkClick = function (id) {
        CompanyDetailPageComponent.instance.referenceId = id;
        CompanyDetailPageComponent.instance.open();
    };
    JobOverviewTabComponent.prototype.onStudentLinkClick = function (id) {
        StudentDetailPageComponent.instance.referenceId = id;
        StudentDetailPageComponent.instance.open();
    };
    return JobOverviewTabComponent;
}(Tab));
export { JobOverviewTabComponent };
