/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-image.component";
import * as i4 from "../../services/api/student-api.service";
import * as i5 from "@angular/platform-browser";
var styles_ProfileImageComponent = [i0.styles];
var RenderType_ProfileImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileImageComponent, data: {} });
export { RenderType_ProfileImageComponent as RenderType_ProfileImageComponent };
function View_ProfileImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "loading__spinner"], ["viewBox", "0 0 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["class", "loading__spinner__path"], ["cx", "25"], ["cy", "25"], ["r", "20"]], null, null, null, null, null))], null, null); }
export function View_ProfileImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image"]], [[8, "width", 0], [8, "src", 4], [2, "image__loading", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileImageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.loading; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.displaySize || _co.size); var currVal_1 = (_co.src || "assets/images/profile.png"); var currVal_2 = _co.loading; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ProfileImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-profile-image", [], null, null, null, View_ProfileImageComponent_0, RenderType_ProfileImageComponent)), i1.ɵdid(1, 638976, null, 0, i3.ProfileImageComponent, [i4.StudentApiService, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileImageComponentNgFactory = i1.ɵccf("ag-profile-image", i3.ProfileImageComponent, View_ProfileImageComponent_Host_0, { id: "id", size: "size", displaySize: "displaySize" }, {}, []);
export { ProfileImageComponentNgFactory as ProfileImageComponentNgFactory };
