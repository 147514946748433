import { ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, ValidationErrors, Validator } from '@angular/forms';
var OrderedListComponent = /** @class */ (function () {
    function OrderedListComponent(host, cdr) {
        var _this = this;
        this.host = host;
        this.cdr = cdr;
        this.valueChange = new EventEmitter();
        this.valueInner = [];
        this.alive = true;
        this.selectedItems = [];
        this.propagateChange = function () { return _this.valueChange.emit(_this.value); };
        this.propagateTouch = function () { return false; };
    }
    Object.defineProperty(OrderedListComponent.prototype, "value", {
        get: function () {
            return this.valueInner;
        },
        set: function (value) {
            this.valueInner = value || [];
            this.cdr.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    OrderedListComponent.prototype.ngOnInit = function () { };
    OrderedListComponent.prototype.ngOnDestroy = function () {
        this.alive = false;
        this.propagateChange = this.propagateTouch = function () { return false; };
    };
    OrderedListComponent.prototype.onItemClick = function (event, value) {
        var _this = this;
        if (!event.ctrlKey && !event.shiftKey) {
            this.selectedItems.length = 0;
            this.firstSelectedItem = value;
        }
        if (event.shiftKey) {
            var firstIdx_1 = this.value.indexOf(this.firstSelectedItem);
            var valIdx_1 = this.value.indexOf(value);
            this.selectedItems.length = 0;
            if (firstIdx_1 > valIdx_1) {
                this.value.forEach(function (item, index) {
                    if (index <= firstIdx_1 && index >= valIdx_1) {
                        _this.selectedItems.push(item);
                    }
                });
            }
            else if (firstIdx_1 < valIdx_1) {
                this.value.forEach(function (item, index) {
                    if (index >= firstIdx_1 && index <= valIdx_1) {
                        _this.selectedItems.push(item);
                    }
                });
            }
            else {
                this.selectedItems.push(value);
            }
        }
        else if (this.selectedItems.includes(value)) {
            this.selectedItems.remove(value);
        }
        else {
            this.selectedItems.push(value);
        }
        this.cdr.detectChanges();
    };
    OrderedListComponent.prototype.onDragStart = function (event, item) {
        var _a;
        event.dataTransfer.clearData();
        // if (event.ctrlKey) {
        OrderedListComponent.dragCopy = true;
        // }
        OrderedListComponent.dragStepRef = this;
        if (this.selectedItems.length) {
            (_a = OrderedListComponent.dragElements).push.apply(_a, this.selectedItems);
        }
        else {
            OrderedListComponent.dragElements.push(item);
        }
    };
    OrderedListComponent.prototype.onDragEnd = function (event) {
        var _this = this;
        if (!OrderedListComponent.dragCopy && OrderedListComponent.dragStepRef !== this && !this.readonly) {
            OrderedListComponent.dragElements.forEach(function (item) { return _this.value.remove(item); });
            this.propagateTouch();
            this.propagateChange();
        }
        OrderedListComponent.dragElements.length = 0;
        OrderedListComponent.dragStepRef = null;
        OrderedListComponent.dragCopy = false;
        this.cdr.detectChanges();
    };
    OrderedListComponent.prototype.onDrop = function (event) {
        var _this = this;
        if (OrderedListComponent.dragStepRef !== this && !this.readonly) {
            OrderedListComponent.dragStepRef = this;
            OrderedListComponent.dragElements.forEach(function (item) { return _this.value.include(item); });
            this.propagateTouch();
            this.propagateChange();
            this.cdr.detectChanges();
        }
    };
    OrderedListComponent.prototype.onUp = function () {
        var _this = this;
        this.value.forEach(function (item, itemIdx) {
            if (_this.selectedItems.includes(item)) {
                var replaceIdx = -1;
                for (replaceIdx = itemIdx - 1; replaceIdx > -1; replaceIdx--) {
                    if (!_this.selectedItems.includes(_this.value[replaceIdx])) {
                        break;
                    }
                }
                if (replaceIdx > -1) {
                    var replaced = _this.value[replaceIdx];
                    _this.value[replaceIdx] = item;
                    _this.value[itemIdx] = replaced;
                }
            }
        });
        this.cdr.detectChanges();
        this.propagateTouch();
        this.propagateChange();
    };
    OrderedListComponent.prototype.onDown = function () {
        for (var itemIdx = this.value.length - 1; itemIdx > -1; itemIdx--) {
            if (this.selectedItems.includes(this.value[itemIdx])) {
                var replaceIdx = this.value.length;
                for (replaceIdx = itemIdx + 1; replaceIdx < this.value.length; replaceIdx++) {
                    if (!this.selectedItems.includes(this.value[replaceIdx])) {
                        break;
                    }
                }
                if (replaceIdx < this.value.length) {
                    var replaced = this.value[replaceIdx];
                    this.value[replaceIdx] = this.value[itemIdx];
                    this.value[itemIdx] = replaced;
                }
            }
        }
        this.cdr.detectChanges();
        this.propagateTouch();
        this.propagateChange();
    };
    OrderedListComponent.prototype.writeValue = function (value) {
        this.value = value || [];
    };
    OrderedListComponent.prototype.registerOnChange = function (fn) {
        var _this = this;
        this.propagateChange = function () {
            fn(_this.value);
            _this.valueChange.emit(_this.value);
        };
    };
    OrderedListComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouch = fn;
    };
    OrderedListComponent.prototype.setDisabledState = function (isDisabled) {
        this.readonly = isDisabled;
        this.cdr.detectChanges();
    };
    OrderedListComponent.prototype.validate = function (c) {
        var _this = this;
        if (!this.alive) {
            return null;
        }
        var valid = {};
        if (this.validators) {
            Object.keys(this.validators).forEach(function (key) {
                var ret = typeof _this.validators[key] === 'function' ? _this.validators[key](c) : null;
                if ((key === 'required' && _this.validators[key] && !_this.value.length) || ret != null) {
                    valid[key] = ret || true;
                }
            });
        }
        return Object.keys(valid).length ? valid : null;
    };
    OrderedListComponent.dragElements = [];
    return OrderedListComponent;
}());
export { OrderedListComponent };
