import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { DialogService, DynamicFieldService, FileApiService, JobApiService, MailApiService } from '@agent-ds/shared/services';
import { OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { EMPTY, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
var JobMailTabComponent = /** @class */ (function () {
    function JobMailTabComponent(dynamicService, mailApiService, fileApiService, jobApiService, dialogService) {
        this.dynamicService = dynamicService;
        this.mailApiService = mailApiService;
        this.fileApiService = fileApiService;
        this.jobApiService = jobApiService;
        this.dialogService = dialogService;
        this.headMeta = {
            groups: [],
        };
        // APIレスポンスのモデル
        this.jobSettingMail = JobMailTabComponent.getJobSettingMailDefault();
        // 添付ファイルのフォームが受け付ける型がAPIのレスポンスとは合わないので、変換するためのモデル
        this.dynamicFormModel = {
            template: { id: 0 },
            enterpriseDataFileIds: [],
        };
        this.timingList = [
            { label: '-----', value: null },
            { label: '3営業日前', value: -3 },
            { label: '2営業日前', value: -2 },
            { label: '1営業日前', value: -1 },
            { label: '当日', value: 0 },
            { label: '1営業日後', value: 1 },
            { label: '2営業日後', value: 2 },
            { label: '3営業日後', value: 3 },
        ];
        // 添付ファイルHTMLテンプレート内で利用する変数
        this.enterpriseFiles = {};
        this.enterpriseFileIds = [];
        this.enterpriseInfoById = {};
    }
    // APIレスポンスのデフォルトを生成
    JobMailTabComponent.getJobSettingMailDefault = function () {
        return {
            mailTemplateId: 0,
            enterpriseDataFileIds: [],
            settings: Array(10).slice().map(function (_, index) {
                return {
                    eventDateAt: null,
                    eventDateTimeFrom: null,
                    eventDateTimeTo: null,
                    eventDateType: [],
                    url: null,
                    sendMailTiming: null,
                };
            }),
        };
    };
    JobMailTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.init();
        });
    };
    JobMailTabComponent.prototype.init = function () {
        var _this = this;
        // メールテンプレート選択、添付ファイル選択部分の dynacmic form
        this.headMeta = {
            groups: [
                {
                    title: '開催日程別メール配信',
                    class: 'form__group--green-title-border',
                    rows: [
                        {
                            title: '使用するテンプレート',
                            fields: [
                                {
                                    name: 'template',
                                    type: 'autocomplete',
                                    placeholder: 'テンプレート名',
                                    labelField: 'name',
                                    class: 'half',
                                    options: [],
                                    supplier: function (value, callType, getValue) {
                                        return _this.mailApiService.getMailTemplateNames().pipe(map(function (res) {
                                            var filtered = res.filter(function (r) { return r.status === 1 && r.mailTemplateTypeId === 1; });
                                            return {
                                                options: filtered,
                                            };
                                        }));
                                    },
                                },
                            ],
                        },
                        (function () {
                            var row = {
                                title: '添付ファイル',
                                class: 'ou',
                                fields: [
                                    // 添付ファイルを選択するフィールド
                                    {
                                        type: 'label',
                                        name: 'sendFile',
                                        class: 'half',
                                        linkTo: ['enterpriseDataFileIds'],
                                        actions: [
                                            {
                                                title: 'データフォルダから選択',
                                                type: 'RUNNABLE',
                                                runnable: function () {
                                                    _this.enterpriseFileIds = _this.dynamicFormModel.enterpriseDataFileIds.slice();
                                                    PopupControlComponent.instance.open({
                                                        content: _this.fileSelectTemplate,
                                                        confirmText: '添付',
                                                        cancelText: 'キャンセル',
                                                        title: 'データフォルダ',
                                                        confirmCallback: function () {
                                                            _this.dynamicFormModel.enterpriseDataFileIds = _this.enterpriseFileIds.slice();
                                                        },
                                                    });
                                                },
                                            },
                                        ],
                                    },
                                    // 添付済みのファイルを画面に表示するフィールド
                                    {
                                        name: 'enterpriseDataFileIds',
                                        type: 'autocomplete',
                                        labelField: [
                                            {
                                                name: 'name',
                                                class: null,
                                                action: null,
                                            },
                                        ],
                                        valueField: 'id',
                                        multi: true,
                                        class: 'options-only half',
                                        options: [],
                                        supplier: function (value, callType, getValue) {
                                            if (!_this.job) {
                                                return;
                                            }
                                            var observal = _this.fileApiService.getFiles('enterprise', _this.job.enterpriseId).pipe(map(function (res) {
                                                // 求職者への送信可能データファイルを取得
                                                _this.enterpriseFiles[_this.job.enterpriseId] = res.filter(function (f) { return f.studentSendFlag; });
                                                return { options: _this.enterpriseFiles[_this.job.enterpriseId] };
                                            }));
                                            return observal;
                                        },
                                    },
                                ],
                            };
                            return row;
                        })(),
                    ],
                },
            ],
        };
    };
    JobMailTabComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.job && this.job) {
            this.enterpriseIdForFile = this.job.enterpriseId;
            // forkJoin は rxjs での Promise.all
            forkJoin(
            // 求職者への送信可能データファイルを取得
            this.fileApiService.getFiles('enterprise', this.job.enterpriseId).pipe(map(function (res) { return res; }), catchError(function (error) {
                var dialogConfig = ConfirmDialogConfig.createErrorMessageDialog([
                    '開催日種別メール配信に添付可能なファイル情報の取得に失敗しました。',
                    error.message,
                ]);
                _this.dialogService.open(ConfirmDialogComponent, dialogConfig);
                return EMPTY;
            })), 
            // APIから開催日程メール配信情報を取得
            this.jobApiService.getJobSettingMail(this.job.id).pipe(map(function (res) { return res; }), catchError(function (error) {
                // エラー時は初期値で埋めておく
                _this.jobSettingMail = JobMailTabComponent.getJobSettingMailDefault();
                _this.dynamicFormModel = {
                    template: { id: 0 },
                    enterpriseDataFileIds: [],
                };
                // エラー時のダイアログ表示
                var dialogConfig = ConfirmDialogConfig.createErrorMessageDialog([
                    '開催日種別メール配信情報の取得に失敗しました。',
                    error.message,
                ]);
                _this.dialogService.open(ConfirmDialogComponent, dialogConfig);
                return EMPTY;
            }))).subscribe(function (response) {
                // どちらも成功したとき
                // ファイル一覧の取得
                var data = response[0];
                _this.enterpriseFiles[_this.job.enterpriseId] = data.filter(function (file) {
                    return file.studentSendFlag;
                });
                // 開催日程別メール配信の取得
                _this.jobSettingMail = response[1];
                // 設定枠に入れる値がなければ、デフォルト値を入れておく
                Array(10).slice().map(function (_, index) {
                    if (_this.jobSettingMail.settings[index]) {
                        // do nothing
                    }
                    else {
                        _this.jobSettingMail.settings[index] = JobMailTabComponent.getJobSettingMailDefault().settings[index];
                    }
                });
                // dynacmicForm のメールテンプレートが求めている型に変換する
                _this.dynamicFormModel = {
                    template: { id: _this.jobSettingMail.mailTemplateId },
                    // JobSettingMail API側で設定されているファイルを求職者に送信可能なファイルのみに絞る処理
                    enterpriseDataFileIds: _this.enterpriseFiles[_this.job.enterpriseId]
                        .filter(function (file) {
                        return file.studentSendFlag && _this.jobSettingMail.enterpriseDataFileIds.includes(file.id);
                    })
                        .map(function (file) {
                        return file.id;
                    }),
                };
            });
        }
    };
    JobMailTabComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
    };
    // settingオブジェクトのキーバリューから、url以外のいずれかが入力されている && いずれかが入力されていない場合 falseを出力
    JobMailTabComponent.prototype.isSettingValidated = function (setting) {
        if (setting.eventDateAt &&
            setting.eventDateTimeFrom &&
            setting.eventDateTimeTo &&
            setting.eventDateType.length &&
            setting.sendMailTiming != null // 0 と null/undefined を分けたいので、!=で比較
        ) {
            // すべて入力されている
            return true;
        }
        else if (!setting.eventDateAt &&
            !setting.eventDateTimeFrom &&
            !setting.eventDateTimeTo &&
            !setting.eventDateType.length &&
            setting.sendMailTiming == null //  0 と null/undefined を分けたいので、==で比較
        ) {
            // すべて入力されていない
            return true;
        }
        else {
            // いずれかが入力されている && いずれかが入力されていない
            return false;
        }
    };
    // 時間指定のfromとtoの整合性（fromがtoより大きくないか）を確認
    JobMailTabComponent.prototype.isEventDateTimeValidated = function (setting) {
        if (!setting.eventDateTimeFrom || !setting.eventDateTimeTo) {
            // どちらかが入力されていないければ、バリデーションを実施しない
            return true;
        }
        var date = new Date();
        var eventDateTimeFromDate = new Date(date.toDateString() + " " + setting.eventDateTimeFrom);
        var eventDateTimeToDate = new Date(date.toDateString() + " " + setting.eventDateTimeTo);
        // どちらかが日付オブジェクトではなかった場合の確認
        if (Number.isNaN(eventDateTimeFromDate.getDate()) || Number.isNaN(eventDateTimeToDate.getDate())) {
            return false;
        }
        return eventDateTimeFromDate < eventDateTimeToDate;
    };
    // 設定をリセットする
    JobMailTabComponent.prototype.onResetSetting = function (index) {
        this.jobSettingMail.settings[index] = JobMailTabComponent.getJobSettingMailDefault().settings[0];
    };
    Object.defineProperty(JobMailTabComponent.prototype, "validity", {
        // バリデーションを実施し、更新ボタンを押せるようにする
        get: function () {
            var _this = this;
            var validity = this.jobSettingMail.settings.reduce(function (previousValue, setting) {
                return previousValue && _this.isSettingValidated(setting) && _this.isEventDateTimeValidated(setting);
            }, true);
            return validity;
        },
        enumerable: true,
        configurable: true
    });
    JobMailTabComponent.prototype.save = function () {
        var _this = this;
        // dynamicformのモデルから、APIリクエスト用のモデルに受け渡し
        this.jobSettingMail.mailTemplateId = this.dynamicFormModel.template ? this.dynamicFormModel.template.id : null;
        this.jobSettingMail.enterpriseDataFileIds = this.dynamicFormModel.enterpriseDataFileIds;
        // putリクエスト用にオブジェクトを生成する。settingsは入力されているものしか送信したくないため。
        var putRequestObject = {
            mailTemplateId: this.dynamicFormModel.template ? this.dynamicFormModel.template.id : null,
            enterpriseDataFileIds: this.dynamicFormModel.enterpriseDataFileIds,
            // 入力されている、urlが入力されているsettingを抽出する
            settings: this.jobSettingMail.settings.filter(function (setting) {
                return !!setting.eventDateAt || !!setting.url;
            }),
        };
        this.jobApiService.updateJobSettingMail(this.job.id, putRequestObject).subscribe(function (response) {
            // 更新後に再読み込みする
            _this.jobApiService.refreshEvent.emit();
        }, function (error) {
            // bad requestは既存のダイアログを表示
            if (error.status === 400) {
                return;
            }
            // エラー時のダイアログ表示
            error.error.fields = error.error.fields || {};
            var dialogConfig = ConfirmDialogConfig.createErrorMessageDialog([
                error.error.fields.status || '開催日種別メール配信情報の更新に失敗しました。',
                error.message,
            ]);
            _this.dialogService.open(ConfirmDialogComponent, dialogConfig);
        });
    };
    return JobMailTabComponent;
}());
export { JobMailTabComponent };
