import * as tslib_1 from "tslib";
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { APPLICATION_TYPES } from '@agent-ds/shared/constants';
import { DetailPage } from '@agent-ds/shared/models';
import { DialogService, JobApiService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { JobRegistrationDialogComponent } from '../job-registration-dialog/job-registration-dialog.component';
var SeminarJobApplicationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SeminarJobApplicationComponent, _super);
    function SeminarJobApplicationComponent(counselingApiService, chRef, router, activeRoute, dialog, jobApiService) {
        var _this = _super.call(this, router, counselingApiService, activeRoute) || this;
        _this.counselingApiService = counselingApiService;
        _this.chRef = chRef;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.dialog = dialog;
        _this.jobApiService = jobApiService;
        _this.jobs = [];
        _this.requestObject = {};
        _this.model = [];
        _this.applications = Object.keys(APPLICATION_TYPES).map(function (key) { return ({
            id: +key,
            name: APPLICATION_TYPES[key],
        }); });
        _this.APPLICATION_TYPES = APPLICATION_TYPES;
        _this.listConfiguration = {
            body: {
                hideCreateButton: true,
                arrow: false,
                hideRefreshButton: true,
                hideActionsHeader: true,
                hideAllSort: true,
                noSorting: true,
            },
            new: false,
            favorites: false,
            persons_in_charge: false,
            recruitYear: false,
        };
        _this.errorJobs = [];
        _this.sideActions = [{ phases: ['100%'] }];
        _this.tabs = {};
        _this.urlTag = '';
        return _this;
    }
    SeminarJobApplicationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refreshSubscription = this.counselingApiService.refreshEvent.subscribe(function () { return _this.getJobs(); });
        this.jobRefreshSubscription = this.jobApiService.refreshEvent.subscribe(function () { return _this.getJobs(); });
    };
    SeminarJobApplicationComponent.prototype.ngAfterViewInit = function () {
        this.selectorColumnConfig = [
            {
                fields: [
                    {
                        name: 'application',
                        title: '応募・辞退',
                        cellTemplate: this.selectorTemplate,
                        sortable: false,
                    },
                ],
                style: {
                    width: '150px',
                    'margin-right': '10px',
                },
                bodyStyle: {
                    padding: '3px',
                },
            },
        ];
        this.remarksRowConfig = [
            {
                hidden: true,
                columns: [
                    {
                        fields: [
                            {
                                name: 'remarks',
                                hidden: true,
                                cellTemplate: this.remarksTemplate,
                                sortable: false,
                            },
                        ],
                        style: {
                            'margin-right': '10px',
                            width: '100%',
                        },
                        bodyStyle: {
                            padding: '4px 11px',
                        },
                    },
                ],
                clickAction: function (event, data) { return event.stopPropagation(); },
            },
        ];
        this.chRef.detectChanges();
    };
    SeminarJobApplicationComponent.prototype.ngOnDestroy = function () {
        if (this.jobRegistrationDialogSubscription) {
            this.jobRegistrationDialogSubscription.unsubscribe();
            this.jobRegistrationDialogSubscription = null;
        }
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = null;
        }
        if (this.jobRefreshSubscription) {
            this.jobRefreshSubscription.unsubscribe();
            this.jobRefreshSubscription = null;
        }
    };
    SeminarJobApplicationComponent.prototype.fill = function (onRefresh) {
        this.getJobs();
    };
    SeminarJobApplicationComponent.prototype.saveApplications = function () {
        var _this = this;
        if (!this.jobs || !this.jobs.length) {
            return;
        }
        this.applicationsSummaryJobs = {
            apply: this.jobs.filter(function (job) { return _this.model[job.id].application === 1; }),
            caNg: this.jobs.filter(function (job) { return _this.model[job.id].application === 2; }),
        };
        PopupControlComponent.instance.open({
            title: '応募、CA NG、辞退',
            content: this.applicationsSummaryTemplate,
            confirmText: '確定',
            confirmCallback: function () {
                PopupControlComponent.instance.close();
                var studentApplyRequestObj = {
                    jobs: Object.keys(_this.model).map(function (jobId) { return ({
                        jobId: +jobId,
                        application: _this.model[jobId].application,
                        remarks: _this.model[jobId].job.remarks,
                    }); }),
                };
                return _this.counselingApiService
                    .updateSeminarJobApplicationsForStudent(_this.referenceId, _this.data.seminar.id, studentApplyRequestObj)
                    .pipe(tap(function (res) {
                    _this.errorJobs = [];
                    if (res.errorIds && res.errorIds.length) {
                        _this.errorJobs = res.errorIds.map(function (id) { return _this.jobs.find(function (job) { return job.id === id; }); });
                        PopupControlComponent.instance.open({
                            content: _this.errorsTemplate,
                            confirmText: '閉じる',
                            confirmCallback: function () {
                                if (res.mailErrorIds && res.mailErrorIds.length) {
                                    _this.openMailErrorPopup();
                                }
                                return null;
                            },
                            title: '求人応募',
                            height: '357px',
                            width: '600px',
                        });
                    }
                    else if (res.mailErrorIds && res.mailErrorIds.length) {
                        _this.openMailErrorPopup();
                    }
                    _this.data.seminar.isApplied = 1;
                    _this.counselingApiService.refreshEvent.next();
                }));
            },
            cancelText: 'キャンセル',
            height: '640px',
            width: '800px',
        });
    };
    SeminarJobApplicationComponent.prototype.deleteJob = function (job) {
        var _this = this;
        if (!job || this.referenceId == null || !this.data || !this.data.seminar) {
            return;
        }
        PopupControlComponent.instance.open({
            title: 'データの削除',
            content: '選択した求人をセミナー紹介求人から削除します。\nよろしいですか？',
            confirmText: '確定',
            confirmCallback: function () {
                return _this.counselingApiService
                    .deleteSeminarJobsForStudent(_this.referenceId, _this.data.seminar.id, job.id)
                    .pipe(tap(function () { return _this.counselingApiService.refreshEvent.next(); }));
            },
            cancelText: 'キャンセル',
            height: '357px',
            width: '600px',
        });
    };
    SeminarJobApplicationComponent.prototype.openSeminarJobRegistrationDialog = function () {
        var _this = this;
        if (this.referenceId == null || !this.data || !this.data.seminar) {
            return;
        }
        var data = {
            seminarId: this.data.seminar.id,
            studentId: this.referenceId,
            addRequestFn: this.counselingApiService.addSeminarJobsForStudent.bind(this.counselingApiService),
        };
        var jobRegistrationDialogRef = this.dialog.open(JobRegistrationDialogComponent, { data: data });
        this.jobRegistrationDialogSubscription = jobRegistrationDialogRef.afterClosed.subscribe(function (res) {
            if (res) {
                _this.counselingApiService.refreshEvent.next();
            }
        });
    };
    SeminarJobApplicationComponent.prototype.openMailErrorPopup = function () {
        PopupControlComponent.instance.open({
            content: '推薦依頼メールの送信に失敗しました。',
            confirmText: '閉じる',
            confirmCallback: function () { return null; },
            title: '求人応募',
            height: '357px',
            width: '600px',
        });
    };
    SeminarJobApplicationComponent.prototype.getJobs = function () {
        var _this = this;
        this.jobs = [];
        this.model = [];
        if (this.referenceId && this.data && this.data.seminar) {
            this.readOnly = this.readonly = this.data.seminar && !!this.data.seminar.isApplied;
            this.counselingApiService.getSeminarJobsForStudent(this.referenceId, this.data.seminar.id).subscribe(function (jobs) {
                jobs.forEach(function (job) {
                    _this.model[job.id] = { application: 3, job: job };
                    // Transform so that job list can display these values
                    job.frontJobId = job.frontId;
                    job.enterprise = { dynamicData: { industry: job.dynamicData.industry } };
                });
                _this.jobs = jobs;
            });
        }
    };
    return SeminarJobApplicationComponent;
}(DetailPage));
export { SeminarJobApplicationComponent };
