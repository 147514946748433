<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">目標設定</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__container">
    <div class="content">
      <ag-dynamic-form
        class="content__column"
        [metadata]="metadataLeft"
        [model]="config.data.model"
      ></ag-dynamic-form>
      <ag-dynamic-form
        class="content__column"
        [metadata]="metadataRight"
        [model]="config.data.model"
      ></ag-dynamic-form>
    </div>
  </div>
  <div class="dialog__footer">
    <button
      class="dialog__footer__button btn btn--outline-blue"
      (click)="close()"
    >
      キャンセル
    </button>
    <button
      class="dialog__footer__button btn btn--blue"
      (click)="update()"
      [disabled]="updating"
    >
      登録
    </button>
  </div>
</div>
