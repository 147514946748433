<div class="content content--header">
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        class="row__head-form"
        [metadata]="metadataHead"
        [model]="company"
        (validated)="onValidityChange($event, 0)"
        *ngIf="metadataHead && company"
      ></ag-dynamic-form>
    </div>
  </div>
</div>
<div class="content">
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        [model]="company.dynamicData"
        [metadata]="metadataLeft"
        (validated)="onValidityChange($event, 1)"
        *ngIf="metadataLeft && company"
      ></ag-dynamic-form>
    </div>
    <div class="row__column">
      <ag-dynamic-form
        [model]="company"
        [metadata]="metadataRight"
        (validated)="onValidityChange($event, 2)"
        *ngIf="metadataRight && company"
      ></ag-dynamic-form>
    </div>
  </div>
</div>

<div *ngIf="metadataCustomFields && company" class="content content--bg-gray">
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        [model]="company.dynamicData"
        [metadata]="metadataCustomFields"
        *ngIf="metadataCustomFields && company"
      ></ag-dynamic-form>
    </div>
  </div>
</div>

<div class="footer">
  <div class="footer__container">
    <button
      class="footer__btn footer__btn--blue"
      [disabled]="!validity"
      (click)="save()"
    >
      更新
    </button>
  </div>
</div>
