import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { OnChanges, SimpleChanges } from '@angular/core';
var JobSalesTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(JobSalesTabComponent, _super);
    function JobSalesTabComponent() {
        return _super.call(this) || this;
    }
    JobSalesTabComponent.prototype.ngOnChanges = function (changes) {
        if (this.jobId != null && changes['jobId'].currentValue !== changes['jobId'].previousValue) {
            this.requestObject = {
                jobId: [this.jobId],
            };
        }
    };
    return JobSalesTabComponent;
}(Tab));
export { JobSalesTabComponent };
