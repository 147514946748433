<div class="content">
  <div class="header">
    <div class="header__title">
      {{ today | safeDate: "yyyy年MM月のKPI目標" }}
    </div>
    <div class="header__buttons btn btn--blue" (click)="setupKpi($event)">
      目標設定
    </div>
  </div>
  <div class="table">
    <div class="table__head">
      <div></div>
      <div class="table__head__title">実績</div>
      <div class="table__head__title">目標</div>
      <div class="table__head__title">達成率(日割り)</div>
    </div>
    <div
      class="table__row table__row--group-start"
      *ngIf="dashboardType === 0 || dashboardType === 1"
    >
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: 'AZ数',
          values: model.azs,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div
      class="table__row table__row--group-start"
      *ngIf="dashboardType === 3 || dashboardType === 4"
    >
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: 'カウ数',
          values: model.counselings,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '打診数',
          values: model.offerSubmissions,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row table__row--group-start">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '本人OK数',
          values: model.studentOks,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '営業書類提出数',
          values: model.salesDocuments,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: 'セミナー設定数',
          values: model.seminarSettings,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: 'セミナー実施数',
          values: model.seminarCompletions,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row table__row--group-start">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '１次面接設定数',
          values: model.firstInterviewSettings,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '１次面接実施数',
          values: model.firstInterviewCompletions,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '最終面接設定数',
          values: model.finalInterviewSettings,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '最終面接実施数',
          values: model.finalInterviewCompletions,
          unit: '件'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '内定数',
          values: model.offers,
          unit: '人'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row table__row--group-start">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '決定人数(SN)',
          values: model.acceptances,
          unit: '人'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row">
      <ng-container
        [ngTemplateOutlet]="row_with_rate"
        [ngTemplateOutletContext]="{
          title: '決定金額(SK)',
          values: model.sales,
          unit: '万円'
        }"
      >
      </ng-container>
    </div>
    <div class="table__row table__row--single-value">
      <div class="table__row__title">キャンセル数</div>
      <div class="table__row__value">
        {{ model.cancels?.total | number | undef }}
      </div>
      <div class="table__row__value" style="text-align: left;">
        <span class="table__row__unit">件</span>
        <span class="table__row__value"
          >({{ model.cancels?.amount | number | undef }}万円)</span
        >
      </div>
    </div>
  </div>
</div>

<ng-template
  #row_with_rate
  let-title="title"
  let-values="values"
  let-unit="unit"
>
  <div class="table__row__title">{{ title }}</div>
  <div class="table__row__value">
    {{ values?.actual | number | undef }}
  </div>
  <div class="table__row__unit">{{ unit }}</div>
  <div class="table__row__value table__row__value--grey">
    {{ values?.target | number | undef }}
  </div>
  <div class="table__row__unit">{{ unit }}</div>
  <div
    class="table__row__value"
    [ngClass]="{
      'table__row__value--bold': values?.rate,
      'table__row__value--highlite': values?.rate && values?.rate < 100
    }"
  >
    {{ values?.rate | number | undef }}
  </div>
  <div class="table__row__unit">%</div>
</ng-template>
