import { HttpClient } from '@angular/common/http';
import { forkJoin, ReplaySubject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { PermanentStorageService } from './permanent-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./permanent-storage.service";
var AUTH_SERVICE_KEY_LOGINED = 'auth-logined';
export var REPORT_SUCCESS_HEADER = 'X-REPORT-SUCCESS';
export var HIDE_ERROR_MESSAGE_HEADER = 'X-HIDE-ERROR-MESSAGE-HEADER';
export var HIDE_ERROR_DIALOG_HEADER = 'X-HIDE-ERROR-DIALOG-HEADER';
var AuthService = /** @class */ (function () {
    /**
     * コンストラクタ
     */
    function AuthService(http, storage) {
        this.http = http;
        this.storage = storage;
        this.logined = new ReplaySubject(1);
        if (!this.hasToken()) {
            this.logout();
        }
    }
    Object.defineProperty(AuthService.prototype, "loginUser", {
        get: function () {
            return this.loginUserRef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "authInfo", {
        get: function () {
            return this.authInfoRef;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * ログイン済みか
     */
    AuthService.prototype.isLogined = function () {
        var _this = this;
        if (this.hasToken()) {
            if (!this.loginUserRef) {
                if (!this.loginUserGetObservable) {
                    this.loginUserGetObservable = this.user.pipe(tap(function () {
                        _this.logined.next(true);
                        _this.loginUserGetObservable = null;
                    }));
                    this.loginUserGetObservable.subscribe();
                }
            }
        }
        return this.logined;
    };
    /**
     * ログインする
     */
    AuthService.prototype.login = function (credentials) {
        var _this = this;
        return this.http
            .post('/api/auth/login', credentials, {
            observe: 'response',
        })
            .pipe(concatMap(function (resp) {
            _this.loginUserRef = resp.body;
            _this.storage.set(AUTH_SERVICE_KEY_LOGINED, true);
            return _this.http.get('/api/auth/info').pipe(map(function (info) {
                _this.authInfoRef = info;
                _this.logined.next(true);
                return resp;
            }));
        }));
    };
    /**
     * ログアウトする
     */
    AuthService.prototype.logout = function () {
        if (this.hasToken()) {
            this.http.post('/api/auth/logout', {}).subscribe();
            this.storage.remove(AUTH_SERVICE_KEY_LOGINED);
            this.loginUserRef = null;
        }
        this.logined.next(false);
    };
    AuthService.prototype.password = function (oldPassword, newPassword) {
        return this.http.put('/api/auth/password', { oldPassword: oldPassword, newPassword: newPassword });
    };
    Object.defineProperty(AuthService.prototype, "user", {
        get: function () {
            var _this = this;
            return forkJoin([this.http.get('/api/auth/info'), this.http.get('/api/auth/user')]).pipe(map(function (_a) {
                var info = _a[0], user = _a[1];
                _this.authInfoRef = info;
                _this.loginUserRef = user;
                return user;
            }));
        },
        enumerable: true,
        configurable: true
    });
    /**
     * トークンを保持しているか
     */
    AuthService.prototype.hasToken = function () {
        return this.storage.get(AUTH_SERVICE_KEY_LOGINED);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PermanentStorageService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
