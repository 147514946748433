<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">
      {{ config.data.title || "一斉日程調整送信"
      }}{{ inConfirm ? "（確認）" : "" }}
    </h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div *ngIf="inConfirm" class="warning">
      ※ 確認画面です。送信ボタンを押すとメールが送信されます。
    </div>

    <div class="content" [class.content--with-warning]="inConfirm">
      <div
        class="content__table content__table--with-colon content__table--with-border"
      >
        <div class="content__label content__label--with-colon">To (求職者)</div>
        <div class="content__value" [class.content__value--bold]="inConfirm">
          {{ model?.to }}
        </div>

        <div class="content__label content__label--with-colon">CC (求職者)</div>
        <div class="content__value">{{ model?.cc1 }}</div>

        <div class="content__label content__label--with-colon">
          CC (求職者担当)
        </div>
        <div class="content__value">{{ model?.cc2 }}</div>

        <div class="content__label content__label--with-colon">CC (RAアシ)</div>
        <div class="content__value">{{ model?.cc3 }}</div>

        <div class="content__label content__label--with-colon">求人企業名</div>
        <div class="content__value">{{ model?.enterpriseName }}</div>

        <div class="content__label content__label--with-colon">求人名</div>
        <div class="content__value">{{ model?.jobPosition }}</div>

        <div class="content__label content__label--with-colon">選考名</div>
        <div class="content__value">{{ model?.selectionName }}</div>

        <ng-container *ngIf="inConfirm">
          <div class="content__label content__label--with-colon">
            メール件名
          </div>
          <div class="content__value">{{ model?.subject }}</div>
        </ng-container>
      </div>

      <ng-container *ngIf="inConfirm">
        <div class="content__table">
          <div class="content__label">
            メール本文
          </div>
          <div class="content__value content__value--pre">
            {{ model?.body }}
          </div>
        </div>
      </ng-container>

      <div *ngIf="!inConfirm" class="content__table">
        <div class="content__label">候補日</div>
        <div class="content__value">
          <div class="content__radios">
            <div class="content__radio">
              <input
                id="radio1"
                name="schedule-input"
                [value]="0"
                type="radio"
                [(ngModel)]="scheduleRadio"
              />
              <label class="content__radio--label" for="radio1">企業指定</label>
            </div>
            <div class="content__radio">
              <input
                id="radio2"
                name="schedule-input"
                [value]="1"
                type="radio"
                [(ngModel)]="scheduleRadio"
                (change)="clearScheduleText()"
              />
              <label class="content__radio--label" for="radio2">学生指定</label>
            </div>
          </div>
        </div>

        <div class="content__label"></div>
        <div *ngIf="scheduleRadio === 0" class="content__value content__row">
          <div class="content__column">
            <span class="content__textarea-label"
              >※チェックボックス一行の区切りは、改行で判断されます。</span
            >
            <textarea
              class="content__textarea"
              [(ngModel)]="scheduleText"
              (keydown.enter)="$event.stopPropagation()"
            ></textarea>
            <button
              class="btn btn--white btn--outline-gray btn--small"
              (click)="generatePreview()"
            >
              プレビュー
            </button>
          </div>
          <div class="content__column">
            <div
              class="content__textarea-label content__textarea-label--with-border"
            >
              <span>プレビュー</span>
            </div>
            <ng-container *ngIf="schedulePreviewList?.length; else previewHint">
              <div
                class="content__preview"
                *ngFor="let previewItem of schedulePreviewList"
              >
                <input type="checkbox" disabled readonly />
                <span>{{ previewItem }}</span>
              </div>
            </ng-container>
            <ng-template #previewHint>
              <div class="content__preview-hint">
                プレビューを表示する場合は、左記のフォームに候補日を入力しプレビューボタンをクリックしてください。
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          !inConfirm &&
          (screeningInfo?.request1 ||
            screeningInfo?.request2 ||
            screeningInfo?.request3)
        "
        class="content__table content__table--with-big-gap content__requests"
      >
        <ng-container *ngIf="screeningInfo?.request1">
          <div class="content__label">対応事項1</div>
          <div class="content__value request">
            <div class="request__part request__part--top">
              <div class="request__checkbox">
                <input
                  type="checkbox"
                  id="request1"
                  [(ngModel)]="model.request1"
                />
                <label for="request1">表示</label>
              </div>
            </div>
            <div class="request__part request__part--bottom">
              {{ screeningInfo?.request1 }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="screeningInfo?.request2">
          <div class="content__label">対応事項2</div>
          <div class="content__value request">
            <div class="request__part request__part--top">
              <div class="request__checkbox">
                <input
                  type="checkbox"
                  id="request2"
                  [(ngModel)]="model.request2"
                />
                <label for="request2">表示</label>
              </div>
            </div>
            <div class="request__part request__part--bottom">
              {{ screeningInfo?.request2 }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="screeningInfo?.request3">
          <div class="content__label">対応事項3</div>
          <div class="content__value request">
            <div class="request__part request__part--top">
              <div class="request__checkbox">
                <input
                  type="checkbox"
                  id="request3"
                  [(ngModel)]="model.request3"
                />
                <label for="request3">表示</label>
              </div>
            </div>
            <div class="request__part request__part--bottom">
              {{ screeningInfo?.request3 }}
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="!inConfirm" class="content__table content__message">
        <div class="content__label">連絡事項</div>
        <div class="content__value">
          <textarea
            class="content__textarea content__textarea--small"
            [(ngModel)]="message"
            (keydown.enter)="$event.stopPropagation()"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <ng-container *ngIf="!inConfirm; else confirmButtons">
      <button
        class="dialog__button dialog__button--accent"
        (click)="cancelMailTemplate(); close()"
      >
        キャンセル
      </button>
      <button
        class="dialog__button dialog__button--primary"
        [disabled]="isTemplateLoadInProgress || !isValidForConfirm()"
        (click)="getMailTemplate()"
      >
        確認
      </button>
    </ng-container>
    <ng-template #confirmButtons>
      <button
        class="dialog__button dialog__button--warning-accent"
        (click)="inConfirm = false; cancelSend()"
      >
        戻る
      </button>
      <button
        class="dialog__button dialog__button--warning"
        [disabled]="isSendInProgress"
        (click)="send()"
      >
        送信
      </button>
    </ng-template>
  </div>
</div>
