import { EventEmitter } from '@angular/core';
var Tab = /** @class */ (function () {
    function Tab() {
        this.gotoIndex = new EventEmitter();
    }
    Object.defineProperty(Tab.prototype, "selectedIndex", {
        set: function (index) {
            if (index === this.tabIndex && this.innerSelectedIndex !== this.tabIndex) {
                this.switchIn();
            }
            else if (index !== this.tabIndex && this.innerSelectedIndex === this.tabIndex) {
                this.switchOut();
            }
        },
        enumerable: true,
        configurable: true
    });
    Tab.prototype.switchIn = function () { };
    Tab.prototype.switchOut = function () { };
    Tab.prototype.close = function () { };
    return Tab;
}());
export { Tab };
