<div class="header">
  管理
</div>

<div class="section section--horizontal">
  <div class="sub-header">
    <div class="sub-header__title">
      ユーザー管理
    </div>
  </div>
  <div class="input-container">
    <input
      #searchInput
      class="input-container__input"
      placeholder="氏名、メールアドレスで検索"
      type="text"
      [(ngModel)]="search"
    />
    <svg
      class="input-container__clear-icon"
      *ngIf="search"
      (click)="clearSearch()"
    >
      <use xlink:href="/assets/icons/btn_clear.svg#btn_clear"></use>
    </svg>
    <svg class="input-container__search-icon">
      <use xlink:href="/assets/icons/icn_search_blue.svg#icn_search"></use>
    </svg>
  </div>
  <button class="btn btn--green section__btn" (click)="openUserEdit()">
    新規ユーザー登録
  </button>
</div>

<ag-page-scroll-table
  *ngIf="tableConfig"
  class="table"
  [tableConfig]="tableConfig"
  [content]="filteredUsers"
  [scrollerClass]="'no-scroll'"
></ag-page-scroll-table>

<ng-template #name let-data>
  <div class="overflow" [title]="data.name">
    {{ data.name }}
  </div>
</ng-template>
<ng-template #email let-data>
  <div class="overflow" [title]="data.email">
    {{ data.email }}
  </div>
</ng-template>

<ng-template #team let-data>
  <div
    *ngIf="
      data.teamId &&
      (userTeamProvider.getUserTeamInfoById(data.id) | async) as team
    "
    class="overflow"
    [title]="team"
  >
    {{ team.teamName }}
  </div>
</ng-template>

<ng-template #editButton let-data>
  <button
    class="edit-btn btn btn--small btn--white btn--outline-gray"
    (click)="openUserEdit(data)"
  >
    変更
  </button>
</ng-template>

<ng-template #userPopup let-data>
  <div class="form">
    <ag-dynamic-form
      #userForm
      [metadata]="userPopupMeta"
      [model]="userPopupModel"
    ></ag-dynamic-form>
  </div>
</ng-template>
