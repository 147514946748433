/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-scroll-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/filter.pipe";
import * as i4 from "./page-scroll-table.component";
import * as i5 from "../../pipes/safe-date.pipe";
var styles_PageScrollTableComponent = [i0.styles];
var RenderType_PageScrollTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageScrollTableComponent, data: {} });
export { RenderType_PageScrollTableComponent as RenderType_PageScrollTableComponent };
function View_PageScrollTableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "checkbox"]], null, null, null, null, null))], null, null); }
function View_PageScrollTableComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "table-head__field__sort"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSortChange(_v.parent.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "table-head__field__sort--up": 0, "table-head__field__sort--down": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "table-head__field__sort"; var currVal_1 = _ck(_v, 3, 0, ((_co.sorting.field === _v.parent.context.$implicit.name) && (_co.sorting.order === "asc")), ((_co.sorting.field !== _v.parent.context.$implicit.name) || (_co.sorting.order !== "asc"))); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PageScrollTableComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "table-head__field"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "table-head__field--sorted": 0, "table-head__field--sortable": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "table-head__field__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "table-head__field"; var currVal_1 = _ck(_v, 3, 0, (_co.sorting.field === _v.context.$implicit.name), (_v.context.$implicit.sortable !== false)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_v.context.$implicit.sortable !== false); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit.title != null) ? _v.context.$implicit.title : _v.context.$implicit.name); _ck(_v, 5, 0, currVal_2); }); }
function View_PageScrollTableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PageScrollTableComponent_5)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(5, 4)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.style; _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.fields, true, "hidden", true)); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "table-head__column ", _v.context.$implicit.class, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_PageScrollTableComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "arrow"]], null, null, null, null, null))], null, null); }
function View_PageScrollTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "table-head__row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_4)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tableConfig.body == null) ? null : _co.tableConfig.body.checkbox); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.columns; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.tableConfig.body == null) ? null : _co.tableConfig.body.arrow); _ck(_v, 6, 0, currVal_2); }, null); }
function View_PageScrollTableComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "disabled", 0], [8, "checked", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allChecked; var currVal_1 = (_co.allChecked || _co.checkedItems.includes(_v.parent.parent.parent.context.$implicit)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PageScrollTableComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "checkbox"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemCheck(_v.parent.parent.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_11)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.index === 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PageScrollTableComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PageScrollTableComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_PageScrollTableComponent_15)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { $implicit: 0, data: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.parent.parent.context.$implicit, _v.parent.parent.parent.parent.context.$implicit); var currVal_1 = _v.parent.context.$implicit.cellTemplate; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PageScrollTableComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.formatter ? _v.parent.context.$implicit.formatter(_v.parent.parent.parent.parent.context.$implicit) : _co.defaultFormatter(_v.parent.parent.parent.parent.context.$implicit, _v.parent.context.$implicit.name)); _ck(_v, 1, 0, currVal_0); }); }
function View_PageScrollTableComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFieldClick(_v.context.$implicit, $event, _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_14)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_16)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.style; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.cellTemplate; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.$implicit.cellTemplate; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PageScrollTableComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_13)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.allStyle; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.fields; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "table-body__column ", _v.context.$implicit.class, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_PageScrollTableComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "arrow arrow--right"]], null, null, null, null, null))], null, null); }
function View_PageScrollTableComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "table-body__row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRowClick(_v.context.$implicit, $event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_12)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_17)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicStyle(_v.context.$implicit.dynamicStyle, _v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.index === 0) && ((_co.tableConfig.body == null) ? null : _co.tableConfig.body.checkbox)); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.columns; _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_v.context.index === 0) && ((_co.tableConfig.body == null) ? null : _co.tableConfig.body.arrow)); _ck(_v, 8, 0, currVal_3); }, null); }
function View_PageScrollTableComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_9)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.tableConfig.body == null) ? null : _co.tableConfig.body.style); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.tableConfig.head.config; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "table-body ", ((_co.tableConfig.body == null) ? null : _co.tableConfig.body.class), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_PageScrollTableComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "loading__spinner"], ["viewBox", "0 0 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["class", "loading__spinner__path"], ["cx", "25"], ["cy", "25"], ["r", "20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "loading__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u3055\u3089\u306B\u8868\u793A"]))], null, null); }
function View_PageScrollTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = ((!_co.tableConfig.head.sticky ? _co.onScroll($event) : "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PageScrollTableComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(10, 4), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "table-body-wrapper"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = ((_co.tableConfig.head.sticky ? _co.onScroll($event) : "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_8)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_18)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "table ", _co.tableConfig.class, ""); var currVal_1 = (!_co.tableConfig.head.sticky ? ("scrollable-part " + _co.scrollerClass) : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.tableConfig.style; _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.tableConfig.head.style; _ck(_v, 7, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _co.tableConfig.head.config, true, "hidden", true)); _ck(_v, 9, 0, currVal_5); var currVal_6 = "table-body-wrapper"; var currVal_7 = (_co.tableConfig.head.sticky ? ("scrollable-part " + _co.scrollerClass) : ""); _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_8 = _co.content; _ck(_v, 15, 0, currVal_8); var currVal_9 = _co.loading; _ck(_v, 17, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "table-head ", _co.tableConfig.head.class, ""); _ck(_v, 5, 0, currVal_3); }); }
export function View_PageScrollTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.FilterPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageScrollTableComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableConfig; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PageScrollTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-scroll-table", [], null, null, null, View_PageScrollTableComponent_0, RenderType_PageScrollTableComponent)), i1.ɵdid(1, 638976, null, 0, i4.PageScrollTableComponent, [i5.SafeDatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageScrollTableComponentNgFactory = i1.ɵccf("ag-page-scroll-table", i4.PageScrollTableComponent, View_PageScrollTableComponent_Host_0, { pageSize: "pageSize", content: "content", scrollerClass: "scrollerClass", supplier: "supplier", tableConfig: "tableConfig" }, { itemSelected: "itemSelected", itemChecked: "itemChecked" }, []);
export { PageScrollTableComponentNgFactory as PageScrollTableComponentNgFactory };
