import * as tslib_1 from "tslib";
import { TabGroupComponent } from '@agent-ds/shared/components/tab-group';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { ProgressApiService, StudentApiService } from '@agent-ds/shared/services';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentTabs } from './tabs/student-tabs';
var StudentDetailPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentDetailPageComponent, _super);
    function StudentDetailPageComponent(studentApiService, activeRoute, router, progressApiService) {
        var _this = _super.call(this, router, studentApiService, activeRoute) || this;
        _this.studentApiService = studentApiService;
        _this.activeRoute = activeRoute;
        _this.router = router;
        _this.progressApiService = progressApiService;
        _this.sideActions = [{ phases: ['1000px', '100%'] }];
        _this.tabs = StudentTabs;
        _this.urlTag = 'students';
        _this.progresses = [];
        _this.StudentTabs = StudentTabs;
        _this.studentDetailLoaded = new EventEmitter();
        return _this;
    }
    StudentDetailPageComponent.prototype.fill = function (onRefresh) {
        var _this = this;
        this.updateUrl();
        if (this.referenceId && this.studentApiService) {
            this.studentApiService.getDetail(this.referenceId).subscribe(function (student) {
                if (_this.tabCode.toUpperCase() === _this.tabs[_this.tabs.MATCHES]) {
                    _this.tabCode = _this.tabs[_this.tabs.OVERVIEW];
                }
                if (!onRefresh) {
                    _this.studentDetailLoaded.emit(student);
                }
                _this.student = student;
            });
            this.progressApiService
                .getList({ studentId: [this.referenceId], sort: 'updatedAt', order: 'desc', from: 0, size: 5 })
                .subscribe(function (result) {
                _this.progresses = result.progresses || [];
            });
        }
    };
    return StudentDetailPageComponent;
}(DetailPage));
export { StudentDetailPageComponent };
