<div class="title">
  企業データ取り込み
  <button
    class="btn btn--big btn--white btn--outline-gray"
    (click)="downloadExampleCsv()"
  >
    CSVヘッダー出力
  </button>
</div>
<div class="content">
  <div class="section">
    <div class="section__header header">
      <div class="header__title">
        取り込みファイルの選択
      </div>
    </div>
    <div class="section__content import-container">
      <div
        *ngIf="!isImportInProgress; else showImportLoadingBar"
        class="import-btn-group"
      >
        <label class="import-btn-group__btn btn btn--white btn--outline-gray">
          <input #fileinput type="file" (change)="onInputChanged($event)" />
          ファイル選択
        </label>
        <span class="import-btn-group__label">
          {{
            fileToUpload ? fileToUpload.name : "ファイルが選択されていません"
          }}
        </span>
        <button
          class="btn btn--green"
          [disabled]="!fileToUpload"
          (click)="onImport()"
        >
          取り込み実行
        </button>
      </div>
      <ng-template #showImportLoadingBar>
        <div class="loading">
          <svg class="loading__spinner" viewBox="0 0 50 50">
            <circle
              class="loading__spinner__path"
              cx="25"
              cy="25"
              r="20"
            ></circle>
          </svg>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="section">
    <div class="section__header header">
      <div class="header__title">
        取り込み結果
      </div>
    </div>
    <div class="section__content">
      <div class="import-informations">
        <ng-container *ngIf="importData">
          <div class="import-informations__count">
            <span>{{ importData.successCount + importData.errorCount }}</span>
            <span class="import-informations__count--label">件中</span>
          </div>
          <div class="import-informations__info">
            （取り込み：{{ importData.successCount }}件、エラー：{{
              importData.errorCount
            }}件）
          </div>
        </ng-container>
      </div>
      <table class="import-result-table">
        <thead>
          <tr>
            <th>行番号</th>
            <th>エラー内容</th>
          </tr>
        </thead>
        <tbody *ngIf="importData">
          <tr *ngFor="let error of importData.errorList">
            <td>{{ error.line }}</td>
            <td>
              <ng-container *ngFor="let data of error.error?.data; index as di">
                <span *ngFor="let message of data.messages; index as i">
                  <span>{{ message }}</span>
                  <br *ngIf="data.messages.length - 1 !== i" />
                </span>
                <br *ngIf="error.error.data.length - 1 !== di" />
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="!importData || !importData.errorList?.length"
        class="empty-table-label"
      >
        取り込み候補はありません
      </div>
    </div>
  </div>
</div>
