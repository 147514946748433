import * as tslib_1 from "tslib";
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { MatchingCreateDialogComponent } from '@agent-ds/matching/components/matching-create-dialog/matching-create-dialog.component';
import { ProgressCreateDialogComponent } from '@agent-ds/progress/components';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DetailPage } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { MatchingApiService } from '@agent-ds/shared/services/api/matching-api.service';
import { deepClone } from '@agent-ds/shared/util/util';
import { AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
var JobStudentMatchingPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(JobStudentMatchingPageComponent, _super);
    function JobStudentMatchingPageComponent(matchingApi, dialog, router, activeRoute, service) {
        var _this = _super.call(this, router, service, activeRoute) || this;
        _this.matchingApi = matchingApi;
        _this.dialog = dialog;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.service = service;
        _this.studentSearchObj = { keyword: '' };
        _this.matchings = [];
        _this.listConfiguration = { body: { checkbox: true, hideCreateButton: true, hideBatchUpdateButton: true } };
        _this.sideActions = [{ phases: ['100%'] }];
        _this.tabs = {};
        _this.urlTag = 'matching/job-student';
        _this.refreshSubscription.unsubscribe();
        _this.routeSubscription.unsubscribe();
        return _this;
    }
    JobStudentMatchingPageComponent.prototype.ngOnInit = function () { };
    JobStudentMatchingPageComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.jobDetails.jobDetailLoaded.subscribe(function (job) {
            _this.job = job;
            // マッチング条件で検索項目のデフォルト値を設定する
            _this.studentSearchObj = tslib_1.__assign({ universityLevel: job.dynamicData.universityLevel ? job.dynamicData.universityLevel.slice() : [], 
                // ['男性', '女性', 'その他'] から ['男性', '女性', '回答しない'] への変換
                gender: (function () {
                    if (job.dynamicData.gender) {
                        return job.dynamicData.gender.slice().map(function (value) {
                            return value === 'その他' ? '回答しない' : value;
                        });
                    }
                    else {
                        return [];
                    }
                })(), academicFieldType: job.dynamicData.academicFieldType ? job.dynamicData.academicFieldType.slice() : [], supportYear: job.dynamicData.recruitYear ? [job.dynamicData.recruitYear] : [], 
                // lastAcademicRecord: job.dynamicData.lastAcademicRecord ? [...job.dynamicData.lastAcademicRecord] : [],
                registrationStatus: ['本登録'], communicationSkill: job.dynamicData.communicationSkill, impressionAndCharm: job.dynamicData.impressionAndCharm }, _this.studentSearchObj);
            _this.loadMatchings(job.id);
            _this.updateUrl();
        });
    };
    JobStudentMatchingPageComponent.prototype.fill = function (onRefresh) {
        if (this.referenceId) {
            this.showList = false;
            this.studentSearchObj = {};
            this.jobDetails.referenceId = this.referenceId;
            this.jobDetails.open();
        }
    };
    JobStudentMatchingPageComponent.prototype.loadMatchings = function (jobId, selectedId) {
        var _this = this;
        this.matchingApi.getMatchingsForJob(jobId).subscribe(function (matchings) {
            _this.matchings = matchings;
            _this.selectedMatching = _this.matchings.find(function (m) { return m.id === selectedId; });
        });
    };
    JobStudentMatchingPageComponent.prototype.onFilterChanged = function (changes) {
        if (this.selectedMatching) {
            this.studentSearchObj = tslib_1.__assign({}, deepClone(this.studentSearchObj), changes);
            this.selectedMatching = undefined;
        }
        else {
            this.studentSearchObj = tslib_1.__assign({}, this.studentSearchObj, changes);
        }
    };
    JobStudentMatchingPageComponent.prototype.matchingSelected = function (selected, matching) {
        this.selectedMatching = matching;
        this.studentSearchObj = this.selectedMatching && selected ? deepClone(this.selectedMatching.matchingParameter) : { keyword: '' };
    };
    JobStudentMatchingPageComponent.prototype.saveMatching = function () {
        var _this = this;
        this.dialog.open(MatchingCreateDialogComponent, null, function (dialogModel) {
            if (dialogModel) {
                var matching = {
                    name: dialogModel.name,
                    jobId: _this.job.id,
                    matchingParameter: _this.studentSearchObj,
                };
                return _this.matchingApi.saveMatchingForJob(matching).pipe(tap(function (res) { return _this.loadMatchings(_this.job.id, res.id); }));
            }
        });
    };
    JobStudentMatchingPageComponent.prototype.deleteMatching = function (matching) {
        var _this = this;
        var config = {
            title: '求人検索マッチング条件の削除',
            messages: ['マッチング条件を削除してもよろしいですか？'],
            style: {
                height: '245px',
                width: '510px',
            },
            buttons: {
                no: 'キャンセル',
                yes: 'OK',
            },
        };
        this.dialog.open(ConfirmDialogComponent, config, function (confirmed) {
            if (confirmed) {
                return _this.matchingApi.deleteJobMatching(matching.id).pipe(tap(function () {
                    _this.loadMatchings(_this.job.id);
                }));
            }
        });
    };
    JobStudentMatchingPageComponent.prototype.doStudentSearch = function (params) {
        var _this = this;
        this.showList = true;
        setTimeout(function () {
            return (_this.studentSearchObj = tslib_1.__assign({}, _this.studentSearchObj, params));
        });
    };
    JobStudentMatchingPageComponent.prototype.createProgress = function () {
        this.dialogRef = this.dialog.open(ProgressCreateDialogComponent, {
            data: {
                jobList: [
                    tslib_1.__assign({ frontJobId: this.job.frontId }, this.job),
                ],
                studentList: this.studentList.checkedItems,
                matchingMode: true,
            },
        });
    };
    JobStudentMatchingPageComponent.prototype.toSearch = function () {
        DetailPage.closeAll([JobStudentMatchingPageComponent]);
        this.showList = false;
    };
    return JobStudentMatchingPageComponent;
}(DetailPage));
export { JobStudentMatchingPageComponent };
