<div class="header" *ngIf="!isIntakeCa">
  <span class="header__title">進捗検索結果</span>
  <button
    class="header__modify btn btn--big btn--white btn--outline-blue btn--rounded btn--regular-font"
    (click)="openSearch()"
  >
    検索条件を変更<span
      class="header__modify__arrow header__modify__arrow--down"
    ></span>
  </button>
  <button
    class="header__export btn btn--big btn--white btn--outline-gray"
    (click)="showExport()"
  >
    CSV出力
  </button>
</div>
<ag-progress-list
  [requestObject]="searchObj"
  (statusFilterChange)="proxySearch(searchObj)"
  *ngIf="!isIntakeCa"
></ag-progress-list>

<ng-template #export *ngIf="!isIntakeCa">
  <div class="export">
    <p class="export__note">出力するCSVを選択してください。</p>
    <p class="export__label">出力リスト</p>
    <ag-autocomplete
      class="export__select"
      valueField="id"
      labelField="name"
      [hideBtn]="false"
      [options]="exportTemplates"
      [(value)]="exportTemplateId"
    ></ag-autocomplete>
  </div>
</ng-template>

<ag-progress-search
  *ngIf="isSearchOpen && !isIntakeCa"
  [searchModel]="searchObj"
  (closed)="closeSearch()"
  (search)="doSearch($event)"
></ag-progress-search>
