<div class="section">
  <div class="title">
    取り込みデータ編集
  </div>
</div>

<div class="section">
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        class="row__head-form"
        [metadata]="headMeta"
        [model]="detail.importedStudent"
        (validated)="onValidityChange($event, 0)"
        *ngIf="headMeta && detail"
      ></ag-dynamic-form>
    </div>
  </div>
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        [metadata]="leftMeta"
        [model]="detail.importedStudent.dynamicData"
        (validated)="onValidityChange($event, 1)"
        *ngIf="leftMeta && detail"
      ></ag-dynamic-form>
    </div>

    <div class="row__column">
      <ag-dynamic-form
        [metadata]="rightMeta"
        [model]="detail.importedStudent.dynamicData"
        (validated)="onValidityChange($event, 2)"
        *ngIf="rightMeta && detail"
      ></ag-dynamic-form>
    </div>
  </div>

  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        class="row__bottom-form"
        [metadata]="bottomMeta"
        [model]="detail.importedStudent.dynamicData"
        (validated)="onValidityChange($event, 3)"
        *ngIf="bottomMeta && detail"
      ></ag-dynamic-form>
    </div>
  </div>

  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        [metadata]="customMeta"
        [model]="detail.importedStudent.dynamicData"
        (validated)="onValidityChange($event, 4)"
        *ngIf="customMeta && detail"
      ></ag-dynamic-form>
    </div>
  </div>
</div>

<div class="footer">
  <div class="footer__container">
    <button
      class="footer__btn btn--larger btn btn--blue"
      [disabled]="!detail || !validity"
      (click)="update()"
    >
      保存
    </button>
    <button
      class="footer__btn btn--larger btn btn--white btn--outline-red"
      [disabled]="!detail"
      (click)="delete()"
    >
      削除
    </button>
  </div>
</div>
