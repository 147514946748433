<div class="page" (keydown.enter)="doSearch($event)">
  <div class="search-header">
    <div class="search-header__title">成約検索</div>
    <div class="search-header__close close" (click)="onCloseClick()"></div>
  </div>

  <div class="content">
    <div class="row">
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            class="dynamic-form"
            [metadata]="metadataLeft"
            [model]="innerSearchModel"
          ></ag-dynamic-form>
        </div>
        <div class="row__column">
          <ag-dynamic-form
            class="dynamic-form"
            [metadata]="metadataRight"
            [model]="innerSearchModel"
          ></ag-dynamic-form>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-spacer"></div>
</div>

<div class="footer">
  <button class="footer__btn footer__btn--green" (click)="doSearch()">
    検索
  </button>
</div>
