<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">
      {{ config.data.billing.dynamicData ? "請求先詳細" : "新規請求先登録" }}
    </h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="content">
      <div>
        <p class="message">
          <span class="message__red">*</span
          >BtoBへ連携する場合は、次の項目が必須項目となります。
        </p>
        <p class="message">
          <span class="message__red">*</span>
          <span>得意先コード</span><span class="message__value">|</span>
          <span>請求先ID</span><span class="message__value">|</span>
          <span>氏名</span><span class="message__value">|</span>
          <span>E-mail</span><span class="message__value">|</span>
          <span>請求先サービス</span><span class="message__value">|</span>
          <span>振込先</span>
        </p>
      </div>
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            *ngIf="metadataLeft"
            [metadata]="metadataLeft"
            [model]="model.dynamicData"
            (validated)="onValidityChange(0, $event)"
          ></ag-dynamic-form>
        </div>
        <div class="row__column">
          <ag-dynamic-form
            *ngIf="metadataRight"
            [metadata]="metadataRight"
            [model]="model"
            (validated)="onValidityChange(1, $event)"
          ></ag-dynamic-form>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!valid || dialog.inClose"
      (click)="create()"
    >
      {{ config.data.billing.dynamicData ? "更新" : "登録" }}
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!valid || dialog.inClose"
      (click)="create({ toBtob: $event })"
    >
      {{
        config.data.billing.dynamicData
          ? "更新してBtoB連携"
          : "登録してBtoB連携"
      }}
    </button>
  </div>
</div>

<ng-template #deptSelect>
  <div class="dep-selector">
    <ag-select
      class="dep-selector__select"
      #addressSelect
      [options]="departmentDynamicList"
      labelField="name"
      valueField="address"
    ></ag-select>
    <button
      class="btn btn--small btn--dark"
      (click)="onAddressSelect(addressSelect.value)"
    >
      引用
    </button>
  </div>
</ng-template>
