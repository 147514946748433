<div class="header">
  <ng-container *ngIf="userHasManagementRole">
    <div
      *ngFor="let team of authService.loginUser?.managementTeams"
      class="header__item btn"
      [ngClass]="{
        'btn--blue': selectedManagementTeam === team.id,
        'link blue': selectedManagementTeam !== team.id
      }"
      (click)="updateData(team.id)"
    >
      {{ team.name }}
    </div>
    <div
      *ngIf="userHasManagementRole"
      class="header__item btn"
      [ngClass]="{
        'btn--blue': selectedManagementTeam === null,
        'link blue': selectedManagementTeam !== null
      }"
      (click)="updateData(null)"
    >
      個人
    </div>
  </ng-container>
  <div
    class="header__item header__item--right btn btn--outline-blue"
    (click)="secondMatchClicked()"
    *ngIf="userIsCA"
  >
    2ndマッチ実績出力
  </div>
</div>
<div class="content" *ngIf="!isIntakeCa">
  <ag-kpi-target
    *ngIf="visualDashboardType !== 2"
    class="content__item"
    [model]="kpi"
    [dashboardType]="visualDashboardType"
    (setupClick)="kpiSetupClicked()"
  ></ag-kpi-target>
  <ag-job-progress-dash
    class="content__item"
    [model]="progress"
    [dashboardType]="visualDashboardType"
    [selectedTeamId]="selectedManagementTeam"
    [loginUserId]="authService.loginUser?.id"
    (collapsedNextYear)="collapsedChild = $event"
  ></ag-job-progress-dash>
  <ag-responsible-company
    class="content__item"
    [enterpriseModel]="enterprises"
    [studentModel]="students"
    [dashboardType]="visualDashboardType"
    [selectedTeamId]="selectedManagementTeam"
    [loginUserId]="authService.loginUser?.id"
    [collapsedNextYear]="collapsedChild"
  ></ag-responsible-company>
</div>
<div *ngIf="isIntakeCa" style="text-align: center; color: #999999;">
  表示する項目はありません。
</div>
