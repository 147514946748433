<div class="header">
  個人設定
</div>

<div class="holder">
  <div class="holder__title">
    <span class="holder__text">署名設定</span>
    <button class="btn btn--blue" (click)="openSignatureEdit()">
      新規署名登録
    </button>
  </div>
  <ag-page-scroll-table
    *ngIf="signatureTableConfig"
    class="table"
    [tableConfig]="signatureTableConfig"
    [content]="signatures"
    [scrollerClass]="'no-scroll'"
  ></ag-page-scroll-table>
</div>

<div class="holder" *ngIf="authService.loginUser?.role === 1">
  <div class="holder__title">
    <span class="holder__text">CSV出力設定</span>
    <button class="btn btn--blue" (click)="openTemplateEdit()">
      新規CSV出力設定
    </button>
  </div>
  <ag-page-scroll-table
    *ngIf="csvTableConfig"
    class="table"
    [tableConfig]="csvTableConfig"
    [content]="templates"
    [scrollerClass]="'no-scroll'"
  ></ag-page-scroll-table>
</div>

<ng-template #signatureEditButton let-data>
  <button
    class="btn btn--small btn--white btn--outline-gray"
    (click)="openSignatureEdit(data)"
  >
    編集
  </button>
</ng-template>

<ng-template #csvEditButton let-data>
  <button
    class="btn btn--small btn--white btn--outline-gray"
    (click)="openTemplateEdit(data)"
  >
    編集
  </button>
</ng-template>

<ng-template #signatureEditPopup let-data>
  <div class="form">
    <ag-dynamic-form
      #signatureForm
      [metadata]="signatureMeta"
      [model]="selectedSignature"
    ></ag-dynamic-form>
  </div>
</ng-template>

<ng-template #csvEditPopup let-data>
  <div class="form">
    <ag-dynamic-form
      #csvForm
      [metadata]="templateMeta"
      [model]="selectedTemplate"
    ></ag-dynamic-form>
  </div>
</ng-template>
