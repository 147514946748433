<ng-container *ngIf="job">
  <div
    class="deadline"
    *ngIf="
      !showOnlyMainInfo &&
      job.dynamicData?.recommendationDateKind &&
      job.dynamicData?.recommendationDate
    "
  >
    <span
      class="badge"
      [agClass]="job.dynamicData?.recommendationDateKind"
      [classOptions]="recommendationHeatClasses"
      >{{ job.dynamicData?.recommendationDateKind }}</span
    >
    <span class="deadline__label">推薦期限</span>
    <span class="deadline__date">{{
      job.dynamicData?.recommendationDate | safeDate: "MM/dd"
    }}</span>
  </div>

  <div class="header">
    <div class="title" [class.title--margin-top]="showOnlyMainInfo">
      <div class="description" [class.description--vertical]="showOnlyMainInfo">
        <div class="description__label">
          {{ job.frontEnterpriseId }}
        </div>
        <div
          class="description__text description__text--blue link"
          [class.description__text--no-margin-left]="showOnlyMainInfo"
          (click)="onCompanyLinkClick(job.enterpriseId)"
        >
          {{ job.enterpriseName }}
        </div>
      </div>
      <div class="description" [class.description--vertical]="showOnlyMainInfo">
        <div class="description__label">
          {{ job.frontId }}
        </div>
        <div
          class="description__text"
          [class.description__text--no-margin-left]="showOnlyMainInfo"
        >
          {{ job.dynamicData?.position }}
        </div>
      </div>
    </div>
    <div class="segs">
      <ag-segs
        [companyClassification]="job.companyClassification"
        [segs8LastYear]="job.segs8LastYear"
        [segs8CurrentYear]="job.segs8CurrentYear"
      >
      </ag-segs>
    </div>
  </div>

  <div class="buttons" *ngIf="!showOnlyMainInfo && !readonly">
    <div class="spacer"></div>
    <button class="btn btn--white btn--outline-gray" (click)="copyJob()">
      新規求人コピー
    </button>
    <div class="separator"></div>
    <button class="btn btn--blue" (click)="openMatching()" *ngIf="!isIntakeCa">
      マッチング
    </button>
    <button
      class="btn btn--blue"
      (click)="downloadPdf()"
      [disabled]="disablePdfButton"
    >
      求人票
    </button>
    <button class="btn btn--blue" (click)="openMail(postingConfig)">
      求職者へ求人票送信
    </button>
    <button class="btn btn--blue" (click)="openMail(companyConfig)">
      企業へ求人票送信
    </button>
  </div>
</ng-container>
