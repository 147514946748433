import { HttpClient } from '@angular/common/http';
import { first, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth.service";
var UserApiService = /** @class */ (function () {
    function UserApiService(http, authService) {
        var _this = this;
        this.http = http;
        this.authService = authService;
        this.authService.isLogined().subscribe(function (logined) {
            if (logined) {
                _this.users = null;
            }
        });
    }
    UserApiService.prototype.get = function (id) {
        return this.http.get('/api/master/users/' + id);
    };
    UserApiService.prototype.getAll = function () {
        if (!this.users) {
            this.users = this.http.get('/api/master/users').pipe(shareReplay(1));
        }
        return this.users.pipe(first());
    };
    UserApiService.prototype.add = function (user) {
        var _this = this;
        return this.http.post('/api/master/users', user).pipe(tap(function () { return (_this.users = null); }));
    };
    UserApiService.prototype.update = function (id, user) {
        var _this = this;
        return this.http.put('/api/master/users/' + id, user).pipe(tap(function () { return (_this.users = null); }));
    };
    UserApiService.prototype.delete = function (id) {
        var _this = this;
        this.http.delete('/api/master/users/' + id).pipe(tap(function () { return (_this.users = null); }));
    };
    UserApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserApiService_Factory() { return new UserApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: UserApiService, providedIn: "root" });
    return UserApiService;
}());
export { UserApiService };
