var _a;
export var ExportModel;
(function (ExportModel) {
    ExportModel["student"] = "student";
    ExportModel["enterprise"] = "enterprise";
    ExportModel["job"] = "job";
    ExportModel["progress"] = "progress";
})(ExportModel || (ExportModel = {}));
export var ExportModelLabel = (_a = {},
    _a[ExportModel.student] = '求職者',
    _a[ExportModel.enterprise] = '企業',
    _a[ExportModel.job] = '求人',
    _a[ExportModel.progress] = '進捗',
    _a);
