<div class="header">
  管理
</div>

<div class="section">
  <div class="sub-header">
    <div class="sub-header__title">
      カスタムフィールド設定
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th>求職者</th>
        <th class="table__action">
          <ng-container
            *ngTemplateOutlet="newFieldBtn; context: { $implicit: 'student' }"
          ></ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="editors[NEW_FIELD_EDITOR_KEY.student]?.isEdit">
        <tr>
          <ng-container
            *ngTemplateOutlet="
              tableRow;
              context: {
                $implicit: {
                  id: NEW_FIELD_EDITOR_KEY.student,
                  label: '新規カスタムフィールド',
                  model: 'student'
                }
              }
            "
          ></ng-container>
        </tr>
      </ng-container>
      <ng-container *ngIf="studentFields && studentFields.length">
        <tr
          *ngFor="let field of studentFields"
          [class.dark]="!field.isSearchable"
        >
          <ng-container
            *ngTemplateOutlet="tableRow; context: { $implicit: field }"
          ></ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <table class="table">
    <thead>
      <tr>
        <th>企業</th>
        <th class="table__action">
          <ng-container
            *ngTemplateOutlet="
              newFieldBtn;
              context: { $implicit: 'enterprise' }
            "
          ></ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="editors[NEW_FIELD_EDITOR_KEY.enterprise]?.isEdit">
        <tr>
          <ng-container
            *ngTemplateOutlet="
              tableRow;
              context: {
                $implicit: {
                  id: NEW_FIELD_EDITOR_KEY.enterprise,
                  label: '新規カスタムフィールド',
                  model: 'enterprise'
                }
              }
            "
          ></ng-container>
        </tr>
      </ng-container>
      <ng-container *ngIf="enterpriseFields && enterpriseFields.length">
        <tr
          *ngFor="let field of enterpriseFields"
          [class.dark]="!field.isSearchable"
        >
          <ng-container
            *ngTemplateOutlet="tableRow; context: { $implicit: field }"
          ></ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <table class="table">
    <thead>
      <tr>
        <th>求人</th>
        <th class="table__action">
          <ng-container
            *ngTemplateOutlet="newFieldBtn; context: { $implicit: 'job' }"
          ></ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="editors[NEW_FIELD_EDITOR_KEY.job]?.isEdit">
        <tr>
          <ng-container
            *ngTemplateOutlet="
              tableRow;
              context: {
                $implicit: {
                  id: NEW_FIELD_EDITOR_KEY.job,
                  label: '新規カスタムフィールド',
                  model: 'job'
                }
              }
            "
          ></ng-container>
        </tr>
      </ng-container>
      <ng-container *ngIf="jobFields && jobFields.length">
        <tr *ngFor="let field of jobFields" [class.dark]="!field.isSearchable">
          <ng-container
            *ngTemplateOutlet="tableRow; context: { $implicit: field }"
          ></ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #newFieldBtn let-model>
  <div class="table__btn-group">
    <button
      class="table__btn btn btn--small btn--dark"
      (click)="
        editors[NEW_FIELD_EDITOR_KEY[model]] = {
          isEdit: true,
          isUpdateInProgress: false,
          allOptions: [],
          newOption: '',
          newLabel: '',
          type: null,
          isSearchable: false
        }
      "
    >
      追加
    </button>
  </div>
</ng-template>

<ng-template #tableRow let-field>
  <td class="table__value">
    {{ field.label }}
    <div *ngIf="editors[field.id]?.isEdit" class="edit">
      <div class="edit__sections">
        <div>
          <div class="edit__title">項目</div>
          <div class="edit__content">
            <div class="edit__label">名称</div>
            <div class="edit__value">
              <input
                type="text"
                class="edit__text-input"
                [(ngModel)]="editors[field.id].newLabel"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="edit__title">項目形式</div>
          <div class="edit__content">
            <div class="edit__label">種類</div>
            <div class="edit__value">
              <select
                class="edit__select select"
                [(ngModel)]="editors[field.id].type"
                (change)="
                  editors[field.id].allOptions =
                    field.validationStyle?.options?.slice() || []
                "
              >
                <option
                  *ngFor="let option of TYPE_OPTIONS | keyvalue: asIsOrder"
                  [value]="option.key"
                >
                  {{ option.key }}
                </option>
              </select>
            </div>
          </div>
          <div
            *ngIf="
              editors[field.id].type &&
              (TYPE_OPTIONS[editors[field.id].type]?.fieldType === 'list' ||
                TYPE_OPTIONS[editors[field.id].type]?.fieldType ===
                  'multi-list')
            "
            class="edit__content"
          >
            <div class="edit__label">値</div>
            <div class="edit__value">
              <div class="edit__input-container">
                <input
                  type="text"
                  class="edit__text-input"
                  placeholder="リストの項目を入力してください"
                  [(ngModel)]="editors[field.id].newOption"
                />
                <button
                  class="edit__input-btn btn btn--dark"
                  [disabled]="
                    !editors[field.id]?.newOption?.trim() ||
                    (editors[field.id]?.allOptions?.length &&
                      editors[field.id]?.allOptions.includes(
                        editors[field.id].newOption.trim()
                      ))
                  "
                  (click)="
                    editors[field.id]?.allOptions.push(
                      editors[field.id].newOption.trim()
                    );
                    editors[field.id].newOption = ''
                  "
                >
                  追加
                </button>
              </div>
              <ag-ordered-list
                [(value)]="editors[field.id].allOptions"
              ></ag-ordered-list>
            </div>
          </div>
          <div class="edit__content">
            <div class="edit__label edit__checkbox-group">
              <input
                id="searchable"
                type="checkbox"
                [(ngModel)]="editors[field.id].isSearchable"
              />
              <label for="searchable">検索対象</label>
            </div>
          </div>
        </div>
      </div>
      <div class="edit__footer">
        <button
          class="edit__footer-btn edit__footer-btn--accent"
          [disabled]="editors[field.id].isUpdateInProgress"
          (click)="editors[field.id].isEdit = false"
        >
          キャンセル
        </button>
        <button
          class="edit__footer-btn edit__footer-btn--primary"
          [disabled]="
            editors[field.id].isUpdateInProgress || !isEditorValid(field.id)
          "
          (click)="onSave(field, editors[field.id])"
        >
          確定
        </button>
      </div>
    </div>
  </td>
  <td class="table__action">
    <div
      *ngIf="!editors[field.id]?.isEdit; else cancelBtn"
      class="table__btn-group"
    >
      <button
        *ngIf="
          field.fieldType === 'text' ||
          field.fieldType === 'number' ||
          field.fieldType === 'date' ||
          field.fieldType === 'list' ||
          field.fieldType === 'multi-list'
        "
        class="table__btn btn btn--small btn--bg-white btn--outline-gray"
        [disabled]="editors[field.id]?.isUpdateInProgress"
        (click)="
          editors[field.id] = {
            isEdit: true,
            isUpdateInProgress: false,
            allOptions: field.validationStyle?.options?.slice() || [],
            newOption: '',
            newLabel: field.label,
            isSearchable: field.isSearchable,
            type: toType(field)
          }
        "
      >
        編集
      </button>
      <button
        class="table__btn btn btn--small btn--bg-white btn--outline-gray"
        [disabled]="editors[field.id]?.isUpdateInProgress"
        (click)="toggleIsSearchable(field)"
      >
        {{ field.isSearchable ? "検索対象外" : "検索対象" }}
      </button>
    </div>
    <ng-template #cancelBtn>
      <div class="table__btn-group">
        <button
          class="edit-cancel-btn btn btn--white"
          (click)="
            !editors[field.id].isUpdateInProgress
              ? (editors[field.id].isEdit = false)
              : null
          "
        >
          キャンセル
          <span class="close close--blue"></span>
        </button>
      </div>
    </ng-template>
  </td>
</ng-template>
