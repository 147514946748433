/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./global-menu/global-menu.component.ngfactory";
import * as i3 from "./global-menu/global-menu.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/auth.service";
import * as i6 from "../../services/api/job-api.service";
import * as i7 from "./global-header/global-header.component.ngfactory";
import * as i8 from "./global-header/global-header.component";
import * as i9 from "../../services/dialog.service";
import * as i10 from "@angular/common";
import * as i11 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-global-menu", [["class", "menu"]], null, null, null, i2.View_GlobalMenuComponent_0, i2.RenderType_GlobalMenuComponent)), i1.ɵdid(1, 4243456, null, 0, i3.GlobalMenuComponent, [i4.Router, i5.AuthService, i6.JobApiService], null, null)], null, null); }
function View_LayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-global-header", [["class", "header"]], null, null, null, i7.View_GlobalHeaderComponent_0, i7.RenderType_GlobalHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.GlobalHeaderComponent, [i5.AuthService, i9.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "content"]], [[2, "content--full", null]], null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.useLayout; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.useLayout; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.useLayout; _ck(_v, 4, 0, currVal_2); }); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 49152, null, 0, i11.LayoutComponent, [], null, null)], null, null); }
var LayoutComponentNgFactory = i1.ɵccf("ag-layout", i11.LayoutComponent, View_LayoutComponent_Host_0, { pageInfo: "pageInfo" }, {}, ["*"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
