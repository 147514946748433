import * as tslib_1 from "tslib";
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { Tab } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { OnChanges, SimpleChanges } from '@angular/core';
var StudentProgressTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentProgressTabComponent, _super);
    function StudentProgressTabComponent(studentApiService, dialogService) {
        var _this = _super.call(this) || this;
        _this.studentApiService = studentApiService;
        _this.dialogService = dialogService;
        return _this;
    }
    StudentProgressTabComponent.prototype.ngOnChanges = function (changes) {
        if (this.student != null && changes['student'].currentValue !== changes['student'].previousValue) {
            this.requestObject = {
                studentId: [this.student.id],
            };
        }
    };
    StudentProgressTabComponent.prototype.errorGetListCallback = function (error) {
        if (!error.status) {
            this.showErrorDialog();
            this.studentApiService.notifyConnectionErrorEvent.emit();
        }
        else {
            // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
        }
    };
    StudentProgressTabComponent.prototype.showErrorDialog = function () {
        // ダイアログによる通知
        var dialogConfig = ConfirmDialogConfig.createStudentApiErrorDialogConfig([
            '進捗の取得中に通信エラーが発生しました。',
            '画面上部右側の「求職者の全情報を再取得する」ボタンを押して再度取得してください。',
        ]);
        this.dialogService.open(ConfirmDialogComponent, dialogConfig);
    };
    return StudentProgressTabComponent;
}(Tab));
export { StudentProgressTabComponent };
