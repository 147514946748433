import * as tslib_1 from "tslib";
import { MAX_FEE_VALUE } from '@agent-ds/sales/sales-detail-page/helpers/order-info-helper';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { NULL_SELECTED_VALUE, TAX_PERCENTAGE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUserType, } from '@agent-ds/shared/interfaces';
import { AuthService, CompanyApiService, DialogService, DynamicFieldService, MailApiService, MasterApiService, ProgressApiService, SalesApiService, UserApiService, } from '@agent-ds/shared/services';
import { deepClone } from '@agent-ds/shared/util/util';
import { DecimalPipe } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { EMPTY, of } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';
var SalesCreateDialogComponent = /** @class */ (function () {
    function SalesCreateDialogComponent(dialog, config, dynamicService, companyApiService, masterApiService, userApiService, salesApiService, mailApiService, authService, decimalPipe, dialogService, progressApiService) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.companyApiService = companyApiService;
        this.masterApiService = masterApiService;
        this.userApiService = userApiService;
        this.salesApiService = salesApiService;
        this.mailApiService = mailApiService;
        this.authService = authService;
        this.decimalPipe = decimalPipe;
        this.dialogService = dialogService;
        this.progressApiService = progressApiService;
        this.mainModel = {};
        this.feeModel = {};
        this.emailModel = {};
        this.contractOptions = [];
        this.billingOptions = [];
        this.inClose = false;
        this.validity = false;
        this.validityArray = [false, false, false, false];
    }
    SalesCreateDialogComponent.prototype.isActive = function (billing) {
        return billing.dynamicData.btoBBillignAddressRegistrationDate && billing.dynamicData.billignAddressStatus === '有効' ? billing : false;
    };
    SalesCreateDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return _this.register(); });
        this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.mainMeta = {
                groups: [
                    {
                        title: '送信先',
                        class: 'form__group--green-title-border',
                        rows: [
                            {
                                title: 'To',
                                fields: [
                                    {
                                        name: 'mail.to',
                                        type: 'label',
                                    },
                                ],
                            },
                            {
                                title: 'Cc',
                                fields: [
                                    {
                                        name: 'mail.cc',
                                        type: 'label',
                                        class: 'pre',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        title: '成約情報',
                        class: 'form__group--green-title-border',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('sales', 'salesClassification'), null, 'half').concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('sales', 'hireType')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('sales', 'contractDate')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('sales', 'enterDate'))),
                    },
                    {
                        title: '契約先情報',
                        class: 'form__group--green-title-border',
                        rows: [
                            {
                                title: '契約先',
                                showRequired: true,
                                fields: [
                                    {
                                        name: 'contractId',
                                        type: 'dropdown',
                                        class: 'fill',
                                        valueField: 'id',
                                        labelField: 'label',
                                        options: [],
                                        linkTo: [
                                            'contract.companyName',
                                            'contract.phoneticCompanyName',
                                            'contract.departmentName',
                                            'contract.contact.name',
                                            'contract.address',
                                            'contract.contact.tel',
                                            'contract.contact.fax',
                                        ],
                                        supplier: function () {
                                            return _this.companyApiService.getContracts(_this.config.data.enterpriseId).pipe(map(function (contracts) {
                                                _this.contractOptions = [
                                                    { label: NULL_SELECTED_VALUE, id: null }
                                                ].concat(contracts
                                                    .map(function (contract) {
                                                    contract.dynamicData.id = contract.id;
                                                    contract.dynamicData.label = [
                                                        contract.frontId,
                                                        contract.dynamicData.companyName,
                                                        contract.dynamicData.departmentName,
                                                        contract.dynamicData.contractClassification,
                                                    ]
                                                        .filter(function (v) { return v; })
                                                        .join(' ');
                                                    return contract.dynamicData;
                                                })
                                                    .filter(function (c) { return c.status === '有効'; }));
                                                return {
                                                    options: _this.contractOptions,
                                                };
                                            }));
                                        },
                                        validators: { required: true },
                                    },
                                ],
                            },
                            {
                                title: '企業名',
                                fields: [
                                    {
                                        name: 'contract.companyName',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('contractId');
                                            var contract = _this.contractOptions.find(function (v) { return v.id === linkValue; });
                                            return contract && contract.companyName ? contract.companyName : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '企業名カナ',
                                fields: [
                                    {
                                        name: 'contract.phoneticCompanyName',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('contractId');
                                            var contract = _this.contractOptions.find(function (v) { return v.id === linkValue; });
                                            return contract && contract.phoneticCompanyName ? contract.phoneticCompanyName : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '部署名',
                                fields: [
                                    {
                                        name: 'contract.departmentName',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('contractId');
                                            var contract = _this.contractOptions.find(function (v) { return v.id === linkValue; });
                                            return contract && contract.departmentName ? contract.departmentName : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '担当者名',
                                fields: [
                                    {
                                        name: 'contract.contact.name',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('contractId');
                                            var contract = _this.contractOptions.find(function (v) { return v.id === linkValue; });
                                            return contract && contract.contact && contract.contact.name ? contract.contact.name : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '住所',
                                fields: [
                                    {
                                        name: 'contract.address',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('contractId');
                                            var contract = _this.contractOptions.find(function (v) { return v.id === linkValue; });
                                            return contract && contract.address
                                                ? [
                                                    "" + (contract.address.zip ? contract.address.zip.slice(0, 3) + '-' + contract.address.zip.slice(3) : ''),
                                                    "" + (contract.address.prefecture ? contract.address.prefecture : ''),
                                                    "" + (contract.address.address1 ? contract.address.address1 : ''),
                                                    "" + (contract.address.address2 ? contract.address.address2 : ''),
                                                    "" + (contract.address.address3 ? contract.address.address3 : ''),
                                                ].join(' ')
                                                : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: 'Tel',
                                fields: [
                                    {
                                        name: 'contract.contact.tel',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('contractId');
                                            var contract = _this.contractOptions.find(function (v) { return v.id === linkValue; });
                                            return contract && contract.contact && contract.contact.tel ? contract.contact.tel : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: 'Fax',
                                fields: [
                                    {
                                        name: 'contract.contact.fax',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('contractId');
                                            var contract = _this.contractOptions.find(function (v) { return v.id === linkValue; });
                                            return contract && contract.contact && contract.contact.fax ? contract.contact.fax : '';
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        title: '請求先情報',
                        class: 'form__group--green-title-border',
                        rows: [
                            {
                                title: '請求先',
                                showRequired: true,
                                fields: [
                                    {
                                        name: 'enterpriseBillingAddressId',
                                        type: 'dropdown',
                                        class: 'fill',
                                        valueField: 'id',
                                        labelField: 'label',
                                        options: [],
                                        linkTo: [
                                            'billing.companyName',
                                            'billing.contact.department',
                                            'billing.contact.name',
                                            'billing.contact.position',
                                            'billing.contact.tel',
                                            'billing.contact.fax',
                                            'billing.contact.address',
                                        ],
                                        supplier: function () {
                                            return _this.companyApiService.getDepartments(_this.config.data.enterpriseId).pipe(map(function (contacts) {
                                                _this.billingOptions = [
                                                    { label: NULL_SELECTED_VALUE, id: null }
                                                ].concat((contacts && contacts.enterpriseBillingAddresses && contacts.enterpriseBillingAddresses.length
                                                    ? contacts.enterpriseBillingAddresses
                                                        .filter(function (billing) { return _this.isActive(billing); })
                                                        .map(function (billing) {
                                                        billing.dynamicData.id = billing.id;
                                                        var bservice = billing.dynamicData.billignAddressService && billing.dynamicData.billignAddressService !== ''
                                                            ? billing.dynamicData.billignAddressService + '-'
                                                            : '';
                                                        billing.dynamicData.label = [
                                                            bservice,
                                                            billing.dynamicData.companyName,
                                                            billing.dynamicData.departmentName,
                                                            billing.dynamicData.contact.name,
                                                        ]
                                                            .filter(function (v) { return v; })
                                                            .join(' ');
                                                        return billing.dynamicData;
                                                    })
                                                    : []));
                                                return {
                                                    options: _this.billingOptions,
                                                };
                                            }));
                                        },
                                        validators: { required: true },
                                    },
                                ],
                            },
                            {
                                title: '請求先名',
                                fields: [
                                    {
                                        name: 'billing.companyName',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('enterpriseBillingAddressId');
                                            var billing = _this.billingOptions.find(function (v) { return v.id === linkValue; });
                                            if (billing) {
                                                return _this.companyApiService.getDetail(_this.config.data.enterpriseId).pipe(map(function (company) {
                                                    return [
                                                        // company && company.frontId ? company.frontId : '',
                                                        billing && billing.companyName ? billing.companyName : '',
                                                    ]
                                                        .filter(function (v) { return !!v; })
                                                        .join('-');
                                                }));
                                            }
                                        },
                                    },
                                ],
                            },
                            {
                                title: '担当者部署名',
                                fields: [
                                    {
                                        name: 'billing.contact.department',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('enterpriseBillingAddressId');
                                            var billing = _this.billingOptions.find(function (v) { return v.id === linkValue; });
                                            return billing && billing.departmentName ? billing.departmentName : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '担当者名',
                                fields: [
                                    {
                                        name: 'billing.contact.name',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('enterpriseBillingAddressId');
                                            var billing = _this.billingOptions.find(function (v) { return v.id === linkValue; });
                                            return billing && billing.contact && billing.contact.name ? billing.contact.name : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '担当者役職',
                                fields: [
                                    {
                                        name: 'billing.contact.position',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('enterpriseBillingAddressId');
                                            var billing = _this.billingOptions.find(function (v) { return v.id === linkValue; });
                                            return billing && billing.contact && billing.contact.position ? billing.contact.position : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '担当者Tel',
                                fields: [
                                    {
                                        name: 'billing.contact.tel',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('enterpriseBillingAddressId');
                                            var billing = _this.billingOptions.find(function (v) { return v.id === linkValue; });
                                            return billing && billing.contact && billing.contact.tel ? billing.contact.tel : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '担当者Fax',
                                fields: [
                                    {
                                        name: 'billing.contact.fax',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('enterpriseBillingAddressId');
                                            var billing = _this.billingOptions.find(function (v) { return v.id === linkValue; });
                                            return billing && billing.contact && billing.contact.fax ? billing.contact.fax : '';
                                        },
                                    },
                                ],
                            },
                            {
                                title: '住所',
                                fields: [
                                    {
                                        name: 'billing.contact.address',
                                        type: 'label',
                                        supplier: function (value, callType, getValue) {
                                            var linkValue = getValue('enterpriseBillingAddressId');
                                            var billing = _this.billingOptions.find(function (v) { return v.id === linkValue; });
                                            return billing && billing.address
                                                ? [
                                                    "" + (billing.address.zip ? billing.address.zip.slice(0, 3) + '-' + billing.address.zip.slice(3) : ''),
                                                    "" + (billing.address.prefecture ? billing.address.prefecture : ''),
                                                    "" + (billing.address.address1 ? billing.address.address1 : ''),
                                                    "" + (billing.address.address2 ? billing.address.address2 : ''),
                                                    "" + (billing.address.address3 ? billing.address.address3 : ''),
                                                ].join(' ')
                                                : '';
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
            _this.feeMeta = {
                groups: [
                    {
                        title: '受注額情報',
                        class: 'form__group--green-title-border',
                        rows: [
                            {
                                title: 'フィー',
                                showRequired: true,
                                fields: [
                                    {
                                        name: 'fee',
                                        type: 'number',
                                        labelAfter: '円',
                                        linkTo: ['taxFee', 'chargeFee'],
                                        validators: {
                                            required: true,
                                            maxValue: MAX_FEE_VALUE,
                                        },
                                        class: 'half',
                                    },
                                ],
                            },
                            {
                                title: '消費税',
                                showRequired: true,
                                fields: [
                                    {
                                        name: 'taxFee',
                                        type: 'number',
                                        labelAfter: '円',
                                        supplier: function () { return ''; },
                                        validators: {
                                            required: true,
                                        },
                                        class: 'half',
                                    },
                                    {
                                        name: 'fee-action-label',
                                        type: 'label',
                                        actions: [
                                            {
                                                title: '計算',
                                                type: 'RUNNABLE',
                                                runnable: function (getValue, setValue, value) {
                                                    var feeValue = getValue('fee');
                                                    var fee = +_this.removeLocaleFromStringifiedNumber(feeValue != null ? feeValue : '');
                                                    if (!_this.validityArray[1] && (feeValue == null || isNaN(fee) || fee > MAX_FEE_VALUE)) {
                                                        var config = {
                                                            title: '入力値が不正です',
                                                            messages: ['フィーの金額が正しくありません'],
                                                            style: {
                                                                height: '245px',
                                                                width: '510px',
                                                            },
                                                            buttons: {
                                                                no: '',
                                                                hideNo: true,
                                                                yes: 'OK',
                                                            },
                                                        };
                                                        _this.dialogService.open(ConfirmDialogComponent, config);
                                                    }
                                                    ['ca', 'ra', 'pa'].forEach(function (key) { return _this.onAccountRatioChange(key); });
                                                    if (isNaN(fee)) {
                                                        return value;
                                                    }
                                                    setValue('chargeFee', Math.round(fee * (1 + TAX_PERCENTAGE)));
                                                    setValue('taxFee', Math.round(fee * TAX_PERCENTAGE));
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                title: '請求額',
                                showRequired: true,
                                fields: [
                                    {
                                        name: 'chargeFee',
                                        type: 'number',
                                        labelAfter: '円',
                                        supplier: function () { return ''; },
                                        validators: {
                                            required: true,
                                        },
                                        class: 'half',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
            _this.emailMeta = {
                groups: [
                    {
                        title: '計上エビデンスフォルダパス',
                        class: 'form__group--green-title-border',
                        rows: [
                            {
                                title: 'メールの\n追記文言',
                                showRequired: true,
                                fields: [
                                    {
                                        name: 'email',
                                        type: 'textarea',
                                        class: 'fill',
                                        validators: { required: true },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
            _this.accountMetas = {
                userMeta: {
                    groups: [
                        {
                            class: 'no-title-column no-border',
                            rows: (function () {
                                var dynMeta = _this.dynamicService.getFormRows({
                                    fieldName: 'user',
                                    fieldType: 'user',
                                });
                                // show team name only in dropdown, but not after the user has been selected: Team name is shown then in a separate column
                                if (dynMeta.length && dynMeta[0].fields.length && Array.isArray(dynMeta[0].fields[0].labelField)) {
                                    var labels = dynMeta[0].fields[0].labelField;
                                    var label = labels.find(function (lab) { return lab.name === 'team'; });
                                    if (label) {
                                        label.hiddenAsValue = true;
                                    }
                                }
                                return dynMeta;
                            })().slice(),
                        },
                    ],
                },
                teamMeta: {
                    groups: [
                        {
                            class: 'no-title-column no-border',
                            rows: [
                                {
                                    fields: [
                                        {
                                            type: 'label',
                                            name: 'team',
                                            supplier: function (value) {
                                                return _this.masterApiService.getTeams().pipe(map(function (teams) {
                                                    var select = teams.find(function (team) { return team.id === value; });
                                                    return select ? select.name : undefined;
                                                }));
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ratioMeta: {
                    groups: [
                        {
                            class: 'no-title-column no-border',
                            rows: [
                                {
                                    fields: [
                                        {
                                            name: 'ratio',
                                            type: 'number',
                                            labelAfter: '%',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                feeMeta: {
                    groups: [
                        {
                            class: 'no-title-column no-border',
                            rows: [
                                {
                                    fields: [
                                        {
                                            name: 'fee',
                                            type: 'label',
                                            supplier: function (value) { return [value, value != null ? '円' : ''].join(' '); },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            };
            _this.requiredAccountMetas = deepClone(_this.accountMetas);
            _this.requiredAccountMetas.userMeta.groups[0].rows[0].fields[0].validators = { required: true };
            var raJobUser = _this.config.data.jobUsers.find(function (jobUser) { return jobUser.enterpriseDepartmentUser.type === EnterpriseDepartmentUserType.RA; });
            var paJobUser = _this.config.data.progressType === 1 &&
                _this.config.data.jobUsers.find(function (jobUser) { return jobUser.enterpriseDepartmentUser.type === EnterpriseDepartmentUserType.PA; });
            _this.accountModel = {
                ca: _this.config.data.caUserId ? { user: _this.config.data.caUserId } : {},
                ra: raJobUser ? { user: raJobUser.enterpriseDepartmentUser.userId } : {},
                pa: paJobUser ? { user: paJobUser.enterpriseDepartmentUser.userId } : {},
            };
            ['ca', 'ra', 'pa'].forEach(function (key) { return _this.onAccountUserChange(key); });
        });
    };
    SalesCreateDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubScription) {
            this.fieldSubScription.unsubscribe();
        }
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    SalesCreateDialogComponent.prototype.asIsOrder = function (a, b) {
        return 1;
    };
    SalesCreateDialogComponent.prototype.onAccountUserChange = function (key) {
        var _this = this;
        this.userApiService
            .getAll()
            .pipe(concatMap(function (allUsers) {
            if (_this.accountModel[key]['user'] == null) {
                _this.accountModel[key]['team'] = null;
                _this.accountModel[key]['ratio'] = null;
                _this.accountModel[key]['fee'] = null;
            }
            var users = { ca: null, ra: null, pa: null };
            Object.keys(_this.accountModel).map(function (userKey) { return (users[userKey] = allUsers.find(function (user) { return user.id === _this.accountModel[userKey]['user']; })); });
            var mailToUser = users['ra'];
            if (mailToUser) {
                _this.masterApiService.getTeams().subscribe(function (teams) {
                    var raTeam = teams.find(function (t) { return t.id === mailToUser.teamId; });
                    if (raTeam && raTeam.manager) {
                        _this.mainModel['mail'] = tslib_1.__assign({}, _this.mainModel['mail'], { to: raTeam.manager.name + " <" + raTeam.manager.email + ">" });
                    }
                    else {
                        _this.mainModel['mail'] = tslib_1.__assign({}, _this.mainModel['mail'], { to: null });
                    }
                });
            }
            else {
                _this.mainModel['mail'] = tslib_1.__assign({}, _this.mainModel['mail'], { to: null });
            }
            _this.mainModel['mail'] = tslib_1.__assign({}, _this.mainModel['mail'], { cc: Object.values(users)
                    .filter(function (user) { return !!user; })
                    .map(function (user) { return user.name + " <" + user.email + ">"; })
                    .join('\n') });
            if (users[key] && users[key].teamId) {
                return _this.masterApiService.getTeams().pipe(map(function (teams) {
                    return teams.find(function (team) { return team.id === users[key].teamId; });
                }));
            }
            return of(null);
        }))
            .subscribe(function (team) {
            if (_this.accountModel[key]['user'] == null) {
                _this.accountModel[key]['team'] = null;
                _this.accountModel[key]['ratio'] = null;
                _this.accountModel[key]['fee'] = null;
            }
            else {
                _this.accountModel[key]['team'] = team && team.id;
                _this.accountModel[key]['ratio'] = 100;
                _this.onAccountRatioChange(key);
            }
        });
    };
    SalesCreateDialogComponent.prototype.onAccountRatioChange = function (key) {
        this.accountModel[key]['fee'] =
            this.feeModel &&
                this.feeModel['fee'] != null &&
                this.accountModel &&
                this.accountModel[key] &&
                this.accountModel[key]['ratio'] != null
                ? this.decimalPipe.transform((parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['fee']), 10) / 100) * this.accountModel[key]['ratio'])
                : null;
    };
    SalesCreateDialogComponent.prototype.onMainChange = function (metaKey) {
        var _this = this;
        if (metaKey && metaKey === 'contractId' && this.mainModel && this.mainModel[metaKey] != null) {
            var contract = this.contractOptions.find(function (v) { return v.id === _this.mainModel[metaKey]; });
            if (contract) {
                this.feeSummary = [
                    contract && contract.fee1Price
                        ? this.decimalPipe.transform(contract.fee1Price) + "\n\u30D5\u30A3\u30FC\u898F\u5B9A\u5099\u8003\uFF1A" + (contract.fee1Remarks ? contract.fee1Remarks : '')
                        : '',
                    contract && contract.fee2Price
                        ? this.decimalPipe.transform(contract.fee2Price) + "\n\u30D5\u30A3\u30FC\u898F\u5B9A\u5099\u8003\uFF1A" + (contract.fee2Remarks ? contract.fee2Remarks : '')
                        : '',
                    contract && contract.fee3Price
                        ? this.decimalPipe.transform(contract.fee3Price) + "\n\u30D5\u30A3\u30FC\u898F\u5B9A\u5099\u8003\uFF1A" + (contract.fee3Remarks ? contract.fee3Remarks : '')
                        : '',
                    contract && contract.fee4Price
                        ? this.decimalPipe.transform(contract.fee4Price) + "\n\u30D5\u30A3\u30FC\u898F\u5B9A\u5099\u8003\uFF1A" + (contract.fee4Remarks ? contract.fee4Remarks : '')
                        : '',
                    contract && contract.fee5Price
                        ? this.decimalPipe.transform(contract.fee5Price) + "\n\u30D5\u30A3\u30FC\u898F\u5B9A\u5099\u8003\uFF1A" + (contract.fee5Remarks ? contract.fee5Remarks : '')
                        : '',
                ]
                    .filter(function (v) { return !!v; })
                    .join('\n\n');
                // commented by request
                // const highestFee = contract
                //   ? contract.fee1Price
                //     ? contract.fee1Price
                //     : contract.fee2Price
                //     ? contract.fee2Price
                //     : contract.fee3Price
                //     ? contract.fee3Price
                //     : contract.fee4Price
                //     ? contract.fee4Price
                //     : contract.fee5Price
                //     ? contract.fee5Price
                //     : null
                //   : null;
                // this.feeModel['fee'] = this.decimalPipe.transform(highestFee);
            }
        }
    };
    SalesCreateDialogComponent.prototype.onValidityChange = function (validity, index) {
        this.validityArray[index] = validity;
        this.validity = this.validityArray.find(function (v) { return !v; }) == null;
    };
    SalesCreateDialogComponent.prototype.register = function () {
        var _this = this;
        if (!this.validity || this.inClose) {
            return;
        }
        var salesCreateRequest = {
            progressId: this.config.data.progressId,
            contractId: this.mainModel['contractId'],
            enterpriseBillingAddressId: this.mainModel['enterpriseBillingAddressId'],
            type: this.config.data.progressType,
            dynamicData: {
                contractDate: new Date(this.mainModel['contractDate']).toAsialDateString(),
                enterDate: new Date(this.mainModel['enterDate']).toAsialDateString(),
                fee: parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['fee']), 10),
                taxFee: parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['taxFee']), 10),
                chargeFee: parseInt(this.removeLocaleFromStringifiedNumber(this.feeModel['chargeFee']), 10),
            },
        };
        if (this.accountModel['ca']) {
            salesCreateRequest.caUserId = this.accountModel['ca']['user'];
            salesCreateRequest.caPercentage = this.accountModel['ca']['ratio'];
            salesCreateRequest.caReward = this.accountModel['ca']['fee']
                ? parseInt(this.removeLocaleFromStringifiedNumber(this.accountModel['ca']['fee']), 10)
                : null;
        }
        if (this.accountModel['ra']) {
            salesCreateRequest.raUserId = this.accountModel['ra']['user'];
            salesCreateRequest.raPercentage = this.accountModel['ra']['ratio'];
            salesCreateRequest.raReward = this.accountModel['ra']['fee']
                ? parseInt(this.removeLocaleFromStringifiedNumber(this.accountModel['ra']['fee']), 10)
                : null;
        }
        if (this.accountModel['pa']) {
            salesCreateRequest.paUserId = this.accountModel['pa']['user'];
            salesCreateRequest.paPercentage = this.accountModel['pa']['ratio'];
            salesCreateRequest.paReward = this.accountModel['pa']['fee']
                ? parseInt(this.removeLocaleFromStringifiedNumber(this.accountModel['pa']['fee']), 10)
                : null;
        }
        if (this.mainModel['salesClassification']) {
            salesCreateRequest.dynamicData.salesClassification = this.mainModel['salesClassification'];
        }
        if (this.mainModel['hireType']) {
            salesCreateRequest.dynamicData.hireType = this.mainModel['hireType'];
        }
        this.inClose = true;
        var mailNormalizer = function (mailsStr) {
            return mailsStr
                ? mailsStr
                    .trim()
                    .split('\n')
                    .map(function (v) {
                    var reverse = function (str) {
                        return str
                            .split('')
                            .reverse()
                            .join('');
                    };
                    var reversed = reverse(v);
                    return reverse(reversed.substring(reversed.indexOf('>') + 1, reversed.indexOf('<')).trim());
                })
                : [];
        };
        var from = this.authService.loginUser.email;
        var to = mailNormalizer(this.mainModel['mail']['to']);
        this.salesApiService
            .create(salesCreateRequest)
            .pipe(tap(function (list) {
            _this.progressApiService.refreshEvent.emit();
        }))
            .pipe(mergeMap(function (sales) {
            if (sales && sales.id != null && _this.mainModel && _this.mainModel['mail'] && _this.mainModel['mail']['to']) {
                return _this.mailApiService
                    .getMailTemplates()
                    .pipe(mergeMap(function (templates) {
                    var template = templates.find(function (mailTemplate) { return mailTemplate.id === 22; });
                    return _this.mailApiService.replaceTemplate(template.mailTemplateTypeId, {
                        from: from,
                        to: to,
                        salesId: sales.id,
                        subject: template.subject,
                        text: template.body,
                        additionalReplacements: [{ label: '計上エビデンスフォルダパス', value: _this.emailModel['email'] }],
                    });
                }))
                    .pipe(mergeMap(function (replaced) {
                    return _this.mailApiService.sendSalesNotification({
                        userId: _this.authService.loginUser.id,
                        subject: replaced.subject,
                        text: replaced.body,
                        from: from,
                        to: to,
                        salesId: sales.id,
                        cc: mailNormalizer(_this.mainModel['mail']['cc']),
                    });
                }))
                    .pipe(map(function () { return sales; }));
            }
            else {
                var content = void 0;
                if (!_this.mainModel['mail'] || !_this.mainModel['mail']['to']) {
                    content = "\u6C42\u4EBA\u306BRA\u304C\u767B\u9332\u3055\u308C\u3066\u3044\u306A\u3044\u305F\u3081\u3001\u6C7A\u5B9A\u7533\u8ACB\u30E1\u30FC\u30EB\u3092\u9001\u4FE1\u3057\u307E\u305B\u3093\u3067\u3057\u305F\u3002";
                }
                else if (!sales || !sales.id) {
                    content = "\u6210\u7D04\u30C7\u30FC\u30BF\u304C\u53D6\u5F97\u3067\u304D\u306A\u304B\u3063\u305F\u305F\u3081\u3001\u6C7A\u5B9A\u7533\u8ACB\u30E1\u30FC\u30EB\u3092\u9001\u4FE1\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002";
                }
                else {
                    content = "\u30B7\u30B9\u30C6\u30E0\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u3001\u6C7A\u5B9A\u7533\u8ACB\u30E1\u30FC\u30EB\u3092\u9001\u4FE1\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002";
                }
                PopupControlComponent.instance.open({
                    title: '決定申請メール',
                    content: content,
                    confirmText: 'OK',
                    confirmCallback: function () { return false; },
                });
                return EMPTY;
            }
        }))
            .subscribe(function (sales) {
            _this.inClose = false;
            _this.close(sales);
        }, function (e) {
            PopupControlComponent.instance.open({
                title: '決定申請メール',
                content: "\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002\u4EE5\u4E0B\u306E\u30E1\u30C3\u30BB\u30FC\u30B8\u3092\u30B7\u30B9\u30C6\u30E0\u7BA1\u7406\u8005\u306B\u304A\u77E5\u3089\u305B\u304F\u3060\u3055\u3044\u3002\n\u30E1\u30C3\u30BB\u30FC\u30B8: " + JSON.stringify(e),
                confirmText: 'OK',
                confirmCallback: function () { return false; },
            });
            _this.inClose = false;
        });
    };
    SalesCreateDialogComponent.prototype.close = function (param) {
        this.dialog.close(param);
    };
    SalesCreateDialogComponent.prototype.removeLocaleFromStringifiedNumber = function (str) {
        return str != null ? str.toString().replace(new RegExp(',|/.', 'g'), '') : str;
    };
    return SalesCreateDialogComponent;
}());
export { SalesCreateDialogComponent };
