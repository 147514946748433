<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">新規企業登録</h1>
    <span class="dialog__close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="create-container">
      <div class="content">
        <div class="row">
          <div class="row__column">
            <ag-dynamic-form
              class="row__head-form"
              [metadata]="metadataHead"
              [model]="company.dynamicData"
              (validated)="onValidityChange($event, 0)"
              *ngIf="metadataHead && company"
            ></ag-dynamic-form>
          </div>
        </div>
        <div class="row">
          <div class="row__column">
            <ag-dynamic-form
              [model]="company.dynamicData"
              [metadata]="metadataLeft"
              (validated)="onValidityChange($event, 1)"
              *ngIf="metadataLeft && company"
            ></ag-dynamic-form>
          </div>
          <div class="row__column">
            <ag-dynamic-form
              [model]="company"
              [metadata]="metadataRight"
              (validated)="onValidityChange($event, 2)"
              *ngIf="metadataRight && company"
            ></ag-dynamic-form>
          </div>
        </div>
      </div>

      <div class="content content--bg-gray">
        <div class="row">
          <div class="row__column">
            <ag-dynamic-form
              [model]="company.dynamicData"
              [metadata]="metadataCustomFields"
              *ngIf="metadataCustomFields && company"
            ></ag-dynamic-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!validity || inClose || dialog.inClose"
      (click)="create()"
    >
      登録
    </button>
  </div>
</div>
