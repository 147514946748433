import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupType } from '../config';
import { StudentJobConfig } from './student-job-config';
var ProgressOfferConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressOfferConfig, _super);
    function ProgressOfferConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressOfferConfig.prototype.getTemplateId = function () {
        return 4;
    };
    ProgressOfferConfig.prototype.getTemplateTypeId = function () {
        return [4];
    };
    ProgressOfferConfig.prototype.getPopupConfig = function () {
        return [{ type: MailPopupType.JOB_CHECK }];
    };
    ProgressOfferConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.progressIds = params.progressIds || [];
        }
        _super.prototype.setParams.call(this, params);
    };
    ProgressOfferConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.sender.model.students) {
            return;
        }
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        metaData.groups[0].rows.push({
            title: 'To (求職者)',
            showRequired: true,
            fields: [
                {
                    name: 'to',
                    type: 'label',
                    supplier: function () {
                        return _this.sender.model.students
                            .map(function (s) {
                            return s.emailMainAvailable
                                ? "\u3010\u9001\u4FE1\u4E0D\u53EF\u3011" + (s.lastName + s.firstName) + " \u30E1\u30A4\u30F3 <" + s.emailMain + ">"
                                : s.lastName + s.firstName + " \u30E1\u30A4\u30F3 <" + s.emailMain + ">";
                        })
                            .join(', ');
                    },
                },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (求職者、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            row.fields.unshift({
                name: 'cc_label',
                type: 'label',
                default: '※E-Mailサブにも送信',
            }, {
                name: 'cc-hr',
                type: 'hr',
                class: 'full',
            }, {
                name: 'cc2_label',
                type: 'label',
                default: '※求職者担当にも送信',
            });
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                // displayType: 'email',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.subject : undefined;
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return _this.sender.model.subject; },
                    },
            ],
        });
        var fileRow = {
            title: '添付ファイル',
            class: 'ou',
            fields: [],
        };
        metaData.groups[0].rows.push(fileRow);
        if (this.sender.inConfirm) {
            fileRow.fields.push({
                type: 'label',
                name: 'zip',
                class: 'link',
                clickAction: function () {
                    return _this.sender.jobApiService.getJobsAsPdf(_this.sender.model.jobs.map(function (j) { return j.id; })).subscribe(function (res) { return downloadResponse(res); });
                },
                default: '求人票.pdf',
            });
        }
        var entField = this.sender.dynamicService.getFormRows({
            fieldType: 'multi-enterprise',
            label: '添付ファイル',
            fieldName: 'enterprises',
        }, null, 'half')[0].fields[0];
        if (!this.sender.inConfirm) {
            fileRow.fields.push(entField);
            entField.valueField = null;
            entField.labelBefore = '企業：';
            entField.linkTo = ['enterpriseFileIds'];
            entField.actions = [
                {
                    title: 'データフォルダから選択',
                    type: 'RUNNABLE',
                    allowOn: { enterpriseIds: null },
                    runnable: function () {
                        _this.sender.enterpriseFileIds = _this.sender.model.enterpriseFileIds.slice();
                        PopupControlComponent.instance.open({
                            content: _this.sender.fileSelectTemplate,
                            confirmText: '添付',
                            cancelText: 'キャンセル',
                            title: 'データフォルダ',
                            confirmCallback: function () {
                                _this.sender.model.enterpriseFileIds = _this.sender.enterpriseFileIds.slice();
                            },
                        });
                    },
                },
            ];
        }
        if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
            fileRow.fields.push({
                name: 'enterpriseFileIds',
                type: 'autocomplete',
                labelField: [
                    {
                        name: 'name',
                        class: this.sender.inConfirm ? 'link' : null,
                        action: this.sender.inConfirm
                            ? function (file) {
                                return _this.sender.fileService.downloadFile('enterprise', +Object.keys(_this.sender.enterpriseFiles).find(function (key) { return _this.sender.enterpriseFiles[key].find(function (f) { return f.id === file.id; }) != null; }), file.id, file.name);
                            }
                            : null,
                    },
                ],
                labelBefore: this.sender.inConfirm ? '企業：' : null,
                valueField: 'id',
                multi: true,
                style: !this.sender.inConfirm ? { 'padding-left': '40px' } : null,
                class: 'options-only half',
                options: [],
                supplier: function (value, callType, getValue) {
                    var enterprises = getValue('enterprises');
                    _this.sender.model.enterpriseIds = enterprises
                        ? enterprises.map(function (e) {
                            _this.sender.enterpriseInfoById[e.id] = e.frontId + " " + e.name;
                            return e.id;
                        })
                        : [];
                    var ids = _this.sender.model.enterpriseIds;
                    var selectedFiles = Object.values(_this.sender.enterpriseFiles).flatten().filter(function (f) {
                        return _this.sender.model.enterpriseFileIds.includes(f.id);
                    });
                    if (!ids || !ids.length) {
                        return { options: selectedFiles };
                    }
                    else {
                        return forkJoin(ids.map(function (id) {
                            return _this.sender.fileService.getFiles('enterprise', id).pipe(map(function (res) {
                                _this.sender.enterpriseFiles[id] = res.filter(function (f) { return f.studentSendFlag; });
                                return _this.sender.enterpriseFiles[id];
                            }));
                        })).pipe(map(function (val) { return ({ options: val.flatten() }); }));
                    }
                },
            });
        }
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.body : undefined;
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }].concat(res);
                                    return {
                                        value: value ? res.find(function (s) { return s.id === value.id; }) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        if (!this.sender.inConfirm) {
            metaData.groups.unshift({
                title: '選択した求人',
                class: 'no-title-column',
                rows: [
                    {
                        fields: this.sender.model.jobs.map(function (job, index) { return ({
                            name: 'jobs' + index,
                            type: 'textarea',
                            class: 'quarter',
                            disabled: true,
                            supplier: function () { return job.frontId + "\n" + job.position + "\n" + job.enterpriseName; },
                        }); }),
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    ProgressOfferConfig.prototype.save = function (toSave) {
        var _this = this;
        toSave.jobIds = this.sender.model.jobs.map(function (j) { return j.id; });
        this.sender.progressApiService.checkProgress(this.sender.model.students.map(function (s) { return s.id; }), toSave.jobIds).subscribe(function (res) {
            if (res && res.length) {
                _this.sender.progressErrorInfo = res;
                PopupControlComponent.instance.open({
                    cancelText: '中止',
                    cancelCallback: function () { return _this.sender.close(); },
                    confirmText: '重複案件は上書き',
                    content: _this.sender.progressErrorTemplate,
                    title: '進捗重複',
                    confirmCallback: function () {
                        return _this.sender.mailApiService
                            .sendJobStudentsBulkMail(toSave)
                            .pipe(mergeMap(function (result) {
                            return _this.sender.progressApiService
                                .bulkRegisterStudentsByProgressIds(_this.sender.model.progressIds)
                                .pipe(tap(function () { return _this.sender.afterSend(result); }));
                        }));
                    },
                    multiConfirm: [
                        {
                            confirmText: '重複案件は上書きしないで送信のみ行う',
                            confirmCallback: function () {
                                return _this.sender.mailApiService.sendJobStudentsBulkMail(toSave).pipe(tap(function (result) { return _this.sender.afterSend(result); }));
                            },
                        },
                    ],
                });
            }
            else {
                _this.sender.mailApiService.sendJobStudentsBulkMail(toSave).subscribe(function (result) {
                    _this.sender.progressApiService
                        .bulkRegisterStudentsByProgressIds(_this.sender.model.progressIds)
                        .subscribe(function () { return _this.sender.afterSend(result); });
                });
            }
        });
    };
    return ProgressOfferConfig;
}(StudentJobConfig));
export { ProgressOfferConfig };
