import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { SupplierCallType } from '@agent-ds/shared/models';
import { AgePipe } from '@agent-ds/shared/pipes/age.pipe';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { HttpClient } from '@angular/common/http';
import { of, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CompanyApiService } from './company-api.service';
import { JobApiService } from './job-api.service';
import { MasterApiService } from './master-api.service';
import { StudentApiService } from './student-api.service';
import { UserApiService } from './user-api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../pipes/age.pipe";
import * as i3 from "../auth.service";
import * as i4 from "./master-api.service";
import * as i5 from "./user-api.service";
import * as i6 from "./student-api.service";
import * as i7 from "./company-api.service";
import * as i8 from "./job-api.service";
var DynamicFieldService = /** @class */ (function () {
    function DynamicFieldService(http, agePipe, authService, masterService, userService, studentService, companyService, jobService) {
        var _this = this;
        this.http = http;
        this.agePipe = agePipe;
        this.authService = authService;
        this.masterService = masterService;
        this.userService = userService;
        this.studentService = studentService;
        this.companyService = companyService;
        this.jobService = jobService;
        this.fieldsByName = {};
        this.innerFieldUpdateEvent = new ReplaySubject(1);
        this.authService.isLogined().subscribe(function (loggedIn) {
            if (loggedIn) {
                _this.fetchFieldDefinitions();
            }
        });
    }
    Object.defineProperty(DynamicFieldService.prototype, "fieldUpdateEvent", {
        get: function () {
            return this.innerFieldUpdateEvent;
        },
        enumerable: true,
        configurable: true
    });
    DynamicFieldService.prototype.fetchFieldDefinitions = function () {
        var _this = this;
        this.http.get('/api/master/field-definitions').subscribe(function (result) {
            _this.innerDynamicFields = result;
            Object.keys(_this.innerDynamicFields).forEach(function (sectionKey) {
                _this.innerDynamicFields[sectionKey].forEach(function (field) {
                    field.label = field.label.replace('※', '\n※');
                    _this.fieldsByName[sectionKey + "-" + field.fieldName] = field;
                });
            });
            _this.innerFieldUpdateEvent.next();
        });
    };
    DynamicFieldService.prototype.getDefinitions = function (model) {
        return this.innerDynamicFields[model];
    };
    DynamicFieldService.prototype.getDefinition = function (model, field) {
        var fieldObj = this.fieldsByName[model + "-" + field];
        if (!fieldObj) {
            console.warn("No such dynamic field: " + model + "-" + field);
        }
        return fieldObj;
    };
    DynamicFieldService.prototype.addDefinition = function (field) {
        var _this = this;
        return this.http.post('/api/master/custom-field', field).pipe(tap(function () { return _this.fetchFieldDefinitions(); }));
    };
    DynamicFieldService.prototype.updateDefinition = function (id, requestObj) {
        var _this = this;
        return this.http.put("/api/master/custom-field/" + id, requestObj).pipe(tap(function () { return _this.fetchFieldDefinitions(); }));
    };
    DynamicFieldService.prototype.deleteDefinition = function (id) {
        var _this = this;
        return this.http.delete("/api/master/custom-field/" + id).pipe(tap(function () { return _this.fetchFieldDefinitions(); }));
    };
    DynamicFieldService.prototype.updateRegularFieldOption = function (fieldId, option) {
        var _this = this;
        return this.http.put("/api/master/regular-field/" + fieldId + "/option", { option: option }).pipe(tap(function () { return _this.fetchFieldDefinitions(); }));
    };
    DynamicFieldService.prototype.updateRegularFieldOptionsOrder = function (fieldId, options) {
        var _this = this;
        return this.http
            .put("/api/master/regular-field/" + fieldId + "/option/order", { options: options })
            .pipe(tap(function () { return _this.fetchFieldDefinitions(); }));
    };
    DynamicFieldService.prototype.getFormGroup = function (dynamicField, baseKey) {
        if (!dynamicField) {
            console.warn('Dynamic field is empty');
            return { rows: [] };
        }
        return {
            title: dynamicField.label,
            rows: this.getFormRows(dynamicField, baseKey),
        };
    };
    DynamicFieldService.prototype.getFormRows = function (dynamicField, baseKey, className, customFilter) {
        var _this = this;
        var _a, _b, _c, _d, _e;
        if (!dynamicField) {
            console.warn('Dynamic field is empty');
            return [];
        }
        var key = (baseKey ? baseKey + '.' : '') + dynamicField.fieldName;
        var ret = [];
        var baseRow = {
            title: dynamicField.label,
            subTitle: dynamicField.fieldType !== 'number' && dynamicField.validationStyle && dynamicField.validationStyle.max
                ? dynamicField.fieldType.startsWith('multi')
                    ? "\u6700\u5927" + dynamicField.validationStyle.max + "\u4EBA"
                    : dynamicField.validationStyle.max + '字まで'
                : null,
            showRequired: dynamicField.validationStyle &&
                (dynamicField.validationStyle.required ||
                    (dynamicField.validationStyle[dynamicField.fieldType] && dynamicField.validationStyle[dynamicField.fieldType].required)),
            style: dynamicField.displayStyle,
            class: dynamicField.displayStyle && dynamicField.displayStyle.showInJobDescription ? 'bg-yellow' : null,
        };
        var baseField = {
            name: key,
            validators: tslib_1.__assign({}, dynamicField.validationStyle),
            default: dynamicField.defaultValue && JSON.stringify(dynamicField.defaultValue) !== '{}' ? dynamicField.defaultValue : null,
            class: className,
            placeholder: dynamicField.displayStyle ? dynamicField.displayStyle.placeholder : null,
        };
        var options = (dynamicField.validationStyle ? dynamicField.validationStyle.options : []) || [];
        switch (dynamicField.fieldType) {
            case 'text':
            case 'number':
            case 'url':
                if (dynamicField.displayType === 'number-range') {
                    ret.push(tslib_1.__assign({}, baseRow, { fields: [
                            {
                                type: dynamicField.displayType.split('-')[0],
                                name: key + '.from',
                                class: baseField.class,
                                labelAfter: dynamicField.unit,
                            },
                            {
                                type: 'label',
                                name: key + '.label',
                                transparent: true,
                                default: '~',
                            },
                            {
                                type: dynamicField.displayType.split('-')[0],
                                name: key + '.to',
                                class: baseField.class,
                                labelAfter: dynamicField.unit,
                            },
                        ] }));
                }
                else {
                    ret.push(tslib_1.__assign({}, baseRow, { fields: [
                            tslib_1.__assign({}, baseField, { type: dynamicField.displayType, class: dynamicField.displayType === 'textarea' ? 'fill' : baseField.class, labelAfter: dynamicField.unit }),
                        ] }));
                }
                break;
            case 'list':
            case 'multi-list':
            case 'branch':
            case 'multi-branch':
            case 'team':
                var isNested_1 = dynamicField.fieldType.includes('branch') || dynamicField.fieldType === 'team';
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: (dynamicField.displayType || 'dropdown').replace(/\+.*/, ''), options: ((dynamicField.validationStyle && dynamicField.validationStyle.required) ||
                                dynamicField.displayType === 'checkbox' ||
                                dynamicField.fieldType.startsWith('multi')
                                ? []
                                : [NULL_SELECTED_VALUE]).concat(options), labelField: isNested_1 ? 'name' : null, valueField: isNested_1 ? 'id' : null, class: dynamicField.displayType === 'radio+text' ? 'medium' : baseField.class, labelAfter: dynamicField.unit, specialOption: dynamicField.displayType === 'radio+text' ? 'その他' : null, multi: dynamicField.fieldType.startsWith('multi'), placeholder: baseField.placeholder || dynamicField.fieldType === 'team' ? 'チーム名' : '項目を選択', supplier: dynamicField.fieldType === 'team'
                                ? function () {
                                    return _this.masterService.getTeams().pipe(map(function (res) { return ({
                                        options: (dynamicField.validationStyle && dynamicField.validationStyle.required
                                            ? []
                                            : isNested_1
                                                ? [{ name: NULL_SELECTED_VALUE, id: null }]
                                                : [NULL_SELECTED_VALUE]).concat(res),
                                    }); }));
                                }
                                : dynamicField.fieldType.includes('branch')
                                    ? function () {
                                        return _this.masterService.getBranches().pipe(map(function (res) { return ({
                                            options: (dynamicField.validationStyle && dynamicField.validationStyle.required
                                                ? []
                                                : isNested_1
                                                    ? [{ name: NULL_SELECTED_VALUE, id: null }]
                                                    : [NULL_SELECTED_VALUE]).concat(res),
                                        }); }));
                                    }
                                    : undefined }),
                    ] }));
                break;
            case 'date':
                if (dynamicField.displayType === 'yearmonth') {
                    ret.push(tslib_1.__assign({}, baseRow, { fields: [
                            tslib_1.__assign({}, baseField, { type: 'yearmonth', name: key }),
                        ] }));
                }
                else if (dynamicField.displayType === 'year+month') {
                    ret.push(tslib_1.__assign({}, baseRow, { fields: [
                            tslib_1.__assign({}, baseField, { type: 'year', name: key + '.0', labelAfter: '年' }),
                            tslib_1.__assign({}, baseField, { type: 'month', name: key + '.1', labelAfter: '月' }),
                        ] }));
                }
                else {
                    var timeToo_1 = dynamicField.displayType.includes('time');
                    var dateToo_1 = dynamicField.displayType.includes('date');
                    var todayToo = dynamicField.displayType.includes('today');
                    if (dateToo_1 || dynamicField.displayType === 'birthday') {
                        ret.push(tslib_1.__assign({}, baseRow, { fields: [
                                tslib_1.__assign({}, baseField, { type: 'date', name: key + (timeToo_1 ? '.0' : ''), actions: todayToo && !timeToo_1 ? [{ type: 'RUNNABLE', title: '今日', runnable: function () { return new Date().toStartOfDay(); } }] : null, linkTo: dynamicField.displayType === 'birthday' ? [key + '.age'] : null }),
                            ] }));
                    }
                    if (timeToo_1) {
                        ret[0].fields.push(tslib_1.__assign({}, baseField, { type: 'time', name: key + (dateToo_1 ? '.1' : ''), actions: todayToo
                                ? [
                                    {
                                        type: 'RUNNABLE',
                                        title: '今日',
                                        runnable: function (getValue, setValue, value) {
                                            var today = new Date();
                                            setValue(key + (dateToo_1 ? '.0' : ''), today);
                                            return today;
                                        },
                                    },
                                ]
                                : null }));
                    }
                    if (dynamicField.displayType === 'birthday') {
                        ret[0].fields.push(tslib_1.__assign({}, baseField, { type: 'label', name: key + '.age', default: '--', labelAfter: '歳', transparent: true, supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + (timeToo_1 ? '.0' : ''));
                                return typeof linkValue === 'string' || (linkValue instanceof Date && !isNaN(linkValue.getTime()))
                                    ? _this.agePipe.transform(linkValue)
                                    : '-';
                            } }));
                    }
                }
                break;
            case 'student':
            case 'multi-student':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', multi: dynamicField.fieldType.startsWith('multi'), placeholder: baseField.placeholder || '求職者名、求職者カナ、求職者ID', options: options, class: baseField.class + ' full', specialOption: '1', labelField: [
                                { name: 'frontId', class: 'hollow w-59', skipInFilter: true },
                                { name: 'name', class: 'w-100', supplier: function (s) { return s.lastName + s.firstName; }, skipInFilter: true },
                                {
                                    name: 'schoolName',
                                    class: 'full',
                                    skipInFilter: true,
                                },
                                {
                                    name: 'departmentName',
                                    class: 'full',
                                    skipInFilter: true,
                                },
                                {
                                    name: 'dummy',
                                    class: 'hollow',
                                    supplier: function () { return '担当'; },
                                    skipInFilter: true,
                                },
                                {
                                    name: 'responsible',
                                    class: 'w-131',
                                    supplierAsync: function (s) {
                                        if (s.studentUser && s.studentUser.length) {
                                            return _this.userService
                                                .getAll()
                                                .pipe(map(function (users) { return (users.find(function (u) { return u.id === s.studentUser[0].userId; }) || { name: '-' }).name; }));
                                        }
                                        else {
                                            return of('');
                                        }
                                    },
                                    skipInFilter: true,
                                },
                            ], valueField: dynamicField.displayType === 'email' ? null : 'id', supplier: function (value) {
                                // 通常の求職者の場合、通常のサジェストAPIを呼び、インテークCAの場合、専用のサジェストAPIを呼ぶ関数
                                var suggetWrapper = function (keyword, ids) {
                                    if (_this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA) {
                                        return _this.studentService.getSuggestionsForIntakeCa(keyword, ids);
                                    }
                                    else {
                                        return _this.studentService.getSuggestions(keyword, ids);
                                    }
                                };
                                if (Array.isArray(value)) {
                                    return value.length && typeOf(value[0]) === 'object'
                                        ? suggetWrapper(undefined, value.map(function (v) { return v.id; })).pipe(map(function (res) { return ({ options: res }); }))
                                        : suggetWrapper(undefined, value).pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else if (typeOf(value) === 'object') {
                                    return suggetWrapper(undefined, [value.id]).pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else if (typeof value === 'number') {
                                    return suggetWrapper(undefined, [value]).pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else {
                                    return suggetWrapper(value).pipe(map(function (res) { return ({ options: res }); }));
                                }
                            } }),
                    ] }));
                break;
            case 'enterprise':
            case 'multi-enterprise':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', multi: dynamicField.fieldType.startsWith('multi'), options: options, class: baseField.class + ' full', placeholder: baseField.placeholder || '企業ID、企業No.、企業名、企業カナ', specialOption: '1', labelField: [
                                { name: 'frontId', title: '企業ID', class: 'hollow w-50', skipInFilter: true },
                                { name: 'name', title: '企業名', class: 'full', skipInFilter: true },
                                { name: 'enterpriseNo', title: '企業No.', class: 'w-84', skipInFilter: true },
                            ], valueField: 'id', supplier: function (value) {
                                if (Array.isArray(value)) {
                                    return _this.companyService
                                        .getSuggestions(undefined, typeof value[0] === 'number' ? value : value.map(function (v) { return v.id; }))
                                        .pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else if (typeof value === 'number') {
                                    return _this.companyService.getSuggestions(undefined, [value]).pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else {
                                    return _this.companyService.getSuggestions(value).pipe(map(function (res) { return ({ options: res }); }));
                                }
                            } }),
                    ] }));
                break;
            case 'job':
            case 'multi-job':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', multi: dynamicField.fieldType.startsWith('multi'), options: options, class: baseField.class + ' full', placeholder: baseField.placeholder || 'ID、求人名、企業名', specialOption: '1', labelField: [
                                { name: 'frontId', class: 'hollow w-55', skipInFilter: true },
                                { name: 'position', class: 'full', skipInFilter: true },
                                { name: 'enterpriseName', class: 'full', skipInFilter: true },
                            ], valueField: dynamicField.displayType === 'email' ? null : 'id', supplier: function (value, callType, getter, setter, filters) {
                                var fn = (filters &&
                                    (filters.status === '募集中' || (filters.status && filters.status.length && filters.status.includes('募集中')))
                                    ? _this.jobService.getSuggestionsRecruitment
                                    : _this.jobService.getSuggestions).bind(_this.jobService);
                                var enterpriseIds = filters ? filters.enterpriseIds : undefined;
                                if (Array.isArray(value)) {
                                    return value.length && typeOf(value[0]) === 'object'
                                        ? fn(undefined, value.map(function (v) { return v.id; })).pipe(map(function (res) { return ({ options: res }); }))
                                        : fn(undefined, value).pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else if (typeOf(value) === 'object') {
                                    return fn(undefined, [value.id]).pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else if (typeof value === 'number') {
                                    return fn(undefined, [value]).pipe(map(function (res) { return ({ options: res }); }));
                                }
                                else {
                                    return fn(value, undefined, enterpriseIds).pipe(map(function (res) { return ({ options: res }); }));
                                }
                            } }),
                    ] }));
                break;
            case 'multi-team':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', multi: true, options: options, class: baseField.class + ' full', customTooltipErrorMessage: '該当する担当チームが見つかりません', placeholder: baseField.placeholder || 'チーム名', labelField: 'name', valueField: 'id', supplier: function () { return _this.masterService.getTeams().pipe(map(function (res) { return ({ options: res }); })); } }),
                    ] }));
                break;
            case 'station':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', options: options, placeholder: baseField.placeholder || '駅名', labelField: 'name', valueField: 'name', manualInputOn: '', supplier: function (value) {
                                return value ? _this.masterService.searchStations(value).pipe(map(function (res) { return ({ options: res }); })) : undefined;
                            } }),
                    ] }));
                break;
            case 'user':
            case 'multi-user':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', multi: dynamicField.fieldType.startsWith('multi'), options: options, class: baseField.class + ' full', customTooltipErrorMessage: '該当する担当者が見つかりません', placeholder: baseField.placeholder || '氏名、E-mail', labelField: [
                                { name: 'name', class: dynamicField.displayType === 'email' ? '' : 'full' }
                            ].concat((dynamicField.displayType === 'email'
                                ? [
                                    {
                                        name: 'email',
                                        supplier: function (user) { return user.email; },
                                    },
                                ]
                                : []), [
                                {
                                    name: 'team',
                                    class: 'full hollow',
                                    supplierAsync: function (usr) {
                                        return _this.masterService
                                            .getTeams()
                                            .pipe(map(function (teams) { return (teams.find(function (team) { return team.id === usr.teamId; }) || { name: usr.teamId }).name; }));
                                    },
                                },
                                {
                                    name: 'email',
                                    hidden: true,
                                },
                            ]), valueField: 'id', filters: [
                                {
                                    name: 'office',
                                    labelBefore: '拠点',
                                    options: [],
                                    labelField: 'name',
                                    class: 'w-104',
                                    linkTo: ['teamId'],
                                    transparent: true,
                                    supplier: function () {
                                        return _this.masterService.getBranches().pipe(map(function (branches) { return ({
                                            options: [{ id: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }].concat(branches),
                                            value: NULL_SELECTED_VALUE,
                                        }); }));
                                    },
                                },
                                {
                                    name: 'teamId',
                                    labelBefore: '部署',
                                    options: [],
                                    labelField: 'name',
                                    valueField: 'id',
                                    class: 'w-152',
                                    supplier: function (linkValue) {
                                        return linkValue
                                            ? _this.masterService.getTeams().pipe(map(function (teams) { return ({
                                                options: [
                                                    { id: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }
                                                ].concat(teams.filter(function (t) { return t.branch.id === linkValue.id; })),
                                                value: NULL_SELECTED_VALUE,
                                            }); }))
                                            : { options: [{ id: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }], value: NULL_SELECTED_VALUE };
                                    },
                                },
                                {
                                    name: 'status',
                                    hidden: true,
                                    options: [[1, 3]],
                                    supplier: function () { return 0; },
                                },
                            ], supplier: function (value) {
                                return _this.userService.getAll().pipe(map(function (res) { return ({
                                    options: res.filter(function (u) { return (customFilter ? customFilter(u) : true); }),
                                }); }));
                            } }),
                    ] }));
                break;
            case 'industry':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'dropdown', options: [], valueField: 'code', labelField: 'name', placeholder: baseField.placeholder || '項目を選択', multi: dynamicField.displayType && dynamicField.displayType.startsWith('multi'), supplier: function (value) {
                                return _this.masterService.getFlattenedIndustryTypes().pipe(map(function (res) { return ({
                                    options: ((dynamicField.validationStyle && dynamicField.validationStyle.required) ||
                                        (dynamicField.displayType && dynamicField.displayType.startsWith('multi'))
                                        ? []
                                        : [{ name: NULL_SELECTED_VALUE, code: NULL_SELECTED_VALUE }]).concat(res),
                                }); }));
                            } }),
                    ] }));
                break;
            case 'job-type':
                ret.push(tslib_1.__assign({}, baseRow, { fields: !dynamicField.displayType || !dynamicField.displayType.startsWith('multi')
                        ? [
                            tslib_1.__assign({}, baseField, { type: 'dropdown', placeholder: baseField.placeholder || '項目を選択', name: key + '.code1', options: [], valueField: 'code', labelField: 'name', linkTo: [key + '.code2'], supplier: function (value) {
                                    return _this.masterService.getJobTypes().pipe(map(function (res) {
                                        return {
                                            options: dynamicField.validationStyle && dynamicField.validationStyle.required
                                                ? res
                                                : [{ code: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE, jobTypes: [] }].concat(res),
                                        };
                                    }));
                                } }),
                            tslib_1.__assign({}, baseField, { type: 'dropdown', placeholder: baseField.placeholder || '項目を選択', name: key + '.code2', options: [], default: NULL_SELECTED_VALUE, valueField: 'code', labelField: 'name', supplier: function (value, callType, getValue) {
                                    var linkValue = getValue(key + '.code1');
                                    return linkValue
                                        ? _this.masterService.getJobTypes().pipe(map(function (res) {
                                            var mainType = res.find(function (r) { return r.code === linkValue; }) || { jobTypes: [] };
                                            return {
                                                value: mainType ? (mainType.jobTypes.find(function (j) { return j.code === value; }) || { code: null }).code : null,
                                                options: mainType.jobTypes
                                                    ? [{ code: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }].concat(mainType.jobTypes) : [{ code: NULL_SELECTED_VALUE, name: NULL_SELECTED_VALUE }],
                                            };
                                        }))
                                        : { options: [], value: null };
                                } }),
                        ]
                        : [
                            tslib_1.__assign({}, baseField, { type: 'dropdown', placeholder: baseField.placeholder || '項目を選択', name: key, multi: true, options: [], valueField: 'code', labelField: 'name', supplier: function (value, callType, getValue) {
                                    return _this.masterService.getJobTypes().pipe(map(function (res) { return ({
                                        options: res.flatten(function (subType, level) { return (subType['_selectLevel'] = level); }, 'jobTypes'),
                                    }); }));
                                } }),
                        ] }));
                break;
            case 'address':
                ret.push(tslib_1.__assign({}, baseRow, { title: baseField.name === 'onVacationAddress' ? '休暇中郵便番号・都道府県' : '郵便番号・都道府県', fields: [
                        tslib_1.__assign({}, baseField, { type: 'zip', name: key + '.zip.0' }),
                        tslib_1.__assign({}, baseField, { type: 'label', name: key + '.txt', default: '-', transparent: true }),
                        tslib_1.__assign({}, baseField, { type: 'zip', name: key + '.zip.1', linkTo: [key + '.prefecture'], actions: [
                                {
                                    type: 'UPDATE_LINKED',
                                    title: '検索',
                                },
                            ] }),
                        tslib_1.__assign({}, baseField, { type: 'dropdown', name: key + '.prefecture', linkTo: [key + '.address1', key + '.address2'], valueField: { prefecture: 'prefecture' }, labelField: 'prefecture', transparent: true, options: [
                                { prefecture: NULL_SELECTED_VALUE },
                                { prefecture: '北海道' },
                                { prefecture: '青森県' },
                                { prefecture: '岩手県' },
                                { prefecture: '宮城県' },
                                { prefecture: '秋田県' },
                                { prefecture: '山形県' },
                                { prefecture: '福島県' },
                                { prefecture: '茨城県' },
                                { prefecture: '栃木県' },
                                { prefecture: '群馬県' },
                                { prefecture: '埼玉県' },
                                { prefecture: '千葉県' },
                                { prefecture: '東京都' },
                                { prefecture: '神奈川県' },
                                { prefecture: '新潟県' },
                                { prefecture: '富山県' },
                                { prefecture: '石川県' },
                                { prefecture: '福井県' },
                                { prefecture: '山梨県' },
                                { prefecture: '長野県' },
                                { prefecture: '岐阜県' },
                                { prefecture: '静岡県' },
                                { prefecture: '愛知県' },
                                { prefecture: '三重県' },
                                { prefecture: '滋賀県' },
                                { prefecture: '京都府' },
                                { prefecture: '大阪府' },
                                { prefecture: '兵庫県' },
                                { prefecture: '奈良県' },
                                { prefecture: '和歌山県' },
                                { prefecture: '鳥取県' },
                                { prefecture: '島根県' },
                                { prefecture: '岡山県' },
                                { prefecture: '広島県' },
                                { prefecture: '山口県' },
                                { prefecture: '徳島県' },
                                { prefecture: '香川県' },
                                { prefecture: '愛媛県' },
                                { prefecture: '高知県' },
                                { prefecture: '福岡県' },
                                { prefecture: '佐賀県' },
                                { prefecture: '長崎県' },
                                { prefecture: '熊本県' },
                                { prefecture: '大分県' },
                                { prefecture: '宮崎県' },
                                { prefecture: '鹿児島県' },
                                { prefecture: '沖縄県' },
                            ], placeholder: baseField.placeholder || '項目を選択', supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.zip.1');
                                return callType === SupplierCallType.LINK_CHANGE
                                    ? _this.masterService
                                        .getAddressByZip(linkValue)
                                        .pipe(map(function (result) {
                                        return result.results && result.results.length
                                            ? result.results[0]
                                            : typeOf(value) === 'object'
                                                ? value
                                                : value
                                                    ? { prefecture: value }
                                                    : undefined;
                                    }))
                                    : typeOf(value) === 'object'
                                        ? value
                                        : value
                                            ? { prefecture: value }
                                            : undefined;
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: baseField.name === 'onVacationAddress' ? '休暇中市区町村' : '市区町村', fields: [
                        tslib_1.__assign({}, baseField, { type: 'text', name: key + '.address1', class: 'fill', supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.prefecture');
                                return linkValue ? (linkValue.address1 ? linkValue.address1 : undefined) : undefined;
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: baseField.name === 'onVacationAddress' ? '休暇中丁目番地号' : '丁目番地号', fields: [
                        tslib_1.__assign({}, baseField, { type: 'text', name: key + '.address2', class: 'fill', supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.prefecture');
                                return linkValue ? (linkValue.address2 ? linkValue.address2 : undefined) : undefined;
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: baseField.name === 'onVacationAddress' ? '休暇中建物名・部屋番号' : '建物名・部屋番号', fields: [
                        tslib_1.__assign({}, baseField, { type: 'text', name: key + '.address3', class: 'fill' }),
                    ] }));
                break;
            case 'from-station':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        {
                            type: 'dropdown',
                            name: key + '.method',
                            options: [NULL_SELECTED_VALUE, '徒歩', 'バス'],
                        },
                        {
                            type: 'number',
                            name: key + '.minute',
                            labelAfter: '分',
                        },
                    ] }));
                break;
            case 'tel':
            case 'email':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        {
                            type: 'text',
                            name: key + "." + dynamicField.fieldType,
                            class: 'fill',
                            validators: baseField.validators && baseField.validators[dynamicField.fieldType]
                                ? baseField.validators[dynamicField.fieldType]
                                : baseField.validators,
                        },
                        {
                            type: 'dropdown',
                            name: key + "." + dynamicField.fieldType + "Available",
                            options: [NULL_SELECTED_VALUE, '不可'],
                            validators: baseField.validators && baseField.validators[dynamicField.fieldType] ? null : baseField.validators,
                        },
                    ] }));
                break;
            case 'academic':
                ret.push(tslib_1.__assign({}, baseRow, { title: '学校名', fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', name: key + '.schoolCode', class: 'half', valueField: { schoolName: 'name', schoolCode: 'code' }, labelField: 'name', linkTo: [key + '.departmentCode'], options: [], transparent: true, manualInputOn: 'name', supplier: function (value, callType, getValue) {
                                var root = getValue ? getValue(key) : null;
                                var schoolName = root ? root.schoolName : null;
                                return _this.masterService.suggestSchools(typeof value === 'string' ? value : schoolName).pipe(map(function (res) { return ({
                                    value: res.find(function (r) { return r.code === value; }) || schoolName ? { name: schoolName } : undefined,
                                    options: res,
                                }); }));
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '学部', fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', name: key + '.departmentCode', class: 'half', valueField: { departmentName: 'name', departmentCode: 'code' }, labelField: 'name', linkTo: [key + '.subDepartmentCode'], options: [], transparent: true, manualInputOn: 'name', supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.schoolCode');
                                var root = getValue ? getValue(key) : null;
                                var departmentName = root ? root.departmentName : null;
                                return linkValue
                                    ? {
                                        value: (value && linkValue.departments ? linkValue.departments : []).find(function (r) { return r.code === value; }) || departmentName
                                            ? { name: departmentName }
                                            : null,
                                        options: linkValue.departments,
                                    }
                                    : departmentName
                                        ? { name: departmentName }
                                        : null;
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '学科・専攻', fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', name: key + '.subDepartmentCode', class: 'half', valueField: { subDepartmentName: 'name', subDepartmentCode: 'code' }, labelField: 'name', options: [], manualInputOn: 'name', supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.departmentCode');
                                var root = getValue ? getValue(key) : null;
                                var subDepartmentName = root ? root.subDepartmentName : null;
                                return linkValue
                                    ? {
                                        value: (value && linkValue.subDepartments ? linkValue.subDepartments : []).find(function (r) { return r.code === value; }) ||
                                            subDepartmentName
                                            ? { name: subDepartmentName }
                                            : null,
                                        options: linkValue.subDepartments,
                                    }
                                    : subDepartmentName
                                        ? { name: subDepartmentName }
                                        : null;
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '卒業年', fields: [
                        tslib_1.__assign({}, baseField, { type: 'year', name: key + '.graduateYear', labelAfter: '年' }),
                        tslib_1.__assign({}, baseField, { type: 'month', name: key + '.graduateMonth', labelAfter: '月' }),
                        tslib_1.__assign({}, baseField, { type: 'radio', name: key + '.graduateType', multi: true, options: [NULL_SELECTED_VALUE, '卒業見込', '卒業', '中退', '転学'] }),
                    ] }));
                break;
            case 'certificate':
                ret.push(tslib_1.__assign({}, baseRow, { title: '資格', fields: [
                        tslib_1.__assign({}, baseField, { type: 'autocomplete', name: key + '.code', class: 'half', valueField: { name: 'name', code: 'code' }, labelField: 'name', linkTo: [key + '.gradeCode'], options: [], supplier: function (value) {
                                return _this.masterService.getCertificates().pipe(map(function (certs) {
                                    var opts = (certs || []).removeSame('code');
                                    return {
                                        value: opts.find(function (r) { return r.code === value; }),
                                        options: opts,
                                    };
                                }));
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '資格等級', fields: [
                        tslib_1.__assign({}, baseField, { type: 'dropdown', name: key + '.gradeCode', class: 'half', valueField: { gradeName: 'gradeName', gradeCode: 'gradeCode' }, labelField: 'gradeName', options: [], supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.code');
                                return linkValue
                                    ? _this.masterService.getCertificates().pipe(map(function (certs) {
                                        var opts = certs.filter(function (cert) { return cert.code === linkValue; });
                                        return {
                                            value: opts.find(function (opt) { return opt.gradeCode === value; }),
                                            options: baseField.validators && baseField.validators.required
                                                ? opts
                                                : [{ gradeName: NULL_SELECTED_VALUE, gradeCode: null }].concat(opts),
                                        };
                                    }))
                                    : { options: [] };
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '取得年月', fields: [
                        tslib_1.__assign({}, baseField, { type: 'year', name: key + '.issueYear', labelAfter: '年', allowOn: (_a = {}, _a[key + '.code'] = null, _a) }),
                        tslib_1.__assign({}, baseField, { type: 'month', name: key + '.issueMonth', labelAfter: '月', allowOn: (_b = {}, _b[key + '.code'] = null, _b) }),
                    ] }));
                break;
            case 'language':
                ret.push(tslib_1.__assign({}, baseRow, { title: '言語', fields: [
                        tslib_1.__assign({}, baseField, { type: 'dropdown', name: key + '.language', class: 'half', labelField: 'name', valueField: { language: 'code' }, linkTo: [key + '.code'], options: [], transparent: true, supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key);
                                return linkValue !== undefined
                                    ? _this.masterService.getLanguageCerts().pipe(map(function (res) {
                                        var langs = res.map(function (cert) { return cert.language; }).removeSame('id');
                                        var foundBoth = linkValue
                                            ? res.find(function (cert) { return cert.code === linkValue.code || cert.language.code === value; })
                                            : null;
                                        var foundSelected = foundBoth || res.find(function (cert) { return cert.language.code === value; });
                                        var foundForLink = foundBoth || (linkValue ? res.find(function (cert) { return cert.code === linkValue.code; }) : null);
                                        return {
                                            value: (foundBoth || foundSelected || foundForLink || { language: null }).language,
                                            options: baseField.validators && baseField.validators.required
                                                ? langs
                                                : [{ name: NULL_SELECTED_VALUE, code: null }].concat(langs),
                                        };
                                    }))
                                    : _this.masterService.getLanguages().pipe(map(function (res) { return ({
                                        value: undefined,
                                        options: baseField.validators && baseField.validators.required
                                            ? res
                                            : [{ name: NULL_SELECTED_VALUE, code: null }].concat(res),
                                    }); }));
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '語学資格の種類', fields: [
                        tslib_1.__assign({}, baseField, { type: 'dropdown', name: key + '.code', class: 'half', valueField: { name: 'name', code: 'code' }, labelField: 'name', linkTo: [key + '.gradeCode', key + '.language'], options: [], supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.language');
                                return linkValue || value
                                    ? _this.masterService.getLanguageCerts().pipe(map(function (certs) {
                                        var opts = linkValue
                                            ? certs.filter(function (cert) { return cert.language && cert.language.id === linkValue.id; }).removeSame('code')
                                            : certs.filter(function (cert) { return cert.code === value; });
                                        return {
                                            value: opts.find(function (opt) { return opt.code === value; }) || null,
                                            options: baseField.validators && baseField.validators.required
                                                ? opts
                                                : [{ name: NULL_SELECTED_VALUE, code: null }].concat(opts),
                                        };
                                    }))
                                    : { options: [], value: null };
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: 'レベル', fields: [
                        tslib_1.__assign({}, baseField, { type: 'dropdown', name: key + '.gradeCode', class: 'half', valueField: { gradeName: 'gradeName', gradeCode: 'gradeCode' }, labelField: 'gradeName', options: [], supplier: function (value, callType, getValue) {
                                var linkValue = getValue(key + '.code');
                                var langLinkValue = getValue(key + '.language');
                                return linkValue && langLinkValue
                                    ? _this.masterService.getLanguageCerts().pipe(map(function (certs) {
                                        var opts = certs.filter(function (cert) { return cert.code === linkValue && cert.language.id === langLinkValue.id; });
                                        return {
                                            value: opts.find(function (opt) { return opt.gradeCode === value; }) || null,
                                            options: baseField.validators && baseField.validators.required
                                                ? opts
                                                : [{ gradeName: NULL_SELECTED_VALUE, gradeCode: null }].concat(opts),
                                        };
                                    }))
                                    : { options: [], value: linkValue ? undefined : null };
                            } }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '点数', fields: [
                        tslib_1.__assign({}, baseField, { type: 'number', name: key + '.score', labelAfter: '点', allowOn: (_c = {}, _c[key + '.gradeCode'] = null, _c) }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '取得年月', fields: [
                        tslib_1.__assign({}, baseField, { type: 'year', name: key + '.issueYear', labelAfter: '年', allowOn: (_d = {}, _d[key + '.language'] = { code: null }, _d) }),
                        tslib_1.__assign({}, baseField, { type: 'month', name: key + '.issueMonth', labelAfter: '月', allowOn: (_e = {}, _e[key + '.language'] = { code: null }, _e) }),
                    ] }));
                break;
            case 'company-contact':
                ret.push.apply(ret, [tslib_1.__assign({}, baseRow, { title: '役職', fields: [
                            tslib_1.__assign({}, baseField, { type: 'text', name: key + '.position', class: 'fill' }),
                        ] }), tslib_1.__assign({}, baseRow, { title: '氏名', fields: [
                            tslib_1.__assign({}, baseField, { type: 'text', name: key + '.name', class: 'fill' }),
                        ] }), tslib_1.__assign({}, baseRow, { title: 'カナ', fields: [
                            tslib_1.__assign({}, baseField, { type: 'text', name: key + '.phoneticName', class: 'fill' }),
                        ] }), tslib_1.__assign({}, baseRow, { title: 'Tel', fields: [
                            tslib_1.__assign({}, baseField, { type: 'text', name: key + '.tel', class: 'fill' }),
                        ] }), tslib_1.__assign({}, baseRow, { title: 'Fax', fields: [
                            tslib_1.__assign({}, baseField, { type: 'text', name: key + '.fax', class: 'fill' }),
                        ] })].concat(this.getFormRows({
                    label: 'E-mail',
                    fieldName: key,
                    fieldType: 'email',
                    displayStyle: baseRow.style,
                    validationStyle: baseField.validators,
                }, ''), [tslib_1.__assign({}, baseRow, { title: '備考', fields: [
                            tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.remarks', class: 'fill' }),
                        ] })]));
                break;
            case 'date-range':
            case 'time-range':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        {
                            type: dynamicField.displayType.split('-')[0],
                            name: key + '.from',
                            linkTo: [key + '-actions'],
                        },
                        {
                            type: 'label',
                            name: key + '.label',
                            transparent: true,
                            default: '~',
                        },
                        {
                            type: dynamicField.displayType.split('-')[0],
                            name: key + '.to',
                            linkTo: [key + '-actions'],
                        },
                        {
                            name: key + '-actions',
                            transparent: true,
                            type: 'label',
                            hidden: true,
                            actions: dynamicField.displayType === 'date-advanced'
                                ? [
                                    {
                                        type: 'RUNNABLE',
                                        title: '先月',
                                        runnable: function (getValue, setValue, value) {
                                            var tmp = Date.lastMonth();
                                            setValue(key + '.from', tmp.from);
                                            setValue(key + '.to', tmp.to);
                                        },
                                    },
                                    {
                                        type: 'RUNNABLE',
                                        title: '今月',
                                        runnable: function (getValue, setValue, value) {
                                            var tmp = Date.currentMonth();
                                            setValue(key + '.from', tmp.from);
                                            setValue(key + '.to', tmp.to);
                                        },
                                    },
                                    {
                                        type: 'SELECT',
                                        title: 'その他',
                                        options: ['昨日', '今日', '明日', '来月', '昨年', '今年', '来年'],
                                        runnable: function (getValue, setValue, value) {
                                            var tmp = { from: null, to: null };
                                            switch (value) {
                                                case '昨日':
                                                    tmp = Date.yesterday();
                                                    break;
                                                case '今日':
                                                    tmp = Date.today();
                                                    break;
                                                case '明日':
                                                    tmp = Date.tomorrow();
                                                    break;
                                                case '来月':
                                                    tmp = Date.nextMonth();
                                                    break;
                                                case '昨年':
                                                    tmp = Date.lastYear(true);
                                                    break;
                                                case '今年':
                                                    tmp = Date.currentYear(true);
                                                    break;
                                                case '来年':
                                                    tmp = Date.nextYear(true);
                                                    break;
                                            }
                                            if (tmp.from && tmp.to) {
                                                setValue(key + '.from', tmp.from);
                                                setValue(key + '.to', tmp.to);
                                            }
                                            else {
                                                tmp = {
                                                    from: new Date(getValue(key + '.from') || undefined),
                                                    to: new Date(getValue(key + '.to') || undefined),
                                                };
                                                var selectKey = key + '-actions.actions.' + 2;
                                                if ((!tmp.from.isValid() && tmp.to.isValid()) || (tmp.from.isValid() && !tmp.to.isValid())) {
                                                    return;
                                                }
                                                var compare = Date.yesterday();
                                                if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                    setValue(selectKey, '昨日');
                                                    return;
                                                }
                                                compare = Date.today();
                                                if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                    setValue(selectKey, '今日');
                                                    return;
                                                }
                                                compare = Date.tomorrow();
                                                if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                    setValue(selectKey, '明日');
                                                    return;
                                                }
                                                compare = Date.nextMonth();
                                                if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                    setValue(selectKey, '来月');
                                                    return;
                                                }
                                                compare = Date.lastYear(true);
                                                if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                    setValue(selectKey, '昨年');
                                                    return;
                                                }
                                                compare = Date.currentYear(true);
                                                if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                    setValue(selectKey, '今年');
                                                    return;
                                                }
                                                compare = Date.nextYear(true);
                                                if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                    setValue(selectKey, '来年');
                                                    return;
                                                }
                                                setValue(selectKey, null);
                                            }
                                        },
                                    },
                                ]
                                : dynamicField.displayType === 'date-more-advanced'
                                    ? [
                                        {
                                            type: 'RUNNABLE',
                                            title: '先週',
                                            runnable: function (getValue, setValue, value) {
                                                var tmp = Date.lastWeek();
                                                setValue(key + '.from', tmp.from);
                                                setValue(key + '.to', tmp.to);
                                            },
                                        },
                                        {
                                            type: 'RUNNABLE',
                                            title: '今週',
                                            runnable: function (getValue, setValue, value) {
                                                var tmp = Date.currentWeek();
                                                setValue(key + '.from', tmp.from);
                                                setValue(key + '.to', tmp.to);
                                            },
                                        },
                                        {
                                            type: 'RUNNABLE',
                                            title: '来週',
                                            runnable: function (getValue, setValue, value) {
                                                var tmp = Date.nextWeek();
                                                setValue(key + '.from', tmp.from);
                                                setValue(key + '.to', tmp.to);
                                            },
                                        },
                                        {
                                            type: 'RUNNABLE',
                                            title: '先月',
                                            runnable: function (getValue, setValue, value) {
                                                var tmp = Date.lastMonth();
                                                setValue(key + '.from', tmp.from);
                                                setValue(key + '.to', tmp.to);
                                            },
                                        },
                                        {
                                            type: 'RUNNABLE',
                                            title: '今月',
                                            runnable: function (getValue, setValue, value) {
                                                var tmp = Date.currentMonth();
                                                setValue(key + '.from', tmp.from);
                                                setValue(key + '.to', tmp.to);
                                            },
                                        },
                                        {
                                            type: 'RUNNABLE',
                                            title: '来月',
                                            runnable: function (getValue, setValue, value) {
                                                var tmp = Date.nextMonth();
                                                setValue(key + '.from', tmp.from);
                                                setValue(key + '.to', tmp.to);
                                            },
                                        },
                                        {
                                            type: 'SELECT',
                                            title: 'その他',
                                            options: ['昨日', '今日', '明日', '昨年', '今年', '来年'],
                                            runnable: function (getValue, setValue, value) {
                                                var tmp = { from: null, to: null };
                                                switch (value) {
                                                    case '昨日':
                                                        tmp = Date.yesterday();
                                                        break;
                                                    case '今日':
                                                        tmp = Date.today();
                                                        break;
                                                    case '明日':
                                                        tmp = Date.tomorrow();
                                                        break;
                                                    case '昨年':
                                                        tmp = Date.lastYear(true);
                                                        break;
                                                    case '今年':
                                                        tmp = Date.currentYear(true);
                                                        break;
                                                    case '来年':
                                                        tmp = Date.nextYear(true);
                                                        break;
                                                }
                                                if (tmp.from && tmp.to) {
                                                    setValue(key + '.from', tmp.from);
                                                    setValue(key + '.to', tmp.to);
                                                }
                                                else {
                                                    tmp = {
                                                        from: new Date(getValue(key + '.from') || undefined),
                                                        to: new Date(getValue(key + '.to') || undefined),
                                                    };
                                                    var selectKey = key + '-actions.actions.' + 6;
                                                    if ((!tmp.from.isValid() && tmp.to.isValid()) || (tmp.from.isValid() && !tmp.to.isValid())) {
                                                        return;
                                                    }
                                                    var compare = Date.yesterday();
                                                    if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                        setValue(selectKey, '昨日');
                                                        return;
                                                    }
                                                    compare = Date.today();
                                                    if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                        setValue(selectKey, '今日');
                                                        return;
                                                    }
                                                    compare = Date.tomorrow();
                                                    if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                        setValue(selectKey, '明日');
                                                        return;
                                                    }
                                                    compare = Date.lastYear(true);
                                                    if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                        setValue(selectKey, '昨年');
                                                        return;
                                                    }
                                                    compare = Date.currentYear(true);
                                                    if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                        setValue(selectKey, '今年');
                                                        return;
                                                    }
                                                    compare = Date.nextYear(true);
                                                    if (compare.from.dateEqual(tmp.from) && compare.to.dateEqual(tmp.to)) {
                                                        setValue(selectKey, '来年');
                                                        return;
                                                    }
                                                    setValue(selectKey, null);
                                                }
                                            },
                                        },
                                    ]
                                    : dynamicField.displayType === 'date-seminar-list'
                                        ? [
                                            {
                                                type: 'SELECT',
                                                title: '期間',
                                                options: ['今週', '来週', '今月', '先月〜今月', '今年'],
                                                runnable: function (getValue, setValue, value) {
                                                    var tmp = { from: null, to: null };
                                                    switch (value) {
                                                        case '今週': // 今週 this week
                                                            tmp = Date.currentWeek();
                                                            break;
                                                        case '来週': // 来週 next week
                                                            tmp = Date.nextWeek();
                                                            break;
                                                        case '今月': // 今月 this month 1st to end of month
                                                            tmp = Date.currentMonth();
                                                            break;
                                                        case '先月〜今月': // 先月〜今月 last month-this month, Last month 1-end of this month
                                                            var _a = Date.lastMonth(), from = _a.from, restOfLastMonth = tslib_1.__rest(_a, ["from"]);
                                                            var _b = Date.currentMonth(), to = _b.to, restOfCurrentMonth = tslib_1.__rest(_b, ["to"]);
                                                            tmp = { from: from, to: to };
                                                            break;
                                                        case '今年': // 今年 this year, January 1-December 31
                                                            tmp = Date.currentYear();
                                                            break;
                                                    }
                                                    if (tmp.from && tmp.to) {
                                                        setValue(key + '.from', tmp.from);
                                                        setValue(key + '.to', tmp.to);
                                                    }
                                                },
                                            },
                                        ]
                                        : null,
                        },
                    ] }));
                break;
            case 'screening-info':
                ret.push(tslib_1.__assign({}, baseRow, { title: '会場名', fields: [
                        tslib_1.__assign({}, baseField, { type: 'text', name: key + '.location', class: 'fill' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '会場住所', fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.address', class: 'fill' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '会場最寄駅', fields: [
                        tslib_1.__assign({}, baseField, { type: 'text', name: key + '.nearestStation' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '所要時間', fields: [
                        tslib_1.__assign({}, baseField, { type: 'text', name: key + '.duration' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '緊急連絡先', fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.emergencyContact', class: 'fill' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '持参物', fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.preparation', class: 'fill' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: 'その他対応事項', fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.remarks', class: 'fill' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '対応事項1', fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.request1', class: 'fill' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '対応事項2', fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.request2', class: 'fill' }),
                    ] }), tslib_1.__assign({}, baseRow, { title: '対応事項3', fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.request3', class: 'fill' }),
                    ] }));
                break;
            case 'interview-info':
                ret.push(tslib_1.__assign({}, baseRow, { title: null, fields: [
                        tslib_1.__assign({}, baseField, { type: 'textarea', name: key + '.description', class: 'fill' }),
                    ] }));
                break;
            case 'yes-no':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'radio', labelField: 'label', valueField: 'value', options: [
                                { label: NULL_SELECTED_VALUE, value: NULL_SELECTED_VALUE },
                                { label: 'あり', value: 1 },
                                { label: 'なし', value: 0 },
                            ] }),
                    ] }));
                break;
            case 'certificate-level':
                ret.push(tslib_1.__assign({}, baseRow, { fields: [
                        tslib_1.__assign({}, baseField, { type: 'dropdown', multi: true, valueField: 'gradeCode', labelField: 'gradeName', placeholder: baseField.placeholder || '項目を選択', options: [], supplier: function (value) {
                                return _this.masterService.getLanguageCerts().pipe(map(function (certs) { return ({
                                    options: certs
                                        .filter(function (cert) { return cert.code === '69012'; })
                                        .removeSame('gradeCode')
                                        .map(function (cert) { return ({ gradeCode: cert.gradeCode, gradeName: cert.gradeName }); }),
                                }); }));
                            } }),
                    ] }));
                break;
        }
        return ret;
    };
    DynamicFieldService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicFieldService_Factory() { return new DynamicFieldService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AgePipe), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.MasterApiService), i0.ɵɵinject(i5.UserApiService), i0.ɵɵinject(i6.StudentApiService), i0.ɵɵinject(i7.CompanyApiService), i0.ɵɵinject(i8.JobApiService)); }, token: DynamicFieldService, providedIn: "root" });
    return DynamicFieldService;
}());
export { DynamicFieldService };
