import { EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
var DialogRef = /** @class */ (function () {
    function DialogRef(beforeClose) {
        this.afterClosedSubject = new Subject();
        this.confirmed = new EventEmitter();
        this.beforeClose = beforeClose;
    }
    Object.defineProperty(DialogRef.prototype, "afterClosed", {
        get: function () {
            return this.afterClosedSubject;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogRef.prototype, "inClose", {
        get: function () {
            return this.inCloseFlag;
        },
        enumerable: true,
        configurable: true
    });
    DialogRef.prototype.close = function (result) {
        var _this = this;
        if (this.inClose) {
            return;
        }
        this.inCloseFlag = true;
        if (this.beforeClose) {
            var ret = this.beforeClose(result);
            if (ret instanceof Observable) {
                ret.subscribe(function () {
                    _this.afterClosedSubject.next(result);
                    _this.inCloseFlag = false;
                }, function () {
                    _this.inCloseFlag = false;
                });
            }
            else {
                this.inCloseFlag = false;
                if (!ret) {
                    this.afterClosedSubject.next(result);
                }
            }
        }
        else {
            this.inCloseFlag = false;
            this.afterClosedSubject.next(result);
        }
    };
    return DialogRef;
}());
export { DialogRef };
