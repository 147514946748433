<div class="dialog">
  <div class="dialog__header">
    <h1 [ngSwitch]="approvalClassification" class="dialog__title">
      <span *ngSwitchCase="ApprovalClassification.UNAPPROVED"
        >新規契約詳細・編集</span
      >
      <span *ngSwitchCase="ApprovalClassification.WAITING_FOR_RE_APPLICATION"
        >契約詳細・編集</span
      >
      <span *ngSwitchCase="ApprovalClassification.APPROVAL_PENDING"
        >契約詳細</span
      >
      <span *ngSwitchCase="ApprovalClassification.APPROVED">契約詳細</span>
      <span *ngSwitchDefault>新規契約登録</span>
    </h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="content">
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            *ngIf="metadata1"
            [metadata]="metadata1"
            [model]="model"
            (validated)="onValidityChange(0, $event)"
          ></ag-dynamic-form>
        </div>
        <div class="row__column">
          <div class="dep-selector">
            <ag-select
              class="dep-selector__select"
              #depSelect
              [options]="departmentDynamicList"
              labelField="name"
              [readonly]="metadata1?.disabled"
            ></ag-select>
            <button
              class="btn btn--small btn--dark"
              (click)="onDepartmentSelect(depSelect.value)"
              [disabled]="metadata1?.disabled"
            >
              引用
            </button>
          </div>
          <ag-dynamic-form
            *ngIf="metadata2"
            [metadata]="metadata2"
            [model]="model.dynamicData"
            (validated)="onValidityChange(1, $event)"
          ></ag-dynamic-form>
        </div>
      </div>
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            *ngIf="metadata3"
            [metadata]="metadata3"
            [model]="model.dynamicData"
            (validated)="onValidityChange(2, $event)"
          ></ag-dynamic-form>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button
      *ngFor="let action of actions"
      class="dialog__button"
      [disabled]="(action.type === 'primary' && !valid) || dialog.inClose"
      [class.dialog__button--accent]="action.type === 'accent'"
      [class.dialog__button--primary]="action.type === 'primary'"
      (click)="action.action()"
    >
      {{ action.label }}
    </button>
  </div>
</div>
