import { CACHE } from '@agent-ds/shared/util/cache';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { download, openFile } from '../../util/util';
import { StudentApiService } from './student-api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./student-api.service";
var FileApiService = /** @class */ (function () {
    function FileApiService(http, studentService) {
        this.http = http;
        this.studentService = studentService;
    }
    FileApiService.prototype.getFiles = function (model, id) {
        if (model === void 0) { model = 'students'; }
        return this.http.get("/api/" + model + "/" + id + "/files");
    };
    FileApiService.prototype.uploadFile = function (model, id, file, fileName, fileId, enterpriseSendFlag, studentSendFlag) {
        var _this = this;
        if (model === void 0) { model = 'students'; }
        var formData = new FormData();
        formData.append('file', file, fileName || file.name);
        formData.append('enterpriseSendFlag', "" + enterpriseSendFlag);
        formData.append('studentSendFlag', "" + studentSendFlag);
        var url = "/api/" + model + "/" + id + "/file" + (fileId ? "/" + fileId + "/replace" : '');
        var obs = fileId ? this.http.put(url, formData) : this.http.post(url, formData);
        return model === 'students' && fileName && fileName.startsWith('face_')
            ? obs.pipe(tap(function () {
                CACHE.clear("/api/students/" + id + "/profile-image/");
                _this.studentService.refreshEvent.emit();
            }))
            : obs;
    };
    FileApiService.prototype.downloadFile = function (model, id, fileId, fileName) {
        if (model === void 0) { model = 'students'; }
        this.http.get("/api/" + model + "/" + id + "/file/" + fileId, { responseType: 'blob' }).subscribe(function (res) { return download(res, fileName); });
    };
    FileApiService.prototype.openFile = function (model, id, fileId, fileName) {
        if (model === void 0) { model = 'students'; }
        this.http.get("/api/" + model + "/" + id + "/file/" + fileId, { responseType: 'blob' }).subscribe(function (res) { return openFile(res, fileName); });
    };
    FileApiService.prototype.deleteFile = function (model, id, fileId) {
        if (model === void 0) { model = 'students'; }
        return this.http.delete("/api/" + model + "/" + id + "/file/" + fileId);
    };
    FileApiService.prototype.downloadSecondMatchCsv = function (from, to) {
        return this.http.get('/api/second-matching/file/csv', {
            responseType: 'blob',
            observe: 'response',
            params: { from: from, to: to },
            headers: {
                'Content-Type': 'application/csv',
                Accept: 'application/csv',
            },
        });
    };
    FileApiService.prototype.getReportCsvList = function () {
        return this.http.get('/api/published-csv');
    };
    FileApiService.prototype.downloadReportCsv = function (id) {
        return this.http.get("/api/published-csv/" + id, { responseType: 'blob', observe: 'response' });
    };
    FileApiService.prototype.getUnconfirmedFiles = function (from, size) {
        return this.http.get('/api/students/uploads', {
            params: {
                from: from,
                size: 9999,
            },
        });
    };
    FileApiService.prototype.renameStudentUploadedFile = function (studentId, fileId, fileName) {
        return this.http.put("/api/students/" + studentId + "/file/" + fileId + "/rename", { name: fileName });
    };
    FileApiService.prototype.changeToSendable = function (studentId, fileId) {
        return this.http.put("/api/students/" + studentId + "/file/" + fileId + "/change-to-sendable", {});
    };
    FileApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileApiService_Factory() { return new FileApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StudentApiService)); }, token: FileApiService, providedIn: "root" });
    return FileApiService;
}());
export { FileApiService };
