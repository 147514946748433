import * as tslib_1 from "tslib";
import { SaleDetail, SalesAmount, SalesCreateRequest, SalesLinkBtoBResult, SalesListResponse, } from '@agent-ds/shared/interfaces/sale';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import { UserTeamInjectorProvider } from './providers/user-team-injector.provider';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./providers/user-team-injector.provider";
var SALES_API_URL = '/api/sales';
var SalesApiService = /** @class */ (function (_super) {
    tslib_1.__extends(SalesApiService, _super);
    function SalesApiService(http, userMapper) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.userMapper = userMapper;
        return _this;
    }
    SalesApiService.prototype.getDetail = function (salesId) {
        return this.http.get(SALES_API_URL + "/" + salesId);
    };
    SalesApiService.prototype.getList = function (search) {
        var params = search ? toAsialRequestParameters(search) : undefined;
        var sales = this.http.get(SALES_API_URL + '/search', { params: (params ? params : {}) });
        return this.userMapper.getRequestAndProcessList(sales, 'saleses');
    };
    SalesApiService.prototype.getReport = function (query) {
        var params = query ? toAsialRequestParameters(query) : undefined;
        return this.http.get(SALES_API_URL + "/amount", { params: (params ? params : {}) });
    };
    SalesApiService.prototype.create = function (salesCreateRequest) {
        var _a;
        return this.http.post(SALES_API_URL, salesCreateRequest, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    SalesApiService.prototype.update = function (sale, status, cancelStatus) {
        var _a;
        return this.http.put(SALES_API_URL + "/" + sale.id, tslib_1.__assign({}, sale, { status: status, cancelStatus: cancelStatus }), { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    SalesApiService.prototype.linkBtoBSales = function (salesIds) {
        return this.http.put(SALES_API_URL + "/bulk/invoce/btob", { salesIds: salesIds });
    };
    SalesApiService.prototype.linkBtoBSale = function (saleId) {
        var _a;
        return this.http.put(SALES_API_URL + "/" + saleId + "/invoce/btob", { saleId: saleId }, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    SalesApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SalesApiService_Factory() { return new SalesApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserTeamInjectorProvider)); }, token: SalesApiService, providedIn: "root" });
    return SalesApiService;
}(ApiService));
export { SalesApiService };
