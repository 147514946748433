import * as tslib_1 from "tslib";
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { StudentBulkConfig } from './student-bulk-config';
var ProgressBulkConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressBulkConfig, _super);
    function ProgressBulkConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressBulkConfig.prototype.setParams = function (params) {
        var _this = this;
        if (this.sender) {
            this.sender.model.enterpriseIds = params.enterprises.map(function (e) {
                _this.sender.enterpriseInfoById[e.id] = e.frontId + " " + e.name;
                return e.id;
            });
            this.sender.model.enterpriseId = params.enterprises[0].id;
            this.sender.model.jobId = params.jobId;
            this.sender.model.jobs = params.jobs;
        }
        _super.prototype.setParams.call(this, params);
    };
    ProgressBulkConfig.prototype.update = function () {
        if (this.sender.model.jobs && this.sender.model.jobs[0]) {
            this.sender.model.cc = this.sender.model.jobs[0].jobUsers
                .filter(function (u) { return u.type === EnterpriseDepartmentUserType.アシ; })
                .map(function (u) { return u.userId; });
        }
        _super.prototype.update.call(this);
    };
    return ProgressBulkConfig;
}(StudentBulkConfig));
export { ProgressBulkConfig };
