<div
  class="search-bar"
  [class.search-bar--active]="focusedSearchBar"
  [class.search-bar--open]="showDropdown"
  agClickOutside
  (clickOutside)="onOutsideClick()"
  (click)="onSearchBarClick()"
  *ngIf="!isIntakeCa"
>
  <div class="input-container">
    <div class="category" [class.category--open]="showDropdown">
      <div
        class="category__item"
        [class.category__item--active]="
          selectedSearchCategory === SearchCategory.JobSeeker
        "
        (click)="onSearchCategoryClick(SearchCategory.JobSeeker)"
      >
        <div
          class="category__checkbox"
          [class.category__checkbox--active]="
            selectedSearchCategory === SearchCategory.JobSeeker
          "
        >
          <div
            *ngIf="selectedSearchCategory"
            class="category__checkbox__inner"
          ></div>
        </div>
        <span>求職者</span>
      </div>
      <div
        class="category__item"
        [class.category__item--active]="
          selectedSearchCategory === SearchCategory.Company
        "
        (click)="onSearchCategoryClick(SearchCategory.Company)"
      >
        <div
          class="category__checkbox"
          [class.category__checkbox--active]="
            selectedSearchCategory === SearchCategory.Company
          "
        >
          <div
            *ngIf="selectedSearchCategory"
            class="category__checkbox__inner"
          ></div>
        </div>
        <span>企業</span>
      </div>
      <div
        class="category__item"
        [class.category__item--active]="
          selectedSearchCategory === SearchCategory.JobOffer
        "
        (click)="onSearchCategoryClick(SearchCategory.JobOffer)"
      >
        <div
          class="category__checkbox"
          [class.category__checkbox--active]="
            selectedSearchCategory === SearchCategory.JobOffer
          "
        >
          <div
            *ngIf="selectedSearchCategory"
            class="category__checkbox__inner"
          ></div>
        </div>
        <span>求人</span>
      </div>
    </div>

    <input
      #searchInput
      type="text"
      class="input-container__input"
      [(ngModel)]="inputValue"
      (keyup.enter)="onSearch($event)"
      (keyup.esc)="onOutsideClick()"
    />

    <ng-container
      *ngIf="focusedSearchBar; then focusedIcon; else notFocusedIcon"
    ></ng-container>
    <ng-template #focusedIcon>
      <img
        (click)="onSearch()"
        *ngIf="!inputValue; else arrowIcon"
        class="input-container__icon"
        src="/assets/icons/icn_search_blue.svg"
        alt="search"
      />
      <ng-template #arrowIcon>
        <img
          (click)="onSearch()"
          class="input-container__icon"
          src="/assets/icons/icn_arrow_forward_blue.svg"
          alt="submit"
        />
      </ng-template>
    </ng-template>
    <ng-template #notFocusedIcon>
      <img
        (click)="onSearch()"
        *ngIf="!inputValue; else arrowIcon"
        class="input-container__icon"
        src="/assets/icons/icn_search_grey.svg"
        alt="search"
      />
      <ng-template #arrowIcon>
        <img
          (click)="onSearch()"
          class="input-container__icon"
          src="/assets/icons/icn_arrow_forward_grey.svg"
          alt="submit"
        />
      </ng-template>
    </ng-template>
  </div>

  <div *ngIf="showDropdown" class="dropdown">
    <div class="dropdown__content">
      <select
        #select
        name="{{ selectOptions.key || '' }}"
        *ngFor="
          let selectOptions of checkValuesByCategories[selectedSearchCategory]
        "
        class="dropdown__select select"
      >
        <option
          *ngFor="let option of selectOptions.options"
          [value]="option.value"
          >{{ option.label }}</option
        >
      </select>
    </div>
  </div>
</div>
