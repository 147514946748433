<ag-sales-detail-header [sale]="sale"></ag-sales-detail-header>

<div class="content">
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        #leftForm
        [metadata]="metadataLeft"
        [model]="model"
        (validated)="onValidityChange($event, 0)"
      ></ag-dynamic-form>
    </div>
    <div class="row__column">
      <ag-dynamic-form
        #rightForm
        [metadata]="metadataRight"
        [model]="model"
        (validated)="onValidityChange($event, 1)"
      ></ag-dynamic-form>
    </div>
  </div>
</div>

<div class="content">
  <div class="row">
    <div class="row__column">
      <ag-dynamic-form
        #bottomForm
        [metadata]="metadataBottomFields"
        [model]="model"
        (validated)="onValidityChange($event, 2)"
      ></ag-dynamic-form>
    </div>
  </div>
</div>

<div class="footer">
  <div class="footer__container">
    <div class="footer__container__left">
      <div class="footer__label">承認区分</div>
      <button
        class="footer__btn--small footer__btn--blue"
        *ngFor="let button of footerButtons"
        (click)="button.action(button.id)"
        [disabled]="!validity || updatePending"
      >
        {{ button.title }}
      </button>
    </div>
    <div class="footer__container__center">
      <button
        class="footer__btn footer__btn--blue"
        *ngIf="sale && sale.status !== 3"
        [disabled]="!validity || updatePending"
        (click)="updateSale()"
      >
        更新
      </button>
    </div>
    <div class="footer__container__right" *ngIf="sale && sale.status === 2">
      <span class="footer__container__right__memo">経理のみ使用可能→</span>
      <button
        class="footer__btn--small footer__btn--blue"
        (click)="openSaleLinkBtoBDialog()"
      >
        明細連携
      </button>
    </div>
    <div style="flex: 0.5;"></div>
  </div>
</div>

<ng-template #cancellationHeader>
  <div class="header-button--container">
    <button
      class="btn btn--small btn--blue btn--header"
      (click)="setStatusAndUpdate(2, 1)"
      *ngIf="sale && sale.cancelStatus !== 1"
      [disabled]="!validity || updatePending"
    >
      <span *ngIf="sale && sale.cancelStatus === 2">申請取消</span>
      <span *ngIf="sale && sale.cancelStatus === 3">承認取消</span>
    </button>
    <button
      class="btn btn--small btn--blue btn--header"
      (click)="setStatusAndUpdate(2, 3)"
      *ngIf="sale && sale.cancelStatus === 2"
      [disabled]="!validity || updatePending"
    >
      計上承認
    </button>
    <button
      class="btn btn--small btn--blue btn--header"
      (click)="setStatusAndUpdate(2, 2)"
      *ngIf="sale && sale.cancelStatus === 1"
      [disabled]="!validity || updatePending"
    >
      キャンセル申請
    </button>
  </div>
</ng-template>

<ng-template #reApplyPopup>
  <div class="popup">
    <span class="popup__note"
      >成約の再申請を行います。メールの追記文言を入力してください。</span
    >
    <div class="popup__row">
      <label class="popup__label">
        メールの追記文言
        <span class="popup__label--required">必須</span>
      </label>
      <textarea
        class="popup__textarea"
        [(ngModel)]="emailModel.email"
        (keydown.enter)="$event.stopPropagation()"
      ></textarea>
    </div>
  </div>
</ng-template>
