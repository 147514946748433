<div class="row">
  <div class="row__column">
    <ag-dynamic-form
      class="row__head-form"
      [metadata]="headMeta"
      [model]="student"
      (validated)="onValidityChange($event, 0)"
      *ngIf="headMeta"
    ></ag-dynamic-form>
  </div>
</div>

<div class="row">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="leftMeta"
      [model]="student?.dynamicData"
      (validated)="onValidityChange($event, 1)"
      *ngIf="leftMeta"
    ></ag-dynamic-form>
  </div>

  <div class="row__column">
    <ag-dynamic-form
      [metadata]="rightMeta"
      [model]="student"
      (validated)="onValidityChange($event, 2)"
      *ngIf="rightMeta"
    ></ag-dynamic-form>
  </div>
</div>

<div class="row row--padding-bottom-30">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="bottomMeta"
      [model]="student?.dynamicData"
      (validated)="onValidityChange($event, 3)"
      *ngIf="bottomMeta"
    ></ag-dynamic-form>
  </div>
</div>

<div *ngIf="customMeta" class="row row--custom-form">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="customMeta"
      [model]="student?.dynamicData"
      (validated)="onValidityChange($event, 4)"
      *ngIf="customMeta"
    ></ag-dynamic-form>
  </div>
</div>

<div class="footer" *ngIf="!readonly">
  <div class="footer__container">
    <button
      class="footer__btn footer__btn--blue"
      [disabled]="!validity"
      (click)="save()"
    >
      更新
    </button>
  </div>
</div>

<ng-template #contactHistoryForm>
  <div class="contact-form">
    <p class="contact-form__header">対応履歴を登録します。</p>
    <ag-dynamic-form
      [model]="contactHistoryFormModel"
      [metadata]="contactHistoryFormMetadata"
      class="contact-form__column contact-form__column"
      (validated)="validcontactHistoryForm = $event"
    ></ag-dynamic-form>
    <div class="contact-form__column contact-form__column">
      <div class="contact-form__form-group contact-form__form-group--textarea">
        <p class="contact-form__input-label">
          内容
        </p>
        <textarea
          class="contact-form__input contact-form__input--textarea"
          (keydown.enter)="$event.stopPropagation()"
          [(ngModel)]="contactHistoryFormModel.message"
        ></textarea>
      </div>
    </div>
  </div>
</ng-template>
