import * as tslib_1 from "tslib";
import { CompanyContractCreateDialogComponent } from '@agent-ds/company/components';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { APPROVAL_CANCEL_STATES, APPROVAL_STATES, GENDER_VALUE_BADGE_CLASSES, RAPACA_VALUE_BADGE_CLASSES, } from '@agent-ds/shared/constants';
import { ContactHistoryEnterpriseAction, EnterpriseDepartmentUserType, } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DialogService, DynamicFieldService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { of } from 'rxjs';
import { CompanyTabs } from '../company-tabs.enum';
var CompanyOverviewTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyOverviewTabComponent, _super);
    function CompanyOverviewTabComponent(masterApiService, userTeamProvider, datePipe, dynamicService, dialog, companyApiService) {
        var _this = _super.call(this) || this;
        _this.masterApiService = masterApiService;
        _this.userTeamProvider = userTeamProvider;
        _this.datePipe = datePipe;
        _this.dynamicService = dynamicService;
        _this.dialog = dialog;
        _this.companyApiService = companyApiService;
        _this.tabs = CompanyTabs;
        _this.selectedJobId = new EventEmitter();
        _this.APPROVAL_STATES = APPROVAL_STATES;
        _this.APPROVAL_CANCEL_STATES = APPROVAL_CANCEL_STATES;
        _this.RAPACA_VALUE_BADGE_CLASSES = RAPACA_VALUE_BADGE_CLASSES;
        _this.GENDER_VALUE_BADGE_CLASSES = GENDER_VALUE_BADGE_CLASSES;
        _this.contactAction = ContactHistoryEnterpriseAction;
        _this.jobDepartmentUserType = EnterpriseDepartmentUserType;
        return _this;
    }
    CompanyOverviewTabComponent.prototype.userTeamWithJobDepType = function (userId, type) {
        return this.jobDepartmentUserType[type] ? this.userTeamProvider.getUserTeamInfoById(userId) : of(null);
    };
    CompanyOverviewTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadataHead = {
                groups: [
                    {
                        class: 'oneliner',
                        rows: [
                            {
                                title: '企業ID',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'frontId',
                                    },
                                ],
                            },
                            {
                                title: '法人ステータス',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'dynamicData.enterpriseStatus',
                                    },
                                ],
                            },
                            {
                                title: '更新者',
                                class: 'fill',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'updateUserId',
                                        supplier: function () { return (_this.company ? _this.userTeamProvider.getUserTeamNameById(_this.company.updateUserId) : '-'); },
                                    },
                                ],
                            },
                            {
                                title: '登録日',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'createdAt',
                                        supplier: function (val) { return _this.datePipe.transform(val); },
                                    },
                                ],
                            },
                            {
                                title: '更新日',
                                fields: [
                                    {
                                        type: 'label',
                                        name: 'updatedAt',
                                        supplier: function (val) { return _this.datePipe.transform(val); },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };
            _this.capitalDef = _this.dynamicService.getDefinition('enterprise', 'capital');
        });
    };
    CompanyOverviewTabComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['company'] && this.company) {
            this.companyApiService.getDepartments(this.company.id).subscribe(function (response) {
                _this.departments = response.enterpriseDepartments;
            });
        }
    };
    CompanyOverviewTabComponent.prototype.openCompanyContractEditDialog = function (data) {
        var _this = this;
        this.dialog.open(CompanyContractCreateDialogComponent, {
            data: { contract: data, company: this.company, departmentList: this.departments },
        }, function (result) {
            if (!result) {
                return;
            }
            return _this.companyApiService.updateContract(_this.company.id, data.id, result);
        });
    };
    CompanyOverviewTabComponent.prototype.onStudentLinkClick = function (id) {
        StudentDetailPageComponent.instance.referenceId = id;
        StudentDetailPageComponent.instance.open();
    };
    CompanyOverviewTabComponent.prototype.onJobLinkClick = function (id) {
        JobDetailPageComponent.instance.referenceId = id;
        JobDetailPageComponent.instance.open();
    };
    return CompanyOverviewTabComponent;
}(Tab));
export { CompanyOverviewTabComponent };
