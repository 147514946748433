/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register-student-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "./register-student-dialog.component";
import * as i9 from "../../../shared/components/dialog/dialog-ref";
import * as i10 from "../../../shared/components/dialog/dialog-config";
import * as i11 from "../../../shared/services/api/dynamic-field.service";
import * as i12 from "../../../shared/services/api/progress-api.service";
import * as i13 from "../../../shared/services/api/job-api.service";
var styles_RegisterStudentDialogComponent = [i0.styles];
var RenderType_RegisterStudentDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterStudentDialogComponent, data: {} });
export { RenderType_RegisterStudentDialogComponent as RenderType_RegisterStudentDialogComponent };
function View_RegisterStudentDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\u4EF6\u51E6\u7406\u3057\u307E\u3057\u305F\u3002"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u203B\u300C\u9032\u6357\u3042\u308A\u300D\u3068\u8868\u793A\u3055\u308C\u3066\u3044\u305F\u9032\u6357\u306F\u3059\u3067\u306B\u9032\u6357\u304C\u767B\u9332\u6E08\u307F\u306A\u72B6\u614B\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u3001\u9032\u6357\u306E\u4E0A\u66F8\u304D\u767B\u9332\u3092\u304A\u3053\u306A\u3063\u3066\u304A\u308A\u307E\u305B\u3093\u3002 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.registerResult.successIds == null) ? null : _co.registerResult.successIds.length) ? _co.registerResult.successIds.length : 0); _ck(_v, 1, 0, currVal_0); }); }
function View_RegisterStudentDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "row row__table"]], [[2, "row--red", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "name name__big"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "row__column row__column--red"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.progressCheckResponse.indexOf(_v.context.$implicit.id) > (0 - 1)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.frontEnterpriseId; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.enterpriseName; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.frontId; _ck(_v, 9, 0, currVal_3); var currVal_4 = ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.position); _ck(_v, 12, 0, currVal_4); var currVal_5 = ((_co.progressCheckResponse.indexOf(_v.context.$implicit.id) > (0 - 1)) ? "\u9032\u6357\u6709" : ""); _ck(_v, 14, 0, currVal_5); }); }
function View_RegisterStudentDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["container", 1]], null, 16, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "instruct"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u4E00\u62EC\u3067\u9032\u6357\u3092\u767B\u9332\u3057\u307E\u3059\u3002 \u5BFE\u8C61\u306E\u6C42\u8077\u8005\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044 "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ag-dynamic-form", [["class", "row__head-form"]], null, [[null, "validated"], [null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = (_co.onValidityChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("changed" === en)) {
        var pd_1 = (_co.onValueChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(6, 442368, [["student_form", 4]], 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated", changed: "changed" }), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row row__table-header"]], [[4, "padding-right", null]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u4F01\u696DID, \u4F01\u696D\u540D "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6C42\u4EBAID, \u30DD\u30B8\u30B7\u30E7\u30F3\u540D "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u9032\u6357 "])), (_l()(), i1.ɵeld(14, 0, [["table", 1]], null, 2, "div", [["class", "table-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterStudentDialogComponent_3)), i1.ɵdid(16, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadata; var currVal_1 = _co.model; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = _co.config.data; _ck(_v, 16, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = ((i1.ɵnov(_v, 14).scrollHeight === i1.ɵnov(_v, 14).clientHeight) ? "15px" : "32px"); _ck(_v, 7, 0, currVal_2); }); }
function View_RegisterStudentDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.status === 1); _ck(_v, 0, 0, currVal_0); }); }
function View_RegisterStudentDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.register() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u767B\u9332 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isValid || (_co.status === 1)); _ck(_v, 0, 0, currVal_0); }); }
function View_RegisterStudentDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u9589\u3058\u308B "]))], null, null); }
function View_RegisterStudentDialogComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "job-table__info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.frontJobId + " ") + _v.context.$implicit.position); _ck(_v, 1, 0, currVal_0); }); }
function View_RegisterStudentDialogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "job-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "job-table__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "job-table__head"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u4EBAID\u3001\u30DD\u30B8\u30B7\u30E7\u30F3\u540D"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterStudentDialogComponent_8)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.jobErrorInfo; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "\u62C5\u5F53\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002\n\u6C42\u4EBA\u8A73\u7D30\u30DA\u30FC\u30B8\u3067\u3054\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002"; _ck(_v, 2, 0, currVal_0); }); }
export function View_RegisterStudentDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { jobErrorTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u9032\u6357\u767B\u9332"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "dialog__container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterStudentDialogComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterStudentDialogComponent_2)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterStudentDialogComponent_4)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterStudentDialogComponent_5)), i1.ɵdid(15, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterStudentDialogComponent_6)), i1.ɵdid(17, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["jobError", 2]], null, 0, null, View_RegisterStudentDialogComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.status === 2); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.status < 2); _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.status !== 2); _ck(_v, 13, 0, currVal_2); var currVal_3 = (_co.status !== 2); _ck(_v, 15, 0, currVal_3); var currVal_4 = (_co.status === 2); _ck(_v, 17, 0, currVal_4); }, null); }
export function View_RegisterStudentDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-register-student-dialog", [], null, null, null, View_RegisterStudentDialogComponent_0, RenderType_RegisterStudentDialogComponent)), i1.ɵdid(1, 4374528, null, 0, i8.RegisterStudentDialogComponent, [i9.DialogRef, i10.DialogConfig, i11.DynamicFieldService, i12.ProgressApiService, i13.JobApiService], null, null)], null, null); }
var RegisterStudentDialogComponentNgFactory = i1.ɵccf("ag-register-student-dialog", i8.RegisterStudentDialogComponent, View_RegisterStudentDialogComponent_Host_0, {}, {}, []);
export { RegisterStudentDialogComponentNgFactory as RegisterStudentDialogComponentNgFactory };
