<div class="section">
  <div class="title">
    取り込み比較実行
  </div>
</div>

<div *ngIf="basicMetaGroups && compareData" class="section">
  <div class="header">
    <div class="header__title">基本情報</div>
  </div>
  <table class="compare-table" style="width: 100%">
    <tr>
      <th
        class="compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--with-label"
      >
        <span class="label">求職者情報</span>
      </th>
      <th
        class="compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--arrow"
      ></th>
      <th class="compare-table__col-meta compare-table__col-meta--header">
        <span>取り込み情報</span>
      </th>
    </tr>
    <tr
      *ngFor="let metaGroup of basicMetaGroups | keyvalue: asIsOrder"
      class="compare-table__row compare-table__row--border-top"
    >
      <td class="compare-table__col-meta compare-table__col-meta--with-label">
        <ag-dynamic-form
          #basicDbForm
          *ngIf="metaGroup.value.dbMeta"
          [metadata]="metaGroup.value.dbMeta"
          [model]="compareData?.dbStudent?.dynamicData"
          (changed)="onModelChange(basicMetaGroups, $event)"
          (validated)="onValidityChange($event, 0)"
        ></ag-dynamic-form>
      </td>
      <td
        class="compare-table__col-meta compare-table__col-meta--arrow compare-table__col-meta--with-border"
      >
        <div
          *ngIf="
            metaGroup.key !== 'registrationRouteHistory' &&
            metaGroup.key !== 'registrationStatus' &&
            metaGroup.key !== 'contactNotes'
          "
          class="arrow-badge badge badge--dark"
          (click)="copyRow(basicMetaGroups, metaGroup.key)"
        >
          <div class="arrow-badge__arrow arrow-badge__arrow--left"></div>
        </div>
      </td>
      <td class="compare-table__col-meta">
        <ag-dynamic-form
          #basicCsvForm
          *ngIf="metaGroup.value.csvMeta"
          [metadata]="metaGroup.value.csvMeta"
          [model]="compareData?.csvStudent?.dynamicData"
          (changed)="onModelChange(basicMetaGroups, $event)"
          (validated)="onValidityChange($event, 1)"
        >
        </ag-dynamic-form>
      </td>
    </tr>
  </table>
</div>

<div *ngIf="differenceMetaGroups && compareData" class="section">
  <div class="header">
    <div class="header__title">差分情報</div>
  </div>
  <table class="compare-table">
    <tr>
      <th
        class="compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--with-label"
      >
        <span class="label">求職者情報</span>
      </th>
      <th
        class="compare-table__col-meta compare-table__col-meta--header compare-table__col-meta--arrow"
      ></th>
      <th class="compare-table__col-meta compare-table__col-meta--header">
        <span>取り込み情報</span>
      </th>
    </tr>
    <tr *ngFor="let metaGroup of differenceMetaGroups | keyvalue: asIsOrder">
      <td class="compare-table__col-meta compare-table__col-meta--with-label">
        <ag-dynamic-form
          #diffDbForm
          *ngIf="metaGroup.value.dbMeta"
          [metadata]="metaGroup.value.dbMeta"
          [model]="compareData?.dbStudent?.dynamicData"
          (changed)="onModelChange(differenceMetaGroups, $event)"
          (validated)="onValidityChange($event, 2)"
        ></ag-dynamic-form>
      </td>
      <td
        class="compare-table__col-meta compare-table__col-meta--arrow"
        [class.compare-table__col-meta--with-border]="
          toShowCopy(metaGroup.value.csvMeta)
        "
      >
        <div
          *ngIf="toShowCopy(metaGroup.value.csvMeta)"
          class="arrow-badge badge badge--dark"
          (click)="copyRow(differenceMetaGroups, metaGroup.key)"
        >
          <div class="arrow-badge__arrow arrow-badge__arrow--left"></div>
        </div>
      </td>
      <td class="compare-table__col-meta">
        <ag-dynamic-form
          #diffCsvForm
          *ngIf="metaGroup.value.csvMeta"
          [metadata]="metaGroup.value.csvMeta"
          [model]="compareData?.csvStudent?.dynamicData"
          (changed)="onModelChange(differenceMetaGroups, $event)"
          (validated)="onValidityChange($event, 3)"
        >
        </ag-dynamic-form>
      </td>
    </tr>
  </table>
</div>

<div class="footer">
  <div class="footer__container">
    <button
      class="footer__btn btn--larger btn btn--blue"
      [disabled]="!compareData || !validity"
      (click)="update()"
    >
      保存
    </button>
    <button
      class="footer__btn btn--larger btn btn--white btn--outline-red"
      [disabled]="!compareData"
      (click)="delete()"
    >
      削除
    </button>
  </div>
</div>
