<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">ファイルを確認する</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    「{{ config.data.filename }}」の「確認待ち」が解除されます。よろしいですか？
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--danger-accent"
      (click)="close('resubmit')"
    >
      再提出
    </button>
    <button
      class="dialog__button dialog__button--primary"
      (click)="close('confirm')"
    >
      OK
    </button>
  </div>
</div>
