import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DynamicFieldService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
var CompanyDetailTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyDetailTabComponent, _super);
    function CompanyDetailTabComponent(dynamicService, companyService, datePipe, userTeamProvider) {
        var _this = _super.call(this) || this;
        _this.dynamicService = dynamicService;
        _this.companyService = companyService;
        _this.datePipe = datePipe;
        _this.userTeamProvider = userTeamProvider;
        _this.validityArray = [true, true, true];
        _this.validity = false;
        return _this;
    }
    CompanyDetailTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.fieldInit();
        });
        this.validity = false;
    };
    CompanyDetailTabComponent.prototype.ngOnChanges = function (changes) { };
    CompanyDetailTabComponent.prototype.fieldInit = function () {
        var _this = this;
        this.metadataHead = {
            groups: [
                {
                    class: 'oneliner',
                    rows: [
                        {
                            title: '企業ID',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'frontId',
                                },
                            ],
                        }
                    ].concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'enterpriseStatus'), 'dynamicData'), [
                        {
                            title: '更新者',
                            class: 'fill',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'updateUserId',
                                    supplier: function () { return (_this.company ? _this.userTeamProvider.getUserTeamNameById(_this.company.updateUserId) : '-'); },
                                },
                            ],
                        },
                        {
                            title: '登録日',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'createdAt',
                                    supplier: function (val) { return _this.datePipe.transform(val); },
                                },
                            ],
                        },
                        {
                            title: '更新日',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'updatedAt',
                                    supplier: function (val) { return _this.datePipe.transform(val); },
                                },
                            ],
                        },
                    ]),
                },
            ],
        };
        this.metadataLeft = {
            groups: [
                {
                    title: '企業概要1',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'name'), null, 'fill').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'phoneticName'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'enterpriseNo')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyClassification'))),
                },
                {
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'clientManagement'), null, 'three-quarter').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'clientManagementRemarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'agClientStatus')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'rininKRank')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drClientStatus')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drAgreement')), [
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'hasDr'))[0];
                            var textOne = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'drRegisteredEmail'), null, 'full ou')[0];
                            var textTwo = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'recruitmentWindow'), null, 'full ou')[0];
                            textOne.fields[0].labelBefore = textOne.title;
                            textOne.fields[0].validators.required = true;
                            textTwo.fields[0].labelBefore = textTwo.title;
                            textOne.fields[0].allowOn = textTwo.fields[0].allowOn = { hasDr: '有' };
                            row.fields.push(textOne.fields[0], textTwo.fields[0]);
                            return row;
                        })()
                    ], this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drOfferAgency')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'plusUsage')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'drLandingRoute')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'fairContract')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'gpsContract')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'tokuha'))),
                },
                {
                    title: '企業概要2',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'industry'), null, 'full').concat([
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'capital'), null, 'third')[0];
                            row.fields.push(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'capitalVisibility'))[0].fields[0]);
                            return row;
                        })(),
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'salesAmount'), null, 'third')[0];
                            row.fields.push(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'salesVisibility'))[0].fields[0]);
                            row.fields.push(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'salesGroupAmount'))[0].fields[0]);
                            return row;
                        })()
                    ], this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'salesDate')), [
                        (function () {
                            var row = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'numberEmployees'), null, 'third')[0];
                            row.fields.push(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('enterprise', 'numberGroupEmployees'))[0].fields[0]);
                            return row;
                        })()
                    ], this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'establishDate')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'marketName')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'url'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'numberEmployeesNonConsolidated'), null, 'fill')),
                },
                {
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'presidentTitle'), null, 'fill').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'presidentName'), null, 'fill')),
                },
                {
                    title: '本社所在地',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'branches'), null, 'fill').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'address'))),
                },
            ],
        };
        this.metadataRight = {
            groups: [
                {
                    title: '担当',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows({
                        fieldName: 'userIds',
                        fieldType: 'multi-user',
                        label: '担当者',
                        validationStyle: { required: true, max: 5 },
                    }).slice(),
                },
                {
                    title: 'その他',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'recommendDescription'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'remarks'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'genderInfo'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'resumePassword'), 'dynamicData')),
                },
                {
                    title: '企業概要3',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyInfo'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'companyStrength'), 'dynamicData')),
                },
                {
                    title: '8SEGs',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8LastYear'), 'dynamicData', 'full').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8CurrentYear'), 'dynamicData', 'full'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8NextYear'), 'dynamicData', 'full'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('enterprise', 'segs8Confirm'), 'dynamicData')),
                },
            ],
        };
        var customs = this.dynamicService.getDefinitions('enterprise').filter(function (def) { return def.isCustomField; });
        if (customs.length) {
            this.metadataCustomFields = {
                groups: [
                    {
                        title: 'カスタムフィールド',
                        class: 'form__group--red-title-border',
                        rows: customs.map(function (c) { return _this.dynamicService.getFormRows(c); }).flatten(),
                    },
                ],
            };
        }
    };
    CompanyDetailTabComponent.prototype.onValidityChange = function (validity, index) {
        this.validityArray[index] = validity;
        this.validity = this.validityArray.find(function (v) { return !v; }) == null;
    };
    CompanyDetailTabComponent.prototype.save = function () {
        var _this = this;
        this.companyService.update(this.company.id, this.company).subscribe(function (res) { return _this.companyService.refreshEvent.emit(); });
    };
    return CompanyDetailTabComponent;
}(Tab));
export { CompanyDetailTabComponent };
