import * as tslib_1 from "tslib";
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupType } from '../config';
import { StudentJobConfig } from './student-job-config';
var ProgressRecommendConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressRecommendConfig, _super);
    function ProgressRecommendConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressRecommendConfig.prototype.getTemplateId = function () {
        return 6;
    };
    ProgressRecommendConfig.prototype.getTemplateTypeId = function () {
        return [4];
    };
    ProgressRecommendConfig.prototype.getPopupConfig = function () {
        return [
            { type: MailPopupType.JOB_CHECK, customFilter: { jobUserType: EnterpriseDepartmentUserType.RA }, title: '推薦依頼送信' },
            { type: MailPopupType.STUDENT, bodyArea: true, stepBack: 2, title: '推薦依頼送信', message: ' ' },
        ];
    };
    ProgressRecommendConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.progressId = params.progressId;
            this.sender.model.progressType = params.progressType;
            this.sender.model.progressN = params.progressN;
            this.sender.model.progressSeminarType = params.progressSeminarType;
            this.sender.model.progressSeminarAt = params.progressSeminarAt;
            this.sender.model.progressSeminarAtMailSendFlag = params.progressSeminarAtMailSendFlag;
            this.sender.model.progressIsStudentDelay = params.progressIsStudentDelay;
            this.sender.model.progressHasInterview = params.progressHasInterview;
            this.sender.model.progressStatus = params.progressStatus;
        }
        _super.prototype.setParams.call(this, params);
    };
    ProgressRecommendConfig.prototype.sendAfterFlow = function () {
        return true;
    };
    ProgressRecommendConfig.prototype.initForm = function () {
        return;
    };
    ProgressRecommendConfig.prototype.update = function () {
        return;
    };
    ProgressRecommendConfig.prototype.save = function (toSave) {
        var _this = this;
        toSave.jobIds = this.sender.model.jobs.map(function (j) { return j.id; });
        toSave.studentId = this.sender.model.students[0].id;
        this.sender.userService.getAll().subscribe(function (users) {
            var raUsers = _this.sender.model.jobs
                .map(function (j) {
                return j.jobUsers
                    .filter(function (u) { return u.type === EnterpriseDepartmentUserType.RA; })
                    .map(function (u) { return (users.find(function (user) { return user.id === u.userId; }) || { email: null }).email; })
                    .filter(function (m) { return m; });
            })
                .flatten();
            var paUsers = _this.sender.model.jobs
                .map(function (j) {
                return j.jobUsers
                    .filter(function (u) { return u.type === EnterpriseDepartmentUserType.PA; })
                    .map(function (u) { return (users.find(function (user) { return user.id === u.userId; }) || { email: null }).email; })
                    .filter(function (m) { return m; });
            })
                .flatten();
            var asUsers = _this.sender.model.jobs
                .map(function (j) {
                return j.jobUsers
                    .filter(function (u) { return u.type === EnterpriseDepartmentUserType.アシ; })
                    .map(function (u) { return (users.find(function (user) { return user.id === u.userId; }) || { email: null }).email; })
                    .filter(function (m) { return m; });
            })
                .flatten();
            toSave.to = raUsers.length ? raUsers : paUsers;
            toSave.cc = asUsers;
            if (_this.sender.model.progressType === 1) {
                toSave.cc.push(_this.sender.authService.loginUser.email);
            }
            if (_this.sender.model.progressType == null) {
                _this.sender.progressApiService.checkProgress(_this.sender.model.students.map(function (s) { return s.id; }), toSave.jobIds).subscribe(function (res) {
                    if (res && res.length) {
                        _this.sender.progressErrorInfo = res;
                        PopupControlComponent.instance.open({
                            cancelText: '中止',
                            cancelCallback: function () { return _this.sender.close(); },
                            confirmText: null,
                            content: _this.sender.progressErrorTemplate,
                            title: '進捗重複',
                            confirmCallback: null,
                            multiConfirm: [
                                {
                                    confirmText: '重複案件は上書きしないで送信のみ行う',
                                    confirmCallback: function () {
                                        return _this.sender.mailApiService.bulkRecommend(toSave).pipe(tap(function (result) { return _this.sender.afterSend(result); }));
                                    },
                                },
                                {
                                    confirmText: '重複案件は上書き',
                                    confirmCallback: function () {
                                        return forkJoin(_this.sender.model.students.map(function (s) { return _this.sender.progressApiService.bulkRecommendStudent(s.id, toSave.jobIds); })).pipe(mergeMap(function () { return _this.sender.mailApiService.bulkRecommend(toSave).pipe(tap(function (result) { return _this.sender.afterSend(result); })); }));
                                    },
                                },
                                {
                                    confirmText: '重複案件を除いて送信',
                                    confirmEnabled: function () {
                                        // disable when all of selected have '進捗重複' errors
                                        return !(_this.sender.progressErrorInfo.length > 0 && _this.sender.progressErrorInfo.length === toSave.jobIds.length);
                                    },
                                    confirmCallback: function () {
                                        var studentsWithJobIds = {};
                                        _this.sender.model.students.forEach(function (student) {
                                            return (studentsWithJobIds[student.id] = toSave.jobIds.filter(function (jobId) {
                                                return !_this.sender.progressErrorInfo.some(function (error) { return error.studentId === student.id && error.jobId === jobId; });
                                            }));
                                        });
                                        return forkJoin(_this.sender.model.students
                                            .filter(function (student) { return studentsWithJobIds[student.id] && studentsWithJobIds[student.id].length; })
                                            .map(function (student) { return _this.sender.progressApiService.bulkRecommendStudent(student.id, studentsWithJobIds[student.id]); })).pipe(mergeMap(function () {
                                            return _this.sender.mailApiService
                                                .bulkRecommend(tslib_1.__assign({}, toSave, { jobIds: studentsWithJobIds[toSave.studentId] }))
                                                .pipe(tap(function (result) { return _this.sender.afterSend(result); }));
                                        }));
                                    },
                                    confirmClass: 'btn--green',
                                },
                            ],
                        });
                    }
                    else {
                        forkJoin(_this.sender.model.students.map(function (s) { return _this.sender.progressApiService.bulkRecommendStudent(s.id, toSave.jobIds); })).subscribe(function () {
                            _this.sender.mailApiService.bulkRecommend(toSave).subscribe(function (result) { return _this.sender.afterSend(result); });
                        });
                    }
                });
            }
            else {
                _this.sender.progressApiService
                    .updateWithAction(_this.sender.model.progressId, {
                    action: _this.sender.model.progressStatus === 20 ? 23 : 34,
                    type: _this.sender.model.progressType,
                    hasInterview: _this.sender.model.progressHasInterview,
                    n: _this.sender.model.progressN,
                    seminarType: _this.sender.model.progressSeminarType,
                    seminarAt: _this.sender.model.progressSeminarAt,
                    seminarAtMailSendFlag: _this.sender.model.progressSeminarAtMailSendFlag,
                    isStudentDelay: _this.sender.model.progressIsStudentDelay,
                })
                    .pipe(mergeMap(function () { return _this.sender.mailApiService.bulkRecommend(toSave); }))
                    .subscribe(function (result) { return _this.sender.afterSend(result); });
            }
        });
    };
    return ProgressRecommendConfig;
}(StudentJobConfig));
export { ProgressRecommendConfig };
