import { CACHE } from '@agent-ds/shared/util/cache';
import { HttpClient } from '@angular/common/http';
import { of, ReplaySubject } from 'rxjs';
import { first, shareReplay, tap } from 'rxjs/operators';
import { AuthService, REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth.service";
var MasterApiService = /** @class */ (function () {
    function MasterApiService(http, authService) {
        var _this = this;
        this.http = http;
        this.authService = authService;
        this.languages = new ReplaySubject(1);
        this.flattenedIndustryTypes = new ReplaySubject(1);
        this.flattenedJobTypes = new ReplaySubject(1);
        this.authService.isLogined().subscribe(function (logined) {
            if (logined) {
                _this.branches = _this.certificates = _this.highSchools = null;
                _this.industryTypes = _this.jobTypes = _this.languageCerts = _this.schools = _this.skills = _this.teams = null;
            }
        });
    }
    MasterApiService.prototype.getBranches = function () {
        if (!this.branches) {
            this.branches = this.http.get('/api/master/branches').pipe(shareReplay(1));
        }
        return this.branches.pipe(first());
    };
    MasterApiService.prototype.getCertificates = function () {
        if (!this.certificates) {
            this.certificates = this.http.get('/api/master/certificates').pipe(shareReplay(1));
        }
        return this.certificates.pipe(first());
    };
    MasterApiService.prototype.getHighSchools = function () {
        if (!this.highSchools) {
            this.highSchools = this.http.get('/api/master/high-schools').pipe(shareReplay(1));
        }
        return this.highSchools.pipe(first());
    };
    MasterApiService.prototype.getIndustryTypes = function () {
        var _this = this;
        if (!this.industryTypes) {
            this.industryTypes = this.http.get('/api/master/industry-types/relations').pipe(tap(function (res) { return _this.flattenedIndustryTypes.next(res.flatten(function (subType, level) { return (subType['_selectLevel'] = level); }, 'industryTypes')); }), shareReplay(1));
        }
        return this.industryTypes.pipe(first());
    };
    MasterApiService.prototype.getFlattenedIndustryTypes = function () {
        return this.flattenedIndustryTypes.pipe(first());
    };
    MasterApiService.prototype.getJobTypes = function () {
        var _this = this;
        if (!this.jobTypes) {
            this.jobTypes = this.http.get('/api/master/job-types/relations').pipe(tap(function (res) { return _this.flattenedJobTypes.next(res.flatten(function (subType, level) { return (subType['_selectLevel'] = level); }, 'jobTypes')); }), shareReplay(1));
        }
        return this.jobTypes.pipe(first());
    };
    MasterApiService.prototype.getFlattenedJobTypes = function () {
        return this.flattenedJobTypes.pipe(first());
    };
    MasterApiService.prototype.getLanguageCerts = function () {
        var _this = this;
        if (!this.languageCerts) {
            this.languageCerts = this.http.get('/api/master/language-certificates').pipe(shareReplay(1));
            this.languageCerts.subscribe(function (res) { return _this.languages.next(res.map(function (cert) { return cert.language; }).removeSame('id')); }, function () { return _this.languages.next([]); });
        }
        return this.languageCerts.pipe(first());
    };
    MasterApiService.prototype.getLanguages = function () {
        if (!this.languageCerts) {
            this.getLanguageCerts();
        }
        return this.languages.pipe(first());
    };
    MasterApiService.prototype.searchStations = function (keyword) {
        return this.http.get('/api/master/schools/relations?keyword=' + keyword);
    };
    MasterApiService.prototype.getSchools = function () {
        if (!this.schools) {
            this.schools = this.http.get('/api/master/schools/relations').pipe(shareReplay(1));
        }
        return this.schools.pipe(first());
    };
    MasterApiService.prototype.suggestSchools = function (keyword) {
        return keyword ? this.http.get('/api/master/schools/suggest', { params: { keyword: keyword } }) : of([]);
    };
    MasterApiService.prototype.getSkills = function () {
        if (!this.skills) {
            this.skills = this.http.get('/api/master/skills/relations').pipe(shareReplay(1));
        }
        return this.skills.pipe(first());
    };
    MasterApiService.prototype.getTeams = function () {
        if (!this.teams) {
            this.teams = this.http.get('/api/master/teams').pipe(shareReplay(1));
        }
        return this.teams.pipe(first());
    };
    MasterApiService.prototype.addTeam = function (team) {
        var _this = this;
        return this.http.post('/api/master/teams', team).pipe(tap(function () { return (_this.teams = null); }));
    };
    MasterApiService.prototype.updateTeam = function (id, team) {
        var _this = this;
        return this.http.put("/api/master/teams/" + id, team).pipe(tap(function () { return (_this.teams = null); }));
    };
    MasterApiService.prototype.getAddressByZip = function (zip) {
        if (zip) {
            CACHE.set('/api/address/search/zip', null, 300000);
            return this.http.get('/api/address/search/zip', { params: { zipCode: zip } });
        }
        else {
            return of({ results: [] });
        }
    };
    MasterApiService.prototype.getSystemConfigs = function () {
        return this.http.get('/api/master/system-config');
    };
    MasterApiService.prototype.updateSystemConfig = function (id, requestObj) {
        var _a;
        return this.http.put("/api/master/system-config/" + id, requestObj, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    MasterApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MasterApiService_Factory() { return new MasterApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: MasterApiService, providedIn: "root" });
    return MasterApiService;
}());
export { MasterApiService };
