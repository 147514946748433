import * as tslib_1 from "tslib";
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ExtendedProgressStatusCode, ProgressStatusCode } from '@agent-ds/shared/enums';
import { DialogService, DynamicFieldService, JobApiService, ProgressApiService } from '@agent-ds/shared/services';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
var ProgressCreateDialogComponent = /** @class */ (function () {
    function ProgressCreateDialogComponent(dialog, config, formBuilder, jobApiService, progressApiService, dialogService, dynamicFieldService) {
        this.dialog = dialog;
        this.config = config;
        this.formBuilder = formBuilder;
        this.jobApiService = jobApiService;
        this.progressApiService = progressApiService;
        this.dialogService = dialogService;
        this.dynamicFieldService = dynamicFieldService;
        this.inClose = false;
        this.model = {};
        this.isModelValid = false;
        this.progressErrorInfo = [];
        this.jobErrorInfo = [];
        this.progressStatus = ProgressStatusCode;
        this.extendedProgressStatus = ExtendedProgressStatusCode;
    }
    ProgressCreateDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return _this.create(); });
        this.form = this.formBuilder.group({
            type: [null, Validators.required],
            hasInterview: false,
        });
        this.model.studentId = this.config.data.studentId;
        this.model.jobId = this.config.data.jobId;
        this.model.registeredAt = new Date();
        if (this.config.data.matchingMode) {
            if (Array.isArray(this.config.data.studentList)) {
                this.students = this.config.data.studentList
                    .filter(function (s) { return s; })
                    .map(function (st) {
                    return [st.frontId, st.dynamicData ? st.dynamicData.lastName : null, st.dynamicData ? st.dynamicData.firstName : null]
                        .filter(function (stData) { return stData; })
                        .join(' ');
                })
                    .join('、');
            }
            if (Array.isArray(this.config.data.jobList)) {
                this.jobs = this.config.data.jobList
                    .filter(function (j) { return j; })
                    .map(function (job) {
                    return [job.frontJobId, job.dynamicData ? job.dynamicData.position : null, job.enterpriseName].filter(function (jbData) { return jbData; }).join(' ');
                })
                    .join('、');
            }
        }
        var getRowsWithoutRequiredLabel = function (dynamicField, className) {
            var rows = _this.dynamicFieldService.getFormRows(dynamicField, undefined, className);
            rows.forEach(function (row) { return (row.showRequired = false); });
            return rows;
        };
        if (this.config.data.matchingMode) {
            this.meta = {
                groups: [
                    {
                        class: 'no-border title-w80 row-gap-20 no-background no-padding',
                        rows: getRowsWithoutRequiredLabel({
                            fieldName: 'registeredAt',
                            fieldType: 'date',
                            displayType: 'date+time',
                            label: '登録日',
                            validationStyle: { required: true },
                        }).slice(),
                    },
                ],
            };
        }
        else {
            this.meta = {
                groups: [
                    {
                        class: 'no-border title-w80 row-gap-20 no-background no-padding',
                        rows: getRowsWithoutRequiredLabel({ fieldType: 'student', fieldName: 'studentId', label: '求職者', validationStyle: { required: true } }, 'three-quarter').concat([
                            (function () {
                                var row = getRowsWithoutRequiredLabel({ fieldType: 'job', fieldName: 'jobId', label: '求人', validationStyle: { required: true } }, 'three-quarter')[0];
                                row.fields[0].filters = [
                                    {
                                        name: 'status',
                                        hidden: true,
                                        options: [['募集中', '推薦停止']],
                                        supplier: function () { return 0; },
                                    },
                                ];
                                if (_this.config.data.enterpriseId) {
                                    row.fields[0].filters.push({
                                        name: 'enterpriseIds',
                                        hidden: true,
                                        options: [[_this.config.data.enterpriseId]],
                                        supplier: function () { return 0; },
                                    });
                                }
                                row.fields[0].placeholder = '求人ID、求人名、企業名';
                                return row;
                            })()
                        ], getRowsWithoutRequiredLabel({
                            fieldName: 'registeredAt',
                            fieldType: 'date',
                            displayType: 'date+time',
                            label: '登録日',
                            validationStyle: { required: true },
                        })),
                    },
                ],
            };
        }
    };
    ProgressCreateDialogComponent.prototype.ngOnDestroy = function () {
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    ProgressCreateDialogComponent.prototype.create = function () {
        var _this = this;
        if (this.form.invalid || !this.isModelValid || this.inClose || this.dialog.inClose) {
            return;
        }
        var studentIds = this.config.data.matchingMode ? this.config.data.studentList.map(function (student) { return student.id; }) : [this.model.studentId];
        var jobIds = this.config.data.matchingMode ? this.config.data.jobList.map(function (job) { return job.id; }) : [this.model.jobId];
        this.inClose = true;
        this.progressApiService.checkProgress(studentIds, jobIds).subscribe(function (res) {
            if (res && res.length) {
                _this.progressErrorInfo = res;
                if (!_this.config.data.matchingMode) {
                    PopupControlComponent.instance.open({
                        cancelText: '中止',
                        cancelCallback: function () { return _this.close(); },
                        confirmText: '上書き',
                        content: _this.progressErrorTemplate,
                        title: '進捗重複',
                        confirmCallback: function () {
                            return forkJoin(_this.progressErrorInfo.map(function (error) {
                                return _this.progressApiService.overwrite(error.progressId, {
                                    type: _this.form.value.type,
                                    hasInterview: _this.form.value.hasInterview ? 1 : 0,
                                    registeredAt: _this.model.registeredAt,
                                });
                            })).pipe(tap(function () {
                                _this.progressApiService.refreshEvent.emit();
                                _this.close();
                            }));
                        },
                    });
                }
                else {
                    var newProgressesRequests_1 = studentIds
                        .map(function (studentId) {
                        return jobIds
                            .filter(function (jobId) { return !_this.progressErrorInfo.some(function (error) { return error.studentId === studentId && error.jobId === jobId; }); })
                            .map(function (jobId) {
                            return _this.progressApiService.addProgress({
                                registeredAt: _this.model.registeredAt,
                                type: _this.form.value.type,
                                hasInterview: _this.form.value.hasInterview ? 1 : 0,
                                studentId: studentId,
                                jobId: jobId,
                            });
                        });
                    })
                        .flatten();
                    PopupControlComponent.instance.open({
                        cancelText: '中止',
                        cancelCallback: function () { return _this.close(); },
                        confirmText: null,
                        content: _this.progressErrorTemplate,
                        title: '進捗重複',
                        confirmCallback: null,
                        multiConfirm: [
                            {
                                confirmText: '重複を上書きして登録',
                                confirmCallback: function () {
                                    var overwrites = _this.progressErrorInfo.map(function (error) {
                                        return _this.progressApiService.overwrite(error.progressId, {
                                            type: _this.form.value.type,
                                            hasInterview: _this.form.value.hasInterview ? 1 : 0,
                                            registeredAt: _this.model.registeredAt,
                                        });
                                    });
                                    return forkJoin(overwrites.concat(newProgressesRequests_1)).pipe(tap(function () {
                                        _this.progressApiService.refreshEvent.emit();
                                        _this.close();
                                    }));
                                },
                            },
                            {
                                confirmText: '重複を除いて登録',
                                // disable when all of selected have '進捗重複' errors
                                // length of jobIds or studentIds must be 1 if the another has over 1 length
                                confirmEnabled: function () { return !(_this.progressErrorInfo.length === jobIds.length + studentIds.length - 1); },
                                confirmCallback: function () {
                                    return forkJoin(newProgressesRequests_1).pipe(tap(function () {
                                        _this.progressApiService.refreshEvent.emit();
                                        _this.close();
                                    }));
                                },
                                confirmClass: 'btn--green',
                            },
                        ],
                    });
                }
            }
            else {
                _this.save(studentIds, jobIds);
            }
        });
    };
    ProgressCreateDialogComponent.prototype.save = function (studentIds, jobIds) {
        var _this = this;
        this.jobApiService.ckeckJobUsers(jobIds).subscribe(function (jobUsers) {
            if (jobUsers && jobUsers.length) {
                _this.jobErrorInfo = [];
                jobUsers.forEach(function (r) {
                    if (!r.jobUsers.length) {
                        _this.jobErrorInfo.push(r);
                    }
                });
                if (_this.jobErrorInfo.length) {
                    PopupControlComponent.subInstance.open({
                        title: '必要な情報が入力されていません',
                        confirmText: '閉じる',
                        content: _this.jobErrorTemplate,
                        confirmCallback: function () { return false; },
                    });
                    return;
                }
                else {
                    var requestObject_1 = {
                        registeredAt: _this.model.registeredAt,
                        type: _this.form.value.type,
                        hasInterview: _this.form.value.hasInterview ? 1 : 0,
                    };
                    var request = void 0;
                    if (studentIds.length === 1 && jobIds.length > 1) {
                        request = _this.progressApiService.bulkCreateStudent(studentIds[0], jobIds, requestObject_1);
                    }
                    else if (studentIds.length > 1 && jobIds.length === 1) {
                        request = _this.progressApiService.bulkCreateJob(jobIds[0], studentIds, requestObject_1);
                    }
                    else {
                        request = forkJoin(studentIds
                            .map(function (studentId) {
                            return jobIds.map(function (jobId) {
                                return _this.progressApiService.addProgress(tslib_1.__assign({}, requestObject_1, { studentId: studentId, jobId: jobId }));
                            });
                        })
                            .flatten());
                    }
                    request.subscribe(function (res) {
                        _this.progressApiService.refreshEvent.emit();
                        _this.inClose = false;
                        _this.close(res);
                    }, function () { return (_this.inClose = false); });
                }
            }
            else {
                _this.inClose = false;
                var config = {
                    title: '必要な情報が入力されていません',
                    messages: ['担当が設定されていません。'],
                    style: {
                        height: '245px',
                        width: '510px',
                    },
                    buttons: {
                        hideNo: true,
                        no: '',
                        yes: '閉じる',
                    },
                };
                _this.dialogService.open(ConfirmDialogComponent, config);
            }
        }, function () { return (_this.inClose = false); });
    };
    ProgressCreateDialogComponent.prototype.close = function (param) {
        this.dialog.close(param);
    };
    return ProgressCreateDialogComponent;
}());
export { ProgressCreateDialogComponent };
