<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">セミナー予約</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__container">
    <div class="content" #container>
      <ag-dynamic-form
        #reg_form
        class="form"
        [metadata]="metadata"
        [model]="model"
        (validated)="onValidityChange($event)"
      ></ag-dynamic-form>
      <div class="content__buttons">
        <button
          class="content__buttons__button btn btn--green btn--large"
          [disabled]="!isValid || isSearchInProgress"
          (click)="search()"
        >
          検索
        </button>
      </div>
      <div
        class="row row__table-header"
        [style.padding-right]="
          table.scrollHeight === table.clientHeight ? '15px' : '32px'
        "
      >
        <div class="row__column row__column--header-first">
          開催日
        </div>
        <div class="row__column">
          開催時間
        </div>
        <div class="row__column">
          セミナー属性
        </div>
        <div class="row__column">
          セミナーエリア
        </div>
      </div>
      <div class="table-content" #table>
        <div class="row row__table" *ngFor="let seminar of seminars">
          <div class="row__column">
            <input
              type="radio"
              name="selectedSeminar"
              [value]="seminar.id"
              [(ngModel)]="selectedSeminar"
            />
          </div>
          <div class="row__column">
            {{ seminar.seminarAt | date: "yyyy/MM/dd (E)" }}
          </div>
          <div class="row__column">
            {{ seminar.seminarAt | date: "HH:mm〜" }}
          </div>
          <div class="row__column">{{ seminar.type }}</div>
          <div class="row__column">{{ AREAS[seminar.area] }}</div>
        </div>
        <div
          class="table-content table-content--message"
          *ngIf="!seminars?.length"
        >
          検索結果はありません。<br />
          検索条件を指定して「検索」ボタンを押してください。
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="selectedSeminar == null"
      (click)="reserve()"
    >
      登録
    </button>
  </div>
</div>
