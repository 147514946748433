/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./favorite-job-list-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/directives/class.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/pipes/safe-date.pipe";
import * as i5 from "../../../shared/components/page-scroll-table/page-scroll-table.component.ngfactory";
import * as i6 from "../../../shared/components/page-scroll-table/page-scroll-table.component";
import * as i7 from "./favorite-job-list-page.component";
import * as i8 from "../../../shared/services/api/master-api.service";
import * as i9 from "../../../shared/services/api/job-api.service";
import * as i10 from "../../../shared/services/dialog.service";
var styles_FavoriteJobListPageComponent = [i0.styles];
var RenderType_FavoriteJobListPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FavoriteJobListPageComponent, data: {} });
export { RenderType_FavoriteJobListPageComponent as RenderType_FavoriteJobListPageComponent };
function View_FavoriteJobListPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "heat__deadline"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" \u63A8\u85A6\u671F\u9650:\u00A0", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), ((_v.parent.context.$implicit.dynamicData == null) ? null : _v.parent.context.$implicit.dynamicData.recommendationDate), "MM/dd")); _ck(_v, 1, 0, currVal_0); }); }
function View_FavoriteJobListPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "heat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "heat__badge badge"]], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(3, 540672, null, 0, i2.ClassDirective, [], { clazz: [0, "clazz"], value: [1, "value"], classOptions: [2, "classOptions"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavoriteJobListPageComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "heat__badge badge"; var currVal_2 = ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.recommendationDateKind); var currVal_3 = _co.recommendationHeatClasses; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = (((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.recommendationDateKind) && ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.recommendationDate)); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).clazz; _ck(_v, 2, 0, currVal_0); var currVal_4 = ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.recommendationDateKind); _ck(_v, 4, 0, currVal_4); }); }
function View_FavoriteJobListPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "description__seg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "description__seg--ml10 badge"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(4, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "description__seg--ml10 badge"; var currVal_1 = _co.classMap[_v.parent.context.$implicit.companyClassification]; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.companyClassification; _ck(_v, 4, 0, currVal_2); }); }
function View_FavoriteJobListPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "description__label description__label--big"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "description__link description__link--big description__link--bold description__link--no-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavoriteJobListPageComponent_4)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.companyClassification; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.frontEnterpriseId; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.enterpriseName; _ck(_v, 4, 0, currVal_1); }); }
function View_FavoriteJobListPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "description__label description__label--small"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "description__link description__link--small description__link--bold description__link--no-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.frontId; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.dynamicData == null) ? null : _v.context.$implicit.dynamicData.position); _ck(_v, 4, 0, currVal_1); }); }
export function View_FavoriteJobListPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeDatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { table: 0 }), i1.ɵqud(671088640, 2, { companyTemplate: 0 }), i1.ɵqud(671088640, 3, { positionTemplate: 0 }), i1.ɵqud(671088640, 4, { heatTemplate: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u4EBA\u304A\u6C17\u306B\u5165\u308A"])), (_l()(), i1.ɵeld(8, 0, null, null, 18, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 15, "div", [["class", "content__controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "input", [["class", "content__controls__select-all"], ["type", "checkbox"]], [[8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.table == null) ? null : _co.table.checkAll(_co.table.allChecked)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "content__controls__count"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "content__controls__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "content__controls__control"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getJobsAsPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6C42\u4EBA\u7968 "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "content__controls__control"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMail(_co.postingConfig) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6C42\u4EBA\u7968\u9001\u4FE1 "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "content__controls__control"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRegisterStudents() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u9032\u6357\u767B\u9332 "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "content__controls__control"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveFromFavorites() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u304A\u6C17\u306B\u5165\u308A\u304B\u3089\u5916\u3059 "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "content__controls__control"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMail(_co.recommendConfig) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u4E00\u62EC\u63A8\u85A6 "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "ag-page-scroll-table", [["class", "content__table"]], null, [[null, "itemSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemSelected" === en)) {
        var pd_0 = (_co.onItemSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_PageScrollTableComponent_0, i5.RenderType_PageScrollTableComponent)), i1.ɵdid(26, 638976, [[1, 4]], 0, i6.PageScrollTableComponent, [i4.SafeDatePipe], { content: [0, "content"], tableConfig: [1, "tableConfig"] }, { itemSelected: "itemSelected" }), (_l()(), i1.ɵand(0, [[4, 2], ["heat", 2]], null, 0, null, View_FavoriteJobListPageComponent_1)), (_l()(), i1.ɵand(0, [[2, 2], ["company", 2]], null, 0, null, View_FavoriteJobListPageComponent_3)), (_l()(), i1.ɵand(0, [[3, 2], ["position", 2]], null, 0, null, View_FavoriteJobListPageComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.jobs; var currVal_9 = _co.tableConfig; _ck(_v, 26, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.table == null) ? null : _co.table.allChecked); _ck(_v, 10, 0, currVal_0); var currVal_1 = (((_co.table == null) ? null : _co.table.checkedItems.length) || ((_co.table == null) ? null : _co.table.totalSize)); _ck(_v, 12, 0, currVal_1); var currVal_2 = (((_co.table == null) ? null : _co.table.checkedItems.length) ? "\u4EF6\u9078\u629E\u4E2D" : "\u4EF6"); _ck(_v, 14, 0, currVal_2); var currVal_3 = ((((_co.table == null) ? null : _co.table.checkedItems.length) > 20) || !((_co.table == null) ? null : _co.table.checkedItems.length)); _ck(_v, 15, 0, currVal_3); var currVal_4 = !((_co.table == null) ? null : _co.table.checkedItems.length); _ck(_v, 17, 0, currVal_4); var currVal_5 = !((_co.table == null) ? null : _co.table.checkedItems.length); _ck(_v, 19, 0, currVal_5); var currVal_6 = !((_co.table == null) ? null : _co.table.checkedItems.length); _ck(_v, 21, 0, currVal_6); var currVal_7 = !((_co.table == null) ? null : _co.table.checkedItems.length); _ck(_v, 23, 0, currVal_7); }); }
export function View_FavoriteJobListPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-favorite-job-list-page", [], null, null, null, View_FavoriteJobListPageComponent_0, RenderType_FavoriteJobListPageComponent)), i1.ɵdid(1, 4243456, null, 0, i7.FavoriteJobListPageComponent, [i8.MasterApiService, i9.JobApiService, i4.SafeDatePipe, i1.ChangeDetectorRef, i10.DialogService], null, null)], null, null); }
var FavoriteJobListPageComponentNgFactory = i1.ɵccf("ag-favorite-job-list-page", i7.FavoriteJobListPageComponent, View_FavoriteJobListPageComponent_Host_0, {}, {}, []);
export { FavoriteJobListPageComponentNgFactory as FavoriteJobListPageComponentNgFactory };
