import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { MapperInterceptor } from '@agent-ds/shared/interceptors/mapper.interceptor';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { OnDestroy, OnInit } from '@angular/core';
var BatchUpdateDialogComponent = /** @class */ (function () {
    function BatchUpdateDialogComponent(dialog, config, dynamicService, studentService) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.studentService = studentService;
        this.inClose = false;
        this.metadata = {
            groups: [
                {
                    class: 'ou',
                    rows: [
                        {
                            title: '項目',
                            fields: [
                                {
                                    type: 'dropdown',
                                    name: 'field',
                                    valueField: 'field',
                                    labelField: 'label',
                                    options: [
                                        { fieldName: 'userId', label: '担当者' },
                                        this.dynamicService.getDefinition('student', 'rank'),
                                        this.dynamicService.getDefinition('student', 'registrationRoute'),
                                        this.dynamicService.getDefinition('student', 'universityLevel'),
                                        this.dynamicService.getDefinition('student', 'academicFieldType'),
                                        this.dynamicService.getDefinition('student', 'registrationStatus'),
                                        this.dynamicService.getDefinition('student', 'interviewStatus'),
                                    ]
                                        .filter(function (field) { return field; })
                                        .map(function (field) { return ({ label: field.label, field: field.fieldName }); }),
                                    class: 'full tall',
                                },
                            ],
                        },
                        {
                            title: '新しい値',
                            fields: this.dynamicService.getFormRows({ fieldType: 'user', fieldName: 'userId' })[0].fields.concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'rank'), null, 'full tall')[0].fields, this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationRoute'), null, 'full tall')[0]
                                .fields, this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'universityLevel'), null, 'full tall')[0]
                                .fields, this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'academicFieldType'), null, 'full tall')[0]
                                .fields, this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'registrationStatus'), null, 'full tall')[0]
                                .fields, this.dynamicService.getFormRows(this.dynamicService.getDefinition('student', 'interviewStatus'), null, 'full tall')[0]
                                .fields),
                        },
                    ],
                },
            ],
        };
        this.model = {};
        this.innerFieldSelected = '';
        this.finished = false;
        this.updatedCount = 0;
    }
    BatchUpdateDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        for (var _i = 0, _a = this.metadata.groups[0].rows[1].fields; _i < _a.length; _i++) {
            var field = _a[_i];
            field.showOn = { field: field.name };
        }
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return (_this.finished ? _this.close() : _this.save()); });
    };
    BatchUpdateDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubScription) {
            this.fieldSubScription.unsubscribe();
        }
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    Object.defineProperty(BatchUpdateDialogComponent.prototype, "fieldSelected", {
        get: function () {
            return this.innerFieldSelected;
        },
        set: function (value) {
            this.fieldValue = undefined;
            this.innerFieldSelected = value;
        },
        enumerable: true,
        configurable: true
    });
    BatchUpdateDialogComponent.prototype.updateChange = function () {
        this.fieldValue = this.model['field'];
        this.innerFieldSelected = this.model[this.fieldValue];
    };
    BatchUpdateDialogComponent.prototype.save = function () {
        var _this = this;
        if (!this.fieldSelected || !this.fieldValue || this.inClose || this.dialog.inClose) {
            return;
        }
        this.inClose = true;
        var condition = this.config.data.condition ? MapperInterceptor.toSearchFormat(this.config.data.condition) : undefined;
        this.studentService.bulkUpdate(this.fieldValue, this.innerFieldSelected, this.config.data.studentIds, condition).subscribe(function (result) {
            _this.updatedCount = result.id ? result.id.length : 0;
            _this.inClose = false;
            _this.finished = true;
        }, function () { return (_this.inClose = false); });
    };
    BatchUpdateDialogComponent.prototype.close = function () {
        this.dialog.close();
    };
    return BatchUpdateDialogComponent;
}());
export { BatchUpdateDialogComponent };
