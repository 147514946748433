import * as tslib_1 from "tslib";
import { SituationMemo } from '@agent-ds/shared/interfaces';
import { AuthService, CompanyApiService, DynamicFieldService, StudentApiService, UserApiService, } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
var DEFAULT_TRUNCATE_AT = 300;
var MemoComponent = /** @class */ (function () {
    function MemoComponent(masterApiService, dynamicFieldService, userApiService, authService) {
        this.masterApiService = masterApiService;
        this.dynamicFieldService = dynamicFieldService;
        this.userApiService = userApiService;
        this.authService = authService;
        this.isCreateInProgress = false;
        this.createCanceled = new EventEmitter();
        this.saveSuccess = new EventEmitter();
        this.deleteSuccess = new EventEmitter();
        this.isEditInProgress = false;
        this.oldText = '';
        this.truncateAt = DEFAULT_TRUNCATE_AT;
        this.metadata = {
            groups: [
                {
                    class: 'ou',
                    rows: [
                        {
                            fields: [
                                {
                                    type: 'date',
                                    name: 'registerDate',
                                    class: 'quarter tall',
                                    validators: { required: true },
                                },
                                this.dynamicFieldService.getFormRows({
                                    fieldName: 'userId',
                                    fieldType: 'user',
                                    validationStyle: { required: true },
                                }, null, 'quarter')[0].fields[0],
                            ],
                        }
                    ].concat(this.dynamicFieldService.getFormRows({
                        fieldName: 'innerMemo',
                        fieldType: 'text',
                        displayType: 'textarea',
                    })),
                },
            ],
        };
        this.model = { registerDate: new Date() };
    }
    Object.defineProperty(MemoComponent.prototype, "isOpen", {
        get: function () {
            return !this.truncateAt;
        },
        enumerable: true,
        configurable: true
    });
    MemoComponent.prototype.ngOnInit = function () {
        if (this.isCreateInProgress) {
            this.memo = {
                registerDate: new Date().toISOString().substr(0, 10),
                innerMemo: '',
                userId: this.authService.loginUser.id,
            };
        }
        this.model = tslib_1.__assign({}, this.memo);
        delete this.model.user;
    };
    MemoComponent.prototype.ngOnChanges = function (changes) {
        if (changes['memo'] && this.memo) {
            this.model = tslib_1.__assign({}, this.memo);
            delete this.model.user;
        }
        if (changes['service']) {
            this.metadata.groups[0].rows[1].fields[0].name =
                this.service instanceof CompanyApiService ? 'innerMemo' : this.service instanceof StudentApiService ? 'situationMemo' : 'memo';
        }
    };
    MemoComponent.prototype.findUserTeam = function (userId) {
        var _this = this;
        return this.userApiService.getAll().pipe(mergeMap(function (users) {
            var user = users.find(function (u) { return u.id === userId; });
            if (user) {
                return _this.masterApiService.getTeams().pipe(map(function (teams) {
                    var team = teams.find(function (t) { return t.id === user.teamId; });
                    if (team) {
                        return { userName: user.name, teamName: team.name };
                    }
                    else {
                        return { userName: user.name, teamName: '' };
                    }
                }));
            }
            return null;
        }));
    };
    MemoComponent.prototype.toggleText = function () {
        this.truncateAt = this.truncateAt ? null : DEFAULT_TRUNCATE_AT;
    };
    MemoComponent.prototype.edit = function () {
        this.isEditInProgress = true;
    };
    MemoComponent.prototype.cancelEdit = function () {
        this.model = tslib_1.__assign({}, this.memo);
        this.isEditInProgress = false;
    };
    MemoComponent.prototype.cancelCreate = function () {
        this.isCreateInProgress = false;
        this.createCanceled.emit();
    };
    MemoComponent.prototype.create = function () {
        var _this = this;
        this.service.addMemo(this.referenceId, this.model).subscribe(function () {
            _this.cancelCreate();
            _this.saveSuccess.emit();
        });
    };
    MemoComponent.prototype.update = function () {
        var _this = this;
        this.service.updateMemo(this.referenceId, this.model).subscribe(function () {
            _this.cancelEdit();
            _this.saveSuccess.emit();
        });
    };
    MemoComponent.prototype.delete = function () {
        var _this = this;
        PopupControlComponent.instance.open({
            title: '削除確認',
            content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
            confirmText: 'OK',
            cancelText: 'キャンセル',
            confirmCallback: function () {
                return _this.service.deleteMemo(_this.referenceId, _this.memo.id).pipe(tap(function () {
                    _this.deleteSuccess.emit();
                }));
            },
        });
    };
    return MemoComponent;
}());
export { MemoComponent };
