<ag-dynamic-form [metadata]="metadata" [model]="model" *ngIf="metadata">
</ag-dynamic-form>

<div class="footer">
  <div class="footer__container">
    <button class="footer__btn footer__btn--green" (click)="onFilterClick()">
      検索
    </button>
  </div>
</div>
