import * as i0 from "@angular/core";
/**
 * 永続ストレージ
 */
var PermanentStorageService = /** @class */ (function () {
    function PermanentStorageService() {
        /**
         * キー値のプレフィクス
         */
        this.prefix = 'agent-ds';
    }
    /**
     * キー値を取得する
     */
    PermanentStorageService.prototype.key = function (key) {
        return this.prefix + ":" + key;
    };
    /**
     * 取得する
     */
    PermanentStorageService.prototype.get = function (key) {
        var data = localStorage.getItem(this.key(key));
        if (!data) {
            return null;
        }
        try {
            return JSON.parse(data);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    };
    /**
     * 保存する
     */
    PermanentStorageService.prototype.set = function (key, value) {
        return localStorage.setItem(this.key(key), JSON.stringify(value));
    };
    /**
     * 削除する
     */
    PermanentStorageService.prototype.remove = function (key) {
        return localStorage.removeItem(this.key(key));
    };
    PermanentStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermanentStorageService_Factory() { return new PermanentStorageService(); }, token: PermanentStorageService, providedIn: "root" });
    return PermanentStorageService;
}());
export { PermanentStorageService };
