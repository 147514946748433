<div class="header">
  <div class="counter">
    <span class="counter__count">{{ filteredContracts?.length || 0 }}</span>
    <span class="counter__label">件</span>
  </div>
  <div class="controls">
    <div class="checkbox-wrapper">
      <input
        class="checkbox-wrapper__input"
        id="show_ended_jobs"
        type="checkbox"
        [ngModel]="activeFilter"
        (ngModelChange)="onFilterChange($event)"
      />
      <label class="checkbox-wrapper__label" for="show_ended_jobs">
        無効も表示
      </label>
    </div>
    <button
      class="controls__btn btn btn--green btn--larger"
      (click)="openCompanyContractCreateDialog()"
    >
      新規契約登録
    </button>
  </div>
</div>

<ag-page-scroll-table
  *ngIf="contracts && contracts.length"
  class="table"
  [tableConfig]="tableConfig"
  [content]="filteredContracts"
  [scrollerClass]="'no-scroll'"
></ag-page-scroll-table>

<ng-template #actions let-data>
  <div class="actions">
    <button
      class="actions__btn btn btn--small btn--white btn--outline-gray"
      (click)="openCompanyContractEditDialog(data)"
    >
      詳細
    </button>
  </div>
</ng-template>

<ng-template #status let-data>
  <div *ngIf="data.dynamicData.status === '無効'" class="status">
    <div class="status__badge">
      無
    </div>
  </div>
</ng-template>
