import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { OnChanges, SimpleChanges } from '@angular/core';
var CompanySalesTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanySalesTabComponent, _super);
    function CompanySalesTabComponent() {
        var _this = _super.call(this) || this;
        _this.companyId = null;
        return _this;
    }
    CompanySalesTabComponent.prototype.ngOnChanges = function (changes) {
        if (this.companyId != null && changes['companyId'].currentValue !== changes['companyId'].previousValue) {
            this.requestObject = {
                enterpriseId: [this.companyId],
            };
        }
    };
    return CompanySalesTabComponent;
}(Tab));
export { CompanySalesTabComponent };
