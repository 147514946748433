<div class="header">
  <ul class="header__hints">
    <li class="header__hint" *ngIf="model === 'students'">
      ファイル名に求職者の氏名が含まれるファイルのみアップロードできます。
    </li>
    <li class="header__hint" *ngIf="model === 'enterprise'">
      ファイル名を[企業ID]_(ファイル名)としてアップロードしてください。 例)
      1_Sample.txt
    </li>
    <li class="header__hint" *ngIf="model === 'students'">
      顔写真として表示する場合は、ファイル名に ”face_” を含めてください。
    </li>
  </ul>
  <button
    class="header__button btn btn--large btn--green"
    (click)="uploadFiles()"
  >
    アップロード
  </button>
</div>
<table *ngIf="files.length > 0" class="files-table">
  <thead>
    <tr>
      <th>ファイル名</th>
      <th>種類</th>
      <th>サイズ</th>
      <th>登録日時</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let file of files; let i = index">
      <td>
        <div class="files-table__link">
          <span
            class="files-table__badge files-table__badge--gray"
            *ngIf="file.enterpriseSendFlag"
          >
            {{ ENTERPRISE_SEND_FLAG_OPTION }}
          </span>
          <span
            class="files-table__badge files-table__badge--gray"
            *ngIf="file.studentSendFlag"
          >
            {{ STUDENT_SEND_FLAG_OPTION }}
          </span>
          <span (click)="downloadFile(file)">{{ file.name }}</span>
          <span
            *ngIf="file.studentUploadedFlag"
            class="files-table__waiting-for-confirmation"
          >
            確認待ち
          </span>
        </div>
      </td>
      <td class="files-table__type">
        {{
          file.name.indexOf(".") > -1
            ? file.name.split(".").pop()
            : file.mimeType
        }}
      </td>
      <td class="files-table__size">{{ file.size | fileSize }}</td>
      <td class="files-table__created">{{ file.createdAt | basedDate }}</td>
      <td class="files-table__actions">
        <button
          *ngIf="file.studentUploadedFlag"
          class="files-table__button btn btn--small btn--outline-gray btn--white btn--thin"
          (click)="registerFile(file)"
        >
          確認
        </button>
        <button
          *ngIf="!file.studentUploadedFlag"
          class="files-table__button btn btn--small btn--outline-gray btn--white btn--thin"
          (click)="deleteFile(file.id)"
        >
          削除
        </button>
        <button
          *ngIf="!file.studentUploadedFlag && !file.enterpriseSendFlag"
          class="files-table__button btn btn--small btn--outline-gray btn--white btn--thin"
          (click)="changeToSendable(file)"
        >
          登録
        </button>
      </td>
    </tr>
  </tbody>
</table>
