<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">アップロード</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="fileupload-container">
      <ag-file-upload-area
        *ngIf="uploadFilesData.length === 0"
        (filesChanged)="onFilesChanged($event)"
      ></ag-file-upload-area>
      <table *ngIf="uploadFilesData.length > 0" class="files-table">
        <thead>
          <tr>
            <th>ファイル名</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let uploadFileData of uploadFilesData; let i = index">
            <td>
              <div class="files-table__cell">
                <div class="files-table__link">
                  {{ uploadFileData.file.name }}
                </div>
                <div
                  *ngIf="uploadFileData.options"
                  class="files-table__options"
                >
                  <div
                    *ngFor="let option of uploadFileData.options | keyvalue"
                    class="files-table__option"
                  >
                    <input
                      type="checkbox"
                      [(ngModel)]="uploadFileData.options[option.key]"
                    />
                    <span>{{ option.key }}</span>
                  </div>
                </div>
                <button
                  class="files-table__button 
                      files-table__button--small 
                      files-table__button--outline-gray 
                      files-table__button--white"
                  (click)="removeFile(i)"
                >
                  削除
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      (click)="upload()"
      [disabled]="uploadFilesData.length == 0 || dialog.inClose"
    >
      アップロード
    </button>
  </div>
</div>
