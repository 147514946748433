import * as tslib_1 from "tslib";
import { CompanyBillingAddressCreateDialogComponent, CompanyDepartmentCreateDialogComponent, CompanySeminarAttendanceSendDialogComponent, } from '@agent-ds/company/components';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { Tab } from '@agent-ds/shared/models';
import { CompanyApiService, DialogService, ExportApiService, JobApiService } from '@agent-ds/shared/services';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, SimpleChanges, TemplateRef, } from '@angular/core';
import { tap } from 'rxjs/operators';
import { COMPANY_SECTIONS_BILLING_ADDRESS_TABLE_CONFIG, COMPANY_SECTIONS_DEPARTMENT_TABLE_CONFIG, } from './company-sections-tab-table-config';
var CompanySectionsTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanySectionsTabComponent, _super);
    function CompanySectionsTabComponent(companyApiService, dialog, cdr, jobService, exportService) {
        var _this = _super.call(this) || this;
        _this.companyApiService = companyApiService;
        _this.dialog = dialog;
        _this.cdr = cdr;
        _this.jobService = jobService;
        _this.exportService = exportService;
        _this.selectedJobId = new EventEmitter();
        _this.progressExportTemplates = [];
        _this.activeFilter = false;
        _this.activeBllingAddressFilter = false;
        return _this;
    }
    CompanySectionsTabComponent.prototype.ngOnChanges = function (changes) {
        if (changes['company']) {
            this.loadDepartments();
        }
    };
    CompanySectionsTabComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.departmentsTableConfig = COMPANY_SECTIONS_DEPARTMENT_TABLE_CONFIG(this.statusTemplate, this.actionsTemplate, this.actionsBottomTemplate);
        this.billingAddressesTableConfig = COMPANY_SECTIONS_BILLING_ADDRESS_TABLE_CONFIG(this.billingActionsTemplate);
        this.exportService.getTemplates(ExportModel.progress).subscribe(function (res) { return (_this.progressExportTemplates = res.templates); });
        this.cdr.detectChanges();
    };
    CompanySectionsTabComponent.prototype.loadDepartments = function () {
        var _this = this;
        if (this.company) {
            this.companyApiService.getDepartments(this.company.id).subscribe(function (response) {
                _this.departments = response.enterpriseDepartments;
                _this.billingAddresses = response.enterpriseBillingAddresses;
                _this.filter();
                _this.filterBillingAddress();
            });
        }
    };
    CompanySectionsTabComponent.prototype.onFilterChange = function (value) {
        this.activeFilter = value;
        this.filter();
    };
    CompanySectionsTabComponent.prototype.filter = function () {
        this.filteredDepartments = this.activeFilter ? this.departments : this.departments.filter(function (dep) { return dep.dynamicData.status !== '廃止'; });
    };
    CompanySectionsTabComponent.prototype.onFilterChangeBillingAddress = function (value) {
        this.activeBllingAddressFilter = value;
        this.filterBillingAddress();
    };
    CompanySectionsTabComponent.prototype.filterBillingAddress = function () {
        this.filteredBillingAddresses = this.activeBllingAddressFilter
            ? this.billingAddresses
            : this.billingAddresses.filter(function (dep) { return dep.dynamicData.billignAddressStatus !== '無効'; });
    };
    CompanySectionsTabComponent.prototype.createJob = function (department) {
        var _this = this;
        this.jobService.addJob(department.id).subscribe(function (res) {
            _this.jobService.refreshEvent.emit();
            JobDetailPageComponent.instance.referenceId = res.id;
            JobDetailPageComponent.instance.open();
        });
    };
    CompanySectionsTabComponent.prototype.editDepartment = function (data) {
        this.openDepartmentCreateDialog({
            data: { department: data, company: this.company, departmentList: this.departments },
        });
    };
    CompanySectionsTabComponent.prototype.editListSend = function (data) {
        var _this = this;
        this.exportService.getTemplates(ExportModel.progress).subscribe(function (res) {
            _this.progressExportTemplates = res.templates;
            _this.openSeminarAttendanceSendDialog({
                data: { company: _this.company, department: data, progressExportTemplates: _this.progressExportTemplates },
            });
        });
    };
    CompanySectionsTabComponent.prototype.editBillingAddress = function (data) {
        this.openBillingAddressCreateDialog({ data: { billing: data, company: this.company, departmentList: this.departments } });
    };
    CompanySectionsTabComponent.prototype.openDepartmentCreateDialog = function (config) {
        var _this = this;
        this.dialog.open(CompanyDepartmentCreateDialogComponent, config || {
            data: { department: {}, company: this.company, departmentList: this.departments },
        }, function (result) {
            if (!result) {
                return;
            }
            if (config) {
                return _this.companyApiService
                    .updateDepartment(_this.company.id, config.data.department.id, result)
                    .pipe(tap(function () { return _this.loadDepartments(); }));
            }
            else {
                return _this.companyApiService.addDepartment(_this.company.id, result).pipe(tap(function () { return _this.loadDepartments(); }));
            }
        });
    };
    CompanySectionsTabComponent.prototype.openBillingAddressCreateDialog = function (config) {
        var _this = this;
        this.dialog.open(CompanyBillingAddressCreateDialogComponent, config || {
            data: { billing: {}, company: this.company, departmentList: this.departments },
        }, function (result) {
            if (!result) {
                return;
            }
            if (config) {
                return _this.companyApiService
                    .updateBillingAddress(_this.company.id, config.data.billing.id, result)
                    .pipe(tap(function () { return _this.loadDepartments(); }));
            }
            else {
                return _this.companyApiService.addBillingAddress(_this.company.id, result).pipe(tap(function () { return _this.loadDepartments(); }));
            }
        });
    };
    CompanySectionsTabComponent.prototype.openSeminarAttendanceSendDialog = function (config) {
        var _this = this;
        this.dialog.open(CompanySeminarAttendanceSendDialogComponent, config || {
            data: { company: this.company, department: {}, progressExportTemplates: this.progressExportTemplates },
        }, function (result) {
            if (!result) {
                return;
            }
            _this.companyApiService.refreshEvent.emit();
        });
    };
    return CompanySectionsTabComponent;
}(Tab));
export { CompanySectionsTabComponent };
