<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">ファイル名の変更</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="rename-file" [formGroup]="renameFileForm">
      <div class="rename-file__explanatory-note">
        ファイル名の一部を指定できます。<br />
        以下の選択肢から選んでください。
        <p class="rename-file__explanatory-note__remarks">
          ※ ファイル名の仕様<br />
          <span class="rename-file__explanatory-note__remarks--second-line"
            >【送付不可】</span
          >+
          <span class="rename-file__explanatory-note__remarks--text-green"
            >【指定する名称】</span
          >+ [姓][名]様
        </p>
      </div>
      <div class="rename-file__section-header --text-green">指定する名称</div>
      <div>
        <label>
          <input
            type="radio"
            formControlName="prefix"
            value="【仮】【履歴書等】"
          />
          【仮】【履歴書等】
        </label>
      </div>
      <div>
        <label>
          <input type="radio" formControlName="prefix" value="【履歴書】" />
          【履歴書】
        </label>
      </div>
      <div>
        <label>
          <input type="radio" formControlName="prefix" value="【OpenES】" />
          【OpenES】
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            formControlName="prefix"
            value="【MyCareerbox】"
          />
          【MyCareerbox】
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            formControlName="prefix"
            [value]="CUSTOM_OPTION"
          />
          その他（任意入力）
        </label>
      </div>
      <div class="rename-file__custom-prefix pl-18px">
        <input
          type="text"
          class="rename-file__custom-prefix__input"
          placeholder="文字列を入力"
          title="文字列を入力"
          formControlName="custom"
          #customInput
        />
        <div class="rename-file__custom-prefix__remarks">
          ※ 【】 は文字列の両端に自動で挿入されます
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      (click)="renameFile()"
      [disabled]="renameFileForm.invalid || inClose || dialog.inClose"
    >
      OK
    </button>
  </div>
</div>
