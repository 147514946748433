import * as tslib_1 from "tslib";
import { MailPopupType } from '../config';
import { StudentJobConfig } from './student-job-config';
var ProgressConsultConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressConsultConfig, _super);
    function ProgressConsultConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressConsultConfig.prototype.getPopupConfig = function () {
        return [{ type: MailPopupType.JOB_CHECK }];
    };
    return ProgressConsultConfig;
}(StudentJobConfig));
export { ProgressConsultConfig };
