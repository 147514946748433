import * as tslib_1 from "tslib";
import { DynamicFieldService } from '@agent-ds/shared/services';
import { OnInit } from '@angular/core';
import { first, tap } from 'rxjs/operators';
var TypeOptionKey;
(function (TypeOptionKey) {
    TypeOptionKey["SINGLE_LINE_TEXT"] = "\u5358\u4E00\u884C\u30C6\u30AD\u30B9\u30C8";
    TypeOptionKey["MULTI_LINE_TEXT"] = "\u8907\u6570\u884C\u30C6\u30AD\u30B9\u30C8";
    TypeOptionKey["NUMBER"] = "\u6570\u5024";
    TypeOptionKey["SINGLE_DROPDOWN"] = "\u5358\u4E00\u9078\u629E\uFF08\u30C9\u30ED\u30C3\u30D7\u30C0\u30A6\u30F3\uFF09";
    TypeOptionKey["SINGLE_RADIO"] = "\u5358\u4E00\u9078\u629E\uFF08\u30E9\u30B8\u30AA\u30DC\u30BF\u30F3\uFF09";
    TypeOptionKey["MULTI_DROPDOWN"] = "\u8907\u6570\u9078\u629E\uFF08\u30C9\u30ED\u30C3\u30D7\u30C0\u30A6\u30F3\uFF09";
    TypeOptionKey["SINGLE_CHECKBOX"] = "\u8907\u6570\u9078\u629E\uFF08\u30C1\u30A7\u30C3\u30AF\u30DC\u30C3\u30AF\u30B9\uFF09";
    TypeOptionKey["DATE"] = "\u65E5\u4ED8";
})(TypeOptionKey || (TypeOptionKey = {}));
var SettingsCustomFieldsPageComponent = /** @class */ (function () {
    function SettingsCustomFieldsPageComponent(dynamicFieldService) {
        var _a;
        this.dynamicFieldService = dynamicFieldService;
        this.studentFields = [];
        this.enterpriseFields = [];
        this.jobFields = [];
        this.editors = {};
        this.NEW_FIELD_EDITOR_KEY = {
            student: 'NEW_STUDENT_FIELD',
            enterprise: 'NEW_ENTERPRISE_FIELD',
            job: 'NEW_JOB_FIELD',
        };
        this.TYPE_OPTIONS = (_a = {},
            _a[TypeOptionKey.SINGLE_LINE_TEXT] = { fieldType: 'text', displayType: 'text' },
            _a[TypeOptionKey.MULTI_LINE_TEXT] = { fieldType: 'text', displayType: 'textarea' },
            _a[TypeOptionKey.NUMBER] = { fieldType: 'number', displayType: 'number' },
            _a[TypeOptionKey.SINGLE_DROPDOWN] = { fieldType: 'list', displayType: 'dropdown' },
            _a[TypeOptionKey.SINGLE_RADIO] = { fieldType: 'list', displayType: 'radio' },
            _a[TypeOptionKey.MULTI_DROPDOWN] = { fieldType: 'multi-list', displayType: 'dropdown' },
            _a[TypeOptionKey.SINGLE_CHECKBOX] = { fieldType: 'multi-list', displayType: 'checkbox' },
            _a[TypeOptionKey.DATE] = { fieldType: 'date', displayType: 'date' },
            _a);
    }
    SettingsCustomFieldsPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicFieldService.fieldUpdateEvent.pipe(first()).subscribe(function () {
            var filter = function (field) { return field.isCustomField; };
            _this.studentFields = (_this.dynamicFieldService.getDefinitions('student') || []).filter(filter);
            _this.enterpriseFields = (_this.dynamicFieldService.getDefinitions('enterprise') || []).filter(filter);
            _this.jobFields = (_this.dynamicFieldService.getDefinitions('job') || []).filter(filter);
        });
    };
    SettingsCustomFieldsPageComponent.prototype.asIsOrder = function (a, b) {
        return 1;
    };
    SettingsCustomFieldsPageComponent.prototype.toType = function (field) {
        if (field.fieldType === 'text' && field.displayType === 'text') {
            return TypeOptionKey.SINGLE_LINE_TEXT;
        }
        else if (field.fieldType === 'text' && field.displayType === 'textarea') {
            return TypeOptionKey.MULTI_LINE_TEXT;
        }
        else if (field.fieldType === 'number' && field.displayType === 'number') {
            return TypeOptionKey.NUMBER;
        }
        else if (field.fieldType === 'list' && field.displayType === 'dropdown') {
            return TypeOptionKey.SINGLE_DROPDOWN;
        }
        else if (field.fieldType === 'list' && field.displayType === 'radio') {
            return TypeOptionKey.SINGLE_RADIO;
        }
        else if (field.fieldType === 'multi-list' && field.displayType === 'dropdown') {
            return TypeOptionKey.MULTI_DROPDOWN;
        }
        else if (field.fieldType === 'multi-list' && field.displayType === 'checkbox') {
            return TypeOptionKey.SINGLE_CHECKBOX;
        }
        else if (field.fieldType === 'date' && field.displayType === 'date') {
            return TypeOptionKey.DATE;
        }
        return null;
    };
    SettingsCustomFieldsPageComponent.prototype.isEditorValid = function (id) {
        if (!this.editors || !this.editors[id]) {
            return false;
        }
        return (this.editors[id].newLabel &&
            this.editors[id].newLabel.trim() &&
            this.editors[id].type &&
            this.TYPE_OPTIONS[this.editors[id].type] &&
            (this.TYPE_OPTIONS[this.editors[id].type].fieldType === 'list' || this.TYPE_OPTIONS[this.editors[id].type].fieldType === 'multi-list'
                ? this.editors[id].allOptions && !!this.editors[id].allOptions.length
                : true));
    };
    SettingsCustomFieldsPageComponent.prototype.onSave = function (field, editor) {
        var _this = this;
        if (editor.isUpdateInProgress || !this.isEditorValid(field.id)) {
            return;
        }
        editor.isUpdateInProgress = true;
        var requestObj = {
            model: field.model,
            fieldType: this.TYPE_OPTIONS[editor.type].fieldType,
            displayType: this.TYPE_OPTIONS[editor.type].displayType,
            label: editor.newLabel.trim(),
            isSearchable: editor.isSearchable ? 1 : 0,
        };
        if (this.TYPE_OPTIONS[this.editors[field.id].type].fieldType === 'list' ||
            this.TYPE_OPTIONS[this.editors[field.id].type].fieldType === 'multi-list') {
            requestObj = tslib_1.__assign({}, requestObj, { validationStyle: { options: editor.allOptions } });
        }
        var request = field.id === this.NEW_FIELD_EDITOR_KEY.student ||
            field.id === this.NEW_FIELD_EDITOR_KEY.enterprise ||
            field.id === this.NEW_FIELD_EDITOR_KEY.job
            ? this.dynamicFieldService.addDefinition(requestObj).pipe(tap(function () { return (editor.isEdit = false); }))
            : this.dynamicFieldService.updateDefinition(field.id, requestObj);
        request.subscribe(function (updatedDynamicField) {
            editor.newOption = '';
            editor.isUpdateInProgress = false;
            var fields = field.model === 'student'
                ? _this.studentFields
                : field.model === 'enterprise'
                    ? _this.enterpriseFields
                    : field.model === 'job'
                        ? _this.jobFields
                        : [];
            var index = fields.findIndex(function (f) { return f.id === field.id; });
            if (index > -1) {
                fields[index] = tslib_1.__assign({}, updatedDynamicField);
            }
            else {
                fields.push(updatedDynamicField);
            }
            editor.isEdit = false;
        }, function () {
            editor.isUpdateInProgress = false;
        });
    };
    SettingsCustomFieldsPageComponent.prototype.toggleIsSearchable = function (field) {
        var _this = this;
        this.editors[field.id] = tslib_1.__assign({}, this.editors[field.id], { isUpdateInProgress: true });
        this.dynamicFieldService
            .updateDefinition(field.id, {
            fieldType: field.fieldType,
            label: field.label,
            displayType: field.displayType,
            validationStyle: field.validationStyle,
            isSearchable: field.isSearchable ? 0 : 1,
        })
            .subscribe(function (updatedDynamicField) {
            _this.editors[field.id].isUpdateInProgress = false;
            var fields = field.model === 'student'
                ? _this.studentFields
                : field.model === 'enterprise'
                    ? _this.enterpriseFields
                    : field.model === 'job'
                        ? _this.jobFields
                        : [];
            var index = fields.findIndex(function (f) { return f.id === field.id; });
            if (index > -1) {
                fields[index] = tslib_1.__assign({}, updatedDynamicField);
            }
        }, function () {
            _this.editors[field.id].isUpdateInProgress = false;
        });
    };
    return SettingsCustomFieldsPageComponent;
}());
export { SettingsCustomFieldsPageComponent };
