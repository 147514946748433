<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">
      セミナー・面接参加者リスト送付設定
    </h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="content">
      <form #myForm="ngForm" class="form-wrap">
        <div class="form-heading">対象部署</div>
        <p class="form-text">
          {{ department?.dynamicData?.name }}
        </p>
        <div class="form-heading">対象CSV</div>
        <ag-autocomplete
          class="export__select"
          placeholder="CSVテンプレート名"
          valueField="id"
          labelField="name"
          [options]="exportTemplates"
          [(value)]="exportTemplateId"
        ></ag-autocomplete>

        <div class="form-heading">日時の設定</div>
        <div class="form-container -vertical">
          <ng-container
            *ngFor="let dateSetting of formData.dateSettings; index as i"
          >
            <div class="form-block">
              <div class="form-label__schedule">
                送信日時
              </div>
              <input
                class="form-item-input"
                type="date"
                name="dateSettingDate{{ i }}"
                max="2500-01-01"
                [min]="minDate.format('YYYY-MM-DD')"
                [(ngModel)]="dateSetting.senderDate"
              />
              <input
                class="form-item-input"
                type="time"
                step="1800"
                name="dateSettingTime{{ i }}"
                [(ngModel)]="dateSetting.senderTime"
              />
              <div class="form-label__schedule">
                選考日
              </div>
              <input
                class="form-item-input"
                type="date"
                name="seminarDate{{ i }}"
                max="2500-01-01"
                [min]="minDate.format('YYYY-MM-DD')"
                [(ngModel)]="dateSetting.seminarDate"
              />
            </div>
          </ng-container>
        </div>
        <p class="form-note">
          ※祝日の場合も送信されます。翌日の送信から適用されます。
        </p>

        <div class="form-heading">曜日と時間の設定</div>
        <div class="form-container">
          <ng-container
            *ngFor="let dayOfWeek of formData.dayOfWeeks; index as i"
          >
            <div class="form-block">
              <input
                id="dayOfWeek{{ i }}"
                class="form-item-input"
                type="checkbox"
                name="dayOfWeek{{ i }}"
                [(ngModel)]="dayOfWeek.checked"
              />
              <label for="dayOfWeek{{ i }}">{{ dayOfWeek.label }}</label>
            </div>
          </ng-container>
          <div class="form-block">
            <input
              class="form-item-input"
              type="time"
              step="1800"
              name="dayOfWeekTime"
              [(ngModel)]="formData.dayOfWeekTime"
            />
          </div>
        </div>
        <p class="form-note">
          ※未来日の全ての選考が対象になります。祝日の場合も送信されます。翌日の送信から適用されます。
        </p>

        <div class="form-heading">配信タイミング</div>
        <div class="form-container -vertical">
          <ng-container
            *ngFor="let deliveryTiming of formData.deliveryTimings; index as i"
          >
            <div class="form-block">
              <input
                id="deliveryTiming{{ i }}"
                class="form-item-input"
                type="checkbox"
                name="deliveryTiming{{ i }}"
                [(ngModel)]="deliveryTiming.checked"
              />
              <label for="deliveryTiming{{ i }}" class="form-label__timing">{{
                deliveryTiming.label
              }}</label>
              <input
                class="form-item-input"
                type="time"
                step="1800"
                name="deliveryTimingTime{{ i }}"
                [(ngModel)]="deliveryTiming.time"
              />
            </div>
          </ng-container>
        </div>
        <p class="form-note">
          ※N営業日前のカウントに祝日は考慮されません。翌日の送信から適用されます。
        </p>
      </form>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!isValid"
      (click)="create()"
    >
      登録
    </button>
  </div>
</div>
