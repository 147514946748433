/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sales-report-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "./sales-report-filter.component";
import * as i9 from "../../../shared/services/api/dynamic-field.service";
var styles_SalesReportFilterComponent = [i0.styles];
var RenderType_SalesReportFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SalesReportFilterComponent, data: {} });
export { RenderType_SalesReportFilterComponent as RenderType_SalesReportFilterComponent };
function View_SalesReportFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadata; var currVal_1 = _co.model; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SalesReportFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalesReportFilterComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "footer__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "footer__btn footer__btn--green"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u691C\u7D22 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadata; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SalesReportFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-sales-report-filter", [], null, null, null, View_SalesReportFilterComponent_0, RenderType_SalesReportFilterComponent)), i1.ɵdid(1, 114688, null, 0, i8.SalesReportFilterComponent, [i9.DynamicFieldService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SalesReportFilterComponentNgFactory = i1.ɵccf("ag-sales-report-filter", i8.SalesReportFilterComponent, View_SalesReportFilterComponent_Host_0, {}, { filter: "filter" }, []);
export { SalesReportFilterComponentNgFactory as SalesReportFilterComponentNgFactory };
