<div class="error">
  <p class="title">
    {{ serverError ? "500 Internal server error." : "404 Page not found." }}
  </p>
  <p class="description">
    {{
      serverError
        ? "アクセスしようとしたページは表示できませんでした。"
        : "お探しのページは見つかりませんでした。"
    }}
  </p>
  <p class="hint">
    {{
      serverError
        ? "このサイトではメンテナンス中であるか、\nプログラミングエラーが発生している可能性があります。\nしばらく時間を置いてから再度お試しください。"
        : "アクセスしようとしたページは削除、変更されたか、\n現在利用できない可能性がございます。"
    }}
  </p>
  <p class="action" routerLink="/dashboard" *ngIf="!serverError">
    TOPページへ<span class="action__arrow action__arrow--right"></span>
  </p>
</div>
