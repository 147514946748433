<div class="header" *ngIf="!isIntakeCa">
  <span class="header__title">成約検索結果</span>
  <!-- <span class="header__criteria">
    キーワード：{{ searchObj["keyword"] || "" }}
  </span> -->
  <button class="header__modify" (click)="openSearch()">
    検索条件を変更<span
      class="header__modify__arrow header__modify__arrow--down"
    ></span>
  </button>
  <button
    class="header__export"
    [disabled]="exportInProgress"
    (click)="runExport()"
  >
    CSV出力
  </button>
</div>

<ag-sales-list
  [requestObject]="searchObj"
  [options]="{ body: { checkbox: true } }"
  *ngIf="!isIntakeCa"
>
</ag-sales-list>

<ag-sales-search
  *ngIf="isSearchOpen && !isIntakeCa"
  [searchModel]="searchObj"
  (closed)="closeSearch()"
  (search)="doSearch($event)"
></ag-sales-search>
