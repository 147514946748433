<div class="title">
  求職者データ取り込み
  <button
    class="btn btn--big btn--white btn--outline-gray"
    (click)="downloadExampleCsv()"
  >
    CSVヘッダー出力
  </button>
</div>
<div class="content">
  <div class="section section--file-import">
    <div class="section__header header header--mb-15">
      <div class="header__title">
        取り込みファイルの選択
      </div>
    </div>
    <div class="section__content import-container">
      <div
        *ngIf="!isImportInProgress; else showImportLoadingBar"
        class="import-btn-group"
      >
        <label class="import-btn-group__btn btn btn--white btn--outline-gray">
          <input #fileinput type="file" (change)="onInputChanged($event)" />
          ファイル選択
        </label>
        <span class="import-btn-group__label">
          {{
            fileToUpload ? fileToUpload.name : "ファイルが選択されていません"
          }}
        </span>
        <button
          class="btn btn--green"
          [disabled]="!fileToUpload"
          (click)="onImport()"
        >
          取り込み実行
        </button>
      </div>
      <ng-template #showImportLoadingBar>
        <div class="loading">
          <svg class="loading__spinner" viewBox="0 0 50 50">
            <circle
              class="loading__spinner__path"
              cx="25"
              cy="25"
              r="20"
            ></circle>
          </svg>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="section section--file-select">
    <div class="section__header header header--mb-10">
      <div class="header__title">
        取り込み結果
      </div>
    </div>
    <div class="section__control control-group">
      <span class="control-group__label">履歴：</span>
      <select
        class="control-group__select select"
        [disabled]="!importFiles || !importFiles.length"
        [(ngModel)]="selectedFileId"
        (change)="onFileSelect(selectedFileId)"
      >
        <option [value]="DEFAULT_IMPORT_HISTORY_OPTION.value">
          {{ DEFAULT_IMPORT_HISTORY_OPTION.label }}
        </option>
        <option *ngFor="let importFile of importFiles" [value]="importFile.id">
          {{ importFile.createdDate | safeDate: "yyyy/MM/dd (E)" }}
          {{ importFile.originalFilename }}
        </option>
      </select>
      <button
        class="control-group__btn btn btn--blue-primary"
        [disabled]="!importFiles || !importFiles.length"
        (click)="onClearImportHistory()"
      >
        すべてクリア
      </button>
    </div>
    <div class="section__content">
      <div *ngIf="importData" class="import-informations">
        <div class="import-informations__count">
          {{ importData.successCount + importData.errorCount }}
        </div>
        <div class="import-informations__label">件中</div>
        <div class="import-informations__count">
          {{ importData.successCount }}
        </div>
        <div class="import-informations__label">
          件のデータを取り込みました。
        </div>
      </div>
    </div>
  </div>
  <div class="section section--import-details">
    <div class="section__header header header--mb-15">
      <div class="header__title">
        取り込み候補一覧
      </div>
    </div>
    <div class="section__control detail-controls">
      <div class="detail-controls__group">
        <input
          *ngIf="table"
          type="checkbox"
          class="detail-controls__checkbox"
          [disabled]="!importData || !importData.errorCount"
          [checked]="table?.allChecked"
          (click)="table?.checkAll(table.allChecked)"
        />
        <button
          class="detail-controls__btn btn btn--larger btn--blue-primary"
          [disabled]="
            !importData || !importData.errorCount || !table?.checkedItems.length
          "
          (click)="onBatchDeleteErrors()"
        >
          削除
        </button>
        <div *ngIf="importData" class="detail-controls__info">
          残りの件数：
          <span class="detail-controls__info--count">{{
            importData?.errorCount || 0
          }}</span>
          <span class="detail-controls__info--unit">件</span>
        </div>
      </div>
      <div class="detail-controls__group">
        <span class="detail-controls__select--label">媒体でフィルタ：</span>
        <select
          class="detail-controls__select select"
          [disabled]="
            !importData ||
            !registrationRouteOptions ||
            !registrationRouteOptions.length
          "
          [(ngModel)]="selectedFirstRegistrationRoute"
          (change)="
            onFirstRegistrationRouteSelect(
              selectedFileId,
              selectedFirstRegistrationRoute
            )
          "
        >
          <option [value]="DEFAULT_FIRST_REGISTRATION_ROUTE.value">
            {{ DEFAULT_FIRST_REGISTRATION_ROUTE.label }}
          </option>
          <option
            *ngFor="let option of registrationRouteOptions"
            [value]="option"
            >{{ option }}</option
          >
        </select>
      </div>
    </div>
    <div class="section__content">
      <ag-page-scroll-table
        *ngIf="tableConfig"
        [tableConfig]="tableConfig"
        [content]="importData?.errorList"
        (itemSelected)="
          floaterContent = FloaterContent.DETAIL; onImportErrorSelect($event)
        "
      ></ag-page-scroll-table>
      <div
        *ngIf="!importData || !importData.errorList?.length"
        class="empty-table-label"
      >
        取り込み候補はありません
      </div>
    </div>
  </div>
</div>

<ag-page-floater
  [sideActions]="sideActions"
  (transformed)="isFloaterOpen = $event !== '0px'"
>
  <ng-container
    *ngIf="floaterContent === FloaterContent.COMPARE; then compare; else detail"
  ></ng-container>
  <ng-template #compare>
    <ag-student-import-compare
      [studentImportErrorId]="selectedStudentImportErrorId"
      [duplicatedStudentId]="selectedDuplicatedStudentId"
      [errorType]="selectedErrorType"
      (closeWithRefresh)="onCloseWithRefresh()"
    ></ag-student-import-compare>
  </ng-template>
  <ng-template #detail>
    <ag-student-import-detail
      [studentImportErrorId]="selectedStudentImportErrorId"
      (closeWithRefresh)="onCloseWithRefresh()"
    ></ag-student-import-detail>
  </ng-template>
</ag-page-floater>

<ng-template #errorTypeTemplate let-data>
  <div class="error-type">
    {{ StudentImportErrors[data.type]
    }}<span
      *ngIf="
        (data.type === 3 || data.type === 4) && data.duplicatedStudentFrontId
      "
      >(
      <span
        *ngFor="let student of data.duplicatedStudent"
        class="error-type--blue"
        (click)="
          $event.stopPropagation();
          floaterContent = FloaterContent.COMPARE;
          onImportErrorSelect(data, student.id)
        "
        >{{ student.frontId
        }}<span
          *ngIf="
            data.duplicatedStudent.indexOf(student) !==
            data.duplicatedStudent.length - 1
          "
          >,
        </span>
      </span>
      )</span
    >
  </div>
</ng-template>

<ng-template #actions let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <div
      *ngIf="data.type === 3"
      class="actions__action"
      (click)="onAccept(data.id)"
    >
      取り込み
    </div>
    <div class="actions__action" (click)="onDelete([data.id])">
      削除
    </div>
  </div>
</ng-template>
