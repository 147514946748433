/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job-sales-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/sales-list/sales-list.component.ngfactory";
import * as i3 from "../../../../../../shared/components/sales-list/sales-list.component";
import * as i4 from "../../../../../../shared/services/api/sales-api.service";
import * as i5 from "../../../../../../shared/services/dialog.service";
import * as i6 from "../../../../../../shared/pipes/safe-date.pipe";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../shared/pipes/find.pipe";
import * as i9 from "./job-sales-tab.component";
var styles_JobSalesTabComponent = [i0.styles];
var RenderType_JobSalesTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobSalesTabComponent, data: {} });
export { RenderType_JobSalesTabComponent as RenderType_JobSalesTabComponent };
export function View_JobSalesTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-sales-list", [], null, null, null, i2.View_SalesListComponent_0, i2.RenderType_SalesListComponent)), i1.ɵdid(1, 4964352, null, 0, i3.SalesListComponent, [i4.SalesApiService, i5.DialogService, i6.SafeDatePipe, i7.DecimalPipe, i8.FindPipe, i1.ChangeDetectorRef], { requestObject: [0, "requestObject"], options: [1, "options"] }, null), i1.ɵpod(2, { refundDate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requestObject; var currVal_1 = _ck(_v, 2, 0, false); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_JobSalesTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-job-sales-tab", [], null, null, null, View_JobSalesTabComponent_0, RenderType_JobSalesTabComponent)), i1.ɵdid(1, 573440, null, 0, i9.JobSalesTabComponent, [], null, null)], null, null); }
var JobSalesTabComponentNgFactory = i1.ɵccf("ag-job-sales-tab", i9.JobSalesTabComponent, View_JobSalesTabComponent_Host_0, { jobId: "jobId" }, {}, []);
export { JobSalesTabComponentNgFactory as JobSalesTabComponentNgFactory };
