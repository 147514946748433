import * as tslib_1 from "tslib";
import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { getProgressStatusLabel } from '@agent-ds/shared/constants';
import { ContactHistoryAction, } from '@agent-ds/shared/interfaces';
import { Tab } from '@agent-ds/shared/models';
import { FileApiService, StudentApiService, UserApiService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { StudentTabs } from '../student-tabs';
var StudentOverviewTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentOverviewTabComponent, _super);
    function StudentOverviewTabComponent(studentService, masterService, fileService, userService) {
        var _this = _super.call(this) || this;
        _this.studentService = studentService;
        _this.masterService = masterService;
        _this.fileService = fileService;
        _this.userService = userService;
        _this.progresses = [];
        _this.tabs = StudentTabs;
        _this.jobTypes = [];
        _this.industryTypes = [];
        _this.files = [];
        _this.contacts = [];
        _this.progressesMain = [];
        _this.progressesMainPlus = [];
        _this.progressesSub = [];
        _this.progressesSubPlus = [];
        _this.contactAction = ContactHistoryAction;
        _this.getStatusLabel = getProgressStatusLabel;
        return _this;
    }
    StudentOverviewTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.masterService.getFlattenedJobTypes().subscribe(function (jobs) { return (_this.jobTypes = jobs); });
        this.masterService.getFlattenedIndustryTypes().subscribe(function (types) { return (_this.industryTypes = types); });
    };
    StudentOverviewTabComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['student'] && this.student) {
            this.files.length = 0;
            this.contacts.length = 0;
            this.fileService.getFiles('students', this.student.id).subscribe(function (result) { return (_this.files = result); });
            this.studentService.getContacts(this.student.id).subscribe(function (result) { return (_this.contacts = result); });
        }
        if (changes['progresses'] && this.progresses) {
            this.progressesMain = this.progresses.filter(function (prog) { return [60, 70, 80, 90, 100, 110, 120, 130].includes(prog.status); });
            this.progressesMainPlus = this.progressesMain.filter(function (prog) { return prog.type === 1; });
            this.progressesSub = this.progresses.filter(function (prog) { return [100, 110, 120, 130].includes(prog.status); });
            this.progressesSubPlus = this.progressesSub.filter(function (prog) { return prog.type === 1; });
        }
    };
    StudentOverviewTabComponent.prototype.findUserTeam = function (userId) {
        var _this = this;
        return this.userService.getAll().pipe(mergeMap(function (users) {
            var user = users.find(function (u) { return u.id === userId; });
            if (user) {
                return _this.masterService.getTeams().pipe(map(function (teams) {
                    var team = teams.find(function (t) { return t.id === user.teamId; });
                    if (team) {
                        return { userName: user.name, teamName: team.name };
                    }
                    else {
                        return { userName: user.name, teamName: '' };
                    }
                }));
            }
            return null;
        }));
    };
    StudentOverviewTabComponent.prototype.downloadFile = function (file) {
        this.fileService.downloadFile('students', this.student.id, file.id, file.name);
    };
    StudentOverviewTabComponent.prototype.onCompanyLinkClick = function (id) {
        if (id) {
            CompanyDetailPageComponent.instance.referenceId = id;
            CompanyDetailPageComponent.instance.open();
        }
    };
    StudentOverviewTabComponent.prototype.onJobLinkClick = function (id) {
        if (id) {
            JobDetailPageComponent.instance.referenceId = id;
            JobDetailPageComponent.instance.open();
        }
    };
    return StudentOverviewTabComponent;
}(Tab));
export { StudentOverviewTabComponent };
