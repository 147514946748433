<div class="header" *ngIf="!isIntakeCa">
  <span class="title">{{ title }}</span>
  <span class="button__conatiner">
    <button
      *ngIf="shownContent === 1"
      class="btn btn--big btn--white btn--outline-gray"
      (click)="onExportStudentsForSeminarClick()"
    >
      出席予定者一覧(Excel)
    </button>
  </span>
</div>
<div
  class="breadcrumb"
  [class.breadcrumb--white]="shownContent !== 0"
  *ngIf="!isIntakeCa"
>
  <ng-container
    *ngFor="let breadcrumbItem of breadcrumbItems; let breadcrumbIndex = index"
  >
    <span
      class="breadcrumb__item"
      [class.breadcrumb__item--clickable]="breadcrumbItem.action"
      (click)="breadcrumbItem.action && breadcrumbItem.action()"
    >
      {{ breadcrumbItem.name }}
    </span>
    <span
      class="breadcrumb__arrow breadcrumb__arrow--right"
      *ngIf="breadcrumbIndex !== breadcrumbItems.length - 1"
    ></span>
  </ng-container>
</div>
<div class="content" *ngIf="!isIntakeCa">
  <ag-seminar-lister
    *ngIf="shownContent === 0"
    [area]="lastSelectedArea"
  ></ag-seminar-lister>
  <ag-seminar-attendee-list
    *ngIf="shownContent === 1"
  ></ag-seminar-attendee-list>
  <ag-seminar-job-lister *ngIf="shownContent === 2"></ag-seminar-job-lister>
</div>
