import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { FileApiService, MasterApiService, UserApiService } from '@agent-ds/shared/services';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
var StudentUploadsPageComponent = /** @class */ (function () {
    function StudentUploadsPageComponent(fileApiService, cdr, datePipe, userService, masterService) {
        var _this = this;
        this.fileApiService = fileApiService;
        this.cdr = cdr;
        this.datePipe = datePipe;
        this.userService = userService;
        this.masterService = masterService;
        this.initialized = false;
        this.loadUploads = function (page, size) {
            var from = page * size;
            return _this.fileApiService.getUnconfirmedFiles(from, size).pipe(map(function (res) { return _this.convertUploadList(res); }));
        };
    }
    Object.defineProperty(StudentUploadsPageComponent.prototype, "branches", {
        get: function () {
            return this.masterService.getBranches();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudentUploadsPageComponent.prototype, "users", {
        get: function () {
            return this.userService.getAll();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudentUploadsPageComponent.prototype, "teams", {
        get: function () {
            return this.masterService.getTeams();
        },
        enumerable: true,
        configurable: true
    });
    StudentUploadsPageComponent.prototype.ngAfterViewInit = function () {
        this.table.tableConfig = this.getTableConfig();
        this.cdr.detectChanges();
        this.initialized = true;
        this.refresh();
    };
    StudentUploadsPageComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
            this.cdr.detectChanges();
            this.refresh();
        }
    };
    StudentUploadsPageComponent.prototype.refresh = function () {
        if (this.table && this.initialized) {
            this.table.reset(true);
            this.table.next();
        }
    };
    StudentUploadsPageComponent.prototype.onItemSelected = function (item) {
        StudentDetailPageComponent.instance.referenceId = item ? item.studentId : null;
        StudentDetailPageComponent.instance.open();
    };
    StudentUploadsPageComponent.prototype.convertUploadList = function (uploads) {
        return {
            totalSize: uploads.totalSize,
            result: uploads.files,
        };
    };
    StudentUploadsPageComponent.prototype.getTableConfig = function () {
        var _this = this;
        return {
            head: {
                sticky: true,
                config: [
                    {
                        columns: [
                            {
                                fields: [
                                    {
                                        name: 'createdAt',
                                        title: '登録日時',
                                        sortable: false,
                                        formatter: function (data) { return _this.datePipe.transform(data.createdAt, 'yyyy/MM/dd (E) H:m'); },
                                    },
                                ],
                                style: {
                                    width: '170px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'studentId',
                                        title: '求職者ID',
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    width: '70px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'name',
                                        title: 'ファイル名',
                                        sortable: false,
                                    },
                                ],
                                style: {
                                    width: '320px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'name',
                                        title: '支援拠点',
                                        sortable: false,
                                        cellTemplate: this.branchTemplate,
                                    },
                                ],
                                style: {
                                    width: '70px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'name',
                                        title: '担当者',
                                        sortable: false,
                                        cellTemplate: this.inChargeTemplate,
                                    },
                                ],
                                style: {
                                    width: '240px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'size',
                                        title: 'サイズ',
                                        sortable: false,
                                        formatter: function (data) { return Math.round(data.size / 1000) + "KB"; },
                                    },
                                ],
                                style: {
                                    width: '70px',
                                },
                            },
                            {
                                fields: [
                                    {
                                        name: 'type',
                                        title: '種類',
                                        sortable: false,
                                        formatter: function (data) { return data.name.split('.').pop(); },
                                    },
                                ],
                                style: {
                                    width: '50px',
                                },
                            },
                        ],
                    },
                ],
            },
            body: {
                checkbox: false,
            },
        };
    };
    return StudentUploadsPageComponent;
}());
export { StudentUploadsPageComponent };
