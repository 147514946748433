import * as tslib_1 from "tslib";
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { Tab } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
var StudentFilesTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentFilesTabComponent, _super);
    function StudentFilesTabComponent(studentApiService, dialogService) {
        var _this = _super.call(this) || this;
        _this.studentApiService = studentApiService;
        _this.dialogService = dialogService;
        _this.files = [];
        _this.jobFiles = [];
        return _this;
    }
    StudentFilesTabComponent.prototype.ngOnInit = function () { };
    StudentFilesTabComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['student']) {
            this.jobFiles = [];
            this.studentId = this.student ? this.student.id : null;
            if (this.studentId) {
                this.studentApiService.getJobFiles(this.studentId).subscribe(function (files) {
                    _this.jobFiles = files;
                }, function (error) {
                    console.error('getJobFiles error', error);
                    if (!error.status) {
                        // ネットワークに繋がらなかったときはエラーを通知する
                        _this.showErrorDialog();
                        _this.studentApiService.notifyConnectionErrorEvent.emit();
                    }
                    else {
                        // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
                    }
                });
            }
        }
    };
    StudentFilesTabComponent.prototype.downloadJobFile = function (file) {
        this.studentApiService.downloadJobFile(this.studentId, file.id);
    };
    StudentFilesTabComponent.prototype.showErrorDialog = function () {
        var dialogConfig = ConfirmDialogConfig.createStudentApiErrorDialogConfig([
            'データフォルダ求人履歴管理の取得中に通信エラーが発生しました。',
            '画面上部右側の「求職者の全情報を再取得する」ボタンを押して再度取得してください。',
        ]);
        this.dialogService.open(ConfirmDialogComponent, dialogConfig);
    };
    return StudentFilesTabComponent;
}(Tab));
export { StudentFilesTabComponent };
