/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kpi-setup-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "./kpi-setup-dialog.component";
import * as i8 from "../../../shared/components/dialog/dialog-ref";
import * as i9 from "../../../shared/components/dialog/dialog-config";
import * as i10 from "../../../shared/services/api/dynamic-field.service";
var styles_KpiSetupDialogComponent = [i0.styles];
var RenderType_KpiSetupDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KpiSetupDialogComponent, data: {} });
export { RenderType_KpiSetupDialogComponent as RenderType_KpiSetupDialogComponent };
export function View_KpiSetupDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u76EE\u6A19\u8A2D\u5B9A"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "dialog__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ag-dynamic-form", [["class", "content__column"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(8, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "ag-dynamic-form", [["class", "content__column"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(10, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "dialog__footer__button btn btn--outline-blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "dialog__footer__button btn btn--blue"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.update() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u767B\u9332 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataLeft; var currVal_1 = _co.config.data.model; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_2 = _co.metadataRight; var currVal_3 = _co.config.data.model; _ck(_v, 10, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.updating; _ck(_v, 14, 0, currVal_4); }); }
export function View_KpiSetupDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-kpi-setup-dialog", [], null, null, null, View_KpiSetupDialogComponent_0, RenderType_KpiSetupDialogComponent)), i1.ɵdid(1, 245760, null, 0, i7.KpiSetupDialogComponent, [i8.DialogRef, i9.DialogConfig, i10.DynamicFieldService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KpiSetupDialogComponentNgFactory = i1.ɵccf("ag-kpi-setup-dialog", i7.KpiSetupDialogComponent, View_KpiSetupDialogComponent_Host_0, {}, {}, []);
export { KpiSetupDialogComponentNgFactory as KpiSetupDialogComponentNgFactory };
