import * as tslib_1 from "tslib";
import { DashboardType } from '@agent-ds/shared/interfaces';
import { DetailPage } from '@agent-ds/shared/models/detail-page';
import { AuthService, CompanyApiService, JobApiService, ProgressApiService, SalesApiService } from '@agent-ds/shared/services';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobTabs, JobTabsForIntakeCa } from './tabs/job-tabs.enum';
var JobDetailPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(JobDetailPageComponent, _super);
    function JobDetailPageComponent(jobApiService, activeRoute, router, progressApiService, companyApiService, salesApiService, authService) {
        var _this = _super.call(this, router, jobApiService, activeRoute) || this;
        _this.jobApiService = jobApiService;
        _this.activeRoute = activeRoute;
        _this.router = router;
        _this.progressApiService = progressApiService;
        _this.companyApiService = companyApiService;
        _this.salesApiService = salesApiService;
        _this.authService = authService;
        _this.sideActions = [{ label: '詳細', phases: ['1030px', '100%'] }, { label: '担当者', phases: ['450px'] }];
        _this.tabs = JobTabs;
        _this.urlTag = 'jobs';
        _this.memos = [];
        _this.sales = [];
        _this.contracts = [];
        _this.progresses = [];
        _this.isIntakeCa = false;
        _this.jobDetailLoaded = new EventEmitter();
        return _this;
    }
    JobDetailPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginSubscription = this.authService.isLogined().subscribe(function (logined) {
            // 副担当者かどうかを確認
            _this.isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
            // 権限を見て、タブの一覧を設定
            _this.tabs = _this.isIntakeCa ? JobTabsForIntakeCa : JobTabs;
            // 副担当者かどうかを確認してから初期化
            _super.prototype.ngOnInit.call(_this);
        });
    };
    JobDetailPageComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
    };
    JobDetailPageComponent.prototype.fill = function (onRefresh) {
        var _this = this;
        if (this.referenceId) {
            this.updateUrl();
            this.jobApiService.getDetail(this.referenceId).subscribe(function (job) {
                _this.job = job;
                if (!_this.readonly) {
                    _this.companyApiService.getDetail(_this.job.enterpriseId).subscribe(function (company) { return (_this.company = company); });
                }
                if (!onRefresh) {
                    _this.jobDetailLoaded.emit(job);
                }
            });
            this.jobApiService.getMemos(this.referenceId, 'updatedAt', 'desc').subscribe(function (memos) { return (_this.memos = memos); });
            // インテークCAの場合は、概要に進捗と成約を表示しないので、APIを実行しない
            if (this.isIntakeCa) {
                return;
            }
            this.progressApiService
                .getList({ jobId: [this.referenceId], sort: 'updatedAt', order: 'desc', from: 0, size: 100 })
                .subscribe(function (result) { return (_this.progresses = result.progresses || []); });
            this.salesApiService
                .getList({ jobId: [this.referenceId], sort: 'updatedAt', order: 'desc', from: 0, size: 100 })
                .subscribe(function (result) { return (_this.sales = result.saleses || []); });
        }
    };
    return JobDetailPageComponent;
}(DetailPage));
export { JobDetailPageComponent };
