import * as tslib_1 from "tslib";
import { mergeMap, tap } from 'rxjs/operators';
import { MailPopupType } from '../config';
import { StudentDetailConfig } from './student-detail-config';
var ProgressSendResumeConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressSendResumeConfig, _super);
    function ProgressSendResumeConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressSendResumeConfig.prototype.getPopupConfig = function () {
        return [{ type: MailPopupType.ENTERPRISE_RESUME_PASSWORD_CHECK }];
    };
    ProgressSendResumeConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.jobs = params.jobs || this.sender.model.jobs;
            this.sender.model.enterpriseId = params.enterpriseId || this.sender.model.enterpriseId;
            this.sender.model.progressId = params.progressId;
            this.sender.model.progressType = params.progressType;
            this.sender.model.progressN = params.progressN;
            this.sender.model.progressSeminarType = params.progressSeminarType;
            this.sender.model.progressSeminarAt = params.progressSeminarAt;
            this.sender.model.progressSeminarAtMailSendFlag = params.progressSeminarAtMailSendFlag;
            this.sender.model.progressIsStudentDelay = params.progressIsStudentDelay;
            this.sender.model.progressHasInterview = params.progressHasInterview;
        }
        _super.prototype.setParams.call(this, params);
    };
    ProgressSendResumeConfig.prototype.save = function (toSave) {
        var _this = this;
        if (this.sender.model.progressType != null) {
            toSave.jobId = this.sender.model.jobs.map(function (j) { return j.id; })[0];
            toSave.studentId = this.sender.model.studentId;
            toSave.updateProgress = false;
            this.sender.mailApiService
                .sendEnterpriseResumeMail(this.sender.model.enterpriseId, this.sender.model.departmentId, toSave)
                .pipe(mergeMap(function (result) {
                return _this.sender.progressApiService
                    .updateWithAction(_this.sender.model.progressId, {
                    action: 54,
                    type: _this.sender.model.progressType,
                    hasInterview: _this.sender.model.progressHasInterview,
                    n: _this.sender.model.progressN,
                    seminarType: _this.sender.model.progressSeminarType,
                    seminarAt: _this.sender.model.progressSeminarAt,
                    seminarAtMailSendFlag: _this.sender.model.progressSeminarAtMailSendFlag,
                    isStudentDelay: _this.sender.model.progressIsStudentDelay,
                })
                    .pipe(tap(function () { return _this.sender.afterSend(result); }));
            }))
                .subscribe();
        }
        else {
            _super.prototype.save.call(this, toSave);
        }
    };
    return ProgressSendResumeConfig;
}(StudentDetailConfig));
export { ProgressSendResumeConfig };
