import * as tslib_1 from "tslib";
import { MailPopupType } from '../config';
import { JobPostingConfig } from './job-posting-config';
var ProgressJobPostingConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressJobPostingConfig, _super);
    function ProgressJobPostingConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressJobPostingConfig.prototype.getPopupConfig = function () {
        return [{ type: MailPopupType.JOB_CHECK }];
    };
    return ProgressJobPostingConfig;
}(JobPostingConfig));
export { ProgressJobPostingConfig };
