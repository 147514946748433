/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./segs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./segs.component";
import * as i4 from "../../services/api/master-api.service";
var styles_SegsComponent = [i0.styles];
var RenderType_SegsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SegsComponent, data: {} });
export { RenderType_SegsComponent as RenderType_SegsComponent };
function View_SegsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "segs-item segs-item-white segs-item-mr20"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "segs-item segs-item-white segs-item-mr20"; var currVal_1 = _co.classMap[_co.companyClassification]; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.companyClassification; _ck(_v, 3, 0, currVal_2); }); }
function View_SegsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "segs-item segs-item-white"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", "", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "segs-item segs-item-white"; var currVal_1 = _co.classMapSeg[_co.segs8LastYear]; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.lastYear; var currVal_3 = _co.segs8LastYear; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_SegsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "segs-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2192"]))], null, null); }
function View_SegsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "segs-item segs-item-white"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", "", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "segs-item segs-item-white"; var currVal_1 = _co.classMapSeg[_co.segs8CurrentYear]; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.currentYear; var currVal_3 = _co.segs8CurrentYear; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_SegsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "content__segs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SegsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SegsComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SegsComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SegsComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyClassification; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.segs8LastYear; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.segs8LastYear && _co.segs8CurrentYear); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.segs8CurrentYear; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_SegsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-segs", [], null, null, null, View_SegsComponent_0, RenderType_SegsComponent)), i1.ɵdid(1, 114688, null, 0, i3.SegsComponent, [i4.MasterApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SegsComponentNgFactory = i1.ɵccf("ag-segs", i3.SegsComponent, View_SegsComponent_Host_0, { companyClassification: "companyClassification", segs8LastYear: "segs8LastYear", segs8CurrentYear: "segs8CurrentYear" }, {}, []);
export { SegsComponentNgFactory as SegsComponentNgFactory };
