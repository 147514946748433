<div class="header">
  <div class="header__title">部署一覧</div>
  <div class="controls">
    <div class="checkbox-wrapper">
      <input
        class="checkbox-wrapper__input"
        id="show_abolished_departments"
        type="checkbox"
        [ngModel]="activeFilter"
        (ngModelChange)="onFilterChange($event)"
      />
      <label class="checkbox-wrapper__label" for="show_abolished_departments"
        >廃止になった部署も表示</label
      >
    </div>
    <button
      class="controls__btn btn btn--green btn--larger"
      (click)="openDepartmentCreateDialog()"
    >
      新規部署登録
    </button>
  </div>
</div>

<ag-page-scroll-table
  *ngIf="departments"
  #departmentTable
  class="table"
  [tableConfig]="departmentsTableConfig"
  [content]="filteredDepartments"
  [scrollerClass]="'no-scroll'"
></ag-page-scroll-table>

<ng-template #actions let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="editDepartment(data)"
    >
      編集
    </button>
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="createJob(data)"
    >
      新規求人登録
    </button>
  </div>
</ng-template>

<ng-template #actionsBottom let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="editListSend(data)"
    >
      リスト送付設定
    </button>
  </div>
</ng-template>

<ng-template #billingActions let-data>
  <div class="actions" (click)="$event.stopPropagation()">
    <button
      class="actions__action btn btn--small btn--white btn--outline-gray"
      (click)="editBillingAddress(data)"
    >
      編集
    </button>
  </div>
</ng-template>

<ng-template #status let-data>
  <div *ngIf="data.dynamicData.status === '廃止'" class="status-badge">廃</div>
</ng-template>

<div class="header">
  <div class="header__title">請求先一覧</div>
  <div class="controls">
    <div class="checkbox-wrapper">
      <input
        class="checkbox-wrapper__input"
        id="show_billing_address"
        type="checkbox"
        [ngModel]="activeBllingAddressFilter"
        (ngModelChange)="onFilterChangeBillingAddress($event)"
      />
      <label class="checkbox-wrapper__label" for="show_billing_address"
        >無効な請求先を表示</label
      >
    </div>
    <button
      class="btn btn--green btn--larger"
      (click)="openBillingAddressCreateDialog()"
    >
      新規請求先登録
    </button>
  </div>
</div>

<ag-page-scroll-table
  *ngIf="billingAddresses"
  #billingAddressesTable
  class="table"
  [tableConfig]="billingAddressesTableConfig"
  [content]="filteredBillingAddresses"
  [scrollerClass]="'no-scroll'"
></ag-page-scroll-table>
