<div class="status">
  <ag-dynamic-form
    class="row__head-form"
    [metadata]="metadataHead"
    [model]="company"
    *ngIf="metadataHead && company"
  ></ag-dynamic-form>
</div>

<div class="block--leftCol">
  <div class="block block--general">
    <div class="block__head">
      <div class="block__title">企業概要</div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.DETAIL)">
        編集
      </div>
    </div>
    <div class="block__body block__body--general">
      <div class="block__row block__row--first block__row--light">企業名</div>
      <div class="block__row block__row--content block__row--light">
        {{ company?.dynamicData?.name }}
      </div>

      <div class="block__row block__row--first block__row--light">
        企業名カナ
      </div>
      <div class="block__row block__row--content block__row--light">
        {{ company?.dynamicData?.phoneticName }}
      </div>

      <div class="block__row block__row--first block__row--light">業種</div>
      <div class="block__row block__row--content block__row--light">
        {{
          (
            (masterApiService.getFlattenedIndustryTypes()
              | async
              | find: company?.dynamicData?.industry:"code") || { name: "" }
          ).name
        }}
      </div>

      <div class="block__row block__row--first block__row--light">資本金</div>
      <div class="block__row block__row--content block__row--light">
        {{
          company?.dynamicData?.capital != null
            ? (company?.dynamicData?.capital | number: "0.0-2") +
              capitalDef?.unit
            : "-"
        }}
      </div>

      <div class="block__row block__row--first block__row--light">株式公開</div>
      <div class="block__row block__row--content block__row--light">
        {{ company?.dynamicData?.marketName }}
      </div>

      <div class="block__row block__row--first block__row--light">企業URL</div>
      <div class="block__row block__row--content block__row--light">
        <a class="link" [href]="company?.dynamicData?.url" target="blank">{{
          company?.dynamicData?.url
        }}</a>
      </div>
    </div>
  </div>
  <div class="block block--jobs">
    <div class="block__head">
      <div class="block__title">求人一覧</div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.JOBS)">
        一覧
      </div>
    </div>

    <div class="block__body block__body--jobs">
      <div class="block__row block__row--first">
        <div class="block__row-header">求人ID</div>
        <div class="block__row-header">ポジション名</div>
        <div class="block__row-header">担当RA/PA</div>
      </div>
      <div class="block__row block__row--align-right">
        <div class="block__horizontal-list">
          <div class="block__row-header">ステータス</div>
          <div class="block__row-header block__row-header--pl">勤務地</div>
        </div>
      </div>

      <ng-container *ngFor="let job of jobs">
        <div class="job-block job-block--full">
          <div
            class="block__row block__row--first job-info job-block--no-border"
          >
            <div class="job-info__id">{{ job.frontJobId }}</div>
            <div class="job-info__name" (click)="selectedJobId.emit(job.id)">
              {{ job.dynamicData?.position }}
            </div>
          </div>

          <div
            class="block__row block__row--no-padding block__row--align-top job-block--no-border"
          >
            <div class="block__horizontal-list">
              <div class="block__horizontal-item">
                {{ job.dynamicData?.status }}
              </div>
              <div class="block__horizontal-item">
                {{ job.dynamicData?.workplace?.prefecture }}
              </div>
            </div>
          </div>

          <div class="block__row block__row--first job-info job-block--full">
            <div
              class="job-info__status"
              *ngFor="let deptUser of job.enterpriseDepartmentUsers"
            >
              <ng-container
                *ngIf="
                  userTeamWithJobDepType(deptUser.userId, deptUser.type)
                    | async as userInfo
                "
              >
                <div
                  class="job-info__item job-info__badge badge"
                  [agClass]="jobDepartmentUserType[deptUser.type]"
                  [classOptions]="RAPACA_VALUE_BADGE_CLASSES"
                >
                  {{ jobDepartmentUserType[deptUser.type] }}
                </div>
                <div class="job-info__item">{{ userInfo.name }}</div>
                <div class="job-info__item job-info__status--light">
                  {{ userInfo.teamName }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="block--rightCol">
  <div class="block block--correspondence">
    <div class="block__head">
      <div class="block__title">
        対応履歴
        <div
          class="block__subtitle block__subtitle--small block__subtitle--light"
        >
          ※最新3件を表示
        </div>
      </div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.CONTACTS)">
        一覧
      </div>
    </div>
    <div class="block__body block__body--correspondence">
      <div class="block__row block__row--first">対応日</div>
      <div class="block__row">アクション</div>
      <div class="block__row">担当者</div>
      <!-- TODO: sort contactHistory and limit result set -->
      <ng-container *ngFor="let contact of contactHistory?.slice(0, 3)">
        <div
          class="block__row block__row--no-border block__row--first block__row--light block__row--small"
        >
          {{ contact.actionAt | safeDate: "yyyy/MM/dd (E) HH:ss" }}
        </div>
        <div
          class="block__row block__row--no-border block__row--medium block__row--bold"
        >
          {{ contactAction[contact.action] }}
        </div>
        <div class="block__row block__row--no-border block__row--medium">
          {{ userTeamProvider.getUserTeamNameById(contact.userId) | async }}
        </div>
        <div class="block__row block__row--first block__row--correspondence">
          {{ contact.message }}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="block block--inner-info">
    <div class="block__head">
      <div class="block__title">INNER情報</div>
      <div class="block__control" (click)="gotoIndex.emit(tabs.MEMO)">
        一覧
      </div>
    </div>
    <div class="block__body block__body--inner-info">
      <div class="block__row block__row--first">入力日</div>
      <div class="block__row">入力者</div>

      <ng-container *ngFor="let info of innerInfo">
        <div
          class="block__row block__row--no-border block__row--medium block__row--first"
        >
          {{ info?.registerDate | safeDate: "yyyy/MM/dd (E)" }}
        </div>
        <div class="block__row block__row--no-border block__row--medium">
          {{ userTeamProvider.getUserTeamNameById(info.userId) | async }}
        </div>
        <div class="block__row block__row--first block__row--inner-info">
          {{ info?.innerMemo }}
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="block block--sales" *ngIf="!isIntakeCa">
  <div class="block__head">
    <div class="block__title fit">成約一覧</div>
    <div class="block__control fit" (click)="gotoIndex.emit(tabs.SALES)">
      一覧
    </div>
  </div>
  <div class="block__body block__body--sales">
    <div class="block__row"></div>
    <div class="block__row fit">成約ID</div>
    <div class="block__row fit">担当者</div>
    <div class="block__row fit">{{ "成約日\n入社日" }}</div>
    <div class="block__row block__row--align-center fit">フィー</div>
    <div class="block__row fit">承認区分</div>
    <div class="block__row fit">{{ "キャンセル日\nキャンセル承認区分" }}</div>

    <ng-container *ngFor="let sale of sales">
      <div
        class="block__row block__row--no-border block__row--no-padding block__row--align-top"
      >
        <div
          class="block__badge-label badge badge--blue-light badge--rectangular"
        >
          AG
        </div>
      </div>
      <div class="block__row block__row--no-border">{{ sale?.frontId }}</div>
      <div
        class="block__row block__row--no-border block__row--align-top block__row--align-center block__list"
      >
        <ng-container *ngFor="let person of sale?.users">
          <div class="block__list-item" *ngIf="person?.name">
            <div
              class="badge"
              [agClass]="person.area"
              [classOptions]="RAPACA_VALUE_BADGE_CLASSES"
            >
              {{ person.area }}
            </div>
            {{ person.name }} {{ person.teamName }}
          </div>
        </ng-container>
      </div>
      <div
        class="block__row block__row--no-border block__row--align-top block__row--align-center block__list"
      >
        <div class="block__list-item">
          {{ sale?.dynamicData?.contractDate }}
        </div>
        <div class="block__list-item">{{ sale?.dynamicData?.enterDate }}</div>
      </div>
      <div class="block__row block__row--no-border block__row--align-center">
        {{ sale.dynamicData?.fee | number: "0.0-2" }}
        {{ sale.dynamicData?.fee ? "円" : "" }}
      </div>
      <div class="block__row block__row--no-border">
        {{ (APPROVAL_STATES | find: sale.status:"code")?.name || "-" }}
      </div>
      <div
        class="block__row block__row--no-border block__row--align-top block__row--align-center block__list"
      >
        <div class="blocl__list-item">
          {{ sale?.cancelApprovedDate | safeDate: "yyyy/MM/dd" }}
        </div>
        <div class="blocl__list-item">
          {{
            (APPROVAL_CANCEL_STATES | find: sale.cancelStatus:"code")?.name ||
              "-"
          }}
        </div>
      </div>
      <div class="block__row block__row--no-padding-top"></div>
      <div class="block__row block__row--no-padding-top block__row--sales-jobs">
        <div class="block__row--light fit">求人:</div>
        <div
          class="block__row--blue link"
          (click)="onJobLinkClick(sale?.jobId)"
        >
          {{ sale?.jobFrontId }}-{{ sale?.jobPosition }}
        </div>
      </div>
      <div
        class="block__row block__row--no-padding-top block__row--sales-job-seeker"
      >
        <div class="block__row--light fit">求職者:</div>
        <div
          class="block__row--blue link"
          (click)="onStudentLinkClick(sale?.student?.id)"
        >
          {{ sale?.student?.frontId }}-
          {{ sale?.student?.dynamicData?.lastName }}
          {{ sale?.student?.dynamicData?.firstName }}
          {{ sale?.student?.dynamicData?.academicFieldType }}
        </div>
        <div
          class="badge"
          *ngIf="sale?.student?.dynamicData?.academicFieldType"
          [class.badge--pink]="
            sale.student.dynamicData.academicFieldType[0] === '文'
          "
          [class.badge--blue]="
            sale.student.dynamicData.academicFieldType[0] === '理'
          "
        >
          {{ sale.student.dynamicData.academicFieldType[0] }}
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="block block--contracts" *ngIf="!isIntakeCa">
  <div class="block__head block__head--contracts">
    <div class="block__title">
      契約・覚書
      <div class="block__subtitle">
        有効のものだけ表示　更新日の最新5件を表示
      </div>
    </div>
    <div class="block__control" (click)="gotoIndex.emit(tabs.CONTRACTS)">
      一覧
    </div>
  </div>
  <div class="block__body block__body--contracts">
    <div class="block__row block__row--first-p">契約ID</div>
    <div class="block__row">契約先企業名</div>
    <div class="block__row">契約先部署名</div>
    <div class="block__row">契約区分</div>
    <div class="block__row">固定額</div>
    <div class="block__row">締結日</div>
    <div class="block__row">契約種別</div>

    <ng-container
      *ngFor="
        let contract of (
          contracts | filter: '無効':'dynamicData.status':true
        ).slice(0, 5)
      "
    >
      <div
        class="block__row block__row--large block__row--first-p block__row--lighter"
      >
        {{ contract?.frontId }}
      </div>
      <div
        class="block__row block__row--large block__row--blue block__row--link"
        (click)="openCompanyContractEditDialog(contract)"
      >
        {{ contract?.dynamicData?.companyName }}
      </div>
      <div class="block__row block__row--large">
        {{ contract?.dynamicData?.departmentName }}
      </div>
      <div class="block__row block__row--large">
        {{ contract?.dynamicData?.approvalClassification }}
      </div>
      <div class="block__row block__row--large">
        {{ contract?.dynamicData?.fee1Price | number: "0.0-2"
        }}{{ contract?.dynamicData?.fee1Price ? "円" : "" }}
        {{ contract?.dynamicData?.fee2Price | number: "0.0-2"
        }}{{ contract?.dynamicData?.fee2Price ? "円" : "" }}
      </div>
      <div class="block__row block__row--large">
        {{ contract?.dynamicData?.signedDate }}
      </div>
      <div class="block__row block__row--large">
        {{ contract?.dynamicData?.contractCategory }}
      </div>
    </ng-container>
  </div>
</div>
