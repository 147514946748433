/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload-area.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-upload-area.component";
var styles_FileUploadAreaComponent = [i0.styles];
var RenderType_FileUploadAreaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadAreaComponent, data: {} });
export { RenderType_FileUploadAreaComponent as RenderType_FileUploadAreaComponent };
export function View_FileUploadAreaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "upload-area-wrapper"]], [[2, "upload-area-wrapper--dragactive", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["fileinput", 1]], null, 0, "input", [["class", "upload-area-wrapper__input"], ["multiple", ""], ["type", "file"]], null, [[null, "dragenter"], [null, "dragleave"], [null, "blur"], [null, "drop"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragenter" === en)) {
        var pd_0 = (_co.setInputDrag(true) !== false);
        ad = (pd_0 && ad);
    } if (("dragleave" === en)) {
        var pd_1 = (_co.setInputDrag(false) !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (_co.setInputDrag(false) !== false);
        ad = (pd_2 && ad);
    } if (("drop" === en)) {
        var pd_3 = (_co.setInputDrag(false) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (_co.onInputChanged($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "upload-area-wrapper__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u3053\u3053\u306B\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3057\u305F\u3044\u30D5\u30A1\u30A4\u30EB\u3092\u30C9\u30ED\u30C3\u30D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u307E\u305F\u306F"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "upload-area-wrapper__button btn btn--outline-gray btn--white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30D5\u30A1\u30A4\u30EB\u3092\u9078\u629E "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dragActive; _ck(_v, 0, 0, currVal_0); }); }
export function View_FileUploadAreaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-file-upload-area", [], null, null, null, View_FileUploadAreaComponent_0, RenderType_FileUploadAreaComponent)), i1.ɵdid(1, 114688, null, 0, i2.FileUploadAreaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadAreaComponentNgFactory = i1.ɵccf("ag-file-upload-area", i2.FileUploadAreaComponent, View_FileUploadAreaComponent_Host_0, {}, { filesChanged: "filesChanged" }, []);
export { FileUploadAreaComponentNgFactory as FileUploadAreaComponentNgFactory };
