import * as tslib_1 from "tslib";
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ApprovalClassification } from '@agent-ds/shared/interfaces';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { deepClone, getValueFromObject } from '@agent-ds/shared/util/util';
import { OnDestroy, OnInit } from '@angular/core';
var CompanyContractCreateDialogComponent = /** @class */ (function () {
    function CompanyContractCreateDialogComponent(dialog, config, dynamicService) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.ApprovalClassification = ApprovalClassification;
        this.departmentDynamicList = [];
        this.validityArray = [false, true, true];
        this.valid = false;
    }
    Object.defineProperty(CompanyContractCreateDialogComponent.prototype, "approvalClassification", {
        get: function () {
            return getValueFromObject(this.model, 'dynamicData.approvalClassification');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyContractCreateDialogComponent.prototype, "actions", {
        get: function () {
            var _this = this;
            switch (this.approvalClassification) {
                case ApprovalClassification.UNAPPROVED:
                    return [
                        { label: 'キャンセル', action: function () { return _this.cancel(); }, type: 'accent' },
                        { label: '更新', action: function () { return _this.saveApplication(); }, type: 'primary' },
                        { label: '申請', action: function () { return _this.applyApplication(); }, type: 'primary' },
                    ];
                case ApprovalClassification.WAITING_FOR_RE_APPLICATION:
                    return [
                        { label: 'キャンセル', action: function () { return _this.cancel(); }, type: 'accent' },
                        { label: '保存', action: function () { return _this.saveApplication(); }, type: 'primary' },
                        { label: '再申請', action: function () { return _this.reApplyApplication(); }, type: 'primary' },
                    ];
                case ApprovalClassification.APPROVAL_PENDING:
                    return [
                        { label: 'キャンセル', action: function () { return _this.cancel(); }, type: 'accent' },
                        { label: '申請取消', action: function () { return _this.cancelApplication(); }, type: 'primary' },
                        { label: '承認', action: function () { return _this.approveApplication(); }, type: 'primary' },
                        { label: '否認', action: function () { return _this.denyApplication(); }, type: 'primary' },
                    ];
                case ApprovalClassification.APPROVED:
                    return [
                        { label: 'キャンセル', action: function () { return _this.cancel(); }, type: 'accent' },
                        { label: '承認取消', action: function () { return _this.denyApplication(); }, type: 'primary' },
                    ];
                default:
                    return [
                        { label: 'キャンセル', action: function () { return _this.cancel(); }, type: 'accent' },
                        { label: '登録', action: function () { return _this.saveApplication(); }, type: 'primary' },
                    ];
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyContractCreateDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () {
            var action = _this.actions.filter(function (a) { return a.type === 'primary'; }).last();
            if (action && _this.valid && !_this.dialog.inClose) {
                action.action();
            }
        });
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.model = _this.config.data.contract.dynamicData
                ? tslib_1.__assign({}, _this.config.data.contract, { dynamicData: tslib_1.__assign({}, _this.config.data.contract.dynamicData, { address: tslib_1.__assign({}, _this.config.data.contract.dynamicData.address), contact: tslib_1.__assign({}, _this.config.data.contract.dynamicData.contact) }) }) : { dynamicData: {} };
            _this.departmentDynamicList = _this.config.data.departmentList.map(function (dep) { return dep.dynamicData; });
            _this.initForm();
        });
    };
    CompanyContractCreateDialogComponent.prototype.initForm = function () {
        var _this = this;
        var disabled = this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVED ||
            this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVAL_PENDING;
        this.metadata1 = {
            disabled: disabled,
            groups: [
                {
                    title: '企業情報',
                    class: 'form__group--red-title-border',
                    rows: [
                        {
                            title: '企業ID',
                            fields: [
                                {
                                    name: 'frontId',
                                    type: 'label',
                                    supplier: function () { return _this.config.data.company.frontId; },
                                },
                            ],
                        },
                        {
                            title: '企業名',
                            fields: [
                                {
                                    name: 'name',
                                    type: 'label',
                                    supplier: function () { return _this.config.data.company.dynamicData.name; },
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '契約情報',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows({
                        fieldName: 'teamId',
                        fieldType: 'team',
                        label: '担当チーム',
                        validationStyle: { required: true },
                    }, null, 'fill').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contractClassification'), 'dynamicData', 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contractCategory'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'signedDate'), 'dynamicData'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'expireDate'), 'dynamicData'), (function () {
                        var ret = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('contract', 'paymentCutoffDay'), 'dynamicData', 'fill').concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('contract', 'paymentDateMonth'), 'dynamicData', 'fill'), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('contract', 'paymentDateDay'), 'dynamicData', 'fill'), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('contract', 'paymentSchedule'), 'dynamicData'));
                        if (disabled) {
                            ret.forEach(function (r) { return (r.fields[0].type = 'label'); });
                        }
                        return ret;
                    })()),
                },
                {
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'status'), 'dynamicData').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contractType'), 'dynamicData')),
                },
            ],
        };
        this.metadata2 = {
            disabled: disabled,
            groups: [
                {
                    title: '契約先情報',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'companyName'), null, 'fill').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'phoneticCompanyName'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'departmentName'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contact')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'address'))),
                },
            ],
        };
        this.metadata3 = {
            disabled: disabled,
            groups: [
                {
                    title: 'フィー',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee1Price'), null, 'quarter').concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee1Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee2Price'), null, 'quarter'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee2Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee3Price'), null, 'quarter'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee3Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee4Price'), null, 'quarter'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee4Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee5Price'), null, 'quarter'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'fee5Remarks'), null, 'fill')),
                },
                {
                    title: '返金',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return1Period')).concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return1Percent')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return1Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return2Period')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return2Percent')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return2Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return3Period')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return3Percent')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return3Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return4Period')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return4Percent')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return4Remarks'), null, 'fill'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return5Period')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return5Percent')), this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'return5Remarks'), null, 'fill')),
                },
                {
                    title: '備考',
                    class: 'form__group--red-title-border',
                    rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'contractRemarks')).concat(this.dynamicService.getFormRows(this.dynamicService.getDefinition('contract', 'paymentRemarks'))),
                },
            ],
        };
    };
    CompanyContractCreateDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
        }
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    CompanyContractCreateDialogComponent.prototype.onDepartmentSelect = function (dep) {
        this.model.dynamicData.companyName = this.config.data.company.dynamicData.name;
        this.model.dynamicData.phoneticCompanyName = this.config.data.company.dynamicData.phoneticName;
        this.model.dynamicData.departmentName = dep.name;
        this.model.dynamicData.contact = tslib_1.__assign({}, dep.contact1);
        this.model.dynamicData.address = tslib_1.__assign({}, dep.address);
    };
    CompanyContractCreateDialogComponent.prototype.onValidityChange = function (index, validity) {
        this.validityArray[index] = validity;
        this.valid =
            this.validityArray.find(function (v) { return !v; }) == null ||
                this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVED ||
                this.model.dynamicData.approvalClassification === ApprovalClassification.APPROVAL_PENDING;
    };
    CompanyContractCreateDialogComponent.prototype.cancel = function () {
        this.close();
    };
    CompanyContractCreateDialogComponent.prototype.close = function (params) {
        var _this = this;
        if (params && params.dynamicData.contractType === 'まき直し') {
            PopupControlComponent.instance.open({
                title: 'まき直し契約の確認',
                content: '過去の契約を無効にしてください',
                cancelText: 'いいえ',
                confirmText: 'はい',
                confirmCallback: function () { return _this.dialog.close(params); },
            });
        }
        else {
            this.dialog.close(params);
        }
    };
    CompanyContractCreateDialogComponent.prototype.applyApplication = function () {
        var obj = deepClone(this.model);
        obj.dynamicData.approvalClassification = ApprovalClassification.APPROVAL_PENDING;
        this.close(obj);
    };
    CompanyContractCreateDialogComponent.prototype.cancelApplication = function () {
        var obj = deepClone(this.model);
        obj.dynamicData.approvalClassification = ApprovalClassification.UNAPPROVED;
        this.close(obj);
    };
    CompanyContractCreateDialogComponent.prototype.approveApplication = function () {
        var obj = deepClone(this.model);
        obj.dynamicData.approvalClassification = ApprovalClassification.APPROVED;
        this.close(obj);
    };
    CompanyContractCreateDialogComponent.prototype.denyApplication = function () {
        var obj = deepClone(this.model);
        obj.dynamicData.approvalClassification = ApprovalClassification.WAITING_FOR_RE_APPLICATION;
        this.close(obj);
    };
    CompanyContractCreateDialogComponent.prototype.saveApplication = function () {
        var obj = deepClone(this.model);
        obj.dynamicData.approvalClassification = this.model.dynamicData.approvalClassification || ApprovalClassification.UNAPPROVED;
        this.close(obj);
    };
    CompanyContractCreateDialogComponent.prototype.reApplyApplication = function () {
        var obj = deepClone(this.model);
        obj.dynamicData.approvalClassification = ApprovalClassification.APPROVAL_PENDING;
        this.close(obj);
    };
    return CompanyContractCreateDialogComponent;
}());
export { CompanyContractCreateDialogComponent };
