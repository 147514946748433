import {
  BulkUpdateResult,
  EnterpriseDepartmentUserType,
  Progress,
  ProgressBulkEditRequest,
  ProgressBulkSettingInterviewRequest,
  ProgressCheckResponse,
  ProgressCreateRequest,
  ProgressHistoryRemarksUpdateRequest,
  ProgressKpiResponse,
  ProgressOverwriteRequest,
  ProgressSearchParams,
  ProgressSearchResponse,
  ProgressStakeholderEmailResponse,
  ProgressUpdateWithActionRequest,
  ProgressUpdateWithoutActionRequest,
} from '@agent-ds/shared/interfaces';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { REPORT_SUCCESS_HEADER } from '../auth.service';

interface Users {
  type: EnterpriseDepartmentUserType;
  userId: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProgressApiService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  addProgress(request: ProgressCreateRequest): Observable<any> {
    return this.http.post<{ id: number }>('/api/progress', request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
  }

  getList(params?: ProgressSearchParams): Observable<ProgressSearchResponse> {
    return this.http.get<ProgressSearchResponse>('/api/progress/search', {
      params: toAsialRequestParameters(params) as { [param: string]: string | string[] },
    });
  }

  updateUsers(progressId: number, users): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/progress/${progressId}/users`, users);
  }

  updateHistoryRemarks(progressId: number, revision: number, remarks: ProgressHistoryRemarksUpdateRequest): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/progress/${progressId}/history/${revision}`, remarks);
  }

  updateWithAction(progressId: number, requestObject: ProgressUpdateWithActionRequest): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/progress/${progressId}`, requestObject);
  }

  updateWithoutAction(progressId: number, requestObject: ProgressUpdateWithoutActionRequest): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/progress/${progressId}/no-action`, requestObject);
  }

  delete(progressId: number): Observable<any> {
    return this.http.delete(`/api/progress/${progressId}`);
  }

  getDetail(progressId: number): Observable<Progress> {
    return this.http.get<Progress>(`/api/progress/${progressId}`);
  }

  revertHistory(progressId: number, revision: number, remarks: string): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`/api/progress/${progressId}/revert/${revision}`, null, { params: { remarks: remarks } });
  }

  bulkRegisterStudent(studentId: number, jobIds: number[]): Observable<any> {
    return this.http.put<BulkUpdateResult>(`/api/progress/bulk/student/${studentId}/register`, {
      jobIds: jobIds,
    });
  }

  bulkRegisterStudentsByJobId(jobId: number, studentIds: number[]): Observable<any> {
    return this.http.put<BulkUpdateResult>(`/api/progress/bulk/job/${jobId}/register`, {
      studentIds: studentIds,
    });
  }

  bulkRegisterStudentsByProgressIds(progressIds: number[]): Observable<any> {
    return this.http.put<BulkUpdateResult>('/api/progress/bulk/waiting-student-ok/overwrite', {
      progressIds: progressIds,
    });
  }

  bulkRecommendStudent(studentId: number, jobIds: number[]): Observable<any> {
    return this.http.put<BulkUpdateResult>(`/api/progress/bulk/student/${studentId}/recommend`, {
      jobIds: jobIds,
    });
  }

  bulkRegisterStudentNoUpdate(studentId: number, jobIds: number[]): Observable<any> {
    return this.http.put<BulkUpdateResult>(`/api/progress/bulk/student/${studentId}/register/no-update`, {
      jobIds: jobIds,
    });
  }

  bulkDecline(progressIds: number[]): Observable<BulkUpdateResult> {
    return this.http.put<BulkUpdateResult>('/api/progress/bulk/end', { progressIds: progressIds });
  }

  bulkEdit(progressIds: number[], request: ProgressBulkEditRequest): Observable<BulkUpdateResult> {
    return this.http.put<BulkUpdateResult>('/api/progress/bulk/edit', {
      progressIds: progressIds,
      fromStatus: request.fromStatus,
      toStatus: request.toStatus,
      stopReason: request.stopReason,
      remarks: request.remarks,
    });
  }

  bulkSettingInterview(request: ProgressBulkSettingInterviewRequest): Observable<BulkUpdateResult> {
    return this.http.put<BulkUpdateResult>('/api/progress/bulk/setting-interview', request);
  }

  checkProgress(studentIds?: number[], jobIds?: number[]): Observable<ProgressCheckResponse[]> {
    return this.http.get<ProgressCheckResponse[]>('/api/progress/check', {
      params: toAsialRequestParameters({ studentIds: studentIds, jobIds: jobIds }) as { [param: string]: string | string[] },
    });
  }

  getStakeholders(progressIds: number[]): Observable<ProgressStakeholderEmailResponse[]> {
    return this.http.get<ProgressStakeholderEmailResponse[]>('/api/progress/stakeholders/email', {
      params: toAsialRequestParameters({ progressIds: progressIds }) as { [param: string]: string | string[] },
    });
  }

  overwrite(progressId: number, requestObject: ProgressOverwriteRequest): Observable<any> {
    return this.http.put(`/api/progress/${progressId}/overwrite`, requestObject);
  }

  getContractInfo(progressId: number): Observable<{ approvedContractIds: number[]; enterpriseBillingAddressIds: number[] }> {
    return this.http.get<{ approvedContractIds: number[]; enterpriseBillingAddressIds: number[] }>(
      `/api/progress/${progressId}/contract-info`,
    );
  }

  getReportRapa(date: { from: string; to: string }, teamId: number): Observable<ProgressKpiResponse> {
    return this.http.get<ProgressKpiResponse>('/api/progress/monthly-kpi/rapa', { params: { ...date, teamId: teamId + '' } });
  }

  getReportCa(date: { from: string; to: string }, teamId: number): Observable<ProgressKpiResponse> {
    return this.http.get<ProgressKpiResponse>('/api/progress/monthly-kpi/ca', { params: { ...date, teamId: teamId + '' } });
  }

  ngEnd(progressIds: number[]): Observable<BulkUpdateResult> {
    return this.http.put<BulkUpdateResult>('/api/progress/bulk/ng-end', { progressIds: progressIds });
  }

  bulkCreate(jobIds: number[], studentIds: number[]): Observable<BulkUpdateResult> {
    return this.http.post<BulkUpdateResult>('/api/progress/bulk', { jobIds: jobIds, studentIds: studentIds });
  }

  bulkCreateStudent(studentId: number, jobIds: number[], request: ProgressCreateRequest): Observable<any> {
    return this.http.post<BulkUpdateResult>(`/api/progress/bulk/student/${studentId}`, {
      ...request,
      jobIds: jobIds,
    });
  }

  bulkCreateJob(jobId: number, studentIds: number[], request: ProgressCreateRequest): Observable<any> {
    return this.http.post<BulkUpdateResult>(`/api/progress/bulk/job/${jobId}`, {
      ...request,
      studentIds: studentIds,
    });
  }

  bulkCreateCombination(combinations: { studentId: number; jobIds: number[] }[]): Observable<any> {
    return this.http.post<BulkUpdateResult>(`/api/progress/bulk/combination`, {
      studentIdJobIds: combinations,
    });
  }
}
