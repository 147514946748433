import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailSendConfig } from '../config';
var JobCompanyConfig = /** @class */ (function (_super) {
    tslib_1.__extends(JobCompanyConfig, _super);
    function JobCompanyConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JobCompanyConfig.prototype.getTemplateId = function () {
        return 5;
    };
    JobCompanyConfig.prototype.getTemplateTypeId = function () {
        return [5];
    };
    JobCompanyConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.enterprise = params.enterprise || this.sender.model.enterprise;
            if (this.sender.model.enterprise) {
                this.sender.model.enterprises = [this.sender.model.enterprise];
            }
            this.sender.model.enterpriseId = this.sender.model.enterprise.id;
            this.sender.model.department = params.department || this.sender.model.department;
            this.sender.model.departmentId = this.sender.model.department.id;
            this.sender.model.jobs = params.jobs || this.sender.model.jobs;
        }
        _super.prototype.setParams.call(this, params);
    };
    JobCompanyConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.contacts || !this.sender.model.enterprise || !this.sender.model.department) {
            return;
        }
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        var enterprise = this.sender.model.enterprise;
        var department = this.sender.model.department;
        var mappedContacts = this.contacts.map(function (c) {
            return {
                label: c.name + " <" + c.email + ">",
                value: c.email,
            };
        });
        metaData.groups[0].rows.push({
            title: 'To (企業・部署)',
            showRequired: true,
            class: 'ou',
            fields: [
                {
                    name: 'enterpriseName',
                    type: 'label',
                    class: 'pre',
                    supplier: function () {
                        return enterprise.frontId + " " + enterprise.dynamicData.name + enterprise.dynamicData.enterpriseNo + "\n" + department.dynamicData.name;
                    },
                },
                !this.sender.inConfirm
                    ? {
                        name: 'to',
                        type: 'checkbox',
                        labelField: 'label',
                        valueField: 'value',
                        class: 'multi-ou',
                        style: { 'margin-left': '29px' },
                        multi: true,
                        options: mappedContacts,
                        validators: { required: true },
                    }
                    : {
                        name: 'to',
                        type: 'label',
                        supplier: function () {
                            return _this.sender ? _this.contacts.filter(function (c) { return _this.sender.model.to.includes(c.email); }).map(function (c) { return c.name + " <" + c.email + ">"; }) : '';
                        },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (企業、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            if (!_this.sender.inConfirm) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'checkbox',
                    labelField: 'label',
                    valueField: 'value',
                    labelBefore: '企業',
                    class: 'full multi-ou sticky-label',
                    multi: true,
                    options: mappedContacts.length > 1 ? mappedContacts : [],
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                });
            }
            else if (_this.sender.model.cc_single.length) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'label',
                    class: 'full',
                    supplier: function () {
                        return _this.contacts.filter(function (c) { return _this.sender.model.cc_single.includes(c.email); }).map(function (c) { return c.name + " <" + c.email + ">"; });
                    },
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                });
            }
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                // displayType: 'email',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.subject : undefined;
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return _this.sender.model.subject; },
                    },
            ],
        });
        var fileRow = {
            title: '添付ファイル',
            class: 'ou',
            fields: [],
        };
        metaData.groups[0].rows.push(fileRow);
        if (this.sender.inConfirm) {
            fileRow.fields.push({
                type: 'label',
                name: 'zip',
                class: 'link',
                clickAction: function () { return _this.sender.jobApiService.downloadExcel(_this.sender.model.jobs[0].id).subscribe(function (res) { return downloadResponse(res); }); },
                default: '求人票.xlsx',
            });
        }
        var entField = this.sender.dynamicService.getFormRows({
            fieldType: 'multi-enterprise',
            label: '添付ファイル',
            fieldName: 'enterprises',
        }, null, 'half')[0].fields[0];
        if (!this.sender.inConfirm) {
            fileRow.fields.push(entField);
            entField.valueField = null;
            entField.labelBefore = '企業：';
            entField.linkTo = ['enterpriseFileIds'];
            entField.actions = [
                {
                    title: 'データフォルダから選択',
                    type: 'RUNNABLE',
                    allowOn: { enterpriseIds: null },
                    runnable: function () {
                        _this.sender.enterpriseFileIds = _this.sender.model.enterpriseFileIds.slice();
                        PopupControlComponent.instance.open({
                            content: _this.sender.fileSelectTemplate,
                            confirmText: '添付',
                            cancelText: 'キャンセル',
                            title: 'データフォルダ',
                            confirmCallback: function () {
                                _this.sender.model.enterpriseFileIds = _this.sender.enterpriseFileIds.slice();
                            },
                        });
                    },
                },
            ];
        }
        if (!this.sender.inConfirm || this.sender.model.enterpriseFileIds.length) {
            fileRow.fields.push({
                name: 'enterpriseFileIds',
                type: 'autocomplete',
                labelField: [
                    {
                        name: 'name',
                        class: this.sender.inConfirm ? 'link' : null,
                        action: this.sender.inConfirm
                            ? function (file) {
                                return _this.sender.fileService.downloadFile('enterprise', +Object.keys(_this.sender.enterpriseFiles).find(function (key) { return _this.sender.enterpriseFiles[key].find(function (f) { return f.id === file.id; }) != null; }), file.id, file.name);
                            }
                            : null,
                    },
                ],
                labelBefore: this.sender.inConfirm ? '企業：' : null,
                valueField: 'id',
                multi: true,
                style: !this.sender.inConfirm ? { 'padding-left': '40px' } : null,
                class: 'options-only half',
                options: [],
                supplier: function (value, callType, getValue) {
                    var enterprises = getValue('enterprises');
                    _this.sender.model.enterpriseIds = enterprises
                        ? enterprises.map(function (e) {
                            _this.sender.enterpriseInfoById[e.id] = e.frontId + " " + e.name;
                            return e.id;
                        })
                        : [];
                    var ids = _this.sender.model.enterpriseIds;
                    var selectedFiles = Object.values(_this.sender.enterpriseFiles).flatten().filter(function (f) {
                        return _this.sender.model.enterpriseFileIds.includes(f.id);
                    });
                    if (!ids || !ids.length) {
                        return { options: selectedFiles };
                    }
                    else {
                        return forkJoin(ids.map(function (id) {
                            return _this.sender.fileService.getFiles('enterprise', id).pipe(map(function (res) {
                                _this.sender.enterpriseFiles[id] = res.filter(function (f) { return f.enterpriseSendFlag; });
                                return _this.sender.enterpriseFiles[id];
                            }));
                        })).pipe(map(function (val) { return ({ options: val.flatten() }); }));
                    }
                },
            });
        }
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            var template = getValue('dummyTemplateType');
                            return template ? value || template.body : undefined;
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }].concat(res);
                                    return {
                                        value: value ? res.find(function (s) { return s.id === value.id; }) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    JobCompanyConfig.prototype.update = function () {
        var _this = this;
        if (this.sender.model.department) {
            this.contacts = [
                this.sender.model.department.dynamicData.contact1,
                this.sender.model.department.dynamicData.contact2,
                this.sender.model.department.dynamicData.contact3,
                this.sender.model.department.dynamicData.contact4,
                this.sender.model.department.dynamicData.contact5,
            ].filter(function (c) { return c && c.name && c.email && !c.emailAvailable; });
            this.contacts.forEach(function (c, i) {
                if (!i) {
                    _this.sender.model.to.push(c.email);
                }
                else if (_this.sender.model.cc_single) {
                    _this.sender.model.cc_single.push(c.email);
                }
                else {
                    // do nothing
                }
            });
            this.sender.model.bcc = this.sender.model.jobs[0].jobUsers
                .filter(function (u) { return u.type === EnterpriseDepartmentUserType.RA; })
                .map(function (u) { return u.userId; });
        }
    };
    JobCompanyConfig.prototype.save = function (toSave) {
        var _this = this;
        this.sender.mailApiService
            .sendJobEnterpriseMail(this.sender.model.jobs[0].id, this.sender.model.enterprise.id, this.sender.model.department.id, toSave)
            .subscribe(function (res) { return _this.sender.afterSend(res); });
    };
    return JobCompanyConfig;
}(MailSendConfig));
export { JobCompanyConfig };
