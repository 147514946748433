import * as tslib_1 from "tslib";
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { MailApiService } from '@agent-ds/shared/services';
import { OnInit, TemplateRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
var SettingsMailTemplatesPageComponent = /** @class */ (function () {
    function SettingsMailTemplatesPageComponent(mailApiService) {
        var _this = this;
        this.mailApiService = mailApiService;
        this.mailTypeGroups = [];
        this.subwindow = null;
        this.loadRequest = function () { return forkJoin(_this.mailApiService.getMailTemplateTypes(), _this.mailApiService.getMailTemplates()); };
        this.populateDataFn = function (_a) {
            var mailTypes = _a[0], mailTemplates = _a[1];
            _this.mailTypeGroups = mailTypes.map(function (mailType) { return ({
                mailType: mailType,
                mailTemplates: mailTemplates
                    .filter(function (template) { return template.mailTemplateTypeId === mailType.id; })
                    .map(function (t) { return (tslib_1.__assign({}, t, { status: t.status ? 0 : 1 })); }),
            }); });
        };
    }
    SettingsMailTemplatesPageComponent.prototype.ngOnInit = function () {
        this.loadRequest().subscribe(this.populateDataFn);
        this.mailTemplatePopupMeta = {
            groups: [
                {
                    class: 'ou',
                    rows: [
                        {
                            title: 'テンプレート名',
                            style: { 'padding-top': 0, 'padding-bottom': '13px' },
                            fields: [
                                {
                                    name: 'name',
                                    type: 'text',
                                    validators: { required: true },
                                    class: 'tall fill',
                                    style: { padding: 0 },
                                },
                            ],
                        },
                        {
                            title: '件名',
                            style: { 'padding-top': 0, 'padding-bottom': '20px' },
                            fields: [
                                {
                                    name: 'subject',
                                    type: 'text',
                                    validators: { required: true },
                                    class: 'tall fill',
                                    style: { padding: 0 },
                                },
                            ],
                        },
                    ],
                },
                {
                    injectToHeader: this.mailBodyHeader,
                    class: 'ou form__group--no-margin form__group--no-title-border',
                    rows: [
                        {
                            style: { 'padding-top': 0, 'padding-bottom': '20px' },
                            fields: [
                                {
                                    name: 'body',
                                    type: 'textarea',
                                    validators: { required: true },
                                    class: 'tall fill',
                                    style: {
                                        padding: 0,
                                        'margin-top': '-10px',
                                        minRows: 15,
                                        maxRows: 15,
                                    },
                                },
                            ],
                        },
                        {
                            style: { 'padding-top': 0 },
                            fields: [
                                {
                                    name: 'status',
                                    type: 'checkbox',
                                    style: { padding: 0 },
                                    labelAfter: 'このテンプレートを非表示にする',
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    };
    SettingsMailTemplatesPageComponent.prototype.openMailTemplateEdit = function (mailTemplateTypeId, mailTemplate) {
        var _this = this;
        this.mailTemplatePopupModel = tslib_1.__assign({}, mailTemplate);
        this.selectedMailTemplateTypeId = mailTemplateTypeId;
        PopupControlComponent.instance.open({
            title: 'メールテンプレート設定',
            cancelText: 'キャンセル',
            confirmText: mailTemplate ? '更新' : '保存',
            width: '800px',
            height: '640px',
            content: this.mailTemplatePopupTemplate,
            confirmEnabled: function () { return _this.mailTemplateFormComponent && _this.mailTemplateFormComponent.myForm.valid; },
            confirmCallback: function () {
                var requestObj = {
                    name: _this.mailTemplatePopupModel.name.trim(),
                    subject: _this.mailTemplatePopupModel.subject.trim(),
                    body: _this.mailTemplatePopupModel.body.trim(),
                    status: _this.mailTemplatePopupModel.status ? 0 : 1,
                    mailTemplateTypeId: _this.selectedMailTemplateTypeId,
                };
                var request = mailTemplate
                    ? _this.mailApiService.updateMailTemplate(mailTemplate.id, requestObj)
                    : _this.mailApiService.addMailTemplate(requestObj);
                return request.pipe(concatMap(function () { return _this.loadRequest().pipe(tap(_this.populateDataFn)); }));
            },
        });
    };
    SettingsMailTemplatesPageComponent.prototype.openPlaceholderWindow = function () {
        var _this = this;
        if (this.subwindow && !this.subwindow.closed) {
            this.subwindow.close();
        }
        this.mailApiService.getMailTemplatePlaceholder().subscribe(function (placeholders) {
            var filtered = placeholders.filter(function (p) { return p.mail_template_type_id === _this.selectedMailTemplateTypeId; });
            _this.subwindow = window.open('', '差し込み変数 | Mach', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=750,width=630');
            _this.subwindow.document.write("\n<html>\n  <head>\n    <title>\u5DEE\u3057\u8FBC\u307F\u5909\u6570 | Mach - doda\u65B0\u5352\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8</title>\n    <link rel=\"stylesheet\" type=\"text/css\" href=\"/styles.css\">\n    <style>\n      * {\n        box-sizing: border-box;\n        margin: 0;\n        padding: 0;\n      }\n\n      body {\n        width: 100%;\n        min-width: 550px;\n        overflow: auto;\n        padding: 30px;\n        font-family: " + [
                '"Meiryo UI"',
                '"游ゴシック Medium"',
                'YuGothic',
                'YuGothicM',
                '"Hiragino Kaku Gothic ProN"',
                '"Hiragino Kaku Gothic Pro"',
                'メイリオ',
                'Meiryo',
                'sans-serif',
            ].join(', ') + ";\n      }\n\n      .detail-header {\n        height: 23px;\n        padding-left: 7px;\n        border-left: 5px solid #1f98e3;\n        margin-bottom: 9px;\n      }\n\n      .detail-header__title {\n        font-weight: bold;\n        color: $text;\n        font-size: 16px;\n        line-height: 20px;\n      }\n\n      .detail {\n        font-size: 12px;\n        line-height: 16px;\n        color: #9E9E9E;\n        width: 100%;\n        border-collapse: collapse;\n      }\n\n      .detail__row {\n        height: 30px;\n      }\n\n      .detail__cell {\n        padding: 6px 15px;\n        border: 1px solid #e3e3e3;\n      }\n\n      .detail__label {\n        background-color: #fafafa;\n        font-size: 12px;\n        line-height: 14px;\n        color: #666666;\n        width: 130px;\n      }\n    </style>\n  </head>\n  <body>\n    <div class=\"detail-header\">\n      <div class=\"detail-header__title\">\u5DEE\u3057\u8FBC\u307F\u5909\u6570\u4E00\u89A7</div>\n    </div>\n    <table class=\"detail\">\n      " + filtered
                .map(function (p) { return "\n          <tr class=\"detail__row\">\n            <td class=\"detail__cell detail__label\">" + p.label + "</td>\n            <td class=\"detail__cell\">" + p.value + "</td>\n          </tr>\n          "; })
                .join('') + "\n    </table>\n  </body>\n</html>\n");
        });
    };
    return SettingsMailTemplatesPageComponent;
}());
export { SettingsMailTemplatesPageComponent };
