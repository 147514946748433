<ag-page-floater
  #mailFloater
  [sideActions]="mailSideActions"
  (transformed)="$event === '0px' ? close(true) : ''"
>
  <ag-mail-send
    #mailSend
    (closed)="close()"
    (shouldOpen)="mailFloater.grow()"
    (showPopup)="openPopup($event)"
    (sent)="sent.emit()"
  ></ag-mail-send>
</ag-page-floater>

<ng-template #jobSelect>
  <div class="job">
    <div class="job__head">
      {{ popupMessage || "該当する求人を選択してください。" }}
    </div>
    <ag-dynamic-form
      class="job__select"
      [metadata]="popupMeta"
      [model]="popupModel"
    ></ag-dynamic-form>
  </div>
</ng-template>

<ng-template #jobError>
  <div class="job-table">
    <div class="job-table__title">
      {{
        "担当が設定されていないか、求人ステータスが募集中または推薦停止ではありません。\n求人詳細ページを確認してください。"
      }}
    </div>
    <div class="job-table__head">求人ID、ポジション名</div>
    <div class="job-table__info" *ngFor="let info of jobErrorInfo">
      {{ info.frontJobId + " " + info.position }}
    </div>
  </div>
</ng-template>

<ng-template #jobWarning>
  <div class="job-table">
    <div class="job-table__title">
      求人ステータスが推薦停止ですがよろしいですか？
    </div>
    <div class="job-table__head">求人ID、ポジション名</div>
    <div class="job-table__info" *ngFor="let info of jobWarningInfo">
      {{ info.frontJobId + " " + info.position }}
    </div>
  </div>
</ng-template>

<ng-template #depSelect>
  <div class="department">
    <div class="department__head">部署を選択します。</div>
    <ag-dynamic-form
      class="department__select"
      [metadata]="popupMeta"
      [model]="popupModel"
    ></ag-dynamic-form>
  </div>
</ng-template>

<ng-template #studentSelect>
  <div class="student">
    <div class="student__head">
      {{ popupMessage || "一括推薦します。\n対象の求職者を選択してください" }}
    </div>
    <ag-dynamic-form
      class="student__select"
      [metadata]="popupMeta"
      [model]="popupModel"
    ></ag-dynamic-form>
    <ag-dynamic-form
      *ngIf="popupSendMeta.groups[0].rows.length"
      class="student__select"
      [metadata]="popupSendMeta"
      [model]="mailSend?.model"
    ></ag-dynamic-form>
  </div>
</ng-template>
