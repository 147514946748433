<div class="header">
  管理
</div>

<div class="section">
  <div class="sub-header">
    <div class="sub-header__title">
      メールテンプレート管理
    </div>
  </div>
  <table *ngFor="let mailTypeGroup of mailTypeGroups" class="table">
    <thead>
      <tr>
        <th class="table--bold">{{ mailTypeGroup.mailType.name }}</th>
        <th class="table__status">ステータス</th>
        <th class="table__action">
          <button
            class="table__btn btn btn--small btn--dark"
            (click)="openMailTemplateEdit(mailTypeGroup.mailType.id)"
          >
            新規テンプレート登録
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let mailtemplate of mailTypeGroup.mailTemplates"
        [class.table--bg-dark]="mailtemplate.status === 1"
      >
        <td class="table__value">
          {{ mailtemplate.name }}
        </td>
        <td class="table__status">
          {{ mailtemplate.status === 1 ? "非表示" : "" }}
        </td>
        <td class="table__action">
          <button
            class="table__btn btn btn--small btn--bg-white btn--outline-gray"
            (click)="
              openMailTemplateEdit(
                mailtemplate.mailTemplateTypeId,
                mailtemplate
              )
            "
          >
            編集
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #mailBodyHeader>
  <div class="form__header">
    <span class="form__title">本文</span>
    <span class="form__action" (click)="openPlaceholderWindow()">
      差し込み変数に関する説明
    </span>
  </div>
</ng-template>

<ng-template #mailTemplatePopup let-data>
  <div class="form">
    <ag-dynamic-form
      #mailTemplateForm
      [metadata]="mailTemplatePopupMeta"
      [model]="mailTemplatePopupModel"
    ></ag-dynamic-form>
  </div>
</ng-template>
