<div class="header">
  管理
</div>

<div class="section section--horizontal">
  <div class="sub-header">
    <div class="sub-header__title">
      チーム管理
    </div>
  </div>
  <button class="btn btn--green section__btn" (click)="openTeamEdit()">
    新規チーム登録
  </button>
</div>

<ag-page-scroll-table
  *ngIf="tableConfig"
  class="table"
  [tableConfig]="tableConfig"
  [content]="teams"
  [scrollerClass]="'no-scroll'"
></ag-page-scroll-table>

<ng-template #teamName let-data>
  <div class="overflow" [title]="data?.name">
    {{ data?.name }}
  </div>
</ng-template>
<ng-template #branchName let-data>
  <div class="overflow" [title]="data?.branch?.name">
    {{ data?.branch?.name }}
  </div>
</ng-template>
<ng-template #managerName let-data>
  <div class="overflow" [title]="data?.manager?.name">
    {{ data?.manager?.name }}
  </div>
</ng-template>

<ng-template #editButton let-data>
  <button
    class="edit-btn btn btn--small btn--white btn--outline-gray"
    (click)="openTeamEdit(data)"
  >
    変更
  </button>
</ng-template>

<ng-template #teamPopup let-data>
  <div class="form">
    <ag-dynamic-form
      #teamForm
      [metadata]="teamPopupMeta"
      [model]="teamPopupModel"
    ></ag-dynamic-form>
  </div>
</ng-template>
