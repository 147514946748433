<div class="header">
  <div class="search-bar">
    <input
      class="search-bar__input"
      type="text"
      placeholder="キーワード"
      [ngModel]="searchInputValue"
      (ngModelChange)="onSearchChange($event)"
      (keydown.enter)="onSearchChange(searchInputValue, true)"
    />
  </div>
  <div class="refresh" (click)="init()">
    <div class="refresh-icon">
      <svg class="refresh--svg">
        <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
      </svg>
    </div>
    最新情報に更新
  </div>
  <button
    class="new-message-btn new-message-btn--large new-message-btn--green"
    (click)="send.emit()"
  >
    新規メール作成
  </button>
</div>
<div class="messages">
  <div class="category-column">
    <div class="category-list">
      <div
        class="category-list__item"
        [class.category-list__item--selected]="selectedList === mails"
        (click)="onListChanges(mails)"
      >
        全てのメール ({{ total }})
      </div>
      <div
        class="category-list__item"
        [class.category-list__item--selected]="selectedList === incoming"
        (click)="onListChanges(incoming)"
      >
        受信メール ({{ incoming.length }})
      </div>
      <div
        class="category-list__item"
        [class.category-list__item--selected]="selectedList === outgoing"
        (click)="onListChanges(outgoing)"
      >
        送信メール ({{ outgoing.length }})
      </div>
    </div>
  </div>
  <div class="details-column">
    <!-- <div class="details-column__header">
      並び替え:
      <ag-select
        class="details-column__sort"
        [options]="sortValues"
        [labelField]="'title'"
        [value]="sortValue"
        (valueChange)="onSortChange($event)"
      ></ag-select>
    </div> -->
    <div class="details-column__message-list" (scroll)="onScroll($event)">
      <div class="message-list">
        <div
          *ngFor="let mail of selectedList"
          class="message-list__item"
          [class.message-list__item--selected]="selectedMail === mail"
          (click)="onSelectedChanges(mail)"
        >
          <div class="message-list__item__side">
            <img
              *ngIf="mail.mime.attachments"
              class="message-list__item__side__icon"
              src="/assets/icons/icn_attachment.svg"
              alt="attachment-icon"
            />
          </div>
          <div class="message-list__item__header">
            <div class="message-list__item__header__sender">
              {{ mail.message.displayName?.from }}
            </div>
            <div class="message-list__item__header__date">
              {{ mail.timestamp | safeDate: "yyyy/MM/dd" }}
            </div>
          </div>
          <div class="message-list__item__title">
            {{ mail.mime.subject }}
          </div>
          <div class="message-list__item__text-snippet">
            {{ mail.mime.text | truncate: 35 }}
          </div>
        </div>
      </div>
    </div>
    <div class="details-column__message">
      <div class="message-placeholder" *ngIf="!selectedMail">
        {{
          mails.length ? "メッセージは未選択です" : "メッセージはまだありません"
        }}
      </div>
      <div class="message" *ngIf="selectedMail">
        <div class="message__container">
          <div class="message__details">
            <div class="message__details__sender">
              {{ selectedMail.message.displayName?.from }}
              <img
                *ngIf="selectedMail.mime.attachments"
                class="message__details__attachment-icon"
                src="/assets/icons/icn_attachment.svg"
                alt="attachment-icon"
              />
            </div>
            <div class="message__details__title">
              {{ selectedMail.mime.subject }}
            </div>
            <div class="message__details__date">
              {{ selectedMail.timestamp | safeDate: "yyyy/MM/dd HH:mm" }}
            </div>
            <div class="message__details__receivers">
              <div class="message__details__receivers__group">
                <span class="message__details__receivers__label">To.</span>
                <span class="message__details__receivers__item">{{
                  selectedMail.message.displayName?.to
                }}</span>
              </div>
              <div class="message__details__receivers__group">
                <span class="message__details__receivers__label">Cc.</span>
                <span class="message__details__receivers__item">{{
                  selectedMail.message.displayName?.cc
                }}</span>
              </div>
              <div
                class="message__details__receivers__group"
                *ngIf="selectedMail.isSend"
              >
                <span class="message__details__receivers__label">Bcc.</span>
                <span class="message__details__receivers__item">{{
                  selectedMail.message.displayName?.bcc
                }}</span>
              </div>
            </div>
          </div>
          <pre class="message__text">{{ selectedMail.mime.text }}</pre>
          <div
            class="message__attachments"
            *ngIf="selectedMail.attachments?.length"
          >
            <div
              class="message__attachments__item"
              *ngFor="let attachment of selectedMail.attachments"
            >
              <span class="message__attachments__item__label"
                >添付ファイル.</span
              >
              <span
                class="message__attachments__item__name"
                (click)="downloadAttachment(attachment.partId)"
                >{{ attachment.fileName }}</span
              >
              <span class="message__attachments__item__size"
                >({{ attachment.size | fileSize }})</span
              >
            </div>
          </div>
        </div>
        <div class="message__actions">
          <div class="message__actions__quote">
            <input
              id="quote_checkbox"
              class="message__actions__quote__checkbox"
              type="checkbox"
              [(ngModel)]="quote"
            />
            <label for="quote_checkbox" class="message__actions__quote__label">
              メールを引用
            </label>
          </div>
          <button
            class="message__actions__reply-btn control control--small control--outline-gray control--white"
            (click)="onReply()"
          >
            返信
          </button>
          <!-- <button
            class="message__actions__transfer-btn control control--small control--outline-gray control--white"
            (click)="onForward()"
          >
            転送
          </button> -->
        </div>
      </div>
    </div>
  </div>
</div>
