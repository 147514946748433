import * as tslib_1 from "tslib";
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AREAS, DAYS } from '@agent-ds/shared/constants';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { AfterViewInit, OnDestroy, TemplateRef } from '@angular/core';
var NewSeminarRegistrationDialogComponent = /** @class */ (function () {
    function NewSeminarRegistrationDialogComponent(dialog, config, dynamicService, counselingApiService) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.counselingApiService = counselingApiService;
        this.model = {};
        this.metadata = { groups: [] };
        this.isValid = false;
        this.daysOfTheWeek = (function () {
            var _a = Object.keys(DAYS).map(function (key) { return ({ id: +key, name: DAYS[key] }); }), sunday = _a[0], restOfDays = _a.slice(1);
            return restOfDays.concat([sunday]);
        })();
        this.areas = Object.keys(AREAS).map(function (key) { return ({ id: +key, name: AREAS[key] }); });
        this.AREAS = AREAS;
    }
    NewSeminarRegistrationDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.model.area = this.areas[0].id;
        this.model.initialReqruitmentMasterRegistration = true;
        this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadata = {
                groups: [
                    {
                        class: 'form__group--no-title-border no-border-around no-background',
                        rows: (function () {
                            var rows = _this.dynamicService.getFormRows({
                                fieldName: 'area',
                                fieldType: 'list',
                                label: 'セミナーエリア',
                                displayType: 'radio',
                                validationStyle: { required: true, options: _this.areas },
                            }, null, 'full');
                            rows[0].fields[0].labelField = 'name';
                            rows[0].fields[0].valueField = 'id';
                            return rows;
                        })().concat((function () {
                            var rows = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('student', 'seminarReservationDate'), { validationStyle: { required: true }, fieldType: 'date-range', displayType: 'date', label: '期間' }), null, 'full');
                            rows[0].fields[0].validators = tslib_1.__assign({}, rows[0].fields[0].validators, { required: true, minValue: new Date().toAsialDateString(), minDate: new Date().toAsialDateString() });
                            rows[0].fields[2].validators = tslib_1.__assign({}, rows[0].fields[2].validators, { required: true, minValue: new Date().toAsialDateString(), minDate: new Date().toAsialDateString() });
                            return rows;
                        })(), (function () {
                            var rows = _this.dynamicService.getFormRows({
                                fieldName: 'dayOfTheWeek',
                                fieldType: 'multi-list',
                                label: '曜日',
                                displayType: 'checkbox',
                                validationStyle: {
                                    required: true,
                                    options: _this.daysOfTheWeek,
                                    minChecked: 1,
                                },
                            }, null, 'full');
                            rows[0].fields[0].labelField = 'name';
                            rows[0].fields[0].valueField = 'id';
                            return rows;
                        })(), (function () {
                            var def = _this.dynamicService.getDefinition('student', 'seminarReservationTime');
                            var rows = _this.dynamicService.getFormRows(tslib_1.__assign({}, def, { fieldType: 'multi-list', displayType: 'checkbox', validationStyle: tslib_1.__assign({}, def.validationStyle, { required: true, minChecked: 1 }), label: '開始時間' }), null, 'full');
                            rows[0].fields[0].validators.options = rows[0].fields[0].validators.options.filter(function (option) { return !isNaN(Date.parse('1970-01-01T' + option)); });
                            rows[0].fields[0].options = rows[0].fields[0].validators.options.slice();
                            return rows;
                        })(), _this.dynamicService.getFormRows({
                            fieldName: 'seminarAttributes',
                            fieldType: 'number',
                            label: 'セミナー属性',
                            displayType: 'number',
                            displayStyle: {
                                placeholder: 'セミナー属性番号',
                            },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'initialReqruitmentMasterRegistration',
                            fieldType: 'list',
                            label: '紹介求人マスタ初期登録',
                            displayType: 'checkbox',
                        }, null, 'half')),
                    },
                ],
            };
        });
    };
    NewSeminarRegistrationDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubScription) {
            this.fieldSubScription.unsubscribe();
            this.fieldSubScription = null;
        }
    };
    NewSeminarRegistrationDialogComponent.prototype.create = function () {
        var _this = this;
        var _a = this.model.seminarReservationDate, from = _a.from, to = _a.to;
        var requestObj = {
            area: this.model.area,
            startDate: new Date(from).toAsialDateString(),
            endDate: new Date(to).toAsialDateString(),
            weekdays: from === to ? this.daysOfTheWeek.map(function (day) { return day.id; }) : this.model.dayOfTheWeek,
            startTimes: this.model.seminarReservationTime,
            type: this.model.seminarAttributes,
            registerJobs: this.model.initialReqruitmentMasterRegistration ? 1 : 0,
        };
        this.counselingApiService.addSeminar(requestObj).subscribe(function (res) {
            _this.close(res);
            if (res && res.errorItems && res.errorItems.length) {
                _this.createErrors = res.errorItems;
                PopupControlComponent.instance.open({
                    content: _this.errorsTemplate,
                    confirmText: null,
                    confirmCallback: function () { return void 0; },
                    title: 'セミナー予約',
                    height: '357px',
                    width: '600px',
                    cancelText: '閉じる',
                    cancelCallback: function () { return void 0; },
                });
            }
            else if (res && res.successItems && res.successItems.length) {
                PopupControlComponent.instance.open({
                    content: 'セミナー登録が完了しました。',
                    confirmText: '閉じる',
                    confirmCallback: function () { return void 0; },
                    title: 'セミナー予約',
                    height: '357px',
                    width: '600px',
                });
            }
        });
    };
    NewSeminarRegistrationDialogComponent.prototype.close = function (result) {
        this.dialog.close(result);
    };
    NewSeminarRegistrationDialogComponent.prototype.onValidityChange = function (valid) {
        this.isValid = valid;
    };
    NewSeminarRegistrationDialogComponent.prototype.onFormChange = function (event) {
        if (event.includes('seminarReservationDate')) {
            var _a = this.model.seminarReservationDate, from = _a.from, to = _a.to;
            if (from === to) {
                this.metadata.groups[0].rows[2].showRequired = false;
                this.metadata.groups[0].rows[2].fields[0].disabled = true;
            }
            else {
                this.metadata.groups[0].rows[2].showRequired = true;
                this.metadata.groups[0].rows[2].fields[0].disabled = false;
            }
        }
    };
    return NewSeminarRegistrationDialogComponent;
}());
export { NewSeminarRegistrationDialogComponent };
