<div class="content" [class.content--hidden]="showList">
  <div class="row">
    <div class="row__column">
      <div class="matching-parameters">
        <div class="matching-parameters__header">
          <span class="matching-parameters__header__title"
            >求人検索マッチング条件 ></span
          >
          <span class="matching-parameters__header__subtitle"
            >保存された検索条件</span
          >
        </div>
        <div class="matching-parameters__list">
          <div *ngIf="matchings?.length" class="matching-parameters__btn-group">
            <ag-chip
              class="chip chip--green"
              [label]="matching.name"
              (toggled)="matchingSelected($event, matching)"
              (removed)="deleteMatching(matching)"
              [selected]="matching.id === selectedMatching?.id"
              *ngFor="let matching of matchings"
            ></ag-chip>
          </div>
          <div class="matching-parameters__btn-group">
            <button
              *ngIf="student"
              class="btn second-matching-chip"
              [disabled]="isSecondMatchingUpdateInProgress"
              [class.second-matching-chip--selected]="is2ndMatchOnState"
              (click)="toggleSecondMatching()"
            >
              <span
                class="second-matching-chip__status"
                [class.second-matching-chip__status--selected]="
                  is2ndMatchOnState
                "
              >
                <span *ngIf="is2ndMatchOnState; else offState">ON</span>
                <ng-template #offState>OFF</ng-template>
              </span>
              <span
                class="second-matching-chip__label"
                [class.second-matching-chip__label--selected]="
                  is2ndMatchOnState
                "
                >2ndマッチ</span
              >
            </button>
          </div>
        </div>
      </div>
      <ag-job-search
        #matchingJobSearch
        [searchModel]="jobSearchObj"
        (search)="doJobSearch($event)"
        (filterChanged)="onFilterChanged($event)"
        class="job-search-matching"
      ></ag-job-search>
    </div>
    <div class="row__column">
      <ag-student-detail-page
        #matchingStudentDetails
        class="student-detail"
        [inSlimMode]="true"
        [inFullMode]="false"
        [isReadonly]="true"
      ></ag-student-detail-page>
    </div>
  </div>
</div>

<div class="job-list" *ngIf="showList">
  <div class="header">
    <span class="header__title">求人検索マッチング条件</span>
    <button class="header__modify" (click)="toSearch()">
      マッチング条件を変更<span
        class="header__modify__arrow header__modify__arrow--left"
      ></span>
    </button>
  </div>

  <ag-student-detail-header
    [student]="student"
    [readonly]="true"
    class="student-detail-header"
  ></ag-student-detail-header>

  <ag-job-list
    class="job-list-content"
    #matchingJobList
    [requestObject]="jobSearchObj"
    [options]="listConfiguration"
    [extraControls]="extraButtons"
    [matchingMode]="true"
  >
  </ag-job-list>
</div>
<div class="footer">
  <div class="footer__container">
    <button
      class="footer__btn footer__btn--outline-white footer__btn--bg-transparent footer__btn--hover-blue footer__btn--active-blue"
      (click)="saveMatching()"
    >
      マッチング条件を保存
    </button>
    <button
      *ngIf="!showList"
      class="footer__btn footer__btn--green"
      (click)="matchingJobSearch.doSearch()"
    >
      検索
    </button>
  </div>
</div>

<ng-template #extraButtons>
  <button
    [disabled]="
      jobList?.checkedItems.length > 20 ||
      !jobList?.checkedItems.length ||
      (jobList?.allChecked && jobList?.totalSize > 20)
    "
    class="content__controls__control"
    (click)="getJobsAsPdf(jobList?.checkedItems)"
  >
    求人票の出力
  </button>
  <button
    [disabled]="!jobList?.checkedItems.length"
    class="content__controls__control"
    (click)="openMail(postingConfig)"
  >
    求人票送信
  </button>
  <button
    [disabled]="
      jobList?.checkedItems.length > 20 ||
      !jobList?.checkedItems.length ||
      (jobList?.allChecked && jobList?.totalSize > 20)
    "
    class="content__controls__control"
    (click)="createProgress()"
  >
    進捗登録
  </button>
</ng-template>
