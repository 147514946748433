import * as tslib_1 from "tslib";
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { TargetKpi } from '@agent-ds/shared/interfaces/dashboard';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { OnDestroy, OnInit } from '@angular/core';
var KpiSetupDialogComponent = /** @class */ (function () {
    function KpiSetupDialogComponent(dialog, config, dynamicService) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.metadataLeft = { groups: [] };
        this.metadataRight = { groups: [] };
        this.updating = false;
    }
    KpiSetupDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.config.data.model = this.config.data.model || TargetKpi.empty();
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return _this.update(); });
        this.fieldSubScription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadataLeft = {
                groups: [
                    {
                        class: 'form__group--no-title-border',
                        rows: (_this.config.data.dashboardType === DashboardType.CA || _this.config.data.dashboardType === DashboardType.CA_Manager
                            ? _this.dynamicService.getFormRows({
                                fieldName: 'counselings',
                                fieldType: 'number',
                                displayType: 'number',
                                unit: '件',
                                label: 'カウ数',
                                validationStyle: { minValue: 0 },
                            }, null, 'half')
                            : _this.dynamicService.getFormRows({
                                fieldName: 'azs',
                                fieldType: 'number',
                                displayType: 'number',
                                unit: '件',
                                label: 'AZ数',
                                validationStyle: { minValue: 0 },
                            }, null, 'half')).concat(_this.dynamicService.getFormRows({
                            fieldName: 'offerSubmissions',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: '打診数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'studentOks',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: '本人OK数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'salesDocuments',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: '営業書類提出数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'seminarSettings',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: 'セミナー設定数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'seminarCompletions',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: 'セミナー実施数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half')),
                    },
                ],
            };
            _this.metadataRight = {
                groups: [
                    {
                        // class: 'form__group--no-title-border no-border-around no-background',
                        class: 'form__group--no-title-border',
                        rows: _this.dynamicService.getFormRows({
                            fieldName: 'firstInterviewSettings',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: '１次面接設定数',
                        }, null, 'half').concat(_this.dynamicService.getFormRows({
                            fieldName: 'firstInterviewCompletions',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: '１次面接実施数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'finalInterviewSettings',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: '最終面接設定数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'finalInterviewCompletions',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '件',
                            label: '最終面接実施数',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'offers',
                            unit: '人',
                            fieldType: 'number',
                            displayType: 'number',
                            label: '内定数',
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'acceptances',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '人',
                            label: '決定人数(SN)',
                            validationStyle: { minValue: 0 },
                        }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'sales',
                            fieldType: 'number',
                            displayType: 'number',
                            unit: '万円',
                            label: '決定金額(SK)',
                            validationStyle: { minValue: 0 },
                        }, null, 'half')),
                    },
                ],
            };
        });
    };
    KpiSetupDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubScription) {
            this.fieldSubScription.unsubscribe();
        }
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    KpiSetupDialogComponent.prototype.close = function () {
        this.dialog.close();
    };
    KpiSetupDialogComponent.prototype.update = function () {
        var _this = this;
        if (this.updating) {
            return;
        }
        var toSave = this.config.data.model;
        if (toSave.sales) {
            toSave = tslib_1.__assign({}, toSave, { sales: toSave.sales * 10000 });
        }
        this.config.data.updater(toSave).subscribe(function (response) {
            if (response.ok) {
                _this.close();
            }
            _this.updating = false;
        }, function () {
            _this.updating = false;
        });
        this.updating = true;
    };
    return KpiSetupDialogComponent;
}());
export { KpiSetupDialogComponent };
