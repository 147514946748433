import * as tslib_1 from "tslib";
import { ApiService } from '@agent-ds/shared/models/api-service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OutlookApiService = /** @class */ (function (_super) {
    tslib_1.__extends(OutlookApiService, _super);
    function OutlookApiService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    OutlookApiService.prototype.getMessageInfo = function (messageId) {
        return this.http.get('/api/mail/integration/' + messageId);
    };
    OutlookApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OutlookApiService_Factory() { return new OutlookApiService(i0.ɵɵinject(i1.HttpClient)); }, token: OutlookApiService, providedIn: "root" });
    return OutlookApiService;
}(ApiService));
export { OutlookApiService };
