import { SearchCategory } from '@agent-ds/shared/enums';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { AuthService, DynamicFieldService } from '@agent-ds/shared/services';
import { ElementRef, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Router } from '@angular/router';
var SearchBarComponent = /** @class */ (function () {
    function SearchBarComponent(router, dynamicFieldService, authService) {
        var _a;
        this.router = router;
        this.dynamicFieldService = dynamicFieldService;
        this.authService = authService;
        this.SearchCategory = SearchCategory;
        this.selectedSearchCategory = null;
        this.inputValue = '';
        this.isIntakeCa = false;
        this.checkValuesByCategories = (_a = {},
            _a[SearchCategory.JobSeeker] = [],
            _a[SearchCategory.Company] = [],
            _a[SearchCategory.JobOffer] = [],
            _a);
    }
    SearchBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginSubscription = this.authService.isLogined().subscribe(function (logined) {
            // 副担当者かどうかを確認
            _this.isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
        });
        this.dynamicFieldService.fieldUpdateEvent.subscribe(function () {
            _this.checkValuesByCategories[SearchCategory.JobSeeker].length = 0;
            _this.checkValuesByCategories[SearchCategory.JobOffer].length = 0;
            var studentRegField = _this.dynamicFieldService.getDefinition('student', 'registrationStatus');
            if (studentRegField && studentRegField.validationStyle && studentRegField.validationStyle.options) {
                _this.checkValuesByCategories[SearchCategory.JobSeeker].push({
                    key: 'registrationStatus',
                    options: [
                        { value: '', label: '登録ステータス' }
                    ].concat(studentRegField.validationStyle.options.map(function (o) { return ({ value: o, label: o }); })),
                });
            }
            var jobStatusField = _this.dynamicFieldService.getDefinition('job', 'status');
            if (jobStatusField && jobStatusField.validationStyle && jobStatusField.validationStyle.options) {
                _this.checkValuesByCategories[SearchCategory.JobOffer].push({
                    key: 'status',
                    options: [{ value: '', label: '求人ステータス' }].concat(jobStatusField.validationStyle.options.map(function (o) { return ({ value: o, label: o }); })),
                });
            }
            var jobScreeningField = _this.dynamicFieldService.getDefinition('job', 'screeningArea');
            if (jobScreeningField && jobScreeningField.validationStyle && jobScreeningField.validationStyle.options) {
                _this.checkValuesByCategories[SearchCategory.JobOffer].push({
                    key: 'screeningArea',
                    options: [{ value: '', label: '選考エリア' }].concat(jobScreeningField.validationStyle.options.map(function (o) { return ({ value: o, label: o }); })),
                });
            }
        });
    };
    SearchBarComponent.prototype.ngOnDestroy = function () {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
    };
    SearchBarComponent.prototype.onSearchCategoryClick = function (searchCategory) {
        this.focusedSearchBar = true;
        this.selectedSearchCategory = this.selectedSearchCategory === searchCategory ? null : searchCategory;
        this.showDropdown = this.hasDropdownContent(this.selectedSearchCategory);
        this.focusInput();
    };
    SearchBarComponent.prototype.onSearchBarClick = function () {
        this.showDropdown = !!this.selectedSearchCategory && this.hasDropdownContent(this.selectedSearchCategory);
        this.focusedSearchBar = true;
        if (!this.selectedSearchCategory) {
            this.selectedSearchCategory = SearchCategory.JobSeeker;
        }
    };
    SearchBarComponent.prototype.onOutsideClick = function () {
        this.searchInput.nativeElement.blur();
        this.focusedInput = false;
        this.focusedSearchBar = false;
        this.showDropdown = false;
    };
    SearchBarComponent.prototype.onSearch = function (event) {
        if (event && event['isComposing']) {
            return;
        }
        var obj = {};
        if (this.inputValue) {
            obj['keyword'] = this.inputValue;
        }
        this.selectInputs.forEach(function (item) {
            if (item.nativeElement.value) {
                obj[item.nativeElement.name] = [item.nativeElement.value];
            }
        });
        if (!this.selectedSearchCategory || this.selectedSearchCategory === SearchCategory.JobSeeker) {
            this.router.navigate(['/students/list'], { queryParams: obj });
        }
        else if (this.selectedSearchCategory === SearchCategory.Company) {
            this.router.navigate(['/enterprises/list'], { queryParams: obj });
        }
        else {
            this.router.navigate(['/jobs/list'], { queryParams: obj });
        }
        this.onOutsideClick();
    };
    SearchBarComponent.prototype.focusInput = function () {
        this.searchInput.nativeElement.focus();
        this.focusedInput = true;
    };
    SearchBarComponent.prototype.hasDropdownContent = function (searchCategory) {
        return searchCategory === SearchCategory.JobSeeker || searchCategory === SearchCategory.JobOffer;
    };
    return SearchBarComponent;
}());
export { SearchBarComponent };
