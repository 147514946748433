/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../page-scroll-table/page-scroll-table.component.ngfactory";
import * as i4 from "../page-scroll-table/page-scroll-table.component";
import * as i5 from "../../pipes/safe-date.pipe";
import * as i6 from "@angular/common";
import * as i7 from "../dynamic-form/dynamic-form.component.ngfactory";
import * as i8 from "../dynamic-form/dynamic-form.component";
import * as i9 from "../../pipes/truncate.pipe";
import * as i10 from "./contact-history.component";
import * as i11 from "../../services/api/dynamic-field.service";
import * as i12 from "../../services/api/user-api.service";
import * as i13 from "../../services/api/master-api.service";
import * as i14 from "../../services/auth.service";
var styles_ContactHistoryComponent = [i0.styles];
var RenderType_ContactHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactHistoryComponent, data: {} });
export { RenderType_ContactHistoryComponent as RenderType_ContactHistoryComponent };
function View_ContactHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_2); }); }
function View_ContactHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-scroll-table", [["class", "contact-history__table"]], null, null, null, i3.View_PageScrollTableComponent_0, i3.RenderType_PageScrollTableComponent)), i1.ɵdid(1, 638976, [[1, 4]], 0, i4.PageScrollTableComponent, [i5.SafeDatePipe], { content: [0, "content"], tableConfig: [1, "tableConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredContacts; var currVal_1 = _co.tableConfig; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ContactHistoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.teamName; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.ngIf.userName; _ck(_v, 3, 0, currVal_1); }); }
function View_ContactHistoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ContactHistoryComponent_4)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.findUserTeam(_v.context.$implicit.userId))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ContactHistoryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "actions__control btn btn--white btn--outline-gray btn--small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateContact(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "actions__control btn btn--white btn--outline-gray btn--small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteContact(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u524A\u9664 "]))], null, null); }
function View_ContactHistoryComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "message__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "message__btn open-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleText(_v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", " open-btn__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "open-btn__arrow"]], [[2, "open-btn__arrow--down", null], [2, "open-btn__arrow--up", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isOpen(_v.parent.context.$implicit.id) ? "\u9589\u3058\u308B" : "\u958B\u304F"); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isOpen(_v.parent.context.$implicit.id); var currVal_2 = _co.isOpen(_v.parent.context.$implicit.id); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_ContactHistoryComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "message__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactHistoryComponent_7)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isTruncated(((_v.context.$implicit == null) ? null : _v.context.$implicit.message)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.message), ((_co.truncateAt[_v.context.$implicit.id] === undefined) ? _co.defaultTruncateAt : _co.truncateAt[_v.context.$implicit.id]), ((_co.truncateAt[_v.context.$implicit.id] === null) ? 0 : _co.defaultTruncateLines))); _ck(_v, 2, 0, currVal_0); }); }
function View_ContactHistoryComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "contact-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "contact-form__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u5BFE\u5FDC\u5C65\u6B74\u3092\u767B\u9332\u3057\u307E\u3059\u3002"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ag-dynamic-form", [["class", "contact-form__column contact-form__column"]], null, [[null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = ((_co.validForm = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DynamicFormComponent_0, i7.RenderType_DynamicFormComponent)), i1.ɵdid(4, 442368, null, 0, i8.DynamicFormComponent, [i2.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i9.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated" }), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "contact-form__column contact-form__column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "contact-form__form-group contact-form__form-group--textarea"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "contact-form__input-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5185\u5BB9 "])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "textarea", [["class", "contact-form__input contact-form__input--textarea"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keydown.enter"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.enter" === en)) {
        var pd_4 = ($event.stopPropagation() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.model.message = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(12, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadata; var currVal_1 = _co.model; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_9 = _co.model.message; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 14).ngClassValid; var currVal_7 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ContactHistoryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.TruncatePipe, []), i1.ɵqud(671088640, 1, { table: 0 }), i1.ɵqud(671088640, 2, { actionsTemplate: 0 }), i1.ɵqud(671088640, 3, { responsibleTemplate: 0 }), i1.ɵqud(671088640, 4, { messageTemplate: 0 }), i1.ɵqud(671088640, 5, { contactHistoryFormTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 15, "div", [["class", "contact-history"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "contact-history__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "contact-history__select-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u7D5E\u308A\u8FBC\u307F\uFF1A"])), (_l()(), i1.ɵeld(11, 0, [["actionFilter", 1]], null, 6, "select", [["class", "contact-history__select select select--bg-gray select--small"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.getContacts(i1.ɵnov(_v, 11).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "option", [["value", "0"]], null, null, null, null, null)), i1.ɵdid(13, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(14, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["\u3059\u3079\u3066\u306E\u30A2\u30AF\u30B7\u30E7\u30F3"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactHistoryComponent_1)), i1.ɵdid(17, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "btn btn--green btn--large"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addContact() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5BFE\u5FDC\u5C65\u6B74\u767B\u9332 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactHistoryComponent_2)), i1.ɵdid(21, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[3, 2], ["responsible", 2]], null, 0, null, View_ContactHistoryComponent_3)), (_l()(), i1.ɵand(0, [[2, 2], ["actions", 2]], null, 0, null, View_ContactHistoryComponent_5)), (_l()(), i1.ɵand(0, [[4, 2], ["message", 2]], null, 0, null, View_ContactHistoryComponent_6)), (_l()(), i1.ɵand(0, [[5, 2], ["contactHistoryForm", 2]], null, 0, null, View_ContactHistoryComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "0"; _ck(_v, 13, 0, currVal_0); var currVal_1 = "0"; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.contactHistoryActions; _ck(_v, 17, 0, currVal_2); var currVal_3 = _co.filteredContacts; _ck(_v, 21, 0, currVal_3); }, null); }
export function View_ContactHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-contact-history", [], null, null, null, View_ContactHistoryComponent_0, RenderType_ContactHistoryComponent)), i1.ɵdid(1, 4898816, null, 0, i10.ContactHistoryComponent, [i5.SafeDatePipe, i11.DynamicFieldService, i12.UserApiService, i13.MasterApiService, i14.AuthService], null, null)], null, null); }
var ContactHistoryComponentNgFactory = i1.ɵccf("ag-contact-history", i10.ContactHistoryComponent, View_ContactHistoryComponent_Host_0, { service: "service", referenceId: "referenceId" }, { errorGetContacts: "errorGetContacts" }, []);
export { ContactHistoryComponentNgFactory as ContactHistoryComponentNgFactory };
