<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">成約登録</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="create-container">
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            [metadata]="mainMeta"
            [model]="mainModel"
            (validated)="onValidityChange($event, 0)"
            (changed)="onMainChange($event)"
            *ngIf="mainMeta"
          ></ag-dynamic-form>
        </div>
      </div>
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            [metadata]="feeMeta"
            [model]="feeModel"
            (validated)="onValidityChange($event, 1)"
            *ngIf="feeMeta"
          ></ag-dynamic-form>
        </div>
        <div class="row__column">
          <div *ngIf="feeSummary != null" class="fee-summary">
            <div class="fee-summary__title">フィー一覧</div>
            <textarea
              class="fee-summary__content"
              (keydown.enter)="$event.stopPropagation()"
              >{{ feeSummary }}</textarea
            >
          </div>
        </div>
      </div>
      <div *ngIf="accountModel && accountMetas" class="row">
        <div class="row__column">
          <div class="header">
            <div class="header__title">計上情報</div>
          </div>
          <table class="account-table">
            <thead>
              <tr>
                <th class="no-border w-130"></th>
                <th class="no-border w-250">担当者</th>
                <th class="no-border w-230">チーム</th>
                <th class="no-border">比率</th>
              </tr>
              <tr>
                <th></th>
                <th>売上金額</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let account of accountModel | keyvalue: asIsOrder"
              >
                <tr>
                  <td rowspan="2" class="side-head">
                    担当{{ account.key | uppercase }}
                    <span
                      *ngIf="account.key === 'ra'"
                      class="side-head__required"
                      >必須</span
                    >
                  </td>
                  <td>
                    <ag-dynamic-form
                      [metadata]="
                        account.key === 'ra'
                          ? requiredAccountMetas.userMeta
                          : accountMetas.userMeta
                      "
                      [model]="account.value"
                      (changed)="onAccountUserChange(account.key)"
                      (validated)="
                        account.key === 'ra'
                          ? onValidityChange($event, 3)
                          : null
                      "
                    ></ag-dynamic-form>
                  </td>
                  <td>
                    <ag-dynamic-form
                      [metadata]="
                        account.key === 'ra'
                          ? requiredAccountMetas.teamMeta
                          : accountMetas.teamMeta
                      "
                      [model]="account.value"
                    ></ag-dynamic-form>
                  </td>
                  <td>
                    <ag-dynamic-form
                      [metadata]="
                        account.key === 'ra'
                          ? requiredAccountMetas.ratioMeta
                          : accountMetas.ratioMeta
                      "
                      [model]="account.value"
                      (changed)="onAccountRatioChange(account.key)"
                    ></ag-dynamic-form>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <ag-dynamic-form
                      [metadata]="
                        account.key === 'ra'
                          ? accountMetas.feeMeta
                          : requiredAccountMetas.feeMeta
                      "
                      [model]="account.value"
                    ></ag-dynamic-form>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="row__column">
          <ag-dynamic-form
            [metadata]="emailMeta"
            [model]="emailModel"
            *ngIf="emailMeta"
            (validated)="onValidityChange($event, 2)"
          ></ag-dynamic-form>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!validity || inClose"
      (click)="register()"
    >
      送信して登録
    </button>
  </div>
</div>
