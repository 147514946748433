import { EventEmitter, OnDestroy, OnInit, Type } from '@angular/core';
export var RefreshEvent;
(function (RefreshEvent) {
    RefreshEvent[RefreshEvent["DELETE"] = 0] = "DELETE";
})(RefreshEvent || (RefreshEvent = {}));
export var NotifyEvent;
(function (NotifyEvent) {
    NotifyEvent[NotifyEvent["CONNECTION_ERROR"] = 0] = "CONNECTION_ERROR";
})(NotifyEvent || (NotifyEvent = {}));
var DetailPage = /** @class */ (function () {
    function DetailPage(router, service, activeRoute) {
        var _this = this;
        this.router = router;
        this.service = service;
        this.activeRoute = activeRoute;
        this.opened = new EventEmitter();
        this.closed = new EventEmitter();
        this.fullMode = true;
        this.readonly = false;
        this.tabCode = '';
        this.origUrl = '';
        this.fullModeUrl = '';
        this.selectedSideActionIndex = 0;
        if (!DetailPage.INSTANCES.find(function (instance) { return instance instanceof _this.constructor; })) {
            DetailPage.INSTANCES.push(this);
        }
        this.routeSubscription = this.activeRoute.params.subscribe(function (params) {
            if (params['id']) {
                _this.referenceId = params['id'];
            }
            if (params['tab']) {
                _this.tabCode = params['tab'];
            }
        });
        this.refreshSubscription = this.service.refreshEvent.subscribe(function (event) { return _this.fill(true, event); });
    }
    Object.defineProperty(DetailPage, "instance", {
        get: function () {
            var _this = this;
            return DetailPage.INSTANCES.find(function (instance) { return instance instanceof _this; });
        },
        enumerable: true,
        configurable: true
    });
    DetailPage.closeAll = function (except) {
        DetailPage.INSTANCES.filter(function (ins) { return !except || except.find(function (e) { return ins instanceof e; }) == null; }).forEach(function (page) { return page.close(); });
    };
    DetailPage.resetAll = function () {
        DetailPage.INSTANCES.forEach(function (page) { return page.reset(); });
    };
    Object.defineProperty(DetailPage.prototype, "referenceId", {
        get: function () {
            return this.innerReferenceId;
        },
        set: function (referenceId) {
            var _this = this;
            this.disableFullScreen = false;
            this.innerReferenceId = referenceId;
            if (this.innerReferenceId) {
                setTimeout(function () { return _this.fill(); }, 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailPage.prototype, "inFullMode", {
        get: function () {
            return this.fullMode;
        },
        set: function (fullMode) {
            var toUpdate = this.fullMode !== fullMode;
            this.fullMode = fullMode;
            if (toUpdate) {
                this.updateUrl();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailPage.prototype, "inSlimMode", {
        get: function () {
            return this.slimMode;
        },
        set: function (slimMode) {
            this.slimMode = slimMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailPage.prototype, "isReadonly", {
        get: function () {
            return this.readonly;
        },
        set: function (readonly) {
            this.readonly = readonly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailPage.prototype, "selectedTabIndex", {
        get: function () {
            return this.tabs[(this.tabCode || '').toUpperCase()] || 0;
        },
        set: function (index) {
            this.tabCode = !this.tabs[index] || this.tabs[index] === 'OVERVIEW' ? '' : this.tabs[index].toLowerCase();
            this.updateUrl();
        },
        enumerable: true,
        configurable: true
    });
    DetailPage.prototype.open = function () {
        this.opened.emit();
        this.origUrl = window.location.href;
    };
    DetailPage.prototype.close = function () {
        this.closed.emit();
    };
    DetailPage.prototype.reset = function () {
        this.referenceId = undefined;
    };
    DetailPage.prototype.updateUrl = function () {
        var _this = this;
        setTimeout(function () {
            _this.fullModeUrl = _this.referenceId ? _this.urlTag + "/" + (_this.referenceId + (_this.tabCode ? '/' + _this.tabCode : '')) : _this.urlTag;
            if (_this.inFullMode) {
                window.history.replaceState({}, undefined, _this.fullModeUrl);
            }
            else if (_this.origUrl) {
                window.history.replaceState({}, undefined, _this.origUrl);
            }
        });
    };
    DetailPage.prototype.ngOnInit = function () {
        this.fill();
    };
    DetailPage.prototype.ngOnDestroy = function () {
        this.routeSubscription.unsubscribe();
        this.refreshSubscription.unsubscribe();
    };
    DetailPage.INSTANCES = [];
    return DetailPage;
}());
export { DetailPage };
