import * as tslib_1 from "tslib";
import { CounselingContentType, CounselingNavService } from '@agent-ds/counseling/services/counseling-nav.service';
import { PageScrollTableComponent } from '@agent-ds/shared/components/page-scroll-table/page-scroll-table.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { AREAS } from '@agent-ds/shared/constants';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DialogService, DynamicFieldService } from '@agent-ds/shared/services';
import { CounselingApiService } from '@agent-ds/shared/services/api/counseling-api.service';
import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NewSeminarRegistrationDialogComponent } from '../new-seminar-registration-dialog/new-seminar-registration-dialog.component';
import { SEMINAR_LIST_TABLE_CONFIG } from './seminar-lister-table-config';
var SeminarListerComponent = /** @class */ (function () {
    function SeminarListerComponent(dialog, counselingApiService, datePipe, dynamicService, cdr, navService) {
        this.dialog = dialog;
        this.counselingApiService = counselingApiService;
        this.datePipe = datePipe;
        this.dynamicService = dynamicService;
        this.cdr = cdr;
        this.navService = navService;
        this.tabCode = 0;
        this.areas = Object.keys(AREAS).map(function (key) { return ({ id: +key, name: AREAS[key] }); });
        this.filterModel = {
            filterRange: Date.currentWeek(),
        };
        this.filterMetadata = {
            groups: [],
        };
    }
    Object.defineProperty(SeminarListerComponent.prototype, "area", {
        set: function (v) {
            this.selectedTabIndex = v && v.id != null ? this.areas.findIndex(function (area) { return area.id === v.id; }) : this.selectedTabIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SeminarListerComponent.prototype, "selectedTabIndex", {
        get: function () {
            return this.tabCode;
        },
        set: function (index) {
            this.tabCode = index;
            var nextSelectedArea = this.areas ? this.areas[index] : null;
            if (nextSelectedArea !== this.selectedArea) {
                this.selectedArea = nextSelectedArea;
                this.getSeminars();
            }
        },
        enumerable: true,
        configurable: true
    });
    SeminarListerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tableConfig = SEMINAR_LIST_TABLE_CONFIG(this.buttonsTemplate, function (date) { return _this.datePipe.transform(date, 'yyyy/MM/dd (E)'); }, function (date) { return _this.datePipe.transform(date, 'HH:mm〜'); });
        this.filterMetadata = {
            groups: [
                {
                    class: 'form__group--no-title-border no-border-around no-background',
                    rows: this.dynamicService.getFormRows({
                        fieldName: 'filterRange',
                        fieldType: 'date-range',
                        displayType: 'date-seminar-list',
                    }).slice(),
                },
            ],
        };
        this.cdr.detectChanges();
        this.refreshSubscription = this.counselingApiService.refreshEvent.subscribe(function () { return _this.getSeminars(); });
    };
    SeminarListerComponent.prototype.ngOnDestroy = function () {
        if (this.seminarCreateDialogSubscription) {
            this.seminarCreateDialogSubscription.unsubscribe();
            this.seminarCreateDialogSubscription = null;
        }
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = null;
        }
    };
    SeminarListerComponent.prototype.openSeminarRegistrationDialog = function () {
        var _this = this;
        var seminarCreateDialogRef = this.dialog.open(NewSeminarRegistrationDialogComponent);
        this.seminarCreateDialogSubscription = seminarCreateDialogRef.afterClosed.subscribe(function (res) {
            if (res) {
                _this.counselingApiService.refreshEvent.next();
            }
        });
    };
    SeminarListerComponent.prototype.onFilterChanged = function () {
        this.getSeminars();
    };
    SeminarListerComponent.prototype.onDelete = function () {
        var _this = this;
        if (!this.table.checkedItems.length) {
            return;
        }
        PopupControlComponent.instance.open({
            content: 'セミナーを削除します。よろしいですか？',
            confirmText: 'OK',
            confirmCallback: function () {
                PopupControlComponent.instance.close();
                _this.counselingApiService
                    .deleteSeminars(_this.table.checkedItems.map(function (seminar) { return seminar.id; }))
                    .subscribe(function (res) {
                    if (res && res.successIds && res.successIds.length) {
                        _this.counselingApiService.refreshEvent.next();
                    }
                    if (res && res.errorIds && res.errorIds.length) {
                        PopupControlComponent.subInstance.open({
                            content: 'データ削除に失敗しました。\n求職者が登録されているセミナーを削除しようとしたか、\n既に削除されている可能性があります。',
                            confirmText: '閉じる',
                            confirmCallback: function () { return false; },
                            title: 'セミナー削除',
                            height: '357px',
                            width: '600px',
                        });
                    }
                });
            },
            title: 'セミナー削除',
            height: '357px',
            width: '600px',
            cancelText: 'キャンセル',
        });
    };
    SeminarListerComponent.prototype.onAttendeeListClick = function (seminar) {
        this.navService.changePageContent.next({
            content: CounselingContentType.attendeeList,
            area: this.selectedArea,
            data: { seminar: seminar },
        });
    };
    SeminarListerComponent.prototype.onJobListClick = function (seminar) {
        this.navService.changePageContent.next({
            content: CounselingContentType.jobList,
            area: this.selectedArea,
            data: { seminar: seminar },
        });
    };
    SeminarListerComponent.prototype.onMasterJobListClick = function () {
        this.navService.changePageContent.next({
            content: CounselingContentType.jobList,
            area: this.selectedArea,
        });
    };
    SeminarListerComponent.prototype.getSeminars = function () {
        var _this = this;
        this.counselingApiService
            .getSeminars(this.selectedArea.id, this.filterModel['filterRange'].from, this.filterModel['filterRange'].to)
            .subscribe(function (response) {
            if (_this.table) {
                _this.table.checkAll(true);
            }
            _this.seminars = response.map(function (res) { return (tslib_1.__assign({}, res, { areaName: AREAS[res.area] || '', isSeminarAtPastDate: new Date(res.seminarAt) < new Date() })); });
        });
    };
    return SeminarListerComponent;
}());
export { SeminarListerComponent };
