/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-sales-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/sales-list/sales-list.component.ngfactory";
import * as i3 from "../../../../../shared/components/sales-list/sales-list.component";
import * as i4 from "../../../../../shared/services/api/sales-api.service";
import * as i5 from "../../../../../shared/services/dialog.service";
import * as i6 from "../../../../../shared/pipes/safe-date.pipe";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../shared/pipes/find.pipe";
import * as i9 from "../../../../../shared/models/tab";
import * as i10 from "./company-sales-tab.component";
var styles_CompanySalesTabComponent = [i0.styles];
var RenderType_CompanySalesTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanySalesTabComponent, data: {} });
export { RenderType_CompanySalesTabComponent as RenderType_CompanySalesTabComponent };
export function View_CompanySalesTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-sales-list", [], null, null, null, i2.View_SalesListComponent_0, i2.RenderType_SalesListComponent)), i1.ɵdid(1, 4964352, null, 0, i3.SalesListComponent, [i4.SalesApiService, i5.DialogService, i6.SafeDatePipe, i7.DecimalPipe, i8.FindPipe, i1.ChangeDetectorRef], { requestObject: [0, "requestObject"], options: [1, "options"] }, null), i1.ɵpod(2, { refundDate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requestObject; var currVal_1 = _ck(_v, 2, 0, false); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CompanySalesTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-company-sales-tab", [], null, null, null, View_CompanySalesTabComponent_0, RenderType_CompanySalesTabComponent)), i1.ɵprd(6144, null, i9.Tab, null, [i10.CompanySalesTabComponent]), i1.ɵdid(2, 573440, null, 0, i10.CompanySalesTabComponent, [], null, null)], null, null); }
var CompanySalesTabComponentNgFactory = i1.ɵccf("ag-company-sales-tab", i10.CompanySalesTabComponent, View_CompanySalesTabComponent_Host_0, { companyId: "companyId" }, {}, []);
export { CompanySalesTabComponentNgFactory as CompanySalesTabComponentNgFactory };
