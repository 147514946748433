<div class="header">担当者一覧</div>
<div class="list">
  <ng-container *ngFor="let group of groupsToShow">
    <div class="list__header">{{ group.title }}</div>
    <div class="list__empty" *ngIf="group.users.length === 0">
      登録されていません
    </div>
    <div class="list__row" *ngFor="let person of group.users">
      <div class="list__column">
        <div class="list__text" [title]="person.name">{{ person.name }}</div>
      </div>
      <div class="list__column">
        <div
          class="list__badge badge"
          *ngFor="let type of person.types"
          [agClass]="departmentUserType[type]"
          [classOptions]="RAPACA_VALUE_BADGE_CLASSES"
        >
          {{ departmentUserType[type] }}
        </div>
      </div>
      <div class="list__column">
        <div class="list__text list__text--light" [title]="person.teamName">
          {{ person.teamName }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
