<div class="contact-history">
  <div class="contact-history__header">
    <div>
      <span class="contact-history__select-label">絞り込み：</span>
      <select
        #actionFilter
        class="contact-history__select select select--bg-gray select--small"
        (change)="getContacts(actionFilter.value)"
      >
        <option value="0">すべてのアクション</option>
        <option
          *ngFor="let option of contactHistoryActions"
          [value]="option.value"
          >{{ option.label }}</option
        >
      </select>
    </div>
    <span class="btn btn--green btn--large" (click)="addContact()">
      対応履歴登録
    </span>
  </div>
  <ag-page-scroll-table
    *ngIf="filteredContacts"
    class="contact-history__table"
    [tableConfig]="tableConfig"
    [content]="filteredContacts"
  >
  </ag-page-scroll-table>
</div>

<ng-template #responsible let-data>
  <ng-container *ngIf="findUserTeam(data.userId) | async as info">
    {{ info.teamName }}<br />
    {{ info.userName }}
  </ng-container>
</ng-template>

<ng-template #actions let-data>
  <div class="actions">
    <button
      class="actions__control btn btn--white btn--outline-gray btn--small"
      (click)="updateContact(data)"
    >
      編集
    </button>
    <button
      class="actions__control btn btn--white btn--outline-gray btn--small"
      (click)="deleteContact(data.id)"
    >
      削除
    </button>
  </div>
</ng-template>

<ng-template #message let-data>
  <div class="message">
    <span class="message__text">
      {{
        data?.message
          | truncate
            : (truncateAt[data.id] === undefined
                ? defaultTruncateAt
                : truncateAt[data.id])
            : (truncateAt[data.id] === null ? 0 : defaultTruncateLines)
      }}
    </span>
    <div *ngIf="isTruncated(data?.message)" class="message__footer">
      <div class="message__btn open-btn" (click)="toggleText(data.id)">
        <div class=" open-btn__label">
          {{ isOpen(data.id) ? "閉じる" : "開く" }}
        </div>
        <div
          class="open-btn__arrow"
          [class.open-btn__arrow--down]="!isOpen(data.id)"
          [class.open-btn__arrow--up]="isOpen(data.id)"
        ></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contactHistoryForm>
  <div class="contact-form">
    <p class="contact-form__header">対応履歴を登録します。</p>
    <ag-dynamic-form
      [model]="model"
      [metadata]="metadata"
      class="contact-form__column contact-form__column"
      (validated)="validForm = $event"
    ></ag-dynamic-form>
    <div class="contact-form__column contact-form__column">
      <div class="contact-form__form-group contact-form__form-group--textarea">
        <p class="contact-form__input-label">
          内容
        </p>
        <textarea
          class="contact-form__input contact-form__input--textarea"
          (keydown.enter)="$event.stopPropagation()"
          [(ngModel)]="model.message"
        ></textarea>
      </div>
    </div>
  </div>
</ng-template>
