/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings-mail-templates-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i4 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../shared/pipes/safe-date.pipe";
import * as i7 from "../../../shared/pipes/truncate.pipe";
import * as i8 from "./settings-mail-templates-page.component";
import * as i9 from "../../../shared/services/api/mail-api.service";
var styles_SettingsMailTemplatesPageComponent = [i0.styles];
var RenderType_SettingsMailTemplatesPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsMailTemplatesPageComponent, data: {} });
export { RenderType_SettingsMailTemplatesPageComponent as RenderType_SettingsMailTemplatesPageComponent };
function View_SettingsMailTemplatesPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], [[2, "table--bg-dark", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "table__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [["class", "table__status"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "td", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "table__btn btn btn--small btn--bg-white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMailTemplateEdit(_v.context.$implicit.mailTemplateTypeId, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "]))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.status === 1); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_v.context.$implicit.status === 1) ? "\u975E\u8868\u793A" : ""); _ck(_v, 4, 0, currVal_2); }); }
function View_SettingsMailTemplatesPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["class", "table--bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["class", "table__status"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30B9\u30C6\u30FC\u30BF\u30B9"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "th", [["class", "table__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "table__btn btn btn--small btn--dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMailTemplateEdit(_v.context.$implicit.mailType.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u65B0\u898F\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8\u767B\u9332 "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsMailTemplatesPageComponent_2)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.mailTemplates; _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.mailType.name; _ck(_v, 4, 0, currVal_0); }); }
function View_SettingsMailTemplatesPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "form__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "form__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u672C\u6587"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "form__action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPlaceholderWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u5DEE\u3057\u8FBC\u307F\u5909\u6570\u306B\u95A2\u3059\u308B\u8AAC\u660E "]))], null, null); }
function View_SettingsMailTemplatesPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i3.View_DynamicFormComponent_0, i3.RenderType_DynamicFormComponent)), i1.ɵdid(2, 442368, [[3, 4], ["mailTemplateForm", 4]], 0, i4.DynamicFormComponent, [i5.FormBuilder, i1.KeyValueDiffers, i6.SafeDatePipe, i7.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mailTemplatePopupMeta; var currVal_1 = _co.mailTemplatePopupModel; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SettingsMailTemplatesPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mailTemplatePopupTemplate: 0 }), i1.ɵqud(402653184, 2, { mailBodyHeader: 0 }), i1.ɵqud(671088640, 3, { mailTemplateFormComponent: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7BA1\u7406\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "sub-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30E1\u30FC\u30EB\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8\u7BA1\u7406 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsMailTemplatesPageComponent_1)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["mailBodyHeader", 2]], null, 0, null, View_SettingsMailTemplatesPageComponent_3)), (_l()(), i1.ɵand(0, [[1, 2], ["mailTemplatePopup", 2]], null, 0, null, View_SettingsMailTemplatesPageComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mailTypeGroups; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_SettingsMailTemplatesPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-settings-mail-templates-page", [], null, null, null, View_SettingsMailTemplatesPageComponent_0, RenderType_SettingsMailTemplatesPageComponent)), i1.ɵdid(1, 114688, null, 0, i8.SettingsMailTemplatesPageComponent, [i9.MailApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsMailTemplatesPageComponentNgFactory = i1.ɵccf("ag-settings-mail-templates-page", i8.SettingsMailTemplatesPageComponent, View_SettingsMailTemplatesPageComponent_Host_0, {}, {}, []);
export { SettingsMailTemplatesPageComponentNgFactory as SettingsMailTemplatesPageComponentNgFactory };
