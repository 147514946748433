import * as tslib_1 from "tslib";
import { HelperBase } from './helper-base';
var BillingExtraInformationHelper = /** @class */ (function (_super) {
    tslib_1.__extends(BillingExtraInformationHelper, _super);
    function BillingExtraInformationHelper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endOfMonthActionProp = {
            subscribers: [],
            sameBlock: true,
            actions: [
                {
                    title: '当月末',
                    type: 'RUNNABLE',
                    runnable: function () { return new Date().toEndOfMonth(); },
                },
            ],
        };
        _this.endOfNextMonthActionProp = {
            subscribers: [],
            sameBlock: true,
            overwrite: true,
            actions: [
                {
                    title: '来月末',
                    type: 'RUNNABLE',
                    runnable: function () { return new Date().addMonths(1).toEndOfMonth(); },
                },
            ],
        };
        _this.editable = { rows: [] };
        _this.matrix = [];
        return _this;
    }
    BillingExtraInformationHelper.prototype.updateFieldDefinitions = function () {
        this.editable = this.getTemplate(true);
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [null, null, null, null],
            [null, null, null, null],
            [this.editable, this.editable, this.editable, this.editable],
            [null, null, null, null],
        ];
    };
    BillingExtraInformationHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus]];
    };
    BillingExtraInformationHelper.prototype.getTemplate = function (editable) {
        var res = {
            // Billing information
            title: '請求情報',
            class: 'form__group--golden-title-border',
            rows: this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'invoiceConfirmation'), null, 'sales.dynamicData').concat(this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'invoiceDate'), this.endOfMonthActionProp, 'sales.dynamicData'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'transferDate'), this.endOfNextMonthActionProp, 'sales.dynamicData'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'transferConfirmation'), null, 'sales.dynamicData'), this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'billingLinkageStatus'), null, 'sales.dynamicData'), this.getDynamicRowOrLabel(false, tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'billingLinkageDate'), { validationStyle: { required: false } }), null, 'sales.dynamicData', 'half', function (val) { return val; })),
        };
        this.setupAction(this.endOfMonthActionProp);
        this.setupAction(this.endOfNextMonthActionProp);
        return res;
    };
    return BillingExtraInformationHelper;
}(HelperBase));
export { BillingExtraInformationHelper };
