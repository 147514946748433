import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { ClickOutsideDirective } from '@agent-ds/shared/directives/click-outside.directive';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { deepCompare, getValueFromObject } from '@agent-ds/shared/util/util';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { ControlValueAccessor, FormControl, ValidationErrors, Validator } from '@angular/forms';
var SelectComponent = /** @class */ (function () {
    function SelectComponent(cdr) {
        var _this = this;
        this.cdr = cdr;
        /**
         * Use the hidden _selectLevel(: number > 0) field in an option to indent it, like a tree.
         */
        this.options = [];
        this.multi = false;
        this.valueChange = new EventEmitter();
        this.closed = true;
        this.tempSelected = {};
        this.getValueFromObject = getValueFromObject;
        this.alive = true;
        this.propagateChange = function () { return _this.valueChange.emit(_this.value); };
        this.propagateTouch = function () { return false; };
    }
    SelectComponent.prototype.ngOnInit = function () {
        this.alive = true;
        this.clickDir.detach();
    };
    SelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes['options'] || changes['value']) {
            this.refreshTemp();
        }
        if (changes['multi'] && changes['multi'].previousValue !== changes['multi'].currentValue) {
            this.value = !changes['multi'].currentValue ? (Array.isArray(this.value) ? this.value[0] : null) : this.value ? [this.value] : [];
        }
    };
    SelectComponent.prototype.ngOnDestroy = function () {
        this.alive = false;
        this.propagateChange = this.propagateTouch = function () { return false; };
    };
    SelectComponent.prototype.open = function () {
        if (this.disabled) {
            return;
        }
        if (!this.closed) {
            this.onOutsideClick(!this.confirmLabel);
        }
        else {
            this.closed = false;
            this.clickDir.attach();
            this.detect();
        }
    };
    SelectComponent.prototype.onOutsideClick = function (confirm) {
        if (!this.closed) {
            if (confirm) {
                this.confirm();
            }
            else {
                this.refreshTemp();
            }
            this.closed = true;
            this.clickDir.detach();
            this.detect();
        }
    };
    SelectComponent.prototype.onSpecificValueChanged = function (index) {
        var _this = this;
        this.tempSelected['all'] = false;
        if (this.multi || !this.compare(this.value, this.options[index])) {
            if (!this.multi) {
                Object.keys(this.tempSelected).forEach(function (key) { return (_this.tempSelected[key] = false); });
            }
            this.tempSelected[index] = !this.tempSelected[index];
            this.confirm(null, this.multi);
        }
        else if (!this.multi) {
            this.closed = true;
            this.clickDir.detach();
            this.detect();
        }
    };
    SelectComponent.prototype.compare = function (value, option) {
        var _this = this;
        return typeof this.valueField === 'string'
            ? value === getValueFromObject(option, this.valueField) ||
                (value == null && NULL_SELECTED_VALUE === getValueFromObject(option, this.valueField))
            : typeOf(this.valueField) === 'object' && value != null
                ? Object.keys(this.valueField).find(function (key) { return value[_this.valueField[key]] === option[_this.valueField[key]]; }) != null
                : value === option || (value == null && option === NULL_SELECTED_VALUE) || deepCompare(value, option, 'id');
    };
    SelectComponent.prototype.compareAll = function (value, option) {
        var _this = this;
        return value.find(function (val) { return _this.compare(val, option); }) !== undefined;
    };
    SelectComponent.prototype.refreshTemp = function () {
        var _this = this;
        this.tempSelected['all'] = this.allLabel && this.allSelected;
        this.options.forEach(function (option, index) {
            return (_this.tempSelected[index] =
                !_this.tempSelected['all'] &&
                    (!_this.multi ? _this.compare(_this.value, option) : _this.value && _this.compareAll(_this.value, option)));
        });
    };
    Object.defineProperty(SelectComponent.prototype, "disabled", {
        get: function () {
            return (this.readonly ||
                !this.options ||
                !this.options.length ||
                (this.options.length === 1 &&
                    (this.labelField ? getValueFromObject(this.options[0], this.labelField) : this.options[0]) === NULL_SELECTED_VALUE));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "selectedLabel", {
        get: function () {
            var _this = this;
            if (this.multi) {
                if (this.allLabel && this.allSelected) {
                    return this.allLabel;
                }
                return this.options
                    .filter(function (item) { return _this.compareAll(_this.value, item); })
                    .map(function (item) {
                    return _this.labelField
                        ? getValueFromObject(item, _this.labelField) ||
                            (typeof _this.valueField === 'string' ? getValueFromObject(item, _this.valueField) : item)
                        : item;
                })
                    .join(', ');
            }
            else {
                var selected = this.options.find(function (option) { return _this.compare(_this.value, option); });
                if (selected == null &&
                    this.options.length &&
                    (this.labelField ? getValueFromObject(this.options[0], this.labelField) : this.options[0]) === NULL_SELECTED_VALUE) {
                    selected = this.options[0];
                }
                if (selected) {
                    return this.labelField ? getValueFromObject(selected, this.labelField) : selected;
                }
            }
            return this.placeholder;
        },
        enumerable: true,
        configurable: true
    });
    SelectComponent.prototype.selectAll = function (unselect) {
        var _this = this;
        if (unselect === void 0) { unselect = false; }
        this.tempSelected['all'] = !unselect;
        Object.keys(this.tempSelected).forEach(function (key) { return (_this.tempSelected[key] = !unselect); });
        if (this.closed || !this.confirmLabel) {
            this.confirm(null, !this.closed);
        }
    };
    SelectComponent.prototype.toggle = function (key) {
        this.tempSelected[key] = !this.tempSelected[key];
        this.detect();
    };
    SelectComponent.prototype.confirm = function (force, suppressEvent) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (suppressEvent === void 0) { suppressEvent = false; }
        this.propagateTouch();
        if (!this.multi) {
            var selected = Object.keys(this.tempSelected).find(function (key) { return _this.tempSelected[key]; });
            this.value = selected
                ? typeof this.valueField === 'string'
                    ? getValueFromObject(this.options[selected], this.valueField)
                    : this.options[selected]
                : null;
            this.options.forEach(function (option, index) { return (_this.tempSelected[index] = false); });
        }
        else {
            if (this.value == null) {
                this.value = [];
            }
            this.value.length = 0;
            if (this.tempSelected['all']) {
                this.allSelected = true;
                this.options.forEach(function (option, index) {
                    _this.tempSelected[index] = false;
                    if (!_this.allIsEmpty) {
                        _this.value.push(typeof _this.valueField === 'string' ? getValueFromObject(option, _this.valueField) : option);
                    }
                });
            }
            else {
                delete this.tempSelected['all'];
                this.allSelected = false;
                Object.keys(this.tempSelected)
                    .filter(function (key) { return _this.tempSelected[key]; })
                    .forEach(function (key) { return _this.value.push(typeof _this.valueField === 'string' ? _this.options[key][_this.valueField] : _this.options[key]); });
            }
        }
        if (!suppressEvent) {
            this.propagateChange();
        }
        if ((!this.multi && !this.confirmLabel) || force) {
            this.closed = true;
            this.clickDir.detach();
        }
        this.refreshTemp();
        this.detect();
    };
    SelectComponent.prototype.writeValue = function (value) {
        this.value = this.multi && !Array.isArray(value) ? [] : value;
        this.refreshTemp();
        this.detect();
    };
    SelectComponent.prototype.registerOnChange = function (fn) {
        var _this = this;
        this.propagateChange = function () {
            fn(_this.value);
            _this.valueChange.emit(_this.value);
        };
    };
    SelectComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouch = fn;
    };
    SelectComponent.prototype.setDisabledState = function (isDisabled) {
        this.readonly = isDisabled;
    };
    SelectComponent.prototype.validate = function (c) {
        var _this = this;
        if (!this.alive) {
            return null;
        }
        var valid = {};
        if (this.validators) {
            Object.keys(this.validators).forEach(function (key) {
                var ret = typeof _this.validators[key] === 'function' ? _this.validators[key](c) : null;
                if ((key === 'required' &&
                    _this.validators[key] &&
                    (_this.value == null || (_this.multi && !_this.value.length) || _this.value === NULL_SELECTED_VALUE)) ||
                    (key === 'min' && _this.multi && _this.value.length < _this.validators[key]) ||
                    (key === 'max' && _this.multi && _this.value.length > _this.validators[key]) ||
                    ret != null) {
                    valid[key] = ret || true;
                }
            });
        }
        return Object.keys(valid).length ? valid : null;
    };
    SelectComponent.prototype.detect = function () {
        if (this.alive) {
            this.cdr.detectChanges();
        }
    };
    return SelectComponent;
}());
export { SelectComponent };
