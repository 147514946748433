import { StudentJobMatchingPageComponent } from '@agent-ds/matching/pages';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentDetailConfig } from '@agent-ds/shared/components/mail-send/configs/student-detail-config';
import { StudentSendConfig } from '@agent-ds/shared/components/mail-send/configs/student-send-config';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { ProfileImageComponent } from '@agent-ds/shared/components/profile-image/profile-image.component';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, StudentApiService } from '@agent-ds/shared/services';
import { getValueFromObject } from '@agent-ds/shared/util/util';
import { Location } from '@angular/common';
import { ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { tap } from 'rxjs/operators';
var StudentDetailHeaderComponent = /** @class */ (function () {
    function StudentDetailHeaderComponent(datePipe, studentService, location, authService) {
        this.datePipe = datePipe;
        this.studentService = studentService;
        this.location = location;
        this.authService = authService;
        this.student = null;
        this.progresses = [];
        this.refreshButtonFlashedTimerId = 0;
        this.messageShown = false;
        this.tempMessages = [];
        this.showPlus = false;
        this.isRefreshButtonFlashed = false;
        this.detailConfig = new StudentDetailConfig();
        this.sendConfig = new StudentSendConfig();
    }
    StudentDetailHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        // イベントを受け取ったときに、ボタンを点滅させる
        this.studentService.notifyConnectionErrorEvent.subscribe(function () {
            // すでに点滅表示用のタイマーが設定されている場合は一度クリアし、点滅解除
            if (_this.refreshButtonFlashedTimerId) {
                window.clearTimeout(_this.refreshButtonFlashedTimerId);
                _this.isRefreshButtonFlashed = false;
            }
            else {
                // do nothing
            }
            // 点滅設定
            _this.isRefreshButtonFlashed = true;
            _this.refreshButtonFlashedTimerId = window.setTimeout(function () {
                _this.isRefreshButtonFlashed = false;
            }, 10 * 1000);
        });
    };
    StudentDetailHeaderComponent.prototype.ngOnDestroy = function () {
        if (this.studentService.notifyConnectionErrorEvent) {
            this.studentService.notifyConnectionErrorEvent.unsubscribe();
        }
    };
    StudentDetailHeaderComponent.prototype.ngOnChanges = function (changes) {
        if (changes['student']) {
            if (this.imgComponent &&
                changes['student'].previousValue &&
                changes['student'].currentValue &&
                changes['student'].previousValue.id === changes['student'].currentValue.id) {
                this.imgComponent.reload();
            }
            this.messageShown = false;
            this.messageEdit = [];
            this.tempMessages = [];
            if (this.msgContainerEl) {
                this.msgContainerEl.nativeElement.scrollTop = 0;
            }
        }
        if (changes['progresses']) {
            this.showPlus = this.progresses.find(function (p) { return p.type === 1; }) != null;
        }
    };
    StudentDetailHeaderComponent.prototype.onMessageDelete = function (i) {
        var _this = this;
        PopupControlComponent.instance.open({
            content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
            confirmText: '確定',
            confirmCallback: function () {
                return _this.studentService
                    .deleteNote(_this.student.id, _this.student.studentNotes[i].id)
                    .pipe(tap(function () { return _this.studentService.refreshEvent.emit(); }));
            },
            cancelText: 'キャンセル',
            title: '伝言の削除',
            height: '357px',
            width: '600px',
        });
    };
    StudentDetailHeaderComponent.prototype.onMessageEditDone = function (i, save, value) {
        var _this = this;
        if (save === void 0) { save = false; }
        if (save) {
            this.student.studentNotes[i].note = value;
            if (this.student.studentNotes[i].id) {
                this.studentService
                    .updateNote(this.student.id, this.student.studentNotes[i])
                    .subscribe(function () { return _this.studentService.refreshEvent.emit(); });
            }
            else {
                this.studentService.addNote(this.student.id, this.student.studentNotes[i]).subscribe(function () { return _this.studentService.refreshEvent.emit(); });
            }
        }
        else if (!this.student.studentNotes[i].id) {
            this.student.studentNotes.splice(i, 1);
        }
        this.messageEdit[i] = false;
    };
    StudentDetailHeaderComponent.prototype.onMessageAdd = function () {
        var baseMessage = this.datePipe.transform(Date.now(), 'yyyy/MM/dd (E) ');
        this.student.studentNotes.unshift({ note: baseMessage });
        this.tempMessages.unshift(baseMessage);
        this.messageEdit.unshift(true);
        this.messageShown = true;
    };
    Object.defineProperty(StudentDetailHeaderComponent.prototype, "loggedInText", {
        get: function () {
            if (this.student.mypageLoggedInAt) {
                var diff = Math.trunc((Date.now() - Date.parse(this.student.mypageLoggedInAt.toString())) / 86400000);
                return diff < 1 ? '今日' : diff + '日前';
            }
            return '-';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudentDetailHeaderComponent.prototype, "editMode", {
        get: function () {
            return this.messageEdit.reduce(function (prev, curr) { return prev || curr; }, false);
        },
        enumerable: true,
        configurable: true
    });
    StudentDetailHeaderComponent.prototype.openMailSend = function (config) {
        if (config === void 0) { config = this.detailConfig; }
        if (this.authService.authInfo.zone !== 'zone3') {
            PopupControlComponent.instance.open({
                title: null,
                content: 'ZONE3専用です。',
                confirmText: 'OK',
                confirmCallback: function () { return false; },
            });
            return;
        }
        MailSendFlowComponent.instance.config = config;
        config.setParams({
            student: {
                id: this.student.id,
                frontId: this.student.frontId,
                firstName: this.student.dynamicData.firstName,
                lastName: this.student.dynamicData.lastName,
                age: null,
                prefecture: null,
                schoolName: null,
                schoolDepartmentName: null,
                studentUser: this.student.studentUsers,
                emailMain: getValueFromObject(this.student, 'dynamicData.emailMain.email'),
                emailMainAvailable: getValueFromObject(this.student, 'dynamicData.emailMain.emailAvailable'),
                emailSub: getValueFromObject(this.student, 'dynamicData.emailSub.email'),
                emailSubAvailable: getValueFromObject(this.student, 'dynamicData.emailSub.emailAvailable'),
            },
        });
        MailSendFlowComponent.instance.start();
    };
    StudentDetailHeaderComponent.prototype.openMatching = function () {
        StudentJobMatchingPageComponent.instance.referenceId = this.student.id;
        StudentJobMatchingPageComponent.instance.open();
    };
    StudentDetailHeaderComponent.prototype.refresh = function () {
        this.studentService.refreshEvent.emit();
    };
    return StudentDetailHeaderComponent;
}());
export { StudentDetailHeaderComponent };
