import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { CompanyApiService } from '@agent-ds/shared/services';
var CompanyContactHistoryTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyContactHistoryTabComponent, _super);
    function CompanyContactHistoryTabComponent(companyApiService) {
        var _this = _super.call(this) || this;
        _this.companyApiService = companyApiService;
        return _this;
    }
    return CompanyContactHistoryTabComponent;
}(Tab));
export { CompanyContactHistoryTabComponent };
