import * as tslib_1 from "tslib";
import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { SalesDetailPageComponent } from '@agent-ds/sales/sales-detail-page/sales-detail-page.component';
import { APPROVAL_CANCEL_STATES, APPROVAL_STATES, GENDER_VALUE_BADGE_CLASSES, RAPACA_VALUE_BADGE_CLASSES, } from '@agent-ds/shared/constants';
import { FindPipe } from '@agent-ds/shared/pipes/find.pipe';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DialogService, SalesApiService } from '@agent-ds/shared/services';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { SALE_LIST_TABLE_CONFIG } from './sales-list-table-config';
var SalesListComponent = /** @class */ (function () {
    function SalesListComponent(salesApiService, dialog, datePipe, decimalPipe, statusPipe, cdr) {
        var _this = this;
        this.salesApiService = salesApiService;
        this.dialog = dialog;
        this.datePipe = datePipe;
        this.decimalPipe = decimalPipe;
        this.statusPipe = statusPipe;
        this.cdr = cdr;
        this.requestObject = {};
        this.options = {};
        this.itemChecked = new EventEmitter();
        this.RAPACA_VALUE_BADGE_CLASSES = RAPACA_VALUE_BADGE_CLASSES;
        this.GENDER_VALUE_BADGE_CLASSES = GENDER_VALUE_BADGE_CLASSES;
        this.initialized = false;
        this.loadList = function (page, size, sort, order) {
            var params = _this.cleanObject(tslib_1.__assign({}, _this.requestObject, { from: page * size, size: size, sort: sort, order: order }));
            return _this.salesApiService.getList(params).pipe(map(function (res) { return _this.convertSalesList(res); }));
        };
    }
    SalesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refreshSubscription = this.salesApiService.refreshEvent.subscribe(function () { return _this.ngOnChanges(); });
    };
    SalesListComponent.prototype.ngOnDestroy = function () {
        this.refreshSubscription.unsubscribe();
    };
    SalesListComponent.prototype.cleanObject = function (obj) {
        for (var propName in obj) {
            if (obj[propName] == null || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj;
    };
    SalesListComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || changes['requestObject'].previousValue !== changes['requestObject'].currentValue) {
            this.cdr.detectChanges();
            if (this.table && this.initialized) {
                this.table.reset();
                this.table.next();
            }
        }
    };
    Object.defineProperty(SalesListComponent.prototype, "hasCheckedItems", {
        get: function () {
            return this.table && this.table.hasCheckedItems;
        },
        enumerable: true,
        configurable: true
    });
    SalesListComponent.prototype.convertSalesList = function (res) {
        // default result
        var result = {
            result: [],
            totalSize: res && res.total ? res.total : 0,
        };
        // map all elements to make object root to _source property
        if (res && res.saleses && res.saleses.length > 0) {
            result.result = res.saleses;
        }
        return result;
    };
    SalesListComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig = SALE_LIST_TABLE_CONFIG(this.agTemplate, this.rowHeaderCompanyTemplate, this.rowHeaderJobSeekerTemplate, this.personInChargeTemplate, function (date) { return _this.datePipe.transform(date, 'yyyy/MM/dd'); }, function (status) { return (_this.statusPipe.transform(APPROVAL_STATES, status, 'code') || { name: '-' }).name; }, function (cancelStatus) {
            return cancelStatus === 1 ? '-' : (_this.statusPipe.transform(APPROVAL_CANCEL_STATES, cancelStatus, 'code') || { name: '-' }).name;
        }, function (num) { return _this.decimalPipe.transform(num, '0.0-2'); }, this.options);
        this.cdr.detectChanges();
        this.initialized = true;
        if (this.requestObject && this.table) {
            this.table.reset(true);
            this.table.next();
        }
    };
    SalesListComponent.prototype.onSaleSelect = function (sale) {
        SalesDetailPageComponent.instance.referenceId = sale ? sale.id : null;
        SalesDetailPageComponent.instance.open();
        this.cdr.detectChanges();
    };
    SalesListComponent.prototype.onCompanyLinkClick = function (event, id) {
        this.onLinkClick(event, CompanyDetailPageComponent.instance, id);
    };
    SalesListComponent.prototype.onJobLinkClick = function (event, id) {
        this.onLinkClick(event, JobDetailPageComponent.instance, id);
    };
    SalesListComponent.prototype.onStudentLinkClick = function (event, id) {
        this.onLinkClick(event, StudentDetailPageComponent.instance, id);
    };
    SalesListComponent.prototype.onLinkClick = function (event, instance, id) {
        event.stopPropagation();
        if (id) {
            instance.referenceId = id;
            instance.open();
        }
    };
    SalesListComponent.prototype.openSalesLinkBtoBDialog = function () {
        var _this = this;
        if (!this.hasCheckedItems) {
            return;
        }
        var config = {
            title: '請求書連携 → BtoB',
            messages: [
                "\u6B21\u306E\u5236\u7D04\u3092BtoB\u3078\u9023\u643A\u3057\u3001\u8ACB\u6C42\u66F8\u3092\u4F5C\u6210\u3057\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F",
                "\n",
                this.table.checkedItems.map(function (item) { return (item && item['id'] ? item['frontId'] : null); }).join('\n'),
            ],
            style: {
                height: 'auto',
                width: '600px',
            },
            buttons: {
                no: 'キャンセル',
                yes: 'OK',
            },
        };
        this.dialog.open(ConfirmDialogComponent, config, function (result) {
            if (result) {
                return _this.salesApiService
                    .linkBtoBSales(_this.table.checkedItems.map(function (item) { return (item && item['id'] ? item['id'] : null); }).filter(function (v) { return v != null; }))
                    .pipe(tap(function (datas) {
                    var messages = '';
                    if (datas) {
                        for (var _i = 0, datas_1 = datas; _i < datas_1.length; _i++) {
                            var data = datas_1[_i];
                            var status_1 = data.status ? '成功' : '失敗';
                            messages += "=====\n\u767A\u884C\u5148ID: " + data.privateCustCd + "\u3001\n\u8ACB\u6C42\u540D: " + data.invName + "\u3001\n\u30B9\u30C6\u30FC\u30BF\u30B9: " + status_1 + "\u3001\n\u30E1\u30C3\u30BB\u30FC\u30B8: " + data.message + "\n=====\n\n";
                        }
                    }
                    var config2 = {
                        title: 'BtoB請求書連携結果',
                        messages: [messages],
                        style: {
                            height: 'auto',
                            width: '600px',
                        },
                        buttons: {
                            no: null,
                            yes: 'OK',
                            hideNo: true,
                        },
                    };
                    _this.dialog.open(ConfirmDialogComponent, config2, function (result2) {
                        if (result2) {
                            _this.salesApiService.refreshEvent.emit();
                        }
                    });
                }));
            }
        });
    };
    return SalesListComponent;
}());
export { SalesListComponent };
