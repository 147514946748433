<div class="content" [class.content--hidden]="showList">
  <div class="row">
    <div class="row__column">
      <div class="matching-parameters">
        <div class="matching-parameters__header">
          <span class="matching-parameters__header__title"
            >求職者検索マッチング条件</span
          >
          <span class="matching-parameters__header__subtitle"
            >保存された検索条件</span
          >
        </div>
        <div class="matching-parameters__list">
          <ag-chip
            class="chip chip--green"
            [label]="matching.name"
            (toggled)="matchingSelected($event, matching)"
            (removed)="deleteMatching(matching)"
            [selected]="matching.id === selectedMatching?.id"
            *ngFor="let matching of matchings"
          ></ag-chip>
        </div>
      </div>
      <ag-student-search
        #matchingStudentSearch
        [searchModel]="studentSearchObj"
        (search)="doStudentSearch($event)"
        (filterChanged)="onFilterChanged($event)"
        class="student-search-matching"
      ></ag-student-search>
    </div>
    <div class="row__column">
      <ag-job-detail-page
        #matchingJobDetails
        class="job-detail"
        [inFullMode]="false"
        [isReadonly]="true"
      ></ag-job-detail-page>
    </div>
  </div>
</div>

<div class="student-list" *ngIf="showList">
  <div class="header">
    <span class="header__title">求職者検索マッチング条件</span>
    <button class="header__modify" (click)="toSearch()">
      マッチング条件を変更<span
        class="header__modify__arrow header__modify__arrow--left"
      ></span>
    </button>
  </div>

  <ag-job-detail-header
    [job]="job"
    class="job-detail-header"
    [readonly]="true"
  ></ag-job-detail-header>

  <ag-student-list
    class="student-list-content"
    #matchingStudentList
    [requestObject]="studentSearchObj"
    [options]="listConfiguration"
    [extraControls]="extraButtons"
    [job]="job"
  >
  </ag-student-list>
</div>
<div class="footer">
  <div class="footer__container">
    <button
      class="footer__btn footer__btn--outline-white footer__btn--bg-transparent footer__btn--hover-blue footer__btn--active-blue"
      (click)="saveMatching()"
    >
      マッチング条件を保存
    </button>
    <button
      *ngIf="!showList"
      class="footer__btn footer__btn--green"
      (click)="matchingStudentSearch.doSearch()"
    >
      検索
    </button>
  </div>
</div>

<ng-template #extraButtons>
  <button
    [disabled]="
      studentList?.checkedItems.length > 20 ||
      !studentList?.checkedItems.length ||
      (studentList?.allChecked && studentList?.totalSize > 20)
    "
    class="content__controls__control"
    (click)="createProgress()"
  >
    進捗登録
  </button>
</ng-template>
