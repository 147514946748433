<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">一括更新</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div *ngIf="!finished">
      <ag-dynamic-form
        class="select-container"
        [metadata]="metadata"
        [model]="model"
        (changed)="updateChange()"
      ></ag-dynamic-form>
    </div>
    <div class="caption" *ngIf="finished">
      {{ updatedCount }}件更新しました。
    </div>
  </div>
  <div class="dialog__footer">
    <button
      *ngIf="!finished"
      class="dialog__button dialog__button--accent"
      (click)="close()"
    >
      キャンセル
    </button>
    <button
      *ngIf="!finished"
      class="dialog__button dialog__button--primary"
      (click)="save()"
      [disabled]="!fieldSelected || !fieldValue || inClose || dialog.inClose"
    >
      一括更新
    </button>
    <button
      *ngIf="finished"
      class="dialog__button dialog__button--primary"
      (click)="close()"
    >
      閉じる
    </button>
  </div>
</div>
