<div class="header">
  <span class="header__title">進捗リスト</span>
</div>
<div class="page">
  <div class="section">
    <ag-dynamic-form
      [metadata]="formMeta"
      [model]="formModel"
      *ngIf="formMeta"
    ></ag-dynamic-form>
  </div>
</div>
<div class="footer">
  <button
    class="footer__btn footer__btn--green"
    [disabled]="isExportInProgress"
    (click)="export()"
  >
    出力
  </button>
</div>
