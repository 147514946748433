import * as tslib_1 from "tslib";
import { NavigationEnd } from '@angular/router';
import { PageFloaterComponent } from '../components/page-floater/page-floater.component';
import { parseQueryParam } from '../util/util';
var ListPage = /** @class */ (function () {
    function ListPage(router, location, activeRoute, exportService) {
        this.router = router;
        this.location = location;
        this.activeRoute = activeRoute;
        this.exportService = exportService;
        this.exportTemplates = [];
        this.searchObj = {};
    }
    ListPage.prototype.ngOnInit = function () {
        var _this = this;
        this.routerEventSubscription = this.router.events.subscribe(function (res) {
            if (res instanceof NavigationEnd) {
                _this.checkRoute();
            }
        });
        this.checkRoute();
        if (this.exportModel) {
            this.exportTemplates.length = 0;
            this.exportService.getTemplates(this.exportModel).subscribe(function (res) {
                var _a;
                return (_a = _this.exportTemplates).push.apply(_a, res.templates);
            });
        }
        this.paramSubscription = this.activeRoute.queryParams.subscribe(function (params) {
            _this.searchObj = parseQueryParam(params);
            if (!_this.router.url.endsWith("/" + _this.model + "/search") && !_this.router.url.endsWith("/" + _this.model + "/add")) {
                setTimeout(function () { return _this.doSearch({}); }, 0);
            }
        });
        this.origUrl = this.router.routerState.snapshot.url;
        if (!this.origUrl.startsWith("/" + this.model + "/list")) {
            this.origUrl = "/" + this.model + "/list";
        }
        setTimeout(function () { return (_this.detail.instance.origUrl = _this.origUrl); });
    };
    ListPage.prototype.ngOnDestroy = function () {
        if (this.routerEventSubscription) {
            this.routerEventSubscription.unsubscribe();
            this.routerEventSubscription = null;
        }
        if (this.paramSubscription) {
            this.paramSubscription.unsubscribe();
            this.paramSubscription = null;
        }
    };
    ListPage.prototype.checkRoute = function () {
        if (this.router.url.endsWith("/" + this.model + "/search")) {
            this.searchObj = { keyword: '' };
            this.openSearch();
        }
        else {
            this.closeSearch();
            if (!this.router.url.endsWith("/" + this.model + "/add")) {
                this.doSearch({});
            }
        }
    };
    ListPage.prototype.doSearch = function (params) {
        this.searchObj = tslib_1.__assign({}, this.searchObj, params);
        this.closeSearch();
    };
    ListPage.prototype.openSearch = function () {
        var _this = this;
        if (this.isSearchOpen) {
            return;
        }
        PageFloaterComponent.closeAll();
        this.location.go("/" + this.model + "/search");
        this.isSearchOpen = true;
        var navigationSubscription = this.location.subscribe(function () {
            _this.closeSearch();
            navigationSubscription.unsubscribe();
        });
    };
    ListPage.prototype.closeSearch = function () {
        if (!this.isSearchOpen) {
            return;
        }
        this.location.go(this.origUrl);
        this.isSearchOpen = false;
    };
    return ListPage;
}());
export { ListPage };
