import * as tslib_1 from "tslib";
import { ConfirmDialogConfig } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog-config';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { Tab } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
var StudentContactHistoryTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentContactHistoryTabComponent, _super);
    function StudentContactHistoryTabComponent(studentApiService, dialogService) {
        var _this = _super.call(this) || this;
        _this.studentApiService = studentApiService;
        _this.dialogService = dialogService;
        return _this;
    }
    StudentContactHistoryTabComponent.prototype.errorGetContactsCallback = function (error) {
        if (!error.status) {
            // ネットワークに繋がらなかったときはエラーを通知する
            this.showErrorDialog();
            this.studentApiService.notifyConnectionErrorEvent.emit();
        }
        else {
            // その他のエラーは共通部分でハンドリングされているのでここではハンドリングしない
        }
    };
    StudentContactHistoryTabComponent.prototype.showErrorDialog = function () {
        var dialogConfig = ConfirmDialogConfig.createStudentApiErrorDialogConfig([
            '対応履歴の取得中に通信エラーが発生しました。',
            '画面上部右側の「求職者の全情報を再取得する」ボタンを押して再度取得してください。',
        ]);
        this.dialogService.open(ConfirmDialogComponent, dialogConfig);
    };
    return StudentContactHistoryTabComponent;
}(Tab));
export { StudentContactHistoryTabComponent };
