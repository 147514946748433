/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature-csv-settings-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/page-scroll-table/page-scroll-table.component.ngfactory";
import * as i3 from "../../../shared/components/page-scroll-table/page-scroll-table.component";
import * as i4 from "../../../shared/pipes/safe-date.pipe";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i7 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../../shared/pipes/truncate.pipe";
import * as i10 from "./signature-csv-settings-page.component";
import * as i11 from "../../../shared/services/api/mail-api.service";
import * as i12 from "../../../shared/services/api/export-api.service";
import * as i13 from "../../../shared/services/auth.service";
var styles_SignatureCsvSettingsPageComponent = [i0.styles];
var RenderType_SignatureCsvSettingsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureCsvSettingsPageComponent, data: {} });
export { RenderType_SignatureCsvSettingsPageComponent as RenderType_SignatureCsvSettingsPageComponent };
function View_SignatureCsvSettingsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-scroll-table", [["class", "table"]], null, null, null, i2.View_PageScrollTableComponent_0, i2.RenderType_PageScrollTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.PageScrollTableComponent, [i4.SafeDatePipe], { content: [0, "content"], scrollerClass: [1, "scrollerClass"], tableConfig: [2, "tableConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signatures; var currVal_1 = "no-scroll"; var currVal_2 = _co.signatureTableConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SignatureCsvSettingsPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-page-scroll-table", [["class", "table"]], null, null, null, i2.View_PageScrollTableComponent_0, i2.RenderType_PageScrollTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.PageScrollTableComponent, [i4.SafeDatePipe], { content: [0, "content"], scrollerClass: [1, "scrollerClass"], tableConfig: [2, "tableConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templates; var currVal_1 = "no-scroll"; var currVal_2 = _co.csvTableConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SignatureCsvSettingsPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "holder__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "holder__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CSV\u51FA\u529B\u8A2D\u5B9A"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn--blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTemplateEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u65B0\u898FCSV\u51FA\u529B\u8A2D\u5B9A "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureCsvSettingsPageComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.csvTableConfig; _ck(_v, 7, 0, currVal_0); }, null); }
function View_SignatureCsvSettingsPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSignatureEdit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "]))], null, null); }
function View_SignatureCsvSettingsPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn--small btn--white btn--outline-gray"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTemplateEdit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u7DE8\u96C6 "]))], null, null); }
function View_SignatureCsvSettingsPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i6.View_DynamicFormComponent_0, i6.RenderType_DynamicFormComponent)), i1.ɵdid(2, 442368, [[5, 4], ["signatureForm", 4]], 0, i7.DynamicFormComponent, [i8.FormBuilder, i1.KeyValueDiffers, i4.SafeDatePipe, i9.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signatureMeta; var currVal_1 = _co.selectedSignature; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SignatureCsvSettingsPageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i6.View_DynamicFormComponent_0, i6.RenderType_DynamicFormComponent)), i1.ɵdid(2, 442368, [[6, 4], ["csvForm", 4]], 0, i7.DynamicFormComponent, [i8.FormBuilder, i1.KeyValueDiffers, i4.SafeDatePipe, i9.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateMeta; var currVal_1 = _co.selectedTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SignatureCsvSettingsPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { signatureEditButtonTemplate: 0 }), i1.ɵqud(402653184, 2, { csvEditButtonTemplate: 0 }), i1.ɵqud(402653184, 3, { signatureEditPopupTemplate: 0 }), i1.ɵqud(402653184, 4, { csvEditPopupTemplate: 0 }), i1.ɵqud(671088640, 5, { signatureFormComponent: 0 }), i1.ɵqud(671088640, 6, { csvFormComponent: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u500B\u4EBA\u8A2D\u5B9A\n"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "holder__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "holder__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u7F72\u540D\u8A2D\u5B9A"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn--blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSignatureEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u65B0\u898F\u7F72\u540D\u767B\u9332 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureCsvSettingsPageComponent_1)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureCsvSettingsPageComponent_2)), i1.ɵdid(17, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["signatureEditButton", 2]], null, 0, null, View_SignatureCsvSettingsPageComponent_4)), (_l()(), i1.ɵand(0, [[2, 2], ["csvEditButton", 2]], null, 0, null, View_SignatureCsvSettingsPageComponent_5)), (_l()(), i1.ɵand(0, [[3, 2], ["signatureEditPopup", 2]], null, 0, null, View_SignatureCsvSettingsPageComponent_6)), (_l()(), i1.ɵand(0, [[4, 2], ["csvEditPopup", 2]], null, 0, null, View_SignatureCsvSettingsPageComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signatureTableConfig; _ck(_v, 15, 0, currVal_0); var currVal_1 = (((_co.authService.loginUser == null) ? null : _co.authService.loginUser.role) === 1); _ck(_v, 17, 0, currVal_1); }, null); }
export function View_SignatureCsvSettingsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-signature-csv-settings-page", [], null, null, null, View_SignatureCsvSettingsPageComponent_0, RenderType_SignatureCsvSettingsPageComponent)), i1.ɵdid(1, 114688, null, 0, i10.SignatureCsvSettingsPageComponent, [i4.SafeDatePipe, i11.MailApiService, i12.ExportApiService, i13.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureCsvSettingsPageComponentNgFactory = i1.ɵccf("ag-signature-csv-settings-page", i10.SignatureCsvSettingsPageComponent, View_SignatureCsvSettingsPageComponent_Host_0, {}, {}, []);
export { SignatureCsvSettingsPageComponentNgFactory as SignatureCsvSettingsPageComponentNgFactory };
