<ag-student-detail-header
  [student]="student"
  [readonly]="isReadonly"
  [progresses]="progresses"
></ag-student-detail-header>
<ag-tab-group class="tab-group" [(selectedIndex)]="selectedTabIndex">
  <ag-tab label="概要" *ngIf="!isReadonly">
    <ag-student-overview-tab
      [student]="student"
      [progresses]="progresses"
    ></ag-student-overview-tab>
  </ag-tab>
  <ag-tab label="詳細">
    <ag-student-detail-tab
      [student]="student"
      [readonly]="isReadonly"
    ></ag-student-detail-tab>
  </ag-tab>
  <ag-tab label="学歴・スキル">
    <ag-student-academic-tab
      [student]="student"
      [readonly]="isReadonly"
    ></ag-student-academic-tab>
  </ag-tab>
  <ag-tab label="希望条件・その他">
    <ag-student-preferences-tab
      [student]="student"
      [readonly]="isReadonly"
    ></ag-student-preferences-tab>
  </ag-tab>
  <ag-tab label="進捗">
    <ag-student-progress-tab [student]="student"></ag-student-progress-tab>
  </ag-tab>
  <ag-tab label="対応履歴" *ngIf="!isReadonly">
    <ag-student-contact-history-tab
      [studentId]="referenceId"
    ></ag-student-contact-history-tab>
  </ag-tab>
  <ag-tab label="メール" *ngIf="!isReadonly">
    <ag-student-message-tab [student]="student"></ag-student-message-tab>
  </ag-tab>
  <ag-tab label="状況メモ" *ngIf="!isReadonly">
    <ag-student-memo-tab [studentId]="referenceId"></ag-student-memo-tab>
  </ag-tab>
  <ag-tab label="データフォルダ" *ngIf="!isReadonly">
    <ag-student-files-tab [student]="student"></ag-student-files-tab>
  </ag-tab>
  <ag-tab label="セミナー" *ngIf="!isReadonly">
    <ag-student-seminars-tab [student]="student"></ag-student-seminars-tab>
  </ag-tab>
</ag-tab-group>
