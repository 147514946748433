<div
  class="upload-area-wrapper"
  [class.upload-area-wrapper--dragactive]="dragActive"
>
  <input
    class="upload-area-wrapper__input"
    #fileinput
    type="file"
    (dragenter)="setInputDrag(true)"
    (dragleave)="setInputDrag(false)"
    (blur)="setInputDrag(false)"
    (drop)="setInputDrag(false)"
    (change)="onInputChanged($event)"
    multiple
  />
  <div class="upload-area-wrapper__content">
    <div>ここにアップロードしたいファイルをドロップ</div>
    <div>または</div>
    <button
      class="upload-area-wrapper__button btn btn--outline-gray btn--white"
      (click)="fileinput.click()"
    >
      ファイルを選択
    </button>
  </div>
</div>
