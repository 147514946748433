import * as tslib_1 from "tslib";
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import { UserTeamInjectorProvider } from './providers/user-team-injector.provider';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./providers/user-team-injector.provider";
var JobApiService = /** @class */ (function (_super) {
    tslib_1.__extends(JobApiService, _super);
    function JobApiService(http, userMapper) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.userMapper = userMapper;
        _this.jobFavoritesChanged = new Subject();
        return _this;
    }
    JobApiService.prototype.getDetail = function (jobId) {
        return this.http.get('/api/job/' + jobId);
    };
    JobApiService.prototype.getList = function (search) {
        var jobs = this.http.get('/api/job/search', {
            params: (search ? toAsialRequestParameters(search) : {}),
        });
        return this.userMapper.getRequestAndProcessList(jobs, 'jobs');
    };
    JobApiService.prototype.addJob = function (departmentId) {
        var _a;
        return this.http.post("/api/job", { departmentId: departmentId }, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    JobApiService.prototype.getSuggestions = function (keyword, ids, enterpriseIds) {
        return keyword || (ids && ids.length) || (enterpriseIds && enterpriseIds.length)
            ? this.http.get("/api/job/suggest", {
                params: { name: keyword || undefined, id: ids, enterpriseId: enterpriseIds },
            })
            : of([]);
    };
    JobApiService.prototype.getSuggestionsRecruitment = function (keyword, ids, enterpriseIds) {
        return keyword || (ids && ids.length) || (enterpriseIds && enterpriseIds.length)
            ? this.http.get("/api/job/suggest/recruitment", {
                params: { name: keyword || undefined, id: ids, enterpriseId: enterpriseIds },
            })
            : of([]);
    };
    JobApiService.prototype.getFavorites = function () {
        return this.http.get('/api/favorite-job/');
    };
    JobApiService.prototype.addFavorite = function (jobId) {
        var _this = this;
        return this.http.post("/api/favorite-job/" + jobId, null).pipe(tap(function () {
            _this.jobFavoritesChanged.next({
                jobId: jobId,
                favorite: true,
            });
        }));
    };
    JobApiService.prototype.deleteFavorite = function (jobId) {
        var _this = this;
        return this.http.delete("/api/favorite-job/" + jobId).pipe(tap(function () {
            _this.jobFavoritesChanged.next({
                jobId: jobId,
                favorite: false,
            });
        }));
    };
    JobApiService.prototype.getMemos = function (id, sort, order) {
        return this.http.get("/api/job/" + id + "/memos", { params: { sort: sort || 'registerDate', order: order || 'desc' } });
    };
    JobApiService.prototype.addMemo = function (id, memo) {
        return this.http.post("/api/job/" + id + "/memos", memo);
    };
    JobApiService.prototype.updateMemo = function (id, memo) {
        return this.http.put("/api/job/" + id + "/memos/" + memo.id, memo);
    };
    JobApiService.prototype.deleteMemo = function (id, memoId) {
        return this.http.delete("/api/job/" + id + "/memos/" + memoId);
    };
    JobApiService.prototype.copyJob = function (id) {
        var _a;
        return this.http.post("/api/job/" + id + "/copy", {}, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    JobApiService.prototype.downloadPdf = function (id) {
        return this.http.get("/api/job/" + id + "/file/pdf", {
            responseType: 'blob',
            observe: 'response',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    };
    JobApiService.prototype.downloadExcel = function (id) {
        return this.http.get("/api/job/" + id + "/file/excel", {
            responseType: 'blob',
            observe: 'response',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            },
        });
    };
    JobApiService.prototype.getJobsAsPdf = function (jobsIds) {
        return this.http.get("/api/job/bulk/file/pdf", {
            responseType: 'blob',
            observe: 'response',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
            params: (jobsIds ? toAsialRequestParameters({ jobIds: jobsIds }) : {}),
        });
    };
    JobApiService.prototype.ckeckJobUsers = function (jobIds, status) {
        return this.http.get('/api/job/check/job-user', {
            params: toAsialRequestParameters({ jobIds: jobIds, status: status }),
        });
    };
    JobApiService.prototype.update = function (id, jobData) {
        var _a;
        return this.http.put("/api/job/" + id, jobData, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    // 開催日程別メール配信取得
    JobApiService.prototype.getJobSettingMail = function (id) {
        return this.http.get("/api/job/" + id + "/setting-mail", {});
    };
    // 開催日程別メール配信更新
    JobApiService.prototype.updateJobSettingMail = function (id, jobSettingMail) {
        var _a;
        return this.http.put("/api/job/" + id + "/setting-mail", jobSettingMail, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    JobApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JobApiService_Factory() { return new JobApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserTeamInjectorProvider)); }, token: JobApiService, providedIn: "root" });
    return JobApiService;
}(ApiService));
export { JobApiService };
