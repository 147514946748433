import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MatchingApiService = /** @class */ (function () {
    function MatchingApiService(http) {
        this.http = http;
        this.refreshEvent = new EventEmitter();
    }
    MatchingApiService.prototype.getMatchingsForStudent = function (studentId) {
        return this.http.get("/api/matching/student/" + studentId);
    };
    MatchingApiService.prototype.saveMatchingForStudent = function (matching) {
        return this.http.post('/api/matching/student', matching);
    };
    MatchingApiService.prototype.deleteStudentMatching = function (studentMatchingId) {
        return this.http.delete("/api/matching/student/" + studentMatchingId);
    };
    MatchingApiService.prototype.getMatchingsForJob = function (jobId) {
        return this.http.get("/api/matching/job/" + jobId);
    };
    MatchingApiService.prototype.saveMatchingForJob = function (matching) {
        return this.http.post('/api/matching/job', matching);
    };
    MatchingApiService.prototype.deleteJobMatching = function (jobMatchingId) {
        return this.http.delete("/api/matching/job/" + jobMatchingId);
    };
    MatchingApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatchingApiService_Factory() { return new MatchingApiService(i0.ɵɵinject(i1.HttpClient)); }, token: MatchingApiService, providedIn: "root" });
    return MatchingApiService;
}());
export { MatchingApiService };
