import * as tslib_1 from "tslib";
import { DialogConfig } from '@agent-ds/shared/components/dialog/dialog-config';
import { DialogRef } from '@agent-ds/shared/components/dialog/dialog-ref';
import { FormMeta, SupplierCallType } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { CompanyApiService } from '@agent-ds/shared/services/api/company-api.service';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
var CompanyBillingAddressCreateDialogComponent = /** @class */ (function () {
    function CompanyBillingAddressCreateDialogComponent(dialog, config, dynamicService, masterApiService, companyService, datePipe) {
        this.dialog = dialog;
        this.config = config;
        this.dynamicService = dynamicService;
        this.masterApiService = masterApiService;
        this.companyService = companyService;
        this.datePipe = datePipe;
        this.departmentDynamicList = [];
        this.validityArray = [false, false];
        this.valid = false;
    }
    CompanyBillingAddressCreateDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.model = this.config.data.billing.dynamicData ? this.config.data.billing : { dynamicData: {} };
        this.validityArray = this.config.data.billing.dynamicData ? [true, true] : [false, false];
        this.confirmSubscription = this.dialog.confirmed.subscribe(function () { return _this.create(); });
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.departmentDynamicList = _this.config.data.departmentList.map(function (dep) { return dep.dynamicData; });
            _this.metadataLeft = {
                groups: [
                    {
                        title: '企業情報',
                        class: 'form__group--red-title-border',
                        rows: [
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('enterprise', 'name'), { displayStyle: null, validationStyle: null }))[0];
                                row.fields[0].type = 'label';
                                row.fields[0].supplier = function () { return _this.config.data.company.dynamicData.name; };
                                return row;
                            })(),
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('enterprise', 'industry'), { displayStyle: null, validationStyle: null }))[0];
                                row.fields[0].type = 'label';
                                row.fields[0].supplier = function () {
                                    return _this.masterApiService
                                        .getFlattenedIndustryTypes()
                                        .pipe(map(function (industries) {
                                        return (industries.find(function (ind) { return ind.code === _this.config.data.company.dynamicData.industry; }) || { name: '' }).name;
                                    }));
                                };
                                return row;
                            })(),
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('enterprise', 'marketName'), { displayStyle: null, validationStyle: null }))[0];
                                row.fields[0].type = 'label';
                                row.fields[0].supplier = function () { return _this.config.data.company.dynamicData.marketName; };
                                return row;
                            })(),
                        ],
                    },
                    {
                        title: '部署住所情報',
                        class: 'form__group--red-title-border',
                        injectToHeader: _this.deptSelectorTemplate,
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'companyName'), null, 'full').concat([
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('billingAddress', 'billignBikeCD')))[0];
                                row.fields[0].class = 'full';
                                row.fields[0].supplier = function (value, callType, getValue) {
                                    var btoBBillignAddressRegistrationDate = getValue('btoBBillignAddressRegistrationDate');
                                    if (btoBBillignAddressRegistrationDate === undefined) {
                                        row.fields[0].linkTo = ['billignBikeCD'];
                                        row.fields[0].actions = [
                                            {
                                                type: 'UPDATE_LINKED',
                                                title: '企業ID引用',
                                            },
                                        ];
                                    }
                                    return callType === SupplierCallType.LINK_CHANGE ? _this.config.data.company.id + 40000000 : value ? value : undefined;
                                };
                                return row;
                            })(),
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('billingAddress', 'billignAddressCode')))[0];
                                row.fields[0].class = 'full';
                                row.fields[0].supplier = function (value, callType, getValue) {
                                    var btoBBillignAddressRegistrationDate = getValue('btoBBillignAddressRegistrationDate');
                                    if (btoBBillignAddressRegistrationDate === undefined) {
                                        row.fields[0].linkTo = ['billignAddressCode'];
                                        row.fields[0].actions = [
                                            {
                                                type: 'UPDATE_LINKED',
                                                title: '請求先ID割当',
                                            },
                                        ];
                                    }
                                    var billignBikeCD = getValue('billignBikeCD');
                                    return callType === SupplierCallType.LINK_CHANGE
                                        ? _this.companyService.getBllingAddressCode(String(billignBikeCD)).pipe(map(function (res) { return ({ value: res }); }))
                                        : value
                                            ? value
                                            : undefined;
                                };
                                return row;
                            })()
                        ], _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'address'))),
                    },
                    {
                        title: 'BtoB情報',
                        class: 'form__group--red-title-border',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'billignAddressStatus')).concat((function () {
                            var serviceRow = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'billignAddressService'), null, 'full')[0];
                            serviceRow.fields[0].linkTo = ['machTransferBankList'];
                            var listRow = _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'machTransferBankList'), null, 'full')[0];
                            listRow.fields[0].supplier = function (value, callType, getValue) {
                                var linkValue = getValue('billignAddressService');
                                switch (linkValue) {
                                    case null:
                                        return null;
                                    case 'AG':
                                        // listRow.fields[0].options = ['01_AG用'];
                                        return '01_AG用';
                                    case 'DR':
                                        // listRow.fields[0].options = ['02_DR用'];
                                        return '02_DR用';
                                    case 'その他':
                                        return value ? value : null;
                                }
                            };
                            // listRow.fields[0].allowOn = { billignAddressService: '有' };
                            return [serviceRow, listRow];
                        })(), [
                            (function () {
                                var row = _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('billingAddress', 'btoBBillignAddressRegistrationDate'), { displayStyle: null, validationStyle: null }))[0];
                                row.fields[0].type = 'label';
                                row.fields[0].supplier = function (val) { return _this.datePipe.transform(val); };
                                return row;
                            })(),
                        ]),
                    },
                ],
            };
            _this.metadataRight = {
                groups: [
                    {
                        title: '担当',
                        class: 'form__group--red-title-border',
                        rows: _this.dynamicService.getFormRows({
                            fieldName: 'user.id',
                            fieldType: 'user',
                            label: '担当者',
                            validationStyle: { required: true },
                        }).slice(),
                    },
                    {
                        title: '部署担当者情報',
                        class: 'form__group--red-title-border',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'departmentName'), 'dynamicData', 'fill').concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'contact'), 'dynamicData')),
                    },
                    {
                        title: '請求書送付情報',
                        class: 'form__group--red-title-border',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'howToSendInvoice'), 'dynamicData').concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('billingAddress', 'remarks'), 'dynamicData')),
                    },
                ],
            };
        });
    };
    CompanyBillingAddressCreateDialogComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    };
    CompanyBillingAddressCreateDialogComponent.prototype.onAddressSelect = function (address) {
        this.model.dynamicData.address = tslib_1.__assign({}, address);
        this.model.dynamicData.companyName = this.config.data.company.dynamicData.name;
    };
    CompanyBillingAddressCreateDialogComponent.prototype.onValidityChange = function (index, validity) {
        this.validityArray[index] = validity;
        this.valid = this.validityArray.find(function (v) { return !v; }) == null;
    };
    CompanyBillingAddressCreateDialogComponent.prototype.create = function (toBtoB) {
        if (!this.valid || this.dialog.inClose) {
            return;
        }
        if (this.model.user) {
            this.model.userId = this.model.user.id;
        }
        var emptyAddress = { zip: '', prefecture: null, address1: '', address2: '', address3: '' };
        this.model.dynamicData.address = this.model.dynamicData.address || emptyAddress;
        var emptyContact = { position: '', name: '', phoneticName: '', tel: '', fax: '', email: '', emailAvailable: null, remarks: '' };
        this.model.dynamicData.contact = this.model.dynamicData.contact || emptyContact;
        this.model.toBtoB = toBtoB === undefined ? false : true;
        this.close(this.model);
    };
    CompanyBillingAddressCreateDialogComponent.prototype.close = function (params) {
        this.dialog.close(params);
    };
    return CompanyBillingAddressCreateDialogComponent;
}());
export { CompanyBillingAddressCreateDialogComponent };
