<div class="page">
  <div class="report-header">
    <div class="report-header__title">担当別月間売上</div>
  </div>
  <div class="content">
    <div class="row">
      <ag-sales-report-filter (filter)="updateReportView($event)">
      </ag-sales-report-filter>
      <hr />

      <div
        class="report-content__group report-content__group--golden-title-border"
      >
        <span class="group__header">求人担当・成約請求額</span>
        <ng-content
          *ngTemplateOutlet="
            table_template;
            context: { collection: model.rapa, agentType: 1 }
          "
        >
        </ng-content>
      </div>

      <div
        class="report-content__group report-content__group--golden-title-border"
      >
        <span class="group__header">求職担当・成約請求額</span>

        <ng-content
          *ngTemplateOutlet="
            table_template;
            context: { collection: model.ca, agentType: 3 }
          "
        >
        </ng-content>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTemplate>
  <thead>
    <tr>
      <th>チーム名</th>
      <th>ユーザ名</th>
      <th>SK (AG)</th>
      <th>SK (Plus)</th>
      <th>キャンセル金額 (AG)</th>
      <th>キャンセル金額 (Plus)</th>
    </tr>
  </thead>
</ng-template>

<ng-template
  #table_template
  let-collection="collection"
  let-agentType="agentType"
>
  <table *ngIf="collection.total">
    <ng-content *ngTemplateOutlet="headerTemplate"></ng-content>
    <tbody>
      <tr *ngFor="let row of collection.users">
        <ng-container
          *ngIf="
            userTeamInjector.getUserTeamInfoById(row.userId) | async as user
          "
        >
          <td>{{ user.teamName }}</td>
          <td>{{ user.name }}</td>
          <ng-content
            *ngTemplateOutlet="
              currencyCell;
              context: {
                currency: row.skAg,
                userId: row.userId,
                progressType: 0,
                agentType: agentType
              }
            "
          ></ng-content>
          <ng-content
            *ngTemplateOutlet="
              currencyCell;
              context: {
                currency: row.skPlus,
                userId: row.userId,
                progressType: 1,
                agentType: agentType
              }
            "
          ></ng-content>
          <ng-content
            *ngTemplateOutlet="
              currencyCell;
              context: {
                currency: row.cancelAg,
                userId: row.userId,
                progressType: 0,
                agentType: agentType,
                canceled: true
              }
            "
          ></ng-content>
          <ng-content
            *ngTemplateOutlet="
              currencyCell;
              context: {
                currency: row.cancelPlus,
                userId: row.userId,
                progressType: 1,
                agentType: agentType,
                canceled: true
              }
            "
          ></ng-content>
        </ng-container>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="label" colspan="2">合計</td>
        <ng-content
          *ngTemplateOutlet="
            totalCell;
            context: { $implicit: collection.total?.skAg }
          "
        ></ng-content>
        <ng-content
          *ngTemplateOutlet="
            totalCell;
            context: { $implicit: collection.total?.skPlus }
          "
        ></ng-content>
        <ng-content
          *ngTemplateOutlet="
            totalCell;
            context: {
              $implicit: collection.total?.cancelAg
            }
          "
        ></ng-content>
        <ng-content
          *ngTemplateOutlet="
            totalCell;
            context: {
              $implicit: collection.total?.cancelPlus
            }
          "
        ></ng-content>
      </tr>
    </tfoot>
  </table>
</ng-template>

<ng-template
  #currencyCell
  let-value="currency"
  let-userId="userId"
  let-progressType="progressType"
  let-agentType="agentType"
  let-canceled="canceled"
>
  <td
    class="currency currency__clickable"
    [ngClass]="{ 'neg-value': value < 0, 'pos-value': value > 0 }"
    [routerLink]="['/sales/list']"
    [queryParams]="
      getSearchQueryParams(userId, progressType, agentType, canceled)
    "
  >
    {{ value | undef | number: "0.0-2" }}
  </td>
</ng-template>

<ng-template #totalCell let-value>
  <td
    class="currency"
    [ngClass]="{ 'neg-value': value < 0, 'pos-value': value > 0 }"
  >
    {{ value | undef | number: "0.0-2" }}
  </td>
</ng-template>
