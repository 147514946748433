import { EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { MailPopupType } from '../mail-send/config';
import { MailSendComponent } from '../mail-send/mail-send.component';
import { PageFloaterComponent } from '../page-floater/page-floater.component';
import { PopupControlComponent } from '../popup-control/popup-control.component';
var MailSendFlowComponent = /** @class */ (function () {
    function MailSendFlowComponent() {
        this.sent = new EventEmitter();
        this.closed = new EventEmitter();
        this.mailSideActions = [{ phases: ['1000px'] }];
        this.popupMeta = {
            groups: [
                {
                    class: 'no-background no-border',
                    rows: [],
                },
            ],
        };
        this.popupSendMeta = {
            groups: [
                {
                    class: 'no-background no-border',
                    rows: [],
                },
            ],
        };
        this.popupModel = {};
        this.jobErrorInfo = [];
        this.jobWarningInfo = [];
        this.currentFlowIndex = null;
        if (MailSendFlowComponent.INSTANCE == null) {
            MailSendFlowComponent.INSTANCE = this;
        }
    }
    Object.defineProperty(MailSendFlowComponent, "instance", {
        get: function () {
            return MailSendFlowComponent.INSTANCE;
        },
        enumerable: true,
        configurable: true
    });
    MailSendFlowComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(MailSendFlowComponent.prototype, "config", {
        set: function (sendConfig) {
            this.mailSend.config = sendConfig;
        },
        enumerable: true,
        configurable: true
    });
    MailSendFlowComponent.prototype.start = function (prefill) {
        this.closeFlow();
        this.mailSend.reset(prefill);
        this.mailSend.update();
        this.currentFlowIndex = -1;
        this.next();
    };
    MailSendFlowComponent.prototype.close = function (closeSender) {
        if (closeSender === void 0) { closeSender = false; }
        if (closeSender) {
            this.mailSend.close();
            return;
        }
        this.closeFlow();
        this.mailFloater.close();
        this.closed.emit();
    };
    MailSendFlowComponent.prototype.closeFlow = function () {
        this.currentFlowIndex = null;
        PopupControlComponent.closeAll();
    };
    MailSendFlowComponent.prototype.prev = function (step) {
        var _this = this;
        if (step === void 0) { step = 1; }
        if (this.currentFlowIndex != null) {
            this.currentFlowIndex -= step;
            var config_1 = this.mailSend.config.getPopupConfig()[this.currentFlowIndex];
            if (config_1) {
                setTimeout(function () { return _this.openPopup(config_1.type); });
            }
            else {
                this.closeFlow();
            }
        }
        else {
            PopupControlComponent.closeAll();
        }
    };
    MailSendFlowComponent.prototype.next = function () {
        var _this = this;
        if (this.currentFlowIndex != null) {
            var config_2 = this.mailSend.config.getPopupConfig()[++this.currentFlowIndex];
            if (config_2) {
                setTimeout(function () { return _this.openPopup(config_2.type); });
            }
            else if (!this.mailSend.config.sendAfterFlow()) {
                this.closeFlow();
                this.mailSend.config.initForm();
                this.mailFloater.open();
            }
            else {
                this.mailSend.confirm(true);
            }
        }
        else {
            PopupControlComponent.closeAll();
        }
    };
    MailSendFlowComponent.prototype.openPopup = function (type) {
        var _this = this;
        var popupConfig = this.mailSend.config.getPopupConfig().find(function (c) { return c.type === type; });
        if (!popupConfig) {
            return;
        }
        this.popupSendMeta.groups[0].rows = [];
        if (type === MailPopupType.JOB) {
            this.popupMeta.groups[0].rows = this.mailSend.dynamicService.getFormRows({
                fieldName: 'jobs',
                fieldType: popupConfig.multiSelect ? 'multi-job' : 'job',
                displayType: 'email',
                label: '求人',
            });
            this.popupMeta.groups[0].rows[0].fields[0].filters = [
                {
                    name: 'status',
                    hidden: true,
                    options: [['募集中', '推薦停止']],
                    supplier: function () { return 0; },
                },
            ];
            this.popupMeta.groups[0].rows[0].fields[0].specialOption = null;
            this.showJobSelect(popupConfig);
        }
        else if (type === MailPopupType.DEPARTMENT) {
            this.popupMeta.groups[0].rows = [];
            var enterpriseRow = this.mailSend.dynamicService.getFormRows({ fieldName: 'enterpriseId', fieldType: 'enterprise', label: '企業' }, null, 'half')[0];
            enterpriseRow.hidden = this.mailSend.model.enterpriseId != null;
            enterpriseRow.fields[0].linkTo = ['department'];
            this.popupMeta.groups[0].rows.push(enterpriseRow, {
                title: '部署',
                fields: [
                    {
                        name: 'department',
                        type: 'dropdown',
                        class: 'half tall',
                        labelField: 'dynamicData.name',
                        options: [],
                        allowOn: { enterpriseId: null },
                        supplier: function (value, callType, getValue) {
                            var linkValue = getValue('enterpriseId');
                            return linkValue
                                ? _this.mailSend.companyApiService.getDepartments(linkValue).pipe(map(function (v) { return ({
                                    options: v.enterpriseDepartments,
                                }); }))
                                : { options: [] };
                        },
                        validators: { required: true },
                    },
                ],
            });
            this.showDepSelect(popupConfig);
        }
        else if (type === MailPopupType.JOB_CHECK) {
            this.runJobCheck(popupConfig);
        }
        else if (type === MailPopupType.ENTERPRISE_RESUME_PASSWORD_CHECK) {
            this.runEnterpriseResumePasswordCheck();
        }
        else if (type === MailPopupType.STUDENT) {
            this.popupMeta.groups[0].rows = this.mailSend.dynamicService.getFormRows({
                fieldName: 'students',
                fieldType: popupConfig.multiSelect ? 'multi-student' : 'student',
                displayType: 'email',
                label: '対象求職者',
            });
            if (popupConfig.bodyArea) {
                this.popupSendMeta.groups[0].rows = [
                    {
                        title: 'メールの\n追記文言',
                        fields: [
                            {
                                type: 'textarea',
                                name: 'body',
                                class: 'full',
                            },
                        ],
                    },
                ];
            }
            this.showStudentSelect(popupConfig);
        }
    };
    MailSendFlowComponent.prototype.runEnterpriseResumePasswordCheck = function (allowFail) {
        var _this = this;
        if (allowFail === void 0) { allowFail = false; }
        if (this.mailSend.model.enterpriseId == null) {
            return;
        }
        this.mailSend.companyApiService.getDetail(this.mailSend.model.enterpriseId).subscribe(function (enterprise) {
            if (enterprise.dynamicData.resumePassword == null) {
                PopupControlComponent.subInstance.open({
                    title: 'レジュメ送信',
                    content: 'レジュメ送信用パスワードが設定されていません。',
                    confirmText: '閉じる',
                    confirmCallback: function () {
                        if (allowFail) {
                            _this.mailSend.model.enterprise = enterprise;
                            _this.mailSend.config.update();
                            _this.next();
                        }
                    },
                });
            }
            else {
                _this.mailSend.model.enterprise = enterprise;
                _this.mailSend.config.update();
                _this.next();
            }
        });
    };
    MailSendFlowComponent.prototype.showJobSelect = function (popupConfig) {
        var _this = this;
        this.popupModel.jobs = popupConfig.multiSelect ? this.mailSend.model.jobs.slice() : this.mailSend.model.jobs[0];
        this.popupMessage = popupConfig.message;
        PopupControlComponent.instance.open({
            content: this.jobSelectTemplate,
            confirmText: popupConfig.confirmText || 'メールを作成',
            cancelText: 'キャンセル',
            title: popupConfig.title || 'レジュメ送信',
            closeCallback: function () { return _this.prev(popupConfig.stepBack); },
            confirmEnabled: function () { return _this.popupModel.jobs && (!popupConfig.multiSelect || _this.popupModel.jobs.length) && !_this.jobCheckObs; },
            confirmCallback: function () {
                _this.mailSend.model.jobs = popupConfig.multiSelect ? _this.popupModel.jobs.slice() : [_this.popupModel.jobs];
                _this.jobCheckObs = _this.mailSend.jobApiService.ckeckJobUsers(_this.mailSend.model.jobs.map(function (j) { return j.id; }), [
                    '募集中',
                    '推薦停止',
                ]);
                _this.jobCheckObs.subscribe(function (res) {
                    _this.jobErrorInfo = [];
                    _this.jobWarningInfo = [];
                    _this.jobCheckObs = null;
                    res.forEach(function (r) {
                        if (!r.status.length ||
                            !r.jobUsers.length ||
                            (popupConfig.customFilter &&
                                popupConfig.customFilter.jobUserType &&
                                r.jobUsers.find(function (u) { return u.type === popupConfig.customFilter.jobUserType; }) == null)) {
                            _this.jobErrorInfo.push(r);
                        }
                        if (r.status.includes('推薦停止')) {
                            _this.jobWarningInfo.push(r);
                        }
                        var job = _this.mailSend.model.jobs.find(function (j) { return j.id === r.jobId; });
                        if (job) {
                            job.jobUsers = r.jobUsers;
                        }
                    });
                    if (_this.jobErrorInfo.length) {
                        PopupControlComponent.subInstance.open({
                            title: '必要な情報が入力されていません',
                            confirmText: '閉じる',
                            content: _this.jobErrorTemplate,
                            confirmCallback: function () { return false; },
                        });
                        return;
                    }
                    else if (_this.jobWarningInfo.length) {
                        PopupControlComponent.subInstance.open({
                            title: '求人票送信',
                            content: _this.jobWarningTemplate,
                            cancelText: 'キャンセル',
                            confirmText: 'OK',
                            confirmCallback: function () {
                                _this.mailSend.model.enterpriseId = _this.mailSend.model.jobs[0].enterpriseId;
                                _this.runEnterpriseResumePasswordCheck(true);
                            },
                        });
                        return;
                    }
                    else if (!popupConfig.multiSelect) {
                        _this.mailSend.model.enterpriseId = _this.mailSend.model.jobs[0].enterpriseId;
                        _this.runEnterpriseResumePasswordCheck(true);
                    }
                    else {
                        _this.mailSend.config.initForm();
                        _this.next();
                    }
                });
                return true;
            },
        });
    };
    MailSendFlowComponent.prototype.showDepSelect = function (popupConfig) {
        var _this = this;
        this.popupModel.enterpriseId = this.mailSend.model.enterpriseId;
        this.popupModel.department = null;
        PopupControlComponent.instance.open({
            content: this.depSelectTemplate,
            confirmText: '選択',
            cancelText: 'キャンセル',
            title: '部署選択',
            closeCallback: function () { return _this.prev(popupConfig.stepBack); },
            confirmEnabled: function () { return _this.popupModel.department && (!popupConfig.multiSelect || _this.popupModel.department.length); },
            confirmCallback: function () {
                _this.mailSend.model.department = _this.popupModel.department;
                _this.mailSend.model.departmentId = _this.popupModel.department.id;
                return _this.mailSend.companyApiService.getDetail(_this.mailSend.model.enterpriseId).pipe(tap(function (comp) {
                    _this.mailSend.model.enterprise = comp;
                    _this.mailSend.config.update();
                    _this.next();
                }));
            },
        });
    };
    MailSendFlowComponent.prototype.showStudentSelect = function (popupConfig) {
        var _this = this;
        this.popupModel.students = popupConfig.multiSelect ? this.mailSend.model.students.slice() : (this.mailSend.model.students || [])[0];
        this.popupMessage = popupConfig.message;
        this.popupModel.body = null;
        PopupControlComponent.instance.open({
            content: this.studentSelectTemplate,
            confirmText: popupConfig.confirmText || '送信して一括推薦',
            cancelText: 'キャンセル',
            title: popupConfig.title || '一括推薦',
            cancelCallback: function () { return _this.prev(popupConfig.stepBack); },
            confirmEnabled: function () { return _this.popupModel.students && (!popupConfig.multiSelect || _this.popupModel.students.length); },
            confirmCallback: function () {
                _this.mailSend.model.students = popupConfig.multiSelect ? _this.popupModel.students.slice() : [_this.popupModel.students];
                if (_this.mailSend.model.students.length === 1) {
                    _this.mailSend.model.studentId = _this.mailSend.model.students[0].id;
                }
                if (_this.popupModel.body) {
                    _this.mailSend.model.body = _this.popupModel.body;
                }
                _this.next();
            },
        });
    };
    MailSendFlowComponent.prototype.runJobCheck = function (popupConfig) {
        var _this = this;
        this.jobCheckObs = this.mailSend.jobApiService.ckeckJobUsers(this.mailSend.model.jobs.map(function (j) { return j.id; }), [
            '募集中',
            '推薦停止',
        ]);
        this.jobCheckObs.subscribe(function (res) {
            _this.jobErrorInfo = [];
            _this.jobCheckObs = null;
            _this.jobWarningInfo = [];
            res.forEach(function (r) {
                if (!r.status.length ||
                    !r.jobUsers.length ||
                    (popupConfig.customFilter &&
                        popupConfig.customFilter.jobUserType &&
                        r.jobUsers.find(function (u) { return u.type === popupConfig.customFilter.jobUserType; }) == null)) {
                    // comment this out if you don't have jobs without errors
                    _this.jobErrorInfo.push(r);
                }
                if (r.status.includes('推薦停止')) {
                    _this.jobWarningInfo.push(r);
                }
                var job = _this.mailSend.model.jobs.find(function (j) { return j.id === r.jobId; });
                if (job) {
                    job.jobUsers = r.jobUsers;
                }
            });
            if (_this.jobErrorInfo.length) {
                PopupControlComponent.instance.open({
                    title: '必要な情報が入力されていません',
                    confirmText: '閉じる',
                    content: _this.jobErrorTemplate,
                    confirmCallback: function () { return false; },
                });
                return;
            }
            else if (_this.jobWarningInfo.length) {
                PopupControlComponent.instance.open({
                    title: popupConfig.title || '求人票送信',
                    content: _this.jobWarningTemplate,
                    cancelText: 'キャンセル',
                    confirmText: 'OK',
                    confirmCallback: function () {
                        _this.mailSend.config.update();
                        _this.next();
                    },
                });
                return;
            }
            else {
                _this.mailSend.config.update();
                _this.next();
            }
        });
    };
    return MailSendFlowComponent;
}());
export { MailSendFlowComponent };
