/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./matching-create-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../shared/pipes/truncate.pipe";
import * as i7 from "./matching-create-dialog.component";
import * as i8 from "../../../shared/components/dialog/dialog-ref";
import * as i9 from "../../../shared/components/dialog/dialog-config";
var styles_MatchingCreateDialogComponent = [i0.styles];
var RenderType_MatchingCreateDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatchingCreateDialogComponent, data: {} });
export { RenderType_MatchingCreateDialogComponent as RenderType_MatchingCreateDialogComponent };
export function View_MatchingCreateDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dialog__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30DE\u30C3\u30C1\u30F3\u30B0\u6761\u4EF6\u306E\u767B\u9332"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "dialog__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "create"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "validated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validated" === en)) {
        var pd_0 = (_co.onValidityChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(8, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { validated: "validated" }), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u30AD\u30E3\u30F3\u30BB\u30EB "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "dialog__button dialog__button--primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u767B\u9332 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.meta; var currVal_1 = _co.model; _ck(_v, 8, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.validity || _co.dialog.inClose); _ck(_v, 12, 0, currVal_2); }); }
export function View_MatchingCreateDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-matching-create-dialog", [], null, null, null, View_MatchingCreateDialogComponent_0, RenderType_MatchingCreateDialogComponent)), i1.ɵdid(1, 245760, null, 0, i7.MatchingCreateDialogComponent, [i8.DialogRef, i9.DialogConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MatchingCreateDialogComponentNgFactory = i1.ɵccf("ag-matching-create-dialog", i7.MatchingCreateDialogComponent, View_MatchingCreateDialogComponent_Host_0, {}, {}, []);
export { MatchingCreateDialogComponentNgFactory as MatchingCreateDialogComponentNgFactory };
