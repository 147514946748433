/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-preferences-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../../../shared/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../shared/pipes/safe-date.pipe";
import * as i6 from "../../../../../shared/pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../shared/models/tab";
import * as i9 from "./student-preferences-tab.component";
import * as i10 from "../../../../../shared/services/api/dynamic-field.service";
import * as i11 from "../../../../../shared/services/api/student-api.service";
var styles_StudentPreferencesTabComponent = [i0.styles];
var RenderType_StudentPreferencesTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentPreferencesTabComponent, data: {} });
export { RenderType_StudentPreferencesTabComponent as RenderType_StudentPreferencesTabComponent };
function View_StudentPreferencesTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onChangedMetadataLeft($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataLeft; var currVal_1 = ((_co.student == null) ? null : _co.student.dynamicData); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentPreferencesTabComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataRight; var currVal_1 = ((_co.student == null) ? null : _co.student.dynamicData); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentPreferencesTabComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataOther; var currVal_1 = ((_co.student == null) ? null : _co.student.dynamicData); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StudentPreferencesTabComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "footer__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "footer__btn footer__btn--blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u66F4\u65B0"]))], null, null); }
export function View_StudentPreferencesTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentPreferencesTabComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentPreferencesTabComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "row__column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentPreferencesTabComponent_3)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StudentPreferencesTabComponent_4)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metadataLeft; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.metadataRight; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.metadataOther; _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.readonly; _ck(_v, 13, 0, currVal_3); }, null); }
export function View_StudentPreferencesTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ag-student-preferences-tab", [], null, null, null, View_StudentPreferencesTabComponent_0, RenderType_StudentPreferencesTabComponent)), i1.ɵprd(6144, null, i8.Tab, null, [i9.StudentPreferencesTabComponent]), i1.ɵdid(2, 114688, null, 0, i9.StudentPreferencesTabComponent, [i10.DynamicFieldService, i11.StudentApiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var StudentPreferencesTabComponentNgFactory = i1.ɵccf("ag-student-preferences-tab", i9.StudentPreferencesTabComponent, View_StudentPreferencesTabComponent_Host_0, { student: "student", readonly: "readonly" }, {}, []);
export { StudentPreferencesTabComponentNgFactory as StudentPreferencesTabComponentNgFactory };
