import * as tslib_1 from "tslib";
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { DecimalPipe } from '@angular/common';
import { HelperBase } from './helper-base';
export var MAX_FEE_VALUE = 1000000000;
var OrderInformationHelper = /** @class */ (function (_super) {
    tslib_1.__extends(OrderInformationHelper, _super);
    function OrderInformationHelper(dynamicService, datePipe, decimalPipe) {
        var _this = _super.call(this, dynamicService, datePipe) || this;
        _this.decimalPipe = decimalPipe;
        _this.calculateTaxActionsProp = { subscribers: [] };
        _this.notApproved = { rows: [] };
        _this.approved = { rows: [] };
        _this.disabled = { rows: [] };
        _this.textarea = { rows: [] };
        _this.contractOptions = [];
        return _this;
    }
    Object.defineProperty(OrderInformationHelper.prototype, "calculateTaxActions", {
        set: function (value) {
            this.setupActionProperty(this.calculateTaxActionsProp, value);
        },
        enumerable: true,
        configurable: true
    });
    OrderInformationHelper.prototype.init = function (detailPage) {
        var _this = this;
        detailPage.contractSubject.subscribe(function (contractOptions) { return (_this.contractOptions = contractOptions); });
    };
    OrderInformationHelper.prototype.updateFieldDefinitions = function () {
        var _this = this;
        this.approved = {
            // Order information
            title: '受注額情報',
            class: 'form__group--golden-title-border',
            rows: this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'invoiceNo'), 'sales.dynamicData', 'half').concat(this.dynamicService.getFormRows(tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'fee'), { validationStyle: { required: false, maxValue: MAX_FEE_VALUE } }), 'sales.dynamicData', 'half'), this.getFormRowsWith1RowActions(tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'taxFee'), { validationStyle: { required: false } }), this.calculateTaxActionsProp, 'sales.dynamicData', 'half'), this.dynamicService.getFormRows(tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'chargeFee'), { validationStyle: { required: false } }), 'sales.dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'feeChangeDate'), 'sales.dynamicData')),
        };
        this.notApproved = {
            // Order information
            title: '受注額情報',
            class: 'form__group--golden-title-border',
            rows: this.dynamicService.getFormRows(tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'fee'), { validationStyle: { required: true, maxValue: MAX_FEE_VALUE } }), 'sales.dynamicData', 'half').concat(this.getFormRowsWith1RowActions(this.dynamicService.getDefinition('sales', 'taxFee'), this.calculateTaxActionsProp, 'sales.dynamicData', 'half'), this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'chargeFee'), 'sales.dynamicData', 'half')),
        };
        this.textarea = {
            title: 'フィー一覧',
            class: 'form__group--margin-spacer form__group--no-title-border form__group--inline-title no-title-column',
            rows: [
                (function () {
                    var row = _this.dynamicService.getFormRows({
                        fieldName: 'contract-priceInformation',
                        fieldType: 'text',
                        displayType: 'textarea',
                    })[0];
                    row.fields[0].transparent = true;
                    row.fields[0].disabled = true;
                    row.fields[0].class = 'full text-area-no-disable-look';
                    row.style = { 'border-width': '1px' };
                    return row;
                })(),
            ],
        };
        this.disabled = {
            // Order information
            title: '受注額情報',
            class: 'form__group--golden-title-border',
            rows: this.getDynamicRowOrLabel(false, this.dynamicService.getDefinition('sales', 'invoiceNo'), null, 'sales.dynamicData', 'half').concat(this.getDynamicRowOrLabel(false, tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'fee'), { validationStyle: { required: false, maxValue: MAX_FEE_VALUE } }), null, 'sales.dynamicData', 'half', function (val) { return _this.decimalPipe.transform(val); }), this.getDynamicRowOrLabel(false, tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'taxFee'), { validationStyle: { required: false } }), null, 'sales.dynamicData', 'half', function (val) { return _this.decimalPipe.transform(val); }), this.getDynamicRowOrLabel(false, tslib_1.__assign({}, this.dynamicService.getDefinition('sales', 'chargeFee'), { validationStyle: { required: false } }), null, 'sales.dynamicData', 'half', function (val) { return _this.decimalPipe.transform(val); }), this.getDynamicRowOrLabel(false, this.dynamicService.getDefinition('sales', 'feeChangeDate'), null, 'sales.dynamicData')),
        };
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [this.notApproved, this.notApproved, this.notApproved, this.disabled],
            [this.notApproved, this.notApproved, this.notApproved, this.disabled],
            [this.approved, this.approved, this.approved, this.disabled],
            [this.approved, this.approved, this.approved, this.disabled],
        ];
    };
    OrderInformationHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus], this.textarea];
    };
    return OrderInformationHelper;
}(HelperBase));
export { OrderInformationHelper };
