<div
  *ngIf="company"
  class="company-detail-header"
  [class.company-detail-header--with-one-child]="showOnlyMainInfo"
>
  <div class="info" [class.info--full]="showOnlyMainInfo">
    <div class="main-info" [class.main-info--full]="showOnlyMainInfo">
      <div class="main-info__id">{{ company?.frontId }}</div>
      <div class="main-info__business-number">
        {{ company?.dynamicData?.enterpriseNo }}
      </div>
      <div class="main-info__company-name">
        {{ company?.dynamicData?.name }}
      </div>
      <div class="main-info__company-8segs">
        <ag-segs
          [companyClassification]="company.dynamicData.companyClassification"
          [segs8LastYear]="company.dynamicData.segs8LastYear"
          [segs8CurrentYear]="company.dynamicData.segs8CurrentYear"
        >
        </ag-segs>
      </div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">顧客ステータス</div>
    <div class="status">
      <div class="status__row">
        <div class="badge badge--blue">AG</div>
        {{ company?.dynamicData?.agClientStatus }}
        <div *ngIf="jobs?.length" class="status__order">オーダー</div>
      </div>
      <div class="status__row">
        <div class="badge badge--red-light">DR</div>
        {{ company?.dynamicData?.drClientStatus }}
      </div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">離任Kランク</div>
    <div class="info__values">
      <div class="info__value">{{ company?.dynamicData?.rininKRank }}</div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">成約実績</div>
    <div class="info__values">
      <div class="info__value">
        {{ company?.salesAmount ? "あり" : "なし" }}
      </div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">成約金額合計</div>
    <div class="info__values">
      <div class="info__value info__value--bold">
        {{ company?.salesAmount | number: "0.0-2" }}
      </div>
    </div>
  </div>
  <div class="info" [class.info--hidden]="showOnlyMainInfo">
    <div class="info__label">企業担当者情報</div>
    <div class="info__values">
      <div class="info__value">
        {{ company?.user?.name }}<br />
        {{
          (
            (masterApiService.getTeams()
              | async
              | find: company?.user?.teamId:"id") || { name: "" }
          ).name
        }}
      </div>
    </div>
  </div>
</div>
