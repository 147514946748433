import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { DialogService, DynamicFieldService, MasterApiService, ProgressApiService } from '@agent-ds/shared/services';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
var ProgressReportPageComponent = /** @class */ (function () {
    function ProgressReportPageComponent(dynamicService, progressApiService, dialog, masterApiService, userTeamService) {
        this.dynamicService = dynamicService;
        this.progressApiService = progressApiService;
        this.dialog = dialog;
        this.masterApiService = masterApiService;
        this.userTeamService = userTeamService;
        this.formModel = {};
        this.caKpiTotal = {};
        this.raKpiTotal = {};
        this.team = null;
    }
    ProgressReportPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formMetadata = {
            groups: [
                {
                    rows: [
                        (function () {
                            var rows = _this.dynamicService.getFormRows({
                                fieldName: 'date',
                                fieldType: 'date-range',
                                displayType: 'date-advanced',
                                label: 'アクション期間',
                            });
                            var _a = rows[0].fields[3].actions, action1 = _a[0], action2 = _a[1], rest = _a.slice(2);
                            rows[0].fields[0].validators = { required: true };
                            rows[0].fields[2].validators = { required: true };
                            rows[0].fields[3].actions = [action1, action2];
                            return rows[0];
                        })(),
                        (function () {
                            var rows = _this.dynamicService.getFormRows({
                                fieldName: 'teamId',
                                fieldType: 'team',
                                displayType: 'dropdown',
                                label: 'チーム',
                            });
                            rows[0].fields[0].class = 'quarter';
                            rows[0].fields[0].validators = { required: true };
                            return rows[0];
                        })(),
                    ],
                },
            ],
        };
    };
    ProgressReportPageComponent.prototype.onValidityChange = function (validity) {
        this.valid = validity;
    };
    ProgressReportPageComponent.prototype.onSearch = function () {
        var _this = this;
        if (!this.valid) {
            return;
        }
        var fromDate = new Date(this.formModel.date.from);
        var toDate = new Date(this.formModel.date.to);
        if (fromDate.getMonth() !== toDate.getMonth() || fromDate.getFullYear() !== toDate.getFullYear()) {
            var config = {
                title: '日付期間が間違えています',
                messages: ['同月の日付を指定してください。'],
                style: {
                    height: '245px',
                    width: '510px',
                },
                buttons: {
                    no: '',
                    hideNo: true,
                    yes: 'OK',
                },
            };
            this.dialog.open(ConfirmDialogComponent, config);
            return;
        }
        forkJoin(this.progressApiService.getReportRapa(this.formModel.date, this.formModel.teamId), this.progressApiService.getReportCa(this.formModel.date, this.formModel.teamId), this.masterApiService.getTeams()).subscribe(function (_a) {
            var raKpiReports = _a[0], caKpiReports = _a[1], teams = _a[2];
            _this.raKpiReports = raKpiReports.items || [];
            _this.caKpiReports = caKpiReports.items || [];
            _this.raKpiReports.forEach(function (report) {
                return _this.userTeamService.getUserInfoById(report.userId).subscribe(function (user) { return (report.userName = user.name); });
            });
            _this.caKpiReports.forEach(function (report) {
                return _this.userTeamService.getUserInfoById(report.userId).subscribe(function (user) { return (report.userName = user.name); });
            });
            _this.raKpiTotal = raKpiReports.total || {};
            _this.caKpiTotal = caKpiReports.total || {};
            _this.team = teams.find(function (team) { return team.id === _this.formModel.teamId; });
        });
    };
    return ProgressReportPageComponent;
}());
export { ProgressReportPageComponent };
