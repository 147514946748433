/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/kpi-target/kpi-target.component.ngfactory";
import * as i4 from "../../components/kpi-target/kpi-target.component";
import * as i5 from "../../components/job-progress-dash/job-progress-dash.component.ngfactory";
import * as i6 from "../../components/job-progress-dash/job-progress-dash.component";
import * as i7 from "../../components/responsible-company/responsible-company.component.ngfactory";
import * as i8 from "../../components/responsible-company/responsible-company.component";
import * as i9 from "./dashboard-page.component";
import * as i10 from "../../../shared/services/auth.service";
import * as i11 from "../../../shared/services/api/dashboard-api.service";
import * as i12 from "../../../shared/services/dialog.service";
var styles_DashboardPageComponent = [i0.styles];
var RenderType_DashboardPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardPageComponent, data: {} });
export { RenderType_DashboardPageComponent as RenderType_DashboardPageComponent };
function View_DashboardPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header__item btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateData(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn--blue": 0, "link blue": 1 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header__item btn"; var currVal_1 = _ck(_v, 3, 0, (_co.selectedManagementTeam === _v.context.$implicit.id), (_co.selectedManagementTeam !== _v.context.$implicit.id)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_DashboardPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header__item btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateData(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn--blue": 0, "link blue": 1 }), (_l()(), i1.ɵted(-1, null, [" \u500B\u4EBA "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header__item btn"; var currVal_1 = _ck(_v, 3, 0, (_co.selectedManagementTeam === null), (_co.selectedManagementTeam !== null)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DashboardPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardPageComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardPageComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.authService.loginUser == null) ? null : _co.authService.loginUser.managementTeams); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.userHasManagementRole; _ck(_v, 4, 0, currVal_1); }, null); }
function View_DashboardPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header__item header__item--right btn btn--outline-blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.secondMatchClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" 2nd\u30DE\u30C3\u30C1\u5B9F\u7E3E\u51FA\u529B "]))], null, null); }
function View_DashboardPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-kpi-target", [["class", "content__item"]], null, [[null, "setupClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setupClick" === en)) {
        var pd_0 = (_co.kpiSetupClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_KpiTargetComponent_0, i3.RenderType_KpiTargetComponent)), i1.ɵdid(1, 114688, null, 0, i4.KpiTargetComponent, [], { model: [0, "model"], dashboardType: [1, "dashboardType"] }, { setupClick: "setupClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.kpi; var currVal_1 = _co.visualDashboardType; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DashboardPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardPageComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ag-job-progress-dash", [["class", "content__item"]], null, [[null, "collapsedNextYear"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("collapsedNextYear" === en)) {
        var pd_0 = ((_co.collapsedChild = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_JobProgressDashComponent_0, i5.RenderType_JobProgressDashComponent)), i1.ɵdid(4, 638976, null, 0, i6.JobProgressDashComponent, [], { model: [0, "model"], dashboardType: [1, "dashboardType"], selectedTeamId: [2, "selectedTeamId"], loginUserId: [3, "loginUserId"] }, { collapsedNextYear: "collapsedNextYear" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ag-responsible-company", [["class", "content__item"]], null, null, null, i7.View_ResponsibleCompanyComponent_0, i7.RenderType_ResponsibleCompanyComponent)), i1.ɵdid(6, 638976, null, 0, i8.ResponsibleCompanyComponent, [], { enterpriseModel: [0, "enterpriseModel"], studentModel: [1, "studentModel"], dashboardType: [2, "dashboardType"], selectedTeamId: [3, "selectedTeamId"], loginUserId: [4, "loginUserId"], collapsedNextYear: [5, "collapsedNextYear"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visualDashboardType !== 2); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.progress; var currVal_2 = _co.visualDashboardType; var currVal_3 = _co.selectedManagementTeam; var currVal_4 = ((_co.authService.loginUser == null) ? null : _co.authService.loginUser.id); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.enterprises; var currVal_6 = _co.students; var currVal_7 = _co.visualDashboardType; var currVal_8 = _co.selectedManagementTeam; var currVal_9 = ((_co.authService.loginUser == null) ? null : _co.authService.loginUser.id); var currVal_10 = _co.collapsedChild; _ck(_v, 6, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
function View_DashboardPageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "text-align: center; color: #999999;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u8868\u793A\u3059\u308B\u9805\u76EE\u306F\u3042\u308A\u307E\u305B\u3093\u3002\n"]))], null, null); }
export function View_DashboardPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardPageComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardPageComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardPageComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userHasManagementRole; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.userIsCA; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.isIntakeCa; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isIntakeCa; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_DashboardPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dashboard-page", [], null, null, null, View_DashboardPageComponent_0, RenderType_DashboardPageComponent)), i1.ɵdid(1, 245760, null, 0, i9.DashboardPageComponent, [i10.AuthService, i11.DashboardApiService, i12.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPageComponentNgFactory = i1.ɵccf("ag-dashboard-page", i9.DashboardPageComponent, View_DashboardPageComponent_Host_0, {}, {}, []);
export { DashboardPageComponentNgFactory as DashboardPageComponentNgFactory };
