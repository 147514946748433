import * as tslib_1 from "tslib";
import { environment } from '@agent-ds/environments/environment';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';
import { MailPopupType } from '../config';
import { StudentSendConfig } from './student-send-config';
var StudentDetailConfig = /** @class */ (function (_super) {
    tslib_1.__extends(StudentDetailConfig, _super);
    function StudentDetailConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StudentDetailConfig.prototype.getTemplateId = function () {
        return 2;
    };
    StudentDetailConfig.prototype.getTemplateTypeId = function () {
        return [3];
    };
    StudentDetailConfig.prototype.getPopupConfig = function () {
        return [{ type: MailPopupType.JOB }];
    };
    StudentDetailConfig.prototype.setParams = function (params) {
        if (this.sender) {
            this.sender.model.jobs = params.jobs || [];
        }
        _super.prototype.setParams.call(this, params);
    };
    StudentDetailConfig.prototype.initForm = function () {
        var _this = this;
        if (!this.sender.model.student || !this.contacts || !this.sender.model.enterprise || !this.sender.model.department) {
            return;
        }
        var metaData = {
            disabled: this.sender.inConfirm,
            groups: [
                {
                    title: '新規メール作成',
                    rows: [],
                },
            ],
        };
        var enterprise = this.sender.model.enterprise;
        var department = this.sender.model.department;
        metaData.groups[0].rows.push({
            title: 'To (企業・部署)',
            showRequired: true,
            class: 'ou',
            fields: [
                {
                    name: 'enterpriseName',
                    type: 'label',
                    class: 'pre',
                    supplier: function () {
                        return enterprise.frontId + " " + enterprise.dynamicData.name + enterprise.dynamicData.enterpriseNo + "\n" + department.dynamicData.name;
                    },
                },
                !this.sender.inConfirm
                    ? {
                        name: 'to',
                        type: 'checkbox',
                        labelField: 'label',
                        valueField: 'value',
                        class: 'multi-ou',
                        style: { 'margin-left': '29px' },
                        multi: true,
                        options: department
                            ? this.contacts.map(function (c) {
                                return {
                                    label: c.name + " <" + c.email + ">",
                                    value: c.email,
                                };
                            })
                            : [],
                        validators: { required: true },
                    }
                    : {
                        name: 'to',
                        type: 'label',
                        supplier: function () {
                            return _this.sender ? _this.contacts.filter(function (c) { return _this.sender.model.to.includes(c.email); }).map(function (c) { return c.name + " <" + c.email + ">"; }) : '';
                        },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Cc (企業、社員)',
                fieldName: 'cc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.class = 'ou';
            row.fields[0].labelBefore = '社員：';
            if (!_this.sender.inConfirm) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'checkbox',
                    labelField: 'label',
                    valueField: 'value',
                    labelBefore: '企業',
                    class: 'full multi-ou sticky-label',
                    multi: true,
                    options: department
                        ? _this.contacts.map(function (c) {
                            return {
                                label: c.name + " <" + c.email + ">",
                                value: c.email,
                            };
                        })
                        : [],
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                });
            }
            else if (_this.sender.model.cc_single.length) {
                row.fields.unshift({
                    name: 'cc_single',
                    type: 'label',
                    class: 'full',
                    supplier: function () {
                        return _this.contacts.filter(function (c) { return _this.sender.model.cc_single.includes(c.email); }).map(function (c) { return c.name + " <" + c.email + ">"; });
                    },
                }, {
                    name: 'cc-hr',
                    type: 'hr',
                    class: 'full',
                });
            }
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'multi-user',
                label: 'Bcc (社員)',
                fieldName: 'bcc',
                displayType: 'email',
            }, null, 'half sticky-label')[0];
            row.fields[0].labelBefore = '社員：';
            return row;
        })());
        metaData.groups[0].rows.push((function () {
            var row = _this.sender.dynamicService.getFormRows({
                fieldType: 'user',
                // displayType: 'email',
                label: '差出人',
                fieldName: 'from',
                validationStyle: { required: true },
            }, null, 'half')[0];
            row.fields[0].linkTo = !_this.sender.inConfirm ? ['signature'] : null;
            row.fields[0].specialOption = '';
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: 'テンプレート選択',
                fields: [
                    {
                        name: 'dummyTemplateType',
                        type: 'autocomplete',
                        linkTo: ['subject', 'body'],
                        placeholder: 'テンプレート名',
                        labelField: 'name',
                        class: 'half',
                        options: [],
                        supplier: function (value, callType, getValue) {
                            var typeIds = getValue('templateTypeIds');
                            return _this.sender.mailApiService.getMailTemplateNames().pipe(map(function (res) {
                                var filtered = res.filter(function (r) { return r.status === 1 && (!typeIds.length || typeIds.includes(r.mailTemplateTypeId)); });
                                return {
                                    options: filtered,
                                };
                            }));
                        },
                    },
                ],
            });
        }
        metaData.groups[0].rows.push({
            title: '件名',
            showRequired: true,
            fields: [
                !this.sender.inConfirm
                    ? {
                        name: 'subject',
                        type: 'text',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.subject : undefined,
                                };
                            }));
                        },
                    }
                    : {
                        type: 'label',
                        name: 'subject',
                        supplier: function () { return _this.sender.model.subject; },
                    },
            ],
        });
        metaData.groups[0].rows.push((function () {
            var row = {
                title: '添付ファイル',
                fields: [
                    {
                        type: 'label',
                        name: 'jobs',
                        linkTo: ['enterpriseFileIds'],
                        supplier: function () { return _this.sender.model.jobs.map(function (j) { return j.frontEnterpriseId + " " + j.enterpriseName; }).join(', '); },
                    },
                ],
            };
            row.class = 'ou';
            if (!_this.sender.inConfirm) {
                row.fields[0].labelBefore = '選択した企業(求人)：';
                row.fields[0].linkTo = ['enterpriseFileIds'];
            }
            else {
                row.fields.length = 0;
            }
            if (!_this.sender.inConfirm) {
                row.fields.push({
                    name: 'enterpriseFileIds',
                    type: 'autocomplete',
                    labelField: [
                        {
                            name: 'name',
                        },
                    ],
                    labelBefore: '企業：',
                    valueField: 'id',
                    multi: true,
                    class: 'options-only half',
                    options: [],
                    actions: [
                        {
                            title: 'データフォルダから選択',
                            type: 'RUNNABLE',
                            runnable: function () {
                                _this.sender.enterpriseFileIds = _this.sender.model.enterpriseFileIds.slice();
                                PopupControlComponent.instance.open({
                                    content: _this.sender.fileSelectTemplate,
                                    confirmText: '添付',
                                    cancelText: 'キャンセル',
                                    title: 'データフォルダ',
                                    confirmCallback: function () {
                                        _this.sender.model.enterpriseFileIds = _this.sender.enterpriseFileIds.slice();
                                    },
                                });
                            },
                        },
                    ],
                    supplier: function (value, callType, getValue) {
                        _this.sender.enterpriseFiles = {};
                        _this.sender.model.jobs.forEach(function (j) { return (_this.sender.enterpriseInfoById[j.enterpriseId] = j.frontEnterpriseId + " " + j.enterpriseName); });
                        return forkJoin(_this.sender.model.jobs.map(function (j) {
                            return _this.sender.fileService.getFiles('enterprise', j.enterpriseId).pipe(map(function (res) {
                                _this.sender.enterpriseFiles[j.enterpriseId] = res.filter(function (f) { return f.enterpriseSendFlag; });
                                return { options: _this.sender.enterpriseFiles[j.enterpriseId] };
                            }));
                        })).pipe(map(function (res) { return ({ options: Object.values(_this.sender.enterpriseFiles).flatten() }); }));
                    },
                });
                row.fields.push({
                    name: 'file-hr',
                    type: 'hr',
                    class: 'full',
                });
                row.fields.push({
                    name: 'studentFileIds',
                    type: 'checkbox',
                    class: 'multi-ou sticky-label full',
                    labelField: 'name',
                    valueField: 'id',
                    labelBefore: '求職者：',
                    multi: true,
                    options: [],
                    supplier: function () { return ({ options: _this.sender.files }); },
                });
            }
            else if (_this.sender.model.enterpriseFileIds.length || _this.sender.model.studentFileIds.length) {
                row.fields.push({
                    type: 'label',
                    name: 'zip',
                    class: 'link',
                    clickAction: function () {
                        return _this.sender.companyApiService.downloadEnterpriseArchiveFile(_this.sender.model.enterpriseId, {
                            studentId: _this.sender.model.student.id,
                            enterpriseDataFileIds: _this.sender.model.enterpriseFileIds,
                            studentDataFileIds: _this.sender.model.studentFileIds,
                        });
                    },
                    default: 'candidate_resume.zip',
                });
            }
            return row;
        })());
        if (!this.sender.inConfirm) {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'body',
                        type: 'textarea',
                        class: 'full',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var template = getValue('dummyTemplateType');
                            if (!template) {
                                return undefined;
                            }
                            var templateId = template ? template.id : 0;
                            return _this.sender.mailApiService.getMailTemplate(templateId).pipe(map(function (res) {
                                return {
                                    value: res ? res.body : undefined,
                                };
                            }));
                        },
                    },
                ],
            });
            metaData.groups[0].rows.push({
                title: '署名',
                class: 'ou',
                fields: [
                    {
                        name: 'signature',
                        type: 'dropdown',
                        labelField: 'title',
                        class: 'half',
                        options: [],
                        linkTo: ['signatureBody'],
                        supplier: function (value, callType, getValue) {
                            var id = _this.sender.authService.loginUser.id;
                            if (id === _this.sender.userIdForSignatures) {
                                return { options: _this.sender.userSignature };
                            }
                            return id
                                ? _this.sender.mailApiService.getSignature('user', id).pipe(map(function (res) {
                                    _this.sender.userIdForSignatures = id;
                                    _this.sender.userSignature = [{ title: NULL_SELECTED_VALUE, id: null, body: null }].concat(res);
                                    return {
                                        value: value ? res.find(function (s) { return s.id === value.id; }) : undefined,
                                        options: _this.sender.userSignature,
                                    };
                                }))
                                : { options: [] };
                        },
                    },
                    {
                        name: 'signatureBody',
                        type: 'textarea',
                        class: 'full',
                        supplier: function (value, callType, getValue) {
                            var sign = getValue('signature');
                            var ret = sign === _this.sender.selectedSignature ? undefined : sign ? sign.body : '';
                            _this.sender.selectedSignature = sign;
                            return ret;
                        },
                    },
                ],
            });
        }
        else {
            metaData.groups[0].rows.push({
                title: '本文',
                showRequired: true,
                fields: [
                    {
                        name: 'text',
                        type: 'label',
                        class: 'full pre',
                        validators: { required: true },
                        supplier: function (value, callType, getValue) {
                            if (value) {
                                return value;
                            }
                            var signatureBody = getValue('signatureBody');
                            var body = getValue('body');
                            return signatureBody ? body + '\n' + signatureBody : body;
                        },
                    },
                ],
            });
        }
        this.sender.meta = metaData;
    };
    StudentDetailConfig.prototype.update = function () {
        var _this = this;
        if (this.sender.model.student) {
            if (this.sender.studentIdForFiles !== this.sender.model.student.id) {
                this.sender.studentIdForFiles = this.sender.model.student.id;
                this.sender.fileService.getFiles('students', this.sender.model.student.id).subscribe(function (res) {
                    var _a;
                    _this.sender.files.length = 0;
                    (_a = _this.sender.files).push.apply(_a, res.filter(function (f) { return f.enterpriseSendFlag; }));
                    _this.initForm();
                });
            }
            if (this.sender.model.enterprise && this.sender.model.jobs && this.sender.model.jobs.length) {
                this.sender.companyApiService.getDepartments(this.sender.model.enterprise.id).subscribe(function (deps) {
                    _this.sender.model.department = deps.enterpriseDepartments.find(function (d) { return d.id === _this.sender.model.jobs[0].enterpriseDepartmentId; });
                    _this.sender.model.departmentId = (_this.sender.model.department || {}).id;
                    _this.sender.progressApiService
                        .checkProgress([_this.sender.model.student.id], _this.sender.model.jobs.map(function (j) { return j.id; }))
                        .subscribe(function (res) {
                        _this.sender.model.bcc = res.find(function (ch) { return ch.progressType === 1; })
                            ? _this.sender.model.jobs[0].jobUsers.filter(function (u) { return u.type === EnterpriseDepartmentUserType.PA; }).map(function (u) { return u.userId; })
                            : [];
                    });
                    _this.contacts = (_this.sender.model.department
                        ? [
                            _this.sender.model.department.dynamicData.contact1,
                            _this.sender.model.department.dynamicData.contact2,
                            _this.sender.model.department.dynamicData.contact3,
                            _this.sender.model.department.dynamicData.contact4,
                            _this.sender.model.department.dynamicData.contact5,
                        ]
                        : []).filter(function (c) { return c && c.name && c.email && !c.emailAvailable; });
                    if (environment.rpaUserIds.includes(_this.sender.authService.loginUser.id)) {
                        // ログインしているユーザーがRPAの場合、TOにチェックを入れず、CCを設定しない
                        // see https://benesse-g.backlog.jp/view/MACH_PJ-157
                    }
                    else {
                        _this.contacts.forEach(function (c, i) {
                            if (!i) {
                                _this.sender.model.to.push(c.email);
                            }
                            else {
                                _this.sender.model.cc_single.push(c.email);
                            }
                        });
                    }
                    _this.sender.model.cc = [
                        _this.sender.authService.loginUser.id
                    ].concat((_this.sender.model.jobs[0].jobUsers || [])
                        .filter(function (u) { return u.type === EnterpriseDepartmentUserType.RA || u.type === EnterpriseDepartmentUserType.アシ; })
                        .map(function (u) { return u.userId; }));
                    _this.initForm();
                });
            }
        }
    };
    StudentDetailConfig.prototype.save = function (toSave) {
        var _this = this;
        toSave.jobId = this.sender.model.jobs.map(function (j) { return j.id; })[0];
        toSave.studentId = this.sender.model.studentId;
        toSave.zip = true;
        this.sender.progressApiService.checkProgress([this.sender.model.student.id], [toSave.jobId]).subscribe(function (res) {
            if (res && res.length) {
                _this.sender.progressErrorInfo = res;
                PopupControlComponent.instance.open({
                    cancelText: '中止',
                    cancelCallback: function () { return _this.sender.close(); },
                    confirmText: '重複案件は上書き',
                    content: _this.sender.progressErrorTemplate,
                    title: '進捗重複',
                    confirmCallback: function () {
                        toSave.updateProgress = true;
                        return _this.sender.mailApiService
                            .sendEnterpriseResumeMail(_this.sender.model.enterpriseId, _this.sender.model.departmentId, toSave)
                            .pipe(tap(function (result) { return _this.sender.afterSend(result); }));
                    },
                    multiConfirm: [
                        {
                            confirmText: '重複案件は上書きしないで送信のみ行う',
                            confirmCallback: function () {
                                toSave.updateProgress = false;
                                return _this.sender.mailApiService
                                    .sendEnterpriseResumeMail(_this.sender.model.enterpriseId, _this.sender.model.departmentId, toSave)
                                    .pipe(tap(function (result) { return _this.sender.afterSend(result); }));
                            },
                        },
                    ],
                });
            }
            else {
                _this.sender.mailApiService
                    .sendEnterpriseResumeMail(_this.sender.model.enterpriseId, _this.sender.model.departmentId, toSave)
                    .subscribe(function (result) { return _this.sender.afterSend(result); });
            }
        });
    };
    return StudentDetailConfig;
}(StudentSendConfig));
export { StudentDetailConfig };
