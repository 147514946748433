import * as tslib_1 from "tslib";
import { ApiService } from '@agent-ds/shared/models/api-service';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CompanyApiService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyApiService, _super);
    function CompanyApiService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    CompanyApiService.prototype.getBllingAddressCode = function (billignBikeCb) {
        // const ans = this.http.get<string>('/api/enterprise/search/billing-address-code?bllingAddressCode='+bllingAddressCode);
        var ans = this.http.get('/api/enterprise/search/billing-address-code', { params: { billignBikeCb: billignBikeCb } });
        return ans;
    };
    CompanyApiService.prototype.getList = function (search) {
        return this.http.get('/api/enterprise/search', { params: (search ? search : {}) });
    };
    CompanyApiService.prototype.getSuggestions = function (keyword, ids) {
        return keyword || (ids && ids.length)
            ? this.http.get("/api/enterprise/suggest", {
                params: { name: keyword || undefined, id: ids },
            })
            : of([]);
    };
    CompanyApiService.prototype.getDetail = function (id) {
        return this.http.get('/api/enterprise/' + id);
    };
    CompanyApiService.prototype.create = function (companyData) {
        var _a;
        return this.http.post('/api/enterprise', companyData, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    CompanyApiService.prototype.update = function (id, companyData) {
        var _a;
        return this.http.put('/api/enterprise/' + id, companyData, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    CompanyApiService.prototype.getDepartments = function (enterpriseId) {
        return this.http.get("/api/enterprise/" + enterpriseId + "/departments");
    };
    CompanyApiService.prototype.addDepartment = function (enterpriseId, request) {
        var _a;
        return this.http.post("/api/enterprise/" + enterpriseId + "/department", request, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    CompanyApiService.prototype.updateDepartment = function (enterpriseId, id, request) {
        var _a;
        return this.http.put("/api/enterprise/" + enterpriseId + "/department/" + id, request, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    CompanyApiService.prototype.addBillingAddress = function (enterpriseId, request) {
        var _a;
        return this.http.post("/api/enterprise/" + enterpriseId + "/billing-address", request, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    CompanyApiService.prototype.updateBillingAddress = function (enterpriseId, id, request) {
        var _a;
        return this.http.put("/api/enterprise/" + enterpriseId + "/billing-address/" + id, request, {
            headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a),
        });
    };
    CompanyApiService.prototype.getContracts = function (id) {
        return this.http.get("/api/enterprise/" + id + "/contracts");
    };
    CompanyApiService.prototype.addContract = function (enterpriseId, request) {
        var _a;
        return this.http.post("/api/enterprise/" + enterpriseId + "/contract", request, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    CompanyApiService.prototype.updateContract = function (enterpriseId, id, request) {
        var _a;
        return this.http.put("/api/enterprise/" + enterpriseId + "/contract/" + id, request, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    CompanyApiService.prototype.getContacts = function (id, actionId) {
        return this.http.get("/api/enterprise/" + id + "/contact-histories", actionId ? { params: { action: "" + actionId } } : undefined);
    };
    CompanyApiService.prototype.addContact = function (id, request) {
        return this.http.post("/api/enterprise/" + id + "/contact-history", request);
    };
    CompanyApiService.prototype.updateContact = function (id, request) {
        return this.http.put("/api/enterprise/" + id + "/contact-history/" + request.id, request);
    };
    CompanyApiService.prototype.deleteContact = function (id, contactId) {
        return this.http.delete("/api/enterprise/" + id + "/contact-history/" + contactId);
    };
    CompanyApiService.prototype.getMemos = function (id) {
        return this.http.get("/api/enterprise/" + id + "/inner-info");
    };
    CompanyApiService.prototype.addMemo = function (id, info) {
        return this.http.post("/api/enterprise/" + id + "/inner-info", info);
    };
    CompanyApiService.prototype.updateMemo = function (id, info) {
        return this.http.put("/api/enterprise/" + id + "/inner-info/" + info.id, info);
    };
    CompanyApiService.prototype.deleteMemo = function (id, infoId) {
        return this.http.delete("/api/enterprise/" + id + "/inner-info/" + infoId);
    };
    CompanyApiService.prototype.downloadImportExampleFile = function () {
        this.http.get('/api/enterprise/csv/header', { responseType: 'blob', observe: 'response' }).subscribe(function (res) { return downloadResponse(res); });
    };
    CompanyApiService.prototype.uploadCompanyImportFile = function (file) {
        var formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post('/api/enterprise/csv/import', formData);
    };
    CompanyApiService.prototype.downloadEnterpriseArchiveFile = function (id, params) {
        this.http
            .get("/api/mail/enterprise/" + id + "/archived-files", { params: params, responseType: 'blob', observe: 'response' })
            .subscribe(function (res) { return downloadResponse(res); });
    };
    CompanyApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyApiService_Factory() { return new CompanyApiService(i0.ɵɵinject(i1.HttpClient)); }, token: CompanyApiService, providedIn: "root" });
    return CompanyApiService;
}(ApiService));
export { CompanyApiService };
