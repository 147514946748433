import { OnChanges } from '@angular/core';
var ClassDirective = /** @class */ (function () {
    function ClassDirective() {
        this.elClasses = [];
    }
    Object.defineProperty(ClassDirective.prototype, "clazz", {
        get: function () {
            return this.elClasses.join(' ');
        },
        set: function (val) {
            this.elClasses = val.split(' ');
        },
        enumerable: true,
        configurable: true
    });
    ClassDirective.prototype.ngOnChanges = function () {
        var _this = this;
        // unset each configured classes first
        this.elClasses = this.elClasses.filter(function (c) {
            for (var value in _this.classOptions) {
                if (_this.classOptions[value] === c) {
                    return false;
                }
            }
            return true;
        });
        // re-add the class vich has a matching value
        for (var valueLiteral in this.classOptions) {
            if (this.value && this.value.toString() === valueLiteral) {
                this.elClasses.push(this.classOptions[valueLiteral]);
                return;
            }
        }
        // if none of the values match, check if there is a default
        if (this.classOptions['default']) {
            this.elClasses.push(this.classOptions['default']);
        }
    };
    return ClassDirective;
}());
export { ClassDirective };
