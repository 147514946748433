<div class="row">
  <div class="row__column">
    <ag-dynamic-form
      class="row__head-form"
      [metadata]="headMeta"
      [model]="job"
      (validated)="onValidityChange($event, 0)"
      *ngIf="headMeta && job"
    ></ag-dynamic-form>
  </div>
</div>

<div class="row">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="leftMeta"
      [model]="job"
      (validated)="onValidityChange($event, 1)"
      (changed)="onFormChanged($event)"
      *ngIf="leftMeta && job"
    ></ag-dynamic-form>
  </div>

  <div class="row__column">
    <ag-dynamic-form
      [metadata]="rightMeta"
      [model]="job.dynamicData"
      (validated)="onValidityChange($event, 2)"
      (changed)="onFormChanged($event)"
      *ngIf="rightMeta && job"
    ></ag-dynamic-form>
  </div>
</div>

<div class="row">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="screeningMeta1"
      [model]="job.dynamicData"
      (validated)="onValidityChange($event, 3)"
      *ngIf="screeningMeta1 && job"
    ></ag-dynamic-form>
  </div>
  <div class="row__column row__column--no-title">
    <ag-dynamic-form
      [metadata]="screeningMeta2"
      [model]="job.dynamicData"
      (validated)="onValidityChange($event, 4)"
      *ngIf="screeningMeta2 && job"
    ></ag-dynamic-form>
  </div>
</div>

<div class="row">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="interviewMeta1"
      [model]="job.dynamicData"
      (validated)="onValidityChange($event, 5)"
      *ngIf="interviewMeta1 && job"
    ></ag-dynamic-form>
  </div>
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="interviewMeta2"
      [model]="job.dynamicData"
      (validated)="onValidityChange($event, 6)"
      *ngIf="interviewMeta2 && job"
    ></ag-dynamic-form>
  </div>
</div>

<div class="row row--padding-bottom-30">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="remarksMeta"
      [model]="job.dynamicData"
      *ngIf="remarksMeta && job"
    ></ag-dynamic-form>
  </div>
  <div class="row__column row__column--no-title">
    <ag-dynamic-form
      [metadata]="remarksMeta1"
      [model]="job.dynamicData"
      *ngIf="remarksMeta1 && job"
    ></ag-dynamic-form>
  </div>
</div>

<div *ngIf="customMeta && job" class="row row--custom-form">
  <div class="row__column">
    <ag-dynamic-form
      [metadata]="customMeta"
      [model]="job.dynamicData"
      (validated)="onValidityChange($event, 7)"
      *ngIf="customMeta && job"
    ></ag-dynamic-form>
  </div>
</div>

<div class="footer" *ngIf="!readonly">
  <div class="footer__container">
    <button
      class="footer__btn footer__btn--blue"
      [disabled]="!validity"
      (click)="save()"
    >
      更新
    </button>
  </div>
</div>

<ng-template #actionButton>
  <button class="btn btn--dark" (click)="onActionButton($event)">
    部署から引用
  </button>
</ng-template>
