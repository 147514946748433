/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-control.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/portal";
import * as i3 from "@angular/common";
import * as i4 from "./popup-control.component";
var styles_PopupControlComponent = [i0.styles];
var RenderType_PopupControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupControlComponent, data: {} });
export { RenderType_PopupControlComponent as RenderType_PopupControlComponent };
function View_PopupControlComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "popup__content__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.content; _ck(_v, 1, 0, currVal_0); }); }
function View_PopupControlComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.outlet; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PopupControlComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "popup__footer__cancel btn btn--larger btn--white-dirty btn--outline-blue-primary btn--regular-font ", _co.config.cancelClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.config.cancelText; _ck(_v, 1, 0, currVal_1); }); }
function View_PopupControlComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], [[8, "disabled", 0], [8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm(_v.context.$implicit.confirmCallback) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.confirmEnabled && !_v.context.$implicit.confirmEnabled()) || _co.inClose); var currVal_1 = i1.ɵinlineInterpolate(1, "popup__footer__confirm btn btn--larger ", (_v.context.$implicit.confirmClass || "btn--blue-primary"), ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.confirmText; _ck(_v, 1, 0, currVal_2); }); }
function View_PopupControlComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "popup__footer__confirm btn btn--larger btn--blue-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm(_co.config.confirmCallback) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.config.confirmEnabled && !_co.config.confirmEnabled()) || _co.inClose); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.confirmText; _ck(_v, 1, 0, currVal_1); }); }
function View_PopupControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "popup"]], [[4, "height", null], [4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "popup__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "popup__header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "popup__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupControlComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupControlComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "popup__footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupControlComponent_4)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupControlComponent_5)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupControlComponent_6)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isText; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.outlet; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.config.cancelText; _ck(_v, 13, 0, currVal_5); var currVal_6 = (_co.config.multiConfirm || i1.ɵEMPTY_ARRAY); _ck(_v, 15, 0, currVal_6); var currVal_7 = _co.config.confirmText; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.height; var currVal_1 = _co.config.width; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.config.title; _ck(_v, 3, 0, currVal_2); }); }
export function View_PopupControlComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupControlComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "ag-popup-control", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PopupControlComponent_0, RenderType_PopupControlComponent)), i1.ɵdid(1, 114688, null, 0, i4.PopupControlComponent, [i1.ElementRef, i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupControlComponentNgFactory = i1.ɵccf("ag-popup-control", i4.PopupControlComponent, View_PopupControlComponent_Host_0, {}, { closed: "closed" }, ["*"]);
export { PopupControlComponentNgFactory as PopupControlComponentNgFactory };
