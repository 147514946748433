<div class="login" [class.login--outlook]="guard.CURRENT_USAGE">
  <div class="login__title">
    <img
      src="/assets/images/mach.svg"
      [height]="pageInfo.layout === false ? 28 : 51"
    />
  </div>
  <div
    class="login__wrapper"
    *ngIf="pageInfo.layout == null || pageInfo.layout"
    [style.width]="'80%'"
  >
    <ag-dynamic-form
      #LoginForm
      [metadata]="meta"
      [model]="credentials"
      [style.width]="'100%'"
      (submitted)="onLogin()"
    ></ag-dynamic-form>
  </div>
  <span class="login__logout-notice" *ngIf="pageInfo.layout === false"
    >ログアウトしました。</span
  >
  <div class="login__footer">
    <button
      class="login__button btn btn--larger btn--blue-primary"
      [class.btn--outlook]="guard.CURRENT_USAGE"
      [disabled]="
        pageInfo.layout !== false &&
        (!credentials.email || !credentials.password)
      "
      (click)="pageInfo.layout === false ? (pageInfo.layout = null) : onLogin()"
    >
      {{ pageInfo.layout === false ? "再ログイン" : "ログイン" }}
    </button>
  </div>
</div>
