<div class="content">
  <div class="header">
    <div class="header__title"></div>
    <div
      class="header__buttons link"
      *ngIf="collapsed; else collapse_button"
      (click)="collapsed = false; collapsedNextYear.emit(collapsed)"
    >
      すべて表示>
    </div>
  </div>
  <div class="table">
    <div class="table__head" [class.table__head--collapsed]="collapsed">
      <div class="table__head__title table__head__title--big">
        {{ groupTitle }}
      </div>
      <div
        class="table__head__title table__head__title--double"
        [ngStyle]="{ 'margin-right': collapsed ? '0px' : '7px' }"
      >
        {{ (fiscalYear + 1).toString().slice(2) }}卒({{
          fiscalYear.toString().slice(2)
        }}卒含む)
      </div>
      <div
        class="table__head__title table__head__title--double"
        style="margin-left: 7px;"
        *ngIf="!collapsed"
      >
        {{ (fiscalYear + 2).toString().slice(2) }}卒
      </div>
      <div class="table__head__title">実績</div>
      <div class="table__head__title">遅延</div>
      <div class="table__head__title" *ngIf="!collapsed">実績</div>
      <div class="table__head__title" *ngIf="!collapsed">遅延</div>
    </div>
    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: 'RAレコメンド中',
          valueSelector: 'raRecommending',
          status: 10
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '本人OK待ち',
          valueSelector: 'waitingStudentOk',
          status: 20
        }"
      >
      </ng-container>
    </div>
    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '営業書類',
          subtitle: '提出前',
          valueSelector: 'salesDocumentBeforeSubmit',
          status: 30
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '結果待ち',
          valueSelector: 'salesDocumentWaitingResult',
          status: 40
        }"
      >
      </ng-container>
    </div>
    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '企業書類',
          subtitle: '提出待ち',
          valueSelector: 'enterpriseDocumentWaitingSubmit',
          status: 50
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '結果待ち',
          valueSelector: 'enterpriseDocumentWaitingResult',
          status: 60
        }"
      >
      </ng-container>
    </div>
    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: 'セミナー',
          subtitle: '設定中',
          valueSelector: 'seminarSetting',
          status: 70
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '確認中',
          valueSelector: 'seminarConfirming',
          status: 80
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '結果待ち',
          valueSelector: 'seminarCompletion',
          status: 90
        }"
      >
      </ng-container>
    </div>

    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '1次面接',
          subtitle: '設定中',
          valueSelector: 'firstInterviewSetting',
          status: 503
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '確認中',
          valueSelector: 'firstInterviewConfirming',
          status: 504
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '結果待ち',
          valueSelector: 'firstInterviewCompletion',
          status: 505
        }"
      >
      </ng-container>
    </div>

    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '2次以降面接',
          subtitle: '設定中',
          valueSelector: 'secondInterviewSetting',
          status: 506
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '確認中',
          valueSelector: 'secondInterviewConfirming',
          status: 507
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '結果待ち',
          valueSelector: 'secondInterviewCompletion',
          status: 508
        }"
      >
      </ng-container>
    </div>

    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '最終前面接',
          subtitle: '確認中',
          valueSelector: 'semiFinalInterviewConfirming',
          status: 511
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '結果待ち',
          valueSelector: 'semiFinalInterviewCompletion',
          status: 512
        }"
      >
      </ng-container>
    </div>

    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '最終面接',
          subtitle: '確認中',
          valueSelector: 'finalInterviewConfirming',
          status: 521
        }"
      >
      </ng-container>
    </div>
    <div class="table__row" [class.table__row--collapsed]="collapsed">
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          subtitle: '結果待ち',
          valueSelector: 'finalInterviewCompletion',
          status: 522
        }"
      >
      </ng-container>
    </div>

    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '本人意思待ち',
          valueSelector: 'waitingStudentDecision',
          status: 130
        }"
      >
      </ng-container>
    </div>
    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '内定承諾',
          valueSelector: 'acceptance',
          status: 140
        }"
      >
      </ng-container>
    </div>
    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
      *ngIf="isCA"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: 'NG連絡前',
          valueSelector: 'ngBeforeEnd',
          status: 170
        }"
      >
      </ng-container>
    </div>
    <div
      class="table__row table__row--group-start"
      [class.table__row--collapsed]="collapsed"
      *ngIf="!isCA"
    >
      <ng-container
        [ngTemplateOutlet]="row"
        [ngTemplateOutletContext]="{
          title: '辞退連絡前',
          valueSelector: 'declineBeforeEnd',
          status: 180
        }"
      >
      </ng-container>
    </div>
  </div>
</div>

<ng-template #collapse_button>
  <div
    class="header__buttons link"
    (click)="collapsed = true; collapsedNextYear.emit(collapsed)"
  >
    ＜閉じる
  </div>
</ng-template>

<ng-template
  #row
  let-title="title"
  let-subtitle="subtitle"
  let-valueSelector="valueSelector"
  let-status="status"
>
  <div class="table__row__title">{{ title }}</div>
  <div class="table__row__title">{{ subtitle }}</div>
  <ng-container
    [ngTemplateOutlet]="value_pair"
    [ngTemplateOutletContext]="{
      total: model.thisYear[valueSelector]?.total,
      delay: model.thisYear[valueSelector]?.delay,
      linkParams: getLinkParams(status, [fiscalYear, fiscalYear + 1])
    }"
  ></ng-container>
  <ng-container
    *ngIf="!collapsed"
    [ngTemplateOutlet]="value_pair"
    [ngTemplateOutletContext]="{
      total: model.nextYear[valueSelector]?.total,
      delay: model.nextYear[valueSelector]?.delay,
      linkParams: getLinkParams(status, [fiscalYear + 2])
    }"
  ></ng-container>
</ng-template>

<ng-template
  #value_pair
  let-total="total"
  let-delay="delay"
  let-linkParams="linkParams"
>
  <div
    class="table__row__value link"
    *ngIf="total != null"
    routerLink="/progresses/list"
    [queryParams]="linkParams"
  >
    {{ total | number | undef }}
  </div>
  <div class="table__row__value" *ngIf="total == null">
    -
  </div>
  <div class="table__row__unit">件</div>
  <div
    class="table__row__value link table__row__value--grey"
    [class.table__row__value--highlite]="delay"
    routerLink="/progresses/list"
    [queryParams]="getLinkParamsWithDelayParam(linkParams)"
  >
    {{ delay | number | undef }}
  </div>
  <div class="table__row__unit">件</div>
</ng-template>
