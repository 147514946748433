/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./global-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../search-bar/search-bar.component.ngfactory";
import * as i4 from "../../search-bar/search-bar.component";
import * as i5 from "../../../services/api/dynamic-field.service";
import * as i6 from "../../../services/auth.service";
import * as i7 from "@angular/common";
import * as i8 from "./global-header.component";
import * as i9 from "../../../services/dialog.service";
var styles_GlobalHeaderComponent = [i0.styles];
var RenderType_GlobalHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalHeaderComponent, data: {} });
export { RenderType_GlobalHeaderComponent as RenderType_GlobalHeaderComponent };
function View_GlobalHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [["class", "popup__item"], ["routerLink", "/signature-csv"], ["routerLinkActive", "popup__item--active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["\u500B\u4EBA\u8A2D\u5B9A"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "popup__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangePwClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30D1\u30B9\u30EF\u30FC\u30C9\u5909\u66F4"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "popup__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.authService.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u30ED\u30B0\u30A2\u30A6\u30C8"]))], function (_ck, _v) { var currVal_0 = "/signature-csv"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "popup__item--active"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GlobalHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-search-bar", [], null, null, null, i3.View_SearchBarComponent_0, i3.RenderType_SearchBarComponent)), i1.ɵdid(1, 245760, null, 0, i4.SearchBarComponent, [i2.Router, i5.DynamicFieldService, i6.AuthService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "login-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "login-name__arrow login-name__arrow--down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalHeaderComponent_1)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = _co.userMenuShown; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.authService.loginUser == null) ? null : _co.authService.loginUser.name); _ck(_v, 4, 0, currVal_0); }); }
export function View_GlobalHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-global-header", [], null, null, null, View_GlobalHeaderComponent_0, RenderType_GlobalHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.GlobalHeaderComponent, [i6.AuthService, i9.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlobalHeaderComponentNgFactory = i1.ɵccf("ag-global-header", i8.GlobalHeaderComponent, View_GlobalHeaderComponent_Host_0, {}, {}, []);
export { GlobalHeaderComponentNgFactory as GlobalHeaderComponentNgFactory };
