import * as tslib_1 from "tslib";
import { DynamicFormComponent } from '@agent-ds/shared/components/dynamic-form/dynamic-form.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { strip } from '@agent-ds/shared/models';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { DynamicFieldService, MasterApiService, StudentApiService } from '@agent-ds/shared/services';
import { deepClone, deepCompare, getValueFromObject } from '@agent-ds/shared/util/util';
import { ChangeDetectorRef, EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
import { forkJoin, timer } from 'rxjs';
import { concatMap } from 'rxjs/operators';
var StudentImportCompareComponent = /** @class */ (function () {
    function StudentImportCompareComponent(studentApiService, dynamicFieldService, masterApiService, chRef, datePipe) {
        this.studentApiService = studentApiService;
        this.dynamicFieldService = dynamicFieldService;
        this.masterApiService = masterApiService;
        this.chRef = chRef;
        this.datePipe = datePipe;
        this.studentImportErrorId = null;
        this.duplicatedStudentId = null;
        this.errorType = null;
        this.closeWithRefresh = new EventEmitter();
        this.compareData = null;
        this.basicMetaGroups = null;
        this.differenceMetaGroups = null;
        this.validity = true;
        this.validityArray = [true, true, true, true];
    }
    StudentImportCompareComponent.prototype.ngOnChanges = function (changes) {
        if ((changes['studentImportErrorId'] && changes['studentImportErrorId'].previousValue !== changes['studentImportErrorId'].currentValue) ||
            (changes['duplicatedStudentId'] && changes['duplicatedStudentId'].previousValue !== changes['duplicatedStudentId'].currentValue)) {
            this.getCompareData();
        }
    };
    StudentImportCompareComponent.prototype.asIsOrder = function (a, b) {
        return 1;
    };
    StudentImportCompareComponent.prototype.update = function () {
        var _this = this;
        if (!this.compareData || !this.compareData.csvStudent || !this.compareData.csvStudent.dynamicData) {
            return;
        }
        var studentUpdateModel = deepClone(this.compareData.dbStudent);
        // Commented out per request https://github.com/asial/mach-frontend/issues/1416
        //
        // if ((this.errorType === 3 && this.compareData.duplicateCount >= 3) || this.errorType === 4) {
        //   Object.keys(this.compareData.csvStudent.dynamicData).forEach((key) => {
        //     if (
        //       this.compareData.csvStudent.dynamicData[key] &&
        //       this.compareData.csvStudent.dynamicData[key] instanceof Object &&
        //       Object.keys(this.compareData.csvStudent.dynamicData[key]).length
        //     ) {
        //       if (studentUpdateModel.dynamicData[key] == null) {
        //         studentUpdateModel.dynamicData[key] = {};
        //       }
        //       Object.keys(this.compareData.csvStudent.dynamicData[key]).forEach((fieldKey) => {
        //         if (!studentUpdateModel.dynamicData[key][fieldKey] && this.compareData.csvStudent.dynamicData[key][fieldKey]) {
        //           studentUpdateModel.dynamicData[key][fieldKey] = this.compareData.csvStudent.dynamicData[key][fieldKey];
        //         }
        //       });
        //     } else if (!studentUpdateModel.dynamicData[key] && this.compareData.csvStudent.dynamicData[key]) {
        //       studentUpdateModel.dynamicData[key] = this.compareData.csvStudent.dynamicData[key];
        //     }
        //   });
        // }
        if (studentUpdateModel.dynamicData.firstRegistrationDate) {
            studentUpdateModel.dynamicData.lastRegistrationDate = this.compareData.csvStudent.dynamicData.firstRegistrationDate;
            delete studentUpdateModel.dynamicData.firstRegistrationDate;
        }
        if (studentUpdateModel.dynamicData.registrationRoute) {
            studentUpdateModel.dynamicData.lastRegistrationRoute = this.compareData.csvStudent.dynamicData.registrationRoute;
            delete studentUpdateModel.dynamicData.registrationRoute;
        }
        this.studentApiService
            .updateImportError(this.studentImportErrorId, this.duplicatedStudentId, {
            dynamicData: studentUpdateModel.dynamicData,
            userId: studentUpdateModel.dynamicData.studentUserId,
        })
            .subscribe(function () {
            _this.compareData = null;
            _this.closeWithRefresh.emit();
        });
    };
    StudentImportCompareComponent.prototype.delete = function () {
        var _this = this;
        PopupControlComponent.instance.open({
            title: '削除',
            cancelText: 'キャンセル',
            content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
            confirmText: 'OK',
            confirmCallback: function () {
                _this.studentApiService.deleteImportError([_this.studentImportErrorId]).subscribe(function () {
                    _this.compareData = null;
                    _this.closeWithRefresh.emit();
                });
            },
        });
    };
    StudentImportCompareComponent.prototype.onModelChange = function (metaGroups, metaKey) {
        if (!this.isInitInProgress) {
            this.changeFieldDifferenceClass(metaGroups, metaKey);
        }
    };
    StudentImportCompareComponent.prototype.copyRow = function (metaGroups, fieldName) {
        var _this = this;
        if (fieldName === 'name') {
            this.compareData.dbStudent.dynamicData.lastName = this.compareData.csvStudent.dynamicData.lastName;
            this.compareData.dbStudent.dynamicData.firstName = this.compareData.csvStudent.dynamicData.firstName;
        }
        else if (fieldName === 'phoneticName') {
            this.compareData.dbStudent.dynamicData.phoneticLastName = this.compareData.csvStudent.dynamicData.phoneticLastName;
            this.compareData.dbStudent.dynamicData.phoneticFirstName = this.compareData.csvStudent.dynamicData.phoneticFirstName;
        }
        else if (typeOf(this.compareData.csvStudent.dynamicData[fieldName]) === 'object' &&
            !Array.isArray(this.compareData.csvStudent.dynamicData[fieldName])) {
            this.compareData.dbStudent.dynamicData[fieldName] = tslib_1.__assign({}, this.compareData.csvStudent.dynamicData[fieldName]);
        }
        else {
            this.compareData.dbStudent.dynamicData[fieldName] = this.compareData.csvStudent.dynamicData[fieldName];
        }
        if (!metaGroups) {
            return;
        }
        var modelKeys = [];
        Object.keys(metaGroups).forEach(function (metaKey) {
            if (metaKey === fieldName && metaGroups[metaKey].csvMeta) {
                metaGroups[metaKey].csvMeta.groups[0].rows.forEach(function (row) {
                    row.fields.forEach(function (field) {
                        modelKeys.push(field.name);
                    });
                });
            }
        });
        modelKeys.forEach(function (modelKey) { return _this.changeFieldDifferenceClass(metaGroups, modelKey); });
    };
    StudentImportCompareComponent.prototype.getCompareData = function () {
        var _this = this;
        this.compareData = null;
        this.dynamicFieldService.fieldUpdateEvent
            .pipe(concatMap(function () { return _this.studentApiService.getImportErrorDiff(_this.studentImportErrorId, _this.duplicatedStudentId); }))
            .subscribe(function (compareData) {
            _this.isInitInProgress = true;
            _this.compareData = compareData;
            _this.compareData.dbStudent.dynamicData = tslib_1.__assign({}, _this.compareData.dbStudent.dynamicData, { studentUserId: _this.compareData.dbStudentUserId });
            _this.compareData.csvStudent.dynamicData = tslib_1.__assign({}, _this.compareData.csvStudent.dynamicData, { studentUserId: _this.compareData.csvStudentUserId });
            _this.initBasicForm();
            _this.initDiffForm();
            _this.generateSubTitles(_this.basicMetaGroups);
            _this.generateSubTitles(_this.differenceMetaGroups);
            _this.chRef.detectChanges();
            forkJoin(_this.masterApiService.getSchools(), _this.masterApiService.getLanguageCerts(), _this.masterApiService.getCertificates())
                .pipe(concatMap(function () { return timer(500); }))
                .subscribe(function () { return (_this.isInitInProgress = false); });
        });
    };
    StudentImportCompareComponent.prototype.initBasicForm = function () {
        var _this = this;
        this.basicMetaGroups = {
            firstRegistrationDate: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: [
                                (function () {
                                    var row = _this.dynamicFieldService.getFormRows(_this.dynamicFieldService.getDefinition('student', 'firstRegistrationDate'))[0];
                                    row.fields[0].type = 'label';
                                    row.fields[0].supplier = function () {
                                        return _this.compareData
                                            ? _this.datePipe.transform(_this.compareData.dbStudent.dynamicData['firstRegistrationDate'], 'yyyy/MM/dd')
                                            : '';
                                    };
                                    return row;
                                })(),
                            ],
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: [
                                (function () {
                                    var row = _this.dynamicFieldService.getFormRows(_this.dynamicFieldService.getDefinition('student', 'firstRegistrationDate'))[0];
                                    row.fields[0].type = 'label';
                                    row.fields[0].supplier = function () {
                                        return _this.compareData
                                            ? _this.datePipe.transform(_this.compareData.csvStudent.dynamicData['firstRegistrationDate'], 'yyyy/MM/dd')
                                            : '';
                                    };
                                    return row;
                                })(),
                            ],
                        },
                    ],
                },
            },
            registrationRoute: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: [
                                (function () {
                                    var row = _this.dynamicFieldService.getFormRows(_this.dynamicFieldService.getDefinition('student', 'registrationRoute'))[0];
                                    row.fields[0].type = 'label';
                                    return row;
                                })(),
                            ],
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: [
                                (function () {
                                    var row = _this.dynamicFieldService.getFormRows(_this.dynamicFieldService.getDefinition('student', 'registrationRoute'))[0];
                                    row.fields[0].type = 'label';
                                    return row;
                                })(),
                            ],
                        },
                    ],
                },
            },
            registrationRouteDetail: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationRouteDetail')),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationRouteDetail')),
                        },
                    ],
                },
            },
            registrationRouteHistory: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationRouteHistory')),
                        },
                    ],
                },
                csvMeta: null,
            },
            registrationStatus: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'registrationStatus')),
                        },
                    ],
                },
                csvMeta: null,
            },
            name: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: [
                                {
                                    title: '求職者氏名',
                                    showRequired: true,
                                    fields: [{ name: 'lastName', type: 'text' }, { name: 'firstName', type: 'text' }],
                                },
                            ],
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: [{ fields: [{ name: 'lastName', type: 'text' }, { name: 'firstName', type: 'text' }] }],
                        },
                    ],
                },
            },
            phoneticName: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: [
                                {
                                    title: 'フリガナ',
                                    showRequired: true,
                                    fields: [{ name: 'phoneticLastName', type: 'text' }, { name: 'phoneticFirstName', type: 'text' }],
                                },
                            ],
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: [
                                { showRequired: true, fields: [{ name: 'phoneticLastName', type: 'text' }, { name: 'phoneticFirstName', type: 'text' }] },
                            ],
                        },
                    ],
                },
            },
            gender: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'gender')),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'gender')),
                        },
                    ],
                },
            },
            birthday: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'birthday')),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'birthday')),
                        },
                    ],
                },
            },
            emailMain: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailMain')),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailMain')),
                        },
                    ],
                },
            },
            emailSub: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailSub')),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'emailSub')),
                        },
                    ],
                },
            },
            mobileTel: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'mobileTel')),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'mobileTel')),
                        },
                    ],
                },
            },
            houseTel: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'houseTel')),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'houseTel')),
                        },
                    ],
                },
            },
            contactNotes: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows(this.dynamicFieldService.getDefinition('student', 'contactNotes')),
                        },
                    ],
                },
                csvMeta: null,
            },
            studentUserId: {
                dbMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border-around title-w225',
                            rows: this.dynamicFieldService.getFormRows({
                                label: '担当者',
                                fieldType: 'user',
                                fieldName: 'studentUserId',
                            }),
                        },
                    ],
                },
                csvMeta: {
                    groups: [
                        {
                            class: 'title-nowrap no-border no-title-column',
                            rows: this.dynamicFieldService.getFormRows({
                                label: '担当者',
                                fieldType: 'user',
                                fieldName: 'studentUserId',
                            }),
                        },
                    ],
                },
            },
        };
        this.basicMetaGroups.registrationRouteHistory.dbMeta.groups.forEach(function (group) {
            return group.rows.forEach(function (row) { return row.fields.forEach(function (field) { return (field.disabled = true); }); });
        });
        this.addFixHeightToTextAreaFields(this.basicMetaGroups.registrationRouteDetail.dbMeta.groups);
        this.addFixHeightToTextAreaFields(this.basicMetaGroups.registrationRouteDetail.csvMeta.groups);
        this.addFixHeightToTextAreaFields(this.basicMetaGroups.contactNotes.dbMeta.groups);
        this.generateFieldDifferenceClass(this.basicMetaGroups);
        this.finishMetaFormat(this.basicMetaGroups, true);
    };
    StudentImportCompareComponent.prototype.generateFieldDifferenceClass = function (metaGroups) {
        var _this = this;
        if (!metaGroups) {
            return;
        }
        var modelKeys = [];
        Object.keys(metaGroups).forEach(function (metaKey) {
            if (metaGroups[metaKey].csvMeta) {
                metaGroups[metaKey].csvMeta.groups[0].rows.forEach(function (row) {
                    row.fields.forEach(function (field) {
                        modelKeys.push(field.name);
                    });
                });
            }
        });
        modelKeys.forEach(function (modelKey) { return _this.changeFieldDifferenceClass(metaGroups, modelKey, metaGroups === _this.differenceMetaGroups); });
    };
    StudentImportCompareComponent.prototype.changeFieldDifferenceClass = function (metaGroups, changedModelFieldKey, changeHidden) {
        var modelKey = changedModelFieldKey.includes('.') ? changedModelFieldKey.split('.')[0] : changedModelFieldKey;
        var fieldKey = changedModelFieldKey.includes('.') ? changedModelFieldKey.split('.')[1] : null;
        var metaKey;
        switch (modelKey) {
            case 'lastName':
                metaKey = 'name';
                break;
            case 'firstName':
                metaKey = 'name';
                break;
            case 'phoneticLastName':
                metaKey = 'phoneticName';
                break;
            case 'phoneticFirstName':
                metaKey = 'phoneticName';
                break;
            default:
                metaKey = modelKey;
                break;
        }
        var dbValue = modelKey === 'firstRegistrationDate'
            ? new Date(this.compareData.dbStudent.dynamicData['firstRegistrationDate']).getTime()
            : this.compareData.dbStudent.dynamicData[modelKey];
        var csvValue = modelKey === 'firstRegistrationDate'
            ? new Date(this.compareData.csvStudent.dynamicData['firstRegistrationDate']).getTime()
            : this.compareData.csvStudent.dynamicData[modelKey];
        var value = dbValue || csvValue;
        if (value !== undefined) {
            if (value instanceof Object) {
                dbValue = tslib_1.__assign({}, dbValue);
                csvValue = tslib_1.__assign({}, csvValue);
                var dbVal = fieldKey ? dbValue[fieldKey] : dbValue;
                var csvVal = fieldKey ? csvValue[fieldKey] : csvValue;
                if (dbVal) {
                    dbVal = new Date(dbVal).isValid() ? new Date(dbVal).getTime() : dbVal;
                }
                if (csvVal) {
                    csvVal = new Date(csvVal).isValid() ? new Date(csvVal).getTime() : csvVal;
                }
                if (['schoolCode', 'subDepartmentCode', 'departmentCode'].includes(fieldKey)) {
                    switch (fieldKey) {
                        case 'schoolCode':
                            dbVal = dbValue['schoolName'];
                            csvVal = csvValue['schoolName'];
                            break;
                        case 'subDepartmentCode':
                            dbVal = dbValue['subDepartmentName'];
                            csvVal = csvValue['subDepartmentName'];
                            break;
                        case 'departmentCode':
                            dbVal = dbValue['departmentName'];
                            csvVal = csvValue['departmentName'];
                            break;
                        default:
                            break;
                    }
                }
                if ((dbVal && csvVal && !deepCompare(dbVal, csvVal)) || (!dbVal && csvVal) || (!csvVal && dbVal)) {
                    this.objectFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.addFieldClass, changeHidden);
                }
                else {
                    this.objectFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.removeFieldClass, changeHidden);
                }
                this.detect();
            }
            else if (value) {
                if (dbValue) {
                    dbValue = new Date(dbValue).isValid() ? new Date(dbValue).getTime() : dbValue;
                }
                if (csvValue) {
                    csvValue = new Date(csvValue).isValid() ? new Date(csvValue).getTime() : csvValue;
                }
                if ((dbValue && csvValue && !deepCompare(dbValue, csvValue)) || (!dbValue && csvValue) || (dbValue && !csvValue)) {
                    this.simpleFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.addFieldClass, changeHidden);
                }
                else {
                    this.simpleFieldClassMapper(metaGroups, metaKey, modelKey, fieldKey, this.removeFieldClass, changeHidden);
                }
                this.detect();
            }
        }
    };
    StudentImportCompareComponent.prototype.detect = function () {
        var _this = this;
        if (this.basicCsvForm) {
            Object.keys(this.basicMetaGroups).forEach(function (metaKey) { return (_this.basicMetaGroups[metaKey].csvMeta = tslib_1.__assign({}, _this.basicMetaGroups[metaKey].csvMeta)); });
            this.basicCsvForm.detect();
        }
        if (this.basicDbForm) {
            Object.keys(this.basicMetaGroups).forEach(function (metaKey) { return (_this.basicMetaGroups[metaKey].dbMeta = tslib_1.__assign({}, _this.basicMetaGroups[metaKey].dbMeta)); });
            this.basicDbForm.detect();
        }
        if (this.diffCsvForm) {
            Object.keys(this.differenceMetaGroups).forEach(function (metaKey) { return (_this.differenceMetaGroups[metaKey].csvMeta = tslib_1.__assign({}, _this.differenceMetaGroups[metaKey].csvMeta)); });
            this.diffCsvForm.detect();
        }
        if (this.diffDbForm) {
            Object.keys(this.differenceMetaGroups).forEach(function (metaKey) { return (_this.differenceMetaGroups[metaKey].dbMeta = tslib_1.__assign({}, _this.differenceMetaGroups[metaKey].dbMeta)); });
            this.diffDbForm.detect();
        }
    };
    StudentImportCompareComponent.prototype.addFieldClass = function (field, changeHidden) {
        field.class = !field.class ? 'red-border' : field.class.includes('red-border') ? field.class : field.class + " red-border";
        if (changeHidden) {
            field.hidden = false;
        }
    };
    StudentImportCompareComponent.prototype.removeFieldClass = function (field, changeHidden) {
        field.class =
            field.class && field.class.includes('red-border')
                ? field.class
                    .split('red-border')
                    .map(function (v) { return v.trim(); })
                    .join('')
                : field.class;
        if (changeHidden) {
            field.hidden = true;
        }
    };
    StudentImportCompareComponent.prototype.objectFieldClassMapper = function (metaGroups, metaKey, modelKey, fieldKey, fieldOperation, changeHidden) {
        if (!metaGroups || !metaGroups[metaKey]) {
            return;
        }
        Object.keys(metaGroups[metaKey]).forEach(function (metaGroup) {
            metaGroups[metaKey][metaGroup].groups[0].rows.forEach(function (row) {
                row.fields.forEach(function (field) {
                    if (strip(field.name) === (fieldKey ? modelKey + "." + fieldKey : modelKey)) {
                        fieldOperation(field, changeHidden);
                    }
                });
            });
        });
    };
    StudentImportCompareComponent.prototype.simpleFieldClassMapper = function (metaGroups, metaKey, modelKey, fieldKey, fieldOperation, changeHidden) {
        var _this = this;
        if (metaKey !== 'firstRegistrationDate' && !metaGroups[metaKey]) {
            return;
        }
        if (!metaGroups || !metaGroups[metaKey]) {
            return;
        }
        Object.keys(metaGroups[metaKey]).forEach(function (metaGroup) {
            var field = metaGroups[metaKey][metaGroup].groups[0].rows[0].fields.find(function (fieldEl) {
                var fieldDef = _this.dynamicFieldService.getDefinition('student', modelKey);
                if (fieldDef && fieldDef.fieldType === 'date' && fieldDef.displayType.includes('time')) {
                    return fieldEl.name === modelKey + "." + fieldKey;
                }
                return fieldEl.name === modelKey || (metaKey === 'firstRegistrationDate' && fieldEl.name === 'firstRegistrationDate');
            });
            if (field) {
                fieldOperation(field, changeHidden);
            }
            metaGroups[metaKey][metaGroup].groups[0].rows[0].hidden =
                metaGroups[metaKey][metaGroup].groups[0].rows[0].fields.find(function (f) { return !field.hidden; }) == null;
        });
    };
    StudentImportCompareComponent.prototype.initDiffForm = function () {
        var _this = this;
        if (!this.compareData ||
            !this.compareData.dbStudent ||
            !this.compareData.dbStudent.dynamicData ||
            !this.compareData.csvStudent ||
            !this.compareData.csvStudent.dynamicData) {
            return;
        }
        Object.keys(this.compareData.csvStudent.dynamicData)
            .sort(function (a, b) { return a.localeCompare(b); })
            .forEach(function (key) {
            if (!['firstRegistrationDate', 'registrationRoute', 'lastRegistrationDate', 'lastRegistrationRoute'].includes(key) &&
                ((_this.compareData.csvStudent.dynamicData[key] &&
                    _this.compareData.dbStudent.dynamicData[key] &&
                    _this.compareData.dbStudent.dynamicData[key] !== _this.compareData.csvStudent.dynamicData[key]) ||
                    (!_this.compareData.csvStudent.dynamicData[key] && _this.compareData.dbStudent.dynamicData[key]) ||
                    (_this.compareData.csvStudent.dynamicData[key] && !_this.compareData.dbStudent.dynamicData[key]))) {
                if (!Object.keys(_this.basicMetaGroups).concat([
                    'firstRegistrationDate',
                    'lastName',
                    'firstName',
                    'phoneticLastName',
                    'phoneticFirstName',
                ]).includes(key)) {
                    _this.differenceMetaGroups = _this.differenceMetaGroups ? _this.differenceMetaGroups : {};
                    _this.differenceMetaGroups[key] = {
                        dbMeta: {
                            groups: [
                                {
                                    class: 'title-nowrap no-border-around title-w225 border-top',
                                    rows: _this.dynamicFieldService.getFormRows(_this.dynamicFieldService.getDefinition('student', key)).slice(),
                                },
                            ],
                        },
                        csvMeta: {
                            groups: [
                                {
                                    class: 'title-nowrap no-border no-title-column border-top',
                                    rows: _this.dynamicFieldService.getFormRows(_this.dynamicFieldService.getDefinition('student', key)).slice(),
                                },
                            ],
                        },
                    };
                    _this.addFixHeightToTextAreaFields(_this.differenceMetaGroups[key].csvMeta.groups);
                    _this.addFixHeightToTextAreaFields(_this.differenceMetaGroups[key].dbMeta.groups);
                }
            }
        });
        this.generateFieldDifferenceClass(this.differenceMetaGroups);
        this.finishMetaFormat(this.differenceMetaGroups);
    };
    StudentImportCompareComponent.prototype.addFixHeightToTextAreaFields = function (groups, minRows, maxRows) {
        if (minRows === void 0) { minRows = 15; }
        if (maxRows === void 0) { maxRows = 15; }
        groups.forEach(function (group) {
            return group.rows.forEach(function (row) {
                return row.fields.forEach(function (field) {
                    if (field.type === 'textarea') {
                        field.style = tslib_1.__assign({}, field.style, { minRows: minRows, maxRows: maxRows });
                    }
                });
            });
        });
    };
    StudentImportCompareComponent.prototype.onValidityChange = function (validity, index) {
        this.validityArray[index] = validity;
        this.validity = this.validityArray.find(function (v) { return !v; }) == null;
    };
    StudentImportCompareComponent.prototype.finishMetaFormat = function (metaGroups, titleCleanOnly) {
        if (titleCleanOnly === void 0) { titleCleanOnly = false; }
        Object.values(metaGroups).forEach(function (meta) {
            if (meta.csvMeta) {
                meta.csvMeta.groups.forEach(function (group, gIndex) {
                    group.rows.forEach(function (row, rIndex) {
                        delete row.title;
                        if (!titleCleanOnly) {
                            row.hidden = row.fields.every(function (f) { return f.hidden; });
                            var dbRow = getValueFromObject(meta, "dbMeta.groups." + gIndex + ".rows." + rIndex);
                            if (dbRow) {
                                dbRow.hidden = row.hidden;
                            }
                        }
                    });
                    var isEveryRowHidden = group.rows.every(function (r) { return r.hidden; });
                    if (!isEveryRowHidden) {
                        group.rows.forEach(function (row) {
                            row.hidden = false;
                            row.fields.forEach(function (f) { return (f.hidden = false); });
                        });
                        meta.dbMeta.groups[gIndex].rows.forEach(function (row) {
                            row.hidden = false;
                            row.fields.forEach(function (f) { return (f.hidden = false); });
                        });
                    }
                });
            }
        });
    };
    StudentImportCompareComponent.prototype.toShowCopy = function (meta) {
        return meta != null && meta.groups != null && meta.groups.find(function (g) { return g.rows.find(function (r) { return !r.hidden; }) != null; }) != null;
    };
    StudentImportCompareComponent.prototype.generateSubTitles = function (metaGroups) {
        Object.keys(metaGroups).forEach(function (metaKey) {
            return metaGroups[metaKey].dbMeta &&
                metaGroups[metaKey].dbMeta.groups.forEach(function (group, groupIndex) {
                    group.rows.forEach(function (row, rowIndex) {
                        if (row.title && row.title.includes('\n')) {
                            var subTitle = row.title.split('\n')[1];
                            row.title = row.title.split('\n')[0];
                            row.subTitle = subTitle;
                            metaGroups[metaKey].csvMeta.groups[groupIndex].rows[rowIndex].title = row.title;
                            metaGroups[metaKey].csvMeta.groups[groupIndex].rows[rowIndex].subTitle = row.subTitle;
                        }
                    });
                });
        });
    };
    return StudentImportCompareComponent;
}());
export { StudentImportCompareComponent };
