<ag-search-bar></ag-search-bar>
<div class="login-name" (click)="userClick($event)">
  <span>{{ authService.loginUser?.name }}</span>
  <span class="login-name__arrow login-name__arrow--down"></span>
  <div class="popup" *ngIf="userMenuShown">
    <span
      class="popup__item"
      routerLink="/signature-csv"
      routerLinkActive="popup__item--active"
      >個人設定</span
    >
    <span class="popup__item" (click)="onChangePwClicked()"
      >パスワード変更</span
    >
    <span class="popup__item" (click)="authService.logout()">ログアウト</span>
  </div>
</div>
