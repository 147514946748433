import * as tslib_1 from "tslib";
import { ExtendedProgressStatusCode } from '@agent-ds/shared/enums';
import { DynamicFieldService, ExportApiService } from '@agent-ds/shared/services';
import { OnDestroy, OnInit } from '@angular/core';
var ProgressListExportPageComponent = /** @class */ (function () {
    function ProgressListExportPageComponent(dynamicService, exportApiService) {
        this.dynamicService = dynamicService;
        this.exportApiService = exportApiService;
        this.formModel = {};
        this.isExportInProgress = false;
    }
    ProgressListExportPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.formMeta = {
                groups: [
                    {
                        rows: _this.dynamicService.getFormRows({ fieldName: 'studentTeamId', label: '担当チーム (求職者)', fieldType: 'team' }, null, 'third').concat(_this.dynamicService.getFormRows({ fieldName: 'studentUserId', fieldType: 'multi-user', label: '担当者 (求職者)' }, null, 'third'), _this.dynamicService.getFormRows({ fieldName: 'enterpriseTeamId', label: '担当チーム (求人)', fieldType: 'team' }, null, 'third'), _this.dynamicService.getFormRows({ fieldName: 'enterpriseUserId', fieldType: 'multi-user', label: '担当者 (求人)' }, null, 'third'), _this.dynamicService.getFormRows({
                            fieldName: 'studentId',
                            fieldType: 'multi-student',
                            label: '求職者',
                            displayStyle: { placeholder: '求職者名、求職者カナ、求職者ID' },
                        }, null, 'third'), _this.dynamicService.getFormRows({ fieldName: 'jobId', fieldType: 'multi-job', label: '求人' }, null, 'third'), _this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('student', 'interviewDate'), { fieldType: 'date-range', displayType: 'date-more-advanced' })), [
                            {
                                title: '最新ステータス',
                                fields: [
                                    {
                                        name: 'status',
                                        type: 'checkbox',
                                        multi: true,
                                        class: 'medium',
                                        valueField: 'value',
                                        labelField: 'label',
                                        options: [
                                            10,
                                            20,
                                            30,
                                            40,
                                            50,
                                            60,
                                            70,
                                            80,
                                            90,
                                            500,
                                            501,
                                            502,
                                            511,
                                            512,
                                            521,
                                            522,
                                            130,
                                            140,
                                            150,
                                            160,
                                            170,
                                            180,
                                            190,
                                            200,
                                        ].map(function (key) { return ({ label: ExtendedProgressStatusCode[key], value: key }); }).slice(),
                                        actions: [
                                            {
                                                type: 'RUNNABLE',
                                                title: '稼働中',
                                                runnable: function () { return [60, 70, 80, 90, 130, 500, 501, 502, 510, 511, 512, 520, 521, 522]; },
                                            },
                                            {
                                                type: 'RUNNABLE',
                                                title: '進捗中',
                                                runnable: function () { return [130, 500, 501, 502, 510, 511, 512, 520, 521, 522]; },
                                            },
                                            {
                                                type: 'RUNNABLE',
                                                title: '選択解除',
                                                runnable: function () { return []; },
                                            },
                                        ],
                                        actionsClass: 'before full mb-3',
                                    },
                                ],
                            },
                        ]),
                    },
                ],
            };
        });
    };
    ProgressListExportPageComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
    };
    ProgressListExportPageComponent.prototype.export = function () {
        var _this = this;
        this.isExportInProgress = true;
        this.exportApiService
            .exportProgresses(this.formModel)
            .subscribe(function () { return (_this.isExportInProgress = false); }, function () { return (_this.isExportInProgress = false); });
    };
    return ProgressListExportPageComponent;
}());
export { ProgressListExportPageComponent };
