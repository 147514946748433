import * as tslib_1 from "tslib";
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { DynamicFieldService } from '@agent-ds/shared/services';
import { HelperBase } from './helper-base';
var ContractorInformationHelper = /** @class */ (function (_super) {
    tslib_1.__extends(ContractorInformationHelper, _super);
    function ContractorInformationHelper(dynamicService, datePipe) {
        var _this = _super.call(this, dynamicService, datePipe) || this;
        _this.contractOptions = [];
        _this.editable = { rows: [] };
        _this.readonly = { rows: [] };
        return _this;
    }
    ContractorInformationHelper.prototype.init = function (detailPage) {
        var _this = this;
        detailPage.contractSubject.subscribe(function (data) {
            var _a;
            // the object reference of "this.contractOptions" must be unchanged
            _this.contractOptions.splice(0, _this.contractOptions.length);
            (_a = _this.contractOptions).push.apply(_a, data.filter(function (c) { return c.status === '有効'; }));
        });
    };
    ContractorInformationHelper.prototype.updateFieldDefinitions = function () {
        this.editable = this.getTemplate(true);
        this.readonly = this.getTemplate(false);
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [this.readonly, this.readonly, this.readonly, this.readonly],
            [this.editable, this.editable, this.editable, this.editable],
            [this.readonly, this.readonly, this.readonly, this.readonly],
            [this.readonly, this.readonly, this.readonly, this.readonly],
        ];
    };
    ContractorInformationHelper.prototype.getMetadata = function (approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus]];
    };
    ContractorInformationHelper.prototype.getTemplate = function (editable) {
        var _this = this;
        return {
            title: '契約先情報',
            class: 'form__group--golden-title-border',
            rows: [
                editable
                    ? {
                        title: '契約先',
                        showRequired: true,
                        fields: [
                            {
                                name: 'sales.contractId',
                                type: 'dropdown',
                                class: 'fill',
                                valueField: 'id',
                                labelField: 'label',
                                options: this.contractOptions,
                                linkTo: [
                                    'contract.companyName',
                                    'contract.phoneticCompanyName',
                                    'contract.departmentName',
                                    'contract.contact.name',
                                    'contract.address',
                                    'contract.contact.tel',
                                    'contract.contact.fax',
                                    'contract.contractRemarks',
                                    'contract.paymentRemarks',
                                ],
                                validators: { required: true },
                            },
                        ],
                    }
                    : {
                        title: '契約先',
                        fields: [
                            {
                                name: 'sales.contractId',
                                type: 'label',
                                hidden: true,
                                linkTo: [
                                    'contract.title',
                                    'contract.companyName',
                                    'contract.phoneticCompanyName',
                                    'contract.departmentName',
                                    'contract.contact.name',
                                    'contract.address',
                                    'contract.contact.tel',
                                    'contract.contact.fax',
                                    'contract.contractRemarks',
                                    'contract.paymentRemarks',
                                ],
                            },
                            {
                                name: 'contract.title',
                                type: 'label',
                                supplier: function (value, callType, getter) {
                                    var contractId = getter('sales.contractId');
                                    var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                    return contract
                                        ? [contract.frontId, contract.companyName, contract.departmentName, contract.contractClassification]
                                            .filter(function (v) { return v; })
                                            .join(' ')
                                        : '';
                                },
                            },
                        ],
                    },
                {
                    title: (this.dynamicService.getDefinition('contract', 'companyName') || { label: '企業名' }).label,
                    fields: [
                        {
                            name: 'contract.companyName',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.companyName ? contract.companyName : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('contract', 'phoneticCompanyName') || { label: '企業名カナ' }).label,
                    fields: [
                        {
                            name: 'contract.phoneticCompanyName',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.phoneticCompanyName ? contract.phoneticCompanyName : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('contract', 'departmentName') || { label: '部署名' }).label,
                    fields: [
                        {
                            name: 'contract.departmentName',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.departmentName ? contract.departmentName : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('contract', 'contact') || { label: '担当者名' }).label,
                    fields: [
                        {
                            name: 'contract.contact.name',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.contact && contract.contact.name ? contract.contact.name : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('contract', 'address') || { label: '住所' }).label,
                    fields: [
                        {
                            name: 'contract.address',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.address
                                    ? [
                                        "" + (contract.address.zip ? contract.address.zip.slice(0, 3) + '-' + contract.address.zip.slice(3) : ''),
                                        "" + (contract.address.prefecture ? contract.address.prefecture : ''),
                                        "" + (contract.address.address1 ? contract.address.address1 : ''),
                                        "" + (contract.address.address2 ? contract.address.address2 : ''),
                                        "" + (contract.address.address3 ? contract.address.address3 : ''),
                                    ].join(' ')
                                    : '';
                            },
                        },
                    ],
                },
                {
                    title: 'Tel',
                    fields: [
                        {
                            name: 'contract.contact.tel',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.contact && contract.contact.tel ? contract.contact.tel : '';
                            },
                        },
                    ],
                },
                {
                    title: 'Fax',
                    fields: [
                        {
                            name: 'contract.contact.fax',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.contact && contract.contact.fax ? contract.contact.fax : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('contract', 'contractRemarks') || { label: '契約備考' }).label,
                    fields: [
                        {
                            name: 'contract.contractRemarks',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.contractRemarks ? contract.contractRemarks : '';
                            },
                        },
                    ],
                },
                {
                    title: (this.dynamicService.getDefinition('contract', 'paymentRemarks') || { label: '入金・その他備考' }).label,
                    fields: [
                        {
                            name: 'contract.paymentRemarks',
                            type: 'label',
                            supplier: function (value, callType, getter) {
                                var contractId = getter('sales.contractId');
                                var contract = _this.contractOptions.find(function (v) { return v.id === contractId; });
                                return contract && contract.paymentRemarks ? contract.paymentRemarks : '';
                            },
                        },
                    ],
                },
            ],
        };
    };
    return ContractorInformationHelper;
}(HelperBase));
export { ContractorInformationHelper };
