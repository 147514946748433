import { DashboardType } from '@agent-ds/shared/interfaces';
import { DetailPage } from '@agent-ds/shared/models';
import { AuthService, JobApiService } from '@agent-ds/shared/services';
import { AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
var GlobalMenuComponent = /** @class */ (function () {
    function GlobalMenuComponent(router, authService, jobService) {
        var _this = this;
        this.router = router;
        this.authService = authService;
        this.jobService = jobService;
        this.activePopupMenuIndex = null;
        this.mouseLeaveSubcriptions = [];
        this.favorites = 0;
        this.isIntakeCa = false;
        this.popupConfigs = [
            undefined,
            [
                { routerLink: '/students/search', label: '求職者検索' },
                { routerLink: '/students/uploads', label: 'アップロードデータ一覧' },
                { routerLink: '/students/import', label: '求職者データ取り込み' },
            ],
            [{ routerLink: '/enterprises/search', label: '企業検索' }, { routerLink: '/enterprises/import', label: '企業データ取り込み' }],
            undefined,
            undefined,
            [
                { routerLink: '/progresses/search', label: '進捗検索' },
                { routerLink: '/progresses/export', label: '進捗リスト' },
                { routerLink: '/progresses/report', label: '月間進捗' },
            ],
            [{ routerLink: '/sales/search', label: '成約検索' }, { routerLink: '/sales/report', label: '担当別月間売上' }],
            undefined,
        ];
        this.popupConfigsForIntakeCa = [
            undefined,
            [
                { routerLink: '/students/searchWithTodaysCounseling', label: '当日カウ検索' },
                { routerLink: '/students/searchWithSubUser', label: '担当求職者検索' },
            ],
            [{ routerLink: '/enterprises/search', label: '企業検索' }],
            undefined,
            undefined,
            [
                { routerLink: '/progresses/search', label: '進捗検索' },
                { routerLink: '/progresses/export', label: '進捗リスト' },
                { routerLink: '/progresses/report', label: '月間進捗' },
            ],
            [{ routerLink: '/sales/search', label: '成約検索' }, { routerLink: '/sales/report', label: '担当別月間売上' }],
            undefined,
        ];
        this.authService.isLogined().subscribe(function () {
            // 副担当者かどうかを確認
            _this.isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
        });
    }
    Object.defineProperty(GlobalMenuComponent.prototype, "popupMenuPaddingTop", {
        get: function () {
            return (this.activePopupMenuIndex - (!this.favorites && this.activePopupMenuIndex > 4 ? 1 : 0)) * 60;
        },
        enumerable: true,
        configurable: true
    });
    GlobalMenuComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.authService.isLogined().subscribe(function (loggedIn) {
            if (loggedIn) {
                _this.updateFavorites();
            }
            else {
                _this.favorites = 0;
                DetailPage.resetAll();
            }
        });
        this.jobService.jobFavoritesChanged.subscribe(function () {
            _this.updateFavorites();
        });
    };
    GlobalMenuComponent.prototype.updateFavorites = function () {
        var _this = this;
        if (this.updateFavTimer) {
            clearTimeout(this.updateFavTimer);
            this.updateFavTimer = null;
        }
        this.updateFavTimer = setTimeout(function () {
            _this.jobService.getFavorites().subscribe(function (favorites) {
                _this.favorites = favorites.length;
            });
        }, 2000);
    };
    GlobalMenuComponent.prototype.onMouseEnter = function (index) {
        if (this.mouseLeaveSubcriptions && this.mouseLeaveSubcriptions.length) {
            this.mouseLeaveSubcriptions.forEach(function (s) { return s.unsubscribe(); });
            this.mouseLeaveSubcriptions = [];
        }
        if (this.activePopupMenuIndex !== index) {
            // インテークCA用のメニューと通常メニューの出し分け
            this.activePopupConfig = this.isIntakeCa ? this.popupConfigsForIntakeCa[index] : this.popupConfigs[index];
            this.activePopupMenuIndex = index;
        }
    };
    GlobalMenuComponent.prototype.onMouseLeave = function () {
        var _this = this;
        this.mouseLeaveSubcriptions.push(timer(500).subscribe(function () {
            _this.activePopupConfig = null;
            _this.activePopupMenuIndex = null;
        }));
    };
    GlobalMenuComponent.prototype.openLink = function (link) {
        this.activePopupConfig = null;
        this.activePopupMenuIndex = null;
        this.router.navigateByUrl(link);
    };
    return GlobalMenuComponent;
}());
export { GlobalMenuComponent };
