import * as tslib_1 from "tslib";
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { ExtendedProgressStatusCode, HistoryStatus } from '@agent-ds/shared/enums';
import { DynamicFieldService, MasterApiService } from '@agent-ds/shared/services';
import { multiFill } from '@agent-ds/shared/util/util';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
var ProgressSearchComponent = /** @class */ (function () {
    function ProgressSearchComponent(dynamicService, masterApiService) {
        this.dynamicService = dynamicService;
        this.masterApiService = masterApiService;
        this.closed = new EventEmitter();
        this.search = new EventEmitter();
        this.innerSearchModel = {};
        this.historyStatuses = [10, 20, 30, 40, 60, 581, 90, 600, 601, 610, 611, 620, 621, 630, 130, 160, 700, 710, 170, 180, 190, 200];
        this.validityArray = [true, true];
        this.valid = true;
    }
    Object.defineProperty(ProgressSearchComponent.prototype, "searchModel", {
        get: function () {
            return this.innerSearchModel;
        },
        set: function (model) {
            this.innerSearchModel = tslib_1.__assign({}, model);
        },
        enumerable: true,
        configurable: true
    });
    ProgressSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fieldSubscription = this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.leftMeta = {
                groups: [
                    {
                        title: '求職者情報',
                        class: 'form__group--green-title-border',
                        rows: _this.dynamicService.getFormRows({ fieldName: 'studentTeamId', label: '担当チーム', fieldType: 'multi-team', validationStyle: { max: 3 } }, null, 'half').concat(_this.dynamicService.getFormRows({ fieldName: 'studentUserId', fieldType: 'multi-user', label: '担当者', validationStyle: { max: 3 } }, null, 'half'), _this.dynamicService.getFormRows({ fieldName: 'studentSubUserId', fieldType: 'multi-user', label: '副担当者', validationStyle: { max: 5 } }, null, 'half'), _this.dynamicService.getFormRows({
                            fieldName: 'studentId',
                            fieldType: 'multi-student',
                            label: '求職者',
                            displayStyle: { placeholder: '求職者名、求職者カナ、求職者ID' },
                            validationStyle: { max: 3 },
                        }, null, 'half'), _this.dynamicService.getFormRows(multiFill(tslib_1.__assign({}, _this.dynamicService.getDefinition('student', 'registrationRoute'), { fieldType: 'multi-list' })), null, 'half')),
                    },
                    {
                        title: '進捗プロセス',
                        class: 'form__group--green-title-border',
                        rows: [
                            (function () {
                                var row = _this.dynamicService.getFormRows({
                                    fieldName: 'history',
                                    fieldType: 'date-range',
                                    displayType: 'date-more-advanced',
                                    label: '進捗日',
                                })[0];
                                row.fields.push({
                                    type: 'hr',
                                    name: 'line',
                                    class: 'full min-full my-7',
                                });
                                row.fields.push({
                                    type: 'label',
                                    name: 'buttons',
                                    class: 'standalone-actions full',
                                    actions: [
                                        {
                                            title: '全選択',
                                            type: 'RUNNABLE',
                                            runnable: function () {
                                                _this.innerSearchModel.historyStatus = _this.historyStatuses;
                                                return;
                                            },
                                        },
                                        {
                                            title: '選択解除',
                                            type: 'RUNNABLE',
                                            runnable: function () {
                                                _this.innerSearchModel.historyStatus = [];
                                                return;
                                            },
                                        },
                                    ],
                                    actionsClass: 'before',
                                });
                                row.fields.push({
                                    name: 'historyStatus',
                                    type: 'checkbox',
                                    multi: true,
                                    class: 'medium',
                                    labelField: 'label',
                                    valueField: 'value',
                                    options: _this.historyStatuses.map(function (key) { return ({ label: HistoryStatus[key], value: key }); }),
                                });
                                return row;
                            })(),
                        ],
                    },
                    {
                        title: '進捗担当者情報',
                        class: 'form__group--green-title-border',
                        rows: _this.dynamicService.getFormRows({ fieldName: 'progressCAUserId', label: 'CA', fieldType: 'multi-user', validationStyle: { max: 3 } }, null, 'half').concat(_this.dynamicService.getFormRows({ fieldName: 'progressRAUserId', label: 'RA', fieldType: 'multi-user', validationStyle: { max: 3 } }, null, 'half'), _this.dynamicService.getFormRows({ fieldName: 'progressPAUserId', label: 'PA', fieldType: 'multi-user', validationStyle: { max: 3 } }, null, 'half')),
                    },
                    {
                        title: '離職状況確認',
                        class: 'form__group--green-title-border',
                        rows: _this.dynamicService.getFormRows(multiFill(tslib_1.__assign({}, _this.dynamicService.getDefinition('progress', 'workStatus'), { fieldType: 'multi-list', displayType: 'checkbox' }))).concat(_this.dynamicService.getFormRows(tslib_1.__assign({}, _this.dynamicService.getDefinition('progress', 'workStatusDate'), { fieldType: 'date-range', displayType: 'date-more-advanced' })), _this.dynamicService.getFormRows(multiFill(tslib_1.__assign({}, _this.dynamicService.getDefinition('progress', 'workStatusCheckMethod'), { fieldType: 'multi-list', displayType: 'checkbox' })))),
                    },
                ],
            };
            _this.rightMeta = {
                groups: [
                    {
                        title: '企業',
                        class: 'form__group--green-title-border',
                        rows: _this.dynamicService.getFormRows({ fieldName: 'enterpriseId', fieldType: 'multi-enterprise', label: '企業' }, null, 'half'),
                    },
                    {
                        title: '求人情報',
                        class: 'form__group--green-title-border',
                        rows: _this.dynamicService.getFormRows({ fieldName: 'enterpriseTeamId', label: '担当チーム', fieldType: 'multi-team', validationStyle: { max: 3 } }, null, 'half').concat(_this.dynamicService.getFormRows({ fieldName: 'enterpriseUserId', fieldType: 'multi-user', label: '担当者', validationStyle: { max: 3 } }, null, 'half'), _this.dynamicService.getFormRows({ fieldName: 'jobId', fieldType: 'multi-job', label: '求人', validationStyle: { max: 3 } }, null, 'half')),
                    },
                    {
                        title: '最新ステータス',
                        class: 'form__group--green-title-border',
                        rows: [
                            {
                                title: '最新ステータス',
                                fields: [
                                    {
                                        name: 'status',
                                        type: 'checkbox',
                                        multi: true,
                                        class: 'medium',
                                        valueField: 'value',
                                        labelField: 'label',
                                        options: [
                                            10,
                                            20,
                                            30,
                                            40,
                                            50,
                                            60,
                                            70,
                                            80,
                                            90,
                                            500,
                                            501,
                                            502,
                                            511,
                                            512,
                                            521,
                                            522,
                                            130,
                                            140,
                                            150,
                                            160,
                                            170,
                                            180,
                                            190,
                                            200,
                                        ].map(function (key) { return ({ label: ExtendedProgressStatusCode[key], value: key }); }).slice(),
                                        actions: [
                                            {
                                                type: 'RUNNABLE',
                                                title: '稼働中',
                                                runnable: function () { return [60, 70, 80, 90, 130, 500, 501, 502, 510, 511, 512, 520, 521, 522]; },
                                            },
                                            {
                                                type: 'RUNNABLE',
                                                title: '進捗中',
                                                runnable: function () { return [130, 500, 501, 502, 510, 511, 512, 520, 521, 522]; },
                                            },
                                            {
                                                type: 'RUNNABLE',
                                                title: '選択解除',
                                                runnable: function () { return []; },
                                            },
                                        ],
                                        actionsClass: 'before full mb-3',
                                    },
                                ],
                            }
                        ].concat(_this.dynamicService.getFormRows({
                            label: 'セミナー・面接日',
                            fieldType: 'date-range',
                            displayType: 'date-more-advanced',
                            fieldName: 'seminarDate',
                        }), [
                            {
                                title: '学生起因滞留',
                                fields: [
                                    {
                                        name: 'isStudentDelay',
                                        type: 'checkbox',
                                        multi: true,
                                        valueField: 'value',
                                        labelField: 'label',
                                        options: [{ label: NULL_SELECTED_VALUE, value: 0 }, { label: '学生起因滞留', value: 1 }],
                                    },
                                ],
                            },
                            {
                                title: '進捗区分',
                                fields: [
                                    {
                                        name: 'type',
                                        type: 'radio',
                                        valueField: 'value',
                                        labelField: 'label',
                                        options: [
                                            { label: NULL_SELECTED_VALUE, value: NULL_SELECTED_VALUE },
                                            { label: 'AG', value: 0 },
                                            { label: 'Plus', value: 1 },
                                        ],
                                    },
                                ],
                            },
                        ]),
                    },
                ],
            };
        });
    };
    ProgressSearchComponent.prototype.ngOnDestroy = function () {
        if (this.fieldSubscription) {
            this.fieldSubscription.unsubscribe();
            this.fieldSubscription = null;
        }
    };
    ProgressSearchComponent.prototype.onValidityChange = function (index, validity) {
        this.validityArray[index] = validity;
        this.valid = this.validityArray.find(function (v) { return !v; }) == null;
    };
    ProgressSearchComponent.prototype.onCloseClick = function () {
        this.closed.emit();
    };
    ProgressSearchComponent.prototype.doSearch = function (event) {
        if (!this.valid || (event && event['isComposing'])) {
            return;
        }
        var history = this.searchModel.history || {};
        var historyStatus = this.searchModel.historyStatus || [];
        var workStatusDate = this.searchModel.workStatusDate || {};
        var workStatus = this.searchModel.workStatus || {};
        var workStatusCheckMethod = this.searchModel.workStatusCheckMethod || {};
        if ((history.from || history.to || historyStatus.length) && (!historyStatus.length || (!history.from && !history.to))) {
            PopupControlComponent.instance.open({
                title: '必要な情報が入力されていません',
                content: '進捗プロセスを検索条件に指定する際は、「期間」、「進捗ステータス」の両方を指定してください。',
                confirmText: '閉じる',
                confirmCallback: function () { return false; },
            });
        }
        else if ((workStatus.length || workStatusDate.from || workStatusDate.to || workStatusCheckMethod.length) &&
            (!workStatus.length || !workStatusCheckMethod.length || (!workStatusDate.from && !workStatusDate.to))) {
            PopupControlComponent.instance.open({
                title: '必要な情報が入力されていません',
                content: '離職状況確認を検索条件に指定する際は、「就業状況」、「調査日」、「調査方法」の全てを指定してください。',
                confirmText: '閉じる',
                confirmCallback: function () { return false; },
            });
        }
        else {
            this.search.emit(tslib_1.__assign({}, this.searchModel));
        }
    };
    return ProgressSearchComponent;
}());
export { ProgressSearchComponent };
