import * as tslib_1 from "tslib";
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { first } from 'rxjs/operators';
import { SortOrder } from './table-interface';
var PageScrollTableComponent = /** @class */ (function () {
    function PageScrollTableComponent(datePipe) {
        this.datePipe = datePipe;
        this.pageSize = 20;
        this.content = [];
        this.scrollerClass = 'vertical-scroll-only';
        this.itemSelected = new EventEmitter();
        this.itemChecked = new EventEmitter();
        this.page = 0;
        this.innerLoading = false;
        this.innerTotalSize = 0;
        this.innerItemSize = null;
        this.selectedItem = null;
        this.checkedItems = [];
        this.sorting = { field: null, order: SortOrder.DESCENDING, defaultField: null, defaultOrder: SortOrder.DESCENDING };
        this.fieldsByName = {};
    }
    Object.defineProperty(PageScrollTableComponent.prototype, "loading", {
        get: function () {
            return this.innerLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageScrollTableComponent.prototype, "itemSize", {
        get: function () {
            return this.innerItemSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageScrollTableComponent.prototype, "totalSize", {
        get: function () {
            return this.innerTotalSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageScrollTableComponent.prototype, "currentSize", {
        get: function () {
            return this.content ? this.content.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageScrollTableComponent.prototype, "hasCheckedItems", {
        get: function () {
            return !!this.checkedItems.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageScrollTableComponent.prototype, "tableConfig", {
        get: function () {
            return this.innerTableConfig;
        },
        set: function (config) {
            var _this = this;
            this.innerTableConfig = config;
            if (this.innerTableConfig) {
                if (((this.tableConfig.body || {}).style || {})['height']) {
                    this.innerItemSize = parseFloat(this.tableConfig.body.style['height'].toString());
                }
                else if (((this.tableConfig.body || {}).style || {})['min-height']) {
                    this.innerItemSize = parseFloat(this.tableConfig.body.style['min-height'].toString());
                }
                this.tableConfig.head.config.forEach(function (head) {
                    return head.columns.forEach(function (item) {
                        item.allStyle = tslib_1.__assign({}, (item.style || {}), (item.bodyStyle || {}));
                        item.fields.forEach(function (field) {
                            if (field.defaultSort) {
                                _this.sorting.defaultField = _this.sorting.field = field.name;
                                _this.sorting.defaultOrder = _this.sorting.order = field.defaultSort;
                            }
                            _this.fieldsByName[field.name] = field;
                        });
                    });
                });
                if (!this.supplier) {
                    this.innerSort();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PageScrollTableComponent.prototype.ngOnInit = function () { };
    PageScrollTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes['supplier'] && changes['supplier'].previousValue) {
            this.reset(false, false, !!changes['content'].currentValue);
        }
        if (changes['content'] && !this.supplier) {
            this.innerTotalSize = this.content ? this.content.length : 0;
            this.checkedItems.length = 0;
            this.innerSort();
        }
    };
    PageScrollTableComponent.prototype.dynamicStyle = function (dynamicStyles, data) {
        if (dynamicStyles && Object.keys(dynamicStyles).length) {
            var executedStyles_1 = {};
            Object.keys(dynamicStyles).forEach(function (key) {
                if (dynamicStyles.hasOwnProperty(key)) {
                    executedStyles_1[key] = dynamicStyles[key](data);
                }
            });
            return executedStyles_1;
        }
        return null;
    };
    PageScrollTableComponent.prototype.defaultFormatter = function (data, fullPath) {
        if (data && fullPath.includes('.')) {
            var nestedPaths = fullPath.split('.');
            var nestedData_1;
            nestedPaths.forEach(function (path) {
                nestedData_1 = nestedData_1 ? tslib_1.__assign({}, nestedData_1)[path] : tslib_1.__assign({}, data)[path];
            });
            return nestedData_1;
        }
        if (data && data[fullPath] != null) {
            if (data[fullPath] instanceof Date || data[fullPath].getDate) {
                return this.datePipe.transform(data[fullPath], 'yyyy/MM/dd');
            }
            return data[fullPath].toString();
        }
        return '';
    };
    PageScrollTableComponent.prototype.onScroll = function (event) {
        if (event) {
            this.scrollableElement = event.srcElement;
        }
        if (this.supplier &&
            this.content.length < this.totalSize &&
            !this.innerLoading &&
            event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) > 0.95) {
            this.next();
        }
    };
    PageScrollTableComponent.prototype.onSortChange = function (fieldName) {
        if (this.sorting.field === fieldName && this.sorting.order === SortOrder.ASCENDING) {
            this.sorting.order = SortOrder.DESCENDING;
        }
        else {
            this.sorting.field = fieldName;
            this.sorting.order = SortOrder.ASCENDING;
        }
        if (this.supplier) {
            this.reset(true);
            this.next();
        }
        else if (this.content.length) {
            this.innerSort();
        }
    };
    PageScrollTableComponent.prototype.innerSort = function () {
        var _this = this;
        if (this.sorting.field && this.content) {
            var field_1 = this.fieldsByName[this.sorting.field];
            this.content.sort(function (a, b) {
                var aVal = field_1.formatter ? field_1.formatter(a) : a[_this.sorting.field] || a;
                var bVal = field_1.formatter ? field_1.formatter(b) : b[_this.sorting.field] || b;
                if ((aVal == null && bVal != null) || aVal < bVal) {
                    return _this.sorting.order === SortOrder.ASCENDING ? -1 : 1;
                }
                else if ((bVal == null && aVal != null) || aVal > bVal) {
                    return _this.sorting.order === SortOrder.ASCENDING ? 1 : -1;
                }
                else {
                    return 0;
                }
            });
        }
    };
    PageScrollTableComponent.prototype.onItemSelect = function (item) {
        this.itemSelected.emit((this.selectedItem = item));
    };
    PageScrollTableComponent.prototype.onRowClick = function (row, event, data) {
        if (row && row.clickAction) {
            row.clickAction(event, data);
        }
    };
    PageScrollTableComponent.prototype.onFieldClick = function (field, event, data) {
        if (field.clickAction) {
            field.clickAction(event, data);
        }
    };
    PageScrollTableComponent.prototype.onItemCheck = function (item, event) {
        event.stopPropagation();
        var idx = this.checkedItems.indexOf(item);
        if (idx > -1) {
            this.allChecked = false;
            this.checkedItems.splice(idx, 1);
        }
        else {
            this.checkedItems.push(item);
        }
        this.itemChecked.emit({ item: item, checked: idx < 0 });
    };
    PageScrollTableComponent.prototype.next = function () {
        var _this = this;
        if (this.supplier && !this.innerLoading) {
            this.innerLoading = true;
            this.supplier(this.page++, this.pageSize, this.sorting.field, this.sorting.order)
                .pipe(first())
                .subscribe(function (batch) {
                var _a;
                _this.innerLoading = false;
                if (!_this.supplier) {
                    return; // changed to static content while the request was ongoing
                }
                _this.innerTotalSize = batch.totalSize || 0;
                _this.content = _this.content != null ? _this.content.concat(batch.result) : batch.result.slice();
                if (_this.allChecked) {
                    (_a = _this.checkedItems).push.apply(_a, batch.result);
                }
            }, function () { return (_this.innerLoading = false); });
        }
    };
    PageScrollTableComponent.prototype.reset = function (dataOnly, noScroll, keepContent) {
        if (!keepContent && this.content) {
            this.content.length = 0;
        }
        this.checkedItems.length = 0;
        this.allChecked = false;
        if (!this.innerLoading) {
            this.page = 0;
        }
        if (!noScroll && this.scrollableElement) {
            this.scrollableElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (!dataOnly) {
            this.innerTotalSize = 0;
            this.sorting.field = this.sorting.defaultField || null;
            this.sorting.order = this.sorting.defaultOrder || SortOrder.DESCENDING;
        }
    };
    PageScrollTableComponent.prototype.checkAll = function (uncheck) {
        var _a;
        if (uncheck === void 0) { uncheck = false; }
        this.allChecked = !uncheck;
        if (this.allChecked) {
            this.checkedItems.length = 0;
            (_a = this.checkedItems).push.apply(_a, this.content);
            this.itemChecked.emit({ checked: true });
        }
        else {
            this.checkedItems.length = 0;
            this.itemChecked.emit({ checked: false });
        }
    };
    return PageScrollTableComponent;
}());
export { PageScrollTableComponent };
