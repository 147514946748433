<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">セミナー 紹介求人新規登録</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__container">
    <div class="message">セミナー 紹介求人を新規登録します。</div>
    <div class="form-row">
      <div class="form-row__label">
        求人
      </div>
      <ag-dynamic-form
        class="form-row__form"
        [metadata]="metadata"
        [model]="model"
        (validated)="onValidityChange($event)"
      >
      </ag-dynamic-form>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="!isValid"
      (click)="register()"
    >
      登録
    </button>
  </div>
</div>
