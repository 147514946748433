/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-send-flow.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../dynamic-form/dynamic-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../pipes/safe-date.pipe";
import * as i6 from "../../pipes/truncate.pipe";
import * as i7 from "@angular/common";
import * as i8 from "../page-floater/page-floater.component.ngfactory";
import * as i9 from "../page-floater/page-floater.component";
import * as i10 from "../mail-send/mail-send.component.ngfactory";
import * as i11 from "../mail-send/mail-send.component";
import * as i12 from "../../services/api/dynamic-field.service";
import * as i13 from "../../services/api/mail-api.service";
import * as i14 from "../../services/auth.service";
import * as i15 from "../../services/api/file-api.service";
import * as i16 from "../../services/api/job-api.service";
import * as i17 from "../../services/api/progress-api.service";
import * as i18 from "../../services/api/company-api.service";
import * as i19 from "../../services/api/user-api.service";
import * as i20 from "./mail-send-flow.component";
var styles_MailSendFlowComponent = [i0.styles];
var RenderType_MailSendFlowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailSendFlowComponent, data: {} });
export { RenderType_MailSendFlowComponent as RenderType_MailSendFlowComponent };
function View_MailSendFlowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "job"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "job__head"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ag-dynamic-form", [["class", "job__select"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(4, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.popupMeta; var currVal_2 = _co.popupModel; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.popupMessage || "\u8A72\u5F53\u3059\u308B\u6C42\u4EBA\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002"); _ck(_v, 2, 0, currVal_0); }); }
function View_MailSendFlowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "job-table__info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.frontJobId + " ") + _v.context.$implicit.position); _ck(_v, 1, 0, currVal_0); }); }
function View_MailSendFlowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "job-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "job-table__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "job-table__head"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u4EBAID\u3001\u30DD\u30B8\u30B7\u30E7\u30F3\u540D"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailSendFlowComponent_3)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.jobErrorInfo; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "\u62C5\u5F53\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u306A\u3044\u304B\u3001\u6C42\u4EBA\u30B9\u30C6\u30FC\u30BF\u30B9\u304C\u52DF\u96C6\u4E2D\u307E\u305F\u306F\u63A8\u85A6\u505C\u6B62\u3067\u306F\u3042\u308A\u307E\u305B\u3093\u3002\n\u6C42\u4EBA\u8A73\u7D30\u30DA\u30FC\u30B8\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002"; _ck(_v, 2, 0, currVal_0); }); }
function View_MailSendFlowComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "job-table__info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.frontJobId + " ") + _v.context.$implicit.position); _ck(_v, 1, 0, currVal_0); }); }
function View_MailSendFlowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "job-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "job-table__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u6C42\u4EBA\u30B9\u30C6\u30FC\u30BF\u30B9\u304C\u63A8\u85A6\u505C\u6B62\u3067\u3059\u304C\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "job-table__head"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6C42\u4EBAID\u3001\u30DD\u30B8\u30B7\u30E7\u30F3\u540D"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailSendFlowComponent_5)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jobWarningInfo; _ck(_v, 6, 0, currVal_0); }, null); }
function View_MailSendFlowComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "department"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "department__head"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u90E8\u7F72\u3092\u9078\u629E\u3057\u307E\u3059\u3002"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ag-dynamic-form", [["class", "department__select"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(4, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupMeta; var currVal_1 = _co.popupModel; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_MailSendFlowComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-dynamic-form", [["class", "student__select"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupSendMeta; var currVal_1 = ((i1.ɵnov(_v.parent.parent, 10) == null) ? null : i1.ɵnov(_v.parent.parent, 10).model); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MailSendFlowComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "student"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "student__head"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ag-dynamic-form", [["class", "student__select"]], null, null, null, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(4, 442368, null, 0, i3.DynamicFormComponent, [i4.FormBuilder, i1.KeyValueDiffers, i5.SafeDatePipe, i6.TruncatePipe, i1.ChangeDetectorRef], { metadata: [0, "metadata"], model: [1, "model"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailSendFlowComponent_8)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.popupMeta; var currVal_2 = _co.popupModel; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.popupSendMeta.groups[0].rows.length; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.popupMessage || "\u4E00\u62EC\u63A8\u85A6\u3057\u307E\u3059\u3002\n\u5BFE\u8C61\u306E\u6C42\u8077\u8005\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"); _ck(_v, 2, 0, currVal_0); }); }
export function View_MailSendFlowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { mailFloater: 0 }), i1.ɵqud(402653184, 2, { mailSend: 0 }), i1.ɵqud(402653184, 3, { jobSelectTemplate: 0 }), i1.ɵqud(671088640, 4, { jobErrorTemplate: 0 }), i1.ɵqud(671088640, 5, { jobWarningTemplate: 0 }), i1.ɵqud(402653184, 6, { depSelectTemplate: 0 }), i1.ɵqud(402653184, 7, { studentSelectTemplate: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ag-page-floater", [], null, [[null, "transformed"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).keyHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("transformed" === en)) {
        var pd_1 = ((($event === "0px") ? _co.close(true) : "") !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_PageFloaterComponent_0, i8.RenderType_PageFloaterComponent)), i1.ɵdid(8, 245760, [[1, 4], ["mailFloater", 4]], 0, i9.PageFloaterComponent, [i1.ElementRef], { sideActions: [0, "sideActions"] }, { transformed: "transformed" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "ag-mail-send", [], null, [[null, "closed"], [null, "shouldOpen"], [null, "showPopup"], [null, "sent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } if (("shouldOpen" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).grow() !== false);
        ad = (pd_1 && ad);
    } if (("showPopup" === en)) {
        var pd_2 = (_co.openPopup($event) !== false);
        ad = (pd_2 && ad);
    } if (("sent" === en)) {
        var pd_3 = (_co.sent.emit() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i10.View_MailSendComponent_0, i10.RenderType_MailSendComponent)), i1.ɵdid(10, 114688, [[2, 4], ["mailSend", 4]], 0, i11.MailSendComponent, [i12.DynamicFieldService, i13.MailApiService, i14.AuthService, i15.FileApiService, i16.JobApiService, i17.ProgressApiService, i18.CompanyApiService, i19.UserApiService, i5.SafeDatePipe], null, { showPopup: "showPopup", closed: "closed", sent: "sent", shouldOpen: "shouldOpen" }), (_l()(), i1.ɵand(0, [[3, 2], ["jobSelect", 2]], null, 0, null, View_MailSendFlowComponent_1)), (_l()(), i1.ɵand(0, [[4, 2], ["jobError", 2]], null, 0, null, View_MailSendFlowComponent_2)), (_l()(), i1.ɵand(0, [[5, 2], ["jobWarning", 2]], null, 0, null, View_MailSendFlowComponent_4)), (_l()(), i1.ɵand(0, [[6, 2], ["depSelect", 2]], null, 0, null, View_MailSendFlowComponent_6)), (_l()(), i1.ɵand(0, [[7, 2], ["studentSelect", 2]], null, 0, null, View_MailSendFlowComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mailSideActions; _ck(_v, 8, 0, currVal_0); _ck(_v, 10, 0); }, null); }
export function View_MailSendFlowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-mail-send-flow", [], null, null, null, View_MailSendFlowComponent_0, RenderType_MailSendFlowComponent)), i1.ɵdid(1, 114688, null, 0, i20.MailSendFlowComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailSendFlowComponentNgFactory = i1.ɵccf("ag-mail-send-flow", i20.MailSendFlowComponent, View_MailSendFlowComponent_Host_0, {}, { sent: "sent", closed: "closed" }, []);
export { MailSendFlowComponentNgFactory as MailSendFlowComponentNgFactory };
