import * as tslib_1 from "tslib";
import { SalesListComponent } from '@agent-ds/shared/components/sales-list/sales-list.component';
import { DashboardType } from '@agent-ds/shared/interfaces';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { AuthService, ExportApiService } from '@agent-ds/shared/services';
import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesDetailPageComponent } from '../sales-detail-page/sales-detail-page.component';
var SalesListPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SalesListPageComponent, _super);
    function SalesListPageComponent(router, activeRoute, location, exportService, authService) {
        var _this = _super.call(this, router, location, activeRoute, exportService) || this;
        _this.router = router;
        _this.activeRoute = activeRoute;
        _this.location = location;
        _this.exportService = exportService;
        _this.authService = authService;
        _this.model = 'sales';
        _this.detail = SalesDetailPageComponent;
        _this.exportModel = null;
        _this.isIntakeCa = false;
        // 副担当者のログインかどうかを確認
        _this.loginSubscription = _this.authService.isLogined().subscribe(function (logined) {
            _this.isIntakeCa = _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
        });
        return _this;
    }
    SalesListPageComponent.prototype.ngOnDestroy = function () {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
            this.loginSubscription = null;
        }
    };
    SalesListPageComponent.prototype.runExport = function () {
        var _this = this;
        if (this.list.table.allChecked || (this.list.table.checkedItems && this.list.table.checkedItems.length)) {
            this.exportService
                .exportSales(this.list.table.checkedItems.map(function (item) { return item.id; }))
                .subscribe(function () { return (_this.exportInProgress = false); }, function () { return (_this.exportInProgress = false); });
        }
        else {
            this.exportService
                .exportSales(null, this.searchObj)
                .subscribe(function () { return (_this.exportInProgress = false); }, function () { return (_this.exportInProgress = false); });
        }
    };
    return SalesListPageComponent;
}(ListPage));
export { SalesListPageComponent };
