import { EnterpriseDepartmentUserType } from '@agent-ds/shared/interfaces';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { isArray } from 'util';
import { MasterApiService } from '../master-api.service';
import { UserApiService } from '../user-api.service';
var UserTeamInjectorProvider = /** @class */ (function () {
    function UserTeamInjectorProvider(masterApiService, userApiService) {
        this.masterApiService = masterApiService;
        this.userApiService = userApiService;
        this.enterpriseDepartmentUserType = EnterpriseDepartmentUserType;
    }
    UserTeamInjectorProvider.prototype.createMappedUserObject = function (area, userId, users, teams) {
        var user = users.find(function (u) { return u.id === userId; });
        if (user) {
            var team = teams.find(function (t) { return t.id === user.teamId; });
            return {
                area: area,
                id: userId,
                name: user.name,
                teamId: user.teamId,
                teamName: team ? team.name : undefined,
            };
        }
        return {
            area: area,
            id: userId,
        };
    };
    UserTeamInjectorProvider.prototype.getRequestAndProcessList = function (apiResponseObservable, listElementsAccessor) {
        var _this = this;
        var teams = this.masterApiService.getTeams();
        var users = this.userApiService.getAll();
        // wait until all Observables finish
        return forkJoin([teams, users, apiResponseObservable]).pipe(map(function (_a) {
            var teamList = _a[0], userList = _a[1], response = _a[2];
            // append entries in .users entries
            if (isArray(response[listElementsAccessor])) {
                response[listElementsAccessor].forEach(function (s) {
                    s.users = [];
                    if (s.enterpriseDepartmentUsers) {
                        s.enterpriseDepartmentUsers.forEach(function (user) {
                            s.users.push(_this.createMappedUserObject(_this.enterpriseDepartmentUserType[user.type], user.userId, userList, teamList));
                        });
                    }
                    else {
                        s.users.push(_this.createMappedUserObject('PA', s.paUserId, userList, teamList));
                        s.users.push(_this.createMappedUserObject('RA', s.raUserId, userList, teamList));
                        s.users.push(_this.createMappedUserObject('CA', s.caUserId, userList, teamList));
                    }
                });
            }
            else {
                console.warn("Warning!: Expected " + listElementsAccessor + " would point to an array of result items in ", response);
            }
            return response;
        }));
    };
    UserTeamInjectorProvider.prototype.getUserInfoById = function (id, area) {
        var _this = this;
        if (id) {
            var teams = this.masterApiService.getTeams();
            var users = this.userApiService.getAll();
            return forkJoin([teams, users]).pipe(map(function (results) { return _this.createMappedUserObject(area, id, results[1], results[0]); }));
        }
        else {
            return of(null);
        }
    };
    UserTeamInjectorProvider.prototype.getUserTeamNameById = function (id) {
        if (id == null) {
            return of('');
        }
        return this.getUserTeamInfoById(id).pipe(map(function (info) { return (info ? (info.name && info.teamName ? (info.name || '') + " " + (info.teamName || '') : info.name || '') : ''); }));
    };
    UserTeamInjectorProvider.prototype.getUserTeamInfoById = function (id) {
        var _this = this;
        if (id == null) {
            return of(null);
        }
        return this.userApiService.getAll().pipe(mergeMap(function (users) {
            var user = users.find(function (u) { return u.id === id; });
            if (user) {
                return _this.masterApiService.getTeams().pipe(map(function (teams) {
                    var team = teams.find(function (t) { return t.id === user.teamId; });
                    if (team) {
                        return { name: user.name, teamName: team.name };
                    }
                    else {
                        return { name: user.name };
                    }
                }));
            }
            return of(null);
        }));
    };
    return UserTeamInjectorProvider;
}());
export { UserTeamInjectorProvider };
