import * as tslib_1 from "tslib";
import { ApiService } from '@agent-ds/shared/models/api-service';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth.service";
var MailApiService = /** @class */ (function (_super) {
    tslib_1.__extends(MailApiService, _super);
    function MailApiService(http, authService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.authService = authService;
        _this.authService.isLogined().subscribe(function (logined) {
            if (logined) {
                _this.mailTemplates = _this.mailTemplatePlaceholders = _this.mailTemplateTypes = null;
            }
        });
        return _this;
    }
    MailApiService.prototype.getMails = function (model, id, from, size, sort, order, keyword) {
        if (from === void 0) { from = 0; }
        if (size === void 0) { size = 1000; }
        if (sort === void 0) { sort = 'timestamp'; }
        if (order === void 0) { order = 'desc'; }
        return this.http.get("/api/mail/" + model + "/" + id, {
            params: { from: from, size: size, sort: sort, order: order, keyword: keyword || '' },
        });
    };
    MailApiService.prototype.getAttachmentInfo = function (id) {
        return this.http.get("/api/mail/" + id + "/attachment/info");
    };
    MailApiService.prototype.getMailTemplateTypes = function () {
        if (!this.mailTemplateTypes) {
            this.mailTemplateTypes = this.http.get('/api/mail/template/types').pipe(shareReplay(1));
        }
        return this.mailTemplateTypes;
    };
    MailApiService.prototype.getMailTemplatePlaceholder = function () {
        if (!this.mailTemplatePlaceholders) {
            this.mailTemplatePlaceholders = this.http.get('/api/mail/template/placeholder').pipe(shareReplay(1));
        }
        return this.mailTemplatePlaceholders;
    };
    MailApiService.prototype.getMailTemplates = function () {
        if (!this.mailTemplates) {
            this.mailTemplates = this.http.get('/api/mail/template').pipe(shareReplay(1));
        }
        return this.mailTemplates;
    };
    MailApiService.prototype.getMailTemplateNames = function () {
        return this.http.get("/api/mail/template/names").pipe(shareReplay(1));
    };
    MailApiService.prototype.getMailTemplate = function (id) {
        return this.http.get("/api/mail/template/id/" + id).pipe(shareReplay(1));
    };
    MailApiService.prototype.addMailTemplate = function (template) {
        var _this = this;
        return this.http.post('/api/mail/template', template).pipe(tap(function () { return (_this.mailTemplates = null); }));
    };
    MailApiService.prototype.updateMailTemplate = function (id, template) {
        var _this = this;
        return this.http.put("/api/mail/template/" + id, template).pipe(tap(function () { return (_this.mailTemplates = null); }));
    };
    MailApiService.prototype.sendSalesNotification = function (request) {
        var _this = this;
        return this.http.post('/api/mail/sales-notification/send', request).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.downloadAttachment = function (id, partId) {
        this.http
            .get("/api/mail/" + id + "/attachment/" + partId + "/download", { responseType: 'blob', observe: 'response' })
            .subscribe(function (res) { return downloadResponse(res); });
    };
    MailApiService.prototype.getSignature = function (model, id) {
        return this.http.get("/api/mail/signature/" + model + "/" + id);
    };
    MailApiService.prototype.addSignature = function (model, id, signature) {
        return this.http.post("/api/mail/signature/" + model + "/" + id, signature);
    };
    MailApiService.prototype.updateSignature = function (id, signature) {
        return this.http.put("/api/mail/signature/" + id, signature);
    };
    MailApiService.prototype.deleteSignature = function (id) {
        return this.http.delete("/api/mail/signature/" + id);
    };
    MailApiService.prototype.sendStudentMail = function (id, mailObj) {
        var _this = this;
        return this.http.post("/api/mail/students/" + id + "/send", mailObj).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendStudentBulkMail = function (mailObj) {
        var _this = this;
        return this.http.post('/api/mail/bulk/students/send', mailObj).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendEnterpriseMail = function (enterpriseId, departmentId, mailObj) {
        var _this = this;
        return this.http
            .post("/api/mail/enterprise/" + enterpriseId + "/department/" + departmentId + "/send", mailObj)
            .pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendEnterpriseResumeMail = function (enterpriseId, departmentId, mailObj) {
        var _this = this;
        return this.http
            .post("/api/mail/enterprise/" + enterpriseId + "/department/" + departmentId + "/resume/send", mailObj)
            .pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.replaceTemplate = function (templateType, templateObj) {
        return this.http.post("/api/mail/template/replaced/" + templateType, templateObj);
    };
    MailApiService.prototype.bulkRecommend = function (request) {
        var _this = this;
        return this.http.post('/api/mail/bulk/recommendation/send', request).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendJobStudentsBulkMail = function (mailObj) {
        var _this = this;
        return this.http.post('/api/mail/job/students/bulk/send', mailObj).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendJobStudentMail = function (id, mailObj) {
        var _this = this;
        return this.http.post("/api/mail/job/" + id + "/student/send", mailObj).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendJobEnterpriseMail = function (jobId, enterpriseId, departmentId, mailObj) {
        var _this = this;
        return this.http
            .post("/api/mail/job/" + jobId + "/enterprise/" + enterpriseId + "/department/" + departmentId + "/send", mailObj)
            .pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendNgNotification = function (mailObj) {
        var _this = this;
        return this.http.post('/api/mail/ng-notification/send', mailObj).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.prototype.sendSearchResult = function (mailObj) {
        var _this = this;
        return this.http.post("/api/mail/bulk/students/search-result/send", mailObj).pipe(tap(function () { return _this.refreshEvent.emit(); }));
    };
    MailApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MailApiService_Factory() { return new MailApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: MailApiService, providedIn: "root" });
    return MailApiService;
}(ApiService));
export { MailApiService };
