<div class="header">
  <span class="header__title">求人検索結果</span>
  <span class="header__criteria">
    キーワード：{{ searchObj["keyword"] || "" }}
  </span>
  <button class="header__modify" (click)="openSearch()">
    検索条件を変更<span
      class="header__modify__arrow header__modify__arrow--down"
    ></span>
  </button>
  <button class="header__export" (click)="showExport()">CSV出力</button>
</div>
<div class="content">
  <ag-job-list
    [requestObject]="searchObj"
    [options]="listConfiguration"
    [extraControls]="extraButtons"
    #jobList
  >
  </ag-job-list>
</div>

<ag-job-search
  *ngIf="isSearchOpen"
  [searchModel]="searchObj"
  (closed)="closeSearch()"
  (search)="doSearch($event)"
></ag-job-search>

<ng-template #export>
  <div class="export">
    <p class="export__note">出力するCSVを選択してください。</p>
    <p class="export__label">出力リスト</p>
    <ag-autocomplete
      class="export__select"
      valueField="id"
      labelField="name"
      [hideBtn]="false"
      [options]="exportTemplates"
      [(value)]="exportTemplateId"
    ></ag-autocomplete>
  </div>
</ng-template>

<ng-template #extraButtons>
  <button
    [disabled]="
      jobList?.checkedItems.length > 20 || !jobList?.checkedItems.length
    "
    class="content__controls__control"
    (click)="getJobsAsPdf(jobList?.checkedItems)"
  >
    求人票の出力
  </button>
  <button
    [disabled]="!jobList?.checkedItems.length"
    class="content__controls__control"
    (click)="openMail(postingConfig)"
  >
    求人票送信
  </button>
</ng-template>
