import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injector, Type } from '@angular/core';
import { DialogConfig } from '../components/dialog/dialog-config';
import { DialogInjector } from '../components/dialog/dialog-injector';
import { DialogRef } from '../components/dialog/dialog-ref';
import { DialogComponent } from '../components/dialog/dialog.component';
import * as i0 from "@angular/core";
var DialogService = /** @class */ (function () {
    function DialogService(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
    }
    DialogService.prototype.open = function (componentType, config, beforeClose) {
        if (!config) {
            config = { data: {} };
        }
        var dialogRef = this.appendDialogComponentToBody(config, beforeClose);
        this.dialogComponentRef.instance.childComponentType = componentType;
        return dialogRef;
    };
    DialogService.prototype.appendDialogComponentToBody = function (config, beforeClose) {
        var _this = this;
        var map = new WeakMap();
        map.set(DialogConfig, config);
        var dialogRef = new DialogRef(beforeClose);
        map.set(DialogRef, dialogRef);
        var componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
        var componentRef = componentFactory.create(new DialogInjector(this.injector, map));
        if (config && config.zIndex) {
            componentRef.instance.zIndex = config.zIndex;
        }
        this.appRef.attachView(componentRef.hostView);
        var domElem = componentRef.hostView.rootNodes[0];
        document.body.appendChild(domElem);
        this.dialogComponentRef = componentRef;
        var sub = dialogRef.afterClosed.subscribe(function () {
            _this.removeDialogComponentFromBody(componentRef);
            sub.unsubscribe();
        });
        componentRef.instance.onClose.subscribe(function () {
            _this.removeDialogComponentFromBody(componentRef);
        });
        return dialogRef;
    };
    DialogService.prototype.removeDialogComponentFromBody = function (componentRef) {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    };
    DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.INJECTOR)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
