import * as tslib_1 from "tslib";
import { Tab } from '@agent-ds/shared/models';
import { DynamicFieldService, StudentApiService } from '@agent-ds/shared/services';
import { OnInit } from '@angular/core';
var StudentAcademicTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StudentAcademicTabComponent, _super);
    function StudentAcademicTabComponent(dynamicService, studentApiService) {
        var _this = _super.call(this) || this;
        _this.dynamicService = dynamicService;
        _this.studentApiService = studentApiService;
        _this.valid = true;
        return _this;
    }
    StudentAcademicTabComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.metadata = {
                disabled: _this.readonly,
                groups: [
                    {
                        title: '学歴',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'lastAcademicRecord')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'academicFieldType'))),
                    },
                    {
                        title: '最終',
                        class: 'form__group--no-title-border',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'academicHistory1')),
                    },
                    {
                        title: 'No.2',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'academicHistory2')),
                    },
                    {
                        class: 'form__group--no-title-border form__group--no-margin mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'universityLevel')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'academicCourseType'))),
                    },
                    {
                        title: '資格',
                        rows: [],
                    },
                    {
                        title: 'No.1',
                        class: 'form__group--no-title-border form__group--no-margin',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'certificate1')),
                    },
                    {
                        title: 'No.2',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'certificate2')),
                    },
                    {
                        title: 'No.3',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'certificate3')),
                    },
                    {
                        title: 'No.4',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'certificate4')),
                    },
                    {
                        title: 'No.5',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'certificate5')),
                    },
                    {
                        class: 'mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'otherSkill')),
                    },
                    {
                        class: 'mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingC')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingCPP')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingCS')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingHTML')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingJava')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingJS')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingPerl')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingPHP')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingPython')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingRuby')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingVB')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'programmingGo')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'otherITSkills'))),
                    },
                    {
                        title: '語学',
                        rows: [],
                    },
                    {
                        title: 'No.1',
                        class: 'form__group--no-title-border form__group--no-margin',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'language1')),
                    },
                    {
                        title: 'No.2',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'language2')),
                    },
                    {
                        title: 'No.3',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'language3')),
                    },
                    {
                        title: 'No.4',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'language4')),
                    },
                    {
                        title: 'No.5',
                        class: 'form__group--no-title-border form__group--no-margin title-mt-20',
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('student', 'language5')),
                    },
                ],
            };
        });
    };
    StudentAcademicTabComponent.prototype.save = function () {
        var _this = this;
        this.studentApiService.update(this.student.id, this.student).subscribe(function (res) { return _this.studentApiService.refreshEvent.emit(); });
    };
    return StudentAcademicTabComponent;
}(Tab));
export { StudentAcademicTabComponent };
