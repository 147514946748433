<div class="dialog">
  <div class="dialog__header">
    <h1 class="dialog__title">新規進捗登録</h1>
    <span class="close" (click)="close()"></span>
  </div>
  <div class="dialog__body">
    <div class="create">
      <div class="create__subtitle">新規進捗を登録します。</div>

      <div class="static-form" *ngIf="config?.data?.matchingMode">
        <div class="static-form__row">
          <div class="static-form__row__column">求職者</div>
          <div class="static-form__row__column">
            {{ students }}
          </div>
        </div>
        <div class="static-form__row">
          <div class="static-form__row__column">求人</div>
          <div class="static-form__row__column">
            {{ jobs }}
          </div>
        </div>
      </div>
      <ag-dynamic-form
        class="create__dynamic-form"
        [metadata]="meta"
        [model]="model"
        (validated)="isModelValid = $event"
      ></ag-dynamic-form>

      <form class="create__form" [formGroup]="form">
        <div class="create__label">進捗区分</div>
        <div class="create__input">
          <div class="create__radio-group">
            <input type="radio" [value]="0" formControlName="type" />
            <span class="create__progress-badge badge badge--medium badge--blue"
              >AG</span
            >
          </div>
          <div class="create__radio-group">
            <input type="radio" [value]="1" formControlName="type" />
            <span
              class="create__progress-badge badge badge--medium badge--green-light"
              >Plus</span
            >
          </div>
        </div>
        <div class="create__label">面談会<br />(任意)</div>
        <div class="create__input">
          <div class="create__checkbox-group">
            <input type="checkbox" [value]="1" formControlName="hasInterview" />
            有
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="dialog__footer">
    <button class="dialog__button dialog__button--accent" (click)="close()">
      キャンセル
    </button>
    <button
      class="dialog__button dialog__button--primary"
      [disabled]="form.invalid || !isModelValid || inClose || dialog.inClose"
      (click)="create()"
    >
      登録
    </button>
  </div>
</div>

<ng-template #progressError>
  <div class="progress-table">
    <div class="progress-table__title">
      下記の進捗データがすでに存在します。
    </div>
    <div class="progress-table__head">
      <div class="progress-table__head-item">進捗区分</div>
      <div class="progress-table__head-item">求職者ID、求職者名</div>
      <div class="progress-table__head-item">求人ID、ポジション名</div>
      <div class="progress-table__head-item">進捗ステータス</div>
    </div>
    <div class="progress-table__info" *ngFor="let info of progressErrorInfo">
      <div
        class="progress-table__info-item progress-table__info-item--labeled"
        [class.progress-table__info-item--labeled--AG]="info.progressType === 0"
        [class.progress-table__info-item--labeled--Plus]="
          info.progressType === 1
        "
        [class.progress-table__info-item--labeled--DR]="info.progressType === 2"
      ></div>
      <div
        class="progress-table__info-item"
        [title]="info.studentLastName + info.studentFirstName"
      >
        <span class="progress-table__info-item progress-table__info-item--id">{{
          info.studentFrontId
        }}</span>
        <span
          class="progress-table__info-item progress-table__info-item--name"
          >{{ info.studentLastName + info.studentFirstName }}</span
        >
      </div>
      <div class="progress-table__info-item">
        <span class="progress-table__info-item progress-table__info-item--id">{{
          info.jobFrontId
        }}</span>
        <span class="two-line" [title]="info.position">{{
          info.position
        }}</span>
      </div>
      <div class="progress-table__info-item progress-table__info-item--red">
        {{
          progressStatus[info.progressStatus] ||
            extendedProgressStatus[info.progressStatus]
        }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #jobError>
  <div class="job-table">
    <div class="job-table__title">
      {{ "担当が設定されていません。\n求人詳細ページでご確認してください。" }}
    </div>
    <div class="job-table__head">求人ID、ポジション名</div>
    <div class="job-table__info" *ngFor="let info of jobErrorInfo">
      {{ info.frontJobId + " " + info.position }}
    </div>
  </div>
</ng-template>
