import * as tslib_1 from "tslib";
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { CompanySendConfig } from '@agent-ds/shared/components/mail-send/configs/company-send-config';
import { Tab } from '@agent-ds/shared/models';
var CompanyMessageTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyMessageTabComponent, _super);
    function CompanyMessageTabComponent() {
        var _this = _super.call(this) || this;
        _this.sendConfig = new CompanySendConfig();
        return _this;
    }
    CompanyMessageTabComponent.prototype.openMail = function (prefill) {
        MailSendFlowComponent.instance.config = this.sendConfig;
        this.sendConfig.setParams({ enterpriseId: this.companyId });
        MailSendFlowComponent.instance.start(prefill);
    };
    return CompanyMessageTabComponent;
}(Tab));
export { CompanyMessageTabComponent };
